<div class="modal-content">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h3 id="modalSettings" class="modal-header h2">Import</h3>
  <div class="modal-body">
    <fieldset class="Form-fieldset Form-fieldset--divider">
      <div class="form-check">
        <input
          type="radio"
          name="importType"
          value="importType1"
          [(ngModel)]="importType"
          id="importType-1"
          class="form-check-input"
          (change)="importTypeChange()"
        />
        <label for="importType-1" class="form-check-label"
          >Titles, Offices, Practices, and FTE - <a href="#">Excel template</a></label
        >
      </div>
      <div class="form-check">
        <input
          type="radio"
          value="importType2"
          [(ngModel)]="importType"
          name="importType"
          id="importType-1"
          class="form-check-input"
          (change)="importTypeChange()"
        />
        <label for="importType-1" class="form-check-label"
          >Grad/Comp/Experience years - <a href="#">Excel template</a></label
        >
      </div>
      <div class="child-container">
        <label class="checkbox-inline zero-paddings checkbox-container" for="grad">
          <bento-checkbox
            [(ngModel)]="importOptions.grad"
            [disabled]="importType != 'importType2'"
            id="grad"
          ></bento-checkbox>
          Grad years</label
        >

        <label class="checkbox-inline zero-paddings checkbox-container" for="comp">
          <bento-checkbox
            [(ngModel)]="importOptions.comp"
            [disabled]="importType != 'importType2'"
            id="comp"
          ></bento-checkbox>
          Comp years</label
        >

        <label class="checkbox-inline zero-paddings checkbox-container" for="experience">
          <bento-checkbox
            [(ngModel)]="importOptions.experience"
            [disabled]="importType != 'importType2'"
            id="experience"
          ></bento-checkbox>
          Experience years</label
        >

        <label>Overwrite</label>

        <label class="checkbox-inline zero-paddings checkbox-container" for="existing-value">
          <bento-checkbox
            [(ngModel)]="importOptions.existingValue"
            [disabled]="importType != 'importType2'"
            id="existing-value"
          ></bento-checkbox>
          Existing values</label
        >

        <label class="checkbox-inline zero-paddings checkbox-container" for="manually-entered">
          <bento-checkbox
            [(ngModel)]="importOptions.manualEntered"
            [disabled]="importType != 'importType2'"
            id="manually-entered"
          ></bento-checkbox>
          Manually entered values</label
        >
      </div>
      <div class="file-selector-container">
        <bento-file-selector
          #fileSelector
          id="fileSelector"
          [(ngModel)]="selectedFiles"
          [showSelectedFiles]="'true'"
          [multiple]="'false'"
          [showFileSize]="'true'"
          [appendOnSelection]="'false'"
          [accept]="'.xlsx, .csv'"
          [browseButtonLabel]="browseLabelText"
        ></bento-file-selector>
      </div>
    </fieldset>
  </div>
  <div class="modal-footer modal-footer--custom">
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss('Cancel')">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="import()">Import</button>
  </div>
</div>
