<!-- <bento-alert [items]="alerts" (close)="closeAlert($event)"></bento-alert> -->
<form
  bfmForm
  name="fiscalPeriodForm"
  #fiscalPeriodForm="ngForm"
  class="FormRange"
  [bentoBusyLoader]="isMainBusyLoaderBusy"
>
  <div class="FormRange-group">
    <div class="FormRange-item FormRange-item--select">
      <label for="fispfrm">{{ fiscalFromTitle }}</label>
      <select
        *ngIf="requestFrom != 'GL Account Codes'"
        bentoSelect
        bfmField
        [bfmLabel]="fiscalFromTitle"
        class="form-control"
        [errorContainer]="fispfrmError"
        id="fispfrm"
        name="fispfrm"
        [(ngModel)]="fiscalPeriodModel.from_date"
        (change)="onChange()"
        [disabled]="!isFiscalAvail"
      >
        <option *ngFor="let type of fiscalDateRange" [ngValue]="type.value">{{ type.name }}</option>
      </select>
      <input
        name="fispfrm"
        *ngIf="requestFrom == 'GL Account Codes'"
        [(ngModel)]="fiscalPeriodModel.from_date"
        placeholder="Enter From Date"
        type="text"
        bfmField
        [bfmLabel]="fiscalFromTitle"
        class="form-control"
        [errorContainer]="fispfrmError"
        id="fispfrm"
        required
        (keyup)="onChange()"
        [pattern]="validatePattern"
        [validations]="validations.validatePattern"
        (ngModelChange)="onDateChange()"
        (fieldValidate)="isValidDate($event, 'StartDate', fiscalPeriodForm)"
        [disabled]="!isFiscalAvail"
      />
    </div>
    <div class="FormRange-item FormRange-item--mediator">thru.</div>
    <div class="FormRange-item FormRange-item--select">
      <label for="fispto">{{ fiscalToTitle }}</label>
      <select
        *ngIf="requestFrom != 'GL Account Codes'"
        bentoSelect
        bfmField
        [bfmLabel]="fiscalToTitle"
        class="form-control"
        [errorContainer]="fisptoError"
        id="fispto"
        name="fispto"
        [(ngModel)]="fiscalPeriodModel.to_date"
        (change)="onChange()"
        [disabled]="!isFiscalAvail"
      >
        <option *ngFor="let type of fiscalDateRange.slice().reverse()" [ngValue]="type.value">{{ type.name }}</option>
      </select>

      <input
        *ngIf="requestFrom == 'GL Account Codes'"
        placeholder="Enter To Date"
        type="text"
        bfmField
        [bfmLabel]="fiscalToTitle"
        class="form-control"
        [errorContainer]="fisptoError"
        id="fispto"
        required
        name="fispto"
        [(ngModel)]="fiscalPeriodModel.to_date"
        (keyup)="onChange()"
        [disabled]="!isFiscalAvail"
        [pattern]="validatePattern"
        [validations]="validations.validatePattern"
        (ngModelChange)="onDateChange()"
        (fieldValidate)="isValidDate($event, 'EndDate', fiscalPeriodForm)"
      />
    </div>

    <div class="FormRange-item FormRange-item--override" *ngIf="isOverride && availableOverrides.length > 0">
      <label for="addviewOverride">View/Add Periods</label>
      <select
        bentoSelect
        bfmField
        bfmLabel="View/Add Periods"
        class="form-control"
        id="addviewOverride"
        name="addviewOverride"
        [(ngModel)]="selectedOverride"
        (ngModelChange)="onOverridePeriodChange($event)"
      >
        <option *ngFor="let override of availableOverrides" [ngValue]="override.id">
          {{ override.startPeriod == null ? 'No start date' : override.startPeriod }} -
          {{ override.endPeriod == null ? 'No end date' : override.endPeriod }}
        </option>
      </select>
    </div>
    <div class="FormRange-item FormRange-item--action" *ngIf="isButtonVisible">
      <button
        type="button"
        class="btn btn-outline-secondary"
        name="selectButton"
        (click)="selectRange()"
        [disabled]="!isFiscalAvail"
      >
        <span class="btn-text">Select</span>
      </button>
    </div>
  </div>
  <div class="FormRange-group">
    <div class="FormRange-item FormRange-item--select">
      <bfm-error-container #fispfrmError></bfm-error-container>
    </div>
    <div class="FormRange-item FormRange-item--mediator">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
    <div class="FormRange-item FormRange-item--select">
      <bfm-error-container #fisptoError></bfm-error-container>
    </div>
  </div>
</form>
