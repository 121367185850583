<div class="Main one-offs" [bentoBusyLoader]="isLoadingOneOffs">
  <!--Bento loder overlay issue : Temporary Fix for Bento loader overlay in _layout.scss:288-->
  <div class="Main-body" >
    <wj-flex-grid
        #flexGrid
        class="DataGrid"
        [itemsSource]="data"
        [autoClipboard]="false"
        [isReadOnly]="true"
        [headersVisibility]="'Column'"
        [allowDragging]="false"
        [autoRowHeights]="true"
        (initialized)="gridInitialized(flexGrid)"
      >
        <wj-flex-grid-column
          [header]="'Release'"
          [binding]="'name'"
          [visible]="true"
          [width]="550"
          [isReadOnly]="true"
          [wordWrap]="true"
        >
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
          <a [routerLink]="['.']" (click)="downloadResourceFile(item.link, item.name, item.extension)">
          {{ item.name }}
          </a>          
        </ng-template>
      </wj-flex-grid-column>        
    </wj-flex-grid>
  </div>
</div>