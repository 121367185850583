<bento-alert [items]="alerts"></bento-alert>
<main id="MainContent" tabindex="-1" role="main" class="Main" [bentoBusyLoader]="isDataLoading" *ngIf="!isNoAccessUser">
  <header class="Main-header">
    <div class="row mb-1">
      <div class="col">
        <h1 id="pageHeader" tabindex="-1" class="Main-heading">Welcome</h1>
      </div>
      <div class="col">
        <div class="Actions justify-content-end">
          <div class="Actions-item">
            <app-action-dropdown
              #actionButton
              [actionDropdownItems]="actionDropdownItems"
              (dropDownSelectChange)="dropDownOnSelectChange($event)"
            ></app-action-dropdown>
          </div>
        </div>
      </div>
    </div>
    <p>Below is a default view with your metrics and key peer groups.</p>
  </header>
  <div *ngIf="isFirmBasicLoaded" class="Main-body Main-body--viewgrid">
    <app-view-data-grid
      #ViewGrid
      [userSettingsModel]="userSettingsModel"
      [firmBasicData]="firmBasicData"
      [pcViewCols]="pcViewCols"
      [viewDetails]="viewDetails"
      [shouldCardDisplay]="true"
      [shouldDisplayPivot]="true"
      [allowViewSettings]="true"
      [showDescription]="false"
      [shouldCheckMetricGroup]="false"
      [showCustomDescription]="true"
      [isTAdmin]="isTAdmin"
      (dataLoadingStateChange)="dataLoadingStateChange($event)"
      (dragDone)="onDragDone($event)"
    ></app-view-data-grid>
  </div>
</main>
