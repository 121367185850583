import {Inject, Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth/auth.service';
import {AuthService as Auth0Service} from '@auth0/auth0-angular';
import {BaseService} from '../services/base/base.service';
import {environment} from 'src/environments/environment';
import {DOCUMENT, Location} from '@angular/common';
import {featureToggle} from 'src/app/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class FeatureToggleGuard implements CanActivate {
  isFeatureEnable: boolean;
  route: string;
  constructor(
    private authService: AuthService,
    public auth0: Auth0Service,
    private service: BaseService,
    @Inject(DOCUMENT) private doc: Document,
    private router: Router,
    location: Location
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable<boolean>((obs) => {
      let path = next.routeConfig.path.split('/');
      this.service
        .get(environment.FIAdminBaseEndpoint + 'v1/feature/' + featureToggle[path[path.length - 1]], '')
        .subscribe((result) => {
          if (result[featureToggle[path[path.length - 1]]]) {
            obs.next(true);
          } else {
            this.router.navigate(['pageNotFound']);
            obs.next(false);
          }
        });
    });
  }
}
