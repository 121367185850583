export class TkHistoryRequest {
  page = 1;
  size: null | number = 10;
  fiscalPeriodFrom: null | any = null;
  fiscalPeriodTo: null | any = null;
  tkId: string[] = [];
  name: string[] = [];
  fteFrom: null | number = null;
  fteTo: null | number = null;
  peerOffice: string[] = [];
  firmOffice: string[] = [];
  department: string[] = [];
  section: string[] = [];
  udf: string[] = [];
  peerPractice: string[] = [];
  peerTitle: string[] = [];
  firmTitle: string[] = [];
  experienceYear: number[] = [];
  sortColumns: string[] = [];
  sortDir = 'asc';
}
