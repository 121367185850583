import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import RebuildHistoryModel from 'src/app/core/models/rebuild-history.model';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-rebuild-history',
  templateUrl: './rebuild-history.component.html',
  styleUrls: ['./rebuild-history.component.scss'],
})
export class RebuildHistoryComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal, private service: BaseService) {}

  public rebuildHistoryModel: RebuildHistoryModel = new RebuildHistoryModel();
  public firmId: any;
  errorMessage: any;
  isDataLoading: boolean;
  ngOnInit(): void {
    for (let i = 0; i < this.rebuildHistoryModel.selectedRecord.length; i++) {
      this.rebuildHistoryModel.selectedRecordName +=
        this.rebuildHistoryModel.selectedRecord[i].firstName +
        ' ' +
        this.rebuildHistoryModel.selectedRecord[i].lastName +
        '\n';
    }
  }

  onFiscalPeriodChange(event) {
    const fiscalPeriodObj = JSON.parse(event);
    this.rebuildHistoryModel.fiscalPeriodModel = fiscalPeriodObj;
  }

  onClickSave() {
    const tkIds = this.rebuildHistoryModel.selectedRecord.map((record) => record.tkId);

    const requestBody = {
      periodFrom: this.rebuildHistoryModel.fiscalPeriodModel.from_date,
      periodTo: this.rebuildHistoryModel.fiscalPeriodModel.to_date,
      tkIds: tkIds,
    };
    this.isDataLoading = true;
    this.service
      .put(environment.FIClientBaseEndpoint + 'v1/timekeeper/history/rebuild/' + this.firmId, requestBody)
      .subscribe(
        (data) => {
          this.isDataLoading = false;
          this.activeModal.close('Success');
        },
        (error) => {
          this.errorMessage = error.error;
          var msg = '';
          if (this.errorMessage.hasOwnProperty('message')) {
            msg = this.errorMessage.message;
          } else {
            msg = 'Something went wrong, please try again.';
          }
          this.isDataLoading = false;
          this.activeModal.close(msg);
        }
      );
  }
}
