export class StaffingRatioPeerGroupList {
  /*peerId: number;
  peerLabel: string;*/
  firmId: number;
  firmIds: any;
  isCustom: boolean; 
  isGlobal: boolean
  peerGroupId: any;
  peerGroupName: string;
  surveyYear: any;
  action: boolean;
}
