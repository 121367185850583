import {Component, OnInit, ViewChild, Output, EventEmitter, Input, TemplateRef} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {PerformancePeerGroupModel} from 'src/app/core/models/performancePeerGroup.model';
import {PerformancePeerGroupFirmModel} from 'src/app/core/models/performancePeerGroupFirm.model';
import {PerformancePeerGroupFirmPreviewModel} from 'src/app/core/models/performancePeerGroupFirmPreview.model';
import PracticePermissionModel from '../../../models/practice-permission.model';
import OfficePermissionModel from '../../../models/office-permission.model';
import PerformanceCriteriaListModel from '../../../models/performanceCriteria.model';
import {BackendTokenClaims} from '../../../models/tokenResponse';
import {AuthService} from '../../../services/auth/auth.service';
import {Role} from 'src/app/shared/enums';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcCore from '@grapecity/wijmo';
import CreateViewModel from 'src/app/core/models/create-view.model';
import {GlobalService} from 'src/app/core/services/global/global.service';


@Component({
  selector: 'app-create-performance-peer-group',
  templateUrl: './create-performance-peer-group.component.html',
  styleUrls: ['./create-performance-peer-group.component.scss'],
})
export class CreatePerformancePeerGroupComponent implements OnInit {
  @Input() isCanFirm: boolean;
  openCreateView = false;
  selectedCriteriaText = '';
  selectedOfficeText = '';
  selectedPGText = '';
  selectedItemName = '';
  firmCount: number;
  isFilterPCApplied = false;
  isFilterPGApplied = false;
  isFilterOfficesApplied = false;
  isPreview = false;
  alerts: BentoAlertItemOptions[] = [];
  page: any = 1;
  pageSize: any = 10;
  columnName = '';
  name = '';
  selectedItems: any[];
  firmId: number;
  isSaving = false;
  errorMessage: string;
  pcCount: number;
  parameterValues: {};
  resetParameterValues: {};
  hideNumberOfFirmAndPreviewButton: boolean = false;
  currentRecords: number;
  totalRecords = 0;
  colCount:any =0;
  listFirmsHead: any="List Of Firms";
  pcValidations: {};
  disablePreview = true;
  // eslint-disable-next-line max-len

  successMsg: string;
  //errorMessage: any;
  isGetDataLoading = false;

  performancePeerGroupData: PerformancePeerGroupModel = new PerformancePeerGroupModel();
  performancePeerGroupDataBody: PerformancePeerGroupModel = new PerformancePeerGroupModel();
  resetPerformancePeerGroupData: PerformancePeerGroupModel = new PerformancePeerGroupModel();
  performancePeerGroupFirms: PerformancePeerGroupFirmModel = new PerformancePeerGroupFirmModel();
  performanceCriteria: PerformanceCriteriaListModel[] = [];
  practiceGroups: PracticePermissionModel[] = [];
  offices: OfficePermissionModel[] = [];

  performanceCriteriaStream: BehaviorSubject<any>;
  practiceGroupStream: BehaviorSubject<any>;
  officeStream: BehaviorSubject<any>;
  parameterStream: BehaviorSubject<any>;

  parameters = ['Less than', 'In between', 'Greater than'];
  performancePeerGroupFirmsResult: PerformancePeerGroupFirmPreviewModel = new PerformancePeerGroupFirmPreviewModel();
  selectItems: number[] = [10, 20, 30, 40, 50];

  isAscending = false;
  sortDirection = '';
  isAscendingForId = false;

  min = '';
  max = '';

  currentInfo: any = {
    infoText: '_START_ to _END_ of _MAX_ Users',
    infoPageText: '_PAGE_ of _PAGES_',
    goText: 'Go',
    pageSize: 10,
  };

  lastPage = this.currentInfo.page;
  data: wjcCore.CollectionView;
  createViewModel: any;

  @Output() handleUserAction = new EventEmitter<string>();
  @Output() handleCancelPPG = new EventEmitter<boolean>();
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;

  constructor(private authService: AuthService, private service: BaseService, private route: ActivatedRoute, private globalservice: GlobalService) {}

  comboboxOptions = {
    searchable: true, // Make combobox searchable
  };

  ngOnInit(): void {
    this.data = new wjcCore.CollectionView();
    this.data.pageSize = this.currentInfo.pageSize;
    this.performancePeerGroupData.name = '';
    this.manageNumberOfFirmAndPreviewButton();
    this.setParameterValidations();
    this.getFirmID();
    this.getPerformanceCriteria();
  }

  getFirmID() {
    if (this.route.snapshot.params.id) {
      this.firmId = Number(this.route.snapshot.params.id);
    } else {
      const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
      this.firmId =
        backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.firmID
          ? backendTokenClaims.userDDO.firmID
          : 0;
    }
  }

  removePC(id) {
    this.performancePeerGroupData.criteria = this.performancePeerGroupData.criteria.filter(
      (item: any) => item.id !== id
    );
    this.onPCriteriasItemsSelected(this.performancePeerGroupData.criteria.map((x: any) => x.id));
    this.resetParam(id);
    this.getFirmsForPPG();
  }

  initializeDataStream() {
    this.performanceCriteriaStream = new BehaviorSubject(
      (() => {
        const a = [];
        for (var criteria of this.performanceCriteria) {
          a.push({name: criteria.description, id: criteria.metric});
        }
        return a;
      })()
    );
    this.practiceGroupStream = new BehaviorSubject(
      (() => {
        const a = [];
        for (var pGroup of this.practiceGroups) {
          a.push({name: pGroup.practiceName, id: pGroup.id});
        }
        return a;
      })()
    );
    this.officeStream = new BehaviorSubject(
      (() => {
        const a = [];
        for (var office of this.offices) {
          a.push({name: office.name, id: office.id});
        }
        return a;
      })()
    );
    this.parameterStream = new BehaviorSubject(
      (() => {
        const a = [];
        for (var param of this.parameters) {
          a.push(param);
        }
        return a;
      })()
    );
  }

  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }

  gridInitialized(flexGrid) {
    flexGrid.columnHeaders.rows.defaultSize = 40;
    this.currentRecords = this.data.itemCount;
    if (flexGrid) {
      flexGrid.cells.hostElement.setAttribute('aria-labelledby', 'performanceFirmsHeadName');
      this.colCount = wjcCore.Globalize.format(flexGrid.columns.length, 'n0');
    }
    this.flexGrid = flexGrid;
  }

  sortGrid(headerName: string) {
    if (headerName === 'name') {
      this.setOrderByName(headerName, this.isAscending);
    } else {
      this.setOrderById(headerName, this.isAscendingForId);
    }
  }

  setOrderById(value: string, isAscendingForId: boolean) {
    if (this.isAscendingForId !== true) {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
    this.isAscendingForId = !this.isAscendingForId;
    this.columnName = value;
  }

  setOrderByName(value: string, isAscending: boolean) {
    if (this.isAscending !== true) {
      this.sortDirection = 'asc';
    } else {
      this.sortDirection = 'desc';
    }
    this.isAscending = !this.isAscending;
    this.columnName = value;
  }

  getPerformanceCriteria() {
    this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/peergroup/performance/criteria/firm/' + this.firmId, '')
      .subscribe(
        (result) => {
          this.performanceCriteria = result;
          this.pcCount = result.length;
          this.getPerformancePGPractices();
        },
        (error) => {
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong. Please try again.',
            closeable: true,
          });
        }
      );
  }

  getPerformancePGPractices() {
    this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/peergroup/performance/practices/' + this.firmId, '')
      .subscribe(
        (result) => {
          this.practiceGroups = result;
          this.getPerformancePGOffices();
        },
        (error) => {
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong. Please try again.',
            closeable: true,
          });
        }
      );
  }

  getPerformancePGOffices() {
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/peergroup/performance/offices/' + this.firmId, '').subscribe(
      (result) => {
        this.offices = result;
        this.initializeDataStream();
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong. Please try again.',
          closeable: true,
        });
      }
    );
  }

  onPCriteriasItemsSelected(performanceCriteriaIds) {
    this.isFilterPCApplied = false;
    this.globalservice.skipFocusTwiceCombobox();
    if (performanceCriteriaIds.length == 0) {
      this.setParameterValidations();
      this.performancePeerGroupData.criteria = [];
      this.getFirmsForPPG();
    } else {
      if (performanceCriteriaIds.length == 1) {
        this.selectedCriteriaText = '1 selected';
      } else if (performanceCriteriaIds.length == this.pcCount) {
        this.selectedCriteriaText = 'All';
      } else this.selectedCriteriaText = performanceCriteriaIds.length.toString() + ' selected';

      let selectedCriteriaIds = [];
      if (this.performancePeerGroupFirms.criteria && performanceCriteriaIds[0].id) {
        selectedCriteriaIds = performanceCriteriaIds.map((el) => {
          return el.id;
        });
        var res = Object.keys(this.parameterValues).filter((x) => !selectedCriteriaIds.includes(x));
        if (res.length > 0)
          res.forEach((id) => {
            this.resetParam(id);
            this.getFirmsForPPG();
          });
      }
    }
    if (performanceCriteriaIds.length > 0) this.isFilterPCApplied = true;
  }

  onPracticeGroupItemsSelected(practiceGroupsIds: any[]) {
    this.isFilterPGApplied = false;
    if (practiceGroupsIds.length == 1) {
      this.selectedPGText = '1 selected';
    } else if (practiceGroupsIds.length == this.practiceGroups.length) {
      this.selectedPGText = 'All';
    } else this.selectedPGText = practiceGroupsIds.length.toString() + ' selected';
    if (practiceGroupsIds.length > 0) {
      this.isFilterPGApplied = true;
    }
    this.getFirmsForPPG();
  }

  onOfficeItemsSelected(officeIds: any[]) {
    this.isFilterOfficesApplied = false;
    if (officeIds.length == 1) {
      this.selectedOfficeText = '1 selected';
    } else if (officeIds.length == this.offices.length) {
      this.selectedOfficeText = 'All';
    } else this.selectedOfficeText = officeIds.length.toString() + ' selected';
    if (officeIds.length > 0) {
      this.isFilterOfficesApplied = true;
    }
    this.getFirmsForPPG();
  }

  getSelectedCriterias() {
    let criteria = [];
    let k = '';
    for (let item in this.parameterValues) {
      if (this.parameterValues[item].param) {
        k = '';
        switch (this.parameterValues[item].param) {
          case 'Less than': {
            if (this.parameterValues[item].value !== '') k = item + ' less than ' + this.parameterValues[item].value;
            //  k = item + ' less than ' + this.parameterValues[item].value;
            break;
          }
          case 'Greater than': {
            if (this.parameterValues[item].value !== '') k = item + ' greater than ' + this.parameterValues[item].value;
            break;
          }
          case 'In between': {
            if (this.parameterValues[item].minValue !== '' && this.parameterValues[item].maxValue !== '')
              k =
                item +
                ' between ' +
                this.parameterValues[item].minValue +
                ' and ' +
                this.parameterValues[item].maxValue;
            break;
          }
          default:
            break;
        }

        if (k != '') {
          let c = this.performanceCriteria.filter((element) => element.metric == item)[0];
          let metricCriteria = {metric: k, annualized: c.annualized};
          criteria.push(metricCriteria);
        }
      }
    }
    return criteria;
  }

  handleCancel() {
    this.openCreateView = true;
    this.handleCancelPPG.emit(this.openCreateView);
  }

  updatePPGData() {
    let practiceGroups = [];
    let offices = [];
    let criterias = [];
    if (this.performancePeerGroupData.practiceGroup) {
      this.performancePeerGroupData.practiceGroup.forEach((practiceGroup) => {
        let pg = this.practiceGroups.filter((item) => item.practiceName == practiceGroup.name)[0];
        practiceGroups.push(pg.id);
      });
    }

    if (this.performancePeerGroupData.offices) {
      this.performancePeerGroupData.offices.forEach((office) => {
        let off = this.offices.filter((item) => item.name == office.name)[0];
        offices.push(off.id);
      });
    }

    criterias = this.getSelectedCriterias();

    this.performancePeerGroupDataBody.practiceGroup = practiceGroups;
    this.performancePeerGroupDataBody.offices = offices;
    this.performancePeerGroupDataBody.criteria = criterias;
    this.performancePeerGroupDataBody.name = 'PPG - ' + this.performancePeerGroupData.name;
  }

  savePPG() {
    this.updatePPGData();
    this.isSaving = true;
    this.data = new wjcCore.CollectionView();
    this.isPreview = false;
    // this.totalRecords = 0;
    this.service
      .post(
        environment.FIAdminBaseEndpoint + 'v1/peergroup/performance/' + this.firmId,
        this.performancePeerGroupDataBody
      )
      .subscribe(
        (result) => {
          this.isSaving = false;
          let option = {message: 'Saved', selectedOption: {ppgName: 'PPG - ' + this.performancePeerGroupData.name}};
          this.handleUserAction.emit(JSON.stringify(option));
        },
        (error) => {
          if (error.status == 400) {
            if (error.error && error.error.messages) {
              let message = '';
              error.error.messages.forEach((element) => {
                message += '<div>' + element.fieldName + ': ' + element.message + '</div>';
              });
              this.errorMessage = message;
            } else {
              this.errorMessage = error.error.message;
            }
          } else {
            this.errorMessage = 'Something went wrong. Please try again.';
          }

          this.alerts.push({
            type: 'warning',
            msg: this.errorMessage,
            closeable: true,
          });
          this.isSaving = false;
        }
      );
  }

  updatePPGDataForFirms() {
    let practiceGroups = [];
    let offices = [];
    let criterias = [];
    criterias = this.getSelectedCriterias();
    if (this.performancePeerGroupData.practiceGroup) {
      this.performancePeerGroupData.practiceGroup.forEach((practiceGroup) => {
        let pg = this.practiceGroups.filter((item) => item.practiceName == practiceGroup.name)[0];
        practiceGroups.push(pg.id);
      });
    }

    if (this.performancePeerGroupData.offices) {
      this.performancePeerGroupData.offices.forEach((office) => {
        let off = this.offices.filter((item) => item.name == office.name)[0];
        offices.push(off.id);
      });
    }

    this.performancePeerGroupFirms.practiceGroup = practiceGroups;
    this.performancePeerGroupFirms.offices = offices;
    this.performancePeerGroupFirms.criteria = criterias;
  }

  onChangePCParam(criteria, event) {
    if (this.parameterValues[criteria].param == null) this.parameterValues[criteria].param = '';
    this.parameterValues[criteria].value = '';
    this.getFirmsForPPG();
  }

  onChangeParam(criteria, event) {
    this.data = new wjcCore.CollectionView();
    this.isPreview = false;

    this.max = this.pcValidations[criteria].max;
    this.min = this.pcValidations[criteria].min;

    if (this.parameterValues[criteria].param != 'In between') {
      if (
        Number(this.parameterValues[criteria].value) <= Number(this.max) &&
        Number(this.parameterValues[criteria].value) >= Number(this.min)
      )
        this.getFirmsForPPG();
      else if (this.parameterValues[criteria].value == null) this.getFirmsForPPG();
    } else if (this.parameterValues[criteria].minValue != '' && this.parameterValues[criteria].maxValue != '')
      if (
        Number(this.parameterValues[criteria].minValue) >= Number(this.min) &&
        Number(this.parameterValues[criteria].maxValue) <= Number(this.max)
      )
        this.getFirmsForPPG();
  }

  getFirmsForPPG() {
    this.updatePPGDataForFirms();
    this.isSaving = true;
    this.disablePreview = true;
    if (
      this.performancePeerGroupFirms.criteria.length > 0 ||
      this.performancePeerGroupFirms.practiceGroup.length ||
      this.performancePeerGroupFirms.offices.length
    ) {
      this.disablePreview = false;
      this.service
        .post(
          environment.FIAdminBaseEndpoint + 'v1/peergroup/performance/firms/' + this.firmId,
          this.performancePeerGroupFirms
        )
        .subscribe(
          (result) => {
            this.isSaving = false;
            this.isPreview = false;
            this.performancePeerGroupFirmsResult = result;
            this.firmCount = result.totalCount;
            this.data = new wjcCore.CollectionView(this.performancePeerGroupFirmsResult.firms);
            this.data.pageSize = this.currentInfo.pageSize;
            this.currentRecords = this.data.itemCount;
            this.totalRecords = this.data.totalItemCount;
            this.globalservice.addPaginationAdditionalInfo();
          },
          (error) => {
            this.isSaving = false;
            if (error.status == 400) {
              if (error.error && error.error.messages) {
                let message = '';
                error.error.messages.forEach((element) => {
                  message += '<div>' + element.fieldName + ': ' + element.message + '</div>';
                });
                this.errorMessage = message;
              } else {
                this.errorMessage = error.error.message;
              }
            } else {
              this.errorMessage = 'Something went wrong. Please try again.';
            }
            this.data = new wjcCore.CollectionView();
            this.firmCount = 0;
            this.isPreview = false;
            this.isSaving = false;
            this.totalRecords = 0;

            this.alerts.push({
              type: 'warning',
              msg: this.errorMessage,
              closeable: true,
            });
          }
        );
    } else {
      this.data = new wjcCore.CollectionView();
      this.firmCount = 0;
      this.isPreview = false;
      this.isSaving = false;
    }
  }

  previewPPG() {
    this.isPreview = true;
    this.isGetDataLoading = true;
    this.currentInfo.totalItems = this.performancePeerGroupFirmsResult.totalCount;
    this.globalservice.addPaginationAdditionalInfo();
    this.isGetDataLoading = false;
  }

  resetPPG() {
    this.isGetDataLoading = true;
    this.isFilterOfficesApplied = false;
    this.isFilterPCApplied = false;
    this.isFilterPGApplied = false;
    this.disablePreview = true;

    this.setParameterValidations();

    if (typeof this.resetPerformancePeerGroupData !== 'undefined') {
      this.performancePeerGroupData = JSON.parse(JSON.stringify(this.resetPerformancePeerGroupData));
      this.parameterValues;
    }

    this.data = new wjcCore.CollectionView();

    this.isPreview = false;
    this.successMsg = 'Data reset successfully.';
    this.alerts.push({
      type: 'success',
      msg: this.successMsg,
      timeout: 2500,
      closeable: true,
    });
    this.isGetDataLoading = false;
  }

  // Pagination
  onPageChanged(page) {
    this.data.moveToPage(page - 1);
    this.currentRecords = this.data.itemCount;
  }

  onItemsPerPageChanged(itemsPerPage) {
    this.data.pageSize = itemsPerPage;
    this.currentRecords = this.data.itemCount;
  }

  resetParam(id) {
    this.parameterValues[id].param = '';
    this.parameterValues[id].value = '';
    this.parameterValues[id].minValue = '';
    this.parameterValues[id].maxValue = '';
  }

  setParameterValidations() {
    this.parameterValues = {
      number_of_lawyers: {
        param: '',
        value: '',
        minValue: '',
        maxValue: '',
        range: {min: '0', max: '5000'},
        placeholder: '',
      },
      revenue_per_lawyer: {
        param: '',
        value: '',
        minValue: '',
        maxValue: '',
        range: {min: '$0', max: '$2,000,000'},
        placeholder: '$',
      },
      profit_per_lawyer: {
        param: '',
        value: '',
        minValue: '',
        maxValue: '',
        range: {min: '$0', max: '$4,000,000'},
        placeholder: '$',
      },
      profit_per_partner: {
        param: '',
        value: '',
        minValue: '',
        maxValue: '',
        range: {min: '$0', max: '$4,000,000'},
        placeholder: '$',
      },
      standard_rate: {
        param: '',
        value: '',
        minValue: '',
        maxValue: '',
        range: {min: '$50', max: '$2000'},
        placeholder: '$',
      },
      worked_rate: {
        param: '',
        value: '',
        minValue: '',
        maxValue: '',
        range: {min: '$50', max: '$2000'},
        placeholder: '$',
      },
      coll_real: {param: '', value: '', minValue: '', maxValue: '', range: {min: '25%', max: '110%'}, placeholder: '%'},
    };

    this.pcValidations = {
      number_of_lawyers: {
        min: 0,
        max: 5000,
        msg: {pattern: 'Error: Value should be between 0 to 5000'},
        pattern: '(([0-9])|([0-9]{0,3})|([0-4][0-9]{3})|([5][0]{3}))',
      },
      revenue_per_lawyer: {
        min: 0,
        max: 2000000,
        msg: {pattern: 'Error: Value should be between 0 to 2000000'},
        pattern: '(([0-9])|([0-9]{0,6})|([0-1][0-9]{6})|([2][0]{6}))',
      },
      profit_per_lawyer: {
        min: 0,
        max: 4000000,
        msg: {pattern: 'Error: Value should be between 0 to 4000000'},
        pattern: '(([0-9])|([0-9]{0,6})|([0-3][0-9]{6})|([4][0]{6}))',
      },
      profit_per_partner: {
        min: 0,
        max: 4000000,
        msg: {pattern: 'Error: Value should be between 0 to 4000000'},
        pattern: '(([0-9])|([0-9]{0,6})|([0-3][0-9]{6})|([4][0]{6}))',
      },
      standard_rate: {
        min: 50,
        max: 2000,
        msg: {pattern: 'Error: Value should be between 50 to 2000'},
        pattern: '((5[0-9][0-9]{0,1})|([0-4][0-9]{2})|([6-9][0-9]{1,2})|([0-1][0-9]{3})|([2][0]{3}))',
      },
      worked_rate: {
        min: 50,
        max: 2000,
        msg: {pattern: 'Error: Value should be between 50 to 2000'},
        pattern: '((5[0-9][0-9]{0,1})|([0-4][0-9]{2})|([6-9][0-9]{1,2})|([0-1][0-9]{3})|([2][0]{3}))',
      },
      coll_real: {
        min: 25,
        max: 110,
        msg: {pattern: 'Error: Value should be between 25 to 110'},
        pattern: '((2[5-9])|([3-9][0-9])|([1][0][0-9])|(110))',
      },
    };
  }
  manageNumberOfFirmAndPreviewButton() {
    if (this.isCanFirm && !this.isTAdmin) {
      this.hideNumberOfFirmAndPreviewButton = true;
    } else {
      this.hideNumberOfFirmAndPreviewButton = false;
    }
  }
}
