import {DOCUMENT, ɵgetDOM as getDOM} from '@angular/common';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import {ɵSharedStylesHost} from '@angular/platform-browser';

@Injectable()
export class CustomDomSharedStylesHost extends ɵSharedStylesHost implements OnDestroy {
  // Maps all registered host nodes to a list of style nodes that have been added to the host node.
  private _hostNodes = new Map<Node, Node[]>();
  private _nonce: string | null | undefined = null;

  constructor(
    @Inject(DOCUMENT) private _doc: any,
    @Inject('cspMetaSelector')
    private _metaCSPTag: string
  ) {
    super();
    this._hostNodes.set(_doc.head, []);
    this._setCSPNonce();
  }

  private _addStylesToHost(style: string, host: Node, styleNodes: Node[]): void {
    const styleEl = this._doc.createElement('style');
    styleEl.textContent = style;
    styleEl.setAttribute('nonce', 'cssRand0m'); // Add nonce
    styleNodes.push(host.appendChild(styleEl));
  }

  private _setCSPNonce(): void {
    this._nonce = document.querySelector(this._metaCSPTag)?.getAttribute('content');
  }

  private _removeCSPNonceHeader(): void {
    document.querySelector(this._metaCSPTag)?.remove();
  }

  addHost(hostNode: Node): void {
    const styleNodes: Node[] = [];
    this._hostNodes.set(hostNode, styleNodes);
  }

  removeHost(hostNode: Node): void {
    const styleNodes = this._hostNodes.get(hostNode);
    if (styleNodes) {
      styleNodes.forEach(removeStyle);
    }
    this._hostNodes.delete(hostNode);
  }

  onStyleAdded(additions: any): void {
    this._hostNodes.forEach((styleNodes, hostNode) => {
      this._addStylesToHost(additions, hostNode, styleNodes);
    });
  }
  ngOnDestroy(): void {
    this._hostNodes.forEach((styleNodes) => styleNodes.forEach(removeStyle));
  }
}

function removeStyle(styleNode: Node): void {
  getDOM().remove(styleNode);
}
