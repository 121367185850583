import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-timekeepers-edit',
  templateUrl: './timekeepers-edit.component.html',
})
export class TimekeepersEditComponent implements OnInit {
  types = [];
  selectedOptions = [];
  currentSelection: any;

  constructor() {}

  ngOnInit(): void {
    this.types = [
      {ext: '.csv', name: 'Comma Delimited'},
      {ext: '.doc', name: 'MS Word'},
      {ext: '.pdf', name: 'Adobe PDF'},
      {ext: '.txt', name: 'Plain Text'},
      {ext: '.xls', name: 'MS Excel'},
    ];

    this.selectedOptions = [
      {
        id: 'mapAccount',
        selectedOption: [
          {ext: '.csv', name: 'Comma Delimited'},
          {ext: '.txt', name: 'Plain Text'},
          {ext: '.xls', name: 'MS Excel'},
        ],
      },
    ];
  }

  saveCurrentSelection(selection: any) {}

  updateFiscalDateRange(selection) {
    var data = JSON.parse(selection);
    var option = {
      id: 'fiscalDateRange',
      selectedOption: data,
    };
    if (!this.selectedOptions.some((item) => item.id == 'fiscalDateRange')) {
      this.selectedOptions.push(option);
    } else if (this.selectedOptions.indexOf(option)) {
      this.selectedOptions.splice(this.selectedOptions.indexOf(option), 1, option);
    }
  }
}
