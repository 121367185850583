import {Component, Input} from '@angular/core';
import CloudCredential3E from '../../../../models/3e-cloud-credential.model';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {environment} from 'src/environments/environment';
import {BaseService} from 'src/app/core/services/base/base.service';
import {EncryptionDecryptionService} from 'src/app/core/services/encryption-decryption/encryption-decryption.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-client-credential',
  templateUrl: './client-credential.component.html',
  styleUrls: ['./client-credential.component.scss'],
})
export class ClientCredentialComponent {
  is3EUpdated = false;
  cloudCredential3E: CloudCredential3E = new CloudCredential3E();
  originalCloudCredential3E: CloudCredential3E;
  alerts: BentoAlertItemOptions[] = [];
  errorMessage = '';
  @Input() firmId: number;

  constructor(
    private encryptionService: EncryptionDecryptionService,
    private service: BaseService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.is3EUpdated = true;
    await this.get3ECloudCredential();
    this.originalCloudCredential3E = Object.assign({}, this.cloudCredential3E);
    this.is3EUpdated = false;
  }

  // private get firmId(): number {
  //   if (this.route.snapshot.params.id) {
  //     return Number(this.route.snapshot.params.id);
  //   } else {
  //     const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
  //     return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.firmID
  //     ? backendTokenClaims.userDDO.firmID
  //     : 0;
  //   }
  // }
  resetInformation() {
    this.cloudCredential3E = Object.assign({}, this.originalCloudCredential3E);
    this.alerts.push({
      type: 'success',
      msg: 'Data reset successfully.',
      timeout: 2500,
      closeable: true,
    });
  }
  save3ECloudCredential() {
    this.is3EUpdated = true;
    let values =
      this.cloudCredential3E.tenantId +
      ',' +
      this.cloudCredential3E.clientId +
      ',' +
      this.cloudCredential3E.clientSecret +
      ',' +
      this.cloudCredential3E.grantType +
      ',' +
      this.cloudCredential3E.scope +
      ',' +
      this.cloudCredential3E.instanceId;
    // Encrypt
    let ciphertext = this.encryptionService.encrypt(values);
    console.log(ciphertext);
    this.service
      .post(environment.FIClientBaseEndpoint + 'v1/key/firm/' + this.firmId + '/secretkeytype/3e-cloud-secret-value', {
        value: ciphertext,
      })
      .subscribe(
        (result) => {
          console.log(result);
          this.originalCloudCredential3E = Object.assign({}, this.cloudCredential3E);
          this.is3EUpdated = false;
          this.alerts.push({
            type: 'success',
            msg: 'Data saved successfully.',
            timeout: 2500,
            closeable: true,
          });
        },

        (error) => {
          this.is3EUpdated = false;
          this.errorMessage = error.error.message;
          if (this.errorMessage != 'Error while retrieving the values') {
            this.alerts.push({
              type: 'warning',
              msg: 'Something went wrong, please try again.',
              closeable: true,
            });
          }
        }
      );
  }

  get3ECloudCredential() {
    return this.service
      .get(environment.FIClientBaseEndpoint + 'v1/key/firm/' + this.firmId + '/secretkeytype/3e-cloud-secret-value', '')
      .toPromise()
      .then(
        (result) => {
          let values = '';
          console.log(result);
          values = this.encryptionService.decrypt(result.value);
          this.getCredentialObject(values);
          console.log(values);
        },
        (error) => {
          this.errorMessage = error.error.message;
          if (this.errorMessage != 'Error while retrieving the values') {
            this.alerts.push({
              type: 'warning',
              msg: 'Something went wrong, please try again.',
              closeable: true,
            });
          }
        }
      );
  }
  getCredentialObject(result) {
    console.log(result);
    let toArray = result.split(',');
    if (toArray.length == 6) {
      this.cloudCredential3E.tenantId = toArray[0];
      this.cloudCredential3E.clientId = toArray[1];
      this.cloudCredential3E.clientSecret = toArray[2];
      this.cloudCredential3E.grantType = toArray[3];
      this.cloudCredential3E.scope = toArray[4];
      this.cloudCredential3E.instanceId = toArray[5];
    }
    
  }
}
