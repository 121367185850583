<bento-alert [items]="alerts" (close)="closeAlert($event)"></bento-alert>
<div class="GlobalLayout">
  <main id="MainContent" tabindex="-1" class="Main Main--firmSettings" [bentoBusyLoader]="isGetDataLoading">
    <header class="Main-header">
      <div class="row">
        <div class="col">
          <h1 class="Main-heading">Firms</h1>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <app-grid-record-details
            [currentRecords]="currentRecords"
            [totalRecords]="totalRecords"
          ></app-grid-record-details>
        </div>
        <div class="col">
          <div class="Actions justify-content-end">
            <div *ngIf="!isMappings" class="Actions-item Actions-item--large">
              <button type="button" class="btn btn-outline-secondary" [routerLink]="['../addfirm']">
                <span class="btn-text">Add firm</span>
              </button>
            </div>
            <div class="Actions-item Actions-item--large">
              <span class="bento-search">
                <label for="firmSearch" class="sr-only">Search firm</label>
                <input type="text" class="form-control" name="firmSearch" id="firmSearch" [(ngModel)]="searchText" />
                <button type="button" class="btn btn-primary" (click)="searchFirms()">
                  <span class="btn-text">Search</span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="Main-body">
      <wj-flex-grid
        #flexGrid
        class="DataGrid"
        [itemsSource]="firmSettingModel.firms"
        [headersVisibility]="'Column'"
        [autoClipboard]="false"
        [isReadOnly]="true"
        [allowDragging]="false"
        [autoRowHeights]="true"
        (initialized)="gridInitialized(flexGrid)"
        (sortingColumn)="sortGrid($event.getColumn().binding)"
      >
        <wj-flex-grid-column [header]="'Firm ID'" [binding]="'id'" [align]="'left'" [width]="120" [wordWrap]="true">
          <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
            Firm ID
            <span class="wj-glyph-up" *ngIf="isFirmIdAscending"></span>
            <span class="wj-glyph-down" *ngIf="isFirmIdAscending === null ? false : !isFirmIdAscending"></span>
          </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-column
          [header]="'Firm name'"
          [binding]="'name'"
          [width]="'*'"
          [minWidth]="240"
          [allowResizing]="false"
          [wordWrap]="true"
        >
          <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
            Firm name
            <span class="wj-glyph-up" *ngIf="isFirmNameAscending"></span>
            <span class="wj-glyph-down" *ngIf="isFirmNameAscending === null ? false : !isFirmNameAscending"></span>
          </ng-template>
          <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
            <a [routerLink]="['./', item.id]">{{ item.name }}</a>
          </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-column
          [header]="'Pending Peer group'"
          [binding]="'pendingPeerGroupCount'"
          [width]="300"
          [allowResizing]="false"
          [align]="'left'"
          [wordWrap]="true"
        >
          <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'">
            Pending Peer group
            <span class="wj-glyph-up" *ngIf="isPendingAscending"></span>
            <span class="wj-glyph-down" *ngIf="isPendingAscending === null ? false : !isPendingAscending"></span>
          </ng-template>
          <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
            <span *ngIf="!item.pendingPeerGroupCount">--</span>
            <a
              *ngIf="item.pendingPeerGroupCount"
              [routerLink]="['./', item.id]"
              (click)="navigateToPendingPeerGroup()"
              >{{ item.pendingPeerGroupCount + ' Pending peer groups' }}</a
            >
          </ng-template>
        </wj-flex-grid-column>
      </wj-flex-grid>
      <bento-pagination
        [(page)]="currentInfo.page"
        (pageChange)="onPageChanged($event)"
        [directionLinks]="true"
        [boundaryLinks]="true"
        [totalItems]="currentInfo.totalItems"
        [itemsArray]="selectItems"
        (itemsPerPageChange)="onItemsPerPageChanged($event)"
        [(itemsPerPage)]="currentInfo.numItems"
      ></bento-pagination>
    </div>
  </main>
</div>
