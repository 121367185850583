import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-grid-record-details',
  templateUrl: './grid-record-details.component.html',
})
export class GridRecordDetailsComponent implements OnInit {
  @Input() currentRecords: number;
  @Input() totalRecords: number;
  constructor() {}

  ngOnInit(): void {}
}
