import {Inject, Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth/auth.service';
import {AuthService as Auth0Service} from '@auth0/auth0-angular';
import {BaseService} from '../services/base/base.service';
import {environment} from 'src/environments/environment';
import {DOCUMENT} from '@angular/common';
import {IExceptionTelemetry} from '@microsoft/applicationinsights-web';
import {LoggingService} from '../services/app-logging/logging.service';
import jwtDecode from 'jwt-decode';
import {BackendTokenClaims} from '../models/tokenResponse';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    public auth0: Auth0Service,
    private service: BaseService,
    @Inject(DOCUMENT) private doc: Document,
    private router: Router,
    private loggingServices: LoggingService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable<boolean>((obs) => {
      if (this.authService.isUserAuthenticated()) {
        obs.next(this.authService.isUserAuthorised(next));
      } else {
        this.loggingServices.logTrace('Calling CIAM API, URL: ' + environment.appInsights.ciamUrl);
        this.auth0.getAccessTokenSilently().subscribe((token: string) => {
          this.authService.ciamToken.next(token);
          const tokenObj = jwtDecode(token);
          this.loggingServices.logTrace('Response from CIAM API', {
            url: environment.appInsights.ciamUrl,
            ciamToken: token,
            userGuid: tokenObj['https://tr.com/euid'],
          });
          this.service
            .post(environment.FIAdminBaseEndpoint + 'v1/user/authenticate-ciam', null, tokenObj['https://tr.com/euid'])
            .subscribe(
              (result) => {
                const {jwt} = result;
                this.loggingServices.logTrace('After calling authenticate-ciam', {
                  url: environment.appInsights.ciamUrl,
                  ciamToken: token,
                  serviceJwt: jwt,
                  userGuid: tokenObj['https://tr.com/euid'],
                });
                this.authService.backendJWTToken.next(jwt);
                obs.next(this.authService.isUserAuthorised(next));
              },
              (error) => {
                obs.next(false);
              }
            );
        });

        this.auth0.error$.subscribe((error: any) => {
          if (error && error.error === 'login_required') {
            this.auth0.loginWithRedirect({
              redirect_uri: `${window.location.origin}`,
              appState: {target: state.url},
            });
            obs.next(false);
          } else {
            this.loggingServices.logException(new Error(error), {
              url: environment.appInsights.ciamUrl,
              message: 'API call CIAM token API failed',
              exception: JSON.stringify(error),
              severityLevel: 1,
            });
          }
        });
      }
    });
  }
}
