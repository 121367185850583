import {Component, EventEmitter, Input, Output, ViewChild, OnInit} from '@angular/core';
import {BentoAlertItemOptions, BentoTransferboxBasicColumnDefinitions} from '@bento/bento-ng';
import {eventDispatcher, store} from 'src/app/core/store';
import {ActionTypes} from 'src/app/core/store/actions';
import UserModel from 'src/app/core/models/user.model';
import {environment} from 'src/environments/environment';
import {BaseService} from 'src/app/core/services/base/base.service';

@Component({
  selector: 'app-title-permission',
  templateUrl: './title-permission.component.html',
})
export class TitlePermissionComponent implements OnInit {
  @ViewChild('transferbox', {static: true}) transferbox;
  dataOne: any[];
  dataTwo: any[];
  gridOptions: BentoTransferboxBasicColumnDefinitions[] = [];
  gridHeight = 530;
  hidePagination = false;
  userData: UserModel = new UserModel();
  clearUserData: UserModel = new UserModel();

  @Input() firmId: number;
  @Input() selectedUserData: any;
  @Output() handleUserAction = new EventEmitter<string>();
  isGetDataloading = false;
  isInformationLoading = false;
  errorMessage = '';
  alerts: BentoAlertItemOptions[] = [];

  constructor(private service: BaseService) {}

  ngOnInit(): void {
    this._initLeftSide();
    this.gridOptions = [
      {
        header: 'All titles',
        binding: 'title',
      },
    ];
  }
  onTransfer($event) {}

  private _initLeftSide() {
    this.isGetDataloading = false;
    this.isInformationLoading = true;
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/firm/' + this.firmId + '/title', '').subscribe(
      (result) => {
        this.isGetDataloading = true;
        this.isInformationLoading = false;
        this.dataOne = result;
        this._initRightSide();
      },
      (error) => {
        this.isGetDataloading = true;
        this.isInformationLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
  private _initRightSide() {
    this.isGetDataloading = false;
    this.isInformationLoading = true;
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/firm/' + this.firmId + '/allTitle', '').subscribe(
      (result) => {
        this.isGetDataloading = true;
        this.isInformationLoading = false;
        this.dataTwo = result;
      },
      (error) => {
        this.isGetDataloading = true;
        this.isInformationLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  saveMetricInfo() {}
  closeMetricInfo() {
    if (typeof this.clearUserData !== 'undefined') {
      this.userData = JSON.parse(JSON.stringify(this.clearUserData));
    }
    eventDispatcher.next({type: ActionTypes.SetUserDetail, payload: this.userData});
    let option = {message: 'Return', selectedOption: null};
    this.handleUserAction.emit(JSON.stringify(option));
  }
}
