<div [bentoBusyLoader]="isLoading" *ngIf="isLoading; else elseBlock"></div>
<ng-template #elseBlock>
  <app-global-header></app-global-header>
  <main id="MainContent" tabindex="-1" class="Main Main--zeroState">
    <figure class="ZeroState">
      <app-svg-question class="ZeroState-header"></app-svg-question>
      <figcaption class="ZeroState-body">
        <h1 class="h3">404 - Page not found</h1>
        <p>We can't seem to find a page with that URL. It is also possible that the page was removed.</p>
        <p>Try double-checking your URL or go to <a [routerLink]="['/']">Home</a>.</p>
        <div class="ZeroState-actions">
          <p>Here are some links you may find helpful:</p>
          <!-- TODO: Add URLS to links -->
          <ul class="ZeroState-list">
            <li class="ZeroState-item">
              <a href="mailto:financialinsights@thomsonreuters.com">Contact support</a>
            </li>
            <!-- <li class="ZeroState-item">
              <a href="#">Help documentation</a>
            </li> -->
          </ul>
        </div>
      </figcaption>
    </figure>
  </main>
</ng-template>
