import {Component, EventEmitter, Input, Output, OnInit, OnDestroy} from '@angular/core';
import {BentoSideNavItem} from '@bento/bento-ng';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import CreateViewModel from 'src/app/core/models/create-view.model';

@Component({
  selector: 'app-performance-peer-groups',
  templateUrl: './performance-peer-groups.component.html',
})
export class PerformancePeerGroupsComponent implements OnInit {
  @Input() createViewsModel: CreateViewModel;
  @Input() isCanFirm : boolean;
  isPPGSaved = false;
  isPPGDeleted = false;
  alerts: BentoAlertItemOptions[] = [];
  successMsg = '';

  items: BentoSideNavItem[] = [
    {
      label: 'Create',
      selected: true,
      action: () => {
        this.showHideUserTabSideNav('Create');
      },
      data: {isVisible: true},
    },
    {
      label: 'Current groups',
      action: () => {
        this.showHideUserTabSideNav('Current groups');
      },
      data: {isVisible: false},
    },
  ];

  @Output() handleCancelPPG = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  usersActionHandler(message: string) {
    let response = JSON.parse(message);
    if (response.message == 'Saved') {
      this.showHideUserTabSideNav('Current groups');
      this.isPPGSaved = true;
      this.successMsg = '"' + response.selectedOption.ppgName + '" performance peer group was saved successfully.';
      this.alerts.push({
        type: 'success',
        msg: this.successMsg,
        timeout: 2500,
        closeable: true,
      });
    }
  }

  handleUserActionCancelPPG(isPPGOpen) {
    this.handleCancelPPG.emit(isPPGOpen);
  }

  handleUserActionDeletePPG(isPPGDeleted) {
    this.isPPGDeleted = isPPGDeleted;
  }

  showHideUserTabSideNav(label: string) {
    this.items.forEach((element: BentoSideNavItem) => {
      if (element.label === label) {
        element.selected = true;
        element.data.isVisible = true;
      } else {
        element.selected = false;
        element.data.isVisible = false;
      }
    });
  }
}
