import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import FirmBasicsModel from '../../../models/firm-basics.model';
import {PeerCheckServiceService} from '../peer-check/peer-check-service.service';
import PeerCheckViewColumns from '../../../models/peerCheckViewColumns.model';
import {ExportModalComponent} from '../export-modal/export-modal.component';
import {environment} from 'src/environments/environment';
import {BaseService} from 'src/app/core/services/base/base.service';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import CreateViewModel, {
  DescriptionSummary,
  FixedTimeRange,
  ProcessedBaseTableData,
  ProcessedMainData,
  RollingTimeRange,
  ViewDetails,
  ViewsOverlayObject,
  ViewsSettings,
} from 'src/app/core/models/create-view.model';
import {PeercheckMessageHandlerService} from 'src/app/core/services/peercheck-message-handler/peercheck-message-handler.service';
import {PivotRequestBody} from 'src/app/core/models/pivotRequestBody.model';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {Subscription} from 'rxjs';
import {ViewDataGridComponent} from '../view-grid/view-data-grid/view-data-grid.component';
import {ShareViewModelComponent} from '../current-views/share-view-model/share-view-model.component';
import {eventDispatcher, store} from 'src/app/core/store';
import {ActionTypes} from 'src/app/core/store/actions';
import {Role} from 'src/app/shared/enums';

@Component({
  selector: 'app-current-view-details',
  templateUrl: './current-view-details.component.html',
  providers: [PeerCheckServiceService],
})
export class CurrentViewDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('actionButton') actionButton: ElementRef;
  @ViewChild('ViewGrid') viewGridComponent: ViewDataGridComponent;
  yoyHeader: string;
  actionDropdownItems: {name: string; isDisabled: boolean}[];
  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }
  @Output() backToCurrentViewAction = new EventEmitter();
  @Input() selectedViewData: any;

  alerts: BentoAlertItemOptions[] = [];
  errorMessage: any;
  viewDetails: ViewDetails;
  isSharedView: boolean;
  subscription: Subscription[];
  summary: DescriptionSummary;
  isDataLoading: boolean;
  firmBasicData = new FirmBasicsModel();
  pcViewCols = new PeerCheckViewColumns();
  metricBasicData = {};
  firmRules = [];
  quartile = true;
  mean = true;
  median = true;
  stdDev = true;
  hilo = true;
  defaultCol = '';
  offGroups = [];

  expandData: any;
  expandDataRaw: any;
  isGetBasicAPICalled = false;
  isDisplayGrid = false;

  // Added for Main Grid
  processedMainGridData: ProcessedMainData;
  processedBaseTableData: ProcessedBaseTableData;
  userSettingsModel: CreateViewModel;
  requestBodyForPivot = new PivotRequestBody();

  constructor(
    private modalService: NgbModal,
    public _service: PeerCheckServiceService,
    private service: BaseService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private peercheckMessageHandlerService: PeercheckMessageHandlerService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    if (this.subscription && this.subscription.length) {
      this.subscription.forEach((subsc) => {
        subsc.unsubscribe();
      });
    }
  }

  async ngOnInit() {
    this.actionDropdownItems = [
      {name: 'Export', isDisabled: false},
      {name: 'Share', isDisabled: false},
      {name: 'Edit/Copy', isDisabled: false},
    ];
    this.yoyHeader = 'YOY growth';
    this.getBasicDetails();
  }

  async getBasicDetails() {
    //Service will return getBasic details and firm level rules
    this.subscription = [];
    let dataLoader = this._service.isDataLoadingMessage.subscribe((isDataProcessing: boolean) => {
      this.isDataLoading = isDataProcessing;
    });
    this.subscription.push(dataLoader);
    this.firmBasicData = await this._service.getFirmBasicsData(this.getDefaultOrSelectedFirmId);
    this.pcViewCols = this.firmBasicData.firmLevelRules;
    this.isGetBasicAPICalled = true;
    this.initializeValues();
    //this.isDataLoading = false;
  }

  initializeValues() {
    this.userSettingsModel = new CreateViewModel();
    if (this.selectedViewData && this.selectedViewData.viewId) {
      this.getViewDetails();
    }
    this.isDataLoading = true;
    this.isSharedView = this.peercheckMessageHandlerService.getrequestFromSharedViews;
  }

  dropDownOnSelectChange(name) {
    if (name === 'Export') {
      this.exportView();
    } else if (name === 'Settings') {
      if (this.viewGridComponent) this.viewGridComponent.openViewSettings();
    } else if (name === 'Edit/Copy') {
      if (this.viewDetails && this.viewDetails.viewId) {
        this.peercheckMessageHandlerService.setIsCopyEditRequest = true;
        this.peercheckMessageHandlerService.setCreateViewUserSettings = this.userSettingsModel;
        this.peercheckMessageHandlerService.setSelectedViewId = this.viewDetails.viewId;
        this.peercheckMessageHandlerService.setViewDetails = this.viewDetails;
        if (!this.isSharedView) {
          this.peercheckMessageHandlerService.setSelectedViewData = this.selectedViewData;
        }
        this.router.navigate(['../create-view'], {relativeTo: this.route});
      } else {
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    } else if (name === 'Share') {
      this.shareView();
    }
  }

  backToCurrentView(): void {
    this.backToCurrentViewAction.emit();
  }

  editView(requestPayload) {
    this.isDataLoading = true;
    this.service
      .put(
        environment.FIAdminBaseEndpoint +
          'v1/view/viewTemplate/' +
          this.viewDetails.viewTemplateId +
          '/firm/' +
          this.getDefaultOrSelectedFirmId,
        requestPayload
      )
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          //this.activeModal.close('Success');
          this.service
            .get(
              environment.FIAdminBaseEndpoint +
                'v1/view/' +
                this.selectedViewData.viewId +
                '/firm/' +
                this.getDefaultOrSelectedFirmId +
                '/details',
              ''
            )
            .subscribe(
              (result) => {
                // this.isDataLoading = false;
                this.viewDetails = result;
                this.userSettingsModel = this.peercheckMessageHandlerService.processSelectedViewDetails(
                  this.viewDetails,
                  this.userSettingsModel,
                  this.firmBasicData.yyyymm
                );
                this.isDisplayGrid = true;
              },
              (error) => {
                this.isDataLoading = false;
                this.errorMessage = error.error;
                this.alerts.push({
                  type: 'warning',
                  msg: 'Something went wrong, please try again.',
                  closeable: true,
                });
              }
            );
        },
        (error) => {
          this.errorMessage = error.error;
          let errMsg = this.peercheckMessageHandlerService.getServiceErrorMessageString(this.errorMessage);
          this.alerts.push({
            type: 'warning',
            msg: errMsg,
            closeable: true,
          });
          this.isDataLoading = false;
        }
      );
  }

  onDragDone(indexObj) {
    const {dragIndex, dropIndex, metricIdArr} = indexObj;

    let requestBody = this.createRequestBodyForSaveView();
    requestBody['name'] = this.viewDetails.viewName;
    requestBody['isDefault'] = this.viewDetails.default;
    // let item = requestBody['metrics'][dragIndex];
    // requestBody['metrics'].splice(dragIndex, 1);
    // requestBody['metrics'].splice(dropIndex, 0, item);
    requestBody['metrics'] = metricIdArr;
    //need to ask sumita about users and defaultForAll key
    requestBody['users'] = [];
    requestBody['isDefaultforAll'] = false;

    this.editView(requestBody);
  }

  createRequestBodyForSaveView() {
    let requestbody = {};
    if (this.userSettingsModel.timeRangeSelected == 'Rolling' && this.userSettingsModel.rollingTimeRangeSelectedItems) {
      let stDateAry = this.userSettingsModel.rollingTimeRangeSelectedItems.startPeriod1
        .replace(/(.{4})/g, '$1-')
        .split('-');
      let edDateAry = this.userSettingsModel.rollingTimeRangeSelectedItems.endPeriod1
        .replace(/(.{4})/g, '$1-')
        .split('-');

      let firstDay_startDate = new Date();
      let lastDay_endDate = new Date();

      firstDay_startDate.setFullYear(Number(stDateAry[0]));
      firstDay_startDate.setDate(1);
      firstDay_startDate.setMonth(Number(stDateAry[1]) - 1);

      lastDay_endDate.setDate(1);
      lastDay_endDate.setFullYear(Number(edDateAry[0]));
      lastDay_endDate.setMonth(Number(edDateAry[1]));
      lastDay_endDate.setDate(0);

      requestbody['rollingPeriodId'] = this.userSettingsModel.rollingTimeRangeSelectedItems.id;
      requestbody['startPeriod'] =
        firstDay_startDate.getFullYear() +
        '-' +
        ('0' + (firstDay_startDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + firstDay_startDate.getDate()).toString().slice(-2);
      requestbody['endPeriod'] =
        lastDay_endDate.getFullYear() +
        '-' +
        ('0' + (lastDay_endDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + lastDay_endDate.getDate()).toString().slice(-2);
    } else if (
      this.userSettingsModel.timeRangeSelected == 'Fixed' &&
      this.userSettingsModel.fixedTimeRange &&
      this.userSettingsModel.fixedTimeRange.startPeriod1 &&
      this.userSettingsModel.fixedTimeRange.endPeriod1
    ) {
      let stDateAry = this.userSettingsModel.fixedTimeRange.startPeriod1.replace(/(.{4})/g, '$1-').split('-');
      let edDateAry = this.userSettingsModel.fixedTimeRange.endPeriod1.replace(/(.{4})/g, '$1-').split('-');

      let firstDay_startDate = new Date();
      let lastDay_endDate = new Date();

      firstDay_startDate.setFullYear(Number(stDateAry[0]));
      firstDay_startDate.setDate(1);
      firstDay_startDate.setMonth(Number(stDateAry[1]) - 1);

      lastDay_endDate.setDate(1);
      lastDay_endDate.setFullYear(Number(edDateAry[0]));
      lastDay_endDate.setMonth(Number(edDateAry[1]));
      lastDay_endDate.setDate(0);

      requestbody['rollingPeriodId'] = 9;
      requestbody['startPeriod'] =
        firstDay_startDate.getFullYear() +
        '-' +
        ('0' + (firstDay_startDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + firstDay_startDate.getDate()).toString().slice(-2);
      requestbody['endPeriod'] =
        lastDay_endDate.getFullYear() +
        '-' +
        ('0' + (lastDay_endDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + lastDay_endDate.getDate()).toString().slice(-2);
    } else if (this.userSettingsModel.timeRangeSelected == 'Year To Date(YTD)') {
      const year = this.firmBasicData.yyyymm.toString().slice(0, 4);
      const month = this.firmBasicData.yyyymm.toString().slice(-2);
      let firstDay_startDate = new Date();
      let lastDay_endDate = new Date();
      firstDay_startDate.setDate(1);
      firstDay_startDate.setMonth(1);
      firstDay_startDate.setFullYear(parseInt(year));
      lastDay_endDate.setDate(1);
      lastDay_endDate.setMonth(parseInt(month) - 1);
      lastDay_endDate.setFullYear(parseInt(year));
      requestbody['rollingPeriodId'] = 0;
      requestbody['startPeriod'] =
        firstDay_startDate.getFullYear() +
        '-' +
        ('0' + firstDay_startDate.getMonth()).toString().slice(-2) +
        '-' +
        ('0' + firstDay_startDate.getDate()).toString().slice(-2);
      requestbody['endPeriod'] =
        lastDay_endDate.getFullYear() +
        '-' +
        ('0' + (lastDay_endDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + lastDay_endDate.getDate()).toString().slice(-2);
    } else {
      requestbody['rollingPeriodId'] = null;
      requestbody['startPeriod'] = null;
      requestbody['endPeriod'] = null;
    }

    if (this.userSettingsModel.metricSelectedItems) {
      let result = this.userSettingsModel.metricSelectedItems.filter((item) => true);
      let metrics: number[] = [...new Set(result.map((item) => item.id))];
      requestbody['metrics'] = metrics;
    } else {
      requestbody['metrics'] = [];
    }

    if (this.userSettingsModel.peerGroupSelectedItems) {
      let peerGroups: number[] = [...new Set(this.userSettingsModel.peerGroupSelectedItems.map((item) => item.id))];
      requestbody['peerGroups'] = peerGroups;
    } else {
      requestbody['peerGroups'] = [];
    }

    if (this.userSettingsModel.practiceGroupSelectedItems) {
      let practices: number[] = [...new Set(this.userSettingsModel.practiceGroupSelectedItems.map((item) => item.id))];
      requestbody['practices'] = practices;
    } else {
      requestbody['practices'] = [];
    }

    if (this.userSettingsModel.officeSelectedItems) {
      let offices: number[] = [...new Set(this.userSettingsModel.officeSelectedItems.map((item) => item.id))];
      requestbody['offices'] = offices;
    } else {
      requestbody['offices'] = [];
    }

    if (this.userSettingsModel.titleSelectedItems) {
      let titles: number[] = [...new Set(this.userSettingsModel.titleSelectedItems.map((item) => item.id))];
      requestbody['titles'] = titles;
    } else {
      requestbody['titles'] = [];
    }

    let experienceYears = {};

    if (this.userSettingsModel.partnerYearSelectedItems) {
      let partnerYears: number[] = [...new Set(this.userSettingsModel.partnerYearSelectedItems.map((item) => item.id))];
      experienceYears['partnerYears'] = partnerYears;
    } else {
      experienceYears['partnerYears'] = [];
    }

    if (this.userSettingsModel.srAssociateYearSelectedItems) {
      let srAssociateYears: number[] = [
        ...new Set(this.userSettingsModel.srAssociateYearSelectedItems.map((item) => item.id)),
      ];
      experienceYears['srAssociateYears'] = srAssociateYears;
    } else {
      experienceYears['srAssociateYears'] = [];
    }

    if (this.userSettingsModel.srAssociateYearSelectedItems) {
      let associateYears: number[] = [
        ...new Set(this.userSettingsModel.associateYearSelectedItems.map((item) => item.id)),
      ];
      experienceYears['associateYears'] = associateYears;
    } else {
      experienceYears['associateYears'] = [];
    }

    requestbody['experienceYears'] = experienceYears;

    requestbody['matterType'] = this.userSettingsModel.viewsSettings.matterType.toLowerCase();
    requestbody['contractors'] = this.userSettingsModel.viewsSettings.contractorsType.toLowerCase();
    requestbody['currency'] = this.userSettingsModel.viewsSettings.currencyType.toLowerCase();
    requestbody['comparison'] = this.userSettingsModel.viewsSettings.comparisonType.toLowerCase();
    requestbody['statistics'] = this.userSettingsModel.viewsSettings.statisticsType.toLowerCase();
    return requestbody;
  }

  // getDefaultOrSelectedFirmId() should return the selected firmID OR firmID of logged in user.
  private get getDefaultOrSelectedFirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : parseInt(this.route.snapshot.paramMap.get('id'));
  }

  getViewDetails() {
    // this.isDataLoading = true;
    this.service
      .get(
        environment.FIAdminBaseEndpoint +
          'v1/view/' +
          this.selectedViewData.viewId +
          '/firm/' +
          this.getDefaultOrSelectedFirmId +
          '/details',
        ''
      )
      .subscribe(
        (result) => {
          // this.isDataLoading = false;
          this.viewDetails = result;
          this.userSettingsModel = this.peercheckMessageHandlerService.processSelectedViewDetails(
            this.viewDetails,
            this.userSettingsModel,
            this.firmBasicData.yyyymm
          );
          this.isDisplayGrid = true;
          this.updateSelection();
        },
        (error) => {
          this.isDataLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  updateSelection(requestOrigin: any = null) {
    if (this.viewGridComponent) this.viewGridComponent.updateSummary();

    // get basic data and firm level rules for the firm
    this.userSettingsModel.isUKFirm = this.firmBasicData.defaultAccess == 'UK';
    this.userSettingsModel.isCANFirm = this.firmBasicData.defaultAccess == 'CAN';

    let requestbody: any = this.peercheckMessageHandlerService.createRequestBody(
      this.userSettingsModel,
      this.firmBasicData,
      'gas',
      false,
      null,
      null,
      false
    );
    if (!requestbody || !this.firmBasicData || !this.pcViewCols) {
      let error = this.peercheckMessageHandlerService.getCustomErrors(this.userSettingsModel);
      error.forEach((err) => {
        this.alerts.push({
          type: 'warning',
          msg: err,
          closeable: true,
        });
      });
      this.isDataLoading = false;
      return;
    }

    this.isDataLoading = true;
    this.userSettingsModel.cardTitles = [];
    this.service
      .post(environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getDefaultOrSelectedFirmId, requestbody)
      .subscribe(
        (result) => {
          this.processedBaseTableData = new ProcessedBaseTableData();
          this.expandDataRaw = result;

          let diffOfYear = this.peercheckMessageHandlerService.diffOfYears(
            requestbody.endPeriod,
            requestbody.startPeriod
          );
          this.yoyHeader = diffOfYear && diffOfYear > 1 ? 'Rolling growth' : 'YOY growth';
          // PROCESS API RESPONSE

          if (this.peercheckMessageHandlerService && result) {
            let response = Array.isArray(result) && result.length == 1 ? result[0] : result;
            this.processedMainGridData = this.peercheckMessageHandlerService.processDataForMainGrid(
              response,
              this.userSettingsModel,
              null,
              this.pcViewCols,
              this.firmBasicData,
              this.yoyHeader
            );
          }
          if (this.viewGridComponent) this.viewGridComponent.updateGrid(this.processedMainGridData);
          if (this.processedMainGridData.hasEmptyResult) {
            this.alerts.push({
              type: 'warning',
              msg: 'No data available to display.',
              closeable: true,
            });
          }
        },
        (error) => {
          this.handleRequestOriginChanges(requestOrigin, true);
          let errMsg = '';
          this.errorMessage = error.error;
          if (error.error.message == 'Peer Group is not valid.') {
            errMsg = error.error.message;
          }
          if (requestOrigin && requestOrigin.requestFrom && requestOrigin.requestFrom != 'openViewSettings') {
            errMsg = 'Something went wrong, please try again.';
            if (this.peercheckMessageHandlerService) {
              errMsg = this.peercheckMessageHandlerService.getServiceErrorMessageString(this.errorMessage);
            }
          }
          this.alerts.push({
            type: 'warning',
            msg: errMsg,
            closeable: true,
          });
          this.isDataLoading = false;
        }
      );
  }

  handleRequestOriginChanges(requestOrigin: any, isFailure: boolean = false) {
    if (!requestOrigin) return;

    if (requestOrigin.requestFrom && requestOrigin.requestFrom == 'openViewSettings') {
      const previousSettings = requestOrigin.previousSettings ? requestOrigin.previousSettings : null;
      const currentSettings = requestOrigin.currentSettings ? requestOrigin.currentSettings : null;
      if (isFailure) {
        this.userSettingsModel.viewsSettings = previousSettings || new ViewsSettings();
        this.alerts.push({
          type: 'warning',
          msg: 'Unable to apply settings.',
          closeable: true,
        });
      } else {
        this.userSettingsModel.viewsSettings = currentSettings || new ViewsSettings();
        this.alerts.push({
          type: 'success',
          msg: 'Settings applied successfully.',
          timeout: 2500,
          closeable: true,
        });
      }
    }
  }

  dataLoadingStateChange(currentDataLoadingState) {
    this.isDataLoading = currentDataLoadingState;
  }

  exportView() {
    const modalRef = this.modalService.open(ExportModalComponent);
    modalRef.componentInstance.triggeredFrom = 'Landing';
    modalRef.componentInstance.firmBasicData = this.firmBasicData;
    modalRef.componentInstance.viewDetails = this.viewDetails;
    modalRef.componentInstance.userSettingsModel = this.userSettingsModel;
    modalRef.componentInstance.firmId = this.getDefaultOrSelectedFirmId;
    modalRef.result.then(
      (result) => {
        if (result == 'Success') {
          this.alerts.push({
            type: 'success',
            msg: 'Data saved Successfully.',
            timeout: 2500,
            closeable: true,
          });
        } else {
          this.alerts.push({
            type: 'warning',
            msg: result,
            closeable: true,
          });
        }
      },
      (reason) => {}
    );
  }

  shareView() {
    let showPPPError = false;
    if (this.viewDetails && this.viewDetails.peerGroups) {
      for (let pg of this.viewDetails.peerGroups) {
        if (pg.isPpg) {
          showPPPError = true;
          break;
        }
      }
    }
    const modalRef = this.modalService.open(ShareViewModelComponent);
    modalRef.componentInstance.showPPPError = showPPPError;
    modalRef.componentInstance.viewTemplateId = this.viewDetails.viewTemplateId;
    modalRef.componentInstance.firmId = this.getDefaultOrSelectedFirmId;

    modalRef.result.then(
      (result) => {
        if (result == 'Success') {
          this.alerts.push({
            type: 'success',
            msg: 'View shared successfully.',
            timeout: 2500,
            closeable: true,
          });
        } else {
          this.alerts.push({
            type: 'warning',
            msg: result,
            closeable: true,
          });
        }
      },
      (reason) => {}
    );
  }

  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }
}
