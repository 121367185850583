<bento-alert [items]="alerts" ></bento-alert>
<div class="GlobalLayout staffing-ratio-custom-segment" role="main">
    <form class="FilterPanel-control--Timekeeper" name="customizeSegment" #customizeSegment="ngForm" bfmForm>
    <main id="MainContent" tabindex="-1" class="Main Main--narrow">
      <div class="Actions">
        <header class="Main-header">
          <h1 class="Main-heading">Custom peer group segment mapping</h1>
        </header>
        <div role="alert" aria-live="polite" class="notify Actions-item">       
          <p class="note-info">Note - Press <span class="shortcut">Alt + Shift + Down Arrow </span> to see filter options for selected column.</p>
        </div>
      </div>
        <div class="col" style="padding-left: 5px;">
          <div class="col">
              <span class="sr-total-firms" role="status">Showing {{currentRecords}} of {{totalRecords}}</span>
          </div>
        </div>
      <div class="Main-body"  >
        <wj-flex-grid
        #flexGrid
        class="DataGrid DataGridFlexScroll"
        [autoGenerateColumns]="false"
        [itemsSource]="data"
        [stickyHeaders]="true"
        [showMarquee]="true"
        [showSort]="true"
        [allowDragging]="false"
        [isReadOnly]="true"
        [headersVisibility]="'Column'" 
        [bentoBusyLoader]="isDataLoading"
        (initialized)="gridInitialized(flexGrid, 'addPeerGroupHeader')"
        (selectionChanged)="handleSelectionChanged(flexGrid, $event)"
      >
      <wj-flex-grid-filter
            #filter
            (initialized)="initFilter()"
            (filterChanged)="onFilterChange()"
            (filterApplied)="onFilterApply()"
          ></wj-flex-grid-filter>
       
        <wj-flex-grid-column
          [header]="'Firm name'"
          [binding]="'firmName'"
          [visible]="true"
          [minWidth]="350"
          [width]="'*'"
          [align]="'left'"
          [isReadOnly]="true"
          [wordWrap]="true"
        ></wj-flex-grid-column>
        <wj-flex-grid-column
          [header]="'Main office country'"
          [binding]="'mainOfficeCountry'"
          [minWidth]="30"
          [width]="162"
          [visible]="true"
          [align]="'left'"
          [isReadOnly]="true"
          [wordWrap]="true"
        ></wj-flex-grid-column>
        <wj-flex-grid-column
          [header]="'Rank (AmLaw)'"
          [binding]="'amLawRank'"
          [dataType] ="2"
          [format]="'f'"
          [minWidth]="30"
          [width]="136"
          [visible]="true"
          [align]="'right'"
          [isReadOnly]="true"
          [wordWrap]="true"
        ></wj-flex-grid-column>
        <wj-flex-grid-column
        cssClass="wj-cell--input"
        [header]="'AmLaw segment'"
        [binding]="'amLawSegment'"
        [visible]="true"
        [minWidth]="80"
        [align]="'left'"
        [width]="172"
        [wordWrap]="true"
      >
      <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
          <label for="amLawSegment-{{ row.index }}" class="sr-only">AmLaw segment</label>
          <input
                      type="text"
                      name="amLawSegment-{{ row.index }}"
                      id="amLawSegment-{{ row.index }}"
                      bfmField
                      bfmLabel="amLawSegment"
                      [disabled]="item.dataProcessing.amLawSegment"
                      [(ngModel)]="item.amLawSegment"
                      (focusout)="saveCustomeSegment(item,item.amLawSegment,'amLawSegment')"
                    />
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-column
          [header]="'Lawyers'"
          [binding]="'lawyers'"
          [dataType] ="2"
          [format]="'f'"
          [minWidth]="30"
          [width]="98"
          [visible]="true"
          [align]="'right'"
          [isReadOnly]="true"
          [wordWrap]="true"
        ></wj-flex-grid-column>
        <wj-flex-grid-column
        [header]="'Lawyer segment'"
        [binding]="'lawyerSegment'"
        [visible]="true"
        [minWidth]="80"
        [width]="172"
        [wordWrap]="true"
      >
      <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
          <label for="lawyerSegment-{{ row.index }}" class="sr-only">Lawyer segment</label>
          <input
                      type="text"
                      name="lawyerSegment-{{ row.index }}"
                      id="lawyerSegment-{{ row.index }}"
                      #officeSegment
                      bfmField
                      bfmLabel="lawyerSegment"
                      [disabled]="item.dataProcessing.lawyerSegment"
                      [(ngModel)]="item.lawyerSegment"
                      (focusout)="saveCustomeSegment(item,item.lawyerSegment,'lawyerSegment')"
                    />
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-column
          [header]="'Offices'"
          [binding]="'offices'"
          [dataType] ="2"
          [format]="'f'"
          [minWidth]="30"
          [width]="94"
          [visible]="true"
          [align]="'right'"
          [isReadOnly]="true"
          [wordWrap]="true"
        ></wj-flex-grid-column>
        <wj-flex-grid-column
        [header]="'Office segment'"
        [binding]="'officeSegment'"
        [visible]="true"
        [minWidth]="80"
        [width]="172"
        [wordWrap]="true"
      >
      <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
          <label for="officeSegment-{{ row.index }}" class="sr-only">Office segment</label>
          <input
                      type="text"
                      name="officeSegment-{{ row.index }}"
                      id="officeSegment-{{ row.index }}"
                      #officeSegment
                      bfmField
                      bfmLabel="officeSegment"
                      [disabled]="item.dataProcessing.officeSegment"
                      [(ngModel)]="item.officeSegment"
                      (focusout)="saveCustomeSegment(item,item.officeSegment,'officeSegment')"
                    />
        </ng-template>
      </wj-flex-grid-column> 
      </wj-flex-grid>
      </div>
    </main>
    </form>
  </div>
  