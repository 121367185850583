<bento-alert [items]="alerts"></bento-alert>
<section class="Section Section--views" [bentoBusyLoader]="isDataLoading">
  <form name="visualizations" #visualizations="ngForm" class="Section-form Form" bfmForm>
    <header class="Section-header" *ngIf="visualizationList && visualizationList.length">
      <h2 [id]="getSectionHeadingId()" class="Section-heading mb-3">{{ sectionHeading }}</h2>
      <div class="row align-items-center">
        <div [id]="getSubSectionHeadingId()" class="col">
          <app-grid-record-details [currentRecords]="currentRecords" [totalRecords]="totalRecords">
          </app-grid-record-details>
        </div>
        <div class="col">
          <app-sorting-dropdown
            [sortingDropdownItems]="sortingDropdownItems"
            [viewNameSortOrderAsc]="visualizationNameSortOrderAsc"
            [createdDateSortOrderAsc]="createdDateSortOrderAsc"
            (dropDownSelectChange)="dropDownOnSelectChange($event)"
            [isChartList]="isChartList"
          ></app-sorting-dropdown>
        </div>
      </div>
    </header>
    <div class="Section-body">
      <ol class="chart-list-type" role="presentation">
        <ng-container *ngFor="let item of visualizationList | slice : startIndex : endIndex; index as i">
          <li>
            <div class="card Card">
              <div class="card-body d-flex">
                <div id="card-title-{{ i }}" class="imgContent">
                  <img
                    [attr.id]="'card-image-' + i"
                    class="image-icon-body"
                    [src]="'data:image/png;base64,' + item.image"
                    [alt]="'Sample of the ' + getVisualizationName(item)"
                  />
                </div>
                <div id="card-title-{{ i }}" class="row imgDetails w-100" *ngIf="sectionHeading == 'My visualizations'">
                  <div class="col Visualization-label-col">
                    <div class="d-flex justify-content-between align-items-center w-100">
                      <div>
                        <a
                          href="javascript:void(0)"
                          [attr.id]="'card-link-mv-' + i"
                          [routerLink]=""
                          class="card-link Card-link cursor-pointer"
                          (click)="viewCurrentVisualization(item)"
                          [attr.aria-label]="getVisualizationName(item)"
                        >
                          {{ getVisualizationName(item) }}
                        </a>
                      </div>
                    </div>
                    <div>
                      <div class="bento-desc-list bento-desc-list--inline PeerMeta" role="presentation">
                        <div class="bento-desc-list-item">
                          <div id="card-created-date-mv" *ngIf="isChartList">
                            <label>Created:</label>
                            <span> {{ item.createdDate | date : 'MM/dd/yyyy' }} </span>
                          </div>
                          <div *ngIf="!isChartList">
                            <span *ngIf="item.dateRangeSelectedIds == '1'">
                              {{ extractDateFormat(item.currentPeriodStartDate) }} v.
                              {{ extractDateFormat(item.priorPeriodStartDate) }}
                            </span>
                            <span *ngIf="item.dateRangeSelectedIds == '2'">
                              {{ extractDateFormat(item.currentPeriodStartDate) }} -
                              {{ extractDateFormat(item.currentPeriodEndDate) }} to
                              {{ extractDateFormat(item.priorPeriodStartDate) }} -
                              {{ extractDateFormat(item.priorPeriodEndDate) }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="Actions Actions-flex justify-content-end">
                        <div>
                          <button
                            type="button"
                            (click)="redirectToNewVisualization(item)"
                            class="btn btn-primary Actions-button"
                            [attr.aria-label]="'Edit/Copy '+ getVisualizationName(item)"
                          >
                            Edit/Copy
                          </button>
                        </div>

                        <div class="Actions-item Actions-item-flex">
                          <button
                            type="button"
                            (click)="removeChartWithConfirmation(removeModalContent, item)"
                            class="btn btn-danger Actions-button"
                            [attr.aria-label]="'Delete '+ getVisualizationName(item)"
                          >
                            Delete
                          </button>
                        </div>

                        <div class="Actions-item Actions-item-flex">
                          <button
                            type="button"
                            class="btn btn-outline-secondary btn-icon btn-sm"
                            (click)="favoriteUnfavoriteToggle(item)"
                            ngbTooltip="{{ item.favorite ? 'Remove favorite status' : ' Add to favorite' }}"
                            [attr.aria-label]="
                              item.favorite
                                ? 'Remove favorite status from ' + getVisualizationName(item)
                                : 'Add to favorite ' + getVisualizationName(item)
                            "
                          >
                            <i
                              [ngClass]="item.favorite ? 'bento-icon-star' : 'bento-icon-star2'"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <div role="status" class="sr-only">
                            {{
                              item.favorite
                                ? getVisualizationName(item) + ' was added to favorite'
                                : getVisualizationName(item) + ' was removed from favorite'
                            }}
                          </div>
                        </div>

                        <div class="Actions-item Actions-item-flex">
                          <button
                            type="button"
                            class="btn btn-outline-secondary btn-icon btn-sm"
                            ngbTooltip="{{ item.isExpand ? 'Collapse' : 'Expand' }}"
                            [attr.aria-label]="
                              item.isExpand
                                ? getVisualizationName(item) + ' Collapse'
                                : getVisualizationName(item) + ' Expand'
                            "
                            [attr.aria-expanded]="item.isExpand ? 'true' : 'false'"
                            (click)="expandCollapseToggle(item)"
                          >
                            <i
                              [ngClass]="item.isExpand ? 'bento-icon-caret-up' : 'bento-icon-caret-down'"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="card-title-{{ i }}"
                  class="row imgDetails w-100"
                  *ngIf="sectionHeading == 'Visualization templates'"
                >
                  <div class="col Visualization-label-col">
                    <div class="d-flex justify-content-between align-items-center w-100">
                      <div>
                        <a
                          href="javascript:void(0)"
                          [attr.id]="'card-link-vt-' + i"
                          [routerLink]=""
                          class="card-link Card-link cursor-pointer"
                          (click)="viewCurrentVisualization(item)"
                          [attr.aria-label]="getVisualizationName(item)"
                        >
                          {{ getVisualizationName(item) }}
                        </a>
                      </div>
                    </div>
                    <div>
                      <div class="bento-desc-list bento-desc-list--inline PeerMeta" role="presentation">
                        <div id="card-created-date-vt-" class="bento-desc-list-item">
                          <div *ngIf="isChartList">
                            <label>Created:</label>
                            <span> {{ item.createdDate | date : 'MM/dd/yyyy' }}</span>
                          </div>
                          <div *ngIf="!isChartList">
                            <span *ngIf="item.dateRangeSelectedIds == '1'">
                              {{ extractDateFormat(item.currentPeriodStartDate) }} v.
                              {{ extractDateFormat(item.priorPeriodStartDate) }}
                            </span>
                            <span *ngIf="item.dateRangeSelectedIds == '2'">
                              {{ extractDateFormat(item.currentPeriodStartDate) }} -
                              {{ extractDateFormat(item.currentPeriodEndDate) }} to
                              {{ extractDateFormat(item.priorPeriodStartDate) }} -
                              {{ extractDateFormat(item.priorPeriodEndDate) }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col visualization-templates-list">
                      <div class="Actions Actions-flex justify-content-end">
                        <div>
                          <button
                            type="button"
                            (click)="redirectToNewVisualization(item)"
                            class="btn btn-primary Actions-button"
                            [attr.aria-label]="'Create a visualization ' + getVisualizationName(item)"
                          >
                            Create Visualization
                          </button>
                        </div>

                        <div class="Actions-item Actions-item-flex">
                          <button
                            type="button"
                            class="btn btn-outline-secondary btn-icon btn-sm"
                            (click)="favoriteUnfavoriteToggle(item)"
                            ngbTooltip="{{ item.favorite ? 'Remove favorite status' : ' Add to favorite' }}"
                            [attr.aria-label]="
                              item.favorite
                                ? 'Remove favorite status from ' + getVisualizationName(item)
                                : 'Add to favorite ' + getVisualizationName(item)
                            "
                          >
                            <i
                              [ngClass]="item.favorite ? 'bento-icon-star' : 'bento-icon-star2'"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <div role="status" class="sr-only">
                            {{
                              item.favorite
                                ? getVisualizationName(item) + ' was added to favorite'
                                : getVisualizationName(item) + ' was removed from favorite'
                            }}
                          </div>
                        </div>

                        <div class="Actions-item Actions-item-flex">
                          <button
                            type="button"
                            class="btn btn-outline-secondary btn-icon btn-sm"
                            ngbTooltip="{{ item.isExpand ? 'Collapse' : 'Expand' }}"
                            [attr.aria-label]="
                              item.isExpand
                                ? getVisualizationName(item) + ' Collapse'
                                : getVisualizationName(item) + ' Expand'
                            "
                            [attr.aria-expanded]="item.isExpand ? 'true' : 'false'"
                            (click)="expandCollapseToggle(item)"
                          >
                            <i
                              [ngClass]="item.isExpand ? 'bento-icon-caret-up' : 'bento-icon-caret-down'"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Card-details" *ngIf="item.isExpand" [bentoBusyLoader]="item.isDataLoading" [size]="'sm'">
                <div class="Card-group">
                  <div class="Card-item Card-item--large">
                    <div
                      attr.aria-labelledby="card-title-{{ i }}"
                      class="bento-desc-list bento-desc-list--inline PeerMeta"
                    >
                      <div
                        class="bento-desc-list-item"
                        *ngIf="item.createChartModel !== undefined && item.createChartModel.selectedMetrics.length"
                      >
                        <span class="dtCss">Metrics:</span>
                        <span class="ddCss">
                          {{ getDescription(item.createChartModel.selectedMetrics) }}
                        </span>
                      </div>
                      <div
                        class="bento-desc-list-item"
                        *ngIf="item.createChartModel !== undefined && item.createChartModel.selectedPeerGroups.length"
                      >
                        <span class="dtCss">Peer groups:</span>
                        <span class="ddCss">
                          {{ getDescription(item.createChartModel.selectedPeerGroups) }}
                        </span>
                      </div>
                      <div
                        class="bento-desc-list-item"
                        *ngIf="
                          item.createChartModel !== undefined &&
                          item.createChartModel.selectedDateRangeType.id !== undefined
                        "
                      >
                        <span class="dtCss">Date range:</span>
                        <span class="ddCss" *ngIf="item.createChartModel.selectedDateRangeType.id == 1">
                          Year to year monthly percent change:
                          {{ extractDateFormat(item.createChartModel.currentPeriodStartDate) }} v.
                          {{ extractDateFormat(item.createChartModel.priorPeriodStartDate) }}
                        </span>
                        <span class="ddCss" *ngIf="item.createChartModel.selectedDateRangeType.id == 2">
                          Custom range comparison: compare
                          {{ extractDateFormat(item.createChartModel.currentPeriodStartDate) }} -
                          {{ extractDateFormat(item.createChartModel.currentPeriodEndDate) }} to
                          {{ extractDateFormat(item.createChartModel.priorPeriodStartDate) }} -
                          {{ extractDateFormat(item.createChartModel.priorPeriodEndDate) }}
                        </span>
                      </div>
                      <div
                        class="bento-desc-list-item"
                        *ngIf="
                          item.createChartModel !== undefined && item.createChartModel.selectedPracticeGroups.length
                        "
                      >
                        <span class="dtCss">Practice group:</span>
                        <span class="ddCss">
                          {{ getDescription(item.createChartModel.selectedPracticeGroups) }}
                        </span>
                      </div>
                      <div
                        class="bento-desc-list-item"
                        *ngIf="item.createChartModel !== undefined && item.createChartModel.selectedOffices.length"
                      >
                        <span class="dtCss">Office:</span>
                        <span class="ddCss">
                          {{ getDescription(item.createChartModel.selectedOffices) }}
                        </span>
                      </div>
                      <div
                        class="bento-desc-list-item"
                        *ngIf="item.createChartModel !== undefined && item.createChartModel.selectedTitles.length"
                      >
                        <span class="dtCss">Title:</span>
                        <span class="ddCss">
                          {{ getDescription(item.createChartModel.selectedTitles) }}
                        </span>
                      </div>
                      <div
                        class="bento-desc-list-item"
                        *ngIf="
                          item.createChartModel &&
                          ((item.createChartModel.selectedAssociateYears &&
                            item.createChartModel.selectedAssociateYears.length) ||
                            (item.createChartModel.selectedSrAssociateYears &&
                              item.createChartModel.selectedSrAssociateYears.length) ||
                            (item.createChartModel.selectedPartnerYears &&
                              item.createChartModel.selectedPartnerYears.length))
                        "
                      >
                        <span class="dtCss">Experience(s):</span>
                        <span class="ddCss">
                          <ng-container
                            *ngIf="
                              item.createChartModel.selectedAssociateYears &&
                              item.createChartModel.selectedAssociateYears.length
                            "
                          >
                            {{ getDescription(item.createChartModel.selectedAssociateYears)
                            }}{{
                              (item.createChartModel.selectedSrAssociateYears &&
                                item.createChartModel.selectedSrAssociateYears.length) ||
                              (item.createChartModel.selectedPartnerYears &&
                                item.createChartModel.selectedPartnerYears.length)
                                ? ','
                                : ''
                            }}
                          </ng-container>
                          <ng-container
                            *ngIf="
                              item.createChartModel.selectedSrAssociateYears &&
                              item.createChartModel.selectedSrAssociateYears.length
                            "
                          >
                            {{ getDescription(item.createChartModel.selectedSrAssociateYears)
                            }}{{
                              item.createChartModel.selectedPartnerYears &&
                              item.createChartModel.selectedPartnerYears.length
                                ? ','
                                : ''
                            }}
                          </ng-container>
                          <ng-container
                            *ngIf="
                              item.createChartModel.selectedPartnerYears &&
                              item.createChartModel.selectedPartnerYears.length
                            "
                          >
                            {{ getDescription(item.createChartModel.selectedPartnerYears) }}
                          </ng-container>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="Card-item Card-item--small"
                    *ngIf="item.createChartModel !== undefined && item.createChartModel.viewsSettings"
                  >
                    <div class="bento-desc-list bento-desc-list--inline PeerMeta">
                      <div class="bento-desc-list-item" *ngIf="item.createChartModel.viewsSettings.statisticsType">
                        <span class="dtCss">Statistics:</span>
                        <span class="ddCss">
                          {{ item.createChartModel.viewsSettings.statisticsType | titlecase }}
                        </span>
                      </div>
                      <div class="bento-desc-list-item" *ngIf="item.createChartModel.viewsSettings.comparisonType">
                        <span class="dtCss">Comparison:</span>
                        <span class="ddCss">
                          {{ item.createChartModel.viewsSettings.comparisonType | titlecase }}
                        </span>
                      </div>
                      <div class="bento-desc-list-item" *ngIf="item.createChartModel.viewsSettings.matterType">
                        <span class="dtCss">Matter Type:</span>
                        <span class="ddCss">
                          {{ item.createChartModel.viewsSettings.matterType | titlecase }}
                        </span>
                      </div>
                      <div class="bento-desc-list-item" *ngIf="item.createChartModel.viewsSettings.contractorsType">
                        <span class="dtCss">Contractors:</span>
                        <span class="ddCss">
                          {{ item.createChartModel.viewsSettings.contractorsType | titlecase }}
                        </span>
                      </div>
                      <div class="bento-desc-list-item" *ngIf="item.createChartModel.viewsSettings.currencyType">
                        <span class="dtCss">Currency:</span>
                        <span class="ddCss">
                          {{ item.createChartModel.viewsSettings.currencyType | titlecase }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ng-container>
      </ol>
      <div *ngIf="totalRecords == 0" role="alert" aria-live="polite" >No visualization found.</div>
    </div>
    <bento-pagination
      *ngIf="visualizationList && visualizationList.length"
      [(page)]="currentInfo.page"
      (pageChange)="onPageChanged($event)"
      [directionLinks]="true"
      [boundaryLinks]="true"
      [totalItems]="currentInfo.totalItems"
      [itemsArray]="selectItems"
      (itemsPerPageChange)="onItemsPerPageChanged($event)"
      [(itemsPerPage)]="currentInfo.numItems"
    >
    </bento-pagination>
  </form>
</section>
<ng-template #removeModalContent let-c="close" let-d="dismiss">
  <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h2 id="modalViewRemove" class="modal-header h2">Remove</h2>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <p>
            Click remove to confirm
            {{ selectedVisualization && selectedVisualization.chartName ? selectedVisualization.chartName : '' }}
            removal.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer modal-common-action-btns-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="c('cancel')">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="c('confirm')">Remove</button>
  </div>
</ng-template>
