import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AxeToolCommonMethodsService {
  constructor() {}

  addAttributeToElement(querySelector, attributeToBeAdded, valueOfAttribute) {
    const elements1 = document.querySelectorAll(querySelector);
    elements1.forEach((elements1) => {
      elements1.setAttribute(attributeToBeAdded, valueOfAttribute);
    });
  }
  removeAttributeToElement(querySelector, attributeToBeRemoved) {
    const elements1 = document.querySelectorAll(querySelector);
    elements1.forEach((elements1) => {
      elements1.removeAttribute(attributeToBeRemoved);
    });
  }
  getHTMLElement(querySelector) {
    const elements = document.querySelector(querySelector);
    return elements;
  }
}
