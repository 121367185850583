<bento-alert *ngIf="this.selectedAccountCodes.length > 0" [items]="alerts"></bento-alert>
<main id="MainContent" tabindex="-1" class="Main" role="main" [bentoBusyLoader]="isDataLoading">
  <header class="Main-header Main-header--border">
    <h1 class="h2 Main-heading">{{ bulkEditTitle }}</h1>
    <dl class="bento-desc-list bento-desc-list--bullets bento-desc-list--sideBySide GLAccountCodeDetails">
      <dt>GL Account Code:</dt>
      <dd>{{ glAccCode }}</dd>
      <dt>Mapped Account Name:</dt>
      <dd>{{ mappedAccName }}</dd>
    </dl>
  </header>
  <div class="Main-body">
    <app-fiscal-period
      #fiscal
      [isButtonVisible]="false"
      [requestFrom]="'GL Account Codes'"
      [availableOverrides]="overrides"
      [selectedOverride]="selectedOverride"
      [isOverride]="isOverride"
      [readyToSubmit]="readyToSubmit"
      (overridePeriodChanged)="onOverridePeriodChanged($event)"
      (selectionChanged)="updateFiscalDateRange($event)"
      (saveDetails)="saveOverrideDetails()"
      (bentoAlertOccured)="onBentoAlertOccured($event)"
      [selectedGlAcOverride]="selectedGlAcOverride"
      (verifyDateRangeInvalidPattern)="verifyDateRangeInvalidPattern($event)"
    ></app-fiscal-period>

    <form name="editForm" #editForm="ngForm" class="Section-form Form" bfmForm>
      <fieldset class="FormSet">
        <legend class="h5 FormSet-heading">Select</legend>
        <div class="form-group">
          <label class="display-block width-fit-content radio-inline" *ngFor="let choice of saveChoices">
            <input
              name="allocationType"
              [(ngModel)]="model.allocationType"
              [value]="choice.value"
              type="radio"
              (change)="onAllocationTypeChange()"
            />
            {{ choice.label }}
          </label>

          <br />

          <input
            *ngIf="model.allocationType == 'P'"
            name="allocationValue"
            [(ngModel)]="
              selectedGlAcOverride && selectedGlAcOverride.override && selectedGlAcOverride.override.accountTotalAmount
            "
            placeholder="Enter allocation value"
            type="text"
            (keyup)="onValueChange()"
            appTwoDigitDecimalNumber
            required
            (blur)="onBlur()"
            bfmField
            [ngClass]="
              showValueError || showPercentError ? 'form-control bfm-invalid alloc-value' : 'alloc-value form-control'
            "
          />

          <input
            *ngIf="model.allocationType == 'D'"
            name="allocationValue"
            [(ngModel)]="
              selectedGlAcOverride && selectedGlAcOverride.override && selectedGlAcOverride.override.accountTotalAmount
            "
            placeholder="Enter allocation value"
            type="text"
            (keyup)="onValueChange()"
            appTwoDigitDecimalNumber
            required
            (blur)="onBlur()"
            bfmField
            [ngClass]="showValueError ? 'form-control bfm-invalid alloc-value' : 'alloc-value form-control'"
          />

          <p class="glEditError" *ngIf="!editForm.invalid && showValueError">Please Enter 2 decimal digit</p>
          <p class="glEditError" *ngIf="!editForm.invalid && showPercentError">Percent cannot be more than 100</p>
        </div>
      </fieldset>
    </form>

    <fieldset class="FormSet">
      <legend class="h5 FormSet-heading">Map Account</legend>
      <div class="form-check">
        <bento-checkbox
          id="allocDepartment"
          [(ngModel)]="model.isDepartmentChecked"
          [disabled]="model.allocationType == 'D'"
          (change)="onMapAccountChange()"
        ></bento-checkbox>
        <label for="allocDepartment">Allocate by department</label>
      </div>
      <div class="form-check">
        <bento-checkbox
          id="allocOffice"
          [(ngModel)]="model.isOfficeChecked"
          [disabled]="model.allocationType == 'D'"
          (change)="onMapAccountChange()"
        ></bento-checkbox>
        <label for="allocOffice">Allocate by office</label>
      </div>
      <div class="form-check">
        <bento-checkbox
          id="allocPMOffice"
          [(ngModel)]="model.isPmOfficeChecked"
          (change)="onMapAccountChange()"
        ></bento-checkbox>
        <label for="allocPMOffice">Re-assign Peer Monitor office</label>
      </div>
    </fieldset>

    <app-map-account-grid
      *ngIf="this.selectedAccountCodes[0]"
      [selectedAllocType]="model.allocationType"
      [selectedAllocValue]="model.allocationValue"
      [selectedMappedOfficeName]="this.selectedAccountCodes[0].pmCode"
      [headers]="headers"
      [glacDepartment]="glacDepartment"
      [glacOffice]="glacOffice"
      [glacMappedOffice]="glacMappedOffice"
      [glacMappedOfficeName]="glacMappedOfficeName"
      [avaliableCols]="avaliableCols"
      [selectedDetails]="selectedGlAcOverride.override.details"
      [selectedGlAcOverride]="selectedGlAcOverride"
      (currentSelection)="saveCurrentSelection($event)"
      [invalidForm]="showValueError || showPercentError"
    >
    </app-map-account-grid>
  </div>
  <footer class="Main-footer">
    <div class="Actions">
      <div class="Actions-item" *ngIf="isOverride && selectedOverride != null">
        <button type="button" class="btn btn-outline-secondary" (click)="deleteSelectedOverride()">
          <span class="btn-text">Delete current overrides</span>
        </button>
      </div>
      <div class="Actions-item">
        <button type="button" class="btn btn-outline-secondary" (click)="closeBulkEdit()">
          <span class="btn-text">Cancel</span>
        </button>
      </div>
      <div class="Actions-item a11y">
        <button
          type="submit"
          class="btn btn-primary"
          [attr.aria-disabled]="!readyToSubmit || showValueError ? 'true' : 'false' "
          (click)="!readyToSubmit || showValueError ? $event.stopPropagation() :saveGLAccOverrideDetails()"
          [attr.aria-labelledby]="!readyToSubmit || showValueError ?'aria-required-save' : null "
        >
          <span class="btn-text">Save</span>
        </button>
      </div>
      <span class="sr-only" id="aria-required-save">Fill required fields, Save</span>
    </div>
  </footer>
</main>
