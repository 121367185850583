import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TkFilterModel} from 'src/app/core/models/tk-filter.model';
import {TkHistoryRequest} from 'src/app/core/models/tk-history-request.model';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import {BentoAlertItemOptions} from '@bento/bento-ng/lib';

import {ActivatedRoute, Router} from '@angular/router';
import {GlobalHeaderServiceService} from '../../../global-header/global-header-service.service';
import {BehaviorSubject} from 'rxjs';
import {ProductionExtractFilterModel} from 'src/app/core/models/production-extract-filter.model';
import {ProductionExtractRequest} from 'src/app/core/models/productionExtract-request.model';
import {FiscalPeriod} from 'src/app/core/models/fiscal-period.model';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {Typeahead} from 'src/app/core/models/typeahead.model';
import {ProductionExtractResponse} from 'src/app/core/models/production-extract-inquiry-response.model';
import {LocalStorageService} from 'src/app/core/services/local-storage/local-storage.service';
import {PeerCheckServiceService} from '../../../peer-check/peer-check/peer-check-service.service';
import {GlobalService} from 'src/app/core/services/global/global.service';
import $ from 'jquery';

@Component({
  selector: 'app-production-extract',
  templateUrl: './production-extract.component.html',
  styleUrls: ['./production-extract.component.scss'],
})
export class ProductionExtractComponent implements OnInit {
  alerts: BentoAlertItemOptions[] = [];
  isDataLoading = false;
  actionDropdownItems: {name: string; isDisabled: boolean}[];
  isFilterOpen = false;
  isMainBusyLoaderBusy = false;
  fiscalPeriodModel: FiscalPeriod = new FiscalPeriod();
  fiscalDateRange = [];
  isFilterButtonDisabled = false;
  tkFilterData: ProductionExtractFilterModel = new ProductionExtractFilterModel();
  tkHistoryRecordRequest: ProductionExtractRequest = new ProductionExtractRequest();
  resetTKFilterFields: ProductionExtractRequest = new ProductionExtractRequest();
  tkHistoryFilterData: ProductionExtractRequest = new ProductionExtractRequest();
  isDataModified = false;
  showInquiryMenu: any = false;
  validations: {tkFilterFte: {pattern: string}};
  tkFilterFteVal = '(([0.0])|([0].*)|([0].[0-9][0-9])|([1.0]))';
  isColumnFreezed = false;
  totalCount: any;
  gridData: any;
  sortColumnsMap: any = {};
  gridData1: any;
  fmsType: any;
  enableClearFilter = false;
  pageInfo: any = {
    infoText: '_START_ to _END_ of _MAX_ Users',
    infoPageText: '_PAGE_ of _PAGES_',
    goText: 'Go',
    pageSize: 10,
  };
  CHECKBOX_SELECTED = 1;
  CHECKBOX_UNSELECTED = 2;
  headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
  CHECKBOX_INDETERMINATE = 3;
  selectedRecord = [];
  isFilterReset = false;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  @ViewChild('filter', {static: true}) gridFilter: wjcGridFilter.FlexGridFilter;
  selectedInquiry: any;
  inquirySubscriptions: any;
  errorMessage: any;
  firmId: any;
  peerOffices: Typeahead[] = [];
  peerPractices: Typeahead[] = [];
  peerTitles: Typeahead[] = [];
  firmLocale: any;
  successMsg: string;
  valuesObj: any = {};
  fromData: any;
  toDate: any;
  columnFreezeMessage = 'Freeze Period, First name, Last name, TKINIT and Time type columns';
  columnUnfreezeMessage = 'Unfreeze Period, First name, Last name, TKINIT and Time type columns';
  numberOfCoulmnToFreeze = 6;

  displayColumnsArr = [
    'period',
    'firstName',
    'lastName',
    'tkId',
    'peerOffice',
    'firmOffice',
    'department',
    'section',
    'udf',
    'peerPractice',
    'peerTitle',
    'firmTitle',
    'timeType',
    'gradYear',
  ];

  selectBinding = 'selected';
  valuesCodeMap: any = {
    workedHrs: 'WORKED_HRS',
    workedAmt: 'WORKED_AMT',
    workedRate: 'WORKED_RATE',
    standardRate: 'STANDARD_RATE',
    billedAmt: 'BILLED_AMT',
    billedRate: 'BILLED_RATE',
    collectedAmt: 'COLLECTED_AMT',
    collectedRate: 'COLLECTED_RATE',
    billingRealization: 'BILLING_REALIZE_PCNT',
    collectionRealization: 'COLLECTED_REALIZE_PCNT',
    writeoffAmt: 'WRITEOFF_AMT',
    fteValue: 'FTE_VALUE',
  };
  tkHistoryRecordResponse: ProductionExtractResponse = new ProductionExtractResponse();
  tkHistoryRecordResponseOriginal: ProductionExtractResponse = new ProductionExtractResponse();

  constructor(
    private globalHeaderService: GlobalHeaderServiceService,
    private authService: AuthService,
    private service: BaseService,
    private router: Router,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private _peerCheckService: PeerCheckServiceService,
    private globalservice: GlobalService
  ) {
    this.gridData = new wjcCore.CollectionView(this.tkHistoryRecordResponse.details);
    this.gridData.pageSize = this.pageInfo.pageSize;
    this.actionDropdownItems = [
      {name: 'Export selected', isDisabled: false},
      {name: 'Import', isDisabled: false},
    ];

    this.validations = {
      tkFilterFte: {
        pattern: 'Error: Value should be between 0 to 1',
      },
    };
  }

  onPersonChange() {
    const index = this.displayColumnsArr.indexOf('firstName');
    //const index1 = this.displayColArr.indexOf('lastName');

    if (!this.tkFilterData.isPersonVisible) {
      this.displayColumnsArr.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArr.push('firstName');
      }
    }
    const index1 = this.displayColumnsArr.indexOf('lastName');
    if (this.tkFilterData.isPersonVisible == false) {
      this.displayColumnsArr.splice(index1, 1);
    } else {
      if (index1 == -1) {
        this.displayColumnsArr.push('lastName');
      }
    }
    const index2 = this.displayColumnsArr.indexOf('tkId');
    if (!this.tkFilterData.isPersonVisible) {
      this.displayColumnsArr.splice(index2, 1);
    } else {
      if (index2 == -1) {
        this.displayColumnsArr.push('tkId');
      }
    }
  }
  onPeriodChange() {
    const index = this.displayColumnsArr.indexOf('period');
    if (!this.tkFilterData.isPeriodVisible) {
      this.displayColumnsArr.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArr.push('period');
      }
    }
  }
  onTitleChange() {
    const index = this.displayColumnsArr.indexOf('firmTitle');
    if (this.tkFilterData.isTitleVisible == false) {
      this.displayColumnsArr.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArr.push('firmTitle');
      }
    }
  }
  onOfficeChange() {
    const index = this.displayColumnsArr.indexOf('firmOffice');
    if (this.tkFilterData.isOfficeVisible == false) {
      this.displayColumnsArr.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArr.push('firmOffice');
      }
    }
  }
  onDeptChange() {
    const index = this.displayColumnsArr.indexOf('department');
    if (this.tkFilterData.isDeptVisible == false) {
      this.displayColumnsArr.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArr.push('department');
      }
    }
  }

  onSectionChange() {
    const index = this.displayColumnsArr.indexOf('section');
    if (this.tkFilterData.isSectionVisible == false) {
      this.displayColumnsArr.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArr.push('section');
      }
    }
  }

  onUdfChange() {
    const index = this.displayColumnsArr.indexOf('udf');
    if (this.tkFilterData.isUdfVisible == false) {
      this.displayColumnsArr.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArr.push('udf');
      }
    }
  }

  onPeerOfficeChange() {
    const index = this.displayColumnsArr.indexOf('peerOffice');
    if (this.tkFilterData.isPeerOfficeVisible == false) {
      this.displayColumnsArr.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArr.push('peerOffice');
      }
    }
  }

  onPeerPracticeChange() {
    const index = this.displayColumnsArr.indexOf('peerPractice');
    if (this.tkFilterData.isPeerPracticeVisible == false) {
      this.displayColumnsArr.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArr.push('peerPractice');
      }
    }
  }

  onPeerTitleChange() {
    const index = this.displayColumnsArr.indexOf('peerTitle');
    if (this.tkFilterData.isPmTitleVisible == false) {
      this.displayColumnsArr.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArr.push('peerTitle');
      }
    }
  }

  onGradYearChange() {
    const index = this.displayColumnsArr.indexOf('gradYear');
    if (this.tkFilterData.isGradYear == false) {
      this.displayColumnsArr.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArr.push('gradYear');
      }
    }
  }

  onTimeTypeChange() {
    const index = this.displayColumnsArr.indexOf('timeType');
    if (!this.tkFilterData.isTimeTypeVisible) {
      this.displayColumnsArr.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArr.push('timeType');
      }
    }
  }
  getInquiryFeatureFlag(): any {
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/INQUIRY_MENU', '')
      .toPromise()
      .then((result) => {
        return result['INQUIRY_MENU'];
      });
  }

  async ngOnInit() {
    //Filter Body scroll method call
    var filterbodyId = 'FilterPanel-body-scroll-pr';
    this._peerCheckService.filterBodyMaxheightScoll(filterbodyId);

    this.showInquiryMenu = await this.getInquiryFeatureFlag();
    this._peerCheckService.setBalancingValue({selected: 'Default', value: ''});
    this.route.params.subscribe((val) => {
      if (val && val.id) {
        this.firmId = val.id;
      } else {
        this.firmId = this.getfirmId;
      }
      if (this._peerCheckService.isValidMappingFirm(this.firmId) && this.showInquiryMenu) {
        this.getFms();
        this.enableClearFilter = false;
        this.firmLocale = this.localStorageService.get('firmLocale');
        this.getInquiryProductionExtractFilterPanelTimeType();
        this.getInquiryProductionExtractFilterPanelPerson();
        this.getInquiryProductionExtractFilterPanelOffice();
        this.getInquiryProductionExtractFilterPanelDept();
        this.getInquiryProductionExtractFilterPanelSection();

        this.getInquiryProductionExtractFilterPanelValues();
        this.getInquiryProductionExtractFilterPanelPracticeGroups();
        this.getInquiryProductionExtractFilterPanelPmTitle();
        this.getInquiryProductionExtractFilterPanelTitle();
        this.getInquiryProductionExtractFilterPanelTKIDS();
        this.getInquiryProductionExtractFilterPanelPeerOffice();
        this.getInquiryProductionExtractFilterPanelPeerPractice();
      }
    });

    this.tkHistoryRecordRequest.displayColumns = [
      'period',
      'firstName',
      'lastName',
      'tkId',
      'peerOffice',
      'firmOffice',
      'department',
      'section',
      'udf',
      'peerPractice',
      'peerTitle',
      'firmTitle',
      'timeType',
      'gradYear',
    ];
    this.sortColumnsMap = {
      period: 'period',
      firstName: 'firstName',
      lastName: 'lastName',
      tkId: 'tkId',
      timeType: 'timeType',
      firmOfficeDesc: 'firmOffice',
      peerOfficeDesc: 'peerOffice',
      peerPracticeDesc: 'peerPractice',
      peerTitleDesc: 'peerTitle',
      departmentDesc: 'department',
      sectionDesc: 'section',
      udfDesc: 'udf',
      workedHrs: 'worked_hrs',
      workedAmt: 'worked_amt',
      workedRate: 'worked_rate',
      standardRate: 'standard_rate',
      billedAmt: 'billed_amt',
      billedRate: 'billed_rate',
      collectedAmt: 'collected_amt',
      collectedRate: 'collected_rate',
      billingRealizePcnt: 'billing_realize_pcnt',
      collectedRealizePcnt: 'collected_realize_pcnt',
      writeOffAmt: 'writeoff_amt',
      fteValue: 'fte_value',
    };
    this.filterPanelFocus();
    this.loopfilter();
  }

  getFms() {
   
    this.service
      .get(environment.FIClientBaseEndpoint + 'v1/firm/firmparameters/' + this.firmId, '')
      .subscribe((result) => {
      
        this.fmsType = result.financialManagementSystem.financialManagementSystem;
        if (this.fmsType == '4' || this.fmsType == '5' || this.fmsType == '3') {
          this.columnFreezeMessage = 'Freeze Period, Name, TKINIT and Time type columns';
          this.columnUnfreezeMessage = 'Unfreeze Period, Name, TKINIT and Time type columns';
          this.numberOfCoulmnToFreeze = 6;
        } else {
          this.columnFreezeMessage = 'Freeze Period, First name, Last name, TKINIT and Time type columns';
          this.columnUnfreezeMessage = 'Unfreeze Period, First name, Last name, TKINIT and Time type columns';
          this.numberOfCoulmnToFreeze = 6;
        }
      });
  }

  ngOnDestroy(): void {
    if (this.inquirySubscriptions) {
      this.inquirySubscriptions.unsubscribe();
    }
  }

  dropDownOnSelectChange(name) {
    // Write code to perform an action on selected option from action dropdown.
  }

  onClickFilter(newValue: boolean) {
    if (this.isFilterOpen === newValue) {
      this.isFilterOpen = false;
    } else {
      this.isFilterOpen = newValue;
    }
  }

  validateNumber(v: any) {
    if (v.value == '') v.value = v.value.slice(0, -1);
  }

  onFiscalPeriodChange(fiscalData: any, shouldRetrieveData: boolean = false) {
    this.fiscalPeriodModel = JSON.parse(fiscalData);
    if (
      Number(this.fiscalPeriodModel.from_date) > Number(this.fiscalPeriodModel.to_date) &&
      this.fiscalPeriodModel.to_date != null
    ) {
      this.isFilterButtonDisabled = true;
    } else {
      this.isFilterButtonDisabled = false;
    }
    if (shouldRetrieveData) {
      this.tkHistoryRecordRequest.fiscalPeriodFrom = this.fiscalPeriodModel.to_date;
      this.fromData = this.fiscalPeriodModel.to_date;
      this.toDate = this.fiscalPeriodModel.to_date;
    } else {
      this.tkHistoryRecordRequest.fiscalPeriodFrom = this.fiscalPeriodModel.from_date;
    }
    this.tkHistoryRecordRequest.fiscalPeriodTo = this.fiscalPeriodModel.to_date;

    if (shouldRetrieveData) {
      this.retrieveProductionExtractRecords();
    }
  }

  validateNumberWith(e) {
    if (e.keyCode == 43 || e.keyCode == 45 || e.keyCode == 101) {
      return false;
    }
  }

  generateRequestbody() {
    this.tkHistoryRecordRequest.displayColumns = this.displayColumnsArr;
    return this.tkHistoryRecordRequest;
  }

  onTimeTypeSelected(timeTypes: any[]) {
    this.tkHistoryRecordRequest.timeType = [];
    if (timeTypes.length == 1) {
      this.tkFilterData.selectedTimeTypeText = '1 TimeType selected';
    } else if (timeTypes.length == this.tkFilterData.timeTypes.length) {
      this.tkFilterData.selectedTimeTypeText = 'All';
    } else this.tkFilterData.selectedTimeTypeText = timeTypes.length.toString() + ' names selected';
    for (var timeType of timeTypes) {
      this.tkHistoryRecordRequest.timeType.push(timeType);
    }
  }

  onLocSelected(locs: any[]) {
    this.tkHistoryRecordRequest.loc = [];
    if (locs.length == 1) {
      this.tkFilterData.selectedLocText = '1 loc selected';
    } else if (locs.length == this.tkFilterData.loc.length) {
      this.tkFilterData.selectedLocText = 'All';
    } else this.tkFilterData.selectedLocText = locs.length.toString() + ' loc selected';
    for (var loc of locs) {
      this.tkHistoryRecordRequest.loc.push(loc);
    }
  }

  onTkinitItemsSelected(tkIds: any[]) {
    this.tkHistoryRecordRequest.tkId = [];
    if (tkIds.length == 1) {
      this.tkFilterData.selectedTkinitsText = '1 tkId selected';
    } else if (tkIds.length == this.tkFilterData.tkId.length) {
      this.tkFilterData.selectedTkinitsText = 'All';
    } else this.tkFilterData.selectedTkinitsText = tkIds.length.toString() + ' tkIds selected';
    for (var tkId of tkIds) {
      this.tkHistoryRecordRequest.tkId.push(tkId);
    }
  }

  onValueSelected(values: any[]) {
    this.tkHistoryRecordRequest.values = [];

    this.tkHistoryFilterData.values = values;
    if (values.length == 1) {
      this.tkFilterData.selectedValuesTypeText = '1 value selected';
    } else if (values.length == this.tkFilterData.values.length) {
      this.tkFilterData.selectedValuesTypeText = 'All';
    } else this.tkFilterData.selectedValuesTypeText = values.length.toString() + ' values selected';
    for (var value of values) {
      this.tkHistoryRecordRequest.values.push(value.key);
    }
    if (this.tkHistoryFilterData.values.length == 0) {
      this.alerts.push({
        type: 'warning',
        msg: 'Values cannot be empty',
        closeable: true,
      });
    }
  }

  onPmTitleSelected(peerTitles: any[]) {
    this.tkHistoryRecordRequest.peerTitle = [];
    if (peerTitles.length == 1) {
      this.tkFilterData.selectedPeerTitleText = '1 peer title selected';
    } else if (peerTitles.length == this.tkFilterData.peerTitles.length) {
      this.tkFilterData.selectedPeerTitleText = 'All';
    } else this.tkFilterData.selectedPeerTitleText = peerTitles.length.toString() + ' peer titles selected';
    for (var title of peerTitles) {
      let peerTitle = Object.keys(this.tkFilterData.peerTitleKeyValue).find(
        (key) => this.tkFilterData.peerTitleKeyValue[key] === title
      );
      this.tkHistoryRecordRequest.peerTitle.push(peerTitle);
    }
  }

  onPeerPracticeSelected(peerPractices: any[]) {
    this.tkHistoryRecordRequest.pmPractice = [];
    if (peerPractices.length == 1) {
      this.tkFilterData.selectedPeerPracticeText = '1 peer practice selected';
    } else if (peerPractices.length == this.tkFilterData.peerPractices.length) {
      this.tkFilterData.selectedPeerPracticeText = 'All';
    } else this.tkFilterData.selectedPeerPracticeText = peerPractices.length.toString() + ' peer practices selected';
    for (var practice of peerPractices) {
      let peerPractice = Object.keys(this.tkFilterData.peerPracticeKeyValue).find(
        (key) => this.tkFilterData.peerPracticeKeyValue[key] === practice
      );
      this.tkHistoryRecordRequest.pmPractice.push(peerPractice);
    }
  }

  onTitleSelected(titles: any[]) {
    this.tkHistoryRecordRequest.firmTitle = [];
    if (titles.length == 1) {
      this.tkFilterData.selectedFirmTitleText = '1 title selected';
    } else if (titles.length == this.tkFilterData.titles.length) {
      this.tkFilterData.selectedFirmTitleText = 'All';
    } else this.tkFilterData.selectedFirmTitleText = titles.length.toString() + ' pmTitles selected';
    for (var title of titles) {
      this.tkHistoryRecordRequest.firmTitle.push(title);
    }
  }

  onSectionSelected(sections: any[]) {
    this.tkHistoryRecordRequest.section = [];
    if (sections.length == 1) {
      this.tkFilterData.selectedSectionText = '1 Section selected';
    } else if (sections.length == this.tkFilterData.section.length) {
      this.tkFilterData.selectedSectionText = 'All';
    } else this.tkFilterData.selectedSectionText = sections.length.toString() + ' sections selected';
    for (var section of sections) {
      this.tkHistoryRecordRequest.section.push(section);
    }
  }

  searchCompare(item: any, search: string) {
    const name = item;
    const searchStr = search.toLowerCase();
    return name.toLowerCase().indexOf(searchStr) > -1;
  }

  onPersonSelected(persons: any[]) {
    this.tkHistoryRecordRequest.name = [];
    if (persons.length == 1) {
      this.tkFilterData.selectedPersonText = '1 name selected';
    } else if (persons.length == this.tkFilterData.persons.length) {
      this.tkFilterData.selectedPersonText = 'All';
    } else this.tkFilterData.selectedPersonText = persons.length.toString() + ' names selected';
    for (var person of persons) {
      this.tkHistoryRecordRequest.name.push(person);
    }
  }

  // onTitleSelected(titles: any[]) {
  //   this.tkHistoryRecordRequest.firmTitle = [];
  //   if (titles.length == 1) {
  //     this.tkFilterData.selectedFirmTitleText = '1 title selected';
  //   } else if (titles.length == this.tkFilterData.titles.length) {
  //     this.tkFilterData.selectedFirmTitleText = 'All';
  //   } else this.tkFilterData.selectedFirmTitleText = titles.length.toString() + ' titles selected';
  //   for (var title of titles) {
  //     let firmTitle = Object.keys(this.tkFilterData.titleKeyValue).find(
  //       (key) => this.tkFilterData.titleKeyValue[key] === title
  //     );
  //     this.tkHistoryRecordRequest.firmTitle.push(firmTitle);
  //   }
  // }
  onOfficeSelected(offices: any[]) {
    this.tkHistoryRecordRequest.firmOffice = [];
    if (offices.length == 1) {
      this.tkFilterData.selectedFirmOfficeText = '1 office selected';
    } else if (offices.length == this.tkFilterData.offices.length) {
      this.tkFilterData.selectedFirmOfficeText = 'All';
    } else this.tkFilterData.selectedFirmOfficeText = offices.length.toString() + ' offices selected';
    for (var office of offices) {
      let firmOffice = Object.keys(this.tkFilterData.officeKeyValue).find(
        (key) => this.tkFilterData.officeKeyValue[key] === office
      );
      this.tkHistoryRecordRequest.firmOffice.push(firmOffice);
    }
  }
  onDeptSelected(departments: any[]) {
    this.tkHistoryRecordRequest.department = [];
    if (departments.length == 1) {
      this.tkFilterData.selectedDeptText = '1 department selected';
    } else if (departments.length == this.tkFilterData.departments.length) {
      this.tkFilterData.selectedDeptText = 'All';
    } else this.tkFilterData.selectedDeptText = departments.length.toString() + ' departments selected';
    for (var department of departments) {
      let dept = Object.keys(this.tkFilterData.deptKeyValue).find(
        (key) => this.tkFilterData.deptKeyValue[key] === department
      );
      this.tkHistoryRecordRequest.department.push(dept);
    }
  }
  onPeerOfficeSelected(peerOffices: any[]) {
    this.tkHistoryRecordRequest.peerOffice = [];
    if (peerOffices.length == 1) {
      this.tkFilterData.selectedPeerOfficeText = '1 peer office selected';
    } else if (peerOffices.length == this.tkFilterData.peerOffices.length) {
      this.tkFilterData.selectedPeerOfficeText = 'All';
    } else this.tkFilterData.selectedPeerOfficeText = peerOffices.length.toString() + ' peer offices selected';
    for (var office of peerOffices) {
      let peerOffice = Object.keys(this.tkFilterData.peerOfficeKeyValue).find(
        (key) => this.tkFilterData.peerOfficeKeyValue[key] === office
      );
      this.tkHistoryRecordRequest.peerOffice.push(peerOffice);
    }
  }
  onPracticeGroupSelected(practiceGroups: any[]) {
    this.tkHistoryRecordRequest.practiceGroup = [];
    if (practiceGroups.length == 1) {
      this.tkFilterData.selectedPracticeGroupText = '1 practice group selected';
    } else if (practiceGroups.length == this.tkFilterData.practiceGroups.length) {
      this.tkFilterData.selectedPracticeGroupText = 'All';
    } else this.tkFilterData.selectedPracticeGroupText = practiceGroups.length.toString() + ' practicr groups selected';
    for (var practiceGroup of practiceGroups) {
      this.tkHistoryRecordRequest.practiceGroup.push(practiceGroup);
    }
  }
  onPeerTitleSelected(peerTitles: any[]) {
    this.tkHistoryRecordRequest.peerTitle = [];
    if (peerTitles.length == 1) {
      this.tkFilterData.selectedPeerTitleText = '1 peer title selected';
    } else if (peerTitles.length == this.tkFilterData.peerTitles.length) {
      this.tkFilterData.selectedPeerTitleText = 'All';
    } else this.tkFilterData.selectedPeerTitleText = peerTitles.length.toString() + ' peer titles selected';
    for (var title of peerTitles) {
      let peerTitle = Object.keys(this.tkFilterData.peerTitleKeyValue).find(
        (key) => this.tkFilterData.peerTitleKeyValue[key] === title
      );
      this.tkHistoryRecordRequest.peerTitle.push(peerTitle);
    }
  }

  isAllSelected() {
    return this.headerCheckBoxMode === this.CHECKBOX_SELECTED;
  }

  isIndeterminate() {
    return this.headerCheckBoxMode === this.CHECKBOX_INDETERMINATE;
  }

  saveSelection(selectedOption) {
    const index = this.selectedRecord.indexOf(selectedOption);
    if (index != -1) {
      this.selectedRecord.splice(index, 1, selectedOption);
    } else {
      this.selectedRecord.push(selectedOption);
    }
  }

  removeSelection(selectedOption) {
    const index = this.selectedRecord.indexOf(selectedOption);
    if (index != -1) {
      this.selectedRecord.splice(index, 1);
    }
  }

  // Event Handler for Multi-Select Column Header Checkbox
  onHeaderCheckBoxChange() {
    let selected = false;
    if (this.headerCheckBoxMode !== this.CHECKBOX_SELECTED) {
      this.headerCheckBoxMode = this.CHECKBOX_SELECTED;
      selected = true;
    } else {
      this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
      selected = false;
    }
    if (this.flexGrid.rows) {
      for (let i = 0, ttl = this.flexGrid.rows.length; i < ttl; i++) {
        this.flexGrid.rows[i].dataItem[this.selectBinding] = selected;
        this.flexGrid.rows[i].cssClass = selected ? 'row-selected' : '';
        selected
          ? this.saveSelection(this.flexGrid.rows[i].dataItem)
          : this.removeSelection(this.flexGrid.rows[i].dataItem);
      }
    }
    // this.updateActionDropdownItems();
    // this.setEditFields();
  }

  onCheckBoxChange() {
    this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
    let count = 0;

    if (this.flexGrid.rows) {
      for (let i = 0, ttl = this.flexGrid.rows.length; i < ttl; i++) {
        if (this.flexGrid.rows[i].dataItem[this.selectBinding] === true) {
          count++;
          this.flexGrid.rows[i].cssClass = 'row-selected';
          this.saveSelection(this.flexGrid.rows[i].dataItem);
        } else {
          this.removeSelection(this.flexGrid.rows[i].dataItem);
          this.flexGrid.rows[i].cssClass = '';
        }
      }
      if (count === this.flexGrid.rows.length) {
        this.headerCheckBoxMode = this.CHECKBOX_SELECTED;
      } else if (count > 0) {
        this.headerCheckBoxMode = this.CHECKBOX_INDETERMINATE;
      }
    }
    // this.setEditFields();
    // this.updateActionDropdownItems();
  }

  gridInitialized(flexGrid) {
    if (flexGrid.rows) {
      flexGrid.rows.defaultSize = 56;
      flexGrid.columnHeaders.rows.defaultSize = 40;
    }
    this.flexGrid = flexGrid;
  }

  initFilter() {
    this.gridFilter.showSortButtons = false;
  }

  // Pagination Size Changed Event Handler
  onPageSizeChanged(size) {
    this.gridData.pageSize = size;
    this.tkHistoryRecordRequest.size = size;
    this.pageInfo.pageSize = size;
    this.onCheckBoxChange();
  }

  // Pagination Page Changed Event Handler
  onPageChanged(page) {
    this.gridData.moveToPage(page - 1);
    this.tkHistoryRecordRequest.page = page;
    this.onCheckBoxChange();
    this.retrieveProductionExtractRecords();
  }

  sortGrid(headerName: string, curSort: any) {
    const currentSort = curSort.currentSort == '+' ? 'ASC' : 'DESC';
    this.tkHistoryRecordRequest.sortColumns = [this.sortColumnsMap[headerName]];
    this.tkHistoryRecordRequest.sortDir = currentSort;
    this.retrieveProductionExtractRecords(null, true, {
      headerName: headerName,
      curSort: curSort.currentSort == '+' ? true : false,
    });
  }

  onFilterChange() {
    this.enableClearFilter = true;
  }

  filterPanelFocus() {
    $('.filter-common-btn-open').click(function () {
      setTimeout(function () {
        $('#isPeriodChecked').focus();
      }, 0);
    });
  }
  loopfilter(){
    $('.filter-common-btn-loop').focus(function () {
      setTimeout(function () {
        $('#close').focus();
      }, 0);
    });
  }
  retrieveProductionExtractRecords(
    isFilter: boolean = false,
    isSorting: boolean = false,
    sortObj: any = {},
    isReset: boolean = false
  ) {
    if (isFilter) {
      this.enableClearFilter = true;
      this.isFilterOpen = false;
    }
    if (this.tkHistoryRecordRequest.values.length > 0 || isReset) {
      const requestBody = this.generateRequestbody();
      if (requestBody.fiscalPeriodFrom && requestBody.fiscalPeriodTo) {
        this.isDataLoading = true;
        this.service
          .post(environment.FIClientBaseEndpoint + 'v1/inquiry/prodExtract/' + this.getfirmId, requestBody)
          .subscribe(
            (result) => {
              this.isDataLoading = false;
              this.tkHistoryRecordResponse = result;
              for (let i = 0; i < this.tkHistoryRecordResponse.details.length; i++) {
                this.tkHistoryRecordResponse.details[i].period += '';
                this.tkHistoryRecordResponse.details[i].gradYear += '';
              }
              this.gridData = new wjcCore.CollectionView(result.details);
              if (isSorting) {
                const sortDir = new wjcCore.SortDescription(sortObj.headerName, sortObj.curSort);
                this.gridData.sortDescriptions.push(sortDir);
              }
              this.gridData.pageSize = this.pageInfo.pageSize;
              this.totalCount = result.totalCount;
              this.globalservice.addPaginationAdditionalInfo();
            },
            (error) => {
              this.errorMessage = error.error;
              if (this.errorMessage.message != '') {
                this.alerts.push({
                  type: 'warning',
                  msg: this.errorMessage.message,
                  closeable: true,
                });
              } else {
                this.alerts.push({
                  type: 'warning',
                  msg: 'Something went wrong, please try again.',
                  closeable: true,
                });
              }
              this.isDataLoading = false;
            }
          );
      }
    } else {
      if (isFilter)
        this.alerts.push({
          type: 'warning',
          msg: 'Values cannot be empty',
          closeable: true,
        });
      this.isDataLoading = false;
    }
  }

  getInquiryProductionExtractFilterPanelTimeType() {
    this.tkFilterData.timeTypeStream = new BehaviorSubject(
      (() => {
        const a = ['Billable', 'Contingency'];
        this.tkFilterData.timeTypes = a;
        return a;
      })()
    );
    this.tkHistoryFilterData.timeType = ['Billable'];
    this.tkHistoryRecordRequest.timeType = ['Billable'];
    this.tkFilterData.selectedTimeTypeText = '1 Time Type Selected';
  }

  getInquiryProductionExtractFilterPanelPerson() {
    this.service.get(environment.FIClientBaseEndpoint + 'v1/timekeeper/name/', this.getfirmId).subscribe(
      (result) => {
        this.tkFilterData.persons = result;

        this.tkFilterData.personStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (let i = 0; i < this.tkFilterData.persons.length; i++) {
              a.push(this.tkFilterData.persons[i]);
            }
            return a;
          })()
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getInquiryProductionExtractFilterPanelOffice() {
    var officeDescription = [];
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/office/pm/', this.getfirmId).subscribe(
      (result) => {
        this.tkFilterData.offices = result;
        for (var office of this.tkFilterData.offices) {
          this.tkFilterData.officeKeyValue[office['firmCode']] = office['firmDescription'];
          officeDescription.push(office['firmDescription']);
        }
        this.tkFilterData.firmOfficeStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (var office of officeDescription) {
              a.push(office);
            }
            return a;
          })()
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getInquiryProductionExtractFilterPanelDept() {
    var deptDescription = [];
    this.service.get(environment.FIClientBaseEndpoint + 'v1/department/', this.getfirmId).subscribe(
      (result) => {
        this.tkFilterData.departments = result;
        for (var dept of this.tkFilterData.departments) {
          this.tkFilterData.deptKeyValue[dept['code']] = dept['label'];
          deptDescription.push(dept['label']);
        }
        this.tkFilterData.departmentStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (var dept of deptDescription) {
              a.push(dept);
            }
            return a;
          })()
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getInquiryProductionExtractFilterPanelSection() {
    this.tkFilterData.sectionStream = new BehaviorSubject(
      (() => {
        const a = ['section1', 'section2'];
        this.tkFilterData.section = a;
        return a;
      })()
    );
  }

  getInquiryProductionExtractFilterPanelValues() {
    this.service.get(environment.FIClientBaseEndpoint + 'v1/inquiry/values', '').subscribe(
      (result) => {
        this.tkHistoryRecordRequest.values = result.map((res) => {
          return res.inquiryValueCode;
        });

        this.tkFilterData.values = result;

        this.tkHistoryFilterData.values = result.map((res) => {
          return {key: res.inquiryValueCode};
        });

        this.tkFilterData.selectedValuesTypeText = 'All';
        for (let i = 0; i < result.length; i++) {
          this.valuesObj[result[i].inquiryValueCode] = result[i].inquiryValueDescription;
        }
        this.tkFilterData.valuesStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (let i = 0; i < result.length; i++) {
              a.push({key: result[i].inquiryValueCode});
            }

            return a;
          })()
        );
        this.retrieveProductionExtractRecords();
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getInquiryProductionExtractFilterPanelPmTitle() {
    var peerTitleDescription = [];
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/title/pm/', this.firmLocale).subscribe(
      (result) => {
        this.tkFilterData.peerTitles = result;
        this.peerTitles = result;
        this.tkHistoryRecordResponse.details.forEach((o) => {
          let peerTitle = this.peerTitles.find((obj) => {
            return obj.code == o.peerTitle;
          });
          o.peerTitle = peerTitle != undefined ? peerTitle.description : null;
        });
        this.tkHistoryRecordResponseOriginal = JSON.parse(JSON.stringify(this.tkHistoryRecordResponse));
        for (var title of this.tkFilterData.peerTitles) {
          this.tkFilterData.peerTitleKeyValue[title['code']] = title['description'];
          peerTitleDescription.push(title['description']);
        }
        this.tkFilterData.peerTitleStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (var title of peerTitleDescription) {
              a.push(title);
            }
            return a;
          })()
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getInquiryProductionExtractFilterPanelTitle() {
    var titleDescription = [];
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/title/', this.getfirmId).subscribe(
      (result) => {
        this.tkFilterData.titles = result;
        for (var title of this.tkFilterData.titles) {
          this.tkFilterData.titleKeyValue[title['firmCode']] = title['firmDescription'];
          titleDescription.push(title['firmDescription']);
        }
        this.tkFilterData.firmTitleStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (var title of titleDescription) {
              a.push(title);
            }
            return a;
          })()
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getInquiryProductionExtractFilterPanelPracticeGroups() {
    this.tkFilterData.practiceGroupStream = new BehaviorSubject(
      (() => {
        const a = ['practice group1', 'practice group2'];
        this.tkFilterData.practiceGroups = a;
        return a;
      })()
    );
  }

  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (this.firmId) return this.firmId;
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : parseInt(this.route.snapshot.paramMap.get('id'));
  }

  getInquiryProductionExtractFilterPanelTKIDS() {
    this.service.get(environment.FIClientBaseEndpoint + 'v1/timekeeper/id/', this.getfirmId).subscribe(
      (result) => {
        this.tkFilterData.tkId = result;
        this.tkFilterData.tkinitStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (var tkid of this.tkFilterData.tkId) {
              a.push(tkid);
            }
            return a;
          })()
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );

    // this.tkFilterData.tkinitStream = new BehaviorSubject(
    //   (() => {
    //     const a = ['tkId1', 'tkId2'];
    //     this.tkFilterData.tkId = a;
    //     return a;
    //   })()
    // );
  }

  getInquiryProductionExtractFilterPanelPeerOffice() {
    var peerOfficeDescription = [];
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/office/pm', '').subscribe(
      (result) => {
        this.tkFilterData.peerOffices = result;
        this.peerOffices = result;
        this.tkHistoryRecordResponse.details.forEach((o) => {
          let peerOffice = this.peerOffices.find((obj) => {
            return obj.code == o.peerOffice;
          });
          o.peerOffice = peerOffice != undefined ? peerOffice.description : null;
        });
        this.tkHistoryRecordResponseOriginal = JSON.parse(JSON.stringify(this.tkHistoryRecordResponse));
        for (var office of this.tkFilterData.peerOffices) {
          this.tkFilterData.peerOfficeKeyValue[office['code']] = office['description'];
          peerOfficeDescription.push(office['description']);
        }
        this.tkFilterData.peerOfficeStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (var office of peerOfficeDescription) {
              a.push(office);
            }
            return a;
          })()
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getInquiryProductionExtractFilterPanelPeerPractice() {
    var peerPracticeDescription = [];
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/practice/pm/', this.firmLocale).subscribe(
      (result) => {
        this.tkFilterData.peerPractices = result;
        this.peerPractices = result;
        this.tkHistoryRecordResponse.details.forEach((o) => {
          let peerPractice = this.peerPractices.find((obj) => {
            return obj.code == o.peerPractice;
          });
          o.peerPractice = peerPractice != undefined ? peerPractice.description : null;
        });
        this.tkHistoryRecordResponseOriginal = JSON.parse(JSON.stringify(this.tkHistoryRecordResponse));
        for (var practice of this.tkFilterData.peerPractices) {
          this.tkFilterData.peerPracticeKeyValue[practice['code']] = practice['description'];
          peerPracticeDescription.push(practice['description']);
        }
        this.tkFilterData.peerPracticeStream = new BehaviorSubject(
          (() => {
            const a = [];
            for (var practice of peerPracticeDescription) {
              a.push(practice);
            }
            return a;
          })()
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  onBentoAlertOccured(alerts: BentoAlertItemOptions[]) {
    this.alerts = [...this.alerts, ...alerts];
  }

  resetTKFilter(val: boolean = false) {
    if (val) {
      if (typeof this.resetTKFilterFields !== 'undefined') {
        const requestValues = this.tkHistoryRecordRequest.values;
        const requestDisplayCols = this.tkHistoryRecordRequest.displayColumns;
        this.tkHistoryRecordRequest = JSON.parse(JSON.stringify(this.resetTKFilterFields));
        this.tkHistoryFilterData = JSON.parse(JSON.stringify(this.resetTKFilterFields));
        this.tkHistoryRecordRequest.values = requestValues;
        this.getInquiryProductionExtractFilterPanelValues();
        this.tkHistoryRecordRequest.displayColumns = requestDisplayCols;
        this.tkHistoryFilterData.displayColumns = requestDisplayCols;
        this.fiscalPeriodModel.to_date = this.toDate;
        this.fiscalPeriodModel.from_date = this.toDate;
        this.tkHistoryFilterData.timeType = ['Billable'];
        this.tkHistoryRecordRequest.timeType = ['Billable'];
        this.tkFilterData.selectedTimeTypeText = '1 Time Type Selected';
        this.tkHistoryRecordRequest.fiscalPeriodFrom = this.fiscalPeriodModel.from_date;
        this.tkHistoryRecordRequest.fiscalPeriodTo = this.fiscalPeriodModel.to_date;
      }
    }
    this.isFilterReset = true;
    this.retrieveProductionExtractRecords(false, false, {}, true);
    if (this.isDataModified == false) {
      this.successMsg = 'Data reset successfully.';
      this.alerts.push({
        type: 'success',
        msg: this.successMsg,
        timeout: 2500,
        closeable: true,
      });
    }

    this.isFilterReset = false;
    this.flexGrid.refresh();
    this.enableClearFilter = false;
  }

  showValue(key) {
    return this.tkHistoryFilterData.values.map((item) => item['key']).indexOf(key) > -1;
  }
}
