import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-svg-exclamation',
  templateUrl: './svg-exclamation.component.svg',
})
export class SvgExclamationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
