import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {Departments} from '../../models/glac-override.model';

@Injectable({
  providedIn: 'root',
})
export class BulkeditService {
  selectedRecords: [] = [];
  glacMappedOfficeName: [] = [];
  serviceFor: String = 'Bulk edit';
  departments: Departments[] = [];
  preserveFilter: any;

  constructor() {}

  getDepartmentRecords(term) {
    let departments: String[] = [...new Set(this.departments.map((item) => item.label))];
    return of(
      departments.filter((obj) => {
        return obj.toLowerCase().indexOf(term.toLowerCase()) > -1;
      })
    );
  }

  setDepartmentRecords(departments) {
    this.departments = departments;
  }

  getSelectedRecords() {
    return this.selectedRecords;
  }

  setSelectedRecords(selectedRecord) {
    this.selectedRecords = selectedRecord;
  }
  getPreserveFilter() {
    return this.preserveFilter;
  }
  setPreserveFilter(preserveFilter) {
    this.preserveFilter = preserveFilter;
  }

  getglacMappedOfficeName() {
    return this.glacMappedOfficeName;
  }

  setglacMappedOfficeName(glacMappedOfficeName) {
    this.glacMappedOfficeName = glacMappedOfficeName;
  }

  getServiceFor() {
    return this.serviceFor;
  }

  setServiceFor(serviceFrom) {
    this.serviceFor = serviceFrom;
  }
}
