import {Component, OnInit, HostBinding} from '@angular/core';
import {PeerCheckServiceService} from '../peer-check/peer-check-service.service';

@Component({
  selector: 'app-peer-check',
  templateUrl: './peer-check.component.html',
})
export class PeerCheckComponent implements OnInit {
  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }

  constructor() {}

  ngOnInit(): void {}
}
