<div [bentoBusyLoader]="isDataLoading">
  <div class="form-group Form-input--small" *ngIf="showStatistic">
    <label for="statistic">Statistic</label>
    <select
      name="statistic"
      id="statistic"
      class="form-control"
      bentoSelect
      [(ngModel)]="statistic"
      (change)="onChangeStatistic($event)"
    >
      <option *ngFor="let statistic of statistics">{{ statistic }}</option>
    </select>
  </div>
  <div class="bento-tabset tabs-top">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-tabs" ngPreserveWhitespaces>
      <li [ngbNavItem]="title" id="{{ title }}" *ngFor="let title of tabData">
        <a ngbNavLink>
          <bento-tabs-label [label]="title"></bento-tabs-label>
        </a>
        <ng-template ngbNavContent *ngIf="activeTab === 'Offices'">
          <app-expand-table-offices-expenses
            [metricId]="metricId"
            [metricBasicData]="metricBasicData"
            [highLowPercentilesCols]="highLowPercentilesCols"
            class="u-flexGrowCol"
            [peerGroupItems]="peerGroupSelectedItems"
            [pivotIndex]="pivotIndex"
            [selectedFirmId]="selectedFirm"
            [expandType]="'expandOffices'"
            [statistic]="statistic"
            [userSettingsModel]="userSettingsModel"
            [frozenColumnCount]="frozenColumnCount"
            [isFromExpandTableView] ="isFromExpandTableView"
            [isTAdmin] ="isTAdmin"
          >
          </app-expand-table-offices-expenses>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="tab-content"></div>
  </div>
</div>
