import {Component, Input, OnInit} from '@angular/core';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject} from 'rxjs';
import {SaveView, UserList, ViewDetails} from 'src/app/core/models/create-view.model';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {PeercheckMessageHandlerService} from 'src/app/core/services/peercheck-message-handler/peercheck-message-handler.service';
import {Role} from 'src/app/shared/enums';
import {environment} from 'src/environments/environment';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
import {CreateChart, VisualizationOptions} from 'src/app/core/models/create-chart.model';
declare let TRAAC: any;

@Component({
  selector: 'app-save-view',
  templateUrl: './save-view.component.html',
  styleUrls: ['./save-view.component.scss'],
})
export class SaveViewComponent implements OnInit {
  @Input() settingFrom: any;
  @Input() persistSaveViewModel: SaveView;
  @Input() viewData: any;
  @Input() firmID: any;
  @Input() viewDetails: ViewDetails;
  @Input() metadata: any;

  @Input() visualizationOption: VisualizationOptions;
  @Input() isCalledForVizOrView = false;
  @Input() visualizationModel: CreateChart;
  @Input() visualizationRequestBody: any;

  saveViewModel: SaveView;
  viewName: string;
  shareUserItemsStream: BehaviorSubject<any>;
  shareUserSelectedText = 'Select';
  usersList: UserList[] = [];

  isDataLoading = false;
  warningAlerts: BentoAlertItemOptions[] = [];
  alerts: BentoAlertItemOptions[] = [];
  errorMessage: any;
  selectedViewData: any;
  isQuickSightEnabled = false;
  isQuickSightForVisualizationEnabled = false;
  isVisualizationTemplate = false;
  constructor(
    public activeModal: NgbActiveModal,
    private service: BaseService,
    private peercheckMessageHandlerService: PeercheckMessageHandlerService,
    private authService: AuthService,
    private featureToggleService: FeatureToggleService
  ) {
    if (!this.isCalledForVizOrView) {
      this.saveViewModel = new SaveView();
      this.selectedViewData = this.peercheckMessageHandlerService.getSelectedViewData;
    }
  }

  ngOnInit(): void {
    if (this.isCalledForVizOrView) {
      this.isVisualizationTemplate = this.visualizationOption == VisualizationOptions.VisualizationTemplates;
      /***** TO BE REMOVED PART OF FEATURE TOGGLE - VISUALIZATION_QUICKSIGHT ******/
      this.featureToggleService
        .getFeatureValue('VISUALIZATION_QUICKSIGHT')
        .then((val) => (this.isQuickSightForVisualizationEnabled = val));

      /***************************************************************/
    } else {
      if (this.persistSaveViewModel) {
        this.saveViewModel = this.persistSaveViewModel;
      }

      if (this.saveViewModel.isContainsPPG) {
        this.addAlert('warning', 'View contains a Performance Peer Group and cannot be shared.', false, true);
      } else {
        this.getUsers();
      }

      if (this.saveViewModel.isSharedView) {
        this.addAlert('info', 'A shared view cannot be edited; to edit this view, make a copy.', false, false);
      }
      /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
      this.featureToggleService.getFeatureValue('QUICKSIGHT').then((val) => (this.isQuickSightEnabled = val));
      /***************************************************************/
    }
  }

  shareUserOnItemsSelected(v: any[]) {
    if (v && v.length) {
      this.saveViewModel.shareUserSelectedItems = v;
    } else {
      this.saveViewModel.shareUserSelectedItems = [];
      this.shareUserSelectedText = 'Select';
    }
    this.updateSelectedUserText();
  }

  addAlert(
    type: 'success' | 'info' | 'warning' | 'danger' = 'info',
    message: string,
    closeable: boolean = false,
    isWarningAlert: boolean = false
  ) {
    if (isWarningAlert) {
      this.warningAlerts.push({
        type,
        msg: message,
        closeable: closeable,
      });
    } else {
      this.alerts.push({
        type,
        msg: message,
        closeable: closeable,
      });
    }
  }

  saveSelection() {
    let requestPayload = this.generateRequestBody();
    if (this.isQuickSightEnabled) {
      TRAAC.track(TRAAC.keyValue('Saved view', this.metadata).build());
    }
    if (this.saveViewModel.viewName != this.saveViewModel.original_viewName || this.saveViewModel.isSharedView) {
      // Call API to create new View
      this.isDataLoading = true;
      this.service.post(environment.FIAdminBaseEndpoint + 'v1/view/' + this.firmID, requestPayload).subscribe(
        (result) => {
          this.isDataLoading = false;
          this.activeModal.close('Success');
        },
        (error) => {
          this.errorMessage = error.error;
          let errMsg = this.peercheckMessageHandlerService.getServiceErrorMessageString(this.errorMessage);
          this.addAlert('warning', errMsg, true, false);
          this.isDataLoading = false;
        }
      );
    } else if (
      this.saveViewModel.viewName === this.saveViewModel.original_viewName &&
      !this.saveViewModel.isSharedView
    ) {
      // Call API to Update new View
      this.isDataLoading = true;
      this.service
        .put(
          environment.FIAdminBaseEndpoint +
            'v1/view/viewTemplate/' +
            this.viewDetails.viewTemplateId +
            '/firm/' +
            this.firmID,
          requestPayload
        )
        .subscribe(
          (result) => {
            this.isDataLoading = false;
            this.activeModal.close('Success');
          },
          (error) => {
            this.errorMessage = error.error;
            let errMsg = this.peercheckMessageHandlerService.getServiceErrorMessageString(this.errorMessage);
            this.addAlert('warning', errMsg, true, false);
            this.isDataLoading = false;
          }
        );
    } else {
      this.addAlert('warning', 'Something went wrong, please try again.', true, false);
    }
  }

  generateRequestBody() {
    let requestbody = this.viewData;

    if (this.saveViewModel.isSharedView && this.saveViewModel.viewName == this.saveViewModel.original_viewName) {
      requestbody['name'] = this.saveViewModel.viewName + '_copy';
    } else {
      requestbody['name'] = this.saveViewModel.viewName;
    }
    requestbody['isDefault'] = this.saveViewModel.isDefaultForMe;

    if (this.saveViewModel.shareUserSelectedItems && !this.saveViewModel.isContainsPPG) {
      let users: string[] = [...new Set(this.saveViewModel.shareUserSelectedItems.map((item: UserList) => item.id))];
      requestbody['users'] = users;
      requestbody['isDefaultforAll'] = this.isGUser ? false : this.saveViewModel.isDefaultForUser;
      if (this.saveViewModel.shareUserSelectedItems.length > 0 && this.isQuickSightEnabled) {
        const metadata = {
          userids: this.saveViewModel.shareUserSelectedItems.map(({id}) => id),
          userNames: this.saveViewModel.shareUserSelectedItems.map(({name}) => name),
          isDefaultforAll: this.saveViewModel.isDefaultForUser,
        };
        TRAAC.track(TRAAC.keyValue('Shared view', metadata).build());
      }
    } else {
      requestbody['users'] = [];
      requestbody['isDefaultforAll'] = false;
    }

    return requestbody;
  }

  getUsers() {
    let requestURL = 'v1/view/users/firm/' + this.getDefaultfirmId;
    if (this.saveViewModel.viewId && !this.saveViewModel.isSharedView) {
      requestURL = 'v1/view/users/firm/' + this.getDefaultfirmId + '?viewTemplateId=' + this.viewDetails.viewTemplateId;
    } else {
      requestURL = 'v1/view/users/firm/' + this.getDefaultfirmId;
    }
    this.isDataLoading = true;
    this.service.get(environment.FIAdminBaseEndpoint + requestURL, '').subscribe(
      (result) => {
        this.isDataLoading = false;
        this.usersList = result;
        this.saveViewModel.shareUserSelectedItems = [];
        let userIdsSelected = result.filter((item) => item.alreadyShared == true);
        this.shareUserItemsStream = new BehaviorSubject(result);
        this.saveViewModel.shareUserSelectedItems = userIdsSelected;
        this.updateSelectedUserText();
        this.shareUserItemsStream = new BehaviorSubject(result);
      },
      (error) => {
        this.errorMessage = error.error;
        let errMsg = this.peercheckMessageHandlerService.getServiceErrorMessageString(this.errorMessage);
        this.addAlert('warning', errMsg, true, false);
        this.isDataLoading = false;
      }
    );
  }

  public get isGUser(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.GUser;
  }

  public get isUserSelected(): boolean {
    return this.saveViewModel.shareUserSelectedItems && this.saveViewModel.shareUserSelectedItems.length ? false : true;
  }

  updateSelectedUserText() {
    let selectedUserCount = this.saveViewModel.shareUserSelectedItems.filter((item) => {
      if (!item.disabled) {
        return true;
      }
    }).length;
    if (selectedUserCount && selectedUserCount == 1) {
      this.shareUserSelectedText = selectedUserCount.toString() + ' user selected';
    } else if (selectedUserCount && selectedUserCount > 1) {
      this.shareUserSelectedText = selectedUserCount.toString() + ' users selected';
    } else {
      this.shareUserSelectedText = 'Select';
    }
    this.saveViewModel.isDefaultForUser =
      this.saveViewModel.shareUserSelectedItems && this.saveViewModel.shareUserSelectedItems.length ? true : false;
  }

  // Return firmId of loggedin user
  private get getDefaultfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (backendTokenClaims.userDDO) {
      return backendTokenClaims.userDDO.firmID;
    }
  }

  saveVisualization() {
    let requestbody = this.visualizationRequestBody;
    requestbody['favorite'] = this.visualizationModel.favorite;
    requestbody['chartName'] = this.visualizationModel.name;
    if (this.isQuickSightForVisualizationEnabled) {
      this.logQuickSightForVisulization(this.visualizationModel.name);
    }
    if (this.visualizationModel.name != this.visualizationModel.orignalName) {
      // Proceed to the Save visualization from template
      requestbody['chartId'] = null;

      // hit API call to save visualization
      this.isDataLoading = true;
      this.service.post(environment.FIAdminBaseEndpoint + 'v1/chart/' + this.firmID, requestbody).subscribe(
        (result) => {
          this.isDataLoading = false;
          this.activeModal.close('Success');
        },
        (error) => {
          this.errorMessage = error.error;
          let errMsg = this.peercheckMessageHandlerService.getServiceErrorMessageString(this.errorMessage);
          this.addAlert('warning', errMsg, true, false);
          this.isDataLoading = false;
        }
      );
    } else if (
      this.visualizationOption == VisualizationOptions.MyVisualizations &&
      this.visualizationModel.name === this.visualizationModel.orignalName
    ) {
      // Proceed to the Update Visualization from my visualization
      // hit API call to save visualization
      this.isDataLoading = true;
      this.service.put(environment.FIAdminBaseEndpoint + 'v1/chart/' + this.firmID, requestbody).subscribe(
        (result) => {
          this.isDataLoading = false;
          this.activeModal.close('Success');
        },
        (error) => {
          this.errorMessage = error.error;
          let errMsg = this.peercheckMessageHandlerService.getServiceErrorMessageString(this.errorMessage);
          this.addAlert('warning', errMsg, true, false);
          this.isDataLoading = false;
        }
      );
    }
  }
  logQuickSightForVisulization(name) {
    let metadata = {
      visualizationName: name,
      startDate: this.visualizationModel.currentPeriodStartDate,
      endDate: this.visualizationModel.currentPeriodEndDate,
      templateKey: this.visualizationModel.templateKey.toUpperCase(),
    };
    TRAAC.track(TRAAC.keyValue('Save Visualization', metadata).build());
  }
}
