import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Subject} from 'rxjs/internal/Subject';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatureToggleService {
  constructor(private service: BaseService) {}

  getFeatureValue(featureName: string): any {
    if (featureName) {
      return this.service
        .get(environment.FIAdminBaseEndpoint + 'v1/feature/' + featureName, '')
        .toPromise()
        .then(
          (result) => {
            return result[featureName];
          },
          (error) => {
            return false;
          }
        );
    }
  }
}
