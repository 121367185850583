/* eslint-disable max-len */
import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {NgbDropdown, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Directionality} from '@angular/cdk/bidi';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {ActivatedRoute, Router} from '@angular/router';
import {PeercheckMessageHandlerService} from 'src/app/core/services/peercheck-message-handler/peercheck-message-handler.service';
import {
  CreateChart,
  VisualizationList,
  VisualizationOptions,
  VisualizationTypes,
} from 'src/app/core/models/create-chart.model';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {environment} from 'src/environments/environment';
import {BaseService} from 'src/app/core/services/base/base.service';
import {FormatdateService} from 'src/app/core/services/formatdate/formatdate.service';
import {DateFormat} from 'src/app/shared/enums';
import {PeerCheckServiceService} from '../peer-check/peer-check-service.service';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
declare let TRAAC: any;
import {GlobalService} from 'src/app/core/services/global/global.service';

@Component({
  selector: 'app-current-charts-list',
  templateUrl: './current-charts-list.component.html',
})
export class CurrentChartsListComponent implements OnInit {
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;
  @Input() isChartList: boolean;
  @Input() isCalledForTemplates = false;
  @Output() currentVisualizationAction = new EventEmitter();
  dropdown: NgbDropdown;
  sectionHeading: string;
  isFirmSelected: boolean;
  firmId: number;
  selectedVisualization: any;
  isQuickSightEnabled = false;
  constructor(
    private dir: Directionality,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private service: BaseService,
    private _peercheckMsgService: PeercheckMessageHandlerService,
    private _peercheckService: PeerCheckServiceService,
    private formatdate: FormatdateService,
    private featureToggleService: FeatureToggleService,
    private globalservice: GlobalService
  ) {}
  visualizationList: VisualizationList[];
  currentRecords: number;
  totalRecords: number;
  totalItem = 0;
  currentInfo: any = {
    page: 1,
    totalItems: this.totalItem,
    numItems: 10,
    infoText: '_START_ to _END_ of _MAX_ Users',
    infoPageText: '_PAGE_ of _PAGES_',
    goText: 'Go',
  };
  lastPage = this.currentInfo.page;
  page: any = 1;
  pageSize: any = 10;
  startIndex = 0;
  endIndex = 10;
  selectItems: number[] = [10, 20, 30, 40, 50];
  alerts: BentoAlertItemOptions[] = [];
  isDataLoading = false;
  visualizationNameSortOrderAsc = true;
  createdDateSortOrderAsc: boolean | null = null;
  isFavoritesSorted = false;
  makeDefaultOptions: any = [];
  makeDefaultOptionsModel: string;
  sortingDropdownItems: {name: string; isDisabled: boolean}[];
  sortOrder = 'Z to A';
  ngOnInit(): void {
    this.sectionHeading = this.isChartList ? 'My visualizations' : 'Visualization templates';
    this.getChartList();
    this.sortingDropdownItems = [
      {name: this.sortOrder, isDisabled: false},
      {name: 'Favorites', isDisabled: false},
      {name: 'Created date', isDisabled: false},
    ];
    this.firmId = this.getDefaultOrSelectedFirmId;
  }
  get bottomRight() {
    // Change sides of RTL for the dropdowns
    return this.dir.value === 'rtl' ? 'bottom-left' : 'bottom-right';
  }

  getChartList(): void {
    this.isDataLoading = true;
    let url: string;
    if (this.isChartList) {
      url = `${environment.FIAdminBaseEndpoint}v1/charts/firm/${this.getDefaultOrSelectedFirmId}`;
    } else {
      url = `${environment.FIAdminBaseEndpoint}v1/chart/templates/firm/${this.getDefaultOrSelectedFirmId}`;
    }
    this.service.get(url, '').subscribe(
      (result) => {
        this.isDataLoading = false;
        this.visualizationList = result;
        this.totalRecords = this.visualizationList.length;
        this.currentInfo.totalItems = this.visualizationList.length;
        this.currentRecords =
          this.visualizationList.length > this.pageSize ? this.pageSize : this.visualizationList.length;
        this.visualizationNameSortOrderAsc = true;
        this.globalservice.addPaginationAdditionalInfo();
      },
      (error) => {
        this.isDataLoading = false;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong. Please try again.',
          closeable: true,
        });
      }
    );
  }

  async getAllTemplatesDetails(chart: VisualizationList): Promise<void> {
    let apiResponse = await this._peercheckService.getVisualizationDetailsWithType(
      !this.isChartList && this.isCalledForTemplates ? chart.chartTemplateId : chart.chartId,
      this.getDefaultOrSelectedFirmId,
      !this.isChartList && this.isCalledForTemplates
    );

    if (apiResponse && apiResponse.response && !apiResponse.hasError) {
      chart.createChartModel = new CreateChart();
      chart.visualizationDetails = apiResponse.response;
      this._peercheckMsgService.populatePreSelectedVisualizationDetails(
        chart.createChartModel,
        chart.visualizationDetails
      );
    } else {
      this.alerts = apiResponse.alerts;
    }
  }

  onPageChanged(page) {
    if (page !== this.lastPage) {
      setTimeout(() => {
        this.page = page;
        this.startIndex = (this.page - 1) * this.pageSize;
        this.endIndex = this.page * this.pageSize;
        this.currentRecords =
          this.totalRecords > this.page * this.pageSize
            ? this.pageSize
            : this.totalRecords - (this.page - 1) * this.pageSize;
      });
    }
    this.lastPage = page;
  }

  onItemsPerPageChanged(itemsPerPage) {
    this.pageSize = itemsPerPage;
    if (this.page === 1) {
      this.startIndex = 0;
      this.endIndex = this.pageSize;
    } else {
      this.startIndex = (this.page - 1) * this.pageSize;
      this.endIndex = this.page * this.pageSize;
    }
    this.currentRecords = this.totalRecords > this.page * this.pageSize ? this.pageSize : this.totalRecords;
  }

  visualizationNameSort() {
    this.isFavoritesSorted = false;
    this.createdDateSortOrderAsc = null;
    if (this.visualizationNameSortOrderAsc === true) {
      // Descending
      this.sortOrder = 'A to Z';
      this.visualizationList.sort((a, b) =>
        this.getVisualizationName(a).toLowerCase() > this.getVisualizationName(b).toLowerCase() ? -1 : 1
      );
      // this.setDefaultVisualizationOnTop();
      this.visualizationNameSortOrderAsc = false;
    } else {
      // Ascending
      this.sortOrder = 'Z to A';
      this.visualizationList.sort((a, b) =>
        this.getVisualizationName(a).toLowerCase() > this.getVisualizationName(b).toLowerCase() ? 1 : -1
      );
      // this.setDefaultVisualizationOnTop();
      this.visualizationNameSortOrderAsc = true;
    }
    this.sortingDropdownItems.forEach((element) => {
      if (element.name == 'A to Z' || element.name == 'Z to A') {
        element.name = this.sortOrder;
      }
    });
  }

  favoritesSort() {
    this.visualizationNameSortOrderAsc = null;
    this.createdDateSortOrderAsc = null;
    if (this.isFavoritesSorted) {
      this.visualizationList.sort((a, b) =>
        a.favorite === b.favorite
          ? this.getVisualizationName(a).toLowerCase() > this.getVisualizationName(b).toLowerCase()
            ? 1
            : -1
          : a.favorite
          ? 1
          : -1
      );
      // this.setDefaultVisualizationOnTop();
      this.isFavoritesSorted = false;
    } else {
      this.visualizationList.sort((a, b) =>
        a.favorite === b.favorite
          ? this.getVisualizationName(a).toLowerCase() > this.getVisualizationName(b).toLowerCase()
            ? 1
            : -1
          : a.favorite
          ? -1
          : 1
      );
      // this.setDefaultVisualizationOnTop();
      this.isFavoritesSorted = true;
    }
  }

  createdDateSort() {
    this.visualizationNameSortOrderAsc = null;
    this.isFavoritesSorted = false;
    if (this.createdDateSortOrderAsc && this.createdDateSortOrderAsc === true) {
      // Descending
      if (this.isChartList || !this.isCalledForTemplates) {
        this.visualizationList.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1));
      }
      this.createdDateSortOrderAsc = false;
    } else {
      // Ascending
      if (this.isChartList || !this.isCalledForTemplates) {
        this.visualizationList.sort((a, b) => (a.createdDate > b.createdDate ? 1 : -1));
      }
      // this.setDefaultVisualizationOnTop();
      this.createdDateSortOrderAsc = true;
    }
  }

  // setDefaultVisualizationOnTop() {
  //   this.visualizationList.sort((a, b) => {
  //     if (a.default === b.default) return 0;
  //     if (a.default === true) return -1;
  //     if (b.default === true) return 1;
  //   });
  // }
  dropDownOnSelectChange(name) {
    if (name == 'A to Z' || name == 'Z to A') {
      this.visualizationNameSort();
    } else if (name == 'Favorites') {
      this.favoritesSort();
    } else if (name == 'Created date') {
      this.createdDateSort();
    }
  }

  viewCurrentVisualization(item: any) {
    let templateKey: string = item.templateKey.toString().toLowerCase();
    if (!(templateKey == VisualizationTypes.KPMV1 || templateKey == VisualizationTypes.DPA)) {
      return;
    }
    this.currentVisualizationAction.emit(item);
  }

  redirectToNewVisualization(selectedVisualization) {
    let templateKey: string = selectedVisualization.templateKey.toString().toLowerCase();
    if (!(templateKey == VisualizationTypes.KPMV1 || templateKey == VisualizationTypes.DPA)) {
      return;
    }
    this._peercheckMsgService.setSelectedVisualizationData = selectedVisualization;
    this._peercheckMsgService.setSelectedVisualizationType = this.isCalledForTemplates
      ? VisualizationOptions.VisualizationTemplates
      : VisualizationOptions.MyVisualizations;
    if (this.isFirmSelected) {
      this.router.navigate(['firm/' + this.firmId + '/new-visualization']);
    } else {
      this.router.navigate(['new-visualization']);
    }
  }

  private get getDefaultOrSelectedFirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    this.isFirmSelected = false;
    if (this.route.snapshot && this.route.snapshot.params && this.route.snapshot.params.id) {
      this.isFirmSelected = true;
      return parseInt(this.route.snapshot.paramMap.get('id'));
    } else {
      return backendTokenClaims.userDDO.firmID;
    }
  }

  async expandCollapseToggle(item: VisualizationList) {
    if (!item.isExpand) {
      item.isExpand = !item.isExpand;
      item.isDataLoading = true;
      await this.getAllTemplatesDetails(item);
      item.isDataLoading = false;
    } else {
      item.isExpand = !item.isExpand;
    }
  }

  async favouriteToggle(item: VisualizationList) {
    if (!item.favorite) {
      item.favorite = !item.favorite;
    } else {
      item.favorite = !item.favorite;
    }
  }

  extractDateFormat(date) {
    return this.formatdate.format(date, DateFormat.MMMMy);
  }

  removeChartWithConfirmation(removeModalContent, item) {
    this.selectedVisualization = item;
    if (!this.isChartList || this.isCalledForTemplates) {
      return;
    }

    this.modalService.open(removeModalContent, {ariaLabelledBy: 'modalViewRemove'}).result.then(
      (result: string) => {
        if (result === 'confirm' && this.isChartList && !this.isCalledForTemplates) {
          this.removeVisualization(item);
        }
      },
      (reason) => {}
    );
  }

  removeVisualization(item) {
    this.isDataLoading = true;
    this.service.delete(environment.FIAdminBaseEndpoint + 'v1/chart/', item.chartId).subscribe(
      (result) => {
        const removeIndex = this.visualizationList
          .map(function (item) {
            return item.chartId;
          })
          .indexOf(item.chartId);
        this.visualizationList.splice(removeIndex, 1);

        this.totalRecords = this.visualizationList.length;
        this.currentInfo.totalItems = this.visualizationList.length;
        const noPage = this.totalRecords / this.pageSize + 1;
        if (noPage === this.page) {
          this.onPageChanged(this.page - 1);
          this.currentInfo.page = this.page - 1;
        } else {
          this.currentRecords =
            this.totalRecords > this.page * this.pageSize
              ? this.pageSize
              : this.totalRecords - (this.page - 1) * this.pageSize;
        }
        this.isDataLoading = false;
      },
      (error) => {
        this.isDataLoading = false;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong. Please try again.',
          closeable: true,
        });
      }
    );
  }

  getDescription(descriptionOption) {
    return descriptionOption ? this._peercheckMsgService.getCommaSeparatedString(descriptionOption, true) : '';
  }

  getVisualizationName(visualization: VisualizationList) {
    if (this.isChartList || !this.isCalledForTemplates) {
      return visualization.chartName;
    } else {
      return visualization.templateName;
    }
  }

  async favoriteUnfavoriteToggle(item: VisualizationList) {
    item.isDataLoading = true;
    const persist_FavoriteValue = item.favorite;
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
    await this.featureToggleService
      .getFeatureValue('VISUALIZATION_QUICKSIGHT')
      .then((val) => (this.isQuickSightEnabled = val));
    /*************************************************/
    let response = await this._peercheckService.markVisualizationFavoriteUnfavorite(
      !this.isChartList && this.isCalledForTemplates ? item.chartTemplateId : item.chartId,
      item.favorite,
      !this.isChartList && this.isCalledForTemplates
    );
    item.isDataLoading = false;
    if (response && !response.hasError) {
      item.favorite = !persist_FavoriteValue;
    } else {
      this.alerts.push({
        type: 'warning',
        msg: 'Something went wrong, please try again.',
        closeable: true,
      });
    }
    if (!this.isChartList && this.isCalledForTemplates && this.isQuickSightEnabled) {
      let metadata = {
        visualizationName: item.templateName,
        startDate: item.currentPeriodStartDate,
        endDate: item.currentPeriodEndDate,
        templateKey: item.templateKey,
        email: this.authService.getUserEmail(),
      };
      TRAAC.track(TRAAC.keyValue('Mark visualization template as favorite click', metadata).build());
    } else if (this.isQuickSightEnabled) {
      let metadata = {
        visualizationName: item.chartName,
        startDate: item.currentPeriodStartDate,
        endDate: item.currentPeriodEndDate,
        templateKey: item.templateKey,
        email: this.authService.getUserEmail(),
      };
      TRAAC.track(TRAAC.keyValue('Mark visualization as favorite click', metadata).build());
    }
  }

  getSectionHeadingId(): string {
    return 'section-heading-vt-' + this.sectionHeading.replace(' ', '-');
  }

  getSubSectionHeadingId(): string {
    return 'sub-heading-vt-' + this.sectionHeading.replace(' ', '-');
  }
}
