import {Component, HostBinding, OnInit} from '@angular/core';
import {BentoSideNavItem} from '@bento/bento-ng';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
})
export class ContactUsComponent implements OnInit {
  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }
  items: BentoSideNavItem[] = [
    {
      label: 'General',
      selected: true,
      action: () => {
        this.showHideUserTabSideNav('General');
      },
      data: {isVisible: true},
    },
    {
      label: 'Technical support',
      action: () => {
        this.showHideUserTabSideNav('Technical support');
      },
      data: {isVisible: false},
    },
    {
      label: 'Sales assistance',
      action: () => {
        this.showHideUserTabSideNav('Sales assistance');
      },
      data: {isVisible: false},
    },
  ];

  selectedLabel: string = this.items[0].label;

  constructor() {}

  ngOnInit(): void {}

  onSelect(item: BentoSideNavItem) {
    this.selectedLabel = item.label;
  }

  showHideUserTabSideNav(label: string) {
    this.items.forEach((element: BentoSideNavItem) => {
      if (element.label === label) {
        element.data.isVisible = true;
      } else {
        element.data.isVisible = false;
      }
    });
  }
}
