import { Component, Input } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BaseService} from 'src/app/core/services/base/base.service';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {environment} from 'src/environments/environment';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {DownloadService} from 'src/app/core/services/download/download.service';
import { HttpParams } from '@angular/common/http';
@Component({
  selector: 'app-my-reports',
  templateUrl: './my-reports.component.html'
})
export class MyReportsComponent {
  alerts: BentoAlertItemOptions[] = [];
  isDataLoading = false;
  successMsg = '';
  errorMessage = '';
  @Input() selectedFirmId: any;
  @Input() currentSurveyYearOfFirm: any;
  selectedPeerGroupsReportList: any=[];
  isExportReportBtnPeerGroupDisabled:boolean =false;
  constructor(
    public modalService: NgbModal,
    private authService: AuthService,
    private service: BaseService,
    private downloadFile: DownloadService
  ) {}
  ngOnInit() {
    this.getSelectedPeerGroupsReortData();
  }
  getSelectedPeerGroupsReortData() {
    this.isDataLoading = true;
    this.service.get(environment.FIClientBaseEndpoint + 'v1/staffingratio/peergroup/selected/'+this.selectedFirmId+'?surveyYear='+this.currentSurveyYearOfFirm, '').subscribe(
      (result) => {
        this.isDataLoading =false;
        this.selectedPeerGroupsReportList = result;
        let countSelectedPeerGroups = this.selectedPeerGroupsReportList.length;
        if (countSelectedPeerGroups ==0)
          this.isExportReportBtnPeerGroupDisabled =true;
        else
          this.isExportReportBtnPeerGroupDisabled =false;
      },
      (error) => {
        this.isExportReportBtnPeerGroupDisabled =true;
        this.isDataLoading =false;
        this.errorMessage = error.error.message;
        /*this.alerts.push({
          type: 'warning',
          msg: this.errorMessage,
          closeable: true,
        });*/
      }
    );
  }

  downloadReportError(params) {
    this.alerts = [];
    this.isDataLoading = true;
    this.service.post(environment.FIClientBaseEndpoint + 'v1/staffingratio/export/' + this.selectedFirmId, params).subscribe(
      (result) => {
        this.isDataLoading =false;
      },
      (error) => {
        this.isDataLoading =false;
        this.errorMessage = error.error.message;
        this.alerts.push({
          type: 'warning',
          msg: this.errorMessage,
          closeable: true,
        });
      }
    );
  }
  downloadReport(peerGroupId: string, peerGroupFirmId: number) {
    console.log("In Progress");
    let params ={
    peerId: peerGroupId,
    peerGroupFirmId: peerGroupFirmId
    }
    this.isDataLoading = true;
    this.alerts=[];
    this.service.downloadExcelPost(environment.FIClientBaseEndpoint + 'v1/staffingratio/export/' + this.selectedFirmId, params).subscribe(
      (response) => {
        this.downloadFile.download(response.body, response.headers.get('content-disposition').split('=')[1].replace(/['"]+/g,""));
        this.isDataLoading = false;
      },
      (error) => {
        if(error.status == 400){
          this.downloadReportError(params);
        }else{
        this.isDataLoading = false;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
      }
    );
  }
  openSRReports(content) {
    this.modalService.open(content, {ariaLabelledBy: 'sr-modal-basic-title',  backdrop : 'static', keyboard : false}).result.then(
      (result) => {
        //this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
}
