import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {BackendTokenClaims} from '../../models/tokenResponse';
import {AuthService} from '../../services/auth/auth.service';
import {Role} from 'src/app/shared/enums';
import {PeerCheckServiceService} from '../peer-check/peer-check/peer-check-service.service';

@Component({
  selector: 'app-global-breadcrumbs',
  templateUrl: './global-breadcrumbs.component.html',
})
export class GlobalBreadcrumbsComponent implements OnInit {
  isMappings: boolean;
  currentRouteArray: any;
  currentRoute: string;
  hasbreadcrumbVariance: boolean;
  peerCheckCreatePPG = false;
  breadcrumbSelectFirm: string;
  breadcrumbMappingsTab: string;
  breadcrumbMappingsTabText: string;
  breadcrumbVariance: string;
  breadcrumbMappingsTabLink: string;
  isFirmListPage: boolean;
  defaultPeriod: any;
  selectedPeriod: any;
  balancingValue: any;
  size: number;
  @Input() isFirmPeerGroup;
  @Input() firmName;
  @Input() peerLabel;
  @Input() firmParameterrouterLink;
  @Output() updateService = new EventEmitter();
  @Output() handleUserEventPPG = new EventEmitter<boolean>();

  breadcrumbDict = {
    timekeepers: 'Timekeepers',
    practices: 'Practices',
    titles: 'Titles',
    offices: 'Offices',
    'gl-offices': 'GL Offices',
    'gl-account-codes': 'GL Account Codes',
    balancing: 'Balancing',
    variance: 'Variance detail',
    inquiry: 'Inquiry',
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private _peerCheckService: PeerCheckServiceService,
    private _route: Router
  ) {
    this.isFirmListPage = false;
    this.breadcrumbVariance = '';
    this.hasbreadcrumbVariance = false;
    if (this.activatedRoute.snapshot) {
      this.currentRoute = this.activatedRoute.snapshot['_routerState'].url;
    }
    if (!this.currentRoute) {
      this.currentRoute = '';
    }
    this.currentRouteArray = this.currentRoute.split('/');
    this.size = this.currentRouteArray.length;
    this.setBreadcrumbs();
  }

  setBreadcrumbs(): void {
    if (this.currentRoute.includes('create-view/performance-peer-groups')) {
      this.peerCheckCreatePPG = true;
    } else if (this.size == 5) {
      this.breadcrumbMappingsTab = this.currentRouteArray[this.size - 3];
      this.breadcrumbMappingsTabLink = this.currentRoute;
    } else if (this.currentRouteArray.includes('variance')) {
      this.hasbreadcrumbVariance = true;
      this.breadcrumbMappingsTab = this.currentRouteArray[this.size - 4];
      this.breadcrumbVariance = this.breadcrumbDict['variance'];
      this.breadcrumbMappingsTabLink = '/mappings/balancing/firms/' + this.currentRouteArray[this.size - 2];
    } else {
      this.breadcrumbMappingsTab = this.currentRouteArray[this.size - 1];
      this.breadcrumbMappingsTabLink = this.currentRoute;
    }
    if (this.currentRouteArray[this.size - 1] == 'firms') this.isFirmListPage = true;
    this.isMappings = this.currentRouteArray.includes('mappings');
    this.breadcrumbSelectFirm = '/mappings/' + this.breadcrumbMappingsTab + '/firms';
    this.breadcrumbMappingsTabText = this.breadcrumbDict[this.breadcrumbMappingsTab];
  }

  onClickBalancingLink(clickVal) {
    this._peerCheckService.setBalancingValue({...this.balancingValue, selected: clickVal});
    this._route.navigate([this.breadcrumbMappingsTabLink]);
  }

  ngOnInit(): void {
    if (this.firmParameterrouterLink) this.firmParameterrouterLink = this.firmParameterrouterLink;
    else this.firmParameterrouterLink = '.';
    this._peerCheckService.getBalancingValue().subscribe((data) => {
      this.balancingValue = data;
    });
    this._peerCheckService.getDefaultSubject().subscribe((data) => {
      this.defaultPeriod = data;
    });
    this._peerCheckService.getSelectedPeriod().subscribe((data) => {
      this.selectedPeriod = data;
    });
  }

  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }

  updateServiceEvent() {
    this.updateService.emit();
  }
}
