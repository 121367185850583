<style nonce="cssRand0m">
  .flex-container {
    display: flex;
  }
  .flex-child {
    flex: 1;
  }
  .flex-child:first-child {
    margin-right: 20px;
  }
</style>

<bento-alert [items]="alerts"></bento-alert>
<section class="Section" [bentoBusyLoader]="isFMSUpdated">
  <form name="fms" #fms="ngForm" class="Section-form Form" bfmForm *ngIf="isGetSettings">
    <header class="Section-header">
      <h2 class="Section-heading">Financial Management System (FMS)</h2>
    </header>
    <div class="Section-body">
      <div class="form-group Form-input Form-input--medium">
        <label for="status" id="statusLabel">Financial Management System (FMS)</label>
               <bento-combobox
                  id="status"
                  name="status"
                  class="form-control"
                  bfmField
                  bfmLabel="status"
                  [(ngModel)]="fmsTypeObjSelected"
                  [itemsObservable]="fmsTypes"
                  (ngModelChange)="onChangeFmsField(fmsTypeObjSelected)"
                  [options]="comboboxOptions"
                  [placeholder]="false"
                  [rowTemplate]="myTemplate"
                  [revertOnInvalidInput]="true"
                  ngDefaultControl
                ></bento-combobox>
                <ng-template #myTemplate let-$data="data" let-$options="options" let-$index="index">
                    <div class="safe-id">
                      {{$data.row.label}}
                  </div>
                </ng-template>
      </div>
      <ng-container *ngIf="showCustomerMigration">
        <div class="form-group Form-input Form-input--medium">
          <label for="customrMigration">
            <bento-checkbox
              name="customrMigration"
              id="customrMigration"
              [(ngModel)]="isCustomerMigration"
              (ngModelChange)="isCustomerMigrationChange()"
            ></bento-checkbox>
            Customer Migration</label
          >
        </div>
        <div *ngIf="isCustomerMigration && fmsType != '5'" class="form-group Form-input Form-input--medium">
          <input
            type="text"
            name="customMigrateInput"
            id="customMigrateInput"
            [pattern]="fmsValidations"
            class="form-control"
            placeholder="Enter Pmm Database name"
            bfmField
            required
            bfmLabel="pmm Database name"
            [(ngModel)]="customerMigrateDb"
            [validations]="validations.fmsValidations"
          />
          <!-- <span style="color: #8f221a">{{ customerMigrateMaxLengthError }}</span> -->
        </div>
        <div *ngIf="isCustomerMigration && fmsType == '5'" class="form-group Form-input Form-input--medium">
          <label for="customMigrateHost" class="bento-label-required">Host</label>
          <input
            style ="width:120% !important"
            type="text"
            name="customMigrateHost"
            id="customMigrateHost"
            [pattern]="fmsValidations"
            class="form-control"
            bfmField
            required
            bfmLabel="Host"
            [(ngModel)]="customer3EMigration.host"
            [validations]="validations.fmsValidations"
          />
          <!-- <span style="color: #8f221a">{{ customerMigrateMaxLengthError }}</span> -->
        </div>
        <div *ngIf="isCustomerMigration && fmsType == '5'" class="form-group Form-input Form-input--medium">
          <label for="customMigrateDBName" class="bento-label-required">DB Name</label>
          <input
            style ="width:120% !important"
            type="text"
            name="customMigrateDBName"
            id="customMigrateDBName"
            [pattern]="fmsValidations"
            class="form-control"
            bfmField
            required
            bfmLabel="DBName"
            [(ngModel)]="customer3EMigration.DBName"
            [validations]="validations.fmsValidations"
          />
          <!-- <span style="color: #8f221a">{{ customerMigrateMaxLengthError }}</span> -->
        </div>
        <div *ngIf="isCustomerMigration && fmsType== '5'" class="form-group Form-input Form-input--medium">
          <label for="customMigrateuserName" class="bento-label-required">Username</label>
          <input
            style ="width:120% !important"
            type="text"
            name="customMigrateuserName"
            id="customMigrateuserName"
            [pattern]="fmsValidations"
            class="form-control"
            bfmField
            required
            bfmLabel="userName"
            [(ngModel)]="customer3EMigration.userName"
            [validations]="validations.fmsValidations"
          />
        </div>
        <div *ngIf="isCustomerMigration && fmsType == '5'" class="form-group Form-input Form-input--medium">
          <label for="customMigratepassword" class="bento-label-required">Password</label>
          <input
            style ="width:120% !important"
            type="text"
            name="customMigratepassword"
            id="customMigratepassword"
            [pattern]="fmsValidations"
            class="form-control"
            bfmField
            required
            bfmLabel="password"
            [(ngModel)]="customer3EMigration.password"
            [validations]="validations.fmsValidations"
          />
        </div>
        
      </ng-container>
    </div>

    <footer class="Section-footer">
      <div class="Actions">
        <div class="Actions-item">
          <button type="button" class="btn btn-outline-secondary" (click)="resetFMS()">
            <span class="btn-text">Cancel</span>
          </button>
        </div>
        <div class="Actions-item a11y">
          <button
            type="submit"
            class="btn btn-primary"
            [attr.aria-disabled]=" (isCustomerMigration && fms.invalid) ? 'true' : 'false' "
            (click)=" (isCustomerMigration && fms.invalid) ? $event.stopPropagation() : saveFMS() "
            [attr.aria-labelledby]=" (isCustomerMigration && fms.invalid) ? 'aria-required-save' : null "
          >
            <span class="btn-text">Save</span>
          </button>
        </div>
        <span class="sr-only" id="aria-required-save">Fill required fields, Save</span>
      </div>
    </footer>
  </form>
</section>
