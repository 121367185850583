export default class GeneralFirmSettingModel {
  public currentFiscalPeriod?: number;
  public currentCalendarPeriod?: number;
  public contigencyFilter?: string;
  public mattersFilter?: string;
  public timekeeperFilter?: string;
  public includeReversals?: boolean;
  public multiCurrency?: boolean;
  public usePremiumDiscount?: boolean;

  public constructor() {}
}
