import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from 'src/environments/environment';
import ExtractJobData from '../../models/extract-job-data.model';
import {BackendTokenClaims} from '../../models/tokenResponse';
import {AuthService} from '../../services/auth/auth.service';
import {BaseService} from '../../services/base/base.service';
import {LocalStorageService} from '../../services/local-storage/local-storage.service';
import {PeerCheckServiceService} from '../peer-check/peer-check/peer-check-service.service';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjGridDetail from '@grapecity/wijmo.grid.detail';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import {FlexGridDetailProvider} from '@grapecity/wijmo.grid.detail';
import {WjFlexGrid} from '@grapecity/wijmo.angular2.grid';

@Component({
  selector: 'app-extract-jobs',
  templateUrl: './extract-jobs.component.html',
  styleUrls: ['./extract-jobs.component.scss'],
})
export class ExtractJobsComponent implements OnInit {
  firmId: number;
  alerts: BentoAlertItemOptions[] = [];
  isGetDataLoading = false;
  extractHistoryData: ExtractJobData[] = [];
  errorMessage: any;
  rowCount:any =0;
  colCount:any =0;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  @ViewChild('flexGridDetails', {static: true}) flexGridDetails: FlexGridDetailProvider;
  @ViewChild('filter', {static: true}) gridFilter: wjcGridFilter.FlexGridFilter;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private service: BaseService,
    private modalService: NgbModal,
    private localStorageService: LocalStorageService,
    private _peerCheckService: PeerCheckServiceService
  ) {
    if (this.route.params) {
      this.route.params.subscribe((val) => {
        if (val && val.id) {
          this.firmId = val.id;
        } else {
          if (this._peerCheckService) {
            if (
              this._peerCheckService.getSelectedFirmId &&
              this._peerCheckService.getSelectedFirmId != this.getfirmId
            ) {
              this.firmId = this._peerCheckService.getSelectedFirmId;
            } else {
              this.firmId = this.getfirmId;
            }
          }
        }
        this.getExtractHistoryData();
      });
    }
  }

  ngOnInit(): void {}

  gridInitialized(flexGrid) {
    if (flexGrid.rows) {
      flexGrid.rows.defaultSize = 500;
      flexGrid.columnHeaders.rows.defaultSize = 40;
    }
    if (flexGrid) {
      flexGrid.cells.hostElement.setAttribute('aria-labelledby', 'ExtractJobsName');
      this.colCount = wijmo.Globalize.format(flexGrid.columns.length, 'n0');
    }
    this.flexGrid = flexGrid;
    flexGrid.addEventListener(flexGrid.hostElement, 'click', (e) => {
      let ht = flexGrid.hitTest(e);

      setTimeout(() => {
        const cellEle = this.flexGrid.rowHeaders.getCellElement(ht.row, ht.col);
        cellEle.classList.add('c1');
      }, 500);
    });
    
  }

  initFilter() {
    this.gridFilter.showSortButtons = false;
  }

  gridDetailsInitialized(flexGrid) {
    if (flexGrid.rows) {
      flexGrid.rows.defaultSize = 160;
      flexGrid.columnHeaders.rows.defaultSize = 40;
    }
    this.flexGridDetails = flexGrid;
  }

  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (this.firmId) return this.firmId;
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : parseInt(this.route.snapshot.paramMap.get('id'));
  }

  formatDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear() + ' ' + strTime;
  }
  formatExtractDate(date) {
    return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  }
  getExtractHistoryData() {
    this.isGetDataLoading = true;
    this.extractHistoryData = [];
    this.service.post(environment.FIClientBaseEndpoint + 'v1/audit/extract/search/' + this.firmId, {}).subscribe(
      (result) => {
        this.isGetDataLoading = false;
        this.extractHistoryData = result;
        this.rowCount = this.extractHistoryData.length;
        for (let i = 0; i < this.extractHistoryData.length; i++) {
          this.extractHistoryData[i]['extractDate'] = this.formatExtractDate(
            new Date(this.extractHistoryData[i]['extractDate'])
          );
          this.extractHistoryData[i]['jobStartTimestamp'] = this.formatDate(
            new Date(this.extractHistoryData[i]['jobStartTimestamp'])
          );
          if (this.extractHistoryData[i]['jobEndTimestamp'] != null) {
            this.extractHistoryData[i]['jobEndTimestamp'] = this.formatDate(
              new Date(this.extractHistoryData[i]['jobEndTimestamp'])
            );
          } else {
            this.extractHistoryData[i]['jobEndTimestamp'] = '-';
          }
          for (let j = 0; j < this.extractHistoryData[i].stepData.length; j++) {
            this.extractHistoryData[i].stepData[j]['startTimestamp'] = this.formatDate(
              new Date(this.extractHistoryData[i].stepData[j]['startTimestamp'])
            );
            if (this.extractHistoryData[i].stepData[j]['endTimestamp'] != null) {
              this.extractHistoryData[i].stepData[j]['endTimestamp'] = this.formatDate(
                new Date(this.extractHistoryData[i].stepData[j]['endTimestamp'])
              );
            } else {
              this.extractHistoryData[i].stepData[j]['jobEndTimestamp'] = '-';
            }
          }
        }
        //this.expand(this.extractHistoryData[0]);
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'something went wrong please try again.',
          timeout: 2500,
          closeable: true,
        });
      }
    );
  }
}
