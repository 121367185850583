<app-global-header></app-global-header>
<div class="GlobalLayout GlobalLayout--twoColumn">
  <main id="MainContent" role="main" tabindex="-1" class="Main">
    <header class="Main-header">
      <h1 class="Main-heading">Contact us</h1>
    </header>
    <div class="Main-body">
      <div class="GlobalLayout-group">
        <div class="GlobalLayout-item GlobalLayout-item--small">
          <bento-side-nav
            class="NavSide"
            aria-label="Contact us"
            [items]="items"
            (select)="onSelect($event)"
          ></bento-side-nav>
        </div>
        <div class="GlobalLayout-item GlobalLayout-item--large">
          <ng-container *ngIf="items[0].data.isVisible">
            <h2>General</h2>
            <address>
              <div>Thomson Reuters</div>
              <div>2900 Ames crossing road</div>
              <div class="mb-3">Eagan, MN 55121</div>
              <div>
                For all inquiries, <a href="mailto:financialinsights@thomsonreuters.com">email us</a> and we will
                respond as quickly as possible.
              </div>
            </address>
          </ng-container>
          <ng-container *ngIf="items[1].data.isVisible">
            <h2>Technical support</h2>
            <address>
              For help with implementation, monthly extract issues, or other software issues,
              <a href="mailto:financialinsights.admin@thomsonreuters.com">contact us</a>.
            </address>
          </ng-container>
          <ng-container *ngIf="items[2].data.isVisible">
            <h2>Sales assistance</h2>
            <address>
              <div class="mb-3">
                For product information, pricing, and to begin the process of bringing Financial Insights to your firm,
                visit
                <a href="https://legal.thomsonreuters.com/en/products/strategic-insights/features" target="_blank">Financial Insights details</a> or
                <a href="mailto:financialinsights@thomsonreuters.com">email</a>.
              </div>
            </address>
          </ng-container>
        </div>
      </div>
    </div>
  </main>
</div>
