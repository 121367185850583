export default class RebuildHistoryModel {
  public fiscalPeriodModel: any;
  public fiscalDateRange: any;
  public selectedRecord: any[];
  public fte = false;
  public office = false;
  public practice = false;
  public title = false;
  public experienceYears = false;
  public selectedRecordName = '';
  public constructor() {}
}
