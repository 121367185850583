<app-global-header></app-global-header>
<main id="MainContent" tabindex="-1" class="Main Main--zeroState">
  <figure class="ZeroState">
    <app-svg-exclamation class="ZeroState-header"></app-svg-exclamation>
    <figcaption class="ZeroState-body">
      <h1 class="h3">{{ errorStatus }} - Something went wrong on our side</h1>
      <p>
        An error occurred while responding to this request. Reload the page or
        <a [routerLink]="" (click)="signOut()">Login</a> again. If the problem persists, reach out to our support team.
      </p>
      <p>
        <a href="mailto:financialinsights@thomsonreuters.com">Contact support</a>
      </p>
      <p *ngIf="ciamToken">Reference the code below to address this issue:</p>
      <div class="ZeroState-actions" *ngIf="ciamToken">
        <ul class="ZeroState-list">
          <li class="ZeroState-item" class="fontXXLarge">
            {{ ciamToken.substring(ciamToken.length - 20) }}
          </li>
        </ul>
      </div>
    </figcaption>
  </figure>
</main>
