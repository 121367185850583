import {Component, OnInit, HostBinding} from '@angular/core';

@Component({
  selector: 'app-site-down',
  templateUrl: './site-down.component.html',
})
export class SiteDownComponent implements OnInit {
  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }

  constructor() {}

  ngOnInit(): void {}
}
