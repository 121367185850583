import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-prevent-mapping-modal',
  templateUrl: './prevent-mapping-modal.component.html',
})
export class PreventMappingModalComponent implements OnInit {
  public subject: Subject<boolean>;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
  saveChanges(msg) {
    if (this.subject) {
      this.subject.next(true);
      this.subject.complete();
    }
    this.activeModal.close(msg);
  }
}
