export default class PeerCheckViewColumns {
  showMean: boolean;
  showMedian: boolean;
  showStdDev: boolean;
  showHiLo: boolean;
  showQuartile: boolean;
  showYOY: boolean;
  showVariance: boolean;
  defaultCol: string;
  public constructor() {
    this.defaultCol = 'Mean';
  }
}
