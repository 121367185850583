import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../../../services/auth/auth.service';
import {BackendTokenClaims} from '../../../models/tokenResponse';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {eventDispatcher, store} from 'src/app/core/store';
import {ActionTypes} from 'src/app/core/store/actions';
import FirmBasicsModel from '../../../models/firm-basics.model';
import PeerCheckViewColumns from '../../../models/peerCheckViewColumns.model';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {PeerGroupDetails, PeerGroupInfo} from 'src/app/core/models/create-view.model';
import {ResponseHandler, VisualizationTemplate} from 'src/app/core/models/create-chart.model';
import {PeercheckMessageHandlerService} from 'src/app/core/services/peercheck-message-handler/peercheck-message-handler.service';
//import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class PeerCheckServiceService {
  firmBasicsData = new FirmBasicsModel();
  firmId: number;
  errorMessage = '';
  basicDetails = new FirmBasicsModel();
  peerCheckViewCols = new PeerCheckViewColumns();
  httpClient: any;
  alerts: BentoAlertItemOptions[] = [];
  isGetBasicCalled = false;

  private isDataLoadingMessageSource = new Subject<boolean>();
  isDataLoadingMessage = this.isDataLoadingMessageSource.asObservable();

  private peerGroupSelectedFirmsSource = new Subject<PeerGroupDetails>();
  peerGroupSelectedFirms = this.peerGroupSelectedFirmsSource.asObservable();

  isFirmChangedSource = new Subject<any>();
  isFirmChangedMessage = this.isFirmChangedSource.asObservable();
  private vizTemplateDetails: VisualizationTemplate;
  private templateDetailsResponseHandler: ResponseHandler;
  private selectedFirmId: any;
  indicatorSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');
  selectedPeriodSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');
  displayAuthorizeButton: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  balancingLinkSubject: BehaviorSubject<any> = new BehaviorSubject<any>({selected: 'Default', value: ''});
  defaultPeriodSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');
  selectedGlAcOverrideSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private service: BaseService,
    private peercheckMsgService: PeercheckMessageHandlerService
  ) {
    store.subscribe((state) => {
      const {basicDetails} = state;
      if (basicDetails != null && !this.isGetBasicCalled) {
        this.basicDetails = basicDetails;
      } else if (!this.isGetBasicCalled) {
        this.callGetBasic();
      }
    });
    eventDispatcher.next({type: ActionTypes.GetBasicDetails});

    //set default values for the peercheck grid columns
    this.setDefaultValues();
  }

  get getSelectedFirmId() {
    return this.selectedFirmId;
  }

  set setSelectedFirmId(firmID) {
    if(firmID !=this.selectedFirmId){
      if (firmID) this.selectedFirmId = firmID;
      this.isFirmChangedSource.next(this.selectedFirmId);
    }
  }

  isValidMappingFirm(firmId) {
    return parseInt(firmId) > 900 && parseInt(firmId) < 1999;
  }

  async callGetBasic() {
    this.basicDetails = await this.getBasicDetails(this.firmId);
  }

  setDefaultValues() {
    this.peerCheckViewCols = {
      showMean: true,
      showMedian: true,
      showStdDev: true,
      showHiLo: true,
      showQuartile: true,
      showYOY: true,
      showVariance: true,
      defaultCol: 'Mean',
    };
  }

  /*getFirmId() {
    if (this.route.snapshot.params.id) {
      this.firmId = Number(this.route.snapshot.params.id);
    } else {
      const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
      this.firmId =
        backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.firmID
          ? backendTokenClaims.userDDO.firmID
          : 0;
    }
  } */

  async getFirmBasicsData(firmId) {
    this.firmId = firmId;
    if (!this.basicDetails || this.basicDetails.firmId != firmId)
      this.basicDetails = await this.getBasicDetails(this.firmId);
    return this.basicDetails;
  }

  getIndicatorName() {
    return this.indicatorSubject.asObservable();
  }
  setIndicatorName(indicatorName) {
    this.indicatorSubject.next(indicatorName);
  }

  getSelectedPeriod() {
    return this.selectedPeriodSubject.asObservable();
  }

  setSelectedPeriod(period) {
    this.selectedPeriodSubject.next(period);
  }

  getDisplayAuthorizeButtonValue() {
    return this.displayAuthorizeButton.asObservable();
  }

  setDisplayAuthorizeButton(displayAuthorizeButton) {
    this.displayAuthorizeButton.next(displayAuthorizeButton);
  }

  getBalancingValue() {
    return this.balancingLinkSubject.asObservable();
  }
  setBalancingValue(balancingValue) {
    this.balancingLinkSubject.next(balancingValue);
  }

  getDefaultSubject() {
    return this.defaultPeriodSubject.asObservable();
  }
  setDefaultSubject(defaultSubject) {
    this.defaultPeriodSubject.next(defaultSubject);
  }

  getSelectedGlAcOverrideSubject() {
    return this.selectedGlAcOverrideSubject.asObservable();
  }
  setSelectedGlAcOverrideSubject(defaultSubject) {
    this.selectedGlAcOverrideSubject.next(defaultSubject);
  }

  getBasicDetails(firmId): any {
    if (firmId) {
      this.isDataLoadingMessageSource.next(true);
      this.firmId = firmId;
      return this.service
        .get(environment.FIAdminBaseEndpoint + 'v1/view/basic/details/' + firmId, '')
        .toPromise()
        .then(
          (result) => {
            this.isDataLoadingMessageSource.next(false);
            this.firmBasicsData = result;
            this.getFirmLevelRules();
            this.firmBasicsData['firmLevelRules'] = this.peerCheckViewCols;
            this.firmBasicsData['firmId'] = firmId;
            eventDispatcher.next({type: ActionTypes.SetBasicDetails, payload: this.firmBasicsData});
            this.isGetBasicCalled = true;

            return this.firmBasicsData;
          },
          (error) => {
            this.errorMessage = error.error;
            return this.firmBasicsData;
          }
        );
    }
  }

  //set firm level rules to the peercheck view
  getFirmLevelRules(): any {
    this.setDefaultValues();
    let rules = [];
    let firmBasicsData = this.firmBasicsData;

    if (!firmBasicsData || Object.keys(firmBasicsData).length === 0) return;

    // create array of firm rule names
    firmBasicsData.rules.forEach((element) => {
      rules.push(element.name);
    });

    // set default column to 'Mean' and hide the columns for quartile and median,
    // if either the rule has 'NoPercentiles' or the default access for firm is 'UK' 
    if (rules.includes('NoPercentiles') || firmBasicsData.defaultAccess == 'UK') {
      this.peerCheckViewCols.showQuartile = false;
      this.peerCheckViewCols.showMedian = false;
      this.peerCheckViewCols.defaultCol = 'Mean';
    }

    // set default column to 'Mean' and hide the columns for Variance, YOY and median,
    // if either the rule has the default access for firm is 'CAN' 
    if(firmBasicsData.defaultAccess == 'CAN') {
      this.peerCheckViewCols.showYOY = false;
      this.peerCheckViewCols.showVariance = false;
      this.peerCheckViewCols.showMedian = false;
      this.peerCheckViewCols.defaultCol = 'Mean';
    }
    // set default column to 'Median' and hide the column for mean,
    // if the rule has 'NoMean' and the default access for the firm is not 'UK'
    if (rules.includes('NoMean') && firmBasicsData.defaultAccess != 'UK') {
      this.peerCheckViewCols.defaultCol = 'Median';
      this.peerCheckViewCols.showMean = false;
    }
    // hide the column for mean, if the rule has 'NoMean'
    if (rules.includes('NoMean')) {
      this.peerCheckViewCols.showMean = false;
    }

    return this.peerCheckViewCols;
  }

  isforbiddenMedianMetrics(metricId, firmDefaultAccess) {
    var localSpecificMetrics = {
      CAN: [9, 10, 11, 12, 13, 17, 19, 20, 21, 26, 47, 49, 53, 56, 57, 63, 64, 69, 91, 93],
      AU: [9, 10, 11, 49],
      NZ: [9, 10, 11, 49],
    };
    if (localSpecificMetrics[firmDefaultAccess]) {
      if (localSpecificMetrics[firmDefaultAccess].includes(metricId)) return true;
    } else return false;
  }

  //set metric level rules to the peercheck view
  getColVisibilityForSelectedMetric(metricId, pcViewCols, firmBasicsData): any {
    let rules = [];
    // create array of firm rule names
    firmBasicsData.rules.forEach((element) => {
      rules.push(element.names);
    });

    //hide median for local specific metrics
    if (this.isforbiddenMedianMetrics(metricId, firmBasicsData.defaultAccess)) pcViewCols.showMedian = false;

    // get metric data from getBasic data
    let selectedMetricData = firmBasicsData.metrics.filter((item) => item.metricId == metricId)[0];

    // if deltaOnly = true for the selected metric,
    // hide columns for standard deviations and set the default column to 'Median'
    if (selectedMetricData.deltaOnly) {
      pcViewCols.showStdDev = false;
    }
    // if the firm rule contains 'HighLowPercentiles',
    // hide the columns for standard deviations and show the column for high/low percentiles
    if (rules.includes('HighLowPercentiles')) {
      pcViewCols.showStdDev = false;
      pcViewCols.showHiLo = true;
    }
    // if the firm rule contains 'NoHighLow', or the selected metric-id is in local specific metrics
    // hide the columns for standard deviations and high/low percentiles
    if (rules.includes('NoHighLow') || this.isforbiddenMedianMetrics(metricId, firmBasicsData.defaultAccess)) {
      pcViewCols.showStdDev = false;
      pcViewCols.showHiLo = false;
    }
    //if the firm currency is 'GBP', hide the columns for standard deviations and high/low percentiles
    if (firmBasicsData.firmCurrency == 'GBP') {
      pcViewCols.showStdDev = false;
      pcViewCols.showHiLo = false;
    }
    // if (firmBasicsData.defaultAccess == 'CAN') {
    //   pcViewCols.showStdDev = false;
    // }

    let metricBasicData = {
      mean: pcViewCols.showMean,
      median: pcViewCols.showMedian,
      stdDev: pcViewCols.showStdDev,
      hilo: pcViewCols.showHiLo,
      defaultCol: pcViewCols.defaultCol,
      quartile: pcViewCols.showQuartile,
      highBad: selectedMetricData.highBad,
      isDeltaOnly: selectedMetricData.deltaOnly,
    };
    return metricBasicData;
  }

  getSelectedFirmsForPg(peerGroup) {
    this.isDataLoadingMessageSource.next(true);
    this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/peergroup/' + peerGroup.id + '/firm/' + this.firmId + '/view', '')
      .subscribe(
        (result) => {
          this.isDataLoadingMessageSource.next(false);
          let peerGroupSelectedFirms: PeerGroupInfo = new PeerGroupInfo();
          peerGroupSelectedFirms.selectedPeerGroup = peerGroup;
          peerGroupSelectedFirms.selectedFirms = result;

          let peerGroupDetails: PeerGroupDetails = new PeerGroupDetails();
          peerGroupDetails.hasError = false;
          peerGroupDetails.selectedPeerGroup = peerGroup;
          peerGroupDetails.successResponse = peerGroupSelectedFirms;
          peerGroupDetails.alerts = [];
          this.peerGroupSelectedFirmsSource.next(peerGroupDetails);
        },
        (error) => {
          this.isDataLoadingMessageSource.next(false);
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
          let peerGroupDetails: PeerGroupDetails = new PeerGroupDetails();
          peerGroupDetails.hasError = true;
          peerGroupDetails.failedResponse = this.errorMessage;
          peerGroupDetails.errorMessage = this.errorMessage;
          peerGroupDetails.alerts = this.alerts;
          this.peerGroupSelectedFirmsSource.next(peerGroupDetails);
        }
      );
  }

  getCriteriaForPerformancePg(peerGroup, isCanFirm, isTAdmin) {
    this.isDataLoadingMessageSource.next(true);
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/peergroup/performance/' + peerGroup.id, '').subscribe(
      (result) => {
        this.isDataLoadingMessageSource.next(false);
        let peerGroupSelectedFirms: PeerGroupInfo = new PeerGroupInfo();

        let ppgDetails = [];
        if( (isCanFirm && isTAdmin) || !isCanFirm ){
          ppgDetails.push('Firms: ' + result.numberOfFirms);
        }
        if (result.offices && result.offices.length) {
          ppgDetails.push('Offices: ' + this.getCommaSeparatedByStringArray(result.offices));
        }
        if (result.practiceGroups && result.practiceGroups.length) {
          ppgDetails.push('Practices: ' + this.getCommaSeparatedByStringArray(result.practiceGroups));
        }
        if (result.criteria && result.criteria.length) {
          ppgDetails.push('Criteria: ' + this.getCommaSeparatedByStringArray(result.criteria));
        }
        peerGroupSelectedFirms.selectedPeerGroup = peerGroup;
        peerGroupSelectedFirms.ppgCriteria = ppgDetails;
        peerGroupSelectedFirms.is_ppgRequest = true;

        let peerGroupDetails: PeerGroupDetails = new PeerGroupDetails();
        peerGroupDetails.hasError = false;
        peerGroupDetails.selectedPeerGroup = peerGroup;
        peerGroupDetails.successResponse = peerGroupSelectedFirms;
        peerGroupDetails.alerts = [];
        this.peerGroupSelectedFirmsSource.next(peerGroupDetails);
      },
      (error) => {
        this.isDataLoadingMessageSource.next(false);
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
        let peerGroupDetails: PeerGroupDetails = new PeerGroupDetails();
        peerGroupDetails.hasError = true;
        peerGroupDetails.failedResponse = this.errorMessage;
        peerGroupDetails.errorMessage = this.errorMessage;
        peerGroupDetails.alerts = this.alerts;
        this.peerGroupSelectedFirmsSource.next(peerGroupDetails);
      }
    );
  }

  getCommaSeparatedByStringArray(items: string[]) {
    let string = '';
    if (items && items.length > 0) {
      items.forEach((item, index) => {
        if (items.length === 1) {
          string = item;
        } else if (items.length === index + 1 && index > 1) {
          string = string.replace(/,\s*$/, '');
          string = string + ', and ' + item;
        } else {
          string = string + item + ', ';
        }
      });
      string = string.replace(/,\s*$/, '');
      return string;
    }
  }

  async getVisualizationDetailsWithType(visualizationId: string, firmId: number, isForTemplate?: boolean) {
    this.firmId = firmId;
    if (
      !this.vizTemplateDetails ||
      this.vizTemplateDetails.firmId != firmId ||
      (isForTemplate && this.vizTemplateDetails.chartId != null) ||
      (isForTemplate && this.vizTemplateDetails.templateId != visualizationId) ||
      (!isForTemplate && this.vizTemplateDetails.chartId != visualizationId) 
    ) {
      this.templateDetailsResponseHandler = await this.getTemplateDetails(visualizationId, this.firmId, isForTemplate);
      this.vizTemplateDetails = this.templateDetailsResponseHandler.response as VisualizationTemplate;
    }
    return this.templateDetailsResponseHandler;
  }

  getTemplateDetails(templateId: string, firmId: number, isForTemplate?: boolean) {
    let responseHandler: ResponseHandler = new ResponseHandler();
    if (firmId) {
      let url = '';
      if (isForTemplate) {
        // v1/chart/template/1/firm/950
        url = environment.FIAdminBaseEndpoint + 'v1/chart/template/' + templateId + '/firm/' + firmId;
      } else {
        // v1/chart/1/firm/950/details
        url = environment.FIAdminBaseEndpoint + 'v1/chart/' + templateId + '/firm/' + firmId + '/details';
      }
      this.isDataLoadingMessageSource.next(true);
      return this.service
        .get(url, '')
        .toPromise()
        .then(
          (result) => {
            this.isDataLoadingMessageSource.next(false);
            this.vizTemplateDetails = result;
            this.vizTemplateDetails.firmId = firmId;
            responseHandler.hasError = false;
            responseHandler.response = this.vizTemplateDetails;
            responseHandler.alerts = [];
            responseHandler.errorMessage = null;
            return responseHandler;
          },
          (error) => {
            this.isDataLoadingMessageSource.next(false);
            this.alerts.push({
              type: 'warning',
              msg: this.peercheckMsgService.getServiceErrorMessageString(error.error),
              closeable: true,
            });
            this.errorMessage = error.error;
            responseHandler.hasError = true;
            responseHandler.response = null;
            responseHandler.alerts = this.alerts;
            responseHandler.errorMessage = this.errorMessage;
            return responseHandler;
          }
        );
    }
  }

  markVisualizationFavoriteUnfavorite(visualizationId: string, isFavorite: boolean, isForTemplate?: boolean) {
    let responseHandler: ResponseHandler = new ResponseHandler();
    let url = '';
    let actionText = isFavorite ? '/unfavorite' : '/favorite';
    if (isForTemplate) {
      url = environment.FIAdminBaseEndpoint + 'v1/chart/template/' + visualizationId + actionText;
    } else {
      url = environment.FIAdminBaseEndpoint + 'v1/chart/' + visualizationId + actionText;
    }
    this.isDataLoadingMessageSource.next(true);
    return this.service
      .put(url, '')
      .toPromise()
      .then(
        (result) => {
          this.isDataLoadingMessageSource.next(false);
          responseHandler.hasError = false;
          responseHandler.response = [];
          responseHandler.alerts = [];
          responseHandler.errorMessage = null;
          return responseHandler;
        },
        (error) => {
          this.isDataLoadingMessageSource.next(false);
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
          this.errorMessage = error.error;
          responseHandler.hasError = true;
          responseHandler.response = null;
          responseHandler.alerts = this.alerts;
          responseHandler.errorMessage = this.errorMessage;
          return responseHandler;
        }
      );
  }

  resetVisualizationTemplateDetails() {
    this.vizTemplateDetails = null;
    this.templateDetailsResponseHandler = null;
  }
  //Filter Body scroll method for timekeeper, gl extract and production extract
  filterBodyMaxheightScoll(filterbodyId) {
    var winHeight =  window.innerHeight;
    var filterBodyMaxheight = winHeight - 221;
    var filterBodyMinheight = winHeight - 222;
    const filterPanelBodyElement = document.getElementById(filterbodyId);
    filterPanelBodyElement.style.maxHeight = filterBodyMaxheight+'px';
    filterPanelBodyElement.style.minHeight = filterBodyMinheight+'px';
    filterPanelBodyElement.style.overflowY = "auto";
  }
}
