<div class="bento-multiselect-overlay-inner">
  <div
    #overlay
    class="bento-side-overlay"
    [ngClass]="
      (_side == 'right' ? 'bento-side-overlay-right ' : '') +
      (_side == 'left' ? 'bento-side-overlay-left ' : '') +
      (_isOpen ? 'open ' : '')
    "
    tabindex="-1"
  >
    <button
      #toggleButton
      class="bento-side-overlay-toggle btn btn-outline-primary btn-multiselect-overlay"
      [disabled]="_disabled"
      (click)="_onToggleClick($event)"
      aria-haspopup="true"
      [attr.aria-labelledby]="ariaFieldHeadingValueId+' '+ariaLabelValueLableId+' '+ariaFieldHintTextValueId+' '+ariaLabelValueId"
      [attr.id]="ariaLabelValueId"
      type="button"
    >
      {{
        selectedList.length === 0
          ? ('BENTO_UI_MULTISELECT_OVERLAY_SELECT'
            | bentoTranslate : selectButtonText || customTranslation.BENTO_UI_MULTISELECT_OVERLAY_SELECT)
          : ('BENTO_UI_MULTISELECT_OVERLAY_EDIT'
            | bentoTranslate : editButtonText || customTranslation.BENTO_UI_MULTISELECT_OVERLAY_EDIT)
      }}
    </button>
    <span class="sr-only" [attr.id]="ariaLabelValueLableId">{{ariaLabelValue}}{{ariaRequiredLabelText}}</span>
    <div
      #containerWrapper
      class="bui-bento-side-overlay-container-wrapper bento-side-overlay-container-wrapper"
      [ngClass]="_showOverlay ? 'show-overlay ' : ''"
    >
      <div #overlayContainer class="bento-side-overlay-container" tabindex="-1" cdkTrapFocus>
        <app-bento-multiselect-list
          [itemsObservable]="itemsObservable"
          [height]="_listHeight"
          [width]="_listWidth"
          [showAllText]="showAllText || customTranslation.BENTO_UI_MULTISELECT_OVERLAY_SHOW_ALL"
          [showSelectedText]="showSelectedText || customTranslation.BENTO_UI_MULTISELECT_OVERLAY_SHOW_SELECTED"
          [selectAllText]="selectAllText || customTranslation.BENTO_UI_MULTISELECT_OVERLAY_SELECT_ALL"
          [searchLabelText]="searchLableText || customTranslation.BENTO_UI_MULTISELECT_OVERLAY_SEARCH_LABEL"
          [searchLabelHelpText]="searchLableHelpText"
          [selectedList]="_preselectedList"
          [isRatesReport]="isRatesReport"
          (selectedListChange)="_onSelectionChange($event)"
          [listItemTemplate]="listItemTemplate"
          [searchCompare]="searchCompare"
          (lengthChange)="_itemsLengthChange($event)"
          class="fill"
          [maxSelectedItems]="maxSelectedItems || 0"
          [minSelectedItems]="minSelectedItems || 0"
          [isSelectAllVisible]="isSelectAllVisible"
          [ariaLabelValue] = "ariaLabelValue"
        ></app-bento-multiselect-list>
        <div #overlayFooter class="bento-side-overlay-container-footer">
          <button
            #doneButton
            class="btn bento-side-overlay-container-footer-done"
            [ngClass]="revertSelectionsOnDismissal ? 'btn-primary' : 'btn-outline-primary btn-multiselect-overlay'"
            type="button"
            (click)="_onDoneClick($event, false)"
          >
            {{
              'BENTO_UI_MULTISELECT_OVERLAY_DONE'
                | bentoTranslate : doneButtonText || customTranslation.BENTO_UI_MULTISELECT_OVERLAY_DONE
            }}
          </button>
          <button
            #cancelButton
            class="btn btn-outline-primary bento-side-overlay-container-footer-cancel"
            type="button"
            *ngIf="revertSelectionsOnDismissal"
            (click)="_onDoneClick($event, true)"
          >
            {{
              'BENTO_UI_MULTISELECT_OVERLAY_CANCEL'
                | bentoTranslate : cancelButtonText || customTranslation.BENTO_UI_MULTISELECT_OVERLAY_CANCEL
            }}
          </button>
        </div>
      </div>
      <div class="bento-side-overlay-left-arrow">
        <svg height="35" width="20">
          <polygon points="21,0 0.5,17.5 21,35" class="bento-side-overlay-triangle" />
        </svg>
      </div>
      <div class="bento-side-overlay-right-arrow">
        <svg height="35" width="20">
          <polygon points="-1,0 19.5,17.5 -1,35" class="bento-side-overlay-triangle" />
        </svg>
      </div>
    </div>
  </div>
  <!-- <span #label class="bento-multiselect-overlay-label" tabindex="-1" [hidden]="selectedList.length === 0">
    {{
      selectedList.length === 1
        ? selectedList[0].name
        : selectedList.length === _itemsLength
        ? ('BENTO_UI_MULTISELECT_OVERLAY_ALL_SELECTED_INFO'
          | bentoTranslate: allSelectedInfoText || customTranslation.BENTO_UI_MULTISELECT_OVERLAY_ALL_SELECTED_INFO
          | bentoStringReplace: '_NUMBER_':selectedList.length)
        : ('BENTO_UI_MULTISELECT_OVERLAY_SELECTED_INFO'
          | bentoTranslate: selectedInfoText || customTranslation.BENTO_UI_MULTISELECT_OVERLAY_SELECTED_INFO
          | bentoStringReplace: '_NUMBER_':selectedList.length)
    }}
  </span> -->
</div>
