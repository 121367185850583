import {Component, OnInit, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ManageColumn} from 'src/app/core/models/manage-column.model';
import {TkFilterModel} from 'src/app/core/models/tk-filter.model';
import {TkHistoryRequest} from 'src/app/core/models/tk-history-request.model';

@Component({
  selector: 'app-manage-columns',
  templateUrl: './manage-columns.component.html',
  styleUrls: ['./manage-columns.component.scss'],
})
export class ManageColumnsComponent implements OnInit {
  isDataLoading: boolean;
  isOfficeVisible: boolean;
  manageColumn: ManageColumn = new ManageColumn();
  @Input() public tkFilterData: TkFilterModel;

  constructor(public activeModal: NgbActiveModal) {}

  onSave() {
    this.tkFilterData.isOfficeVisible = this.manageColumn.isOfficeVisible;
    this.tkFilterData.isTitleVisible = this.manageColumn.isTitleVisible;
    this.tkFilterData.isExpYearsVisible = this.manageColumn.isExpYearsVisible;
    this.tkFilterData.isPeerOfficeVisible = this.manageColumn.isPeerOfficeVisible;
    this.tkFilterData.isPeerTitleVisible = this.manageColumn.isPeerTitleVisible;
    this.tkFilterData.isPeerPracticeVisible = this.manageColumn.isPeerPracticeVisible;
    this.activeModal.close();
  }

  ngOnInit(): void {
    this.manageColumn.isOfficeVisible = this.tkFilterData.isOfficeVisible;
    this.manageColumn.isTitleVisible = this.tkFilterData.isTitleVisible;
    this.manageColumn.isExpYearsVisible = this.tkFilterData.isExpYearsVisible;
    this.manageColumn.isPeerOfficeVisible = this.tkFilterData.isPeerOfficeVisible;
    this.manageColumn.isPeerTitleVisible = this.tkFilterData.isPeerTitleVisible;
    this.manageColumn.isPeerPracticeVisible = this.tkFilterData.isPeerPracticeVisible;
  }
}
