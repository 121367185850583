<div class="PivotTable" [bentoBusyLoader]="isDataLoading">
  <ng-container *ngIf="showTab">
    <div *ngIf="showStatistic" class="form-group Form-input Form-input--small PivotTable-header">
      <label for="statistic">Statistic</label>
      <select
        name="statistic"
        id="statistic"
        class="form-control"
        bentoSelect
        [(ngModel)]="statistic"
        (change)="onChangeStatistic($event)"
      >
        <option *ngFor="let statistic of statistics">{{ statistic }}</option>
      </select>
    </div>
    <div class="bento-tabset tabs-top">
      <ul ngbNav #nav="ngbNav" [activeId]="activeTab" (navChange)="onTabChange($event.nextId)" class="nav nav-tabs">
        <li [ngbNavItem]="title" id="{{ title }}" *ngFor="let title of tabData">
          <a ngbNavLink>
            <bento-tabs-label [label]="title"></bento-tabs-label>
          </a>
          <ng-template ngbNavContent>
            <div *ngIf="activeTab === 'Practice Groups' && isPivotDataLoaded">
              <app-pivot-table-grid-view
                [metricId]="metricId"
                [metricBasicData]="metricBasicData"
                [highLowPercentilesCols]="highLowPercentilesCols"
                class="u-flexGrowCol"
                [peerGroupItems]="peerGroupItems"
                [pivotIndex]="cardIndex"
                [practiceData]="practiceData"
                [pivotTab]="'practices'"
                [currency]="firmBasicData.currency"
                [frozenColumnCount]="frozenColumnCount"
                [userSettingsModel]="userSettingsModel"
                [yoyHeader]="yoyHeader"
                [isTAdmin] ="isTAdmin"
                [isCANFirm] = "isCANFirm"
              ></app-pivot-table-grid-view>
            </div>
            <div *ngIf="activeTab === 'Offices'">
              <app-pivot-table-grid-view
                [metricId]="metricId"
                [metricBasicData]="metricBasicData"
                [highLowPercentilesCols]="highLowPercentilesCols"
                class="u-flexGrowCol"
                [peerGroupItems]="peerGroupItems"
                [pivotIndex]="cardIndex"
                [officeData]="officeData"
                [officeGroupData]="officeGroupData"
                [pivotTab]="'offices'"
                [selectedOffice]="selectedOffice"
                [currency]="firmBasicData.currency"
                [frozenColumnCount]="frozenColumnCount"
                [userSettingsModel]="userSettingsModel"
                [yoyHeader]="yoyHeader"
                [isTAdmin] ="isTAdmin"
                [isCANFirm] = "isCANFirm"
              ></app-pivot-table-grid-view>
            </div>
            <div *ngIf="activeTab === 'Timekeepers' || activeTab === 'Fee Earners'">
              <app-pivot-table-grid-view
                [activeTab]="activeTab"
                [metricId]="metricId"
                [metricBasicData]="metricBasicData"
                [highLowPercentilesCols]="highLowPercentilesCols"
                class="u-flexGrowCol"
                [peerGroupItems]="peerGroupItems"
                [pivotIndex]="cardIndex"
                [titleData]="titleData"
                [titleGroupData]="titleGroupData"
                [allLawyersData]="allLawyersData"
                [allPartnersData]="allPartnersData"
                [allTimekeepersData]="allTimekeepersData"
                [pivotTab]="'titles'"
                [currency]="firmBasicData.currency"
                [frozenColumnCount]="frozenColumnCount"
                [userSettingsModel]="userSettingsModel"
                [yoyHeader]="yoyHeader"
                [isTAdmin] ="isTAdmin"
                [isCANFirm] = "isCANFirm"
              ></app-pivot-table-grid-view>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="tab-content"></div>
    </div>
  </ng-container>
</div>
