<bento-alert [items]="alerts"></bento-alert>
<section class="Section" [bentoBusyLoader]="isSRUpdated">
  <form name="staffingratioSetting" #staffingratioSetting="ngForm" class="Section-form Form" bfmForm>
    <header class="Section-header">
      <h2 class="Section-heading">Staffing ratio subscription</h2>
    </header>
    <div class="Section-body">
      <div class="form-group Form-input Form-checkbox">
        <bento-checkbox
          name="isSubscribed"
          id="isSubscribed"
          [(ngModel)]="firmStaffingratioSubscription.isSubscribed"
        ></bento-checkbox>
        <label for="isSubscribed" class="checkbox-inline">Subscribe for {{ currentSurveyYearOfFirm }}</label>
      </div>

      <div class="form-group Form-input Form-input--medium">
        <label for="mainOfficeCountry">Main office country</label>
        <input
          bfmField
          bfmLabel="mainOfficeCountry"
          id="mainOfficeCountry"
          name="mainOfficeCountry"
          type="text"
          class="form-control"
          [disabled]="true"
          [(ngModel)]="firmStaffingratioSubscription.mainOfficeCountry"
        />
      </div>
      <div class="form-group Form-input Form-input--medium">
        <label for="noOfStdPg">Number of standard peer groups</label>
        <input
          bfmField
          bfmLabel="Number of standard peer groups"
          id="noOfStdPg"
          name="noOfStdPg"
          type="number"
          min="0"
          max="60"
          [pattern]="numberOfStdPeerGroupValidation"
          [validations]="validations.numberOfStdPeerGroupValidation"
          class="form-control"
          [(ngModel)]="firmStaffingratioSubscription.numberOfStdPeerGroups"
        />
      </div>
      <div class="form-group Form-input Form-input--medium">
        <label for="noOfCustomPg">Number of custom peer groups</label>
        <input
          bfmField
          bfmLabel="Number of custom peer groups"
          id="noOfCustomPg"
          name="noOfCustomPg"
          type="number"
          min="0"
          max="60"
          [pattern]="numberOfCustPeerGroupValidation"
          [validations]="validations.numberOfCustPeerGroupValidation"
          class="form-control"
          [(ngModel)]="firmStaffingratioSubscription.numberOfCustomPeerGroups"
        />
      </div>

      <div style="padding-top: 2%">
        <label for="flexGrid">Existing survey subscriptions</label>
        <wj-flex-grid
          #flexGrid
          class="DataGrid DataGridFlexScroll widthClass btn-addreport"
          [itemsSource]="data"
          [stickyHeaders]="true"
          [showMarquee]="true"
          [showSort]="true"
          [autoRowHeights]="true"
          [headersVisibility]="'Column'"
          (initialized)="gridInitialized(flexGrid)"
        >
          <wj-flex-grid-column
            [header]="'Survey year'"
            [binding]="'surveyYear'"
            [visible]="true"
            [width]="173"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            style="left: 0px; top: 0px; width: 103px; height: 34px"
            [header]="'Standard peer groups '"
            [binding]="'numberOfStdPeerGroups'"
            [visible]="true"
            [width]="180"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            style="left: 0px; top: 0px; width: 103px; height: 34px"
            [header]="'Custom peer groups '"
            [binding]="'numberOfCustomPeerGroups'"
            [visible]="true"
            [width]="180"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
        </wj-flex-grid>
      </div>
    </div>
    <footer class="Section-footer">
      <div class="Actions">
        <div class="Actions-item">
          <button type="button" class="btn btn-outline-secondary" (click)="resetInformation()">
            <span class="btn-text">Cancel</span>
          </button>
        </div>
        <div class="Actions-item">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="staffingratioSetting.invalid"
            (click)="saveStaffingRationSubscription()"
          >
            <span class="btn-text">Save</span>
          </button>
        </div>
      </div>
    </footer>
  </form>
</section>
