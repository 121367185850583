<div class="GlobalLayout GlobalLayout--twoColumn" >
  <main id="MainContent" role="main" tabindex="-1" class="Main" [bentoBusyLoader]="isLoading" role="main">
    <header class="Main-header">
      <h1 class="Main-heading">Resources</h1>
    </header>
    <div class="Main-body">
      <div class="GlobalLayout-group">
        <div class="GlobalLayout-item GlobalLayout-item--small">
          <bento-side-nav class="NavSide" aria-label="Resources" [collapsible]="true" [items]="items"></bento-side-nav>
        </div>
        <div class="GlobalLayout-item GlobalLayout-item--large">
          <ng-container *ngIf="items[0].data.isVisible && resourceContent">
            <h2>Reference documents</h2>
            <ul class="bento-list" *ngIf="resourceContent.reference">
              <li class="bento-list-item mb-3" *ngFor="let content of resourceContent.reference">
                <a [routerLink]="['.']" (click)="downloadResourceFile(content.link, content.name, content.extension)">
                  {{ content.name.split('.').slice(0, -1).join('.') }}
                </a>
                <span> ({{ content.extension | uppercase }})</span>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="items[1].data.isVisible && resourceContent">
            <h2>User guide</h2>
            <ul class="bento-list" *ngIf="resourceContent.userGuide">
              <li class="bento-list-item mb-3" *ngFor="let content of resourceContent.userGuide">
                <a [routerLink]="['.']" (click)="downloadResourceFile(content.link, content.name, content.extension)">
                  {{ content.name.split('.').slice(0, -1).join('.') }}
                </a>
                <span>&nbsp;({{ content.extension | uppercase }})</span>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="items[2].data.isVisible && resourceContent">
            <h2>Legal industry update</h2>
            <address>
              If you are looking for legal industry updates beyond 2019 contact a Financial Insights
              <a href="mailto:financialinsights@thomsonreuters.com">representive</a>.
            </address>
            <ng-container *ngIf="resourceContent.liu">
              <section *ngFor="let content of resourceContent.liu">
                <ng-container *ngFor="let files of content.files | keyvalue">
                  <ng-container *ngIf="files.value.length > 0">
                    <div class="d-inline-flex">
                      <h3>{{ content.year }} {{ files.key }}</h3>
                      <span>&nbsp;- (PDF)</span>
                    </div>
                    <ul class="bento-list mb-3">
                      <li class="bento-list-item" *ngFor="let file of files.value">
                        <a [routerLink]="['.']" (click)="downloadResourceFile(file.link, file.name, file.extension)">
                          {{ file.name.split('.').slice(0, -1).join('.') }}
                        </a>
                      </li>
                    </ul>
                  </ng-container>
                </ng-container>
              </section>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let item of items[3].items">
            <ng-container *ngIf="item.data.isVisible && resourceContent">
              <h2>Client reports - {{ selectedCountry }}</h2>
              <address>
                If you are looking for client reports beyond 2019 contact a Financial Insights
                <a href="mailto:financialinsights@thomsonreuters.com">representive</a>.
              </address>
              <ng-container *ngIf="resourceContent.clientReport">
                <section *ngFor="let content of resourceContent.clientReport">
                  <ng-container *ngIf="content.country == selectedCountry">
                    <ng-container *ngFor="let files of content.files | keyvalue : asIsOrder">
                      <ng-container *ngIf="files.value.length > 0">
                        <div class="d-inline-flex">
                          <h3>{{ files.key }} {{ content.country }}</h3>
                          <span>&nbsp;- (PDF)</span>
                        </div>
                        <ul class="bento-list mb-3">
                          <li class="bento-list-item" *ngFor="let file of files.value">
                            <a
                              [routerLink]="['.']"
                              (click)="downloadResourceFile(file.link, file.name, file.extension)"
                            >
                              {{ file.name.split('.').slice(0, -1).join('.') }}
                            </a>
                          </li>
                        </ul>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </section>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="items[4].data.isVisible && resourceContent">
            <h2>Thought leadership reports</h2>
            <address>
              If you are looking for thought leadership reports beyond 2019 contact a Financial Insights
              <a href="mailto:financialinsights@thomsonreuters.com">representive</a>.
            </address>
            <ng-container *ngIf="resourceContent.thoughtLeadershipReports">
              <section *ngFor="let content of resourceContent.thoughtLeadershipReports">
                <ng-container *ngFor="let files of content.files | keyvalue">
                  <ng-container *ngIf="files.value.length > 0">
                    <div class="d-inline-flex">
                      <h3>{{ content.year }}</h3>
                    </div>
                    <ul class="bento-list mb-3">
                      <li class="bento-list-item" *ngFor="let file of files.value">
                        <a [routerLink]="['.']" (click)="downloadResourceFile(file.link, file.name, file.extension)">
                          {{ file.name.split('.').slice(0, -1).join('.') }}
                        </a>
                        <span>&nbsp; (PDF)</span>
                      </li>
                    </ul>
                  </ng-container>
                </ng-container>
              </section>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="items[5].data.isVisible && resourceContent">
            <h2>Law firm financial index</h2>
            <address>
              If you are looking for law firm financial index beyond 2019 contact a Financial Insights
              <a href="mailto:financialinsights@thomsonreuters.com">representive</a>.
            </address>
            <ng-container *ngIf="resourceContent.lawFirmFinancialIndex">
              <section *ngFor="let content of resourceContent.lawFirmFinancialIndex">
                <ng-container *ngFor="let files of content.files | keyvalue">
                  <ng-container *ngIf="files.value.length > 0">
                    <div class="d-inline-flex">
                      <h3>
                        {{ content.year }}
                        <ng-container *ngIf="yearForPeerMonitor.includes(content.year)"
                          >(Peer Monitor Index)
                        </ng-container>
                      </h3>
                    </div>
                    <ul class="bento-list mb-3">
                      <li class="bento-list-item" *ngFor="let file of files.value">
                        <a [routerLink]="['.']" (click)="downloadResourceFile(file.link, file.name, file.extension)">
                          {{ file.name.split('.').slice(0, -1).join('.') }}
                        </a>
                        <span>&nbsp; (PDF)</span>
                      </li>
                    </ul>
                  </ng-container>
                </ng-container>
              </section>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </main>
</div>
