import {Injectable} from '@angular/core';
import $ from 'jquery';
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  constructor() {}

  addPaginationAdditionalInfo() {
    setTimeout(function () {
      $('.paginate-info-select').attr('id', 'paginate-info-select');
      $('.paginate-bento-select select.form-control').attr('aria-labelledby', 'paginate-info-select');
      
    }, 100);
  }
  skipFocusTwiceCombobox() {
    setTimeout(function () {
      $('.bento-combobox-dropdown-button-icon').attr("tabindex","-1");
    }, 100);
  }
  skipFocusDropDown() {
    setTimeout(function () {
      $('.dropdown-item').removeAttr("tabindex");
    }, 100);
  }
}
