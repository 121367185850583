export class TkHistoryResponse {
  details: Detail[] = [];
  totalCount = 0;
}

export class Detail {
  period: any;
  lastName: string;
  firstName: string;
  tkId: string;
  fte: number;
  fteDuration: any;
  peerTitle: number | String;
  peerTitleDuration: any;
  firmTitle: string;
  peerPractice: number | String;
  peerPracticeDuration: any;
  department: any;
  section: any;
  udf: any;
  peerOffice: number | String;
  peerOfficeDuration: any;
  firmOffice: any;
  experienceYear: number;
  experienceYearDuration: any;
  gradCompYear: any;
  isSplitPracticeOverride: null | boolean = false;
  isSplitOfficeOverride: null | boolean = false;
  dataProcessing: DataProcessing = new DataProcessing();
}

export class DataProcessing {
  fte = false;
  fteDuration = false;
  peerTitle = false;
  peerTitleDuration = false;
  peerPractice = false;
  peerPracticeDuration = false;
  peerOffice = false;
  peerOfficeDuration = false;
  experienceYear = false;
  experienceYearDuration = false;
}
