<bento-skip-links></bento-skip-links>
<bento-alert [items]="alerts"></bento-alert>
<router-outlet></router-outlet>
<ng-template #sessionTimeoutModal let-c="close" let-d="dismiss">
  <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h2 id="modalViewRemove" class="modal-header h2">Warning</h2>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <p>
            You will be logged out shortly for security purposes. If you wish to remain logged in, please click Continue
            below.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer modal-common-action-btns-footer">
    <button type="button" class="btn btn-primary" (click)="c('confirm')">Continue</button>
  </div>
</ng-template>
