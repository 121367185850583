<div [bentoBusyLoader]="isLoading" *ngIf="isLoading; else elseBlock"></div>
<ng-template #elseBlock>
  <app-global-header></app-global-header>
  <main id="MainContent" tabindex="-1" class="Main Main--zeroState">
    <figure class="ZeroState">
      <app-svg-exclamation class="ZeroState-header"></app-svg-exclamation>
      <figcaption class="ZeroState-body">
        <h1 class="h3">You don't have access to the requested page.</h1>
        <div class="ZeroState-actions">
          <p>Contact your firm's admin to resolve this issue or go to <a [routerLink]="['/']">Home</a>.</p>
        </div>
      </figcaption>
    </figure>
  </main>
</ng-template>
