import {BentoAlertItemOptions} from '@bento/bento-ng';

export class Firmpeergroupstatus {
  isDefaultView: Boolean;
  currentStatus: string;
  selectedTab: string;
  selectedPeerGroup: any;
  alerts: BentoAlertItemOptions[];
  customPeerGroupMode: string;
  selectedPeerGroupFirms: PeerGroupFirms[];
  isShowSelectedFirms: Boolean;
  isShowAllFirms: Boolean;

  constructor() {
    this.isDefaultView = true;
    this.currentStatus = null;
    this.selectedTab = null;
    this.selectedPeerGroup = null;
    this.alerts = [];
    this.customPeerGroupMode = null;
    this.selectedPeerGroupFirms = [];
    this.isShowSelectedFirms = false;
    this.isShowAllFirms =false;

  }
}

export class FirmPeerGroupList {
  peerid: number;
  peerlabel: string;
  isGlobal: boolean;
  isApproved: boolean;
  isPending: boolean;
}

export class PeerGroupFirms {
  firmId: number;
  firmName: string;
  mainOfficeCountry: string;
  amLawRank: number;
  lawyers: number;
  offices: any;
  isFirmDisabled: any;
  firmDataValid:boolean;
  selected: boolean;
}

export class PeerGroupRequestBody {
  name: string;
  selectedFirmIds: number[];
}

export class SRPresetPeerGroupRequestBody {
  peerGroupId: number;
  peerGroupName: string;
  surveyYear: string;
  isGlobal: boolean;
  firmId: number;
  firmIds: number[];
}
export class SRCustomPeerGroupRequestBody {
  peerGroupId: number;
  peerGroupName: string;
  surveyYear: string;
  isGlobal: boolean;
  firmId: number;
  firmIds: string[];
}
