export default class PracticeFirmSettings {
  public descField: string;
  public codeField: string;
  public lookupTable: string;
  public fieldName: string;
  public practiceGroupDepartment = false;
  public practiceGroupSection = false;
  public practiceGroupOffice = false;
  public practiceGroupUserDefined = false;
  public defaultPracticeGroup;
  public udfFieldName: string;
  public constructor() {}
}
