export enum Role {
  // Firm Admin
  FAdmin = 'fadmin',
  // Thomson Reuters Admin
  TAdmin = 'tadmin',
  // Extract Admin
  EAdmin = 'eadmin',
  // General User
  GUser = 'guser',
  // No access User this user cannot access FI Features
  NOAccess = 'noaccess',
}

export enum DateFormat {
  mmddyyyy = 'mm-dd-yyyy',
  yyyymmdd = 'yyyy-mm-dd',
  mmddpostfixyyyy = 'mm-dd-postfix-yyyy',
  mmddyyyyWithSlash = 'MM/dd/yyyy',
  MMMMy = 'MMMM y',
}
export enum featureToggle {
  'data-manager' = 'DATA_MANAGER',
  'new-visualization' = 'NEW_VISUALIZATION',
  'visualizations' = 'VISUALIZATIONS',
  'rates-reports' = 'RATES-REPORTS',
}
