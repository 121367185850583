export default class TimeKeeperSettingModel {
  public standardWorkRateSource: string;
  public titleSource: string;
  public standardRateSource: string;
  public rateNumber: number;
  public sameSource: boolean;
  public gradYearSource: string;
  public gradYearName: string;
  public gradYearType: string;
  public partnerAnniversaryMonth: number;
  public compYearSource: string;
  public compYearName: string;
  public compYearType: string;
  public associateAnniversaryMonth: number;
  public associateAnniversaryYear: number;
  public currencySource: string;
  public userDefinedStandardRateSource: string;
  public aderantRateSource: string;

  public constructor() {}
}
