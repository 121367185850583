export class GlobalPeerGroupDetails {
  peerGroupName: string;
  autoRule: any;
  selectedfirms: Selectedfirm[];
  selectedLocales: string[];
}

export class Selectedfirm {
  id: number;
  name: string;
}
