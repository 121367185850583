<div *ngIf="isInvalidAction" class="modal-content">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h3 id="modalBulkMapping" class="modal-header h2">Bulk mapping</h3>
  <div class="modal-body">
    <p>
      You cannot bulk map the selected accounts at the same time. Please select only Profit & Loss accounts or only
      Balance Sheet accounts and try again.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="activeModal.dismiss('OK')">OK</button>
  </div>
</div>

<div *ngIf="!isInvalidAction" class="modal-content">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h3 id="modalBulkMapping" class="modal-header h2">Bulk mapping</h3>
  <form bfmForm name="bulkMappingform" [bentoBusyLoader]="isDataLoading">
    <div class="modal-body">
      <p>{{ message }}</p>
      <span class="label-required-summary">Required field</span>
      <div class="form-group">
        <label class="input-label-legal bento-label-required" for="{{ messageFrom.slice(0, -1) }}">{{
          messageFrom.slice(0, -1)
        }}</label>
        <select
          bentoSelect
          bfmField
          [bfmLabel]="messageFrom.slice(0, -1)"
          class="form-control"
          [errorContainer]="titleError"
          name="{{ messageFrom.slice(0, -1) }}"
          [(ngModel)]="selectedBulkMapping.code"
          required
        >
          <option *ngFor="let type of bulkMappingData" [value]="type.code">{{ type.description }}</option>
        </select>
      </div>
      <div class="document-type-ec">
        <bfm-error-container #titleError></bfm-error-container>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        (click)="saveMappings()"
        [disabled]="selectedBulkMapping.code == null"
      >
        Save
      </button>
      <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss('Cancel')">Cancel</button>
    </div>
  </form>
</div>
