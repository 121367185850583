export class BulkMapping {
  code: number;
  description: String;
  selected = false;
  id: number;
  name: number;
  type: String;
  constructor(code, description, type) {
    this.code = code;
    this.description = description;
    this.type = type;
  }
}
