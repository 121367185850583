import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ViewChildren,
  QueryList,
  Query,
  TemplateRef,
} from '@angular/core';
import {BentoComboboxColumn, BentoComboboxComponent, BentoComboboxOptions} from '@bento/bento-ng';
import {BehaviorSubject} from 'rxjs';
import {first} from 'rxjs/operators';
import {BulkMapping} from 'src/app/core/models/bulkMapping.model';
import {Detail} from 'src/app/core/models/glac-override.model';
import {DetailsComponent} from '../../../administrator/details/details.component';

@Component({
  selector: 'app-map-account-grid',
  templateUrl: './map-account-grid.component.html',
  styleUrls: ['./map-account-grid.component.scss'],
})
export class MapAccountGridComponent implements OnInit {
  @Input() selectedMappedOfficeName: string;
  @Input() invalidForm: any;
  @Input() glacDepartment: any[];
  @Input() glacOffice: any;
  @Input() glacMappedOffice: any;
  @Input() glacMappedOfficeName: [BulkMapping];
  @Input() headers: any;
  @Input() avaliableCols: Number;
  @Input() selectedAllocType: string;
  @Input() selectedGlAcOverride: any;

  @Input() selectedDetails: Detail[] = [];

  @Output() currentSelection = new EventEmitter();
  @ViewChildren('bentoComboBox') bentoComboBox: QueryList<BentoComboboxComponent>;
  selectedBentoComboBox: BentoComboboxComponent;
  dataEmitter: EventEmitter<any> = new EventEmitter();
  dataStream: BehaviorSubject<any>;
  isValidAction: boolean;
  selectionModel: Detail[] = [];
  header1: HTMLElement;
  header2: HTMLElement;

  comboboxOptions: BentoComboboxOptions = {
    searchable: true,
    columns: <BentoComboboxColumn[]>[
      // Column definition can be an object formatted as the following
      {
        name: 'code',
        width: '60px',
      },
      {
        name: 'label',
        width: '140px',
      },
      // Column definition can also be a string
    ],
    headerTranslation: {
      code: 'Id',
      label: 'Name',
    },
    labelFormatter: (row) => {
      return [row.code, row.label].join(', ');
    },
  };

  constructor() {}

  ngOnInit(): void {
    this.headers.sort((a, b) => {
      return this.compareObjects(a, b, 'index');
    });
    //this.dataEmitter.emit(this.glacDepartment);
    //this.dataStream = new BehaviorSubject(this.glacDepartment);
    this.addCode();
  }

  sortDepartment(key, order, selctedBentoCombo) {
    const glacDepartmentCopy = [...this.glacDepartment];
    if (key == 'code' && order == 'asc') {
      glacDepartmentCopy.sort((a, b) => a.code - b.code);
    } else if (key == 'code' && order == 'desc') {
      glacDepartmentCopy.sort((a, b) => b.code - a.code);
    } else if (key == 'department' && order == 'asc') {
      glacDepartmentCopy.sort((a, b) => ('' + a.label).localeCompare(b.label));
    } else if (key == 'department' && order == 'desc') {
      glacDepartmentCopy.sort((a, b) => ('' + b.label).localeCompare(a.label));
    }
    selctedBentoCombo._onDataUpdate(glacDepartmentCopy);
    //this.dataStream = new BehaviorSubject(this.glacDepartment);
  }
  ngAfterViewInit() {
    this.bentoComboBox.changes.subscribe((comp: QueryList<BentoComboboxComponent>) => {
      comp.forEach((selctedBentoCombo) => {
        const htmlEle = selctedBentoCombo._listContainer.nativeElement as HTMLElement;
        //const containerEle = htmlEle.children[0] as HTMLElement;
        setTimeout(() => {
          //const headerRowEle = containerEle.children[0] as HTMLElement;
          if (htmlEle) {
            const headerRowEle = htmlEle.getElementsByClassName('bui-bento-combobox-container-header')[0];
            if (headerRowEle) {
              const header1 = headerRowEle.children[0] as HTMLElement;
              const header2 = headerRowEle.children[1] as HTMLElement;
              if (header1) {
                header1.onclick = (e) => {
                  this.onClickHeader(e, header1, 'code', selctedBentoCombo);
                };
              }
              if (header2) {
                header2.onclick = (e) => {
                  this.onClickHeader(e, header2, 'department', selctedBentoCombo);
                };
              }
            }
          }
          // header1.addEventListener('click', (e) => {
          //   this.onClickHeader(e, header1, 'code', selctedBentoCombo);
          // });
          // header2.addEventListener('click', (e) => {
          //   this.onClickHeader(e, header2, 'department', selctedBentoCombo);
          // });
        }, 1000);
      });
    });
  }

  onClickHeader(e, headerObj, key, selctedBentoCombo: BentoComboboxComponent) {
    const iconUP = document.createElement('span');
    iconUP.classList.add('bento-icon-arrow-up');
    if (headerObj.children.length > 0) {
      if (headerObj.children[0].classList.contains('bento-icon-arrow-up')) {
        headerObj.children[0].classList.add('bento-icon-thin-arrow-down');
        headerObj.children[0].classList.remove('bento-icon-arrow-up');
        this.sortDepartment(key, 'desc', selctedBentoCombo);
      } else {
        headerObj.children[0].classList.remove('bento-icon-thin-arrow-down');
        headerObj.children[0].classList.add('bento-icon-arrow-up');
        this.sortDepartment(key, 'asc', selctedBentoCombo);
      }
    } else {
      headerObj.appendChild(iconUP);
      this.sortDepartment(key, 'asc', selctedBentoCombo);
    }
  }

  onComboboxReady(i: any) {
    this.dataStream = new BehaviorSubject(this.glacDepartment);
    for (let j = 0; j < this.glacDepartment.length; j++) {
      if (this.glacDepartment[j].code == this.selectedDetails[i].departmentCode) {
        this.selectedDetails[i].departmentObj = this.glacDepartment[j];
      }
    }
  }

  fetchHeaders() {
    return this.headers.filter((x) => x.active == 1);
  }

  compareObjects(object1, object2, key) {
    const obj1 = object1[key];
    const obj2 = object2[key];

    if (obj1 < obj2) {
      return -1;
    }
    if (obj1 > obj2) {
      return 1;
    }
    return 0;
  }

  saveData() {
    for (let i = 0; i < this.selectedDetails.length; i++) {
      if (
        this.selectedDetails[i] &&
        this.selectedDetails[i].departmentObj &&
        this.selectedDetails[i].departmentObj.code
      ) {
        this.selectedDetails[i].departmentCode = this.selectedDetails[i].departmentObj.code;
      }
      if (i == this.selectedDetails.length - 1) {
        this.currentSelection.emit(JSON.stringify(this.selectedDetails));
      }
    }
  }

  deleteCode(index) {
    this.selectedDetails.splice(index, 1);
    this.refreshValidAction();
    this.allocationValueChange();
  }

  addCode() {
    var option = new Detail();
    let pmOfcCode = this.glacMappedOfficeName.find((x) => x.description == this.selectedMappedOfficeName);
    if (this.selectedMappedOfficeName != '' && pmOfcCode) {
      option.mappedAccountId = pmOfcCode.code;
    }

    if (this.selectedAllocType == 'P') {
      option.amount = this.selectedDetails.length == 0 ? this.selectedGlAcOverride.override.accountTotalAmount : null;
    } else {
      option.amount = null;
    }
    this.selectedDetails.push(option);
    this.refreshValidAction();
  }

  isOptionAvailable(option) {
    var item = this.headers.filter((x) => x.active == 1 && x.name === option).length;
    return item == 1;
  }

  refreshValidAction() {
    this.isValidAction = this.selectedDetails.length > 1;
    this.saveData();
  }

  allocationValueChange() {
    // if (this.selectedAllocType == 'P') {
    //   const firstOverride = this.selectedDetails[0];
    //   let amount = 100;
    //   this.selectedDetails.forEach((element, index) => {
    //     if (index !== 0) {
    //       amount -= element.amount == null ? 0 : element.amount;
    //     }
    //   });
    //   firstOverride.amount = amount > 0 ? amount : 0;
    // }

    this.saveData();
  }
}
