<bento-alert [items]="alerts"></bento-alert>
<section class="Section" [bentoBusyLoader]="isGFSUpdated">
  <form name="gfs" #gfs="ngForm" class="Section-form Form" bfmForm *ngIf="isGetGeneralFirmSettings">
    <header class="Section-header">
      <h2 class="Section-heading">General firm settings</h2>
    </header>
    <div class="Section-body">
      <div class="form-group Form-input Form-input--medium">
        <label for="currentFiscalPeriod">Current fiscal period</label>
        <input
          oninput="this.value = this.value.replace(/[^0-9]/g, '');"
          bfmField
          bfmLabel="currentFiscalPeriod"
          id="currentFiscalPeriod"
          name="currentFiscalPeriod"
          type="text"
          class="form-control"
          maxlength="6"
          [(ngModel)]="generelSettings.currentFiscalPeriod"
          [disabled]="!isTAdmin"
        />
      </div>
      <div class="form-group Form-input Form-input--medium">
        <label for="currentFirmMonth">Current firm month</label>
        <input
          oninput="this.value = this.value.replace(/[^0-9]/g, '');"
          bfmField
          bfmLabel="currentFirmMonth"
          id="currentFirmMonth"
          name="currentFirmMonth"
          type="text"
          class="form-control"
          maxlength="6"
          [(ngModel)]="generelSettings.currentCalendarPeriod"
        />
      </div>
      <div class="form-group Form-input Form-input--medium">
        <label for="contigencyFilter">Contigency filter</label>
        <input
          bfmField
          bfmLabel="contigencyFilter"
          pattern=".*\S+.*"
          id="contigencyFilter"
          name="contigencyFilter"
          type="text"
          class="form-control"
          [(ngModel)]="generelSettings.contigencyFilter"
        />
      </div>
      <div class="form-group Form-input Form-input--medium">
        <label for="mattersFilter">Matters filter</label>
        <input
          bfmField
          bfmLabel="mattersFilter"
          id="mattersFilter"
          name="mattersFilter"
          type="text"
          class="form-control"
          [(ngModel)]="generelSettings.mattersFilter"
        />
      </div>
      <div class="form-group Form-input Form-input--medium">
        <label for="timekeeperFilter">Timekeeper filter</label>
        <input
          bfmField
          bfmLabel="timekeeperFilter"
          id="timekeeperFilter"
          name="timekeeperFilter"
          type="text"
          class="form-control"
          [(ngModel)]="generelSettings.timekeeperFilter"
          pattern=".*\S+.*"
        />
      </div>
      <div class="form-group Form-input Form-input--medium Form-checkbox">
        <bento-checkbox
          id="includeReversals"
          name="includeReversals"
          [(ngModel)]="generelSettings.includeReversals"
        ></bento-checkbox>
        <label for="includeReversals" class="checkbox-inline">Include reversals</label>
      </div>
      <div class="form-group Form-input Form-input--medium Form-checkbox" *ngIf="firmType != '3'">
        <bento-checkbox
          id="multiCurrency"
          name="multiCurrency"
          [(ngModel)]="generelSettings.multiCurrency"
        ></bento-checkbox>
        <label for="multiCurrency" class="checkbox-inline">Multi-currency</label>
      </div>
      <div class="form-group Form-input Form-input--medium Form-checkbox" *ngIf="firmType === '3'">
        <bento-checkbox
          id="usePremiumDiscount"
          name="usePremiumDiscount"
          [(ngModel)]="generelSettings.usePremiumDiscount"
        ></bento-checkbox>
        <label for="usePremiumDiscount" class="checkbox-inline">Use premium discount add-in?</label>
      </div>
    </div>
    <footer class="Section-footer">
      <div class="Actions">
        <div class="Actions-item">
          <button type="button" class="btn btn-outline-secondary" (click)="resetGFS()">
            <span class="btn-text">Cancel</span>
          </button>
        </div>
        <div class="Actions-item">
          <button type="submit" class="btn btn-primary" [disabled]="gfs.invalid" (click)="saveGFS()">
            <span class="btn-text">Save</span>
          </button>
        </div>
      </div>
    </footer>
  </form>
</section>
