import {AfterViewInit, Component, EventEmitter, Output} from '@angular/core';
import {BentoDataSubscribable} from '@bento/bento-ng/lib/data/data-subscribable.interface';
import {Observable} from 'rxjs';
//import {BentoDataSubscribable} from '../data/data-subscribable.interface';

/**
 * This Class is designed to be extended to stream data without constant detection cycle checks
 */
@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class BentoListSubscribable implements BentoDataSubscribable, AfterViewInit {
  /**
   * Main data
   */
  _data: any[] = [];
  /**
   * Main data subscription
   */
  _dataSubscription: any;

  /**
   * Event Emitter when this class is ready
   */
  @Output() ready = new EventEmitter();

  /**
   * Used when view is initialized
   */
  ngAfterViewInit() {
    setTimeout(() => {
      this.ready.emit();
    }, 100);
  }

  /**
   * Logic to control data
   */
  //_setDataObservable(observable: Observable<any>) {
  _setDataObservable(observable: any): any {
    // Reset the main data array
    this._data = [];

    if (this._dataSubscription) {
      // Un-subscribe the previous data stream subscription
      // and reset data before the new subscription starts
      this._dataSubscription.unsubscribe();
    }

    // Declare first initial data stream subscription
    // Fire interface callback
    this._onDataReload(this._data);

    if (observable) {
      // Subscribe the recurrent subscription
      this._subscribeDataUpdate(observable);
    }
  }

  /**
   * Subscribe to when data is on update
   */
  _subscribeDataUpdate(observable: Observable<any>) {
    this._dataSubscription = observable.subscribe((value) => {
      if (!value || !Array.isArray(value)) {
        // Invalid data type for `value`
        this._dataSubscription.unsubscribe();
        throw new Error('[dataObservable]: Invalid data type.  Must be [Array<any>]');
      }

      // Append the new data to the end of the
      // main data
      this._updateData(value);
      // Fire interface update callback
      this._onDataUpdate(this._data);
    });
  }

  /**
   * Method to update data
   */
  _updateData(data: any[]) {
    this._data = data;
  }

  /**
   * handle data updates
   */
  _onDataUpdate(data: any[]) {
    // To be overridden
  }

  /**
   * Callback to when data is reloaded
   */
  _onDataReload(data: any[]) {
    // to be overridden
  }
}
