import {Component, Input} from '@angular/core';
import {BentoAlertItemOptions, BentoComboboxOptions} from '@bento/bento-ng';
import {environment} from 'src/environments/environment';
import {BaseService} from 'src/app/core/services/base/base.service';
import {Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {GlobalService} from 'src/app/core/services/global/global.service';

@Component({
  selector: 'app-run-extract',
  templateUrl: './run-extract.component.html',
  styleUrls: ['./run-extract.component.scss'],
})
export class RunExtractComponent {
  @Input() firmId: number;
  dataStream: BehaviorSubject<any>;
  selectedPeriod: any;
  is3EUpdated = false;
  currentExtractOfFirm;
  isExtractRunningForFirm = false;
  alerts: BentoAlertItemOptions[] = [];
  comboboxOptions: BentoComboboxOptions = {
    searchable: false,
    labelFormatter: (row) => {
      return row.text;
    },
  };
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  disableRunExtract = true;
  dropDownItems = [];
  constructor(private service: BaseService, private router: Router, private globalservice: GlobalService) {}

  async ngOnInit() {
    this.is3EUpdated = true;
    let [fmsPeriod, fmsPeriodErr]: any[] = [];
    [fmsPeriod, fmsPeriodErr] = await this.getFMSCurrentPeriod();
    if (fmsPeriodErr) {
      this.alerts.push({
        type: 'warning',
        msg: 'Something went wrong, please try again.',
        closeable: true,
      });
    }
    await this.getPeriodList(fmsPeriod);
    await this.checkIfExtractIsRunning();
    this.is3EUpdated = false;
  }

  onChangeSelectPeriodField(selectedPeriod: any) {
    this.selectedPeriod = selectedPeriod;
  }

  runExtract() {
    let params = new HttpParams();
    params = params.set('fiscalPeriod', this.selectedPeriod.fiscalPeriod);
    params = params.set('calendarPeriod', this.selectedPeriod.calendarPeriod);
    let periodInput = {
      fiscalPeriod: parseInt(this.selectedPeriod.fiscalPeriod),
      calendarPeriod: parseInt(this.selectedPeriod.calendarPeriod),
    };
    this.service.get(environment.FIClientBaseEndpoint + 'v1/cloud/submit/firm/' + this.firmId + '?', params).subscribe(
      (result) => {
        let successMsg = 'Extract request submitted sucessfully.';
        this.disableRunExtract = true;
        this.alerts.push({
          type: 'success',
          msg: successMsg,
          timeout: 2500,
          closeable: true,
        });
      },
      (error) => {
        //throw new ValidationError('Error while calling Period Endpoint . ', error.message, 0, '', false);
        var err_message = error.error.message;
        if (err_message && err_message == '') err_message = error.message;
        else err_message = 'Something went wrong, please try again.';
        this.alerts.push({
          type: 'warning',
          msg: err_message,
          closeable: true,
        });
        this.is3EUpdated = false;
        this.disableRunExtract = true;
      }
    );
  }
  getPeriodList(fmsPeriod) {
    return this.service
      .get(environment.FIClientBaseEndpoint + 'v1/firm/' + this.firmId + '/period/', fmsPeriod)
      .toPromise()
      .then(
        (period) => {
          if (period != undefined && period.length != 0) {
            for (var i = 0; i < period.length; i++) {
              var result = period[i];
              let year = result.fiscalPeriod.substring(0, 4);
              let month = parseInt(result.fiscalPeriod.substring(result.fiscalPeriod.length - 2), 10) - 1;
              this.dropDownItems.push({
                fiscalPeriod: result.fiscalPeriod,
                calendarPeriod: result.calendarPeriod,
                default: result.default,
                text: this.months[month] + ' ' + year,
              });
              this.dataStream = new BehaviorSubject(this.dropDownItems);
              this.selectedPeriod = this.dropDownItems.find((element) => element.default == true);
              this.disableRunExtract = false;
              this.globalservice.skipFocusTwiceCombobox();
            }
          } else {
            /*throw new ValidationError(
                'Invalid period returned from Server. ',
                'Period got from Server is :' + result.period,
                0,
                '',
                false
              );*/
            var err_message = 'Invalid period returned from Server.' + ' Period got from Server is : ' + result.period;
            this.alerts.push({
              type: 'warning',
              msg: err_message,
              closeable: true,
            });
            this.disableRunExtract = true;
          }
        },
        (error) => {
          //throw new ValidationError('Error while calling Period Endpoint . ', error.message, 0, '', false);
          var err_message = error.error.message;
          this.alerts.push({
            type: 'warning',
            msg: err_message,
            closeable: true,
          });
          this.disableRunExtract = true;
        }
      );
  }
  openExtratJobage() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/extract-jobs/firms/' + this.firmId]));

    window.open(url, '_blank');
  }
  getFMSCurrentPeriod() {
    return this.service
      .get(environment.FIClientBaseEndpoint + 'v1/fmsperiod/' + this.firmId, '')
      .toPromise()
      .then((result) => [result, null])
      .catch((error) => Promise.resolve([null, error]));
  }
  checkIfExtractIsRunning() {
    return this.service
      .post(environment.FIClientBaseEndpoint + 'v1/audit/extract/search/' + this.firmId, {})
      .toPromise()
      .then(
        (result) => {
          if (result && result.length > 0) {
            this.currentExtractOfFirm = result[0];
            if (this.currentExtractOfFirm.jobStatus == 'In-Progress') this.isExtractRunningForFirm = true;
            else this.isExtractRunningForFirm = false;
          } else {
            this.isExtractRunningForFirm = false;
          }
        },
        (error) => {
          this.alerts.push({
            type: 'warning',
            msg: error.error,
            timeout: 2500,
            closeable: true,
          });
        }
      );
  }
}
