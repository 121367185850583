import {Inject, Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthService} from '../services/auth/auth.service';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {AuthService as Auth0Service} from '@auth0/auth0-angular';
import {DOCUMENT} from '@angular/common';
import {BaseService} from '../services/base/base.service';
import {ConnectionService} from '../services/connection/connection.service';
import {Router} from '@angular/router';
import {LoggingService} from '../services/app-logging/logging.service';
import jwtDecode from 'jwt-decode';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  private backendTokenInProgress = false;

  constructor(
    private authService: AuthService,
    public auth0: Auth0Service,
    @Inject(DOCUMENT) private doc: Document,
    private service: BaseService,
    private connection: ConnectionService,
    private router: Router,
    private loggingServices: LoggingService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.auth0.error$.subscribe((error) => {
      // Handle auth0 error here
      this.auth0.logout({returnTo: this.doc.location.origin, localOnly: true});
    });

    if (request.url.toString() === environment.FIAdminBaseEndpoint + 'v1/user/authenticate-ciam') {
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error && error.status === 401) {
            this.router.navigate(['usernotfound']);
            return throwError(error);
          } else if (error && error.status === 500) {
            this.router.navigate(['servererror']);
            return throwError(error);
          } else {
            this.router.navigate(['error', error.status]);
            return throwError(error);
          }
        })
      );
    } else {
      return next.handle(this.addAuthenticationToken(request)).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error && error.status === 401) {
            // 401 errors are most likely going to be because we have an expired token that we need to refresh.
            if (this.backendTokenInProgress) {
              // If backendTokenInProgress is true, we will wait until backendJWTToken has a non-null value
              // which means the new token is ready and we can retry the request again
              return this.authService.backendJWTToken.pipe(
                filter((result) => result !== null),
                take(1),
                switchMap(() => next.handle(this.addAuthenticationToken(request)))
              );
            } else {
              this.backendTokenInProgress = true;

              // Set the backendJWTToken to null so that subsequent API calls will wait until the new token has been retrieved
              this.authService.backendJWTToken.next(null);
              this.loggingServices.logTrace('Calling CIAM API, URL: ' + environment.appInsights.ciamUrl);
              return this.auth0.getAccessTokenSilently().pipe(
                switchMap((token: string) => {
                  this.authService.ciamToken.next(token);
                  const tokenObj = jwtDecode(token);
                  this.loggingServices.logTrace('Response from CIAM API', {
                    url: environment.appInsights.ciamUrl,
                    ciamToken: token,
                    userGuid: tokenObj['https://tr.com/euid'],
                  });
                  return this.service
                    .post(
                      environment.FIAdminBaseEndpoint + 'v1/user/authenticate-ciam',
                      null,
                      tokenObj['https://tr.com/euid']
                    )
                    .pipe(
                      switchMap((result: any) => {
                        const {jwt} = result;
                        this.loggingServices.logTrace('After calling authenticate-ciam', {
                          url: environment.appInsights.ciamUrl,
                          ciamToken: token,
                          serviceJwt: jwt,
                          userGuid: tokenObj['https://tr.com/euid'],
                        });
                        this.authService.backendJWTToken.next(jwt);
                        return next.handle(this.addAuthenticationToken(request));
                      }),
                      // When the call to authenticate-ciam completes we reset the backendTokenInProgress to false
                      // for the next time the token needs to be refreshed
                      finalize(() => (this.backendTokenInProgress = false))
                    );
                })
              );
            }
          } else {
            return throwError(error);
          }
        })
      );
    }
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    const jwtToken = this.authService.getBackendToken();
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
  }
}
