import {Directionality} from '@angular/cdk/bidi';
import {Component, EventEmitter, HostListener, ElementRef, Renderer2, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {NgbDropdown, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import ViewModel from 'src/app/core/models/view.model';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {Role} from 'src/app/shared/enums';
import {environment} from 'src/environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {PeercheckMessageHandlerService} from 'src/app/core/services/peercheck-message-handler/peercheck-message-handler.service';
import {ShareViewModelComponent} from 'src/app/core/components/peer-check/current-views/share-view-model/share-view-model.component';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
import {PeerCheckServiceService} from '../peer-check/peer-check-service.service';
declare let TRAAC: any;
import {GlobalService} from 'src/app/core/services/global/global.service';
@Component({
  selector: 'app-views',
  templateUrl: './views.component.html',
})
export class ViewsComponent implements OnInit {
  @Input() viewType: string;
  @Output() editCurrentViewAction = new EventEmitter();
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;
  dropdown: NgbDropdown;
  currentRecords: number;
  totalRecords: number;
  totalItem = 0;
  currentInfo: any = {
    page: 1,
    totalItems: this.totalItem,
    numItems: 10,
    infoText: '_START_ to _END_ of _MAX_ Users',
    infoPageText: '_PAGE_ of _PAGES_',
    goText: 'Go',
  };
  lastPage = this.currentInfo.page;
  page: any = 1;
  pageSize: any = 10;
  startIndex = 0;
  endIndex = 10;
  selectItems: number[] = [10, 20, 30, 40, 50];
  modalTitle = '';
  selectedView: ViewModel;
  showPPPError = false;
  userSelectedItems: any[];
  userSelectedText = '';
  viewsList: ViewModel[];
  isDataLoading = false;
  viewNameSortOrderAsc = true;
  createdDateSortOrderAsc: boolean | null = null;
  isFavoritesSorted = false;
  makeDefaultOptions: any = [];
  makeDefaultOptionsModel: string;
  alerts: BentoAlertItemOptions[] = [];
  modalAlerts: BentoAlertItemOptions[] = [];
  removeSharedViewAlerts: BentoAlertItemOptions[] = [];
  isQuickSightEnabled = false;
  sortingDropdownItems: {name: string; isDisabled: boolean}[];
  sortOrder = 'Z to A';

  constructor(
    private dir: Directionality,
    private modalService: NgbModal,
    private authService: AuthService,
    private service: BaseService,
    private route: ActivatedRoute,
    private _service: PeerCheckServiceService,
    private peercheckMessageHandlerService: PeercheckMessageHandlerService,
    private router: Router,
    private featureToggleService: FeatureToggleService,
    private globalservice: GlobalService,
    private elementRef: ElementRef, 
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
    this.featureToggleService.getFeatureValue('QUICKSIGHT').then((val) => (this.isQuickSightEnabled = val));
    /*************************************************************/
    this.getMyViewsByUserFirmId();
    this.removeSharedViewAlerts.push({
      type: 'warning',
      msg: "This action removes the view from all users it's shared with.",
      closeable: false,
    });
    this.sortingDropdownItems = [
      {name: this.sortOrder, isDisabled: false},
      {name: 'Favorites', isDisabled: false},
      {name: 'Created date', isDisabled: false},
    ];
  }

  @HostListener('document:keydown.tab', ['$event']) focusEventFindingsSection(
    event: KeyboardEvent
  ) {
      const div = this.elementRef.nativeElement.querySelector('#paginate-info-select');
      const span = this.elementRef.nativeElement.querySelector('#append-tooltip');
    if (div && span) {
      // Append the span to the div
      this.renderer.appendChild(div, span);
    }
  }

  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.firmID
      ? backendTokenClaims.userDDO.firmID
      : 0;
  }

  // getDefaultOrSelectedFirmId() should return the selected firmID OR firmID of logged in user.
  private get getDefaultOrSelectedFirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : parseInt(this.route.snapshot.paramMap.get('id'));
  }

  public get isFAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.FAdmin;
  }

  get bottomRight() {
    // Change sides of RTL for the dropdowns
    return this.dir.value === 'rtl' ? 'bottom-left' : 'bottom-right';
  }

  getMyViewsByUserFirmId(): void {
    this.isDataLoading = true;
    let url: string;
    if (this.viewType === 'myviews') {
      url = `${environment.FIAdminBaseEndpoint}v1/views/firm/${this.getfirmId}`;
    } else {
      url = `${environment.FIAdminBaseEndpoint}v1/views/firm/${this.getfirmId}/shared`;
    }
    this.service.get(url, '').subscribe(
      (result) => {
        this.isDataLoading = false;
        this.viewsList = result;
        this.totalRecords = this.viewsList.length;
        this.currentInfo.totalItems = this.viewsList.length;
        this.currentRecords = this.viewsList.length > this.pageSize ? this.pageSize : this.viewsList.length;
        this.viewNameSortOrderAsc = true;
        this.globalservice.addPaginationAdditionalInfo();
      },
      (error) => {
        this.isDataLoading = false;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong. Please try again.',
          closeable: true,
        });
      }
    );
  }

  checkDropDown(dropdown: any) {
    // Check which dropdown was clicked
    this.dropdown = this.dropdowns.find((x) => (x as any)._elementRef.nativeElement == dropdown);
  }

  onPageChanged(page) {
    if (page !== this.lastPage) {
      setTimeout(() => {
        this.page = page;
        this.startIndex = (this.page - 1) * this.pageSize;
        this.endIndex = this.page * this.pageSize;
        this.currentRecords =
          this.totalRecords > this.page * this.pageSize
            ? this.pageSize
            : this.totalRecords - (this.page - 1) * this.pageSize;
      });
    }
    this.lastPage = page;
  }

  onItemsPerPageChanged(itemsPerPage) {
    this.pageSize = itemsPerPage;
    if (this.page === 1) {
      this.startIndex = 0;
      this.endIndex = this.pageSize;
    } else {
      this.startIndex = (this.page - 1) * this.pageSize;
      this.endIndex = this.page * this.pageSize;
    }
    this.currentRecords = this.totalRecords > this.page * this.pageSize ? this.pageSize : this.totalRecords;
  }

  metricOnItemsSelected(v: any[]) {
    this.userSelectedItems = v;
    this.userSelectedText = v.length.toString() + ' user selected';
  }

  expandCollapseToggle(item: ViewModel) {
    if (!item.isExpand) {
      item.isExpand = !item.isExpand;
      item.isLoading = true;
      this.service
        .get(environment.FIAdminBaseEndpoint + 'v1/view/' + item.viewId + '/firm/' + this.getfirmId + '/details', '')
        .subscribe(
          (result) => {
            if (result) {
              if (result.metrics) item.metrics = this.getCommaSeparatedString(result.metrics, true);
              if (result.peerGroups) {
                item.peerGroups = result.peerGroups;
                item.peerGroupsString = this.getCommaSeparatedString(result.peerGroups, true);
              }
              if (result.practices) item.practices = this.getCommaSeparatedString(result.practices, true);
              if (result.offices) item.offices = this.getCommaSeparatedString(result.offices, true);
              if (result.titles) item.titles = this.getCommaSeparatedString(result.titles, true);
              item.experienceYear = '';
              if (result.associateExpYears) {
                item.experienceYear = this.getCommaSeparatedString(result.associateExpYears, true);
                if (
                  (result.srAssociateExpYears && result.srAssociateExpYears.length) ||
                  (result.partnerExpYears && result.partnerExpYears.length)
                ) {
                  item.experienceYear += ', ';
                }
              }
              if (result.srAssociateExpYears) {
                item.experienceYear += this.getCommaSeparatedString(result.srAssociateExpYears, true);
                if (result.partnerExpYears && result.partnerExpYears.length) {
                  item.experienceYear += ', ';
                }
              }
              if (result.partnerExpYears) {
                item.experienceYear += this.getCommaSeparatedString(result.partnerExpYears, true);
              }
              if (result.rollingFormat.id != 0 && result.rollingFormat.id != 9) {
                item.date = result.rollingFormat.name;
              } else if (result.rollingFormat.id == 0) {
                item.date =
                  result.rollingFormat.name +
                  ' (' +
                  this.formatDateString(result.viewBeginDate, result.viewEndDate) +
                  ')';
              } else if (result.rollingFormat.id == 9) {
                item.date = this.formatDateString(result.viewBeginDate, result.viewEndDate);
              }
              if (result.statisticSetting) item.statisticsType = result.statisticSetting;
              if (result.comparisionSetting) {
                if (result.comparisionSetting.toUpperCase() == 'YOY') {
                  item.comparisonType = 'Year over Year';
                } else item.comparisonType = result.comparisionSetting;
              }
              if (result.matterTypeSetting) item.matterType = result.matterTypeSetting;
              if (result.contractorsSetting) item.contractorsType = result.contractorsSetting;
              if (result.currencySetting) item.currencyType = result.currencySetting;

              item.isLoading = false;
            }
          },
          (error) => {
            item.isLoading = false;
            this.alerts.push({
              type: 'warning',
              msg: 'Something went wrong. Please try again.',
              closeable: true,
            });
          }
        );
    } else {
      item.isExpand = !item.isExpand;
    }
  }

  favoriteToggle(item: ViewModel) {
    if (this.isQuickSightEnabled) {
      TRAAC.track(TRAAC.keyValue('Favorite click', {email: this.authService.getUserEmail()}).build());
    }
    if (item.favorite) {
      this.service.put(environment.FIAdminBaseEndpoint + 'v1/view/' + item.viewId + '/unfavorite', '').subscribe(
        (result) => {
          item.favorite = !item.favorite;
        },
        (error) => {
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong. Please try again.',
            closeable: true,
          });
        }
      );
    } else {
      this.service.put(environment.FIAdminBaseEndpoint + 'v1/view/' + item.viewId + '/favorite', '').subscribe(
        (result) => {
          item.favorite = !item.favorite;
        },
        (error) => {
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong. Please try again.',
            closeable: true,
          });
        }
      );
    }
  }

  removeViewWithConfirmation(removeModalContent, item: ViewModel) {
    this.selectedView = item;
    this.modalService.open(removeModalContent, {ariaLabelledBy: 'modalViewRemove'}).result.then(
      (result: string) => {
        if (result === 'confirm') {
          if (this.isQuickSightEnabled) {
            TRAAC.track(TRAAC.keyValue('Remove click', {email: this.authService.getUserEmail()}).build());
          }
          this.removeView(item);
        }
      },
      (reason) => {}
    );
  }

  removeView(item: ViewModel) {
    this.isDataLoading = true;
    this.service.delete(environment.FIAdminBaseEndpoint + 'v1/view/', item.viewId).subscribe(
      (result) => {
        const removeIndex = this.viewsList
          .map(function (item) {
            return item.viewId;
          })
          .indexOf(item.viewId);
        this.viewsList.splice(removeIndex, 1);

        this.totalRecords = this.viewsList.length;
        this.currentInfo.totalItems = this.viewsList.length;
        const noPage = this.totalRecords / this.pageSize + 1;
        if (noPage === this.page) {
          this.onPageChanged(this.page - 1);
          this.currentInfo.page = this.page - 1;
        } else {
          this.currentRecords =
            this.totalRecords > this.page * this.pageSize
              ? this.pageSize
              : this.totalRecords - (this.page - 1) * this.pageSize;
        }

        this.isDataLoading = false;
      },
      (error) => {
        this.isDataLoading = false;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong. Please try again.',
          closeable: true,
        });
      }
    );
  }

  viewNameSort() {
    this.isFavoritesSorted = false;
    this.createdDateSortOrderAsc = null;
    if (this.viewNameSortOrderAsc === true) {
      // Descending
      this.sortOrder = 'A to Z';
      this.viewsList.sort((a, b) => (a.viewName.toLowerCase() > b.viewName.toLowerCase() ? -1 : 1));
      this.setDefaultViewOnTop();
      this.viewNameSortOrderAsc = false;
    } else {
      // Ascending
      this.sortOrder = 'Z to A';
      this.viewsList.sort((a, b) => (a.viewName.toLowerCase() > b.viewName.toLowerCase() ? 1 : -1));
      this.setDefaultViewOnTop();
      this.viewNameSortOrderAsc = true;
    }
    this.sortingDropdownItems.forEach((element) => {
      if (element.name == 'A to Z' || element.name == 'Z to A') {
        element.name = this.sortOrder;
      }
    });
  }

  favoritesSort() {
    this.viewNameSortOrderAsc = null;
    this.createdDateSortOrderAsc = null;
    if (this.isFavoritesSorted) {
      this.viewsList.sort((a, b) =>
        a.favorite === b.favorite ? (a.viewName.toLowerCase() > b.viewName.toLowerCase() ? 1 : -1) : a.favorite ? 1 : -1
      );
      this.setDefaultViewOnTop();
      this.isFavoritesSorted = false;
    } else {
      this.viewsList.sort((a, b) =>
        a.favorite === b.favorite ? (a.viewName.toLowerCase() > b.viewName.toLowerCase() ? 1 : -1) : a.favorite ? -1 : 1
      );
      this.setDefaultViewOnTop();
      this.isFavoritesSorted = true;
    }
  }

  createdDateSort() {
    this.viewNameSortOrderAsc = null;
    this.isFavoritesSorted = false;
    if (this.createdDateSortOrderAsc && this.createdDateSortOrderAsc === true) {
      // Descending
      this.viewsList.sort((a, b) => (a.viewTemplateCreatedDate > b.viewTemplateCreatedDate ? -1 : 1));
      this.setDefaultViewOnTop();
      this.createdDateSortOrderAsc = false;
    } else {
      // Ascending
      this.viewsList.sort((a, b) => (a.viewTemplateCreatedDate > b.viewTemplateCreatedDate ? 1 : -1));
      this.setDefaultViewOnTop();
      this.createdDateSortOrderAsc = true;
    }
  }

  setDefaultViewOnTop() {
    this.viewsList.sort((a, b) => {
      if (a.default === b.default) return 0;
      if (a.default === true) return -1;
      if (b.default === true) return 1;
    });
  }

  shareView(item: ViewModel) {
    this.showPPPError = false;
    this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/view/' + item.viewId + '/firm/' + this.getfirmId + '/details', '')
      .subscribe(
        (res) => {
          if (item && res.peerGroups) {
            for (let pg of res.peerGroups) {
              if (pg.isPpg) {
                this.showPPPError = true;
                break;
              }
            }
          }
          const modalRef = this.modalService.open(ShareViewModelComponent);
          modalRef.componentInstance.showPPPError = this.showPPPError;
          modalRef.componentInstance.viewTemplateId = item.viewTemplateId;
          modalRef.componentInstance.firmId = this.getDefaultOrSelectedFirmId;

          modalRef.result.then(
            (result) => {
              if (result == 'Success') {
                this.alerts.push({
                  type: 'success',
                  msg: 'View shared successfully.',
                  timeout: 2500,
                  closeable: true,
                });
                if (this.viewType === 'myviews') {
                  this.getMyViewsByUserFirmId();
                }
              } else {
                this.alerts.push({
                  type: 'warning',
                  msg: result,
                  closeable: true,
                });
              }
            },
            (reason) => {}
          );
        },
        (error) => {
          item.isLoading = false;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong. Please try again.',
            closeable: true,
          });
        }
      );
  }

  toggleMakeDefault(item: ViewModel) {
    if (this.isQuickSightEnabled) {
      TRAAC.track(TRAAC.keyValue('Default click', {email: this.authService.getUserEmail()}).build());
    }
    this.isDataLoading = true;
    if (item.default) {
      this.service.put(environment.FIAdminBaseEndpoint + 'v1/view/' + item.viewId + '/undodefault', '').subscribe(
        (result) => {
          item.default = !item.default;
          this.viewsList.sort((a, b) => (a.viewName.toLowerCase() > b.viewName.toLowerCase() ? 1 : -1));
          this.isDataLoading = false;
        },
        (error) => {
          this.isDataLoading = false;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong. Please try again.',
            closeable: true,
          });
        }
      );
    } else {
      this.service.put(environment.FIAdminBaseEndpoint + 'v1/view/' + item.viewId + '/default', '').subscribe(
        (result) => {
          this.viewsList.forEach((element) => {
            element.default = false;
          });
          item.default = true;
          this.setDefaultViewOnTop();
          this.isDataLoading = false;
        },
        (error) => {
          this.isDataLoading = false;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong. Please try again.',
            closeable: true,
          });
        }
      );
    }
  }

  editCurrentView(item) {
    if (this.viewType === 'myviews') {
      this.peercheckMessageHandlerService.setrequestFromSharedViews = false;
      this.peercheckMessageHandlerService.setSelectedViewData = item;
      this.editCurrentViewAction.emit(item);
    } else {
      this.peercheckMessageHandlerService.setrequestFromSharedViews = true;
      this.editCurrentViewAction.emit(item);
    }
  }

  handleCopyEdit(item) {
    if (this.isQuickSightEnabled) {
      TRAAC.track(TRAAC.keyValue('Edit/Copy click', {email: this.authService.getUserEmail()}).build());
    }
    if (!item && !item.viewId) {
      this.alerts.push({
        type: 'warning',
        msg: 'Something went wrong. Please try again.',
        closeable: true,
      });
    } else {
      this.peercheckMessageHandlerService.setIsCopyEditRequest = true;
      this.peercheckMessageHandlerService.setSelectedViewId = item.viewId;
      this.peercheckMessageHandlerService.setCreateViewUserSettings = null;
      if (this.viewType === 'myviews') {
        this.peercheckMessageHandlerService.setSelectedViewData = item;
      } else {
        this.peercheckMessageHandlerService.setViewDetails = null;
        this.peercheckMessageHandlerService.setrequestFromSharedViews = true;
      }
      this.router.navigate(['../create-view'], {relativeTo: this.route});
    }
  }

  formatDateString(viewBeginDate, viewEndDate) {
    let sDt = new Date(viewBeginDate);
    let eDt = new Date(viewEndDate);

    let startDate = ('0' + (sDt.getMonth() + 1)).toString().slice(-2) + '/' + sDt.getFullYear();
    let endDate = ('0' + (eDt.getMonth() + 1)).toString().slice(-2) + '/' + eDt.getFullYear();
    let dateString = startDate + ' through ' + endDate;
    return dateString;
  }

  getCommaSeparatedByStringArray(items: string[]) {
    let string = '';
    if (items && items.length > 0) {
      items.forEach((item, index) => {
        if (items.length === 1) {
          string = item;
        } else if (items.length === index + 1 && index > 1) {
          string = string.replace(/,\s*$/, '');
          string = string + ', and ' + item;
        } else {
          string = string + item + ', ';
        }
      });
      string = string.replace(/,\s*$/, '');
      return string;
    }
  }

  getCommaSeparatedString(inputArray: Array<{id: number; name: string}>, includeAnd: boolean) {
    let string = '';
    if (inputArray && inputArray.length > 0) {
      inputArray.forEach((item, index) => {
        if (inputArray.length === 1) {
          string = item.name;
        } else if (includeAnd && inputArray.length === index + 1 && index > 1) {
          string = string.replace(/,\s*$/, '');
          string = string + ', and ' + item.name;
        } else {
          string = string + item.name + ', ';
        }
      });
      string = string.replace(/,\s*$/, '');
      return string;
    }
  }
  dropDownOnSelectChange(name) {
    if (name == 'A to Z' || name == 'Z to A') {
      this.viewNameSort();
    } else if (name == 'Favorites') {
      this.favoritesSort();
    } else if (name == 'Created date') {
      this.createdDateSort();
    }
  }
}
