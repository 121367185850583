<div class="modal-content">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h3 id="modalSettings" class="modal-header h2">Rebuild History</h3>
  <div class="modal-body" [bentoBusyLoader]="isDataLoading">
    <fieldset class="Form-fieldset Form-fieldset--divider">
      <div class="form-check">
        <label for="selectedTimekeeper" class="form-check-label">Rebuild the history for selected Timekeepers</label>
        <div class="text-area-container">
          <textarea
            disabled="true"
            [(ngModel)]="rebuildHistoryModel.selectedRecordName"
            class="form-control"
            id="selectedTimekeeper"
            rows="10"
          >
          </textarea>
        </div>
      </div>
      <div class="fiscal-period-container">
        <app-fiscal-period
          [fiscalPeriodModel]="rebuildHistoryModel.fiscalPeriodModel"
          [fiscalDateRange]="rebuildHistoryModel.fiscalDateRange"
          (selectionChanged)="onFiscalPeriodChange($event)"
          (fiscalPeriodLoaded)="onFiscalPeriodChange($event)"
        ></app-fiscal-period>
      </div>

      <div class="checkbox-container">
        <label class="checkbox-inline zero-paddings checkbox-container checkbox-label" for="fte">
          <bento-checkbox name="fte" [(ngModel)]="rebuildHistoryModel.fte" ngDefaultControl id="fte"></bento-checkbox>
          FTE</label
        >
        <label class="checkbox-inline zero-paddings checkbox-container checkbox-label" for="title-ch">
          <bento-checkbox
            id="title-ch"
            name="title"
            ngDefaultControl
            [(ngModel)]="rebuildHistoryModel.title"
          ></bento-checkbox>
          Title</label
        >
        <label class="checkbox-inline zero-paddings checkbox-container checkbox-label" for="office-ch">
          <bento-checkbox
            name="office"
            [(ngModel)]="rebuildHistoryModel.office"
            ngDefaultControl
            id="office-ch"
          ></bento-checkbox>
          Office</label
        >

        <label class="checkbox-inline zero-paddings checkbox-container checkbox-label" for="practice">
          <bento-checkbox
            name="practice"
            [(ngModel)]="rebuildHistoryModel.practice"
            ngDefaultControl
            id="practice"
          ></bento-checkbox>
          Practice</label
        >
        <label class="checkbox-inline zero-paddings checkbox-container checkbox-label" for="experience-years">
          <bento-checkbox
            name="experience-years"
            [(ngModel)]="rebuildHistoryModel.experienceYears"
            ngDefaultControl
            id="experience-years"
          ></bento-checkbox>
          Experience Years</label
        >
      </div>
    </fieldset>
  </div>
  <div class="modal-footer modal-footer--custom">
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss('Cancel')">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="isDataLoading" (click)="onClickSave()">Save</button>
  </div>
</div>
