export class ExportViewRequestBody {
  detailedReport: any;
  monthlyReport: any;
  quarterReport: any;
  quid: any;
  type: string;
  startPeriod: string;
  endPeriod: string;
  yyyymm: number;
  currency: string;
  firmCurrency: string;
  defaultAccess: string;
  matterType: string;
  contractors: string;
  currencyType: string;
  log: boolean;
  peerGroups: any;
  statistic: string;
  dataType: string;
  public constructor() {}
}
