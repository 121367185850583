<div class="modal-content">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <i class="bento-icon-close-x" aria-hidden="true"></i>
    </button>
    <h3 id="modalShareView" class="modal-header h2">Edit Custom Peer Group Name</h3>
  <form name="renameCustomPeerGroup" #renameCustomPeerGroup="ngForm" class="Section-form Form" bfmForm >
    <div class="modal-body">
      <span class="bento-label-required">Please enter a new name for this Custom Peer Group:</span>
      <div class="form-group"  style="padding-top: 15px;">
        
        <input 
          type="text"
          name="custPGName"
          id="custPGName"
          class="form-control"
          bfmField
          bfmLabel="Custom peer group name"
          required
          aria-label="Custom peer group name"
          [(ngModel)]="peerGroupName"/>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="activeModal.close(peerGroupName)" [disabled] ='renameCustomPeerGroup.invalid || peerGroupName == peerGroup.pgname'  >Save</button>
      <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss('Cancel')">Cancel</button>
    </div> 
  </form>
  </div>
  