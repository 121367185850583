import {Component, HostBinding, OnInit} from '@angular/core';
import {BentoSideNavItem} from '@bento/bento-ng';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {VisualizationOptions} from 'src/app/core/models/create-chart.model';
import {PeercheckMessageHandlerService} from 'src/app/core/services/peercheck-message-handler/peercheck-message-handler.service';
import {PeerCheckServiceService} from '../peer-check/peer-check-service.service';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import $ from 'jquery';
declare let TRAAC: any;

@Component({
  selector: 'app-current-charts',
  templateUrl: './current-charts.component.html',
})
export class CurrentChartsComponent implements OnInit {
  isCurrentViewSelected: boolean;
  selectedViewData: any;
  selectedLabel: string;
  isChartList: boolean;
  showChartTemplate: boolean;
  items: BentoSideNavItem[];
  selectedChartData: any;
  isCurrentVisualizationSelected: boolean;
  isDataLoading: boolean;
  calledForScreen: string;
  isQuickSightEnabled = false;

  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }

  constructor(
    private service: BaseService,
    private _peercheckMessageService: PeercheckMessageHandlerService,
    private _peercheckService: PeerCheckServiceService,
    private featureToggleService: FeatureToggleService,
    private authService: AuthService
  ) {
    this.isDataLoading = true;
    this.isCurrentViewSelected = false;
    this.showChartTemplate = false;
    if (!this._peercheckMessageService.getSelectedVisualizationType) {
      this._peercheckMessageService.setSelectedVisualizationType = VisualizationOptions.MyVisualizations;
      this.isChartList = true;
    } else {
      if (this._peercheckMessageService.getSelectedVisualizationType == VisualizationOptions.MyVisualizations) {
        this.isChartList = true;
      } else {
        this.isChartList = false;
      }
    }
  }

  async ngOnInit() {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - VISUALIZATION_TEMPLATE ******/
    this.showChartTemplate = await this.getVisualizationTemplateFeatureFlag();
    this.setItems();
    this.selectedLabel = this.items[0].label;

    this.setAriaLabels();

    /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
    await this.featureToggleService
      .getFeatureValue('VISUALIZATION_QUICKSIGHT')
      .then((val) => (this.isQuickSightEnabled = val));
    /*************************************************/
  }

  setAriaLabels() {
    setTimeout(function () {
      //  $('#vt-nav ul').setAttribute('role', 'presentation')
      $('.GlobalLayout-item--small ul').each(function () {
        this.setAttribute('role', 'presentation');
      });
      $('.GlobalLayout-item--small bento-side-nav').each(function () {
        this.setAttribute('role', 'presentation');
      });
    }, 0);
  }

  onSelect(item: BentoSideNavItem) {
    this.selectedLabel = item.label;
  }

  showHideCurrentViewTabSideNav(label: string) {
    this.items.forEach((element) => {
      if (element.label === label) {
        element.data.isVisible = true;
      } else {
        element.data.isVisible = false;
      }
    });
  }
  getVisualizationTemplateFeatureFlag(): any {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - VISUALIZATION_TEMPLATE ******/
    /**Remove the call to feature API ***** */
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/VISUALIZATION_TEMPLATE', '')
      .toPromise()
      .then((result) => {
        return result['VISUALIZATION_TEMPLATE'];
      });
  }
  currentVisualizationActionHandler(item: any) {
    this.selectedChartData = item;
    this.calledForScreen = 'VisualizationDetails';
    this._peercheckService.resetVisualizationTemplateDetails();
    if (this.isChartList) {
      this._peercheckMessageService.setSelectedVisualizationType = VisualizationOptions.MyVisualizations;
    } else {
      this._peercheckMessageService.setSelectedVisualizationType = VisualizationOptions.VisualizationTemplates;
    }
    this.isCurrentVisualizationSelected = true;
  }

  backToCurrentVisualization() {
    this.isCurrentVisualizationSelected = false;
    this.selectedChartData = null;
    this.setItems();
  }

  setItems() {
    if (this.showChartTemplate) {
      this.items = [
        {
          label: 'My visualizations',
          selected: this.isChartList,
          action: () => {
            this.showHideCurrentViewTabSideNav('My visualizations');
            this.isChartList = true;
            if (this.isQuickSightEnabled) {
              TRAAC.track(TRAAC.keyValue('My Visualization click', {email: this.authService.getUserEmail()}).build());
            }
          },
          data: {isVisible: this.isChartList},
        },
        {
          label: 'Visualization templates',
          selected: !this.isChartList,
          action: () => {
            this.showHideCurrentViewTabSideNav('Visualization templates');
            this.isChartList = false;
            if (this.isQuickSightEnabled) {
              TRAAC.track(
                TRAAC.keyValue('Visualization templates click', {email: this.authService.getUserEmail()}).build()
              );
            }
          },
          data: {isVisible: !this.isChartList},
        },
      ];
    } else {
      this.items = [
        {
          label: 'My visualizations',
          selected: true,
          action: () => {
            this.showHideCurrentViewTabSideNav('My visualizations');
            this.isChartList = true;
            if (this.isQuickSightEnabled) {
              TRAAC.track(TRAAC.keyValue('My visualizations click', {email: this.authService.getUserEmail()}).build());
            }
          },
          data: {isVisible: true},
        },
      ];
    }
  }

  handleDataLoadingStateChanged(dataLoading) {
    this.isDataLoading = dataLoading;
  }
}
