export default class GeneralLedgerSettingModel {
  public glLedgerBook: string;
  public glDeptSource: string;
  public glOfficeSource: string;
  public compositeNaturalAccount: boolean;
  public hasGlobalRelease: boolean;
  public generateOfficeAllocation: boolean;
  public useProfCtr: boolean;
  public glSegmentAccountStart: number;
  public glSegmentAccountEnd: number;
  public glSegmentOfficeStart: number;
  public glSegmentOfficeEnd: number;
  public glSegmentDepartmentStart: number;
  public glSegmentDepartmentEnd: number;
  public glSegmentRealNaturalAccStart: number;
  public glSegmentRealNaturalAccEnd: number;
  public useGlrc1: boolean;
  public constructor() {}
}
