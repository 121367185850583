<bento-alert [items]="alerts"></bento-alert>
<section class="Section Section--views" [bentoBusyLoader]="isDataLoading">
  <form name="myViews" #myViews="ngForm" class="Section-form Form" bfmForm>
    <header class="Section-header">
      <h2 class="Section-heading mb-3">{{ viewType === 'myviews' ? 'My views' : 'Shared views' }}</h2>
      <div class="row align-items-center">
        <div class="col">
          <app-grid-record-details
            [currentRecords]="currentRecords"
            [totalRecords]="totalRecords"
          ></app-grid-record-details>
        </div>
        <div class="col">
          <app-sorting-dropdown
            [sortingDropdownItems]="sortingDropdownItems"
            [viewNameSortOrderAsc]="viewNameSortOrderAsc"
            [createdDateSortOrderAsc]="createdDateSortOrderAsc"
            (dropDownSelectChange)="dropDownOnSelectChange($event)"
            [isChartList]="true"
          ></app-sorting-dropdown>
        </div>
      </div>
    </header>
    <div class="Section-body">
      <ng-container *ngFor="let item of viewsList | slice : startIndex : endIndex; index as i">
        <div class="card Card">
          <div class="card-body">
            <div id="card-title-{{ i }}" class="card-title Card-title">
              <a href="javascript:void(0)" class="card-link Card-link cursor-pointer"  [attr.aria-label]="item.viewName" (click)="editCurrentView(item)">
                {{ item.viewName }}
              </a>
              <div class="Actions align-items-center justify-content-end">
                <div class="Actions-item" *ngIf="item.default">
                  <span class="badge badge-info">Default</span>
                </div>
                <div class="Actions-item Actions-item--indicator" *ngIf="item.favorite">
                  <i class="bento-icon-star" aria-hidden="true"></i>
                  Favorite
                </div>
                <div class="Actions-item">
                  <div ngbDropdown class="dropdown" [placement]="bottomRight" container="body">
                    <button
                      ngbDropdownToggle
                      id="menubutton-actions-{{ i }}"
                      class="btn btn-outline-secondary btn-icon btn-sm"
                      aria-haspopup="true"
                      attr.aria-controls="menu-actions-{{ i }}"
                      attr.aria-label="{{ item.viewName }} Actions"
                      ngbTooltip="Actions"
                    >
                      <i class="bento-icon-more-vert" aria-hidden="true"></i>
                    </button>
                    <div
                      ngbDropdownMenu
                      id="menu-actions-{{ i }}"
                      class="dropdown-menu"
                      role="menu"
                      attr.aria-labelledby="menubutton-actions-{{ i }}"
                    >
                      <button ngbDropdownItem class="dropdown-item" role="menuitem" (click)="favoriteToggle(item)">
                        Favorite
                      </button>
                      <button ngbDropdownItem class="dropdown-item" role="menuitem" (click)="shareView(item)">
                        Share
                      </button>
                      <button ngbDropdownItem class="dropdown-item" role="menuitem" (click)="handleCopyEdit(item)">
                        Edit/Copy
                      </button>
                      <button ngbDropdownItem class="dropdown-item" role="menuitem" (click)="toggleMakeDefault(item)">
                        {{ item.default ? 'Unmark as default' : 'Mark as default' }}
                      </button>
                      <button
                        ngbDropdownItem
                        class="dropdown-item"
                        role="menuitem"
                        (click)="removeViewWithConfirmation(removeModalContent, item)"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
                <div class="Actions-item">
                  <button
                    type="button"
                    class="btn btn-outline-secondary btn-icon btn-sm"
                    ngbTooltip="{{ item.isExpand ? 'Collapse' : 'Expand' }}"
                    [attr.aria-label]="item.isExpand ? item.viewName + ' Collapse' : item.viewName + ' Expand'"
                    [attr.aria-expanded]="item.isExpand ? 'true' : 'false'"
                    (click)="expandCollapseToggle(item)"
                  >
                    <i
                      [ngClass]="item.isExpand ? 'bento-icon-caret-up' : 'bento-icon-caret-down'"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="bento-desc-list bento-desc-list--inline PeerMeta">
              <div class="bento-desc-list-item">
                <span class="dtCss">Created:</span>
                <span class="ddCss">{{ item.viewTemplateCreatedDate | date : 'MM/dd/yyyy' }}</span>
              </div>
              <div class="bento-desc-list-item" *ngIf="item.sharedWith && item.sharedWith.length > 0">
                <span class="dtCss">Shared with:</span>
                <span class="ddCss">
                  {{ getCommaSeparatedByStringArray(item.sharedWith) }}
                </span>
              </div>
            </div>
          </div>
          <div class="Card-details" *ngIf="item.isExpand" [bentoBusyLoader]="item.isLoading" [size]="'sm'">
            <div class="Card-group">
              <div class="Card-item Card-item--large">
                <div attr.aria-labelledby="card-title-{{ i }}" class="bento-desc-list bento-desc-list--inline PeerMeta">
                  <div class="bento-desc-list-item" *ngIf="item.metrics">
                    <span class="dtCss">Metrics:</span>
                    <span class="ddCss">
                      {{ item.metrics }}
                    </span>
                  </div>
                  <div class="bento-desc-list-item" *ngIf="item.peerGroups">
                    <span class="dtCss">Peer groups:</span>
                    <span class="ddCss">
                      {{ item.peerGroupsString }}
                    </span>
                  </div>
                  <div class="bento-desc-list-item" *ngIf="item.date">
                    <span class="dtCss">Date:</span>
                    <span class="ddCss">{{ item.date }}</span>
                  </div>
                  <div class="bento-desc-list-item" *ngIf="item.practices">
                    <span class="dtCss">Practice group:</span>
                    <span class="ddCss">
                      {{ item.practices }}
                    </span>
                  </div>
                  <div class="bento-desc-list-item" *ngIf="item.offices">
                    <span class="dtCss">Offices:</span>
                    <span class="ddCss">
                      {{ item.offices }}
                    </span>
                  </div>
                  <div class="bento-desc-list-item" *ngIf="item.titles">
                    <span class="dtCss">Titles:</span>
                    <span class="ddCss">
                      {{ item.titles }}
                    </span>
                  </div>
                  <div class="bento-desc-list-item" *ngIf="item.experienceYear">
                    <span class="dtCss">Experience:</span>
                    <span class="ddCss">
                      {{ item.experienceYear }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="Card-item Card-item--small">
                <div class="bento-desc-list bento-desc-list--inline PeerMeta">
                  <div class="bento-desc-list-item" *ngIf="item.statisticsType">
                    <span class="dtCss">Statistics:</span>
                    <span class="ddCss">
                      {{ item.statisticsType | titlecase }}
                    </span>
                  </div>
                  <div class="bento-desc-list-item" *ngIf="item.comparisonType">
                    <span class="dtCss">Comparison:</span>
                    <span class="ddCss">
                      {{ item.comparisonType }}
                    </span>
                  </div>
                  <div class="bento-desc-list-item" *ngIf="item.matterType">
                    <span class="dtCss">Matter Type:</span>
                    <span class="ddCss">
                      {{ item.matterType | titlecase }}
                    </span>
                  </div>
                  <div class="bento-desc-list-item" *ngIf="item.contractorsType">
                    <span class="dtCss">Contractors:</span>
                    <span class="ddCss">
                      {{ item.contractorsType | titlecase }}
                    </span>
                  </div>
                  <div class="bento-desc-list-item" *ngIf="item.currencyType">
                    <span class="dtCss">Currency:</span>
                    <span class="ddCss">
                      {{ item.currencyType | titlecase }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <bento-pagination
      [(page)]="currentInfo.page"
      (pageChange)="onPageChanged($event)"
      [directionLinks]="true"
      [boundaryLinks]="true"
      [totalItems]="currentInfo.totalItems"
      [itemsArray]="selectItems"
      (itemsPerPageChange)="onItemsPerPageChanged($event)"
      [(itemsPerPage)]="currentInfo.numItems"
    >
    </bento-pagination>
    <span aria-live="polite" aria-atomic="true" id ="append-tooltip" class="sr-only">Page will update on selection</span>
  </form>
</section>

<ng-template #removeModalContent let-c="close" let-d="dismiss">
  <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h2 id="modalViewRemove" class="modal-header h2">Remove</h2>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <bento-alert
            [inline]="true"
            [items]="removeSharedViewAlerts"
            *ngIf="selectedView.sharedWith && selectedView.sharedWith.length > 0"
          ></bento-alert>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <p>Click remove to confirm {{ selectedView.viewName }} removal.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer modal-common-action-btns-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="c('cancel')">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="c('confirm')">Remove</button>
  </div>
</ng-template>
