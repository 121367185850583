export class Typeahead {
  code: number;
  description: string;
  constructor() {}
}

export class TKOverrideRequestBody {
  timekeeperDetails: TimekeeperDetail[] = [];
  fte: number | null = null;
  duration: number | null = null;
}

export class TKPmTitleOverrideRequestBody {
  timekeeperDetails: TimekeeperDetail[] = [];
  title: number | null = null;
  duration: number | null = null;
}

export class TKPmPracticeOverrideRequestBody {
  timekeeperDetails: TimekeeperDetail[] = [];
  practice: number | null = null;
  duration: number | null = null;
}

export class TKPmOfficeOverrideRequestBody {
  timekeeperDetails: TimekeeperDetail[] = [];
  office: number | null = null;
  duration: number | null = null;
}

export class TKExpyearOverrideRequestBody {
  timekeeperDetails: TimekeeperDetail[] = [];
  expYear: number | null = null;
  duration: number | null = null;
}

export class TimekeeperDetail {
  tkId: string | null = null;
  startPeriod: number | null = null;
}
