import {Component, OnInit} from '@angular/core';
import {ArrowNavigationService} from 'src/app/shared/arrow-navigation.service';
import {AxeToolCommonMethodsService} from 'src/app/core/services/axe-tool-common-methods/axe-tool-common-methods.service';
@Component({
  selector: 'app-reports-tab',
  templateUrl: './reports-tab.component.html',
  styleUrls: ['./reports-tab.component.scss'],
})
export class ReportsTabComponent implements OnInit {
  tabData: any[] = ['Rates reports', 'Reports in progress'];
  isMainBusyLoaderBusy = false;
  activeTab = 'Rates reports';

  constructor(private axeToolService: AxeToolCommonMethodsService,private arrowNavigationService: ArrowNavigationService) {}

  ngOnInit(): void {}

  ngAfterViewChecked() {
    this.axeToolService.removeAttributeToElement('[wj-part="focus"]', 'tabindex');
  }


  onTabChange(tabName) {
    if (!this.tabData.includes(tabName)) return;
    this.activeTab = tabName;
  }
  onKeyDown(event: KeyboardEvent) {
    this.arrowNavigationService.handleKeyDown(event);
  }
}
