import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BulkMapping} from 'src/app/core/models/bulkMapping.model';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
@Component({
  selector: 'app-bulk-mapping',
  templateUrl: './bulk-mapping.component.html',
})
export class BulkMappingComponent implements OnInit {
  @Input() public messageFrom: any;
  @Input() public bulkMappingData: [BulkMapping];
  @Input() public selectedRecord: Array<any>;
  @Input() public firmId: number;
  @Input() public isInvalidAction: boolean;
  selectedBulkMapping = {
    code: null,
  };
  errorMessage: any;
  alerts: BentoAlertItemOptions[] = [];
  isMainBusyLoaderBusy = false;
  isDataLoading: boolean;
  message: String;

  constructor(public activeModal: NgbActiveModal, private service: BaseService) {}

  ngOnInit(): void {
    if (this.messageFrom == 'Practices') {
      this.message = 'Select a practice to map the selected items to.';
    } else if (this.messageFrom == 'Titles') {
      this.message = 'Select a title name to map the selected items to.';
    } else if (this.messageFrom == 'Offices') {
      this.message = 'Select an office name to map the selected items to.';
    } else if (this.messageFrom == 'GL Offices') {
      this.message = 'Select a GL office name to map the selected items to.';
    } else if (this.messageFrom == 'GL Account Codes') {
      this.message = 'Select a GL account code to map the selected items to.';
    }
  }

  saveMappings() {
    this.isDataLoading = true;
    if (this.messageFrom == 'Practices') {
      this.saveMappingsFor('practice');
    } else if (this.messageFrom == 'Titles') {
      this.saveMappingsFor('title');
    } else if (this.messageFrom == 'Offices') {
      this.saveMappingsFor('office/pm');
    } else if (this.messageFrom == 'GL Offices') {
      this.saveMappingsFor('gloffice');
    } else if (this.messageFrom == 'GL Account Codes') {
      this.saveMappingsFor('glaccount');
    }
  }

  saveMappingsFor(option) {
    var body = [];
    for (let record of this.selectedRecord) {
      let option = {
        firmCode: String(record.firmCode),
        pmCode: Number(this.selectedBulkMapping.code),
      };
      body.push(option);
    }
    this.service.post(environment.FIClientBaseEndpoint + 'v1/mapping/' + option + '/' + this.firmId, body).subscribe(
      (result) => {
        this.isDataLoading = false;
        this.activeModal.close('Success');
      },
      (error) => {
        this.errorMessage = error.error;
        var msg = '';
        if (this.errorMessage.hasOwnProperty('message')) {
          msg = this.errorMessage.message;
        } else {
          msg = 'Something went wrong, please try again.';
        }
        this.isDataLoading = false;
        this.activeModal.close(msg);
      }
    );
  }
}
