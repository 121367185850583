import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-sorting-dropdown',
  templateUrl: './sorting-dropdown.component.html',
})
export class SortingDropdownComponent implements OnInit {
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;
  dropdown: NgbDropdown;
  constructor() {}
  @Input() viewNameSortOrderAsc;
  @Input() createdDateSortOrderAsc;
  @Input() sortingDropdownItems;
  @Input() isChartList;
  @Output() dropDownSelectChange = new EventEmitter<string>();

  ngOnInit(): void {}

  dropDownOnSelectChange(name) {
    this.dropDownSelectChange.emit(name);
  }
  checkDropDown(dropdown: any) {
    // Check which dropdown was clicked
    this.dropdown = this.dropdowns.find((x) => (x as any)._elementRef.nativeElement == dropdown);
  }
}
