import {Component, OnInit, ViewChild} from '@angular/core';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {GlobalPeerGroupList} from 'src/app/core/models/globalPeerGroupList.model';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-global-peer-groups',
  templateUrl: './global-peer-groups.component.html',
})
export class GlobalPeerGroupsComponent implements OnInit {
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  searchText = '';
  isDataLoading = false;
  globalPeerGroupsGridData: GlobalPeerGroupList[] = [];
  globalPeerGroupsData: GlobalPeerGroupList[] = [];
  isGlobalPeerGroupsFirmSelected = false;
  selectedGlobalPeerGroup: GlobalPeerGroupList;
  errorMessage: any;
  alerts: BentoAlertItemOptions[] = [];
  currentRecords: number = 0;
  totalRecords: number = 0;
  constructor(private service: BaseService) {}

  ngOnInit(): void {
    this.getGlobalPeerGroups();
    setTimeout(() => {
      let element = document.getElementById('Administrator');
      if (element) {
        element.classList.add('is-active');
      }
    }, 0);
    this.isGlobalPeerGroupsFirmSelected = false;
    this.globalPeerGroupsGridData = this.globalPeerGroupsData;
  }

  gridInitialized(flexGrid) {
    flexGrid.rows.defaultSize = 56;
    flexGrid.columnHeaders.rows.defaultSize = 40;
  }

  searchGlobalPeerGroups() {
    this.globalPeerGroupsGridData = this.globalPeerGroupsData.filter(
      (element) => element.peerLabel.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
    );
    this.currentRecords = this.globalPeerGroupsGridData.length;
  }

  editGlobalPeerGroups(item: GlobalPeerGroupList) {
    this.isGlobalPeerGroupsFirmSelected = true;
    this.selectedGlobalPeerGroup = item;
  }
  backToGlobalPeerGroups() {
    this.isGlobalPeerGroupsFirmSelected = false;
  }

  getGlobalPeerGroups() {
    this.isDataLoading = true;
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/peergroup/allglobal', '').subscribe(
      (result) => {
        this.isDataLoading = false;
        this.globalPeerGroupsGridData = result;
        this.globalPeerGroupsData = this.globalPeerGroupsGridData;
        this.currentRecords = this.globalPeerGroupsGridData.length;
        this.totalRecords = this.globalPeerGroupsGridData.length;
      },
      (error) => {
        this.isDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
}
