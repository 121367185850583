<bento-alert [items]="alerts"></bento-alert>
<main id="MainContent" tabindex="-1" class="Main" role="main">
  <header class="Main-header">
    <div class="row">
      <div class="col">
        <app-global-breadcrumbs></app-global-breadcrumbs>
      </div>
    </div>
    <h1 class="Main-heading">Variance detail</h1>
    <app-mapping-firms-details></app-mapping-firms-details>
  </header>
  <div class="Main-body">
    <wj-flex-grid
      #flexGrid
      [bentoBusyLoader]="isGetDataLoading"
      class="DataGrid"
      [itemsSource]="varianceData"
      [headersVisibility]="'Column'"
      [autoClipboard]="false"
      [isReadOnly]="true"
      [stickyHeaders]="true"
      [showMarquee]="true"
      [showSort]="true"
      [allowDragging]="false"
      [autoRowHeights]="true"
      (initialized)="gridInitialized(flexGrid)"
      (formatItem)="onFormatItem(flexGrid, $event)"
    >
      <wj-flex-grid-column *ngFor="let col of colArr" [binding]="col.binding" header="{{ col.header }}">
      </wj-flex-grid-column>
    </wj-flex-grid>
  </div>
  <footer class="Main-footer">
    <div class="Actions a11y justify-content-end">
      <button
        [attr.aria-disabled]="!displayAuthorizeButton ? 'true' : 'false' "
        [attr.aria-labelledby]="!displayAuthorizeButton ? 'aria-required-authorize' : null "
        (click)="!displayAuthorizeButton ? $event.stopPropagation() : onClickAuthorizeExtract() "
        type="button"
        class="btn btn-primary varianceAuthorizeExtractButton"
      >
        <span class="btn-text">Authorize</span>
      </button>
      <span class="sr-only" id="aria-required-authorize">Authorize</span>
    </div>
  </footer>
</main>
