<bento-alert [items]="alerts"></bento-alert>
<div class="mt-10">
  <div id="MainContent" tabindex="-1" class="Main" [bentoBusyLoader]="dataLoading">
    <div class="Section-header">
      <h2 class="Section-heading">Report information</h2>
    </div>

    <form name="ratesForm" #ratesForm="ngForm" bfmForm>
      <div class="flexClass">
        <div class="width-50">
          <div class="form-group col-sm-8 mt-10">
            <span class="req-note label-required-summary" role="alert" aria-live="polite">Required field</span>
          </div>
          <div class="form-group col-sm-8 mt-10">
            <label for="myFirmSelect">Firm</label>

            <bento-combobox
              id="myFirmSelect"
              name="myFirmSelect"
              [(ngModel)]="ratesReportModal.firm"
              (ngModelChange)="onFirmChange()"
              (openChange)="openChangeEvent($event)"
              [itemsObservable]="firmsArr"
              [options]="comboboxOptions"
              class="width-class"
              [rowTemplate]="myFirmSelect"
            ></bento-combobox>
            <ng-template #myFirmSelect let-$data="data" let-$options="options" let-$index="index">
              <div>
                {{ $data.row.name }}
              </div>
            </ng-template>
            <!-- <select
            [(ngModel)]="ratesReportModal.firm"
            (ngModelChange)="onFirmChange()"
            id="my-select"
            bentoSelect
            name="mySelect"
            class="form-control"
          >
            <option *ngFor="let firm of firmsArr" [value]="firm.id">{{ firm.name }}</option>
          </select> -->
          </div>
          <!-- <div class="form-group col-sm-8 mt-10">
            <label for="currency">Currency</label>
            <select
              [(ngModel)]="ratesReportModal.currency"
              id="currency"
              bentoSelect
              name="currency"
              class="form-control"
            >
              <option *ngFor="let currency of currencyArr" [value]="currency.currencyCode">
                {{ currency.currencyCode }}
              </option>
            </select>
          </div> -->

          <div class="form-group col-sm-8 mt-10">
            <label for="currency" id="currencyLabel" class="bento-label">Currency</label>
            <bento-combobox
              name="currency"
              id="currency"
              bfmField
              bfmLabel="Currency"
              [ngModel]="ratesReportModal.currency"
              [itemsObservable]="currencyStream"
              (ngModelChange)="currencySelected($event)"
              [options]="comboboxOptions1"
              [placeholder]="false"
              aria-labelledby="currencyLabel"
            ></bento-combobox>
          </div>
          <div class="form-group col-sm-8 mt-10">
            <label for="Peer-groups" d="Peer-groups-label">Peer groups</label>

            <app-bento-custom-multiselect-overlay
              name="Peer-groups"
              id="Peer-groups"
              class="FormMultiselect"
              [(ngModel)]="ratesReportModal.peerGroups"
              [selectButtonText]="'Select'"
              [overlayMaxHeight]="400"
              [editButtonText]="ratesReportData.selectedPeerGroupText"
              (ngModelChange)="onPeerGroupSelected($event)"
              [itemsObservable]="ratesReportData.peerGroupStream"
              [isRatesReport]="true"
              ngDefaultControl
              [listItemTemplate]="peerGroupTemplate"
              ngDefaultControl
              aria-labelledby="Peer-groups-label"
              [ariaLabelValue]="'Peer groups'"
            >
            </app-bento-custom-multiselect-overlay>
            <!-- Custom list item definition -->
            <ng-template #peerGroupTemplate let-item="item" let-index="index">
              <div>
                {{ item.name }}
              </div>
            </ng-template>
          </div>
          <div class="form-group col-sm-8 mt-10">
            <label for="email" class="bento-label-required">Email</label>
            <br />
            <input
              type="email"
              bfmField
              bfmLabel="Email"
              [pattern]="validatePattern"
              [validations]="validations.validatePattern"
              required
              class="form-control"
              name="email"
              [(ngModel)]="ratesReportModal.email"
            />
          </div>
          <div class="form-group col-sm-8 mt-10">
            <label for="Practice-groups" id="Practice-groups-label">Practice groups</label>

            <app-bento-custom-multiselect-overlay
              name="Practice-groups"
              id="Practice-groups"
              class="FormMultiselect"
              [(ngModel)]="ratesReportModal.practices"
              [selectButtonText]="'Select'"
              [overlayMaxHeight]="400"
              [editButtonText]="ratesReportData.selectedPracticeGroupText"
              (ngModelChange)="onPracticeGroupSelected($event)"
              [itemsObservable]="ratesReportData.practiceGroupStream"
              [isRatesReport]="true"
              ngDefaultControl
              [listItemTemplate]="practiceTemplate"
              ngDefaultControl
              aria-labelledby="Practice-groups-label"
              [ariaLabelValue]="'Practice groups'"
            >
            </app-bento-custom-multiselect-overlay>
            <!-- Custom list item definition -->
            <ng-template #practiceTemplate let-item="item" let-index="index">
              <div>
                {{ item.name }}
              </div>
            </ng-template>
          </div>
          <div class="form-group col-sm-8 mt-10">
            <label for="offices" id="offices-label">Offices</label>

            <app-bento-custom-multiselect-overlay
              name="offices"
              id="offices"
              class="FormMultiselect"
              [(ngModel)]="ratesReportModal.offices"
              [selectButtonText]="'Select'"
              [overlayMaxHeight]="400"
              [editButtonText]="ratesReportData.selectedOfficeText"
              (ngModelChange)="onOfficeSelected($event)"
              [itemsObservable]="ratesReportData.officeStream"
              [isRatesReport]="true"
              ngDefaultControl
              [listItemTemplate]="officeTemplate"
              ngDefaultControl
              aria-labelledby="offices-label"
              [ariaLabelValue]="'Offices'"
            >
            </app-bento-custom-multiselect-overlay>
            <!-- Custom list item definition -->
            <ng-template #officeTemplate let-item="item" let-index="index">
              <div>
                {{ item.name }}
              </div>
            </ng-template>
          </div>
        </div>
        <div class="width-50">
          <div class="form-group col-sm-8 mt-10">
            <fieldset>
              <legend>Date range</legend>
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  name="timeRangeType"
                  id="timeRangeType-rolling"
                  class="form-check-input"
                  [(ngModel)]="ratesReportModal.isRollingTimeRange"
                  [value]="true"
                  aria-labelledby="DateRangeRollingLabel"
                />
                <label class="form-check-label" for="timeRangeType-rolling" id="DateRangeRollingLabel">Rolling</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  name="timeRangeType"
                  id="timeRangeType-fixed"
                  class="form-check-input"
                  [(ngModel)]="ratesReportModal.isRollingTimeRange"
                  [value]="false"
                  aria-labelledby="DateRangeFixedLabel"
                />
                <label for="timeRangeType-fixed" class="form-check-label" id="DateRangeFixedLabel">Fixed</label>
              </div>
            </fieldset>
          </div>
          <div class="form-group col-sm-8 mt-10" *ngIf="ratesReportModal.isRollingTimeRange">
            <label for="timeRange" id="timeRangeLabel" class="bento-label-required">Time range</label>
            <bento-combobox
              name="timeRange"
              id="timeRange"
              bfmField
              bfmLabel="Time range"
              required
              [ngModel]="ratesReportModal.rollingTimeRangeSelectedItems"
              (ngModelChange)="timeRangeOnItemsSelected($event)"
              [itemsObservable]="rollingTimeRangeItemsStream"
              [options]="comboboxOptions"
              [placeholder]="false"
              [rowTemplate]="timeRangeTemplate"
              aria-labelledby="timeRangeLabel"
            ></bento-combobox>
            <ng-template #timeRangeTemplate let-$data="data" let-$options="options" let-$index="index">
              <div class="bento-list-item bento-combobox-item">
                {{ $data.row.name }}
              </div>
            </ng-template>
          </div>
          <div class="form-group col-sm-8 mt-10" *ngIf="!ratesReportModal.isRollingTimeRange">
            <div class="FormRange-group">
              <div class="FormRange-item FormRange-item--input">
                <label for="startDate" id="timeRangeStartLabel">
                  <div class="bento-label-required">Start date</div>
                  <div class="font-weight-normal"><span class="sr-only">Date format:</span>MM/YYYY</div>
                </label>
                <input
                  type="text"
                  name="startDate"
                  id="startDate"
                  class="form-control"
                  bfmField
                  bfmLabel="Start date"
                  required
                  [(ngModel)]="ratesReportModal.fixedTimeRange.startDateText"
                  (ngModelChange)="handleFixedDateRangeSelection()"
                  [pattern]="validatePattern1"
                  [validations]="validations.validatePattern1"
                  (fieldValidate)="isValidDate($event, 'StartDate', ratesForm)"
                  aria-labelledby="timeRangeStartLabel"
                />
              </div>
              <div class="FormRange-item FormRange-item--input">
                <label for="endDate" id="timeRangeEndLabel">
                  <div class="bento-label-required">End date</div>
                  <div class="font-weight-normal"><span class="sr-only">Date format:</span>MM/YYYY</div>
                </label>
                <input
                  type="text"
                  name="endDate"
                  #endDate
                  id="endDate"
                  bfmField
                  bfmLabel="End date"
                  required
                  [(ngModel)]="ratesReportModal.fixedTimeRange.endDateText"
                  (ngModelChange)="handleFixedDateRangeSelection()"
                  [pattern]="validatePattern1"
                  [validations]="validations.validatePattern1"
                  (fieldValidate)="isValidDate($event, 'EndDate', ratesForm)"
                  class="form-control"
                  aria-labelledby="timeRangeEndLabel"
                />
              </div>
            </div>
          </div>
          <div class="form-group col-sm-8 mt-10">
            <fieldset>
              <legend>Matter type</legend>
              <div>
                <label class="checkboxClass" for="billable">
                  <bento-checkbox
                    (ngModelChange)="onBillableSelected()"
                    [(ngModel)]="ratesReportModal.isBillable"
                    ngDefaultControl
                    id="billable"
                    name="billable"
                  >
                  </bento-checkbox>
                  <span class="font-style">Billable</span>
                </label>

                <label class="checkboxClass ml-10" for="contingent">
                  <bento-checkbox
                    [(ngModel)]="ratesReportModal.isContingent"
                    ngDefaultControl
                    id="contingent"
                    name="contingent"
                  >
                  </bento-checkbox>
                  <span class="font-style">Contingent</span>
                </label>
              </div>
            </fieldset>
          </div>

          <div class="form-group col-sm-8 mt-10">
            <fieldset>
              <legend>Contractor</legend>

              <div>
                <!-- <label class="checkboxClass" for="include">
              <bento-checkbox
                type="radio"
                [(ngModel)]="ratesReportModal.isInclude"
                ngDefaultControl
                id="include"
                name="include"
              >
              </bento-checkbox>
              <span class="font-style">Include</span>
            </label>

            <label class="checkboxClass ml-10" for="exclude">
              <bento-checkbox [(ngModel)]="ratesReportModal.isExclude" ngDefaultControl id="exclude" name="exclude">
              </bento-checkbox>
              <span class="font-style">Exclude</span>
            </label> -->

                <label class="checkboxClass" for="contractor-include">
                  <input
                    type="radio"
                    name="contractor"
                    id="contractor-include"
                    [(ngModel)]="ratesReportModal.isContractorInclude"
                    [value]="true"
                  />
                  <span class="font-style ml-10" for="contractor-include" id="includeLabel">Include</span>
                </label>

                <label class="checkboxClass" for="contractor-exclude">
                  <input
                    type="radio"
                    name="contractor"
                    id="contractor-exclude"
                    class="ml-10"
                    [(ngModel)]="ratesReportModal.isContractorInclude"
                    [value]="false"
                  />
                  <span class="font-style ml-10" for="contractor-exclude" id="excludeLabel">Exclude</span>
                </label>
              </div>
            </fieldset>
          </div>
          <div class="form-group col-sm-8 mt-10">
            <fieldset>
              <legend>Report type</legend>

              <div>
                <label class="checkboxClass" for="granular">
                  <bento-checkbox
                    [(ngModel)]="ratesReportModal.isGranular"
                    ngDefaultControl
                    [disabled]="!disableGranularReport"
                    id="granular"
                    name="granular"
                  >
                  </bento-checkbox>
                  <span class="font-style">Granular</span>
                </label>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </form>

    <footer class="Section-footer">
      <div class="Actions">
        <div class="Actions-item">
          <button type="button" (click)="onCancel()" class="btn btn-outline-secondary">
            <span class="btn-text">Cancel</span>
          </button>
        </div>

        <div class="Actions-item a11y">
          <button
            type="button"
            [attr.aria-disabled]="ratesForm.invalid ? 'true' : 'false' "
            (click)="ratesForm.invalid ? $event.stopPropagation() : onGetReport() "
            [attr.aria-labelledby]="ratesForm.invalid ? 'aria-required-get-report' : null "
            class="btn btn-primary Actions-button"
          >
            <span class="btn-text">Get Report</span>
          </button> 
        </div>
        <span class="sr-only" id="aria-required-get-report">Fill Required details, Get Report</span>
      </div>
    </footer>
  </div>
  <!--Main-->
</div>
