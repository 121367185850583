import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-svg-question',
  templateUrl: './svg-question.component.svg',
})
export class SvgQuestionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
