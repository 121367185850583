import {Injectable} from '@angular/core';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {Subject} from 'rxjs';
import {Firmpeergroupstatus, PeerGroupFirms} from '../../models/firmStaffingpeergroupstatus.model';

@Injectable({
  providedIn: 'root',
})
export class StaffingPeergroupService {
  private messageSource = new Subject<Firmpeergroupstatus>();
  currentMessage = this.messageSource.asObservable();
  private isDefaultView = true;
  private currentStatus: string = null;
  private selectedTab: string = null;
  private selectedPeerGroup: any = null;
  private alerts: BentoAlertItemOptions[] = [];
  private customPeerGroupMode: string = null;
  private selectedPeerGroupFirms: PeerGroupFirms[] = [];
  private isShowSelectedFirms = false;
  private isShowAllFirms = false;

  constructor() {}

  peerGroupStatusHandler() {
    let peerGroupStatus: Firmpeergroupstatus = new Firmpeergroupstatus();
    peerGroupStatus.isDefaultView = this.isDefaultView;
    peerGroupStatus.currentStatus = this.currentStatus;
    //peerGroupStatus.selectedTab = this.selectedTab;
    peerGroupStatus.selectedPeerGroup = this.selectedPeerGroup;
    peerGroupStatus.alerts = this.alerts;
    peerGroupStatus.customPeerGroupMode = this.customPeerGroupMode;
    peerGroupStatus.selectedPeerGroupFirms = this.selectedPeerGroupFirms;
    peerGroupStatus.isShowSelectedFirms = this.isShowSelectedFirms;
    peerGroupStatus.isShowAllFirms = this.isShowAllFirms;
    this.messageSource.next(peerGroupStatus);
  }

  public reset() {
    this.isDefaultView = true;
    this.currentStatus = null;
    this.selectedTab = null;
    this.selectedPeerGroup = null;
    this.alerts = [];
    this.customPeerGroupMode = null;
  }

  public set setCurrentStatus(status: string) {
    this.currentStatus = status;
  }

  public set setSelectedTab(selectedTab: string) {
    this.selectedTab = selectedTab;
  }
  public set setselectedPeerGroup(selectedPeerGroup) {
    this.selectedPeerGroup = selectedPeerGroup;
  }

  public set setAlerts(alerts) {
    this.alerts = alerts;
  }

  public set setCustomPeerGroupMode(customPeerGroupMode) {
    this.customPeerGroupMode = customPeerGroupMode;
  }

  public set setIsDefaultView(isDefault) {
    this.isDefaultView = isDefault;
  }

  public set setSelectedPeerGroupFirms(SelectedPeerGroupFirm) {
    this.selectedPeerGroupFirms = SelectedPeerGroupFirm;
  }

  public set setIsShowSelectedFirms(isShowSelectedFirms) {
    this.isShowSelectedFirms = isShowSelectedFirms;
  }
  public set setIsShowAllFirms(isShowAllFirms) {
    this.isShowAllFirms = isShowAllFirms;
  }

  public get getSelectedPeerGroupFirms() {
    return this.selectedPeerGroupFirms;
  }

  public get getCustomPeerGroupMode() {
    return this.customPeerGroupMode;
  }

  public get getAlerts() {
    return this.alerts;
  }

  public get getselectedPeerGroup() {
    return this.selectedPeerGroup;
  }

  public get getCurrentStatus() {
    return this.currentStatus;
  }

  public get getSelectedTab() {
    return this.selectedTab;
  }
  public get getIsShowSelectedFirms() {
    return this.isShowSelectedFirms;
  }
  public get getIsShowAllFirms() {
    return this.isShowAllFirms;
  }
}
