import {Component, Input, OnInit} from '@angular/core';
import {store, eventDispatcher} from 'src/app/core/store';
import FirmDetailModel from 'src/app/core/models/firmdetail.model';
import {ActionTypes} from 'src/app/core/store/actions';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {environment} from 'src/environments/environment';
import {BaseService} from 'src/app/core/services/base/base.service';
import {Router} from '@angular/router';

import {
  mainOfficeCountryValues,
  types,
  statusValues,
  currencyValues,
  peerGroupValues,
  performancePeerGroupValues,
  peerGroupsAllowesValues,
} from 'src/app/shared/constants';
import DataLagModel from 'src/app/core/models/dataLag.model';
import DefaultAccessModel from 'src/app/core/models/defaultAccess.model';
import {LocalStorageService} from 'src/app/core/services/local-storage/local-storage.service';
import {FormatdateService} from 'src/app/core/services/formatdate/formatdate.service';
import {DateFormat} from 'src/app/shared/enums';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  @Input() isNewFirm;
  @Input() isFAdmin;
  @Input() firmId;
  firmDetail: any = {};
  firmDetailModel: FirmDetailModel = new FirmDetailModel();
  resetFirmDetailModel: FirmDetailModel = new FirmDetailModel();
  alerts: BentoAlertItemOptions[] = [];
  metric: [];
  practice: [];
  title: [];
  dataLags: DataLagModel[];
  defaultAccess: DefaultAccessModel[];
  successMsg = '';
  errorMessage = '';
  isGetDataLoading = false;
  isInformationLoading = false;

  types = types;
  mainOfficeCountryValues: any;
  statusValues = statusValues;
  currencyValues: any;
  peerGroupValues = peerGroupValues;
  performancePeerGroupValues = performancePeerGroupValues;
  peerGroupsAllowesValues = peerGroupsAllowesValues;
  validatePattern = '^[a-zA-Z0-9-!@#$*()&_.,\\s+]*$';
  validations: any;
  expirationDate: any;
  resetExpirationDate: any;
  newzealandCurrency: boolean;
  isCustomerMigration = false;
  constructor(
    private service: BaseService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private formatdate: FormatdateService
  ) {
    this.isGetDataLoading = true;
    this.validations = {
      validatePattern: {
        pattern: 'Error:  Only alphanumeric characters allowed',
      },
      validateDatePattern: {
        pattern: 'Error:  Please enter date in mm-dd-yyyy format',
      },
    };
  }

  ngOnInit(): void {
    this.getNewzealandCurrencyFeatureFlag();

    if (!this.isNewFirm) {
      store.subscribe((state) => {
        const {firmDetail} = state;

        this.firmDetailModel = Object.assign({}, firmDetail);
        if (this.firmDetailModel.expirationDate) {
          this.expirationDate = this.formatdate.format(this.firmDetailModel.expirationDate, DateFormat.mmddyyyy);
        }
        this.resetFirmDetailModel = JSON.parse(JSON.stringify(firmDetail)); //Object.assign({},firmDetail);
        this.resetExpirationDate = this.expirationDate;
        this.localStorageService.set('mainOfficeCountry', this.firmDetailModel.defaultAccess);
        if (this.isFAdmin) this.getIsAdminFirmData();
      });
    } else {
      this.getFirmId();
    }
    setTimeout(() => {
      var element = document.getElementById('Administrator');
      if (element) {
        element.classList.add('is-active');
      }
    }, 0);

    if (this.isNewFirm) this.isGetDataLoading = true;
    else eventDispatcher.next({type: ActionTypes.GetFirmDetail});

    if (!this.isFAdmin) {
      this.getLocale();
      this.getDefaultAccess();
    }
    setTimeout(() => {
      this.getDataLag();
    }, 0);
    setTimeout(() => {
      this.getFmsType();
    }, 0);
  }
  getMainOfficeCountry() {
    if (this.newzealandCurrency) {
      this.service.get(environment.FIAdminBaseEndpoint + 'v1/country', '').subscribe(
        (result) => {
          this.mainOfficeCountryValues = result;
        },
        (error) => {
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
    } else {
      this.mainOfficeCountryValues = mainOfficeCountryValues;
    }
  }
  getCurrency() {
    if (this.newzealandCurrency) {
      this.service.get(environment.FIAdminBaseEndpoint + 'v1/currency', '').subscribe(
        (result) => {
          this.currencyValues = result;
        },
        (error) => {
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
    } else {
      this.currencyValues = currencyValues;
    }
  }
  getFirmId() {
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/firm/firmId', '').subscribe(
      (result) => {
        this.firmDetailModel.id = result.firmId;
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
  getLocale() {
    var model = ['Practice', 'Title', 'Metric'];
    this.service.post(environment.FIAdminBaseEndpoint + 'v1/locale', model).subscribe(
      (result) => {
        this.metric = result.metric;
        this.practice = result.practice;
        this.title = result.title;
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getDataLag() {
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/datalag', '').subscribe(
      (result) => {
        this.dataLags = result;
        this.dataLags.forEach((item) => {
          if (item && item.dataLagId && this.firmDetailModel && this.firmDetailModel.dataLag) {
            if (item.dataLagId.toString() == this.firmDetailModel.dataLag.toString()) {
              this.firmDetail.dataLag = item.dataLagName;
            }
          }
        });
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getDefaultAccess() {
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/allfaclocale', '').subscribe(
      (result) => {
        this.defaultAccess = result;
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  saveInformation() {
    this.alerts = [];
    this.isInformationLoading = true;
    if (this.expirationDate) {
      this.firmDetailModel.expirationDate = this.formatdate.format(this.expirationDate, DateFormat.yyyymmdd);
    } else {
      this.expirationDate = null;
      this.firmDetailModel.expirationDate = this.expirationDate;
    }
    if (this.isNewFirm) {
      this.service
        .post(environment.FIAdminBaseEndpoint + 'v1/firm/' + this.firmDetailModel.id, this.firmDetailModel)
        .subscribe(
          (result) => {
            this.resetFirmDetailModel = JSON.parse(JSON.stringify(this.firmDetailModel));
            this.resetExpirationDate = this.formatdate.format(this.expirationDate, DateFormat.mmddyyyy);
            this.localStorageService.set('mainOfficeCountry', this.firmDetailModel.defaultAccess);
            eventDispatcher.next({type: ActionTypes.SetFirmDetail, payload: this.firmDetailModel});
            this.isInformationLoading = false;
            this.successMsg = 'Data modified successfully.';
            this.alerts.push({
              type: 'success',
              msg: this.successMsg,
              timeout: 2500,
              closeable: true,
            });
            this.router.navigateByUrl('/administrator/firms/' + this.firmDetailModel.id);
          },
          (error) => {
            this.isInformationLoading = false;
            if (error.status == 400) {
              let message = '';
              if (error.error && error.error.messages) {
                error.error.messages.forEach((element) => {
                  message += '<div>' + element.fieldName + ': ' + element.message + '</div>';
                });
                this.errorMessage = message;
              } else {
                this.errorMessage = error.error.message;
              }
            } else {
              this.errorMessage = 'Something went wrong, please try again.';
            }
            this.alerts.push({
              type: 'warning',
              msg: this.errorMessage,
              closeable: true,
            });
          }
        );
    } else {
      this.service
        .put(environment.FIAdminBaseEndpoint + 'v1/firm/' + this.firmDetailModel.id, this.firmDetailModel)
        .subscribe(
          (result) => {
            this.resetFirmDetailModel = JSON.parse(JSON.stringify(this.firmDetailModel));
            this.resetExpirationDate = this.expirationDate;
            this.localStorageService.set('mainOfficeCountry', this.firmDetailModel.defaultAccess);
            eventDispatcher.next({type: ActionTypes.SetFirmDetail, payload: this.firmDetailModel});
            this.isInformationLoading = false;
            this.successMsg = 'Data modified successfully.';
            this.alerts.push({
              type: 'success',
              msg: this.successMsg,
              timeout: 2500,
              closeable: true,
            });
          },
          (error) => {
            this.isInformationLoading = false;
            if (error.status == 400) {
              let message = '';
              if (error.error && error.error.messages) {
                error.error.messages.forEach((element) => {
                  message += '<div>' + element.fieldName + ': ' + element.message + '</div>';
                });
                this.errorMessage = message;
              } else {
                this.errorMessage = error.error.message;
              }
            } else {
              this.errorMessage = 'Something went wrong, please try again.';
            }
            this.alerts.push({
              type: 'warning',
              msg: this.errorMessage,
              closeable: true,
            });
          }
        );
    }
  }

  resetInformation() {
    if (this.isNewFirm) {
      this.router.navigateByUrl('/administrator/firms');
    } else {
      this.firmDetailModel = JSON.parse(JSON.stringify(this.resetFirmDetailModel));
      this.expirationDate = this.resetExpirationDate;
      this.successMsg = 'Data reset successfully.';
      this.alerts.push({
        type: 'success',
        msg: this.successMsg,
        timeout: 2500,
        closeable: true,
      });
    }
  }

  getIsAdminFirmData() {
    this.firmDetail = {};
    if (this.firmDetailModel.id > 0) {
      this.firmDetail.status = this.statusValues.find((item) => item.value == this.firmDetailModel.status).label;
      this.firmDetail.currency =
        this.currencyValues &&
        this.currencyValues.find((item) => item.value == this.firmDetailModel && this.firmDetailModel.currency)?.label;
      this.firmDetail.peerGroupList = this.peerGroupValues.find(
        (item) => item.value == this.firmDetailModel.peerGroupList
      ).label;
      // eslint-disable-next-line max-len
      this.firmDetail.performancePeerGroupList = this.performancePeerGroupValues.find(
        (item) => item.value == this.firmDetailModel.performancePeerGroupList
      ).label;
      // eslint-disable-next-line max-len
      this.firmDetail.peerGroupsAllowed = this.peerGroupsAllowesValues.find(
        (item) => item.value == this.firmDetailModel.peerGroupsAllowed
      ).label;
    }
  }
  getNewzealandCurrencyFeatureFlag() {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - NEWZEALAND_CURRENCY ******/
    /**Remove the call to feature API ***** */
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/feature/NEWZEALAND_CURRENCY', '').subscribe((result) => {
      this.newzealandCurrency = result['NEWZEALAND_CURRENCY'];
      this.getMainOfficeCountry();
      this.getCurrency();
    });
  }
  getFmsType(){
    this.service.get(environment.FIClientBaseEndpoint + 'v1/firm/firmparameters/' + this.firmId, '').subscribe(
      (firmparametersResult) => {
       this.localStorageService.set('FIRM_TYPE', firmparametersResult.financialManagementSystem.financialManagementSystem);
      }
    );
  }
}
