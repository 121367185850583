<ngb-accordion class="bento-accordion" [closeOthers]="showOnePanel" [activeIds]="myActiveIds">
  <ngb-panel id="1">
    <ng-template ngbPanelTitle>
      <div class="d-flex justify-content-between align-items-center">
        <span>Results for</span>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
    </ng-template>
  </ngb-panel>
  <ngb-panel id="2">
    <ng-template ngbPanelTitle>
      <div class="d-flex justify-content-between align-items-center">
        <span>Peer groups</span>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
    </ng-template>
  </ngb-panel>
  <ngb-panel id="3" title="Practice groups">
    <ng-template ngbPanelContent>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
    </ng-template>
  </ngb-panel>
  <ngb-panel id="4">
    <ng-template ngbPanelTitle>
      <div class="d-flex justify-content-between align-items-center">
        <span>Offices</span>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
    </ng-template>
  </ngb-panel>
</ngb-accordion>
