import {Component, OnInit, Input, HostBinding, ViewChild} from '@angular/core';
import {eventDispatcher, store} from 'src/app/core/store';
import {ActionTypes} from 'src/app/core/store/actions';
import FirmBasicsModel from '../../../models/firm-basics.model';
import {PeerCheckServiceService} from '../peer-check/peer-check-service.service';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {ActivatedRoute} from '@angular/router';
import {PivotResponseData} from 'src/app/core/models/pivotResponseData.model';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {v4 as uuidv4} from 'uuid';

@Component({
  selector: 'app-pivot-table-view',
  templateUrl: './pivot-table-view.component.html',
  styleUrls: ['./pivot-table-view.component.scss'],
  providers: [PeerCheckServiceService],
})
export class PivotTableViewComponent implements OnInit {
  @Input() metricId: number;
  @Input() metricBasicData: any;
  @Input() peerGroupItems: any;
  @Input() pivotIndex: any;
  @Input() selectedFirmId: any;
  @Input() frozenColumnCount = 0;
  @Input() userSettingsModel: any;
  @Input() yoyHeader: any;
  @Input() isTAdmin: boolean = false;
  @Input() isCANFirm: boolean = false;
 

  statistics = ['Mean', 'Median'];
  statistic = 'Mean';
  tabData: any[] = ['Practice Groups', 'Offices'];
  activeTab = 'Practice Groups';
  isDataLoading = false;
  firmBasicData = new FirmBasicsModel();
  firmRules = [];
  quartile = true;
  showStatistic = false;
  metricData: any;
  highLowPercentilesCols = [];
  requestBody: any;
  pivotData = new PivotResponseData();
  isServiceCalled = false;
  alerts: BentoAlertItemOptions[] = [];
  errorMessage = '';
  showTab = false;
  peerGroupSelectedItems: any;
  cardIndex: string;
  isGetBasicAPICalled = false;
  isValueInitialized = false;
  practiceData: any;
  isPivotDataLoaded = false;
  officeData: any;
  officeGroupData: any;
  titleData: any;
  titleGroupData: any;
  allLawyersData: any;
  allPartnersData: any;
  allTimekeepersData: any;
  selectedOffice: any;

  constructor(
    public _service: PeerCheckServiceService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private service: BaseService
  ) {
    store.subscribe((state) => {
      const {pivotRequestBody} = state;
      if (!this.requestBody && pivotRequestBody) {
        this.requestBody = pivotRequestBody;
        if (this.requestBody && this.isServiceCalled == false) this.getdata();
      }
    });
  }

  async ngOnInit() {
    this.getBasicDetails();
  }

  async getBasicDetails() {
    this.isDataLoading = true;
    //Service will return getBasic details and firm level rules
    this.firmBasicData = await this._service.getFirmBasicsData(this.getfirmId);
    this.initializeValues();
    this.isGetBasicAPICalled = true;
  }

  initializeValues() {
    this.metricData = this.firmBasicData.metrics.filter((item) => item.metricId == this.metricId)[0];
    this.firmRules = this.firmBasicData.rules;
    this.statistic = this.metricBasicData.defaultCol;
    this.applyFirmRules();
    this.isDataLoading = false;
    this.peerGroupSelectedItems = this.peerGroupItems;
    this.cardIndex = this.pivotIndex;
    this.isValueInitialized = true;
    eventDispatcher.next({type: ActionTypes.SetStatistic, payload: this.statistic});
  }

  private get getfirmId(): number {
    if (this.selectedFirmId) return this.selectedFirmId;
    else {
      const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
      return backendTokenClaims.userDDO && !this.route.snapshot.params.id
        ? backendTokenClaims.userDDO.firmID
        : parseInt(this.route.snapshot.paramMap.get('id'));
    }
  }

  applyFirmRules() {
    let rules = [];
    this.firmRules.forEach((element) => {
      rules.push(element.name);
    });
    if (this.metricBasicData.mean && this.metricBasicData.median) this.showStatistic = true;

    if (this.firmBasicData.firmCurrency == 'GBP') this.tabData.push('Fee Earners');
    else if (this.metricData.hasTitles == true) {
      this.tabData.push('Timekeepers');
    }
    this.showTab = true;

    if (rules.includes('HighLowPercentiles') && this.metricBasicData.hilo == true) {
      let rule = this.firmRules.filter((item) => item.name == 'HighLowPercentiles')[0];
      this.highLowPercentilesCols.push(rule.value1);
      this.highLowPercentilesCols.push(rule.value2);
    }
  }

  onChangeStatistic(event) {
    eventDispatcher.next({type: ActionTypes.SetStatistic, payload: event.target.value});
  }

  onTabChange(tabName) {
    if (!this.tabData.includes(tabName)) return;
    this.activeTab = tabName;
  }
  async getdata() {
    this.isDataLoading = true;
    this.isServiceCalled = true;
    let result = await this.getPivotData();
    if (result) {
      if (result['practiceGroup']) this.practiceData = result['practiceGroup'];
      if (result['office']) this.officeData = result['office'];
      if (result['officeGroup']) this.officeGroupData = result['officeGroup'];
      if (result['title']) this.titleData = result['title'];
      if (result['titleGroup']) this.titleGroupData = result['titleGroup'];
      if (result['allLawyers']) this.allLawyersData = result['allLawyers'];
      if (result['allPartners']) this.allPartnersData = result['allPartners'];
      if (result['allTimekeepers']) this.allTimekeepersData = result['allTimekeepers'];
    }

    this.isPivotDataLoaded = true;
    this.isDataLoading = false;
  }

  getPivotData(): any {
    this.isServiceCalled = true;
    this.requestBody[this.pivotIndex].requestBodyPractice.metrics = [this.metricId];
    this.requestBody[this.pivotIndex].requestBodyPractice.groupBy = ['practice'];
    this.requestBody[this.pivotIndex].requestBodyPractice.quid = uuidv4();

    let requestBodyForTitle = JSON.parse(JSON.stringify(this.requestBody[this.pivotIndex].requestBodyTitle));
    requestBodyForTitle['groupBy'] = ['title'];
    requestBodyForTitle['metrics'] = [this.metricId];
    requestBodyForTitle['quid'] = uuidv4();

    let requestBodyForTitleGroup = JSON.parse(JSON.stringify(this.requestBody[this.pivotIndex].requestBodyTitle));
    requestBodyForTitleGroup['groupBy'] = ['title', 'experience_years'];
    requestBodyForTitleGroup['quid'] = uuidv4();

    let requestBodyForAllLawyers = JSON.parse(JSON.stringify(this.requestBody[this.pivotIndex].requestBodyTitle));
    requestBodyForAllLawyers['groupBy'] = [];
    requestBodyForAllLawyers['superTitle'] = 'lawyer';
    requestBodyForAllLawyers['quid'] = uuidv4();

    let requestBodyForAllPartners = JSON.parse(JSON.stringify(this.requestBody[this.pivotIndex].requestBodyTitle));
    requestBodyForAllPartners['groupBy'] = [];
    requestBodyForAllPartners['superTitle'] = 'partner';
    if (requestBodyForAllPartners.titles.length == 0) delete requestBodyForAllPartners.titles;
    requestBodyForAllPartners['quid'] = uuidv4();

    let requestBodyForAllTimekeepers = JSON.parse(JSON.stringify(this.requestBody[this.pivotIndex].requestBodyTitle));
    requestBodyForAllTimekeepers['groupBy'] = [];
    requestBodyForAllTimekeepers['quid'] = uuidv4();

    let serviceRequests = [];

    //API for practice groups
    let practiceGroup = this.service
      .post(
        environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getfirmId,
        this.requestBody[this.pivotIndex].requestBodyPractice
      )
      .toPromise()
      .catch((err) => {
        return {err};
      });

    serviceRequests.push(practiceGroup);

    //API for offices
    this.requestBody[this.pivotIndex].requestBodyOffice.metrics = [this.metricId];
    this.requestBody[this.pivotIndex].requestBodyOffice.groupBy = ['office'];
    this.requestBody[this.pivotIndex].requestBodyOffice.quid = uuidv4();

    this.selectedOffice = this.requestBody[this.pivotIndex].requestBodyOffice.offices;

    let office = this.service
      .post(
        environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getfirmId,
        this.requestBody[this.pivotIndex].requestBodyOffice
      )
      .toPromise()
      .catch((err) => {
        return {err};
      });

    serviceRequests.push(office);

    // API for OfficeGroups
    if (this.requestBody[this.pivotIndex].officeGroups && this.requestBody[this.pivotIndex].officeGroups.length) {
      this.requestBody[this.pivotIndex].requestBodyOfficeGroup.metrics = [this.metricId];
      delete this.requestBody[this.pivotIndex].requestBodyOfficeGroup.groupBy;
      this.requestBody[this.pivotIndex].requestBodyOfficeGroup.officeGroups =
        this.requestBody[this.pivotIndex].officeGroups;
      this.requestBody[this.pivotIndex].requestBodyOfficeGroup.quid = uuidv4();

      let officeGroup = this.service
        .post(
          environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getfirmId,
          this.requestBody[this.pivotIndex].requestBodyOfficeGroup
        )
        .toPromise()
        .catch((err) => {
          return {err};
        });

      serviceRequests.push(officeGroup);
    }

    // API for titles
    let title = this.service
      .post(environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getfirmId, requestBodyForTitle)
      .toPromise()
      .catch((err) => {
        return {err};
      });
    serviceRequests.push(title);

    let titleGroup = this.service
      .post(environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getfirmId, requestBodyForTitleGroup)
      .toPromise()
      .catch((err) => {
        return {err};
      });

    serviceRequests.push(titleGroup);

    let allLawyers = this.service
      .post(environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getfirmId, requestBodyForAllLawyers)
      .toPromise()
      .catch((err) => {
        return {err};
      });

    serviceRequests.push(allLawyers);

    let allPartners = this.service
      .post(environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getfirmId, requestBodyForAllPartners)
      .toPromise()
      .catch((err) => {
        return {err};
      });

    serviceRequests.push(allPartners);

    let allTimekeepers = this.service
      .post(environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getfirmId, requestBodyForAllTimekeepers)
      .toPromise()
      .catch((err) => {
        return {err};
      });

    serviceRequests.push(allTimekeepers);

    return Promise.all(serviceRequests).then(
      (serviceResponse) => {
        let results = {};
        let resp_PracticeGroup = serviceResponse.find((item) => {
          if (item && item[0]) {
            return item[0].quid == this.requestBody[this.pivotIndex].requestBodyPractice.quid;
          }
        });
        let resp_Office = serviceResponse.find((item) => {
          if (item && item[0]) {
            return item[0].quid == this.requestBody[this.pivotIndex].requestBodyOffice.quid;
          }
        });
        let resp_OfficeGroup = serviceResponse.find((item) => {
          if (item && item[0]) {
            return item[0].quid == this.requestBody[this.pivotIndex].requestBodyOfficeGroup.quid;
          }
        });
        let resp_Title = serviceResponse.find((item) => {
          if (item && item[0]) {
            return item[0].quid == requestBodyForTitle.quid;
          }
        });
        let resp_TitleGroup = serviceResponse.find((item) => {
          if (item && item[0]) {
            return item[0].quid == requestBodyForTitleGroup.quid;
          }
        });
        let resp_AllLawyers = serviceResponse.find((item) => {
          if (item && item[0]) {
            return item[0].quid == requestBodyForAllLawyers.quid;
          }
        });
        let resp_AllPartners = serviceResponse.find((item) => {
          if (item && item[0]) {
            return item[0].quid == requestBodyForAllPartners.quid;
          }
        });
        let resp_AllTimekeepers = serviceResponse.find((item) => {
          if (item && item[0]) {
            return item[0].quid == requestBodyForAllTimekeepers.quid;
          }
        });
        if (resp_PracticeGroup) results['practiceGroup'] = resp_PracticeGroup[0];
        if (resp_Office) results['office'] = resp_Office[0];
        if (resp_OfficeGroup) results['officeGroup'] = resp_OfficeGroup;
        if (resp_Title) results['title'] = resp_Title[0];
        if (resp_TitleGroup) results['titleGroup'] = resp_TitleGroup[0];
        if (resp_AllLawyers) results['allLawyers'] = resp_AllLawyers[0];
        if (resp_AllPartners) results['allPartners'] = resp_AllPartners[0];
        if (resp_AllTimekeepers) results['allTimekeepers'] = resp_AllTimekeepers[0];

        return results;
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });

        this.isDataLoading = false;
      }
    );
  }
}
