<div class="GlobalLayout admin-configurations" >
  <bento-alert [items]="alerts"></bento-alert>
  <!-- <form class="FilterPanel-control--Timekeeper" name="customizeSegment" #customizeSegment="ngForm" bfmForm> -->
  <main id="MainContent" role="main" tabindex="-1" class="Main Main--narrow">
    <div class="Actions">
      <header class="Main-header">
        <h1 class="Main-heading">Admin configurations</h1>
      </header>
      <div role="alert" aria-live="polite" class="notify Actions-item">
        <p class="note-info">
          Note - Press <span class="shortcut">Alt + Shift + Down Arrow </span> to see filter options for selected
          column.
        </p>
      </div>
    </div>
    <div>
      <div class="Actions justify-content-end">
        <div class="col" style="padding-left: 5px">
          <div class="col">
            <span class="sr-total-firms" role="status">Showing {{ currentRecords }} of {{ totalRecords }}</span>
          </div>
        </div>
        <div class="Actions-item Actions-item--large">
          <span class="bento-search">
            <label for="staffingRatioPeerGroupsSearch" class="sr-only">Search firm</label>
            <input
              type="text"
              class="form-control"
              name="staffingRatioPeerGroupsSearch"
              id="staffingRatioPeerGroupsSearch"
              (keyup.enter)="searchStaffingRatioFirm()"
              [(ngModel)]="searchText"
            />
            <button
              type="button"
              id="staffingRatioPeerGroupsSearchBtn"
              name="staffingRatioPeerGroupsSearchBtn"
              (keydown)="onTabFromSearchButton($event)"
              class="btn btn-primary"
              (click)="searchStaffingRatioFirm()"
            >
              <span class="btn-text">Search</span>
            </button>
          </span>
        </div>
      </div>
      <div class="Main-body">
        <wj-flex-grid
          #flexGrid
          class="DataGrid DataGridFlexScroll widthClass btn-addreport"
          [itemsSource]="data"
          [stickyHeaders]="true"
          [showMarquee]="true"
          [allowDragging]="false"
          [autoRowHeights]="true"
          [headersVisibility]="'Column'"
          (initialized)="gridInitialized(flexGrid, filter)"
          (selectionChanged)="handleSelectionChanged(flexGrid, $event)"
        >
          <wj-flex-grid-filter
            #filter
            (initialized)="initFilter()"
            (filterChanged)="onFilterChange()"
            (filterApplied)="onFilterApply()"
          ></wj-flex-grid-filter>

          <wj-flex-grid-column
            [header]="'Firm name'"
            [binding]="'firmName'"
            [visible]="true"
            [minWidth]="380"
            [width]="'*'"
            [wordWrap]="true"
            [isReadOnly]="true"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            [header]="'Firm data valid'"
            [binding]="'firmDataValid'"
            [minWidth]="30"
            [width]="140"
            [visible]="true"
            [wordWrap]="true"
            [align]="'center'"
            [isReadOnly]="true"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
              <bento-checkbox
                id="selectRow-{{ row.index }}-firmDataValid"
                [(ngModel)]="item.firmDataValid"
                (change)="onCheckBoxChange(item)"
                [disabled]="item.isUpdating"
              ></bento-checkbox>
              <label for="selectRow-{{ row.index }}-firmDataValid" class="sr-only">{{ item.name }}</label>
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Peer displayed'"
            [binding]="'isPeerDisplayed'"
            [minWidth]="30"
            [width]="150"
            [visible]="true"
            [wordWrap]="true"
            [isReadOnly]="true"
            [align]="'center'"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row" let-item="item">
              <bento-checkbox
                id="selectRow-{{ row.index }}-isPeerDisplayed"
                [(ngModel)]="item.isPeerDisplayed"
                (change)="onCheckBoxChange(item)"
              ></bento-checkbox>
              <label for="selectRow-{{ row.index }}-isPeerDisplayed" class="sr-only">{{ item.name }}</label>
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Peer submitted'"
            [binding]="'peerSubmitted'"
            [visible]="true"
            [minWidth]="80"
            [width]="160"
            [wordWrap]="true"
            [align]="'center'"
            [isReadOnly]="true"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row" let-item="item">
              <bento-checkbox
                id="selectRow-{{ row.index }}-peerSubmitted"
                [(ngModel)]="item.peerSubmitted"
                (change)="onCheckBoxChange(item)"
                [disabled]="item.isUpdating"
              ></bento-checkbox>
              <label for="selectRow-{{ row.index }}-peerSubmitted" class="sr-only">{{ item.name }}</label>
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Report ready'"
            [binding]="'reportReady'"
            [visible]="true"
            [minWidth]="80"
            [width]="150"
            [wordWrap]="true"
            [isReadOnly]="true"
            [align]="'center'"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row" let-item="item">
              <bento-checkbox
                id="selectRow-{{ row.index }}-reportReady"
                [(ngModel)]="item.reportReady"
                (change)="onCheckBoxChange(item)"
                [disabled]="item.isUpdating"
              ></bento-checkbox>
              <label for="selectRow-{{ row.index }}-reportReady" class="sr-only">{{ item.name }}</label>
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Firm disabled'"
            [binding]="'isFirmDisabled'"
            [minWidth]="30"
            [width]="150"
            [visible]="true"
            [wordWrap]="true"
            [isReadOnly]="true"
            [align]="'center'"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row" let-item="item">
              <bento-checkbox
                id="selectRow-{{ row.index }}-isFirmDisabled"
                [(ngModel)]="item.isFirmDisabled"
                (change)="onCheckBoxChange(item)"
                [disabled]="item.isUpdating"
              ></bento-checkbox>
              <label for="selectRow-{{ row.index }}-isFirmDisabled" class="sr-only">{{ item.name }}</label>
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Inputsheet upload datetime'"
            [binding]="'dateTimeReportImported'"
            [visible]="true"
            [minWidth]="155"
            [width]="220"
            [align]="'left'"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Report export datetime'"
            [binding]="'dateTimeReportExported'"
            [visible]="true"
            [minWidth]="155"
            [width]="200"
            [align]="'left'"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
        </wj-flex-grid>
      </div>
    </div>
  </main>
  <!-- </form> -->
</div>
