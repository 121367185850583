import {Component, OnInit, Input, HostBinding, ViewChild} from '@angular/core';
import {eventDispatcher, store} from 'src/app/core/store';
import {ActionTypes} from 'src/app/core/store/actions';
import FirmBasicsModel from '../../../models/firm-basics.model';
import {PeerCheckServiceService} from '../peer-check/peer-check-service.service';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-expand-table-view',
  templateUrl: './expand-table-view.component.html',
})
export class ExpandTableViewComponent implements OnInit {
  @Input() metricId: number;
  @Input() metricBasicData: any;
  @Input() peerGroupItems: any;
  @Input() pivotIndex: any;
  @Input() selectedFirmId: any;
  @Input() expandType: any;
  @Input() frozenColumnCount;
  @Input() userSettingsModel: any;
  @Input() isCANFirm: boolean = false;
  @Input() isTAdmin: boolean = false;
  statistics = ['Mean', 'Median'];
  statistic = 'Mean';
  tabData: any[] = ['Offices'];
  activeTab = 'Offices';
  isDataLoading = false;
  firmBasicData = new FirmBasicsModel();
  firmRules = [];
  quartile = true;
  showStatistic = false;
  metricData: any;
  highLowPercentilesCols = [];
  peerGroupSelectedItems: any;
  isGetBasicAPICalled = false;
  isValueInitialized = false;
  selectedFirm: any;
  isFromExpandTableView =true;
  constructor(
    public _service: PeerCheckServiceService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.selectedFirm = this.selectedFirmId;
    this.getBasicDetails();
  }

  async getBasicDetails() {
    //Service will return getBasic details and firm level rules
    this.firmBasicData = await this._service.getFirmBasicsData(this.getfirmId);
    this.initializeValues();
    this.isGetBasicAPICalled = true;
    this.isDataLoading = false;
  }

  initializeValues() {
    this.metricData = this.firmBasicData.metrics.filter((item) => item.metricId == this.metricId)[0];
    this.firmRules = this.firmBasicData.rules;
    this.statistic = this.metricBasicData.defaultCol;
    this.applyFirmRules();
    this.peerGroupSelectedItems = this.peerGroupItems;
    this.isDataLoading = false;
    this.isValueInitialized = true;
    eventDispatcher.next({type: ActionTypes.SetStatistic, payload: this.statistic});
  }

  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : parseInt(this.route.snapshot.paramMap.get('id'));
  }

  applyFirmRules() {
    let rules = [];
    this.firmRules.forEach((element) => {
      rules.push(element.rule_name);
    });
    if (this.metricBasicData.mean && this.metricBasicData.median) this.showStatistic = true;

    if (rules.includes('HighLowPercentiles') && this.metricBasicData.hilo == true) {
      let rule = this.firmRules.filter((item) => item.rule_name == 'HighLowPercentiles')[0];
      this.highLowPercentilesCols.push(rule.value1);
      this.highLowPercentilesCols.push(rule.value2);
    }
  }

  onChangeStatistic(event) {
    this.statistic = event.target.value;
    eventDispatcher.next({type: ActionTypes.SetStatistic, payload: event.target.value});
  }
}
