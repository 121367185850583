<div class="modal-content">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h2 id="modalSettings" class="modal-header h2">Settings</h2>
  <div class="modal-body">
    <fieldset class="Form-fieldset Form-fieldset--divider" *ngIf="isStatasticsAvail">
      <legend class="h5 Form-legend">Statistics</legend>
      <div class="form-check">
        <input
          type="radio"
          name="statisticsType"
          id="statisticsType-1"
          class="form-check-input"
          [(ngModel)]="localSettingsModel.statisticsType"
          [value]="'Median'"
        />
        <label for="statisticsType-1" class="form-check-label">Median</label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          name="statisticsType"
          id="statisticsType-2"
          class="form-check-input"
          [(ngModel)]="localSettingsModel.statisticsType"
          [value]="'Mean'"
        />
        <label for="statisticsType-2" class="form-check-label">Mean</label>
      </div>
    </fieldset>
    <fieldset class="Form-fieldset Form-fieldset--divider" *ngIf="isComparisonAvail">
      <legend class="h5 Form-legend">Comparison</legend>
      <div class="form-check">
        <input
          type="radio"
          name="comparisonType"
          id="comparisonType-1"
          class="form-check-input"
          [(ngModel)]="localSettingsModel.comparisonType"
          [value]="'year over year'"
        />
        <label for="comparisonType-1" class="form-check-label">Year over year</label>
      </div>
      <div class="form-check" *ngIf="showVariance">
        <input
          type="radio"
          name="comparisonType"
          id="comparisonType-2"
          class="form-check-input"
          [(ngModel)]="localSettingsModel.comparisonType"
          [value]="'variance'"
        />
        <label for="comparisonType-2" class="form-check-label">Variance</label>
      </div>
    </fieldset>
    <fieldset class="Form-fieldset Form-fieldset--divider">
      <legend class="h5 Form-legend">Matter Type</legend>
      <div class="form-check">
        <input
          type="radio"
          name="matterType"
          id="matterType-1"
          class="form-check-input"
          [(ngModel)]="localSettingsModel.matterType"
          [value]="'billable'"
        />
        <label for="matterType-1" class="form-check-label">Billable</label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          name="matterType"
          id="matterType-2"
          class="form-check-input"
          [(ngModel)]="localSettingsModel.matterType"
          [value]="'contingent'"
        />
        <label for="matterType-2" class="form-check-label">Contingent</label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          name="matterType"
          id="matterType-3"
          class="form-check-input"
          [(ngModel)]="localSettingsModel.matterType"
          [value]="'all'"
        />
        <label for="matterType-3" class="form-check-label">Billable and Contingent</label>
      </div>
    </fieldset>
    <fieldset class="Form-fieldset Form-fieldset--divider">
      <legend class="h5 Form-legend">Contractors</legend>
      <div class="form-check">
        <input
          type="radio"
          name="contractorsType"
          id="contractorsType-1"
          class="form-check-input"
          [(ngModel)]="localSettingsModel.contractorsType"
          [value]="'include'"
        />
        <label for="contractorsType-1" class="form-check-label">Include</label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          name="contractorsType"
          id="contractorsType-2"
          class="form-check-input"
          [(ngModel)]="localSettingsModel.contractorsType"
          [value]="'exclude'"
        />
        <label for="contractorsType-2" class="form-check-label">Exclude</label>
      </div>
    </fieldset>
    <fieldset class="Form-fieldset Form-fieldset--divider">
      <legend class="h5 Form-legend">Currency</legend>
      <div class="form-check">
        <input
          type="radio"
          name="currencyType"
          id="currencyType-1"
          class="form-check-input"
          [(ngModel)]="localSettingsModel.currencyType"
          [value]="'dynamic'"
        />
        <label for="currencyType-1" class="form-check-label">Dynamic</label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          name="currencyType"
          id="currencyType-2"
          class="form-check-input"
          [(ngModel)]="localSettingsModel.currencyType"
          [value]="'static'"
        />
        <label for="currencyType-2" class="form-check-label">Static</label>
      </div>
    </fieldset>
  </div>
  <div class="modal-footer modal-footer--custom">
    <button type="button" class="btn btn-primary" (click)="applySettings()">Apply</button>
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss('Cancel')">Cancel</button>
  </div>
</div>
