import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import StaffingratioFirmSettings from 'src/app/core/models/staffingration-subscription-settings.model';
import {CustomPeergroupGridComponent} from './custom-peergroup-grid/custom-peergroup-grid.component';
@Component({
  selector: 'app-my-peer-groups',
  templateUrl: './my-peer-groups.component.html',
})
export class MyPeerGroupsComponent {
  customPeerGroupsList: any = [];
  customPeerGroupsFirmsList: any = [];
  alerts: BentoAlertItemOptions[] = [];
  customGroups = [];
  isDataLoading = false;
  successMsg = '';
  errorMessage = '';
  isFullyDefined = false;
  selectedRecord = [];
  selectBinding = 'selected';
  customPeerGroupsData: any = [];
  isSubmitPeerGroupDisabled = false;
  cust_groups_label = 'Groups';
  preset_groups_label = 'Groups';
  checkedLimit = 0;
  colArr = [];
  colPeerGroupsArr = [];
  selectedPresetPeergroup = [];
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  @Input() selectedFirmId: number;
  @Input() currentSurveyYearOfFirm: any;
  @Input() selectedFirmCurrency: any;
  @Input() selectedFirmSRSubscriptionInfo: StaffingratioFirmSettings;
  @Output() refreshSusbcriptionData = new EventEmitter<boolean>();
  peerGroups = [];
  @Output() handleLoaderAction = new EventEmitter<boolean>();
  constructor(private service: BaseService, public modalService: NgbModal) {}
  async ngOnInit(): Promise<void> {
    this.isDataLoading = true;
    await this.getPeerGroupsData();
    await this.getCustomPeerGroupsData();
    if (this.selectedFirmSRSubscriptionInfo.numberOfStdPeerGroups <= 1) this.preset_groups_label = 'Group';
    if (this.selectedFirmSRSubscriptionInfo.numberOfCustomPeerGroups <= 1) this.cust_groups_label = 'Group';
    this.getSelectedPreSetPeerGroup();
    this.checkedLimit = this.selectedPresetPeergroup ? this.selectedPresetPeergroup.length : 0;
    // Submit Peers Button is disabled when the reports are ready OR peers submitted previously OR things are not fully defined
    this.getIsSubmitBttonEnabled();
    this.customPeerGroupsData = new wjcCore.CollectionView(this.customPeerGroupsFirmsList);
    this.isDataLoading = false;
  }
  getIsSubmitBttonEnabled() {
    this.checkIfPeergroupFullyDefined();
    this.isSubmitPeerGroupDisabled =
      this.selectedFirmSRSubscriptionInfo.reportReady ||
      !this.selectedFirmSRSubscriptionInfo.firmDataValid ||
      this.selectedFirmSRSubscriptionInfo.peerSubmitted ||
      !this.isFullyDefined;
    if (!this.isSubmitPeerGroupDisabled) {
      if (this.selectedFirmSRSubscriptionInfo.numberOfStdPeerGroups != this.checkedLimit) {
        this.isSubmitPeerGroupDisabled = true;
      }
    }
  }
  getPeerGroupsData() {
    var isGlobal = true;
    return this.service
      .get(
        environment.FIClientBaseEndpoint +
          'v1/staffingratio/peergroup?firmId=' +
          this.selectedFirmId +
          '&surveyYear=' +
          this.selectedFirmSRSubscriptionInfo.surveyYear +
          '&isGlobal=' +
          isGlobal,
        ''
      )
      .toPromise()
      .then(
        (result) => {
          this.peerGroups = result;
          //  console.log(this.peerGroups)
        },
        (error) => {
          this.errorMessage = error.error.message;
          /*this.alerts.push({
          type: 'warning',
          msg: this.errorMessage,
          closeable: true,
        });*/
        }
      );
  }
  getCustomPeerGroupsData() {
    var isGlobal = false;
    return this.service
      .get(
        environment.FIClientBaseEndpoint +
          'v1/staffingratio/peergroup?firmId=' +
          this.selectedFirmId +
          '&surveyYear=' +
          this.selectedFirmSRSubscriptionInfo.surveyYear +
          '&isGlobal=' +
          isGlobal,
        ''
      )
      .toPromise()
      .then(
        (result) => {
          this.customGroups = result;
        },
        (error) => {
          this.errorMessage = error.error.message;
          this.alerts.push({
            type: 'warning',
            msg: this.errorMessage,
            closeable: true,
          });
        }
      );
  }
  getSelectedPreSetPeerGroup() {
    if (this.selectedFirmSRSubscriptionInfo && this.selectedFirmSRSubscriptionInfo.numberOfStdPeerGroups) {
      this.selectedPresetPeergroup = this.peerGroups
        .filter((data) => data.isSelected)
        .map(({peerGroupId}) => peerGroupId);
    }
  }
  async submitPeerGroups() {
    this.isDataLoading = true;
    await this.submitPresetPeerGroups();
    await this.updatesubmitPeerGroupsFlag();
    this.isDataLoading = false;
  }

  updatesubmitPeerGroupsFlag() {
    return this.service
      .put(
        environment.FIClientBaseEndpoint +
          'v1/staffingratio/subscription/flag/peersubmit/' +
          this.selectedFirmId +
          '/' +
          this.currentSurveyYearOfFirm,
        ''
      )
      .toPromise()
      .then(
        (result) => {
          this.selectedFirmSRSubscriptionInfo.peerSubmitted = true;
          this.alerts.push({
            type: 'success',
            msg: 'Peer group submitted successfully.',
            timeout: 2500,
            closeable: true,
          });
        },
        (error) => {
          this.alerts.push({
            type: 'warning',
            msg: error.error.message,
            closeable: true,
          });
          this.isDataLoading = false;
        }
      );
  }
  submitPresetPeerGroups() {
    this.getSelectedPreSetPeerGroup();

    return this.service
      .post(environment.FIClientBaseEndpoint + 'v1/staffingratio/peergroup/selected/' + this.selectedFirmId, {
        selectedPeerGroupIds: this.selectedPresetPeergroup,
      })
      .toPromise()
      .then(
        (result) => {
          this.selectedFirmSRSubscriptionInfo.peerSubmitted = true;
        },
        (error) => {
          this.alerts.push({
            type: 'warning',
            msg: error.error.message,
            closeable: true,
          });
          this.isDataLoading = false;
        }
      );
  }
  onPgCheckBoxChange(pg) {
    if (pg.isSelected) this.checkedLimit++;
    else this.checkedLimit--;
    if (this.checkedLimit == this.selectedFirmSRSubscriptionInfo.numberOfStdPeerGroups) this.getIsSubmitBttonEnabled();
    else this.isSubmitPeerGroupDisabled = true;
  }
  customPeerGroupPopUp() {
    const modalRef = this.modalService.open(CustomPeergroupGridComponent, {
      ariaLabelledBy: 'sr-custom-peergroup-modal',
      windowClass: 'sr-custom-peergroup-modal',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.selectedFirmId = this.selectedFirmId;
    modalRef.componentInstance.currentSurveyYearOfFirm = this.currentSurveyYearOfFirm;
    modalRef.componentInstance.selectedFirmSRSubscriptionInfo = this.selectedFirmSRSubscriptionInfo;
    modalRef.result.then(
      async (result) => {
        if (result == 'Success') {
          await this.getCustomPeerGroupsData();
          this.getIsSubmitBttonEnabled();
          this.refreshSusbcriptionData.emit();
          this.alerts.push({
            type: 'success',
            msg: 'Peer group saved successfully.',
            timeout: 2500,
            closeable: true,
          });
        } else {
          this.alerts.push({
            type: 'warning',
            msg: result,
            closeable: true,
          });
        }
      },
      async (reason) => {
        await this.getCustomPeerGroupsData();
        this.getIsSubmitBttonEnabled();
      }
    );
  }
  checkIfPeergroupFullyDefined() {
    let temp = true;
    this.customGroups.map((peerGroup) => {
      if (peerGroup.firmCount < 5) {
        temp = false;
      }
    });
    if (temp) this.isFullyDefined = true;
    else this.isFullyDefined = false;
  }
}
