<nav
  aria-label="Breadcrumbs"
  class="NavBreadcrumb"
  *ngIf="(!isFirmListPage || size != 4) && isMappings && (!(size > 5) || hasbreadcrumbVariance) && size != 4"
>
  <ol class="breadcrumb">
    <ng-container *ngIf="isTAdmin">
      <li class="breadcrumb-item" *ngIf="!hasbreadcrumbVariance" class="breadcrumb-item active" aria-current="page">
        {{ breadcrumbMappingsTabText }}
      </li>
    </ng-container>
    <li class="breadcrumb-item" *ngIf="hasbreadcrumbVariance; active" aria-current="page">
      <a *ngIf="defaultPeriod == selectedPeriod" [routerLink]="breadcrumbMappingsTabLink">{{
        breadcrumbMappingsTabText
      }}</a>
      <ng-container *ngIf="defaultPeriod != selectedPeriod">
        <a class="breadCrumbColor" (click)="onClickBalancingLink('Default')">Balancing</a>&nbsp; &nbsp;/&nbsp;&nbsp;<a
          class="breadCrumbColor"
          (click)="onClickBalancingLink('Variance')"
          >Balancing ({{ selectedPeriod }})</a
        >
      </ng-container>
    </li>
    <li class="breadcrumb-item" *ngIf="hasbreadcrumbVariance">
      {{ breadcrumbVariance }}
    </li>
  </ol>
</nav>
<nav aria-label="breadcrumb" class="NavBreadcrumb" *ngIf="peerCheckCreatePPG">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/create-view']">Create a view</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">
      <p>Performance peer groups</p>
    </li>
  </ol>
</nav>
<nav aria-label="Breadcrumbs" class="NavBreadcrumb" *ngIf="isFirmPeerGroup">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="firmParameterrouterLink" (click)="updateServiceEvent()">{{ firmName }}</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">
      {{ peerLabel }}
    </li>
  </ol>
</nav>
