import {Component} from '@angular/core';

@Component({
  selector: 'app-global-sidebar',
  templateUrl: 'global-sidebar.component.html',
})
export class PmGlobalSidebarComponent {
  showOnePanel = false;
  myActiveIds: string[] = ['1'];
}
