export default class StaffingratioFirmSettings {
  public firmId: number;
  public firmName: string;
  public surveyYear: string;
  public amLawSegment: string;
  public lawyerSegment: string;
  public officeSegment: string;
  public srFirmSubscriptionEntity: string;
  public usesServiceCenter: string;
  public firmDataValid: boolean;
  public peerSubmitted: boolean;
  public reportReady: boolean;
  public isPeerDisplayed: boolean = false;
  public isSubscribed: boolean = false;
  public isFirmDisabled: boolean = false;
  public mainOfficeCountry: string;
  public numberOfStdPeerGroups: number = 0;
  public numberOfCustomPeerGroups: number = 0;
  public dateTimeReportExported: string;
  public dateTimeReportImported: string;
  public currencyCode: string;
  public amLawRank: number;
  public lawyers: number;
  public offices: number;
  public dataProcessing: SRDataProcessing = new SRDataProcessing()
  public constructor() {}
}
export class CustomSegmentRequestBody {
  firmId: number;
  surveyYear: number;
  amLawSegment: string ;
  lawyerSegment: string ;
  officeSegment: string ;
}
export class SRDataProcessing {
  amLawSegment = false;
  lawyerSegment = false;
  officeSegment = false;
}
