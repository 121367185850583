import {Component, OnInit, ViewChild} from '@angular/core';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {environment} from 'src/environments/environment';
import {BaseService} from '../../../services/base/base.service';
import {GlobalService} from 'src/app/core/services/global/global.service';
@Component({
  selector: 'app-reports-progress',
  templateUrl: './reports-progress.component.html',
  styleUrls: ['./reports-progress.component.scss'],
})
export class ReportsProgressComponent implements OnInit {
  reportProgressList = [];

  data: wjcCore.CollectionView;
  currentRecords: number;
  totalRecords: number;
  pageInfo: any = {
    infoText: '_START_ to _END_ of _MAX_ Users',
    infoPageText: '_PAGE_ of _PAGES_',
    goText: 'Go',
    pageSize: 10,
  };
  alerts: BentoAlertItemOptions[] = [];
  isGetDataLoading = false;
  errorMessage: any;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;

  constructor(
    private service: BaseService,
    private globalservice: GlobalService
    ) {
    this.data = new wjcCore.CollectionView(this.reportProgressList);
    //this.data.pageSize = this.pageInfo.pageSize;
    this.getReportProgressData();
  }

  onPageSizeChanged(size) {
    this.data.pageSize = size;
    //this.tkHistoryRecordRequest.size = size;
    this.pageInfo.pageSize = size;
  }

  // Pagination Page Changed Event Handler
  onPageChanged(page) {
    this.data.moveToPage(page - 1);
  }

  gridInitialized(flexGrid) {
    if (flexGrid.rows) {
      flexGrid.rows.defaultSize = 40;
      flexGrid.columnHeaders.rows.defaultSize = 40;
    }
    this.flexGrid = flexGrid;
  }

  ngOnInit(): void {}

  getReportProgressData() {
    this.isGetDataLoading = true;

    this.service.get(environment.FIAdminBaseEndpoint + 'v1/ratesReport/fileList/inProgress', '').subscribe(
      (result) => {
        this.isGetDataLoading = false;
        this.reportProgressList = result;
        this.data = new wjcCore.CollectionView(this.reportProgressList);
        this.data.pageSize = this.pageInfo.pageSize;
        this.globalservice.addPaginationAdditionalInfo();
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'something went wrong please try again.',
          timeout: 2500,
          closeable: true,
        });
      }
    );
  }
}
