import {Component, EventEmitter, Input, Output, ViewChild, OnInit} from '@angular/core';
import {BentoAlertItemOptions, BentoTransferboxBasicColumnDefinitions} from '@bento/bento-ng';
import UserModel from 'src/app/core/models/user.model';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
import {AuthService} from 'src/app/core/services/auth/auth.service';
declare let TRAAC: any;

@Component({
  selector: 'app-firm-permission',
  templateUrl: './firm-permission.component.html',
})
export class FirmPermissionComponent implements OnInit {
  @ViewChild('transferbox', {static: true}) transferbox;
  gridOptions: BentoTransferboxBasicColumnDefinitions[] = [];
  gridHeight = 530;
  hidePagination = false;
  userData: UserModel = new UserModel();
  clearUserData: UserModel = new UserModel();
  errorMessage = '';
  alerts: BentoAlertItemOptions[] = [];
  @Input() firmId: number;
  @Input() permissionName: string;
  @Output() handleUserAction = new EventEmitter<string>();
  isGetDataloading = false;
  isInformationLoading = false;
  transferredMetric = [];
  metricIds = [];
  descriptions = [];
  assignedMetrics: any;
  allMetrics: any;
  dataOne: any;
  dataTwo: any;
  dataOneCopy: any;
  dataTwoCopy: any;

  isFirstTime = false;
  successMsg: string;
  isVisiblePermissionsEmpty = false;
  serviceCall: string;
  getAllService: string;
  bindingName: string;
  columnKey: string;
  isQuickSightEnabled = false;
  constructor(
    private authService: AuthService,
    private service: BaseService,
    private featureToggleService: FeatureToggleService
  ) {}

  ngOnInit(): void {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
    this.featureToggleService.getFeatureValue('QUICKSIGHT').then((val) => (this.isQuickSightEnabled = val));
    /*************************************************************/

    switch (this.permissionName) {
      case 'metrics':
        this.bindingName = 'metricDescription';
        this.serviceCall = '/metric';
        this.getAllService = '/allMetric';
        this.columnKey = 'metricId';
        break;
      case 'offices':
        this.bindingName = 'name';
        this.serviceCall = '/office';
        this.getAllService = '/allOffice';
        this.columnKey = 'id';
        break;
      case 'practice groups':
        this.bindingName = 'practiceName';
        this.serviceCall = '/practice';
        this.getAllService = '/allPractice';
        this.columnKey = 'id';
        break;
      case 'titles':
        this.bindingName = 'title';
        this.serviceCall = '/title';
        this.getAllService = '/allTitle';
        this.columnKey = 'id';
        break;
    }
    this.getAssignedMetricPermissions();

    let header = this.permissionName == 'practice groups' ? 'practices' : this.permissionName;
    this.gridOptions = [
      {
        header: 'All ' + header,
        binding: this.bindingName,
      },
    ];
  }
  onTransfer($event) {
    this.dataOne = $event.gridDataOne;
    this.dataTwo = $event.gridDataTwo;
    this.transferredMetric = $event.gridDataTwo;
    if (this.permissionName == 'metrics') this.metricIds = this.transferredMetric.map(({metricId}) => metricId);
    else this.metricIds = this.transferredMetric.map(({id}) => id);
    if ($event.gridDataTwo.length < 1) this.isVisiblePermissionsEmpty = true;
    else this.isVisiblePermissionsEmpty = false;

    if (this.permissionName == 'metrics') {
      this.descriptions = this.transferredMetric.map(({metricDescription}) => metricDescription);
    } else if (this.permissionName == 'offices') {
      this.descriptions = this.transferredMetric.map(({name}) => name);
    } else if (this.permissionName == 'practice groups') {
      this.descriptions = this.transferredMetric.map(({practiceName}) => practiceName);
    } else if (this.permissionName == 'titles') {
      this.descriptions = this.transferredMetric.map(({title}) => title);
    }
  }

  getAssignedMetricPermissions() {
    this.isGetDataloading = false;
    this.isInformationLoading = true;
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/firm/' + this.firmId + this.serviceCall, '').subscribe(
      (result) => {
        this.isGetDataloading = true;
        this.isInformationLoading = false;
        this.dataTwo = result;
        this.dataTwoCopy = JSON.parse(JSON.stringify(this.dataTwo));

        this.assignedMetrics = result;
        if (this.assignedMetrics.length < 1) this.isVisiblePermissionsEmpty = true;
        this.getAllMetricPermissions();
      },
      (error) => {
        this.isGetDataloading = true;
        this.isInformationLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
  getAllMetricPermissions() {
    this.isGetDataloading = false;
    this.isInformationLoading = true;
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/firm/' + this.firmId + this.getAllService, '').subscribe(
      (result) => {
        this.isGetDataloading = true;
        this.isInformationLoading = false;
        this.allMetrics = result;
        this.getRestrictedMetrics();
      },
      (error) => {
        this.isGetDataloading = true;
        this.isInformationLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getRestrictedMetrics() {
    if (this.assignedMetrics.length < 1) {
      this.dataOne = this.allMetrics;
    } else {
      if (this.permissionName == 'metrics')
        this.dataOne = this.allMetrics.filter((o) => !this.dataTwo.some((v) => v.metricId === o.metricId));
      else this.dataOne = this.allMetrics.filter((o) => !this.dataTwo.some((v) => v.id === o.id));
    }
    this.dataOneCopy = JSON.parse(JSON.stringify(this.dataOne));
  }

  saveMetricInfo() {
    this.isInformationLoading = true;
    if (this.isQuickSightEnabled) {
      const metadata = {
        permissions: this.descriptions,
        email: this.authService.getUserEmail(),
      };
      TRAAC.track(TRAAC.keyValue(`Update firm ${this.serviceCall.substring(1)} permissions`, metadata).build());
    }
    this.service
      .post(environment.FIAdminBaseEndpoint + 'v1/firm/' + this.firmId + this.serviceCall, this.metricIds)
      .subscribe(
        (result) => {
          this.isInformationLoading = false;
          this.successMsg = 'Data modified successfully.';
          this.dataTwoCopy = this.dataTwo;
          this.dataOneCopy = this.dataOne;
          this.alerts.push({
            type: 'success',
            msg: this.successMsg,
            timeout: 2500,
            closeable: true,
          });
        },
        (error) => {
          this.isInformationLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }
  closeAlert($event) {}

  cancelClick() {
    this.isInformationLoading = true;
    this.isGetDataloading = false;
    this.dataOne = this.dataOneCopy;
    this.dataTwo = this.dataTwoCopy;
    this.alerts.push({
      type: 'success',
      msg: 'Data reset successfully.',
      timeout: 2500,
      closeable: true,
    });
    setTimeout(() => {
      this.isInformationLoading = false;
      this.isGetDataloading = true;
    }, 100);
  }
}
