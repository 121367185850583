import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor() {}
  download(data: any, filename: string) {
    var filename = filename;
    const blob = new Blob([data], {type: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    const url = window.URL.createObjectURL(blob);
    var downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = filename;
    downloadLink.click();
  }

  downloadWithExtension(data: any, filename: string, extension: string) {
    var filename = filename;
    var type;

    if (extension === 'xlsx') {
      type = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const blob = new Blob([data], {type: type});
      const url = window.URL.createObjectURL(blob);
      var downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.target = '_blank';
      downloadLink.click();
    } else if (extension === 'pdf') {
      type = 'application/pdf';
      const blob = new Blob([data], {type: type});
      const url = window.URL.createObjectURL(blob);
      var downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.target = '_blank';
      downloadLink.click();
    } else if (extension === 'docx') {
      type = 'application/docx';
      const blob = new Blob([data], {type: type});
      const url = window.URL.createObjectURL(blob);
      var downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.target = '_blank';
      downloadLink.click();
    }

    // }
  }
  onlyDownloadWithExtension(data: any, filename: string, extension: string) {
    var filename = filename;
    var type;

    if (extension === 'xlsx') {
      type = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const blob = new Blob([data], {type: type});
      const url = window.URL.createObjectURL(blob);
      var downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
    } else if (extension === 'pdf') {
      type = 'application/pdf';
      const blob = new Blob([data], {type: type});
      const url = window.URL.createObjectURL(blob);
      var downloadLink = document.createElement('a');
      downloadLink.download = filename;
      downloadLink.href = url;
      downloadLink.click();
    } else if (extension === 'zip') {
      type = 'application/zip';
      const blob = new Blob([data], {type: type});
      const url = window.URL.createObjectURL(blob);
      var downloadLink = document.createElement('a');
      downloadLink.download = filename;
      downloadLink.href = url;
      downloadLink.click();
    } else if (extension === 'doc') {
      type = 'application/doc';
      const blob = new Blob([data], {type: type});
      const url = window.URL.createObjectURL(blob);
      var downloadLink = document.createElement('a');
      downloadLink.download = filename;
      downloadLink.href = url;
      downloadLink.click();
    } else if (extension === 'docx') {
      type = 'application/docx';
      const blob = new Blob([data], {type: type});
      const url = window.URL.createObjectURL(blob);
      var downloadLink = document.createElement('a');
      downloadLink.download = filename;
      downloadLink.href = url;
      downloadLink.click();
    } else if (extension === 'txt') {
      type = 'application/octet-stream';
      const blob = new Blob([data], {type: type});
      const url = window.URL.createObjectURL(blob);
      var downloadLink = document.createElement('a');
      downloadLink.download = filename;
      downloadLink.href = url;
      downloadLink.click();
    }

    // }
  }
}
