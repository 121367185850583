<br />

<span class="label-required-summary font-bold">Percentage/Amount sum should be equal to allocation value</span>
<br />
<form bfmForm name="mapAccountEditForm" class="MappedAccount">
  <bfm-error-container #errorSummary></bfm-error-container>
  <table class="table table-bordered MappedAccountGrid">
    <thead>
      <tr>
        <th scope="col" *ngFor="let header of fetchHeaders()">{{ header.name }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let selection of selectedDetails; let i = index">
        <td class="MappedAccountGrid-cell" *ngIf="isOptionAvailable('Department')">
          <div class="form-group">
            <label for="department-{{ i }}" class="sr-only bento-label-required">Department {{ i }}</label>

            <bento-combobox
              #bentoComboBox
              [inputLabelClass]="'sr-only'"
              [inputLabelText]="'Department'"
              id="department-{{ i }}"
              bfmField
              bfmLabel="Department"
              name="department-{{ i }}"
              [itemsObservable]="dataStream"
              [(ngModel)]="selectedDetails[i].departmentObj"
              [errorContainer]="errorSummary"
              [options]="comboboxOptions"
              (ready)="onComboboxReady(i)"
              (ngModelChange)="saveData()"
              class="form-control"
              [disabled]="!selectedGlAcOverride.override.accountTotalAmount || invalidForm"
              required
            ></bento-combobox>
          </div>
        </td>
        <td class="MappedAccountGrid-cell" *ngIf="isOptionAvailable('Office')">
          <div class="form-group">
            <label for="office-{{ i }}" class="sr-only bento-label-required">Office {{ i }}</label>

            <select
              bentoSelect
              bfmField
              bfmLabel="Office"
              class="form-control"
              [errorContainer]="errorSummary"
              id="office-{{ i }}"
              name="office-{{ i }}"
              [(ngModel)]="selectedDetails[i].officeCode"
              (change)="saveData()"
              [disabled]="!selectedGlAcOverride.override.accountTotalAmount || invalidForm"
              required
            >
              <option *ngFor="let office of glacOffice" [ngValue]="office.firmCode">
                {{ office.firmDescription }}
              </option>
            </select>
          </div>
        </td>
        <td class="MappedAccountGrid-cell">
          <div class="form-group">
            <label for="mapAccountName-{{ i }}" class="sr-only bento-label-required">Mapped account name {{ i }}</label>

            <select
              bentoSelect
              bfmField
              bfmLabel="Map account name"
              class="form-control"
              [errorContainer]="errorSummary"
              id="mapAccountName-{{ i }}"
              name="mapAccountName-{{ i }}"
              [(ngModel)]="selectedDetails[i].mappedAccountId"
              (change)="saveData()"
              [disabled]="!selectedGlAcOverride.override.accountTotalAmount || invalidForm"
              required
            >
              <option *ngFor="let mappedOfficeName of glacMappedOfficeName" [ngValue]="mappedOfficeName.code">
                {{ mappedOfficeName.description }}
              </option>
            </select>
          </div>
        </td>
        <td class="MappedAccountGrid-cell" *ngIf="isOptionAvailable('Mapped office')">
          <div class="form-group">
            <label for="mappedOffice-{{ i }}" class="sr-only bento-label-required">Mapped office {{ i }}</label>

            <select
              bentoSelect
              bfmField
              bfmLabel="Mapped office"
              class="form-control"
              [errorContainer]="errorSummary"
              id="mappedOffice-{{ i }}"
              name="mappedOffice-{{ i }}"
              [(ngModel)]="selectedDetails[i].mappedOfficeId"
              (change)="saveData()"
              [disabled]="!selectedGlAcOverride.override.accountTotalAmount || invalidForm"
              required
            >
              <option [ngValue]="'FTE ALLOCATION'">FTE ALLOCATION</option>
              <option *ngFor="let mappedOffice of glacMappedOffice" [ngValue]="mappedOffice.code">
                {{ mappedOffice.description }}
              </option>
            </select>
          </div>
        </td>
        <td class="MappedAccountGrid-cell MappedAccountGrid-cell--small">
          <div class="form-group">
            <label for="allocated-{{ i }}" class="sr-only bento-label-required">Percent {{ i }}</label>

            <input
              bfmField
              bfmLabel="Allocation"
              class="form-control"
              id="allocated-{{ i }}"
              name="allocated-{{ i }}"
              [errorContainer]="errorSummary"
              [(ngModel)]="selectedDetails[i].amount"
              (ngModelChange)="allocationValueChange()"
              required
              type="text"
              max="3"
              [disabled]="!selectedGlAcOverride.override.accountTotalAmount || invalidForm"
              appTwoDigitDecimalNumber
            />
          </div>
        </td>
        <td class="MappedAccountGrid-cell MappedAccountGrid-cell--small">
          <div class="MappedAccountGrid-action">
            <button
              aria-label="Delete row"
              ngbTooltip="Delete row"
              type="button"
              class="btn btn-flat-icon"
              (click)="deleteCode(i)"
              [disabled]="selectedDetails.length == 1"
            >
              <i class="bento-icon-close-circle" aria-hidden="true"></i>
            </button>
          </div>
        </td>
      </tr>
      <tr class="MappedAccountGrid-addAccount">
        <td [attr.colspan]="avaliableCols" class="MappedAccountGrid-cell">
          <div class="MappedAccountGrid-action">
            <button
              type="button"
              class="btn btn-flat-secondary"
              (click)="addCode()"
              [disabled]="!selectedGlAcOverride.override.accountTotalAmount || invalidForm"
            >
              <i class="bento-icon-add" aria-hidden="true"></i>
              <span class="btn-text">Add another account</span>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</form>
