export class GlExtractRequest {
  page = 1;
  size: null | number = 10;
  fiscalPeriodFrom: null | any = null;
  //to be removes
  pmAccount: string;
  fiscalPeriodTo: null | any = null;
  accountType: string;
  accountId: string;
  sortColumns: string[] = [];
  sortDir = 'asc';
  displayColumns: string[] = [];
}
