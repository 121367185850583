import {Subject, empty} from 'rxjs';
import CustomerModel from '../models/customer.model';
import {ActionTypes} from './actions';
import FirmDetailModel from '../models/firmdetail.model';
import FirmParamterModel from '../models/firmParameter.model';
import UserModel from '../models/user.model';
import FirmBasicsModel from '../models/firm-basics.model';
import {PivotRequestBody} from '../models/pivotRequestBody.model';
import {PivotResponseData} from '../models/pivotResponseData.model';
import {ProcessedMainData} from '../models/create-view.model';

interface InitialState {
  customers: CustomerModel[];
  firmDetail: FirmDetailModel;
  firmParameter: FirmParamterModel;
  userDetail: UserModel;
  statistic: string;
  basicDetails: FirmBasicsModel;
  pivotRequestBody: {string: PivotRequestBody}[];
  selectedFirmId: number;
  processedMainGridData: ProcessedMainData;
}

let state: InitialState = {
  customers: [],
  firmDetail: null,
  firmParameter: null,
  userDetail: null,
  statistic: 'Mean',
  basicDetails: null,
  pivotRequestBody: [],
  selectedFirmId: null,
  processedMainGridData: null,
};

interface Event {
  type: string;
  payload?: any;
}

export const store = new Subject<InitialState>();
export const eventDispatcher = new Subject<Event>();

eventDispatcher.subscribe((data: Event) => {
  switch (data.type) {
    case ActionTypes.GetAllCustomer:
      store.next(state);
      break;

    case ActionTypes.SetAllCustomer:
      state = {
        ...state,
        customers: data.payload,
      };
      store.next(state);
      break;

    case ActionTypes.SetFirmDetail:
      state = {
        ...state,
        firmDetail: data.payload,
      };
      store.next(state);
      break;

    case ActionTypes.CreateCustomer:
      state = {
        ...state,
        customers: [...state.customers, data.payload],
      };
      store.next(state);
      break;

    case ActionTypes.UpdateCustomer:
      {
        const {customers} = state;
        const customer = data.payload;
        const item = customers.find((cust: CustomerModel) => cust.id === customer.id);
        const index = customers.indexOf(item);
        customers[index] = customer;
        state = {
          ...state,
          customers,
        };
        store.next(state);
      }
      break;

    case ActionTypes.DeleteCustomer:
      {
        const {customers} = state;
        const id = data.payload;
        const updatedCustomers = customers.filter((customer: CustomerModel) => customer.id !== id);
        state = {
          ...state,
          customers: updatedCustomers,
        };
        store.next(state);
      }
      break;
    case ActionTypes.GetFirmList:
      store.next(state);
      break;
    case ActionTypes.GetFirmDetail:
      store.next(state);
      break;
    case ActionTypes.SetFirmParameterSetting:
      state = {
        ...state,
        firmParameter: data.payload,
      };
      store.next(state);
      break;
    case ActionTypes.GetFirmParameterSetting:
      store.next(state);
      break;
    case ActionTypes.SetUserDetail:
      state = {
        ...state,
        userDetail: data.payload,
      };
      store.next(state);
      break;
    case ActionTypes.GetUserDetails:
      store.next(state);
      break;
    case ActionTypes.SetStatistic:
      state = {
        ...state,
        statistic: data.payload,
      };
      store.next(state);
      break;
    case ActionTypes.GetStatistic:
      store.next(state);
      break;
    case ActionTypes.SetBasicDetails:
      state = {
        ...state,
        basicDetails: data.payload,
      };
      store.next(state);
      break;
    case ActionTypes.GetBasicDetails:
      store.next(state);
      break;
    case ActionTypes.SetPivotRequestBody:
      state = {
        ...state,
        pivotRequestBody: data.payload,
      };
      store.next(state);
      break;
    case ActionTypes.GetPivotRequestBody:
      store.next(state);
      break;
    case ActionTypes.SetSelectedFirmId:
      state = {
        ...state,
        selectedFirmId: data.payload,
      };
      store.next(state);
      break;
    case ActionTypes.GetSelectedFirmId:
      store.next(state);
      break;
    case ActionTypes.SetProcessedData:
      state = {
        ...state,
        processedMainGridData: data.payload,
      };
      store.next(state);
      break;
    case ActionTypes.GetProcessedData:
      store.next(state);
      break;
    default:
      break;
  }
});
