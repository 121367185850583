import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ContentChild,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {Component, HostBinding, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

import {BentoListSubscribable} from './list-data-subscribable';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {Observable} from 'rxjs';

/**
 * Default variables for the width and height of this element
 */
export const DEFAULT_LIST_WIDTH = 300;
export const DEFAULT_LIST_HEIGHT = 300;
export const DEFAULT_ROW_HEIGHT = 30;
export const DEFAULT_LIST_MAX_HEIGHT = Number.POSITIVE_INFINITY;

@Component({
  selector: 'app-bento-list',
  templateUrl: './list.component.html',
  styleUrls: ['./_list.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BentoListComponent extends BentoListSubscribable implements OnInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() autoHeightResize: boolean = false;

  @Input()
  set width(v: number) {}

  @Input()
  set height(v: number) {
    this._height = v || DEFAULT_LIST_HEIGHT;
    this.r.setStyle(this.el, 'height', this._height + 'px');
    this.vScroll.checkViewportSize();
  }

  @Input()
  set dataObservable(observable: Observable<any>) {
    this._setDataObservable(observable);
  }

  @Input()
  set maxHeight(v: number) {
    this._maxHeight = v || DEFAULT_LIST_MAX_HEIGHT;
  }

  @Input() options: any;

  @HostBinding('style.min-width.px') @Input() minWidth = 0;

  @Output() endOfScroll: EventEmitter<any> = new EventEmitter<any>();

  @Output() heightChange: EventEmitter<number> = new EventEmitter<number>();

  @Output() scrollBarChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(CdkVirtualScrollViewport, {static: true}) vScroll: CdkVirtualScrollViewport;

  /**
   * Default row template reference declared in the template file
   */
  @ViewChild('defaultRowTemp', {static: true}) private defaultRowTemp: TemplateRef<any>;

  /**
   * (Optional) Custom template when wrapped with this component
   */
  @ContentChild('rowTemplate', {static: true}) private customRowTemp: TemplateRef<any>;

  /**
   * Row height that is used here (default: 30px)
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('itemHeight')
  set _itemHeight(h: number) {
    // Make sure that there is no `undefined` for `h`
    this.itemHeight = h || DEFAULT_ROW_HEIGHT;
  }
  itemHeight: number = DEFAULT_ROW_HEIGHT;

  /**
   * Row template reference used internally
   */
  rowTemp: TemplateRef<any>;

  // Private variables
  private _height: number;
  private _maxHeight: number;
  private _dataSize: number;
  private el: HTMLElement;
  private listEl: HTMLElement;
  private lastRenderedEnd = 0;
  private timeoutID;

  constructor(private r: Renderer2, elRef: ElementRef, private changeDetector: ChangeDetectorRef) {
    super();
    this.el = elRef.nativeElement;
    this.onElementScroll = this.onElementScroll.bind(this);
  }

  ngOnInit(): void {
    // Use custom template if available
    this.rowTemp = this.customRowTemp || this.defaultRowTemp;

    // Scroll Listener
    this.vScroll.elementScrolled().subscribe(this.onElementScroll);

    this.listEl = this.vScroll.getElementRef().nativeElement;
  }

  ngOnDestroy() {
    clearTimeout(this.timeoutID);
  }

  trackByFn(index, item) {
    return index;
  }

  getScrollTop(): number {
    return this.vScroll.getViewportSize();
  }

  scrollToRow(row: any) {
    this.vScroll.scrollToIndex(this._data.indexOf(row));
  }

  checkViewportSize() {
    this.vScroll.checkViewportSize();
  }

  getRowRangeInView() {
    const scrollTop = this.vScroll.measureScrollOffset('top');
    const height = this.vScroll.getViewportSize();
    const start = Math.ceil(scrollTop / this.itemHeight);
    const end = Math.floor((scrollTop + height) / this.itemHeight);
    return {from: start, to: end};
  }

  scrollToRowIndex(index: number) {
    this.vScroll.scrollToIndex(index);
  }

  /**
   * Make a row visible in the scroll pane
   */
  showRow(row: any) {
    this.showRowIndex(this._data.indexOf(row));
  }

  /**
   * Make a row visible in thescroll pane by its index
   */
  showRowIndex(index: number) {
    const rowOffsetTop = index * this.itemHeight;
    const listHeight = this.listEl.offsetHeight;
    const scrollTop = this.listEl.scrollTop;

    if (rowOffsetTop + this.itemHeight > listHeight + scrollTop) {
      this.vScroll.scrollTo({top: rowOffsetTop - listHeight + this.itemHeight});
    } else if (rowOffsetTop < scrollTop) {
      this.vScroll.scrollTo({top: rowOffsetTop});
    } else {
      this.vScroll.scrollToIndex(index, 'smooth');
    }
  }

  /**
   * Data reload callback
   */
  _onDataReload(data: any[]) {
    this.lastRenderedEnd = 0;
  }

  /**
   * Update callback
   */
  _onDataUpdate(data: any[]) {
    this._dataSize = data.length;
    this.timeoutID = setTimeout(() => {
      // Data update sometimes comes faster than UI
      // need to wait here
      if (this.autoHeightResize) {
        // Set the height of this list accordingly
        this.height = Math.min(this._maxHeight, this._dataSize * this.itemHeight);
        this.heightChange.emit(this._height);
      }

      this.scrollBarChange.emit(this.vScroll.getViewportSize() < this._dataSize * this.itemHeight);
      this.changeDetector.markForCheck();
    });
  }

  private onElementScroll(e: Event) {
    const range = this.vScroll.getRenderedRange();
    if (range.end === this._dataSize && this.lastRenderedEnd < range.end) {
      this.lastRenderedEnd = range.end;
      this.endOfScroll.emit();
    }
  }
}
