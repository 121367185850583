import {DatePipe, formatDate} from '@angular/common';
import {Injectable} from '@angular/core';
import {DateFormat} from 'src/app/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class FormatdateService {
  constructor() {}

  format(date, format) {
    if (format === DateFormat.mmddyyyy) {
      return formatDate(date, 'MM-dd-yyyy', 'en-us');
    } else if (format === DateFormat.yyyymmdd) {
      let dateParts = date.split('-');
      var dateObject = new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
      return formatDate(dateObject, 'yyyy-MM-dd', 'en-us');
    } else if (format === DateFormat.mmddpostfixyyyy) {
      var datObj = new Date(date);
      var day = '' + datObj.getDate();
      var year = datObj.getFullYear();
      const month = datObj.toLocaleString('default', {month: 'long'});
      return (
        month +
        ' ' +
        day +
        this.postFixDate(day) +
        ', ' +
        year +
        ' at ' +
        datObj.getHours() +
        ':' +
        datObj.getMinutes() +
        ':' +
        datObj.getSeconds() +
        ' UTC'
      );
    } else if (format === DateFormat.mmddyyyyWithSlash) {
      const myDate = new Date(date);
      return (
        (myDate.getMonth() > 8 ? myDate.getMonth() + 1 : '0' + (myDate.getMonth() + 1)) +
        '/' +
        (myDate.getDate() > 9 ? myDate.getDate() : '0' + myDate.getDate()) +
        '/' +
        myDate.getFullYear()
      );
    } else if (format === DateFormat.MMMMy) {
      return formatDate(date, 'MMMM yyyy', 'en-us');
    }
  }

  postFixDate = function (d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };
}
