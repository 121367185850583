import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import FirmDetailModel from 'src/app/core/models/firmdetail.model';
import {FirmPeerGroupList, PeerGroupFirms} from 'src/app/core/models/firmpeergroupstatus.model';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {GlobalPeerGroupDetails} from 'src/app/core/models/globalPeerGroupDetails.model';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {PeergroupService} from 'src/app/core/services/peergroup/peergroup.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {environment} from 'src/environments/environment';
import {Role} from 'src/app/shared/enums';
import {GlobalService} from 'src/app/core/services/global/global.service';

@Component({
  selector: 'app-firm-peer-group-detail',
  templateUrl: './firm-peer-group-detail.component.html',
})
export class FirmPeerGroupDetailComponent implements OnInit, OnDestroy {
  isDataLoading: boolean;
  @Input() firmDetails: FirmDetailModel = new FirmDetailModel();
  @Input() currentStatus: string;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  currentRecords: number;
  totalRecords: number;
  isLoadedFirstTime: boolean;
  errorMessage = '';
  alerts: BentoAlertItemOptions[] = [];
  selectedOption: FirmPeerGroupList;
  gridData: wjcCore.CollectionView;
  searchText: String = '';
  selectedPeerGroupFirms: PeerGroupFirms[] = [];
  globalPeerGroupDetails: GlobalPeerGroupDetails = new GlobalPeerGroupDetails();

  visibility: string;

  constructor(
    private authService: AuthService,
    private peerGroupService: PeergroupService,
    private service: BaseService,
    private globalservice: GlobalService
  ) {}

  ngOnInit(): void {
    this.isLoadedFirstTime = true;
    this.totalRecords = 0;
    let selectedPeerGroup = this.peerGroupService.getselectedPeerGroup;
    let currentStatus = this.peerGroupService.getCurrentStatus;
    this.currentStatus = currentStatus ? currentStatus : 'Approved';
    this.gridData = new wjcCore.CollectionView(this.selectedPeerGroupFirms);
    this.gridData.pageSize = 10;
    if (selectedPeerGroup) {
      this.selectedOption = selectedPeerGroup;
    } else {
      this.updateServiceHandler();
      return;
    }
    if (this.currentStatus != 'Global') {
      this.getSelectedFirms();
    } else {
      this.getSelectedFirmsForGlobal();
    }
  }

  ngOnDestroy(): void {
    if (this.peerGroupService) {
      this.peerGroupService.setAlerts = [];
    }
  }

  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }

  public get isFAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.FAdmin;
  }

  closeAlert($event) {}

  getSelectedFirmsForGlobal() {
    this.isDataLoading = true;
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/peergroup/global/', this.selectedOption.peerid).subscribe(
      (result) => {
        this.isDataLoading = false;
        this.globalPeerGroupDetails = result;
        let selectedLocale = this.globalPeerGroupDetails.selectedLocales;
        this.visibility = selectedLocale ? String(selectedLocale) : '';

        this.gridData = new wjcCore.CollectionView(this.globalPeerGroupDetails.selectedfirms);
        this.gridData.pageSize = 10;
        this.currentRecords = this.gridData.itemCount;
        if (this.isLoadedFirstTime) {
          this.totalRecords = this.gridData.totalItemCount;
          this.isLoadedFirstTime = false;
        }
        this.globalservice.addPaginationAdditionalInfo();
      },
      (error) => {
        this.isDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  // gridInitialized(flexGrid) {
  //   flexGrid.rows.defaultSize = 56;
  //   flexGrid.columnHeaders.rows.defaultSize = 40;
  //   flexGrid.rowHeaders.columns.splice(0, 1);
  //   this.flexGrid = flexGrid;
  //   this.currentRecords = flexGrid.rows.length;
  // }

  onPageChanged(page) {
    this.gridData.moveToPage(page - 1);
    this.currentRecords = this.flexGrid.rows.length;
  }

  onItemsPerPageChanged(itemsPerPage) {
    this.gridData.pageSize = itemsPerPage;
    this.currentRecords = this.flexGrid.rows.length;
  }

  handleEditButtonAction() {
    this.peerGroupService.setCustomPeerGroupMode = 'edit';
    this.peerGroupService.peerGroupStatusHandler();
  }

  searchGlobalPeerGroups() {
    if (this.currentStatus == 'Global') {
      this.gridData.sourceCollection = this.globalPeerGroupDetails.selectedfirms.filter(
        (element) => element.name.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
      );
    } else {
      this.gridData.sourceCollection = this.selectedPeerGroupFirms.filter(
        (element) => element.name.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
      );
    }
    this.currentRecords = this.flexGrid.rows.length;
  }

  getSelectedFirms() {
    this.isDataLoading = true;
    this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/peergroup/' + this.selectedOption.peerid + '/firm/selected', '')
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          this.selectedPeerGroupFirms = result;
          if (this.currentStatus == 'Pending') {
            this.selectedPeerGroupFirms.forEach((obj) => {
              if (obj.changeDate) {
                obj.changeDate = new Date(obj.changeDate);
              } else {
                obj.changeDate = '--';
              }
            });
          }
          this.peerGroupService.setSelectedPeerGroupFirms = this.selectedPeerGroupFirms;
          this.gridData = new wjcCore.CollectionView(this.selectedPeerGroupFirms);
          this.gridData.pageSize = 10;
          if (this.isLoadedFirstTime) {
            this.totalRecords = this.gridData.totalItemCount;
            this.isLoadedFirstTime = false;
          }
          this.currentRecords = this.gridData.itemCount;
        },
        (error) => {
          this.isDataLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  // - - - - - - - - - - - - - Pending Peer Group - - - - - - - - - - - - -  - -

  gridInitialized(flexGrid, gridHeaderId: string = '') {
    flexGrid.rows.defaultSize = 56;
    flexGrid.columnHeaders.rows.defaultSize = 40;
    flexGrid.rowHeaders.columns.splice(0, 1);
    if (gridHeaderId && flexGrid) flexGrid.cells.hostElement.setAttribute('aria-labelledby', gridHeaderId);
    this.flexGrid = flexGrid;
    if (this.isTAdmin && this.currentStatus == 'Pending') flexGrid.columns.getColumn('firmId').format = 's0';
  }

  updateServiceHandler() {
    this.peerGroupService.setSelectedTab = 'Peer groups';
    this.peerGroupService.setCustomPeerGroupMode = null;
    this.peerGroupService.setselectedPeerGroup = null;
    this.peerGroupService.setIsDefaultView = true;
    this.peerGroupService.peerGroupStatusHandler();
  }

  handleButtonAction(actionfrom: string) {
    if (actionfrom == 'Disapprove') {
      this.handlePeerGroupDecision('disapproved');
    } else if (actionfrom == 'Approve') {
      this.handlePeerGroupDecision('approved');
    } else if (actionfrom == 'Cancel') {
      this.updateServiceHandler();
    } else if (actionfrom == 'Delete') {
      this.deletePeerGroup();
    } else if (actionfrom == 'Edit') {
      this.peerGroupService.setCustomPeerGroupMode = 'edit';
      this.peerGroupService.peerGroupStatusHandler();
    }
  }

  deletePeerGroup() {
    this.isDataLoading = true;
    this.service.delete(environment.FIAdminBaseEndpoint + 'v1/peergroup/', this.selectedOption.peerid).subscribe(
      (result) => {
        this.isDataLoading = false;
        let alert = [
          {
            type: 'warning',
            msg: '“' + this.selectedOption.peerlabel + '” peer group has deleted.',
            closeable: true,
          },
        ];
        this.peerGroupService.setAlerts = alert;
        this.updateServiceHandler();
      },

      (error) => {
        this.isDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  handlePeerGroupDecision(decision: string) {
    this.isDataLoading = true;
    this.service
      .post(
        environment.FIAdminBaseEndpoint + 'v1/peergroup/' + this.selectedOption.peerid + '/action/' + decision,
        null
      )
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          if (decision == 'disapproved') {
            let alert = [
              {
                type: 'warning',
                msg: 'The “' + this.selectedOption.peerlabel + '” peer group has NOT been approved.',
                closeable: true,
              },
            ];
            this.peerGroupService.setAlerts = alert;
          } else {
            let alert = [
              {
                type: 'success',
                msg: 'The “' + this.selectedOption.peerlabel + '” peer group has been approved.',
                timeout: 2500,
                closeable: true,
              },
            ];
            this.peerGroupService.setAlerts = alert;
          }
          this.updateServiceHandler();
        },

        (error) => {
          this.isDataLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }
}
