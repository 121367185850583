<div class="modal-content">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h3 id="modalSettings" class="modal-header h2">Manage Columns</h3>
  <div class="modal-body" [bentoBusyLoader]="isDataLoading">
    <fieldset class="Form-fieldset">
      <div class="form-check">
        <label for="selectedTimekeeper" class="form-check-label"
          >Customize the columns to display in your timekeeper table</label
        >
      </div>
      <div class="flex-container">
        <div class="h5 FormSet-heading" class="manageFirmInfoDiv">Firm Information</div>

        <div class="h5 FormSet-heading" class="manageFirmPeerMonitorDiv">Peer Monitor Information</div>
      </div>
      <div class="divFlex">
        <div class="h5 FormSet-heading form-fieldset--divider-right manageFirmDiv">
          <label
            class="checkbox-inline zero-paddings checkbox-label column-checkbox-container manageFirmLabel"
            for="office-ch"
          >
            <bento-checkbox
              name="office-ch"
              [(ngModel)]="manageColumn.isOfficeVisible"
              ngDefaultControl
              id="office-ch"
            ></bento-checkbox>
            Office</label
          >
          <label class="checkbox-inline zero-paddings checkbox-label column-checkbox-container" for="title-ch">
            <bento-checkbox
              name="title"
              [(ngModel)]="manageColumn.isTitleVisible"
              ngDefaultControl
              id="title-ch"
            ></bento-checkbox>
            Title</label
          >

          <label class="checkbox-inline zero-paddings checkbox-label column-checkbox-container" for="experinceYears-ch">
            <bento-checkbox
              name="experinceYears-ch"
              [(ngModel)]="manageColumn.isExpYearsVisible"
              ngDefaultControl
              id="experinceYears-ch"
            ></bento-checkbox>
            Experience years</label
          >
        </div>

        <div class="h5 FormSet-heading manageFirmPeerMonitorDiv">
          <label class="checkbox-inline zero-paddings checkbox-label column-checkbox-container" for="peerOffice-ch">
            <bento-checkbox
              [(ngModel)]="manageColumn.isPeerOfficeVisible"
              name="peerOffice-ch"
              ngDefaultControl
              id="peerOffice-ch"
            ></bento-checkbox>
            Peer Office</label
          >
          <label class="checkbox-inline zero-paddings checkbox-label column-checkbox-container" for="peerTitle-ch">
            <bento-checkbox
              name="peerTitle-ch"
              [(ngModel)]="manageColumn.isPeerTitleVisible"
              ngDefaultControl
              id="peerTitle-ch"
            ></bento-checkbox>
            Peer Title</label
          >
          <label class="checkbox-inline zero-paddings checkbox-label column-checkbox-container" for="peerPractice-ch">
            <bento-checkbox
              [(ngModel)]="manageColumn.isPeerPracticeVisible"
              name="peerPractice-ch"
              ngDefaultControl
              id="peerPractice-ch"
            ></bento-checkbox>
            Peer Practice</label
          >
        </div>
      </div>
    </fieldset>
  </div>
  <div class="modal-footer modal-footer--custom">
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss('Cancel')">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="onSave()" [disabled]="isDataLoading">Save</button>
  </div>
</div>
