import {Component, Inject, OnInit, HostBinding} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService as Auth0Service} from '@auth0/auth0-angular';
import {AuthService} from '../../../services/auth/auth.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
})
export class ServerErrorComponent implements OnInit {
  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }

  isLoading = false;
  ciamToken = '';

  constructor(
    public auth0: Auth0Service,
    private router: Router,
    private authService: AuthService,
    private service: BaseService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit(): void {
    this.ciamToken = this.authService.getCIAMToken();
    this.isLoading = true;
    if (this.authService.isUserAuthenticated()) {
      this.router.navigate(['']);
      this.isLoading = false;
    } else {
      this.auth0.isAuthenticated$.subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this.service.post(environment.FIAdminBaseEndpoint + 'v1/user/authenticate-ciam', null).subscribe(
            (result) => {
              const {jwt} = result;
              this.authService.backendJWTToken.next(jwt);
              this.router.navigate(['']);
            },
            (error) => {
              this.isLoading = false;
            }
          );
        } else {
          this.router.navigate(['']);
        }
      });
    }
  }

  signOut(): void {
    this.auth0.logout({returnTo: this.doc.location.origin});
  }
}
