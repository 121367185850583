import {Component, OnInit, ViewChild} from '@angular/core';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {StaffingRatioPeerGroupList} from 'src/app/core/models/staffingRatioPeerGroupList.model';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {BaseService} from 'src/app/core/services/base/base.service';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {environment} from 'src/environments/environment';
import * as wjcCore from '@grapecity/wijmo';
import {StaffingPeergroupService} from 'src/app/core/services/staffing-peergroup/staffing-peergroup.service';
import {Firmpeergroupstatus} from 'src/app/core/models/firmStaffingpeergroupstatus.model';
import {PeerGroupFirms} from 'src/app/core/models/firmpeergroupstatus.model';
@Component({
  selector: 'app-staffing-ratio-peer-groups',
  templateUrl: './staffing-ratio-peer-groups.component.html',
  styleUrls: ['./staffing-ratio-peer-groups.component.scss'],
})
export class StaffingRatioPeerGroupsComponent implements OnInit {
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  searchText = '';
  isDataLoading = false;
  staffingRatioPeerGroupsGridData: StaffingRatioPeerGroupList[] = [];
  staffingRatioPeerGroupsData: StaffingRatioPeerGroupList[] = [];
  gridData: wjcCore.CollectionView;
  isDefaultView = true;
  isStaffingRatioAddPeerGroupView = false;
  selectedGlobalPeerGroup: StaffingRatioPeerGroupList;
  errorMessage: any;
  alerts: BentoAlertItemOptions[] = [];
  selectedStaffingRatioPeerGroup: any = [];
  currentSurveyYear: any = '';
  isAddpeerGroupbtnDisabled = false;
  peerGroupStatus = new Firmpeergroupstatus();
  currentRecords: number = 0;
  totalRecords: number = 0;
  constructor(
    private service: BaseService,
    private authService: AuthService,
    private staffingPeerGroupService: StaffingPeergroupService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getCurrentSurveyYear();
    if (this.currentSurveyYear && this.currentSurveyYear != '') await this.getStaffingRatioPeerGroups();
    this.staffingPeerGroupService.currentMessage.subscribe((message) => {
      this.peerGroupStatus = message;
      this.alerts = this.peerGroupStatus.alerts;
    });
    setTimeout(() => {
      let element = document.getElementById('Staffing ratio');
      if (element) {
        element.classList.add('is-active');
      }
    }, 0);
    this.totalRecords = this.staffingRatioPeerGroupsGridData.length;
    this.currentRecords = this.totalRecords;
    this.staffingRatioPeerGroupsGridData = this.staffingRatioPeerGroupsData;
  }
  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (backendTokenClaims && backendTokenClaims.userDDO) {
      return backendTokenClaims.userDDO.firmID;
    }
  }
  refreshData() {
    this.gridData = new wjcCore.CollectionView(this.staffingRatioPeerGroupsGridData);
    this.gridData.trackChanges = true;
  }
  gridInitialized(flexGrid) {
    flexGrid.rows.defaultSize = 56;
    flexGrid.columnHeaders.rows.defaultSize = 40;
    this.flexGrid = flexGrid;
    this.currentRecords = this.flexGrid.rows.length;
  }

  searchStaffingRatioPeerGroups() {
    this.staffingRatioPeerGroupsGridData = this.staffingRatioPeerGroupsData.filter(
      (element) => element.peerGroupName.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
    );
    this.gridData = new wjcCore.CollectionView(this.staffingRatioPeerGroupsGridData);
    this.currentRecords = this.staffingRatioPeerGroupsGridData.length;
  }

  closeAlert(event) {}
  backToStaffingRatioPeerGroups() {
    this.getStaffingRatioPeerGroups();
  }

  getStaffingRatioPeerGroups() {
    this.isDataLoading = true;
    var isGlobal = true;
    return this.service
      .get(
        environment.FIClientBaseEndpoint +
          'v1/staffingratio/peergroup?firmId=' +
          this.getfirmId +
          '&surveyYear=' +
          this.currentSurveyYear +
          '&isGlobal=' +
          isGlobal,
        ''
      )
      .toPromise()
      .then(
        (result) => {
          this.isDataLoading = false;
          this.staffingRatioPeerGroupsGridData = result;
          this.staffingRatioPeerGroupsData = this.staffingRatioPeerGroupsGridData;
          this.totalRecords = this.staffingRatioPeerGroupsGridData.length;
          this.currentRecords = this.totalRecords;
          this.refreshData();
        },
        (error) => {
          this.isDataLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  handleAddPeerGroupAction() {
    this.staffingPeerGroupService.setCustomPeerGroupMode = 'add';
    this.staffingPeerGroupService.setIsDefaultView = false;
    this.staffingPeerGroupService.setIsShowSelectedFirms = false;
    this.staffingPeerGroupService.setIsShowAllFirms = false;
    this.staffingPeerGroupService.setSelectedPeerGroupFirms = [];
    this.staffingPeerGroupService.peerGroupStatusHandler();
  }
  getCurrentSurveyYear() {
    return this.service
      .get(environment.FIClientBaseEndpoint + 'v1/staffingratio/surveyyear/' + this.getfirmId, '')
      .toPromise()
      .then(
        (result) => {
          this.currentSurveyYear = result;
        },
        (error) => {
          this.isAddpeerGroupbtnDisabled = true;
          this.errorMessage = error.error.message;
          this.alerts.push({
            type: 'warning',
            msg: this.errorMessage,
            closeable: true,
          });
        }
      );
  }
  updateServiceWith(selectedOption: PeerGroupFirms) {
    this.staffingPeerGroupService.setIsDefaultView = false;
    this.staffingPeerGroupService.setselectedPeerGroup = selectedOption;
    this.staffingPeerGroupService.setCustomPeerGroupMode = null;
    this.staffingPeerGroupService.peerGroupStatusHandler();
  }
}
