<div class="staffing-ratio-page" [bentoBusyLoader]="isDataLoading" role="main">
  <bento-alert [items]="alerts"></bento-alert>
  <header class="Main-header">
    <h1 class="Main-heading">{{ currentSurveyYearOfFirm }} Staffing Ratio Survey</h1>
  </header>
  <div class="pading-note">
    <span
      >Thank you for participating in the {{ currentSurveyYearOfFirm }} Financial Insight Staffing Ratio Survey. Results
      are delivered in a dynamic format with two years of historical data based on selected Peer Groups with ratios
      provided for Mean, Median and Quartiles.</span
    >
  </div>
  <div *ngIf="isContentLoaded && selectedFirmSRSubscriptionInfo">
    <div class="bento-tabset tabs-top pading-note">
      <ul
        ngbNav
        #nav="ngbNav"
        class="nav nav-tabs"
        role="tablist"
        ngPreserveWhitespaces
        [animation]="false"
        (keydown)="onKeyDown($event)"
      >
        <li ngbNavItem="My Input Sheet" role="presentation" >
          <a ngbNavLink tabindex="0">My Input Sheet</a>
          <ng-template ngbNavContent>
            <app-my-input-sheet
              [selectedFirmId]="selectedFirmId"
              [selectedFirmSRSubscriptionInfo]="selectedFirmSRSubscriptionInfo"
              [currentSurveyYearOfFirm]="currentSurveyYearOfFirm"
              (handleLoaderAction)="loaderActionHandler($event)"
              (refreshSusbcriptionData)="getFirmSubscriptionInfo()"
            ></app-my-input-sheet>
          </ng-template>
        </li>
        <li ngbNavItem="My Peer Groups" role="presentation" [disabled]="!selectedFirmSRSubscriptionInfo.isPeerDisplayed">
          <a ngbNavLink tabindex="0">My Peer Groups</a>
          <ng-template ngbNavContent>
            <app-my-peer-groups
              [selectedFirmId]="selectedFirmId"
              [selectedFirmSRSubscriptionInfo]="selectedFirmSRSubscriptionInfo"
              [currentSurveyYearOfFirm]="currentSurveyYearOfFirm"
              (refreshSusbcriptionData)="getFirmSubscriptionInfo()"
            ></app-my-peer-groups>
          </ng-template>
        </li>
        <li
          ngbNavItem="My Reports" role="presentation"
          [disabled]="!selectedFirmSRSubscriptionInfo.reportReady || !selectedFirmSRSubscriptionInfo.peerSubmitted"
        >
          <a ngbNavLink tabindex="0">My Reports</a>
          <ng-template ngbNavContent>
            <app-my-reports
              [selectedFirmId]="selectedFirmId"
              [currentSurveyYearOfFirm]="currentSurveyYearOfFirm"
              [selectedFirmSRSubscriptionInfo]="selectedFirmSRSubscriptionInfo"
            ></app-my-reports>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="tab-content"></div>
    </div>
  </div>
</div>
