import {Component , OnInit, Output, ViewChild} from '@angular/core';
import {RatesReportRequest} from 'src/app/core/models/rates-report-request.model';
import {RatesReportModal} from 'src/app/core/models/rates-report.model';
import {BehaviorSubject, Observable} from 'rxjs';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {BentoComboboxOptions} from '@bento/bento-ng';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {DownloadService} from 'src/app/core/services/download/download.service';
import {HttpParams} from '@angular/common/http';
import {GlobalService} from 'src/app/core/services/global/global.service';
import {setSelectionRange} from '@grapecity/wijmo';

@Component({
  selector: 'app-rates-reports',
  templateUrl: './rates-reports.component.html',
  styleUrls: ['./rates-reports.component.scss'],
})
export class RatesReportsComponent implements OnInit {
  ratesReportData: RatesReportModal = new RatesReportModal();
  searchString: any = '';
  data: wjcCore.CollectionView;
  selectedFirm: any = {id: 'all', name: 'All'};
  currentSelectedFirm: any ={id: 'all', name: 'All'};
  selectedPeriod: any = 'all';
  isGetDataLoading = false;
  ratesReportRequest: RatesReportRequest = new RatesReportRequest();
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  // @Output() openChangeEvent = new EventEmitter<boolean>();
  firmsArr: BehaviorSubject<string[]>;
  openNewReport = false;
  reportDataList: any = [];
  alerts: BentoAlertItemOptions[] = [];
  errorMessage: any;
  lastPressedKey : any;
  comboboxOptions: BentoComboboxOptions = {
    searchable: true,
    autoSelect: true,
    searchCompare: (row, search) => {
      this.searchString = search;
      const searchLowerCase = search.toLocaleLowerCase();
      const name = `${row.name}`.toLowerCase();

      return name.indexOf(searchLowerCase) > -1;
    },
    labelFormatter: (row) => `${row.name}`,
  };

  pageInfo: any = {
    infoText: '_START_ to _END_ of _MAX_ Users',
    infoPageText: '_PAGE_ of _PAGES_',
    goText: 'Go',
    pageSize: 10,
  };
  constructor(
  private downloadFile: DownloadService,
  private _service: BaseService,
  private globalservice: GlobalService ) {
    this.getReportDataList();
    this.data = new wjcCore.CollectionView(this.reportDataList);
    this.data.pageSize = this.pageInfo.pageSize;
  }

  gridInitialized(flexGrid) {
    if (flexGrid.rows) {
      flexGrid.rows.defaultSize = 50;
      flexGrid.columnHeaders.rows.defaultSize = 40;
    }
    this.flexGrid = flexGrid;
  }

  openChangeEvent(isOpen: any) {
    if (isOpen) {
      this.currentSelectedFirm = this.selectedFirm;
      this.selectedFirm = null;
    } else {
      if (this.searchString == '') {
        if (this.selectedFirm == null) {
          //this.selectedFirm = {id: 'all', name: 'All'};
          this.selectedFirm = this.currentSelectedFirm;
        }
      }
    }
  }

  onFirmChange() {
    this.searchString = '';
    this.getReportDataList();
  }
  onPeriodChange() {
    this.searchString = '';
    this.getReportDataList();
  }

  onPageSizeChanged(size) {
    this.data.pageSize = size;
    //this.tkHistoryRecordRequest.size = size;
    this.pageInfo.pageSize = size;
  }

  // Pagination Page Changed Event Handler
  onPageChanged(page) {
    this.data.moveToPage(page - 1);
  }

  ngOnInit(): void {
    this.getRatesPeerGroupNames();
    this.getAllFirms();
  }
  getAllFirms() {
    this.isGetDataLoading = true;
    this._service.get(environment.FIAdminBaseEndpoint + 'v1/firms', '').subscribe((data) => {
      this.isGetDataLoading = false;
      this.firmsArr = new BehaviorSubject(
        (() => {
          const a = [];
          a.push({id: 'all', name: 'All'});
          for (let i = 0; i < data.firms.length; i++) {
            a.push(data.firms[i]);
          }
          return a;
        })()
      );
    });
  }
  onReportsClick() {
    this.openNewReport = !this.openNewReport;
  }

  onPeerGroupSelected(peerGroups: any[]) {
    this.ratesReportRequest.peerGroup = [];
    if (peerGroups.length == 1) {
      this.ratesReportData.selectedPeerGroupText = '1 peer group selected';
    } else if (peerGroups.length == this.ratesReportRequest.peerGroup.length) {
      this.ratesReportData.selectedPeerGroupText = 'All';
    } else this.ratesReportData.selectedPeerGroupText = peerGroups.length.toString() + ' peer groups selected';
    for (var peerGroup of peerGroups) {
      this.ratesReportRequest.peerGroup.push(peerGroup);
    }
  }

  getRatesPeerGroupNames() {
    //this.ratesReportData.peerGroup = ['London', 'Peer Monitor AU Big 8'];
    this.ratesReportData.peerGroupStream = new BehaviorSubject(
      (() => {
        const a = ['London', 'Peer Monitor AU Big 8'];
        //this.ratesReportData.peerGroup = a;
        return a;
      })()
    );
    //this.ratesReportData.peerGroup ;
    //this.ratesReportData.selectedPeerGroupText = 'All';
  }

  getReportDataList() {
    this.isGetDataLoading = true;
    var reportQueryString = '';
    if (this.selectedFirm.id != 'all' && this.selectedPeriod != 'all') {
      reportQueryString = '?firmId=' + this.selectedFirm.id + '&period=' + this.selectedPeriod;
    } else if (this.selectedFirm.id != 'all' && this.selectedPeriod == 'all') {
      reportQueryString = '?firmId=' + this.selectedFirm.id;
    } else if (this.selectedFirm.id == 'all' && this.selectedPeriod != 'all') {
      reportQueryString = '?period=' + this.selectedPeriod;
    }

    this._service.get(environment.FIAdminBaseEndpoint + 'v1/ratesReport/fileList' + reportQueryString, '').subscribe(
      (result) => {
        this.isGetDataLoading = false;
        this.reportDataList = result;
        this.data = new wjcCore.CollectionView(this.reportDataList);
        this.data.pageSize = this.pageInfo.pageSize;
        
        //added for skip 2 focus betno combobox for accessibility fix
        this.globalservice.skipFocusTwiceCombobox();

        //added for pagination additional information announcing for accessibility fix
        this.globalservice.addPaginationAdditionalInfo();
      },
      (error) => {
        this.isGetDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: this.errorMessage,
          timeout: 2500,
          closeable: true,
        });
      }
    );
  }

  reportDownload(reportName) {
    var extension = 'xlsx';
    let params = new HttpParams();
    params = params.set('blobName', reportName);
    params = params.set('fileName', reportName);

    this._service.downloadExcel(environment.FIAdminBaseEndpoint + 'v1/storage/downloadFile?' + params).subscribe(
      (response) => {
        this.downloadFile.downloadWithExtension(
          response.body,
          response.headers.get('content-disposition').split('=')[1],
          extension
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          timeout: 2500,
          closeable: true,
        });
      }
    );
  }
  handleSelectionChanged(sender: wjcGrid.FlexGrid, args: wjcGrid.CellRangeEventArgs) {
  
      let column = args.getColumn();
      if (
        column.binding === 'id' 
      ) {     
          let input = args.panel.getCellElement(args.row, args.col).querySelector('input');
          if(input!=null){
            input.focus();
            setSelectionRange(input, 0, -1);
          }
      }
  }  
  handleKeyDown(flexGrid: wjcGrid.FlexGrid, e){

    if (e.shiftKey && e.code === 'Tab') {
            this.lastPressedKey = e.code;
            //Check if last cell of teh grid if itis then focus out
            if (
              !(
                this.flexGrid.selection.row == 0 &&
                this.flexGrid.selection.col == 0
              )
            ) {
              if (this.flexGrid.selection.row == -1 && this.flexGrid.selection.col == -1) {
                this.flexGrid.select(0, 0);
              } else {
                setTimeout(() => {
                  let cell = this.getPreviousColumnToSelect();
                  this.flexGrid.select(cell.row, cell.col);                  
                },100);
              }
              e.preventDefault();
            } else {
              // this.searchButton.nativeElement.focus();
            }
    } else if (e.code === 'Tab') {
            e.preventDefault();
            this.lastPressedKey = e.code;
            //Check if last cell of teh grid if itis then focus out
            if (
              !(
                flexGrid.columns.length == flexGrid.selection.col + 1 &&
                flexGrid.rows.length == flexGrid.selection.row + 1 
                
              )
            ) {
              if (flexGrid.selection.row == -1 && flexGrid.selection.col == -1) {
                flexGrid.select(0, 0);
              } else {
                  setTimeout(() => {
                    let cell = this.getNextColumnToSelect();
                    flexGrid.select(cell.row, cell.col);                    
                  },1250);
              }
             
            } else {
              flexGrid.select(-1, -1);
            }
          }
  }
  getNextColumnToSelect() {
    let grid = this.flexGrid;
    let col = grid.selection.col + 1,
      row = grid.selection.row;

    // If last cell in a row is already selected.
    if (col >= grid.columns.length) {
      // If there are any more rows in the grid.
      if (row + 1 < grid.rows.length) row++;
      return {col: 0, row};
    }
    //
    return {col, row};
  }
  getPreviousColumnToSelect() {
    let grid = this.flexGrid;
    let col = grid.selection.col - 1,
      row = grid.selection.row;

    // If last cell in a row is already selected.
    if (col < 0) {
      // If there are any more rows in the grid.
      if (row > 0) row--;
      return {col: grid.columns.length - 1, row};
    }
    //
    return {col, row};
  }
}
