import {Injectable} from '@angular/core';
import CreateViewModel, {
  CardTitles,
  DescriptionSummary,
  FixedTimeRange,
  Metric,
  ProcessedBaseTableData,
  ProcessedMainData,
  RollingTimeRange,
  ViewDetails,
  ViewsOverlayObject,
  ViewsSettings,
} from '../../models/create-view.model';
import FirmBasicsModel from '../../models/firm-basics.model';
import PeerCheckViewColumns from '../../models/peerCheckViewColumns.model';
import {
  CreateChart,
  DateRange,
  FixedRange,
  ProcessedVisualization,
  Selection,
  VisualizationTemplate,
  VisualizationTypes,
} from '../../models/create-chart.model';
import {v4 as uuidv4} from 'uuid';
import {CurrencyPipe} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PeercheckMessageHandlerService {
  selectedViewData: any;
  private selectedVisualizationData: any;
  private selectedVisualizationType: string;
  constructor(private currencyPipe: CurrencyPipe) {}

  private isUnread = true;
  private isCustomRequest = false;
  private isCopyEditRequest = false;
  private createViewData: [] = [];
  private userSettingModel: CreateViewModel = null;
  private selectedViewId: string = null;
  private viewDetails: ViewDetails = null;
  private requestFromSharedViews = false;

  public set setIsUnreadMessage(currentStatus) {
    this.isUnread = currentStatus;
  }

  public get getIsUnreadMessage() {
    return this.isUnread;
  }

  public set setIsCustomRequest(isCustomRequest) {
    this.isCustomRequest = isCustomRequest;
  }

  public get getIsCustomRequest() {
    return this.isCustomRequest;
  }

  public set setCreateViewData(currentSelection) {
    this.createViewData = currentSelection;
  }

  public get getCreateViewData() {
    return this.createViewData;
  }

  public set setIsCopyEditRequest(isCopyEditRequest) {
    this.isCopyEditRequest = isCopyEditRequest;
  }

  public get getIsCopyEditRequest() {
    return this.isCopyEditRequest;
  }

  public set setrequestFromSharedViews(requestFromSharedViews: boolean) {
    this.requestFromSharedViews = requestFromSharedViews;
  }

  public get getrequestFromSharedViews() {
    return this.requestFromSharedViews;
  }

  public set setCreateViewUserSettings(userSettingsModel) {
    this.userSettingModel = userSettingsModel;
  }

  public get getCreateViewUserSettings() {
    return this.userSettingModel;
  }

  public set setViewDetails(viewDetails) {
    this.viewDetails = viewDetails;
  }

  public get getViewDetails() {
    return this.viewDetails;
  }

  public set setSelectedViewId(selectedViewId) {
    this.selectedViewId = selectedViewId;
  }

  public get getSelectedViewId() {
    return this.selectedViewId;
  }

  public set setSelectedViewData(selectedViewData) {
    this.selectedViewData = selectedViewData;
  }

  public get getSelectedViewData() {
    return this.selectedViewData;
  }

  private getCurrencySymbol(currency, inHtmlCode: boolean = false) {
    return this.getCurrency(currency);
  }
  private getCurrency(currency) {
    let cursymbol = this.currencyPipe.transform(0, currency, 'symbol', '1.0');

    if (cursymbol && cursymbol != null) {
      cursymbol = cursymbol.slice(0, -1);
    }
    return cursymbol;
  }

  private getFormat(format: string, currency: string, forWijmoCol: boolean = false) {
    if (!format) return;
    let precision = format.slice(format.indexOf('.') + 1);
    if (format == 'percent.1' || format.startsWith('percent')) {
      return forWijmoCol ? 'p' + precision : 'percent';
    } else if (format == 'dollar.0' || format.startsWith('dollar')) {
      let currencySymbol = this.getCurrencySymbol(currency, !forWijmoCol);
      return forWijmoCol ? 'c' + precision + currencySymbol : 'currency';
    } else if (format.startsWith('actual')) {
      return forWijmoCol ? 'g' + precision : format;
    } else {
      return forWijmoCol ? 'g*' : 'number';
    }
  }

  private numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  private getValue(
    value: any,
    format: string,
    forcePercent: boolean = false,
    metricDetails = null,
    currency = null,
    isVisulisation = false,
    isGraph = false
  ) {
    const currencyMap = {
      USD: '$',
      GBP: '£',
      AUD: 'A$',
      NZD: 'NZ$',
      CAD: 'CA$',
      EUR: '€',
    };
    if (!format) return;

    if (value == null || value == undefined) {
      return '--';
    }

    // Rule: if delta_only is true and format is not percent then need to set blank
    if (metricDetails && metricDetails.deltaOnly && format.indexOf('percent.') != 0) {
      return null;
    } else if (format.startsWith('percent')) {
      if (isVisulisation) {
        if (forcePercent && value) {
          return parseFloat((value * 100).toFixed(1)).toFixed(1) + '%';
        } else if (value) {
          return value;
        }
        return value != undefined || value != null ? value : null;
      } else if (isGraph) {
        return value ? value : null;
      } else {
        if (forcePercent && value) {
          return parseFloat((value * 100).toFixed(1)).toFixed(1) + '%';
        } else if (value) {
          return parseFloat((value * 100).toFixed(1)).toFixed(1) + '%';
        }
        return value != undefined || value != null ? value : null;
      }
    } else if (format == 'currency' || format.startsWith('dollar')) {
      if (!currency) {
        return Math.round(value);
      }
      return value != undefined || value != null
        ? currencyMap[currency] + this.numberWithCommas(Math.round(value))
        : null;
      //return Math.round(value);
    } else if (format.startsWith('actual')) {
      let precision = parseInt(format.slice(7));
      if (value != undefined || value != null) {
        return precision != null ? parseFloat(value.toFixed(precision)) : value;
      } else {
        return null;
      }
    } else {
      return value != undefined || value != null ? value : null;
    }
  }

  private getQuarter(dateString, currency) {
    // Formats a date quarter for display in a (currently line) graph, for
    // dateString looks like "2010-06-30 00:00:00";
    var yyyy = dateString.substring(0, 4);
    var m = Number(dateString.substring(5, 7));
    var date = new Date(yyyy + ' ' + m);
    var q = 0;
    switch (currency) {
      case 'AUD':
        // Fiscal year starts 6 months later, in July - [ 7, 10, 1, 4 ]
        if (m >= 7 && m <= 9) {
          q = 1;
          yyyy = Number(yyyy) + 1;
        } else if (m >= 10 && m <= 12) {
          q = 2;
          yyyy = Number(yyyy) + 1;
        } else if (m >= 1 && m <= 3) {
          q = 3;
        } else if (m >= 4 && m <= 6) {
          q = 4;
        }
        break;
      case 'GBP':
        // Fiscal year starts 4 months later, in May - [ 5, 8, 11, 2 ]
        if (m == 7) {
          q = 1;
          yyyy = Number(yyyy) + 1;
        } else if (m == 10) {
          q = 2;
          yyyy = Number(yyyy) + 1;
        } else if (m == 1) {
          q = 3;
        } else if (m == 4) {
          q = 4;
        }
        break;
      default:
        // Fiscal year for USA and Canada starts in January - [ 1, 4, 7, 10
        // ]
        if (m >= 1 && m <= 3) {
          q = 1;
        } else if (m >= 4 && m <= 6) {
          q = 2;
        } else if (m >= 7 && m <= 9) {
          q = 3;
        } else if (m >= 10 && m <= 12) {
          q = 4;
        }
        break;
    }
    return 'Q' + q + '/' + yyyy; // Year and quarter Generate like: Q1/2020
  }

  private getQuarterText(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth() / 3) + 2;
    m -= m > 4 ? 4 : 0;
    var y = d.getFullYear() + (m == 1 ? 1 : 0);
    return ['Q' + m, y];
  }

  public processDataForMainGrid(
    data: any,
    createViewsModel: CreateViewModel,
    card: CardTitles = null,
    pcViewCols: PeerCheckViewColumns = null,
    firmBasicData: FirmBasicsModel = null,
    yoyHeader: string = 'YOY growth'
  ) {
    let dataToProcess = data;
    let griddata = {};
    let finaldata = [];
    let gridHeaders = [];
    let columns = [];
    let columnCount = 0;
    let processedInfo: ProcessedMainData = new ProcessedMainData();
    let isUKORCANFirm = false;

    if (createViewsModel.isUKFirm || createViewsModel.isCANFirm) {
      isUKORCANFirm = true;
    }
    if (
      !(
        dataToProcess &&
        dataToProcess.values &&
        dataToProcess.values.length &&
        dataToProcess.values[0] &&
        dataToProcess.values[0].metrics &&
        pcViewCols
      )
    ) {
      let emptyGridObj = {
        values: [
          {
            metrics: [
              {
                metricName: null,
                firmValue: null,
                firmValueFormat: null,
                firmDelta: null,
                firmDeltaFormat: null,
              },
            ],
          },
        ],
      };
      processedInfo.hasEmptyResult = true;
      dataToProcess = emptyGridObj;
    }

    dataToProcess.values[0].metrics.forEach((obj, indx) => {
      griddata = {};
      let metricDetails = null;
      if (firmBasicData && firmBasicData.metrics) {
        metricDetails = firmBasicData.metrics.find((metric) => obj.metricId == metric.metricId);
      }
      let firmValue_format = this.getFormat(obj.firmValueFormat, dataToProcess.currency);
      griddata['currency'] = dataToProcess.currency;
      griddata['firmCurrency'] = dataToProcess.firmCurrency;
      griddata['metricId'] = obj.metricId;
      griddata['metricName'] = obj.metricName;
      griddata['metricDefinition'] = metricDetails ? metricDetails.definition : obj.metricName;
      griddata['firmValue'] = this.getValue(
        obj.firmValue,
        firmValue_format,
        false,
        metricDetails,
        dataToProcess.currency
      );
      griddata['firmValue_format'] = this.getFormat(obj.firmValueFormat, dataToProcess.currency);
      griddata['firmYOY'] = this.getValue(obj.firmDelta, obj.firmDeltaFormat, null, null, dataToProcess.currency);
      griddata['firmYOY_format'] = this.getFormat(obj.firmDeltaFormat, dataToProcess.currency);
      let peerGroupData = {};
      if (obj.hasOwnProperty('peerGroups') && obj.peerGroups.length > 0) {
        obj.peerGroups.forEach((element, index) => {
          let mean_format = this.getFormat(element.meanFormat, dataToProcess.currency);
          let median_format = this.getFormat(element.medianFormat, dataToProcess.currency);
          let meanDelta_format = this.getFormat(element.meanDeltaFormat, dataToProcess.currency);
          let medianDelta_format = this.getFormat(element.medianDeltaFormat, dataToProcess.currency);
          let meanQuartileFormat = this.getFormat(element.meanQuartileFormat, dataToProcess.currency);
          let medianQuartileFormat = this.getFormat(element.medianQuartileFormat, dataToProcess.currency);

          peerGroupData['pg' + element.peerGroupId + '_mean'] = this.getValue(
            element.mean,
            mean_format,
            false,
            metricDetails,
            dataToProcess.currency
          );
          peerGroupData['pg' + element.peerGroupId + '_mean_format'] = mean_format;
          peerGroupData['pg' + element.peerGroupId + '_median'] = this.getValue(
            element.median,
            median_format,
            false,
            metricDetails,
            dataToProcess.currency
          );
          peerGroupData['pg' + element.peerGroupId + '_median_format'] = median_format;
          peerGroupData['pg' + element.peerGroupId + '_mean_yoy'] = this.getValue(
            element.meanDelta,
            meanDelta_format,
            null,
            null,
            dataToProcess.currency
          );
          peerGroupData['pg' + element.peerGroupId + '_mean_yoy_format'] = meanDelta_format;
          peerGroupData['pg' + element.peerGroupId + '_median_yoy'] = this.getValue(
            element.medianDelta,
            medianDelta_format,
            null,
            null,
            dataToProcess.currency
          );
          peerGroupData['pg' + element.peerGroupId + '_median_yoy_format'] = medianDelta_format;
          peerGroupData['pg' + element.peerGroupId + '_mean_quartile'] = this.getValue(
            element.meanQuartile,
            meanQuartileFormat,
            null,
            null,
            dataToProcess.currency
          );
          peerGroupData['pg' + element.peerGroupId + '_mean_quartile_format'] = meanQuartileFormat;
          peerGroupData['pg' + element.peerGroupId + '_median_quartile'] = this.getValue(
            element.medianQuartile,
            medianQuartileFormat,
            null,
            null,
            dataToProcess.currency
          );
          peerGroupData['pg' + element.peerGroupId + '_median_quartile_format'] = medianQuartileFormat;

          peerGroupData['pg' + element.peerGroupId + '_mean_variance'] = this.getValue(element.meanVariance, 'percent');
          peerGroupData['pg' + element.peerGroupId + '_mean_variance_format'] = 'percent';
          peerGroupData['pg' + element.peerGroupId + '_median_variance'] = this.getValue(
            element.medianVariance,
            'percent'
          );
          peerGroupData['pg' + element.peerGroupId + '_median_variance_format'] = 'percent';

          if (indx == 0) {
            let col_mean = {
              header: 'Mean',
              binding: 'peerGroups.pg' + element.peerGroupId + '_mean',
              visible: createViewsModel.viewsSettings.statisticsType == 'Mean',
              format: 'c',
              width: 130,
              wordWrap: false,
            };
            let col_median = {
              header: 'Median',
              binding: 'peerGroups.pg' + element.peerGroupId + '_median',
              visible: createViewsModel.viewsSettings.statisticsType == 'Median',
              format: 'c',
              width: 130,
              wordWrap: false,
            };
            let col_mean_yoy = {
              header: yoyHeader,
              binding: 'peerGroups.pg' + element.peerGroupId + '_mean_yoy',
              visible:
                createViewsModel.viewsSettings.comparisonType == 'year over year' &&
                createViewsModel.viewsSettings.statisticsType == 'Mean',
              format: 'p*',
              width: 130,
              wordWrap: true,
            };
            let col_median_yoy = {
              header: yoyHeader,
              binding: 'peerGroups.pg' + element.peerGroupId + '_median_yoy',
              visible:
                createViewsModel.viewsSettings.comparisonType == 'year over year' &&
                createViewsModel.viewsSettings.statisticsType == 'Median',
              format: 'p*',
              width: 130,
              wordWrap: true,
            };
            let col_mean_Variance = {
              header: 'Variance',
              binding: 'peerGroups.pg' + element.peerGroupId + '_mean_variance',
              visible:
                createViewsModel.viewsSettings.comparisonType == 'variance' &&
                createViewsModel.viewsSettings.statisticsType == 'Mean',
              format: 'd*',
              width: 92,
              wordWrap: false,
            };
            let col_median_Variance = {
              header: 'Variance',
              binding: 'peerGroups.pg' + element.peerGroupId + '_median_variance',
              visible:
                createViewsModel.viewsSettings.comparisonType == 'variance' &&
                createViewsModel.viewsSettings.statisticsType == 'Median',
              format: 'd*',
              width: 92,
              wordWrap: false,
            };
            let col_Mean_quartile = {
              header: 'Quartile',
              binding: 'peerGroups.pg' + element.peerGroupId + '_mean_quartile',
              visible: createViewsModel.viewsSettings.statisticsType == 'Mean' && pcViewCols.showQuartile,
              format: 'd*',
              width: 92,
              wordWrap: false,
            };
            let col_Median_quartile = {
              header: 'Quartile',
              binding: 'peerGroups.pg' + element.peerGroupId + '_median_quartile',
              visible: createViewsModel.viewsSettings.statisticsType == 'Median' && pcViewCols.showQuartile,
              format: 'd*',
              width: 92,
              wordWrap: false,
            };

            let count = 0;
            gridHeaders.push(element.peerGroupName);

            columns.push(col_mean);
            count++;
            if (!isUKORCANFirm) {
              columns.push(col_median);
              count++;
            }
            columns.push(col_mean_yoy);
            count++;
            if (!isUKORCANFirm) {
              columns.push(col_median_yoy);
              count++;
            }
            if (!createViewsModel.isCANFirm) {
              columns.push(col_mean_Variance);
              count++;
            }
            if (!isUKORCANFirm) {
              columns.push(col_median_Variance);
              count++;
            }
            if (!createViewsModel.isUKFirm) {
              columns.push(col_Mean_quartile);
              columns.push(col_Median_quartile);
              count += 2;
            }

            columnCount = count;
          }
        });

        griddata['peerGroups'] = peerGroupData;
      }
      finaldata.push(griddata);
    });

    processedInfo.isCalledForCard = card ? true : false;
    processedInfo.processedData = finaldata;
    processedInfo.processedHeaders = gridHeaders;
    processedInfo.processedColumn = columns;
    processedInfo.processedColumnCount = columnCount;

    return processedInfo;
  }

  public processDataForBaseTableGrid(
    data: any,
    selectedMetric: Metric,
    card: CardTitles = null,
    groupBy: string = 'yyyymm',
    createViewsModel: CreateViewModel,
    pcViewCols: PeerCheckViewColumns = null,
    firmBasicData: FirmBasicsModel = null,
    isGraph: boolean = false
  ) {
    let processedInfo: ProcessedBaseTableData = new ProcessedBaseTableData();
    let columnsForBaseTable = [];
    let seriesForChart = [];
    let gridData = [];
    let isMeanOrMedian = createViewsModel.viewsSettings.statisticsType == 'Mean' ? true : false;
    let wjColFormat = 'g*';

    if (!data || !data.values) {
      return processedInfo;
    }

    data.values.forEach((obj, index) => {
      let option = {};
      if (index == 0) {
        let row = {
          binding: 'yyyymm',
          header: 'Rate',
          width: 400,
          visible: true,
        };
        columnsForBaseTable.push(row);
      }

      option['currency'] = data.currency;

      if (groupBy != 'yyyymm' && groupBy == 'quarter') {
        option['yyyymm'] = this.getQuarter(obj.quarter, data.currency).toString();
      } else if (groupBy == 'yyyymm') {
        let timePeriod = new Date();
        let dtAry = obj.yyyymm.replace(/(.{4})/g, '$1-').split('-');
        timePeriod.setMonth(Number(dtAry[1]) - 1, 1);
        timePeriod.setFullYear(Number(dtAry[0]));
        option['yyyymm'] = (timePeriod.getMonth() + 1 + '/' + timePeriod.getFullYear()).toString();
      }

      processedInfo.prossedDataCurrency = data.currency;

      obj.metrics.forEach((metric) => {
        if (
          (card && card.selectedMetric && metric.metricId == card.selectedMetric.id) ||
          (selectedMetric && metric.metricId == selectedMetric.id)
        ) {
          let firm_value_Format = this.getFormat(metric.firmValueFormat, data.currency);
          let firm_value = this.getValue(
            metric.firmValue,
            firm_value_Format,
            null,
            null,
            isGraph ? null : data.currency,
            null,
            isGraph
          );
          let firm_delta_value = this.getValue(
            metric.firmDelta,
            metric.firmDeltaFormat,
            null,
            null,
            isGraph ? null : data.currency,
            null,
            isGraph
          );
          option['firmValue'] = firm_value ? firm_value : 0;
          option['firmDelta'] = firm_delta_value ? firm_delta_value : 0;

          let metricDetails = null;
          let myFirm_binding = null;
          if (firmBasicData && firmBasicData.metrics) {
            metricDetails = firmBasicData.metrics.find((mtric) => mtric.metricId == metric.metricId);
          }
          if (metricDetails && metricDetails.deltaOnly && metric.firmValueFormat.indexOf('percent.') != 0) {
            myFirm_binding = 'firmDelta';
            option['firmValueFormat'] = metric.firmDeltaFormat;
            option['dtTyp'] = metric.firmDeltaFormat;
            option['chartSeriesformat'] = this.getFormat(metric.firmDeltaFormat, data.currency, true);
            processedInfo.prossedDataType = metric.firmDeltaFormat;
          } else {
            myFirm_binding = 'firmValue';
            option['firmValueFormat'] = firm_value_Format;
            option['dtTyp'] = firm_value_Format;
            option['chartSeriesformat'] = this.getFormat(metric.firmValueFormat, data.currency, true);
            processedInfo.prossedDataType = firm_value_Format;
          }
          wjColFormat = option['chartSeriesformat'];

          let row = {
            header: 'My firm',
            binding: myFirm_binding ? myFirm_binding : 'firmValue',
            format: wjColFormat,
            visible: true,
            width: 200,
          };
          let row_chart = {
            header: 'My firm',
            binding: myFirm_binding ? myFirm_binding : 'firmValue',
            format: wjColFormat,
            visible: true,
            width: 200,
            styling: {},
            symbolStyling: {'stroke-width': '1'},
          };
          if (index == 0) {
            columnsForBaseTable.push(row);
            seriesForChart.push(row_chart);
          }
          let peerGroups = {};
          metric.peerGroups.forEach((peerGroup, indx) => {
            let pg_binding = 'peerGroups.pg' + peerGroup.peerGroupId;
            let forceDelta = false;
            if (metricDetails && metricDetails.deltaOnly && metric.firmValueFormat.indexOf('percent.') != 0) {
              forceDelta = true;
            }
            let peerGroupMeanFormat = this.getFormat(peerGroup.meanFormat, data.currency);
            let peerGroupMeanDeltaFormat = this.getFormat(peerGroup.meanDeltaFormat, data.currency);
            let peerGroupMedianFormat = this.getFormat(peerGroup.medianFormat, data.currency);
            let peerGroupMedianDeltaFormat = this.getFormat(peerGroup.medianDeltaFormat, data.currency);

            let mean = this.getValue(
              peerGroup.mean,
              peerGroupMeanFormat,
              null,
              null,
              isGraph ? null : data.currency,
              null,
              isGraph
            );
            let meanDelta = this.getValue(
              peerGroup.meanDelta,
              peerGroupMeanDeltaFormat,
              null,
              null,
              isGraph ? null : data.currency,
              null,
              isGraph
            );
            let median = this.getValue(
              peerGroup.median,
              peerGroupMedianFormat,
              null,
              null,
              isGraph ? null : data.currency,
              null,
              isGraph
            );
            let medianDelta = this.getValue(
              peerGroup.medianDelta,
              peerGroupMedianDeltaFormat,
              null,
              null,
              isGraph ? null : data.currency,
              null,
              isGraph
            );

            peerGroups['pg' + peerGroup.peerGroupId + '_mean'] = mean ? mean : 0;
            peerGroups['pg' + peerGroup.peerGroupId + '_mean_format'] = peerGroupMeanFormat;
            peerGroups['pg' + peerGroup.peerGroupId + '_median'] = median ? median : 0;
            peerGroups['pg' + peerGroup.peerGroupId + '_median_format'] = peerGroupMedianFormat;

            peerGroups['pg' + peerGroup.peerGroupId + '_meanDelta'] = meanDelta ? meanDelta : 0;
            peerGroups['pg' + peerGroup.peerGroupId + '_meanDelta_format'] = peerGroupMeanDeltaFormat;
            peerGroups['pg' + peerGroup.peerGroupId + '_medianDelta'] = medianDelta ? medianDelta : 0;
            peerGroups['pg' + peerGroup.peerGroupId + '_medianDelta_format'] = peerGroupMedianDeltaFormat;

            if (index == 0) {
              let row_mean = {
                header: peerGroup.peerGroupName,
                binding: pg_binding + '_mean',
                format: wjColFormat,
                visible: isMeanOrMedian,
                width: 130,
              };
              let row_median = {
                header: peerGroup.peerGroupName,
                binding: pg_binding + '_median',
                format: wjColFormat,
                visible: !isMeanOrMedian,
                width: 130,
              };
              let row_mean_delta = {
                header: peerGroup.peerGroupName,
                binding: pg_binding + '_meanDelta',
                format: wjColFormat,
                visible: isMeanOrMedian && forceDelta,
                width: 130,
              };
              let row_median_delta = {
                header: peerGroup.peerGroupName,
                binding: pg_binding + '_medianDelta',
                format: wjColFormat,
                visible: !isMeanOrMedian && forceDelta,
                width: 130,
              };
              let row_mean_chart = {
                header: peerGroup.peerGroupName,
                binding: pg_binding + '_mean',
                visible: isMeanOrMedian,
                width: 110,
                styling: {},
                symbolStyling: {'stroke-width': '1'},
              };
              let row_median_chart = {
                header: peerGroup.peerGroupName,
                binding: pg_binding + '_median',
                visible: !isMeanOrMedian && !forceDelta,
                width: 110,
                styling: {},
                symbolStyling: {'stroke-width': '1'},
              };
              let row_mean_Delta_chart = {
                header: peerGroup.peerGroupName,
                binding: pg_binding + '_meanDelta',
                visible: isMeanOrMedian && forceDelta,
                width: 110,
                styling: {},
                symbolStyling: {'stroke-width': '1'},
              };
              let row_median_Delta_chart = {
                header: peerGroup.peerGroupName,
                binding: pg_binding + '_medianDelta',
                visible: !isMeanOrMedian && forceDelta,
                width: 110,
                styling: {},
                symbolStyling: {'stroke-width': '1'},
              };
              if (forceDelta) {
                columnsForBaseTable.push(row_mean_delta);
                columnsForBaseTable.push(row_median_delta);
                seriesForChart.push(row_mean_Delta_chart);
                seriesForChart.push(row_median_Delta_chart);
              } else {
                columnsForBaseTable.push(row_mean);
                columnsForBaseTable.push(row_median);
                seriesForChart.push(row_mean_chart);
                seriesForChart.push(row_median_chart);
              }
            }
          });
          option['peerGroups'] = peerGroups;
        }
      });
      gridData.push(option);
    });

    processedInfo.isCalledForCard = card ? true : false;
    processedInfo.processedData = gridData;
    processedInfo.processedColumn = columnsForBaseTable;
    processedInfo.processedChartSeries = seriesForChart;
    processedInfo.precessedWjColformat = wjColFormat;

    return processedInfo;
  }

  /*
   * Removes child ids from selectedList if parent is selected
   * For example,
   * input list [1, 2, -3, 23]
   * where -3 is a group with children {1, 2, 3, 4}
   * So the result should be [-3, 23]
   */
  public getExtractGroupIds(baseGroupList: any, selectionList: any, parents: number[], childrens: number[]) {
    if (!parents && !parents.length) {
      return childrens;
    } else if (!childrens && !childrens.length) {
      return parents;
    } else {
      parents.forEach((id) => {
        let parentGroup = baseGroupList.find((obj) => obj.id === Math.abs(id));
        if (parentGroup) {
          parentGroup.childrens.forEach((childId) => {
            let indx = selectionList.indexOf(childId);
            if (indx != -1) {
              selectionList.splice(indx, 1);
            }
          });
        }
      });
      return selectionList;
    }
  }

  public getSelectedOptionsByGroupIds(baseGroupList: any, selectionList: any, allowedValues: any, showParent: boolean) {
    let finalGroups: any[] = [];
    let nonParentGroup = selectionList.filter((item: any) => item.id >= 0);
    let parentGroups: any[] = [...new Set(selectionList.map((item) => item.id))].filter((id: number) => id < 0);
    if (parentGroups && parentGroups.length) {
      parentGroups.forEach((parentId) => {
        let parentGroup = baseGroupList.find((obj) => obj.id === Math.abs(parentId));
        if (parentGroup && parentGroup.showDetails) {
          allowedValues.forEach((valueId) => {
            if (parentGroup.childrens.includes(valueId.id)) {
              let exist = finalGroups.find((group) => group.id == valueId.id);
              if (!exist) finalGroups.push(valueId);
            }
          });
        }
        let otherGroups = nonParentGroup.filter((o1) => !finalGroups.some((o2) => o1.id === o2.id));
        if (
          parentGroup &&
          (!parentGroup.showDetails || parentGroups.length > 1 || otherGroups.length > 0 || showParent)
        ) {
          let exist = selectionList.find((selectedGroup) => selectedGroup.id == parentId);
          if (exist) finalGroups.push(exist);
        }
      });
      finalGroups = finalGroups.concat(nonParentGroup);
      return finalGroups;
    } else {
      return selectionList;
    }
  }

  public createRequestBody(
    userSettingViewsModel: CreateViewModel,
    firmBasicData: any,
    type: string,
    isMetricSelection: boolean = false,
    selectedMetric: Metric = null,
    card: CardTitles = null,
    shouldCheckMetricGroup: boolean = true
  ) {
    let requestbody = {};
    requestbody['quid'] = uuidv4();
    if (
      firmBasicData &&
      ((userSettingViewsModel.timeRangeSelected == 'Rolling' && userSettingViewsModel.rollingTimeRangeSelectedItems) ||
        (userSettingViewsModel.timeRangeSelected == 'Fixed' &&
          userSettingViewsModel.fixedTimeRange.startDateText &&
          userSettingViewsModel.fixedTimeRange.endDateText) ||
        userSettingViewsModel.timeRangeSelected == 'Year To Date(YTD)') &&
      userSettingViewsModel.metricSelectedItems.length > 0 &&
      userSettingViewsModel.peerGroupSelectedItems.length > 0
    ) {
      //Add Metric
      let metrics = [];
      let result = shouldCheckMetricGroup
        ? userSettingViewsModel.metricSelectedItems.filter((item) => item.groupId != null)
        : userSettingViewsModel.metricSelectedItems;
      if (isMetricSelection && selectedMetric && !card) {
        metrics.push(selectedMetric.id);
      } else if (isMetricSelection && card && card.selectedMetric) {
        metrics.push(card.selectedMetric.id);
      } else {
        for (let i = 0; i < result.length; i++) {
          metrics.push(result[i].id);
        }
      }
      requestbody['metrics'] = metrics;

      let sDateText: string = null;
      let eDateText: string = null;
      //Time Range
      if (userSettingViewsModel.timeRangeSelected == 'Rolling') {
        sDateText = userSettingViewsModel.rollingTimeRangeSelectedItems.startPeriod1;
        eDateText = userSettingViewsModel.rollingTimeRangeSelectedItems.endPeriod1;
        requestbody['startPeriod'] = userSettingViewsModel.rollingTimeRangeSelectedItems.startPeriod1;
        requestbody['endPeriod'] = userSettingViewsModel.rollingTimeRangeSelectedItems.endPeriod1;
      } else if (userSettingViewsModel.timeRangeSelected == 'Year To Date(YTD)') {
        const startDate = new Date();
        const endDate = new Date();
        const year = firmBasicData.yyyymm.toString().slice(0, 4);
        const month = firmBasicData.yyyymm.toString().slice(-2);
        endDate.setDate(1);
        startDate.setDate(1);
        endDate.setFullYear(parseInt(year));
        endDate.setMonth(parseInt(month) - 1);
        startDate.setFullYear(parseInt(year));
        sDateText = startDate.getFullYear() + '01';
        eDateText = endDate.getFullYear() + ('0' + (endDate.getMonth() + 1)).slice(-2);
        requestbody['startPeriod'] = startDate.getFullYear() + '01';
        requestbody['endPeriod'] = endDate.getFullYear() + ('0' + (endDate.getMonth() + 1)).slice(-2);
      } else {
        sDateText = userSettingViewsModel.fixedTimeRange.startPeriod1;
        eDateText = userSettingViewsModel.fixedTimeRange.endPeriod1;
        requestbody['startPeriod'] = userSettingViewsModel.fixedTimeRange.startPeriod1;
        requestbody['endPeriod'] = userSettingViewsModel.fixedTimeRange.endPeriod1;
      }

      if (userSettingViewsModel.peerGroupSelectedItems) {
        let peerGroups: number[] = [...new Set(userSettingViewsModel.peerGroupSelectedItems.map((item) => item.id))];
        requestbody['peerGroups'] = peerGroups;
      } else {
        requestbody['peerGroups'] = [];
      }

      if (card) {
        if ('practice' in card && card.practice && card.practice.id) {
          let practices: number[] = [];
          practices.push(card.practice.id);
          requestbody['practices'] = practices;
        } else {
          requestbody['practices'] = [];
        }

        if ('office' in card && card.office && card.office.id) {
          let offices: number[] = [];
          offices.push(card.office.id);
          requestbody['offices'] = offices;
        } else {
          requestbody['offices'] = [];
        }

        if ('title' in card && card.title && card.title.id) {
          let titles: number[] = [];
          titles.push(card.title.id);
          requestbody['titles'] = titles;
        } else {
          requestbody['titles'] = [];
        }

        let experienceYears = {};

        if ('partnerYears' in card && card.partnerYears && card.partnerYears.id) {
          let partnerYears: number[] = [];
          partnerYears.push(card.partnerYears.id);
          experienceYears['partnerYears'] = partnerYears;
        } else {
          experienceYears['partnerYears'] = [];
        }

        if ('srAssociateYears' in card && card.srAssociateYears && card.srAssociateYears.id) {
          let srAssociateYears: number[] = [];
          srAssociateYears.push(card.srAssociateYears.id);
          experienceYears['srAssociateYears'] = srAssociateYears;
        } else {
          experienceYears['srAssociateYears'] = [];
        }

        if ('associateYears' in card && card.associateYears && card.associateYears.id) {
          let associateYears: number[] = [];
          associateYears.push(card.associateYears.id);
          experienceYears['associateYears'] = associateYears;
        } else {
          experienceYears['associateYears'] = [];
        }
        requestbody['experienceYears'] = experienceYears;
      } else {
        if (userSettingViewsModel.practiceGroupSelectedItems) {
          let practices: number[] = [
            ...new Set(userSettingViewsModel.practiceGroupSelectedItems.map((item) => item.id)),
          ];
          requestbody['practices'] = practices;
        } else {
          requestbody['practices'] = [];
        }

        if (userSettingViewsModel.officeSelectedItems) {
          let offices: number[] = [...new Set(userSettingViewsModel.officeSelectedItems.map((item) => item.id))];
          let parents: number[] = offices.filter((id) => id < 0);
          let child: number[] = offices.filter((id) => id >= 0);
          offices = this.getExtractGroupIds(firmBasicData.officeGroups, offices, parents, child);
          requestbody['offices'] = offices;
        } else {
          requestbody['offices'] = [];
        }

        if (userSettingViewsModel.titleSelectedItems) {
          let titles: number[] = [...new Set(userSettingViewsModel.titleSelectedItems.map((item) => item.id))];
          let parents: number[] = titles.filter((id) => id < 0);
          let child: number[] = titles.filter((id) => id >= 0);
          titles = this.getExtractGroupIds(firmBasicData.titleGroups, titles, parents, child);
          requestbody['titles'] = titles;
        } else {
          requestbody['titles'] = [];
        }

        let experienceYears = {};

        if (userSettingViewsModel.partnerYearSelectedItems) {
          let partnerYears: number[] = [
            ...new Set(userSettingViewsModel.partnerYearSelectedItems.map((item) => item.id)),
          ];
          experienceYears['partnerYears'] = partnerYears;
        } else {
          experienceYears['partnerYears'] = [];
        }

        if (userSettingViewsModel.srAssociateYearSelectedItems) {
          let srAssociateYears: number[] = [
            ...new Set(userSettingViewsModel.srAssociateYearSelectedItems.map((item) => item.id)),
          ];
          experienceYears['srAssociateYears'] = srAssociateYears;
        } else {
          experienceYears['srAssociateYears'] = [];
        }

        if (userSettingViewsModel.srAssociateYearSelectedItems) {
          let associateYears: number[] = [
            ...new Set(userSettingViewsModel.associateYearSelectedItems.map((item) => item.id)),
          ];
          experienceYears['associateYears'] = associateYears;
        } else {
          experienceYears['associateYears'] = [];
        }
        requestbody['experienceYears'] = experienceYears;
      }

      // Dependency data from Basic details
      requestbody['yyyymm'] = firmBasicData.yyyymm;
      requestbody['currency'] = firmBasicData.currency;
      requestbody['firmCurrency'] = firmBasicData.firmCurrency;
      requestbody['defaultAccess'] = firmBasicData.defaultAccess;

      // add query parameter for Matter type
      requestbody['matterType'] = userSettingViewsModel.viewsSettings.matterType;

      // add query parameter for Contractor
      requestbody['contractors'] = userSettingViewsModel.viewsSettings.contractorsType;

      // add query parameter for Currency
      requestbody['currencyType'] = userSettingViewsModel.viewsSettings.currencyType;

      requestbody['log'] = true;

      // if (type == 'pivot') requestbody['groupBy'] = ['practice'];

      requestbody['type'] = type;

      // Add query for Grid or Line
      if (type == 'line') {
        let diffOfYears = this.diffOfYears(eDateText, sDateText);

        if (diffOfYears > 1) {
          let groupBy = [];
          groupBy.push('quarter');
          requestbody['groupBy'] = groupBy;
          requestbody['overFullQuarter'] = true;
        } else {
          let groupBy = [];
          groupBy.push('yyyymm');
          requestbody['groupBy'] = groupBy;
        }
      }
      requestbody['type'] = type;

      return requestbody;
    } else {
      return null;
    }
  }

  public diffOfYears(sDateText, eDateText) {
    if (!sDateText && eDateText) return;

    let start_Date = new Date();
    let end_Date = new Date();
    if (sDateText && sDateText.length == 6 && eDateText && eDateText.length == 6) {
      let start_year: number = parseInt(sDateText.slice(0, 4));
      let start_month: number = parseInt(sDateText.slice(4, 6));
      if (start_Date.getDate() > 28) {
        start_Date.setDate(28);
      }
      start_Date.setFullYear(start_year, start_month - 1);

      let end_year: number = parseInt(eDateText.slice(0, 4));
      let end_month: number = parseInt(eDateText.slice(4, 6));
      if (end_Date.getDate() > 28) {
        end_Date.setDate(28);
      }
      end_Date.setFullYear(end_year, end_month - 1);
      var months;
      months = (start_Date.getFullYear() - end_Date.getFullYear()) * 12;
      months -= end_Date.getMonth();
      months += start_Date.getMonth() + 1;
      var years = months / 12;
      return years;
    }
  }

  public getMonthForTimeRange(value: string) {
    if (value == 'Past month') return 0;
    else if (value == 'Past 3 months') return 3;
    else if (value == 'Past 6 months') return 6;
    else if (value == 'Past 9 months') return 9;
    else if (value == 'Past 12 months') return 12;
    else if (value == 'Past 24 months') return 24;
    else if (value == 'Past 36 months') return 36;
    else if (value == 'Year to date') return 1;
  }

  getPeriod(
    userSettingViewsModel: any,
    currentDate,
    subtractMonth,
    isRolling: boolean,
    startDateArr,
    endDateArr,
    rangeFormat: string = 'MMYYYY'
  ) {
    let stDate = currentDate;
    if (!stDate) {
      stDate = new Date();
    }
    let periods = [];
    if (isRolling) {
      if (subtractMonth == 1) {
        const curDate = new Date();
        periods.push(curDate.getFullYear().toString() + ('0' + (curDate.getMonth() + 1)).toString().slice(-2));
        curDate.setMonth(0);
        periods.push(curDate.getFullYear().toString() + ('0' + (curDate.getMonth() + 1)).toString().slice(-2));
      } else {
        let monthTOSub = subtractMonth == 0 ? subtractMonth : subtractMonth - 1;
        periods.push(stDate.getFullYear().toString() + ('0' + (stDate.getMonth() + 1)).toString().slice(-2));
        stDate.setMonth(stDate.getMonth() - monthTOSub);
        periods.push(stDate.getFullYear().toString() + ('0' + (stDate.getMonth() + 1)).toString().slice(-2));
      }
    } else {
      if (rangeFormat == 'YYYYMM') {
        periods.push(startDateArr[1] + startDateArr[2]);
        periods.push(endDateArr[1] + endDateArr[2]);
      } else if (rangeFormat == 'MMYYYY') {
        periods.push(startDateArr[2] + startDateArr[1]);
        periods.push(endDateArr[2] + endDateArr[1]);
      }
    }
    return periods;
  }

  getCalculatedTimeRange(startDateArr, endDateArr, rangeFormat = 'MMYYYY') {
    let stDate = new Date();
    let endDate = new Date();

    stDate.setDate(1);
    stDate.setFullYear(parseInt(startDateArr[2]));
    stDate.setMonth(parseInt(startDateArr[1]) - 1);

    endDate.setDate(1);
    endDate.setFullYear(parseInt(endDateArr[2]));
    endDate.setMonth(parseInt(endDateArr[1]) - 1);

    let periods = [];
    if (rangeFormat == 'YYYYMM') {
      stDate.setFullYear(stDate.getFullYear() - 1);
      endDate.setFullYear(endDate.getFullYear() - 1);
      periods.push(stDate.getFullYear().toString() + '/' + ('0' + (stDate.getMonth() + 1)).toString().slice(-2));
      periods.push(endDate.getFullYear().toString() + '/' + ('0' + (endDate.getMonth() + 1)).toString().slice(-2));
    } else if (rangeFormat == 'MMYYYY') {
      stDate.setFullYear(stDate.getFullYear() - 1);
      endDate.setFullYear(endDate.getFullYear() - 1);
      periods.push(('0' + (stDate.getMonth() + 1)).toString().slice(-2) + '/' + stDate.getFullYear().toString());
      periods.push(('0' + (endDate.getMonth() + 1)).toString().slice(-2) + '/' + endDate.getFullYear().toString());
      // periods.push(startDateArr[2] + startDateArr[1]);
      // periods.push(endDateArr[2] + endDateArr[1]);
    }
    return periods;
  }

  preSelectDataforTimeRangeAndSettings(viewDetails: ViewDetails, userSettingsModel: CreateViewModel) {
    if (this.viewDetails && this.viewDetails.rollingFormat && this.viewDetails.rollingFormat.id == 9) {
      userSettingsModel.isRollingTimeRange = false;
      userSettingsModel.timeRangeSelected = 'Fixed';
      let sdate = new Date(viewDetails.viewBeginDate);
      let edate = new Date(viewDetails.viewEndDate);
      viewDetails.fixedTimeRange = new FixedTimeRange();
      let sDateMonth =
        (sdate.getMonth() + 1).toString().length == 1
          ? '0' + (sdate.getMonth() + 1)
          : (sdate.getMonth() + 1).toString();
      let eDateMonth =
        (edate.getMonth() + 1).toString().length == 1
          ? '0' + (edate.getMonth() + 1)
          : (edate.getMonth() + 1).toString();
      viewDetails.fixedTimeRange.startDateText = sDateMonth + '/' + sdate.getFullYear();
      viewDetails.fixedTimeRange.startDateValue = sdate.getFullYear().toString() + sDateMonth;
      viewDetails.fixedTimeRange.startPeriod1 = sdate.getFullYear().toString() + sDateMonth;

      viewDetails.fixedTimeRange.endDateText = eDateMonth + '/' + edate.getFullYear();
      viewDetails.fixedTimeRange.endDateValue = edate.getFullYear().toString() + eDateMonth;
      viewDetails.fixedTimeRange.endPeriod1 = edate.getFullYear().toString() + eDateMonth;
      userSettingsModel.fixedTimeRange = viewDetails.fixedTimeRange;
    }

    if (this.viewDetails && this.viewDetails.rollingFormat && this.viewDetails.rollingFormat.id == 0) {
      userSettingsModel.isRollingTimeRange = true;
      userSettingsModel.timeRangeSelected = 'Year To Date(YTD)';
    }

    userSettingsModel.viewsSettings = new ViewsSettings();
    if (viewDetails.statisticSetting) {
      userSettingsModel.viewsSettings.statisticsType = viewDetails.statisticSetting == 'MEDIAN' ? 'Median' : 'Mean';
    }
    if (viewDetails.currencySetting) {
      userSettingsModel.viewsSettings.currencyType = viewDetails.currencySetting.toLowerCase();
    }
    if (viewDetails.matterTypeSetting) {
      userSettingsModel.viewsSettings.matterType = viewDetails.matterTypeSetting.toLowerCase();
    }
    if (viewDetails.contractorsSetting) {
      userSettingsModel.viewsSettings.contractorsType = viewDetails.contractorsSetting.toLowerCase();
    }
    if (viewDetails.comparisionSetting) {
      if (viewDetails.comparisionSetting.toLowerCase() == 'yoy') {
        userSettingsModel.viewsSettings.comparisonType = 'year over year';
      } else {
        userSettingsModel.viewsSettings.comparisonType = viewDetails.comparisionSetting.toLowerCase();
      }
    }

    return userSettingsModel;
  }

  getServiceErrorMessageString(errorMessage: any) {
    let errMessage = 'Something went wrong, please try again.';
    if (errorMessage && errorMessage.status == 400) {
      if (errorMessage.messages) {
        errMessage = '';
        errorMessage.messages.forEach((field) => {
          errMessage += field.message + '\n';
        });
      } else {
        errMessage = errorMessage.message;
      }
    }

    return errMessage;
  }

  formatDateString(viewBeginDate, viewEndDate) {
    let sDt = new Date(viewBeginDate);
    let eDt = new Date(viewEndDate);

    let startDate = ('0' + (sDt.getMonth() + 1)).toString().slice(-2) + '/' + sDt.getFullYear();
    let endDate = ('0' + (eDt.getMonth() + 1)).toString().slice(-2) + '/' + eDt.getFullYear();
    let dateString = startDate + ' through ' + endDate;
    return dateString;
  }

  getCommaSeparatedString(inputArray: Array<{id: number; name: string}>, includeAnd: boolean) {
    let string = '';
    if (inputArray && inputArray.length > 0) {
      inputArray.forEach((item, index) => {
        if (inputArray.length === 1) {
          string = item.name;
        } else if (includeAnd && inputArray.length === index + 1 && index > 1) {
          string = string.replace(/,\s*$/, '');
          string = string + ', and ' + item.name;
        } else {
          string = string + item.name + ', ';
        }
      });
      string = string.replace(/,\s*$/, '');
      return string;
    }
    return '';
  }

  getCustomErrors(userSettingViewsModel) {
    let errors: string[] = [];
    if (
      !(
        (userSettingViewsModel.timeRangeSelected == 'Rolling' && userSettingViewsModel.rollingTimeRangeSelectedItems) ||
        (userSettingViewsModel.timeRangeSelected == 'Fixed' &&
          userSettingViewsModel.fixedTimeRange.startDateText &&
          userSettingViewsModel.fixedTimeRange.endDateText) ||
        userSettingViewsModel.timeRangeSelected == 'Year To Date(YTD)'
      )
    ) {
      errors.push('Start Period or End Period is not valid.');
    }

    if (!(userSettingViewsModel.metricSelectedItems && userSettingViewsModel.metricSelectedItems.length)) {
      errors.push('Metric is not valid.');
    }

    if (!(userSettingViewsModel.peerGroupSelectedItems && userSettingViewsModel.peerGroupSelectedItems.length)) {
      errors.push('Peer Group is not valid.');
    }

    return errors;
  }

  processSelectedViewDetails(viewDetails: ViewDetails, userSettingsModel: CreateViewModel, yyyymm) {
    if (viewDetails) {
      if (viewDetails.rollingFormat && viewDetails.rollingFormat.id != 9 && viewDetails.rollingFormat.id != 0) {
        userSettingsModel.isRollingTimeRange = true;
        userSettingsModel.timeRangeSelected = 'Rolling';
        let periods = [];
        let currentDate = new Date();
        if (yyyymm) {
          let yyyymm_copy = yyyymm.toString();
          let yyyymmAry = yyyymm_copy.replace(/(.{4})/g, '$1-').split('-');
          currentDate.setFullYear(Number(yyyymmAry[0]));
          currentDate.setDate(1);
          currentDate.setMonth(Number(yyyymmAry[1]) - 1);
        }
        periods = this.getPeriod(
          userSettingsModel,
          currentDate,
          this.getMonthForTimeRange(viewDetails.rollingFormat.name),
          true,
          null,
          null
        );

        let selectedTimeRange: RollingTimeRange = new RollingTimeRange();
        selectedTimeRange.id = viewDetails.rollingFormat.id.toString();
        selectedTimeRange.name = viewDetails.rollingFormat.name;
        selectedTimeRange.startPeriod1 = periods[1];
        selectedTimeRange.endPeriod1 = periods[0];

        userSettingsModel.rollingTimeRangeSelectedItems = selectedTimeRange;
      } else if (viewDetails.rollingFormat && viewDetails.rollingFormat.id == 9) {
        userSettingsModel.isRollingTimeRange = false;
        userSettingsModel.timeRangeSelected = 'Fixed';
        let sdate = new Date(viewDetails.viewBeginDate);
        let edate = new Date(viewDetails.viewEndDate);
        viewDetails.fixedTimeRange = new FixedTimeRange();
        let sDateMonth =
          (sdate.getMonth() + 1).toString().length == 1
            ? '0' + (sdate.getMonth() + 1)
            : (sdate.getMonth() + 1).toString();
        let eDateMonth =
          (edate.getMonth() + 1).toString().length == 1
            ? '0' + (edate.getMonth() + 1)
            : (edate.getMonth() + 1).toString();
        viewDetails.fixedTimeRange.startDateText = sDateMonth + '/' + sdate.getFullYear();
        viewDetails.fixedTimeRange.startDateValue = sdate.getFullYear().toString() + sDateMonth;
        viewDetails.fixedTimeRange.startPeriod1 = sdate.getFullYear().toString() + sDateMonth;

        viewDetails.fixedTimeRange.endDateText = eDateMonth + '/' + edate.getFullYear();
        viewDetails.fixedTimeRange.endDateValue = edate.getFullYear().toString() + eDateMonth;
        viewDetails.fixedTimeRange.endPeriod1 = edate.getFullYear().toString() + eDateMonth;
        userSettingsModel.fixedTimeRange = viewDetails.fixedTimeRange;
      } else if (viewDetails.rollingFormat && viewDetails.rollingFormat.id == 0) {
        userSettingsModel.isRollingTimeRange = false;
        userSettingsModel.timeRangeSelected = 'Year To Date(YTD)';
        let sdate = new Date(viewDetails.viewBeginDate);
        let edate = new Date(viewDetails.viewEndDate);
        viewDetails.fixedTimeRange = new FixedTimeRange();
        let sDateMonth =
          (sdate.getMonth() + 1).toString().length == 1
            ? '0' + (sdate.getMonth() + 1)
            : (sdate.getMonth() + 1).toString();
        let eDateMonth =
          (edate.getMonth() + 1).toString().length == 1
            ? '0' + (edate.getMonth() + 1)
            : (edate.getMonth() + 1).toString();
        viewDetails.fixedTimeRange.startDateText = sDateMonth + '/' + sdate.getFullYear();
        viewDetails.fixedTimeRange.startDateValue = sdate.getFullYear().toString() + sDateMonth;
        viewDetails.fixedTimeRange.startPeriod1 = sdate.getFullYear().toString() + sDateMonth;

        viewDetails.fixedTimeRange.endDateText = eDateMonth + '/' + edate.getFullYear();
        viewDetails.fixedTimeRange.endDateValue = edate.getFullYear().toString() + eDateMonth;
        viewDetails.fixedTimeRange.endPeriod1 = edate.getFullYear().toString() + eDateMonth;
        userSettingsModel.fixedTimeRange = viewDetails.fixedTimeRange;
      }

      userSettingsModel.metricSelectedItems = [];
      if (viewDetails.metrics) {
        viewDetails.metrics.forEach((metric) => {
          let selectedMetric = new ViewsOverlayObject();
          selectedMetric.id = metric.id;
          selectedMetric.name = metric.name;
          userSettingsModel.metricSelectedItems.push(selectedMetric);
        });
      }

      userSettingsModel.peerGroupSelectedItems = [];
      if (viewDetails.peerGroups) {
        viewDetails.peerGroups.forEach((peergroup) => {
          let selectedPeerGroup = new ViewsOverlayObject();
          selectedPeerGroup.id = peergroup.id;
          selectedPeerGroup.name = peergroup.name;
          selectedPeerGroup.isGlobal = peergroup.isGlobal;
          selectedPeerGroup.isPpg = peergroup.isPpg;
          userSettingsModel.peerGroupSelectedItems.push(selectedPeerGroup);
        });
      }

      userSettingsModel.practiceGroupSelectedItems = [];
      if (viewDetails.practices) {
        viewDetails.practices.forEach((practice) => {
          let selectedPractice = new ViewsOverlayObject();
          selectedPractice.id = practice.id;
          selectedPractice.name = practice.name;
          userSettingsModel.practiceGroupSelectedItems.push(selectedPractice);
        });
      }

      userSettingsModel.officeSelectedItems = [];
      if (viewDetails.offices) {
        viewDetails.offices.forEach((office) => {
          let selectedOffice = new ViewsOverlayObject();
          selectedOffice.id = office.id;
          selectedOffice.name = office.name;
          userSettingsModel.officeSelectedItems.push(selectedOffice);
        });
      }

      userSettingsModel.titleSelectedItems = [];
      if (viewDetails.titles) {
        viewDetails.titles.forEach((title) => {
          let selectedTitle = new ViewsOverlayObject();
          selectedTitle.id = title.id;
          selectedTitle.name = title.name;
          userSettingsModel.titleSelectedItems.push(selectedTitle);
        });
      }

      userSettingsModel.partnerYearSelectedItems = [];
      if (viewDetails.partnerExpYears) {
        viewDetails.partnerExpYears.forEach((partnerExpYear) => {
          let selectedPartnerExpYear = new ViewsOverlayObject();
          selectedPartnerExpYear.id = partnerExpYear.id;
          selectedPartnerExpYear.name = partnerExpYear.name;
          userSettingsModel.partnerYearSelectedItems.push(selectedPartnerExpYear);
        });
      }

      userSettingsModel.associateYearSelectedItems = [];
      if (viewDetails.associateExpYears) {
        viewDetails.associateExpYears.forEach((associateExpYear) => {
          let selectedAssociateExpYear = new ViewsOverlayObject();
          selectedAssociateExpYear.id = associateExpYear.id;
          selectedAssociateExpYear.name = associateExpYear.name;
          userSettingsModel.associateYearSelectedItems.push(selectedAssociateExpYear);
        });
      }

      userSettingsModel.srAssociateYearSelectedItems = [];
      if (viewDetails.srAssociateExpYears) {
        viewDetails.srAssociateExpYears.forEach((srAssociateExpYear) => {
          let selectedSrAssociateExpYear = new ViewsOverlayObject();
          selectedSrAssociateExpYear.id = srAssociateExpYear.id;
          selectedSrAssociateExpYear.name = srAssociateExpYear.name;
          userSettingsModel.srAssociateYearSelectedItems.push(selectedSrAssociateExpYear);
        });
      }

      userSettingsModel.viewsSettings = new ViewsSettings();
      if (viewDetails.statisticSetting) {
        userSettingsModel.viewsSettings.statisticsType = viewDetails.statisticSetting == 'MEDIAN' ? 'Median' : 'Mean';
      }
      if (viewDetails.currencySetting) {
        userSettingsModel.viewsSettings.currencyType = viewDetails.currencySetting.toLowerCase();
      }
      if (viewDetails.matterTypeSetting) {
        userSettingsModel.viewsSettings.matterType = viewDetails.matterTypeSetting.toLowerCase();
      }
      if (viewDetails.contractorsSetting) {
        userSettingsModel.viewsSettings.contractorsType = viewDetails.contractorsSetting.toLowerCase();
      }
      if (viewDetails.comparisionSetting) {
        if (viewDetails.comparisionSetting.toLowerCase() == 'yoy') {
          userSettingsModel.viewsSettings.comparisonType = 'year over year';
        } else {
          userSettingsModel.viewsSettings.comparisonType = viewDetails.comparisionSetting.toLowerCase();
        }
      }

      let summary = new DescriptionSummary();
      if (viewDetails.rollingFormat) summary.timeRange = viewDetails.rollingFormat.name;
      else {
        summary.timeRange = this.formatDateString(viewDetails.viewBeginDate, viewDetails.viewEndDate);
      }
      summary.metrics = this.getCommaSeparatedString(viewDetails.metrics, true);
      summary.peerGroups = this.getCommaSeparatedString(viewDetails.peerGroups, true);
      summary.practices = this.getCommaSeparatedString(viewDetails.practices, true);
      summary.offices = this.getCommaSeparatedString(viewDetails.offices, true);
      summary.titles = this.getCommaSeparatedString(viewDetails.titles, true);
      summary.associateExp = this.getCommaSeparatedString(viewDetails.associateExpYears, true);
      summary.srAssociateExp = this.getCommaSeparatedString(viewDetails.srAssociateExpYears, true);
      summary.partnerYearsExp = this.getCommaSeparatedString(viewDetails.partnerExpYears, true);
    }
    return userSettingsModel;
  }

  /**
   * Visualization Related Processing code
   */

  public set setSelectedVisualizationData(selectedVisualizationData) {
    this.selectedVisualizationData = selectedVisualizationData;
  }

  public get getSelectedVisualizationData() {
    return this.selectedVisualizationData;
  }

  public set setSelectedVisualizationType(visualizationType) {
    this.selectedVisualizationType = visualizationType;
  }

  public get getSelectedVisualizationType() {
    return this.selectedVisualizationType;
  }

  public processDataForVisualizationType(
    vizType: string,
    data: any,
    visualizationSelectionModel: CreateChart,
    groupBy: string = 'yyyymm',
    pcViewCols: PeerCheckViewColumns = null,
    firmBasicData: FirmBasicsModel = null
  ) {
    let processedInfo: ProcessedVisualization = new ProcessedVisualization();
    let columnsForBaseTable = [];
    let seriesForChart = [];
    let gridData = [];
    let isMeanOrMedian = visualizationSelectionModel.viewsSettings.statisticsType == 'Mean' ? true : false;
    let isYoyOrVariance = visualizationSelectionModel.viewsSettings.comparisonType == 'year over year' ? true : false;
    let wjColFormat = 'g*';

    if (!data || !data.values) {
      processedInfo.hasEmptyResult = true;
      return processedInfo;
    }
    let simplyfiedData = this.getSimplifiedData(vizType, data, isMeanOrMedian, isYoyOrVariance);

    data.values.forEach((obj, index) => {
      if (index == 0) {
        let row = {
          binding: 'bindingX',
          header: 'Firm/Peer group',
          width: 250,
          visible: true,
        };
        columnsForBaseTable.push(row);
      }
      processedInfo.prossedDataCurrency = data.currency;
      let optionForDPA = {};
      if (vizType == VisualizationTypes.DPA) optionForDPA['bindingX'] = obj.practiceName;

      obj.metrics.forEach((metric, metricindex) => {
        let option = {};
        let firm_delta_value = this.getValue(metric.firmDelta, metric.firmDeltaFormat, null, null, null, true);
        if (vizType == VisualizationTypes.KPMV1) {
          option['bindingX'] = metric.metricName;
          option['firmDelta'] = firm_delta_value ? firm_delta_value : 0;
          option['firmDeltaFormat'] = metric.firmDeltaFormat;
          option['chartSeriesformat'] = this.getFormat(metric.firmDeltaFormat, data.currency, true);
          wjColFormat = option['chartSeriesformat'];
        } else if (vizType == VisualizationTypes.DPA) {
          optionForDPA['firmDelta'] = firm_delta_value ? firm_delta_value : 0;
          optionForDPA['firmDeltaFormat'] = metric.firmDeltaFormat;
          optionForDPA['chartSeriesformat'] = this.getFormat(metric.firmDeltaFormat, data.currency, true);
          wjColFormat = optionForDPA['chartSeriesformat'];
        }

        let metricDetails = null;
        let myFirm_binding = null;
        if (firmBasicData && firmBasicData.metrics) {
          metricDetails = firmBasicData.metrics.find((mtric) => mtric.metricId == metric.metricId);
        }
        myFirm_binding = 'firmDelta';
        processedInfo.prossedDataType = metric.firmDeltaFormat;

        let row = {
          header: 'My firm',
          binding: myFirm_binding,
          format: wjColFormat,
          visible: true,
          width: 200,
        };
        let row_chart = {
          header: 'My firm',
          binding: myFirm_binding,
          format: wjColFormat,
          visible: true,
          width: 200,
          styling: {},
          symbolStyling: {'stroke-width': '1'},
        };
        if (
          (vizType == VisualizationTypes.KPMV1 && metricindex == 0) ||
          (vizType == VisualizationTypes.DPA && index == 0)
        ) {
          columnsForBaseTable.push(row);
          seriesForChart.push(row_chart);
        }
        let peerGroups = {};
        metric.peerGroups.forEach((peerGroup, indx) => {
          let pg_binding = 'peerGroups.pg' + peerGroup.peerGroupId;
          let forceDelta = false;
          if (metricDetails && metricDetails.deltaOnly && metric.firmValueFormat.indexOf('percent.') != 0) {
            forceDelta = true;
          }
          let peerGroupMeanDeltaFormat = this.getFormat(peerGroup.meanDeltaFormat, data.currency);
          let peerGroupMedianDeltaFormat = this.getFormat(peerGroup.medianDeltaFormat, data.currency);

          let meanDelta = this.getValue(peerGroup.meanDelta, peerGroupMeanDeltaFormat, null, null, null, true);
          let medianDelta = this.getValue(peerGroup.medianDelta, peerGroupMedianDeltaFormat, null, null, null, true);
          // let meanVariance = this.getValue(peerGroup.meanVariance, metric.firmDeltaFormat);
          // let medianVariance = this.getValue(peerGroup.medianVariance, metric.firmDeltaFormat);

          peerGroups['pg' + peerGroup.peerGroupId + '_meanDelta'] = meanDelta ? meanDelta : 0;
          peerGroups['pg' + peerGroup.peerGroupId + '_meanDelta_format'] = peerGroupMeanDeltaFormat;
          peerGroups['pg' + peerGroup.peerGroupId + '_medianDelta'] = medianDelta ? medianDelta : 0;
          peerGroups['pg' + peerGroup.peerGroupId + '_medianDelta_format'] = peerGroupMedianDeltaFormat;
          // peerGroups['pg' + peerGroup.peerGroupId + '_meanVariance'] = meanVariance ? meanVariance : 0;
          // peerGroups['pg' + peerGroup.peerGroupId + '_medianVariance'] = medianVariance ? medianVariance : 0;

          if (
            (vizType == VisualizationTypes.KPMV1 && metricindex == 0) ||
            (vizType == VisualizationTypes.DPA && index == 0)
          ) {
            let row_mean_delta = {
              header: peerGroup.peerGroupName,
              binding: pg_binding + '_meanDelta',
              format: wjColFormat,
              visible: isYoyOrVariance && isMeanOrMedian,
              width: 130,
            };
            let row_median_delta = {
              header: peerGroup.peerGroupName,
              binding: pg_binding + '_medianDelta',
              format: wjColFormat,
              visible: isYoyOrVariance && !isMeanOrMedian,
              width: 130,
            };

            // let row_mean_variance = {
            //   header: peerGroup.peerGroupName,
            //   binding: pg_binding + '_meanVariance',
            //   visible: !isYoyOrVariance && isMeanOrMedian,
            //   format: 'n1',
            //   width: 130,
            // };
            // let row_median_variance = {
            //   header: peerGroup.peerGroupName,
            //   binding: pg_binding + '_medianVariance',
            //   visible: !isYoyOrVariance && !isMeanOrMedian,
            //   format: 'n1',
            //   width: 130,
            // };
            let row_mean_Delta_chart = {
              header: peerGroup.peerGroupName,
              binding: pg_binding + '_meanDelta',
              visible: isMeanOrMedian,
              width: 110,
              styling: {},
              symbolStyling: {'stroke-width': '1'},
            };
            let row_median_Delta_chart = {
              header: peerGroup.peerGroupName,
              binding: pg_binding + '_medianDelta',
              visible: !isMeanOrMedian,
              width: 110,
              styling: {},
              symbolStyling: {'stroke-width': '1'},
            };
            let row_mean_variance_chart = {
              header: peerGroup.peerGroupName,
              binding: pg_binding + '_meanVariance',
              visible: !isYoyOrVariance && isMeanOrMedian,
              format: 'n1',
              width: 130,
              styling: {},
              symbolStyling: {'stroke-width': '1'},
            };
            // let row_median_variance_chart = {
            //   header: peerGroup.peerGroupName,
            //   binding: pg_binding + '_medianVariance',
            //   visible: !isYoyOrVariance && !isMeanOrMedian,
            //   format: 'n1',
            //   width: 130,
            //   styling: {},
            //   symbolStyling: {'stroke-width': '1'},
            // };

            // series for data table
            columnsForBaseTable.push(row_mean_delta);
            columnsForBaseTable.push(row_median_delta);
            // columnsForBaseTable.push(row_mean_variance);
            // columnsForBaseTable.push(row_median_variance);

            // series for Chart
            seriesForChart.push(row_mean_Delta_chart);
            seriesForChart.push(row_median_Delta_chart);
            // seriesForChart.push(row_mean_variance_chart);
            // seriesForChart.push(row_median_variance_chart);
          }
        });
        if (vizType == VisualizationTypes.KPMV1) {
          option['peerGroups'] = peerGroups;
          gridData.push(option);
        } else if (vizType == VisualizationTypes.DPA) {
          optionForDPA['peerGroups'] = peerGroups;
        }
      });
      if (vizType == VisualizationTypes.DPA) {
        gridData.push(optionForDPA);
      }
    });

    processedInfo.isCalledForCard = false;
    processedInfo.processedData = gridData;
    processedInfo.processedColumn = columnsForBaseTable;
    processedInfo.processedChartSeries = seriesForChart;
    processedInfo.precessedWjColformat = wjColFormat;
    processedInfo.visualizationType = vizType;
    processedInfo.originalResponse = data;
    processedInfo.hasEmptyResult = !(gridData && gridData.length);
    processedInfo.simplifiedColumn = simplyfiedData['simplifiedColumn'];
    processedInfo.simplifiedData = simplyfiedData['simplifiedData'];

    return processedInfo;
  }
  getSimplifiedData(vizType, data, isMeanOrMedian: boolean, isYoyOrVariance: boolean) {
    if (vizType == VisualizationTypes.KPMV1) {
      return this.getSimplifiedDataForKPM(data, isMeanOrMedian, isYoyOrVariance);
    } else if (vizType == VisualizationTypes.DPA) {
      return this.getSimplifiedDataForDPA(data, isMeanOrMedian, isYoyOrVariance);
    }
  }
  getSimplifiedDataForDPA(data, isMeanOrMedian: boolean, isYoyOrVariance: boolean) {
    let finalDataset = {};
    let simplifiedData = [];
    let colArr = [];
    if (data.values.length > 0 && data.values[0].metrics && data.values[0].metrics.length > 0) {
      let peerGroups = data.values[0].metrics[0].peerGroups;
      let values = data.values;
      let columnObj = {
        header: 'Firm/Peer group',
        binding: 'column0',
        width: 250,
        visible: true,
      };
      colArr.push(columnObj);
      let meanDeltaFormat = values[0].metrics[0].peerGroups[0].meanDeltaFormat;
      let wijmoColFormat = this.getFormat(meanDeltaFormat, data.currency, true);

      for (let i = 0; i < values.length; i++) {
        let columnObj1 = {};
        columnObj1['header'] = values[i].practiceName;
        columnObj1['binding'] = 'column' + (i + 1) + '_meanDelta';
        columnObj1['practice'] = values[i].practice;
        columnObj1['format'] = wijmoColFormat;
        columnObj1['visible'] = isYoyOrVariance && isMeanOrMedian;
        columnObj1['width'] = 130;
        colArr.push(columnObj1);
        columnObj1 = {};
        columnObj1['header'] = values[i].practiceName;
        columnObj1['binding'] = 'column' + (i + 1) + '_medianDelta';
        columnObj1['practice'] = values[i].practice;
        columnObj1['format'] = wijmoColFormat;
        (columnObj1['visible'] = isYoyOrVariance && !isMeanOrMedian), (columnObj1['width'] = 130);
        colArr.push(columnObj1);
      }
      let myFirmObj = {
        column0: 'My Firm',
      };
      simplifiedData.push(myFirmObj);
      for (let i = 0; i < peerGroups.length; i++) {
        let columnObj2 = {};
        columnObj2['peerGroupId'] = peerGroups[i].peerGroupId;
        columnObj2['column0'] = peerGroups[i].peerGroupName;

        simplifiedData.push(columnObj2);
      }
      // for(let j=1; j < colArr.length ; j++){
      for (let i = 0; i < simplifiedData.length; i++) {
        for (let k = 0; k < values.length; k++) {
          let col = colArr.filter((x) => x.practice === values[k]['practice']);
          let pgObj = values[k].metrics[0].peerGroups.filter((x) => x.peerGroupId === simplifiedData[i]['peerGroupId']);

          for (let y = 0; y < col.length; y++) {
            if (col[y]['binding'].includes('_meanDelta')) {
              if (i == 0) {
                simplifiedData[i][col[y]['binding']] = this.getValue(
                  values[k].metrics[0].firmDelta,
                  wijmoColFormat,
                  null,
                  null,
                  null,
                  true
                );
              } else {
                simplifiedData[i][col[y]['binding']] = this.getValue(
                  pgObj[0]['meanDelta'],
                  wijmoColFormat,
                  null,
                  null,
                  null,
                  true
                );
              }
            } else {
              if (i == 0) {
                simplifiedData[i][col[y]['binding']] = this.getValue(
                  values[k].metrics[0].firmDelta,
                  wijmoColFormat,
                  null,
                  null,
                  null,
                  true
                );
              } else
                simplifiedData[i][col[y]['binding']] = this.getValue(
                  pgObj[0]['medianDelta'],
                  wijmoColFormat,
                  null,
                  null,
                  null,
                  true
                );
            }
          }
        }
      }
      // }
    }
    finalDataset['simplifiedData'] = simplifiedData;
    finalDataset['simplifiedColumn'] = colArr;
    console.log(simplifiedData);
    return finalDataset;
  }
  getSimplifiedDataForKPM(data, isMeanOrMedian: boolean, isYoyOrVariance: boolean) {
    let finalDataset = {};
    let simplifiedData = [];
    let colArr = [];
    if (data.values.length > 0 && data.values[0].metrics && data.values[0].metrics.length > 0) {
      let peerGroups = data.values[0].metrics[0].peerGroups;
      let values = data.values;
      let columnObj = {
        header: 'Firm/Peer group',
        binding: 'column0',
        width: 250,
        visible: true,
      };
      colArr.push(columnObj);
      let meanDeltaFormat = values[0].metrics[0].peerGroups[0].meanDeltaFormat;
      let wijmoColFormat = this.getFormat(meanDeltaFormat, data.currency, true);

      for (let i = 0; i < values[0].metrics.length; i++) {
        let columnObj1 = {};
        columnObj1['header'] = values[0].metrics[i].metricName;
        columnObj1['metricId'] = values[0].metrics[i].metricId;
        columnObj1['binding'] = 'column' + (i + 1) + '_meanDelta';
        columnObj1['format'] = wijmoColFormat;
        columnObj1['visible'] = isYoyOrVariance && isMeanOrMedian;
        columnObj1['width'] = 130;
        colArr.push(columnObj1);
        columnObj1 = {};
        columnObj1['header'] = values[0].metrics[i].metricName;
        columnObj1['metricId'] = values[0].metrics[i].metricId;
        columnObj1['binding'] = 'column' + (i + 1) + '_medianDelta';
        columnObj1['format'] = wijmoColFormat;
        (columnObj1['visible'] = isYoyOrVariance && !isMeanOrMedian), (columnObj1['width'] = 130);
        colArr.push(columnObj1);
      }
      let myFirmObj = {
        column0: 'My Firm',
      };
      simplifiedData.push(myFirmObj);
      for (let i = 0; i < peerGroups.length; i++) {
        let columnObj2 = {};
        columnObj2['peerGroupId'] = peerGroups[i].peerGroupId;
        columnObj2['column0'] = peerGroups[i].peerGroupName;

        simplifiedData.push(columnObj2);
      }
      // for(let j=1; j < colArr.length ; j++){
      for (let i = 0; i < simplifiedData.length; i++) {
        for (let k = 0; k < values[0].metrics.length; k++) {
          let col = colArr.filter((x) => x.metricId === values[0].metrics[k]['metricId']);
          let pgObj = values[0].metrics[k].peerGroups.filter((x) => x.peerGroupId === simplifiedData[i]['peerGroupId']);

          for (let y = 0; y < col.length; y++) {
            if (col[y]['binding'].includes('_meanDelta')) {
              if (i == 0) {
                simplifiedData[i][col[y]['binding']] = this.getValue(
                  values[0].metrics[k].firmDelta,
                  wijmoColFormat,
                  null,
                  null,
                  null,
                  true
                );
              } else {
                simplifiedData[i][col[y]['binding']] = this.getValue(
                  pgObj[0]['meanDelta'],
                  wijmoColFormat,
                  null,
                  null,
                  null,
                  true
                );
              }
            } else {
              if (i == 0) {
                simplifiedData[i][col[y]['binding']] = this.getValue(
                  values[0].metrics[k].firmDelta,
                  wijmoColFormat,
                  null,
                  null,
                  null,
                  true
                );
              } else
                simplifiedData[i][col[y]['binding']] = this.getValue(
                  pgObj[0]['medianDelta'],
                  wijmoColFormat,
                  null,
                  null,
                  null,
                  true
                );
            }
          }
        }
      }
      // }
    }
    finalDataset['simplifiedData'] = simplifiedData;
    finalDataset['simplifiedColumn'] = colArr;
    console.log(simplifiedData);
    return finalDataset;
  }

  getMonthNameByMonthNumber(month: number) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return month >= 0 && month <= 11 ? monthNames[month] : monthNames[0];
  }

  populateSelectedDateRange(
    createChartModel: CreateChart,
    currentStartDate: Date,
    currentEndDate: Date,
    priorStartDate: Date,
    priorEndDate: Date
  ) {
    if (createChartModel.selectedDateRangeType.id == 1) {
      let startDateRange = new FixedRange();
      let endDateRange = new FixedRange();

      // Start Date range
      let selectionMonth = new Selection();
      selectionMonth.id = currentStartDate.getMonth();
      selectionMonth.value = currentStartDate.getMonth() + 1;
      selectionMonth.name = this.getMonthNameByMonthNumber(currentStartDate.getMonth());

      let selectionYear = new Selection();
      selectionYear.id = 0;
      selectionYear.value = currentStartDate.getFullYear();
      selectionYear.name = currentStartDate.getFullYear().toString();

      startDateRange.years = selectionYear;
      startDateRange.month = selectionMonth;

      // End Date range
      let endSelectionMonth = new Selection();
      endSelectionMonth.id = currentStartDate.getMonth();
      endSelectionMonth.value = currentStartDate.getMonth() + 1;
      endSelectionMonth.name = this.getMonthNameByMonthNumber(currentStartDate.getMonth());

      let endSelectionYear = new Selection();
      endSelectionYear.id = 0;
      endSelectionYear.value = currentStartDate.getFullYear();
      endSelectionYear.name = currentStartDate.getFullYear().toString();

      endDateRange.years = endSelectionYear;
      endDateRange.month = endSelectionMonth;

      // Assigning startDateRange and endDateRange to the chartModel.
      createChartModel.dateRange.startDateRange = startDateRange;
      createChartModel.dateRange.endDateRange = endDateRange;
    } else if (createChartModel.selectedDateRangeType.id == 2) {
      let startDateText =
        ('0' + (currentStartDate.getMonth() + 1)).toString().slice(-2) + '/' + currentStartDate.getFullYear();
      let endDateText =
        ('0' + (currentEndDate.getMonth() + 1)).toString().slice(-2) + '/' + currentEndDate.getFullYear();
      let startPeriod =
        currentStartDate.getFullYear() + '' + ('0' + (currentStartDate.getMonth() + 1)).toString().slice(-2);
      let endPeriod = currentEndDate.getFullYear() + '' + ('0' + (currentEndDate.getMonth() + 1)).toString().slice(-2);

      let startDateArray = [];
      let endDateArray = [];
      startDateArray.push(
        startDateText,
        ('0' + (currentStartDate.getMonth() + 1)).toString().slice(-2),
        currentStartDate.getFullYear().toString()
      );
      endDateArray.push(
        endDateText,
        ('0' + (currentEndDate.getMonth() + 1)).toString().slice(-2),
        currentEndDate.getFullYear().toString()
      );

      let calculatedPeriod = this.getCalculatedTimeRange(startDateArray, endDateArray, 'YYYYMM');
      let compareStartDateText = calculatedPeriod[0];
      let compareEndDateText = calculatedPeriod[1];
      let compareStartPeriod = compareStartDateText.replace('/', '');
      let compareEndPeriod = compareEndDateText.replace('/', '');

      if (!createChartModel.customRange) {
        createChartModel.customRange = new FixedTimeRange();
      }
      createChartModel.customRange.startDateText = startDateText;
      createChartModel.customRange.endDateText = endDateText;
      createChartModel.customRange.startPeriod1 = startPeriod;
      createChartModel.customRange.endPeriod1 = endPeriod;
      let stext = calculatedPeriod[0].split('/').reverse().join('/');
      let etext = calculatedPeriod[1].split('/').reverse().join('/');
      createChartModel.customRange.startDateText2 = stext;

      createChartModel.customRange.endDateText2 = etext;
      createChartModel.customRange.startPeriod2 = compareStartPeriod;
      createChartModel.customRange.endPeriod2 = compareEndPeriod;
    }
  }

  public createRequestBodyForVisualization(
    visualizationSelectionModel: CreateChart,
    visualizationTemplateDetail: VisualizationTemplate,
    firmBasicData: any,
    type: string,
    requestBodyType: string = 'get'
  ) {
    let requestbody = {};
    if (firmBasicData) {
      //Add Metric
      let metrics: number[] = visualizationSelectionModel.selectedMetrics
        .filter((metric) => metric.selected)
        .map((metric) => metric.id);
      requestbody['metrics'] = metrics && metrics.length ? metrics : [];

      if (visualizationSelectionModel.selectedPeerGroups) {
        let peerGroups: number[] = [
          ...new Set(visualizationSelectionModel.selectedPeerGroups.map((peerGroup) => peerGroup.id)),
        ];
        requestbody['peerGroups'] = peerGroups;
      } else {
        requestbody['peerGroups'] = [];
      }

      if (visualizationSelectionModel.selectedPracticeGroups) {
        let practices: number[] = [
          ...new Set(visualizationSelectionModel.selectedPracticeGroups.map((item) => item.id)),
        ];
        requestbody['practices'] = practices;
      } else {
        requestbody['practices'] = [];
      }

      if (visualizationSelectionModel.selectedOffices) {
        let offices: number[] = [...new Set(visualizationSelectionModel.selectedOffices.map((item) => item.id))];
        let parents: number[] = offices.filter((id) => id < 0);
        let child: number[] = offices.filter((id) => id >= 0);
        if (requestBodyType != 'save') {
          offices = this.getExtractGroupIds(firmBasicData.officeGroups, offices, parents, child);
        }
        requestbody['offices'] = offices;
      } else {
        requestbody['offices'] = [];
      }

      if (visualizationSelectionModel.selectedTitles) {
        let titles: number[] = [...new Set(visualizationSelectionModel.selectedTitles.map((item) => item.id))];
        let parents: number[] = titles.filter((id) => id < 0);
        let child: number[] = titles.filter((id) => id >= 0);
        if (requestBodyType != 'save') {
          titles = this.getExtractGroupIds(firmBasicData.titleGroups, titles, parents, child);
        }
        requestbody['titles'] = titles;
      } else {
        requestbody['titles'] = [];
      }

      let experienceYears = {};

      if (visualizationSelectionModel.selectedPartnerYears) {
        let partnerYears: number[] = [
          ...new Set(visualizationSelectionModel.selectedPartnerYears.map((item) => item.id)),
        ];
        experienceYears['partnerYears'] = partnerYears;
      } else {
        experienceYears['partnerYears'] = [];
      }

      if (visualizationSelectionModel.selectedSrAssociateYears) {
        let srAssociateYears: number[] = [
          ...new Set(visualizationSelectionModel.selectedSrAssociateYears.map((item) => item.id)),
        ];
        experienceYears['srAssociateYears'] = srAssociateYears;
      } else {
        experienceYears['srAssociateYears'] = [];
      }

      if (visualizationSelectionModel.selectedAssociateYears) {
        let associateYears: number[] = [
          ...new Set(visualizationSelectionModel.selectedAssociateYears.map((item) => item.id)),
        ];
        experienceYears['associateYears'] = associateYears;
      } else {
        experienceYears['associateYears'] = [];
      }
      requestbody['experienceYears'] = experienceYears;

      // Dependency data from Basic details
      if (requestBodyType == 'get') {
        requestbody['quid'] = uuidv4();
        requestbody['yyyymm'] = firmBasicData.yyyymm;
        requestbody['log'] = true;
      }
      requestbody['currency'] = firmBasicData.currency;
      requestbody['firmCurrency'] = firmBasicData.firmCurrency;
      requestbody['defaultAccess'] = firmBasicData.defaultAccess;

      // add query parameter for Matter type
      requestbody['matterType'] = visualizationSelectionModel.viewsSettings.matterType;

      // add query parameter for Contractor
      requestbody['contractors'] = visualizationSelectionModel.viewsSettings.contractorsType;

      // add query parameter for Currency
      requestbody['currencyType'] = visualizationSelectionModel.viewsSettings.currencyType;

      if (type && requestBodyType == 'get') {
        requestbody['templateType'] = type;
        //Time Range
        if (visualizationSelectionModel.selectedDateRangeType.id == 1) {
          let startPeriod =
            visualizationSelectionModel.dateRange.startDateRange.years.value.toString() +
            ('0' + visualizationSelectionModel.dateRange.startDateRange.month.value.toString()).slice(-2);
          requestbody['currentStartPeriod'] = startPeriod;
          requestbody['currentEndPeriod'] = startPeriod;
        } else {
          let startPeriod = visualizationSelectionModel.customRange.startPeriod1;
          let endPeriod = visualizationSelectionModel.customRange.endPeriod1;
          requestbody['currentStartPeriod'] = startPeriod;
          requestbody['currentEndPeriod'] = endPeriod;
        }
      } else if (requestBodyType == 'save' || requestBodyType == 'update') {
        requestbody['chartTemplateId'] = visualizationSelectionModel.templateId;
        requestbody['chartName'] = visualizationSelectionModel.name;
        requestbody['favorite'] = visualizationSelectionModel.favorite;
        requestbody['dateRangeSelectedIds'] = visualizationSelectionModel.selectedDateRangeType.id;
        requestbody['chartId'] = visualizationTemplateDetail.chartId;
        requestbody['comparison'] = visualizationSelectionModel.viewsSettings.comparisonType.toLowerCase();
        requestbody['statistics'] = visualizationSelectionModel.viewsSettings.statisticsType.toLowerCase();
        requestbody['currentPeriodStartDate'] = visualizationSelectionModel.currentPeriodStartDate;
        requestbody['currentPeriodEndDate'] = visualizationSelectionModel.currentPeriodEndDate;
        requestbody['priorPeriodStartDate'] = visualizationSelectionModel.priorPeriodStartDate;
        requestbody['priorPeriodEndDate'] = visualizationSelectionModel.priorPeriodEndDate;
      }

      return requestbody;
    } else {
      return null;
    }
  }

  populatePreSelectedVisualizationDetails(
    createChartModel: CreateChart,
    vizTemplateDetail: VisualizationTemplate,
    isForTemplate?: boolean
  ) {
    createChartModel.orignalName = isForTemplate ? vizTemplateDetail.templateName : vizTemplateDetail.chartName;
    createChartModel.name = isForTemplate ? vizTemplateDetail.templateName + '_Copy' : vizTemplateDetail.chartName;
    createChartModel.templateKey = vizTemplateDetail.templateKey.toString().toLowerCase();
    createChartModel.selectedMetrics = vizTemplateDetail.metrics.values.filter((metric) => metric.selected);
    createChartModel.selectedDateRangeType = vizTemplateDetail.dateRange.find((dateRange) => dateRange.selected);
    createChartModel.selectedPeerGroups = vizTemplateDetail.peerGroups.values.filter((peerGroup) => peerGroup.selected);
    createChartModel.selectedPracticeGroups = vizTemplateDetail.practices.values.filter(
      (practice) => practice.selected
    );
    createChartModel.selectedOffices = vizTemplateDetail.offices.values.filter((office) => office.selected);
    createChartModel.selectedTitles = vizTemplateDetail.titles.values.filter((title) => title.selected);

    createChartModel.selectedSrAssociateYears = vizTemplateDetail.srAssociateYears.filter(
      (srAssociate) => srAssociate.selected
    );
    createChartModel.selectedAssociateYears = vizTemplateDetail.associateYears.filter(
      (associate) => associate.selected
    );
    createChartModel.selectedPartnerYears = vizTemplateDetail.partnerYears.filter(
      (partnerYear) => partnerYear.selected
    );

    createChartModel.dateRange = new DateRange();
    var currentSTPeriod = vizTemplateDetail.currentPeriodStartDate.toString().split('-');
    var currentENDPeriod = vizTemplateDetail.currentPeriodEndDate.toString().split('-');
    var priorSTPeriod = vizTemplateDetail.priorPeriodStartDate.toString().split('-');
    var priorENDPeriod = vizTemplateDetail.priorPeriodEndDate.toString().split('-');

    var currentSTPeriodDate = new Date();
    var currentENDPeriodDate = new Date();
    var priorSTPeriodDate = new Date();
    var priorENDPeriodDate = new Date();

    currentSTPeriodDate.setDate(parseInt(currentSTPeriod[2]));
    currentSTPeriodDate.setFullYear(parseInt(currentSTPeriod[0]));
    currentSTPeriodDate.setMonth(parseInt(currentSTPeriod[1]) - 1);

    currentENDPeriodDate.setDate(parseInt(currentENDPeriod[2]));
    currentENDPeriodDate.setFullYear(parseInt(currentENDPeriod[0]));
    currentENDPeriodDate.setMonth(parseInt(currentENDPeriod[1]) - 1);

    priorSTPeriodDate.setDate(parseInt(priorSTPeriod[2]));
    priorSTPeriodDate.setFullYear(parseInt(priorSTPeriod[0]));
    priorSTPeriodDate.setMonth(parseInt(priorSTPeriod[1]) - 1);

    priorENDPeriodDate.setDate(parseInt(priorENDPeriod[2]));
    priorENDPeriodDate.setFullYear(parseInt(priorENDPeriod[0]));
    priorENDPeriodDate.setMonth(parseInt(priorENDPeriod[1]) - 1);

    this.populateSelectedDateRange(
      createChartModel,
      currentSTPeriodDate,
      currentENDPeriodDate,
      priorSTPeriodDate,
      priorENDPeriodDate
    );

    createChartModel.currentPeriodStartDate = vizTemplateDetail.currentPeriodStartDate;
    createChartModel.currentPeriodEndDate = vizTemplateDetail.currentPeriodEndDate;
    createChartModel.priorPeriodStartDate = vizTemplateDetail.priorPeriodStartDate;
    createChartModel.priorPeriodEndDate = vizTemplateDetail.priorPeriodEndDate;

    createChartModel.viewsSettings = new ViewsSettings();
    if (vizTemplateDetail.statisticSetting) {
      if (vizTemplateDetail.statisticSetting.toLowerCase() === 'mean') {
        createChartModel.viewsSettings.statisticsType = 'Mean';
      } else if (vizTemplateDetail.statisticSetting.toLowerCase() === 'median') {
        createChartModel.viewsSettings.statisticsType = 'Median';
      }
    }
    if (vizTemplateDetail.comparisionSetting) {
      if (vizTemplateDetail.comparisionSetting.toLowerCase() === 'YOY') {
        createChartModel.viewsSettings.comparisonType = 'year over year';
      } else if (vizTemplateDetail.comparisionSetting.toLowerCase() === 'variance') {
        createChartModel.viewsSettings.comparisonType = 'variance';
      }
    }
    if (vizTemplateDetail.matterTypeSetting) {
      if (vizTemplateDetail.matterTypeSetting.toLowerCase() === 'all') {
        createChartModel.viewsSettings.matterType = 'all';
      } else if (vizTemplateDetail.matterTypeSetting.toLowerCase() === 'billable') {
        createChartModel.viewsSettings.matterType = 'billable';
      } else if (vizTemplateDetail.matterTypeSetting.toLowerCase() === 'contingent') {
        createChartModel.viewsSettings.matterType = 'contingent';
      }
    }
    if (vizTemplateDetail.contractorsSetting) {
      if (vizTemplateDetail.contractorsSetting.toLowerCase() === 'exclude') {
        createChartModel.viewsSettings.contractorsType = 'exclude';
      } else if (vizTemplateDetail.contractorsSetting.toLowerCase() === 'include') {
        createChartModel.viewsSettings.contractorsType = 'include';
      }
    }

    if (vizTemplateDetail.currencySetting) {
      if (vizTemplateDetail.currencySetting.toLowerCase() === 'dynamic') {
        createChartModel.viewsSettings.currencyType = 'dynamic';
      } else if (vizTemplateDetail.currencySetting.toLowerCase() === 'static') {
        createChartModel.viewsSettings.currencyType = 'static';
      }
    }
  }
}
