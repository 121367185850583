/* eslint-disable max-len */
import {Component, OnDestroy, OnInit, HostBinding} from '@angular/core';
import {BentoAlertItemOptions, BentoSideNavItem} from '@bento/bento-ng';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {DownloadService} from 'src/app/core/services/download/download.service';
import {HttpParams} from '@angular/common/http';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
import {AuthService} from 'src/app/core/services/auth/auth.service';
declare let TRAAC: any;

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
})
export class ResourcesComponent implements OnInit, OnDestroy {
  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }
  errorMessage = '';
  isLoading = false;
  alerts: BentoAlertItemOptions[] = [];
  clientReportsArr = [];
  countryArr = [];
  selectedItemIndex: number | null = null;
  items: BentoSideNavItem[] = [
    {
      label: 'Reference documents',
      selected: true,

      action: () => {
        this.showHideUserTabSideNav('Reference documents');
        this.setFocusToFirstLink('.GlobalLayout-item.GlobalLayout-item--large a', 0);
      },
      data: {isVisible: true},
    },
    {
      label: 'User guide',
      action: () => {
        this.showHideUserTabSideNav('User guide');
        this.setFocusToFirstLink('.GlobalLayout-item.GlobalLayout-item--large a', 1);
      },
      data: {isVisible: false},
    },
    {
      label: 'Legal industry update',
      action: () => {
        this.showHideUserTabSideNav('Legal industry update');
        this.setFocusToFirstLink('.GlobalLayout-item.GlobalLayout-item--large a', 2);
      },
      data: {isVisible: false},
    },
    {
      label: 'Client reports',
      action: () => {
        const clientReportsIndex = this.items.findIndex((item) => item.label === 'Client reports');
        this.showHideUserTabSideNav('Client reports');
        if (clientReportsIndex !== -1) {
          this.items[clientReportsIndex].data.isVisible = true;
        }
        this.setFocusToFirstLink('.GlobalLayout-item.GlobalLayout-item--large a', 3);
        this.focusSpecificLinks();
      },
      collapsed: true,
      items: this.clientReportsArr,
      data: {isVisible: false},
    },
    {
      label: 'Thought leadership reports',
      action: () => {
        this.showHideUserTabSideNav('Thought leadership reports');
        this.setFocusToFirstLink('.GlobalLayout-item.GlobalLayout-item--large a', 4);
      },
      data: {isVisible: false},
    },
    {
      label: 'Law firm financial index',
      action: () => {
        this.showHideUserTabSideNav('Law firm financial index');
        this.setFocusToFirstLink('.GlobalLayout-item.GlobalLayout-item--large a', 5);
      },
      data: {isVisible: false},
    },
  ];

  private setFocusToFirstLink(selector: string, itemIndex: number = 0): void {
    setTimeout(() => {
      const links = Array.from(document.querySelectorAll(selector)) as HTMLElement[];
      if (links.length > 0) {
        links[0].focus();
        const lastLink = links[links.length - 1];
        lastLink.addEventListener('keydown', (event: KeyboardEvent) => {
          if (event.key === 'Tab' && !event.shiftKey) {
           
            event.preventDefault();
            this.focusNextSideNavItem(itemIndex);
          }
        });
        lastLink.addEventListener('blur', () => {
          if (!document.activeElement || !document.querySelector(selector).contains(document.activeElement)) {
            this.focusNextSideNavItem(itemIndex);
            
          }
        });
      }
    }, 100);
  }
  private focusSpecificLinks(): void {   
    const specificLinks = ['Australia', 'UK', 'US']; // Specific link texts   

    this.ensureSideNavExpanded('Client reports', () => {     

      specificLinks.forEach((linkText) => {
        setTimeout(() => {
          const links = Array.from(
            document.querySelectorAll('.GlobalLayout-item.GlobalLayout-item--large a')
          ) as HTMLElement[];
          const link = links.find((l) => l.textContent?.trim() === linkText);
          if (link) {
            link.focus();
          } else {
            console.warn(`Link with text "${linkText}" not found.`);
          }
        }, 100);
      });
    });
  }

  private ensureSideNavExpanded(parentLabel: string, callback: () => void): void {
    const parentNavItem = this.items.find((item) => item.label === parentLabel);

    if (parentNavItem && parentNavItem.data.isVisible) {
      callback();
    } else {
      this.showHideUserTabSideNav(parentLabel);

      // Wait for the DOM update to complete
      setTimeout(() => {
        callback();
      }, 100);
    }
  }

  // private focusNextSideNavItem(currentIndex: number): void {
  //   let nextIndex = (currentIndex + 1) % this.items.length;
  //   let attempts = 0;

  //   // Look for the next visible item
  //   while (attempts < this.items.length) {
  //     if (this.items[nextIndex].data.isVisible) {
  //       this.focusOnItem(nextIndex);
  //       return;
  //     }
  //     nextIndex = (nextIndex + 1) % this.items.length;
  //     attempts++;
  //   }

  //   console.warn('No visible items found to focus on.');
  // }
  private focusNextSideNavItem(currentIndex: number): void {
    let nextIndex = (currentIndex + 1) % this.items.length;
    let attempts = 0;
    const lastIndex = this.items.length - 1;
    while (!this.items[nextIndex].data.isVisible && attempts < this.items.length) {
      nextIndex = (nextIndex + 1) % this.items.length;
      attempts++;
    }
    if (currentIndex === lastIndex && this.items[lastIndex].label === 'Law firm financial index') {
      const externalElement = document.querySelector('.Header-logo') as HTMLElement;
      if (externalElement) {
        externalElement.focus();
      }
      return;
    }

    if (currentIndex === lastIndex && this.items[lastIndex].label === 'Law firm financial index') {

      // Move focus to .Header-logo when Tab is pressed
      if (event instanceof KeyboardEvent && event.key === 'Tab') {
        const externalElement = document.querySelector('.Header-logo') as HTMLElement;
        if (externalElement) {
          event.preventDefault();
          externalElement.focus();
        }
        return;
      }
    }

    if (this.selectedItemIndex !== null) {
      
      this.focusOnItem(this.selectedItemIndex);
      return;
    }

    if (attempts === this.items.length) {
      console.warn('No visible items found to focus on.');
      return;
    }

    this.focusOnItem(nextIndex);
  }

  private focusOnItem(index: number): void {
    const item = this.items[index];

    // Use more specific selector if needed
    const itemSelector = `.bento-side-nav-leaf.current-page a`;
    
    const element = document.querySelector(itemSelector) as HTMLAnchorElement;

    if (element) {
     
      element.focus();
    } else {
      console.warn(`Element for index ${index} not found.`);
    }
  }

  resourceContent;
  selectedCountry: string;
  isQuickSightEnabled = false;
  yearForPeerMonitor = [2019, 2020, 2021];
  constructor(
    private downloadFile: DownloadService,
    private service: BaseService,
    private featureToggleService: FeatureToggleService,
    private authService: AuthService
  ) {}

  ngOnDestroy() {
    setTimeout(() => {
      var element = document.getElementById('resources');
      if (element) {
        element.classList.remove('router-link-active');
      }
    }, 0);
  }
  async ngOnInit(): Promise<void> {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
    await this.featureToggleService.getFeatureValue('QUICKSIGHT').then((val) => (this.isQuickSightEnabled = val));
    /*************************************************************/
    if (this.isQuickSightEnabled) {
      TRAAC.track(TRAAC.keyValue('Resources click', {email: this.authService.getUserEmail()}).build());
    }
    this.service.get(environment.FIClientBaseEndpoint + 'v1/storage/listFiles', '').subscribe(
      (content) => {
        this.isLoading = true;
        this.resourceContent = content;
        this.clientReports();
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );

    setTimeout(() => {
      var element = document.getElementById('resources');
      if (element) {
        element.classList.add('router-link-active');
      }
    }, 0);
  }

  clientReports() {
    var itemObj;
    var items = [];
    this.resourceContent.clientReport.forEach((element) => {
      this.countryArr.push(element.country);
      itemObj = {
        label: element.country,
        selected: false,
        data: {isVisible: false},
        action: () => {
          this.showHideUserTabSideNav(element.country);
          const indexToFocus = this.countryArr.indexOf(element.country);
          this.selectedItemIndex = indexToFocus + 1;
          this.setFocusToFirstLink('.GlobalLayout-item.GlobalLayout-item--large a', indexToFocus);
        },
      };

      items.push(itemObj);
      this.items.forEach((element) => {
        if (element.label === 'Client reports') {
          element.items = items;
        }
      });
    });
  }
  showHideUserTabSideNav(label: string) {
    var isClient = false;
    this.items.forEach((element: BentoSideNavItem) => {
      if (element.label === label) {
        switch (element.label) {
          case 'Client reports':
            element.data.isVisible = true;
            this.selectedCountry = element.items[0].label;
            isClient = true;
            element.items[0].data.isVisible = true;
            element.items[0].selected = true;
            //element.collapsed = false;
            element.items.forEach((item, index) => {
              if (item[index] > 0) {
                element.items[index].data.isVisible = false;
                element.items[index].selected = false;
              }
            });
            break;
          default:
            element.data.isVisible = true;
            break;
        }
      } else {
        element.data.isVisible = false;
      }

      this.countryArr.forEach((country, index) => {
        if (country == label) {
          this.selectedCountry = label;
          this.items[3].items[index].data.isVisible = true;
          this.items[3].items[index].selected = true;
        } else {
          if (!isClient) {
            this.items[3].items[index].selected = false;
            this.items[3].items[index].data.isVisible = false;
          }
        }
      });
    });
  }
  downloadResourceFile(blobName: string, fileName: string, extension: string) {
    let params = new HttpParams();
    params = params.set('blobName', blobName);
    params = params.set('fileName', fileName);
    this.service.downloadExcel(environment.FIClientBaseEndpoint + 'v1/storage/downloadFile?' + params).subscribe(
      (response) => {
        this.downloadFile.downloadWithExtension(
          response.body,
          response.headers.get('content-disposition').split('=')[1],
          extension
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  asIsOrder(a, b) {
    return a.key > b.key ? -1 : b.key > a.key ? 1 : 0;
  }
}
