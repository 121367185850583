import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-timekeeper-model',
  templateUrl: './timekeeper-model.component.html',
  styleUrls: ['./timekeeper-model.component.scss'],
})
export class TimekeeperModelComponent implements OnInit {
  selectedFiles: File[];
  importType: any = 'importType1';
  isDisabled = true;
  browseLabelText: any = 'Browse for files';
  importOptions: any = {grad: false, comp: false, experience: false, existingValue: false, manualEntered: false};
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    const element = document.createElement('p');
    element.style.marginTop = '2em';
    element.innerHTML = 'File must be in format of .xlsx or .csv';
    const iconElement = document.createElement('i');
    iconElement.className = 'bento-icon-chevron-large-right';
    const parentNode = document.getElementById('fileSelector');
    if (parentNode) {
      const parentDiv = parentNode.children[0];
      if (parentDiv) {
        parentDiv.children[2].appendChild(iconElement);
        parentDiv.insertBefore(element, parentDiv.children[3]);
      }
    }
  }
  onDisable() {
    return this.importType == 'importType1';
  }
  import() {}
  importTypeChange() {
    if (this.importType == 'importType1') {
      this.importOptions = {grad: false, comp: false, experience: false, existingValue: false, manualEntered: false};
    }
  }
}
