import {Component, Input, OnInit} from '@angular/core';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {ActivatedRoute} from '@angular/router';
import {BaseService} from 'src/app/core/services/base/base.service';
import {store, eventDispatcher} from 'src/app/core/store';
import {ActionTypes} from 'src/app/core/store/actions';
import {environment} from 'src/environments/environment';
import TimeKeeperSettingModel from 'src/app/core/models/timekeeperSetting.model';
// eslint-disable-next-line max-len
import {
  gradYearName3EValues,
  gradeYearNameValues,
  aderantGradeYearNameValues,
  standarRateSourceValues,
  aderantRateSourceValues,
  gradeSourceValues,
  yearTypeValues,
  titleSourceValues,
} from 'src/app/shared/constants';
import {LocalStorageService} from 'src/app/core/services/local-storage/local-storage.service';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
@Component({
  selector: 'app-timekeeper-setting',
  templateUrl: './timekeeper-setting.component.html',
  styleUrls: ['./timekeeper-setting.component.scss']
})
export class TimekeeperSettingComponent implements OnInit {
  @Input() isNewFirm;
  @Input() firmId;
  alerts: BentoAlertItemOptions[] = [];
  timekeeperSettings = new TimeKeeperSettingModel();
  resetTimekeeperSettings = new TimeKeeperSettingModel();
  isTimeKeeperUpdated = false;
  isGetTimeKeeperSettings = false;
  successMsg: string;
  errorMessage: any;
  standarRateSourceValues = standarRateSourceValues;
  aderantRateSourceValues = aderantRateSourceValues;
  gradeSourceValues = gradeSourceValues;
  //eliteEnterPriseGradeYearName = gradeYearNameValues;
  partnerGradeYearNameValues;
  yearTypeValues = yearTypeValues;
  titleSourceValues = titleSourceValues;
  rateNumbers: number[] = [];
  anniversaryMonth: number[] = [];
  anniversaryYear: number[] = [];
  firmType: string;
  firmParameterModel: any;
  validations: any;
  prevPartnerGradYearName:any="";
  prevPartnerGradYearSource:any="";
  prevAssociateGradYearName:any="";
  prevAssociateGradYearSource:any="";

  constructor(
    private route: ActivatedRoute,
    private service: BaseService,
    private localStorageService: LocalStorageService,
    private authService: AuthService
  ) {
    this.validations = {
      validatePattern: {
        pattern: 'Error:  Enter values in the table.column format',
      },
    };

    this.isGetTimeKeeperSettings = false;
    store.subscribe((state) => {
      const {firmParameter} = state;
      if (firmParameter != null) {
        this.timekeeperSettings = Object.assign({}, firmParameter.timekeeperSettings);
        this.firmParameterModel = Object.assign({}, firmParameter);
        
        /* Partner Grade Source handling while load the data*/
        this.prevPartnerGradYearSource = this.timekeeperSettings.gradYearSource;
        this.prevPartnerGradYearName =  this.timekeeperSettings.gradYearName;
        if(this.timekeeperSettings.gradYearSource ==="C"){
          var partnerGradeYearNameCheck = this.partnerGradeYearNameValues.find(t=>t.value == this.timekeeperSettings.gradYearName);
          if(partnerGradeYearNameCheck === undefined)
          this.timekeeperSettings.gradYearName ="";
        }

        /*Associate Grade Source handling while load the data compYearSource*/
        this.prevAssociateGradYearSource = this.timekeeperSettings.compYearSource;
        this.prevAssociateGradYearName =  this.timekeeperSettings.compYearName;
        if(this.timekeeperSettings.compYearSource ==="C"){
          var partnerGradeYearNameCheck = this.partnerGradeYearNameValues.find(t=>t.value == this.timekeeperSettings.compYearName);
          if(partnerGradeYearNameCheck === undefined)
          this.timekeeperSettings.compYearName ="";
        }
      }
    });
    this.firmType = this.localStorageService.get('FIRM_TYPE');
    this.isGetTimeKeeperSettings = true;
    this.rateNumberValues();
    this.anniversaryMonthValues();
    this.anniversaryYearValues();
    this.gradeYearNameValue();
  }
  gradeYearNameValue() {
    if (this.firmType === '1') {
      this.partnerGradeYearNameValues = gradeYearNameValues;
    } else if (this.firmType === '4') {
      this.partnerGradeYearNameValues = gradYearName3EValues;
    } else {
      this.partnerGradeYearNameValues = aderantGradeYearNameValues;
    }
  }
  anniversaryYearValues() {
    for (let index = 1; index <= 2; index++) {
      this.anniversaryYear.push(index);
    }
  }
  anniversaryMonthValues() {
    for (let index = 1; index <= 12; index++) {
      this.anniversaryMonth.push(index);
    }
  }
  rateNumberValues() {
    for (let index = 1; index <= 50; index++) {
      this.rateNumbers.push(index);
    }
  }
  partnerGradSourceChange(_source:any){
    if((this.prevPartnerGradYearName ==="L") || (this.prevPartnerGradYearSource !="L" && _source ==="L") || (this.prevPartnerGradYearSource ==="L" && _source ==="L") || (this.prevPartnerGradYearSource !="U" && _source ==="U") || (this.prevPartnerGradYearSource !="C" && _source ==="C")){
      this.timekeeperSettings.gradYearName="";
    }else if((this.prevPartnerGradYearSource ==="U" && _source ==="U") || (this.prevPartnerGradYearSource ==="C" && _source ==="C")){
      this.timekeeperSettings.gradYearName = this.prevPartnerGradYearName;
    }
  }
  associateGradSourceChange(_source:any){
    if((this.prevAssociateGradYearName ==="L") || (this.prevAssociateGradYearSource !="L" && _source ==="L") || (this.prevAssociateGradYearSource ==="L" && _source ==="L") || (this.prevAssociateGradYearSource !="U" && _source ==="U") || (this.prevAssociateGradYearSource !="C" && _source ==="C")){
      this.timekeeperSettings.compYearName="";
    }
    else if((this.prevAssociateGradYearSource ==="U" && _source ==="U") || (this.prevAssociateGradYearSource ==="C" && _source ==="C")){
      //if(this.prevAssociateGradYearName !="L")
      this.timekeeperSettings.compYearName = this.prevAssociateGradYearName;
    }
  }
  ngOnInit(): void {
    if (this.isNewFirm) this.timekeeperSettings = new TimeKeeperSettingModel();
    else eventDispatcher.next({type: ActionTypes.GetFirmParameterSetting});
    this.resetTimekeeperSettings = JSON.parse(JSON.stringify(this.timekeeperSettings));
  }
  resetTimekeeper() {
    this.isTimeKeeperUpdated = true;
    if (typeof this.resetTimekeeperSettings !== 'undefined') {
      this.timekeeperSettings = JSON.parse(JSON.stringify(this.resetTimekeeperSettings));
    }
    this.successMsg = 'Data reset successfully.';
    this.alerts.push({
      type: 'success',
      msg: this.successMsg,
      timeout: 2500,
      closeable: true,
    });
    this.isTimeKeeperUpdated = false;
  }
  saveTimekeeper() {
    
    this.alerts = [];
    if (this.timekeeperSettings.gradYearSource === 'L') {
      this.timekeeperSettings.gradYearName = 'L';
      this.timekeeperSettings.gradYearType = 'L';
    }
    if (this.timekeeperSettings.compYearSource === 'L') {
      this.timekeeperSettings.compYearName = 'L';
      this.timekeeperSettings.compYearType = 'L';
    }
    if (this.timekeeperSettings.sameSource) {
      this.timekeeperSettings.compYearSource = this.timekeeperSettings.gradYearSource;
      this.timekeeperSettings.compYearType = this.timekeeperSettings.gradYearType;
      this.timekeeperSettings.compYearName = this.timekeeperSettings.gradYearName;
    }
    if (this.timekeeperSettings.gradYearSource === 'C') {
      var gradYearNameLowerCase = this.timekeeperSettings.gradYearName.toLowerCase();
      if(this.timekeeperSettings.gradYearType =="Y" && gradYearNameLowerCase.includes('date') ){
        this.alerts.push({
          type: 'warning',
          msg: "Column used for partner graduation year source is invalid: Conversion from type 'Date' to type 'Integer' is not valid.",
          closeable: true,
        });
        return;
      }

      if(this.timekeeperSettings.gradYearType =="D" && gradYearNameLowerCase.includes('year') ){
        this.alerts.push({
          type: 'warning',
          msg: "Column used for partner graduation year source is invalid: Conversion from type 'Integer' to type 'Date' is not valid.",
          closeable: true,
        });
        return; 
      }
    }
    if (this.timekeeperSettings.compYearSource === 'C') {
      var compYearNameLowerCase = this.timekeeperSettings.compYearName.toLowerCase();
      if(this.timekeeperSettings.compYearType =="Y" && compYearNameLowerCase.includes('date') ){
        this.alerts.push({
          type: 'warning',
          msg: "Column used for associate graduation year source is invalid: Conversion from type 'Date' to type 'Integer' is not valid.",
          closeable: true,
        });
        return;
      }

      if(this.timekeeperSettings.compYearType =="D" && compYearNameLowerCase.includes('year') ){
        this.alerts.push({
          type: 'warning',
          msg: "Column used for associate graduation year source is invalid: Conversion from type 'Integer' to type 'Date' is not valid.",
          closeable: true,
        });
        return;
      }
    }
    this.isTimeKeeperUpdated = true;
    // eslint-disable-next-line max-len
    this.service
      .post(
        environment.FIClientBaseEndpoint + 'v1/firm/firmparameters/timekeeper/' + this.firmId,
        this.timekeeperSettings
      )
      .subscribe(
        (result) => {
          this.resetTimekeeperSettings = JSON.parse(JSON.stringify(this.timekeeperSettings));
          this.firmParameterModel.timekeeperSettings = this.timekeeperSettings;
          eventDispatcher.next({type: ActionTypes.SetFirmParameterSetting, payload: this.firmParameterModel});
          this.successMsg = 'Data modified successfully.';
          this.isTimeKeeperUpdated = false;
          this.alerts.push({
            type: 'success',
            msg: this.successMsg,
            timeout: 2500,
            closeable: true,
          });
        },
        (error) => {
          this.isTimeKeeperUpdated = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }
}
