import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  constructor() {}

  private emitAccessDeniedSource = new Subject();

  accessDeniedEmitted$ = this.emitAccessDeniedSource.asObservable();

  emitAccessDenied(message?: string) {
    this.emitAccessDeniedSource.next(message);
  }
}
