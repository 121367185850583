export default class FirmDetailModel {
  public id: number;
  public name: string;
  public status: number;
  public overallRanking: number;
  public numberOfAttorneys: number;
  public mainOfficeCountry: string;
  public mainOfficeState: string;
  public mainOfficeCity: string;
  public currency: string;
  public defaultAccess: string;
  public practiceDisplayFormat: string;
  public titleDisplayFormat: string;
  public metricDisplayFormat: string;
  public dataLag: string;
  public peerGroupList: string;
  public performancePeerGroupList: string;
  public onePass: boolean;
  public peerGroupsAllowed: boolean;
  public expirationDate?: string;
  public constructor() {
    this.expirationDate = null;
  }
}
