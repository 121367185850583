<bento-alert [items]="alerts"></bento-alert>
<main id="MainContent" tabindex="-1" class="Main" role="main" [bentoBusyLoader]="isDataLoading">
  <header class="Main-header">
    <div class="row">
      <div class="col">
        <app-global-breadcrumbs
          [isFirmPeerGroup]="true"
          (updateService)="backToCurrentView()"
          [peerLabel]="selectedViewData.viewName"
          [firmName]="'Current views'"
        ></app-global-breadcrumbs>
      </div>
      <div class="col-3">
        <app-action-dropdown
          #actionButton
          [actionDropdownItems]="actionDropdownItems"
          (dropDownSelectChange)="dropDownOnSelectChange($event)"
        ></app-action-dropdown>
      </div>
    </div>
    <h1 class="Main-heading mb-1 d-flex align-items-center">
      {{ selectedViewData.viewName }}
      <span class="badge badge-info ml-3" *ngIf="selectedViewData.default">Default</span>
    </h1>
  </header>
  <div *ngIf="isDisplayGrid" class="Main-body Main-body--viewgrid">
    <app-view-data-grid
      #ViewGrid
      [userSettingsModel]="userSettingsModel"
      [firmBasicData]="firmBasicData"
      [viewDetails]="viewDetails"
      [pcViewCols]="pcViewCols"
      [shouldCardDisplay]="true"
      [shouldDisplayPivot]="true"
      [allowViewSettings]="true"
      [showDescription]="false"
      [shouldCheckMetricGroup]="false"
      [showCustomDescription]="true"
      [isTAdmin]="isTAdmin"
      (dataLoadingStateChange)="dataLoadingStateChange($event)"
      [expandDataRaw]="expandDataRaw"
      (dragDone)="onDragDone($event)"
    ></app-view-data-grid>
  </div>
</main>
