<style nonce="cssRand0m">
  ::ng-deep .dropdown-item-nested {
    padding: 0.5rem 2rem !important;
  }
  ::ng-deep .dropdown-item-root {
    padding: 0.5rem 1rem !important;
    font-weight: 600 !important;
    pointer-events: none !important;
  }
</style>
<div class="Actions justify-content-end">
  <div class="Actions-item">
    <div ngbDropdown class="dropdown" placement="bottom-right">
      <button
        ngbDropdownToggle
        id="actionsDropDownButton"
        aria-haspopup="true"
        aria-controls="actionsDropDown"
        class="btn btn-primary Actions-dropDownButton"
        type="button"
        [disabled]="disable"
      >
        <span class="btn-text">Actions</span>
        <i class="bento-icon-caret-down-filled" aria-hidden="true"></i>
      </button>
      <div
        ngbDropdownMenu
        id="actionsDropDown"
        role="menu"
        aria-labelledby="actionsDropDownButton"
        placement="bottom-right"
      >
        <ng-container *ngFor="let item of actionDropdownItems">
          <button
            ngbDropdownItem
            role="button"
            [ngClass]="item.options && item.options.length ? 'dropdown-item-root' : 'dropdown-item'"
            name="{{ item.name }}"
            [disabled]="item.isDisabled"
            (click)="dropDownOnSelectChange($event.target.name)"
          >
            {{ item.name }}
          </button>
          <ng-container *ngFor="let nestedItem of item.options">
            <button
              ngbDropdownItem
              role="button"
              class="dropdown-item-nested"
              name="{{ nestedItem.name }}"
              [disabled]="nestedItem.isDisabled"
              (click)="dropDownOnSelectChange($event.target.name)"
            >
              {{ nestedItem.name }}
            </button>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
