import {FixedTimeRange, ViewsSettings} from './create-view.model';

export class CreateChart {
  visualizationGraphType: string;
  templateKey: string;
  templateId: string;
  chartId: string;
  orignalName: string;
  name: string;
  description: string;
  selectedMetrics: Option[];
  selectedMetricsText: string;
  selectedDateRangeType: Option;
  dateRange: DateRange;
  selectedPeerGroups: Option[];
  selectedPeerGroupsText: string;
  selectedPracticeGroups: Option[];
  selectedPracticeGroupsText: string;
  selectedOffices: Option[];
  selectedOfficesText: string;
  selectedTitles: Option[];
  selectedTitlesText: string;
  selectedAssociateYears: Option[];
  selectedAssociateYearsText: string;
  selectedSrAssociateYears: Option[];
  selectedSrAssociateYearsText: string;
  selectedPartnerYears: Option[];
  selectedPartnerYearsText: string;
  customRange: FixedTimeRange;
  viewsSettings: ViewsSettings;
  currentPeriodStartDate: string | Date;
  currentPeriodEndDate: string | Date;
  priorPeriodStartDate: string | Date;
  priorPeriodEndDate: string | Date;
  favorite?: boolean;
  isCanadianFirm?: boolean;

  constructor() {
    this.visualizationGraphType = 'Column';
    this.templateKey = 'KPM-V1';
    // this.currentPeriodStartDate = new Date(this.currentPeriodStartDate);
    // this.currentPeriodEndDate = new Date(this.currentPeriodEndDate);
    // this.priorPeriodStartDate = new Date(this.priorPeriodStartDate);
    // this.priorPeriodStartDate = new Date(this.priorPeriodStartDate);
  }
}

export enum DateRangeType {
  YOYMonth = 'Year over Year Monthly comparison',
  YOYQuarter = 'Year over Year Quarterly comparison',
  YOYTD = 'Year over Year to Date comparison',
}

export enum VisualizationOptions {
  MyVisualizations = 'My visualizations',
  VisualizationTemplates = 'Visualization templates',
}

export enum VisualizationTypes {
  KPMV1 = 'kpm-v1',
  DPA = 'dgpg-v1',
  YOYGTEMPLATEID = '10',
}

export class DateRange {
  startDateRange: FixedRange;
  endDateRange: FixedRange;
}

export class FixedRange {
  years: Selection;
  month: Selection;
  quarter: Selection;
  date?: any;
}

export class Metric {
  groupId: number;
  groupName: string;
  id: number;
  name: string;
}

export class ExperienceYear {
  id: number;
  name: string;
}
export class Selection {
  id: number;
  value: any;
  name: string;
  ofYear?: any;
}

export class ProcessedVisualization {
  isCalledForCard: boolean;
  processedData: any;
  processedColumn: any;
  processedChartSeries: any;
  prossedDataType: string;
  prossedDataCurrency: string;
  precessedWjColformat: string;
  originalResponse: any;
  visualizationType: string;
  hasEmptyResult?: boolean;
  simplifiedColumn: any;
  simplifiedData: any;
}

export class VisualizationTemplate {
  firmId: number;
  chartId: string;
  templateId: string;
  chartName: string;
  userId: string;
  chartTemplateId: string;
  templateName: string;
  templateKey: string;
  visiblity: string;
  currentPeriodStartDate: string | Date;
  currentPeriodEndDate: string | Date;
  priorPeriodStartDate: string | Date;
  priorPeriodEndDate: string | Date;
  dateRange: Option[];
  metrics: Fields;
  peerGroups: Fields;
  practices: Fields;
  offices: Fields;
  titles: Fields;
  statisticSetting: string;
  comparisionSetting: string;
  matterTypeSetting: string;
  contractorsSetting: string;
  currencySetting: string;
  associateYears: Option[];
  srAssociateYears: Option[];
  partnerYears: Option[];

  constructor() {
    this.currentPeriodStartDate = new Date(this.currentPeriodStartDate);
    this.currentPeriodEndDate = new Date(this.currentPeriodEndDate);
    this.priorPeriodStartDate = new Date(this.priorPeriodStartDate);
    this.priorPeriodStartDate = new Date(this.priorPeriodStartDate);
  }
}

export class Option {
  id: number;
  name: string;
  selected: boolean;
}

export class Fields {
  displayOnUi: boolean;
  minCount: number;
  maxCount: number;
  values: Option[];
}

export class ResponseHandler {
  response: any;
  hasError: boolean;
  errorMessage: any;
  alerts: any;

  constructor() {
    this.response = null;
    this.hasError = true;
    this.errorMessage = null;
    this.alerts = [];
  }
}

export class RequestHandler {
  requestedFrom: string;
  currentRequestData: any;
  priorRequestData: any;
  requestCompletionStatus: boolean;

  constructor() {}
}

export class SelectionFieldRules {
  metrics: MinMaxRules;
  peerGroup: MinMaxRules;
  practiceGroup: MinMaxRules;
  offices: MinMaxRules;
  titles: MinMaxRules;
  dateRange: MinMaxRules;

  constructor() {
    this.metrics = new MinMaxRules();
    this.peerGroup = new MinMaxRules();
    this.practiceGroup = new MinMaxRules();
    this.offices = new MinMaxRules();
    this.titles = new MinMaxRules();
    this.dateRange = new MinMaxRules();
  }
}

export class MinMaxRules {
  displayOnUi: boolean;
  minCount: number;
  maxCount: number;

  constructor() {
    this.displayOnUi = false;
    this.minCount = 0;
    this.maxCount = 0;
  }
}

export interface VisualizationList {
  chartTemplateId: string;
  templateName: string;
  templateKey: string;
  favorite: boolean;
  isExpand: boolean;
  hasErrors: boolean;
  isDataLoading: boolean;
  dateRangeRule: string;
  dateRangeSelectedIds: string;
  currentPeriodStartDate: string;
  currentPeriodEndDate: string;
  priorPeriodStartDate: string;
  priorPeriodEndDate: string;
  chartId: string;
  chartName: string;
  createdDate: string;
  modifiedDate: string;
  visualizationDetails: VisualizationTemplate;
  createChartModel: CreateChart;
}
