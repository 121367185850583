import {Component, OnInit, HostBinding} from '@angular/core';
import {AuthService as Auth0Service} from '@auth0/auth0-angular';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {AuthService} from '../../../services/auth/auth.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent implements OnInit {
  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }

  isLoading = false;
  constructor(public auth0: Auth0Service, private authService: AuthService, private service: BaseService) {}

  ngOnInit(): void {
    this.isLoading = true;
    if (this.authService.isUserAuthenticated()) {
      this.isLoading = false;
    } else {
      this.auth0.isAuthenticated$.subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this.service.post(environment.FIAdminBaseEndpoint + 'v1/user/authenticate-ciam', null).subscribe(
            (result) => {
              const {jwt} = result;
              this.authService.backendJWTToken.next(jwt);
              this.isLoading = false;
            },
            (error) => {
              this.isLoading = false;
            }
          );
        } else {
          this.isLoading = false;
        }
      });
    }
  }
}
