<div class="Actions justify-content-end">
  <div class="Actions-item">
    <div #drop ngbDropdown class="dropdown">
      <button
        ngbDropdownToggle
        id="menubutton-sorting"
        aria-haspopup="true"
        aria-controls="menu-sorting"
        aria-label="Sorting"
        ngbTooltip="Sorting"
        class="btn btn-outline-secondary btn-icon Actions-sortButton"
        type="button"
        (click)="checkDropDown(drop)"
      >
        <i class="bento-icon-filter-sorting-down" aria-hidden="true"></i>
        <i
          [ngClass]="dropdown && dropdown.isOpen() ? 'bento-icon-caret-up-filled' : 'bento-icon-caret-down-filled'"
          aria-hidden="true"
        ></i>
      </button>
      <div ngbDropdownMenu id="menu-sorting" class="dropdown-menu" role="menu" aria-labelledby="menubutton-sorting">
        <ng-container *ngFor="let item of sortingDropdownItems">
          <button
            ngbDropdownItem
            class="dropdown-item"
            role="menuitem"
            *ngIf="item.name != 'Created date'"
            name="{{ item.name }}"
            (click)="dropDownOnSelectChange(item.name)"
          >
            {{ item.name }}
          </button>
          <button
            ngbDropdownItem
            class="dropdown-item"
            role="menuitem"
            *ngIf="isChartList && item.name == 'Created date'"
            name="{{ item.name }}"
            (click)="dropDownOnSelectChange(item.name)"
            [attr.aria-label]="
              createdDateSortOrderAsc === null
                ? ''
                : createdDateSortOrderAsc === true
                ? 'Created date ascending'
                : 'Created date descending'
            "
          >
            {{ item.name }}
            <i
              [ngClass]="
                createdDateSortOrderAsc === null
                  ? ''
                  : createdDateSortOrderAsc === true
                  ? 'bento-icon-chevron-medium-up'
                  : 'bento-icon-chevron-medium-down'
              "
              aria-hidden="true"
            ></i>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
