<bento-alert [items]="alerts"></bento-alert>
<section class="Section" [bentoBusyLoader]="isSaving" *ngIf="!openCreateView">
  <header class="Section-header">
    <h2 class="Section-heading">Create</h2>
  </header>
  <span class="label-required-summary">Required field</span>
  <form name="performancePeerGroup" #ppgInfo="ngForm" class="Section-form Form" bfmForm>
    <div class="Section-body">
      <div class="form-group Form-input Form-input--medium">
        <label for="name">Name*</label>
        <div class="FormPrefix">
          <span class="FormPrefix-name bento-label-required">PPG -</span>
          <input
            type="text"
            id="name"
            name="name"
            class="form-control"
            ngDefaultControl
            bfmField
            bfmLabel="Name"
            autocomplete="off"
            required
            [(ngModel)]="performancePeerGroupData.name"
          />
        </div>
      </div>
      <div class="form-group Form-input Form-input--medium">
        <label for="criteria" class="bento-label-required">Performance criteria</label>
        <app-bento-custom-multiselect-overlay
          ngDefaultControl
          name="criteria"
          id="criteria"
          bfmLabel="Performance criteria"
          required
          bentorequired
          bfmField
          class="FormMultiselect"
          [selectButtonText]="' '"
          [overlayMaxHeight]="384"
          [editButtonText]="selectedCriteriaText"
          [(ngModel)]="performancePeerGroupData.criteria"
          (ngModelChange)="onPCriteriasItemsSelected($event)"
          [itemsObservable]="performanceCriteriaStream"
          [ariaLabelValue]="'performance criteria required'"
        ></app-bento-custom-multiselect-overlay>
      </div>
      <section class="Tile" *ngFor="let criteria of performancePeerGroupData.criteria">
        <header class="Tile-header row" role="Tile-header">
          <div class="col">
            {{ criteria.name }}
          </div>
          <div class="col-2">
            <button
              type="button"
              class="btn btn-flat-icon"
              aria-label="Close"
              ngbTooltip="Close"
              (click)="removePC(criteria.id)"
            >
              <i class="bento-icon-close-x" aria-hidden="true"></i>
            </button>
          </div>
        </header>
        <div class="Tile-body">
          <div class="form-group Tile-item">
            <label for="{{ criteria.id }}" class="bento-label-required">Parameter</label>
            <bento-combobox
              id="{{ criteria.id }}"
              name="{{ criteria.id }}"
              [(ngModel)]="parameterValues[criteria.id]['param']"
              [itemsObservable]="parameterStream"
              [options]="comboboxOptions"
              [placeholder]="' '"
              (ngModelChange)="onChangePCParam(criteria.id, $event)"
              required
            ></bento-combobox>
          </div>
          <div class="form-group Tile-item" *ngIf="parameterValues[criteria.id]['param'] != 'In between'">
            <label for="{{ criteria.id + 'Value' }}" class="bento-label-required">Number</label>
            <div class="hint-text">
              {{ parameterValues[criteria.id]['range']['min'] }} - {{ parameterValues[criteria.id]['range']['max'] }}
            </div>
            <input
              type="number"
              name="{{ criteria.id + 'Value' }}"
              id="{{ criteria.id + 'Value' }}"
              class="form-control"
              [(ngModel)]="parameterValues[criteria.id]['value']"
              bfmField
              bfmLabel="Number"
              placeholder="{{ parameterValues[criteria.id]['placeholder'] }}"
              min="{{ pcValidations[criteria.id].min }}"
              max="{{ pcValidations[criteria.id].max }}"
              [validations]="pcValidations[criteria.id].msg"
              [pattern]="pcValidations[criteria.id].pattern"
              [disabled]="parameterValues[criteria.id]['param'].length > 0 ? false : true"
              (change)="onChangeParam(criteria.id, $event)"
              required
            />
          </div>
          <div class="form-group FormRange Tile-item" *ngIf="parameterValues[criteria.id]['param'] == 'In between'">
            <div class="FormRange-group">
              <div class="FormRange-item">
                <label for="{{ criteria.id + 'MinValue' }}" class="bento-label-required">Number</label>
                <div class="hint-text">As low as {{ parameterValues[criteria.id]['range']['min'] }}</div>
                <input
                  name="{{ criteria.id + 'MinValue' }}"
                  id="{{ criteria.id + 'MinValue' }}"
                  class="form-control"
                  [(ngModel)]="parameterValues[criteria.id]['minValue']"
                  ngDefaultControl
                  bfmField
                  bfmLabel="Number"
                  placeholder="{{ parameterValues[criteria.id]['placeholder'] }}"
                  min="{{ pcValidations[criteria.id].min }}"
                  max="{{ pcValidations[criteria.id].max }}"
                  [validations]="pcValidations[criteria.id].msg"
                  [pattern]="pcValidations[criteria.id].pattern"
                  (change)="onChangeParam(criteria.id, $event)"
                  required
                />
              </div>
              <div class="FormRange-item FormRange-item--mediator">&</div>
              <div class="FormRange-item">
                <label for="{{ criteria.id + 'MaxValue' }}" class="bento-label-required">Number</label>
                <div class="hint-text">Up to {{ parameterValues[criteria.id]['range']['max'] }}</div>
                <input
                  name="{{ criteria.id + 'MaxValue' }}"
                  id="{{ criteria.id + 'MaxValue' }}"
                  class="form-control"
                  [(ngModel)]="parameterValues[criteria.id]['maxValue']"
                  ngDefaultControl
                  bfmField
                  bfmLabel="Number"
                  placeholder="{{ parameterValues[criteria.id]['placeholder'] }}"
                  min="{{ pcValidations[criteria.id].min }}"
                  max="{{ pcValidations[criteria.id].max }}"
                  [validations]="pcValidations[criteria.id].msg"
                  [pattern]="pcValidations[criteria.id].pattern"
                  (change)="onChangeParam(criteria.id, $event)"
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="form-group Form-input Form-input--medium">
        <label for="practiceGroups">Practice groups</label>
        <app-bento-custom-multiselect-overlay
          ngDefaultControl
          name="practiceGroups"
          id="practiceGroups"
          class="FormMultiselect FormMultiselect--extendSmall"
          [selectButtonText]="' '"
          [overlayMaxHeight]="384"
          [editButtonText]="selectedPGText"
          [(ngModel)]="performancePeerGroupData.practiceGroup"
          (ngModelChange)="onPracticeGroupItemsSelected($event)"
          [itemsObservable]="practiceGroupStream"
          [ariaLabelValue]="'practice groups'"
        ></app-bento-custom-multiselect-overlay>
      </div>
      <div class="form-group Form-input Form-input--medium">
        <label for="offices">Offices</label>
        <app-bento-custom-multiselect-overlay
          ngDefaultControl
          name="offices"
          id="offices"
          class="FormMultiselect"
          [selectButtonText]="' '"
          [overlayMaxHeight]="344"
          [editButtonText]="selectedOfficeText"
          [(ngModel)]="performancePeerGroupData.offices"
          (ngModelChange)="onOfficeItemsSelected($event)"
          [itemsObservable]="officeStream"
          [ariaLabelValue]="'offices'"
        ></app-bento-custom-multiselect-overlay>
      </div>
      <ng-container
        *ngIf="(isFilterPCApplied || isFilterOfficesApplied || isFilterPGApplied) && !hideNumberOfFirmAndPreviewButton"
      >
        <div class="alert alert-info FirmsAlert" role="alert" *ngIf="firmCount > 0">
          <span class="font-weight-semi-bold">{{ firmCount }}</span> firms are available in this peer group
        </div>
      </ng-container>
    </div>
    <footer class="Section-footer">
      <div class="Actions">
        <div class="Actions-item">
          <button type="button" class="btn btn-outline-secondary" (click)="handleCancel()">
            <span class="btn-text">Cancel</span>
          </button>
        </div>
        <div class="Actions-item a11y" *ngIf="isTAdmin && !hideNumberOfFirmAndPreviewButton">
          <button
            type="button"
            class="btn btn-outline-secondary"
            aria-live="polite"
            [attr.aria-disabled]="disablePreview ? 'true' : 'false'"
            (click)="disablePreview ? $event.stopPropagation() : previewPPG()"
            [attr.aria-labelledby]="disablePreview ? 'aria-required-preview' : null"
          >
            <span class="btn-text">Preview</span>
          </button>
        </div>
        <div class="Actions-item a11y">
          <button
            type="submit"
            class="btn btn-primary"
            aria-live="polite"
            [attr.aria-disabled]="ppgInfo.invalid ? 'true' : 'false'"
            (click)="ppgInfo.invalid ? $event.stopPropagation() : savePPG()"
            [attr.aria-labelledby]="ppgInfo.invalid ? 'aria-required-save' : null"
          >
            <span class="btn-text">Save</span>
          </button>
        </div>
        <span class="sr-only" id="aria-required-preview">Fill required fields to preview</span>
        <span class="sr-only" id="aria-required-save">Fill required fields to save</span>
      </div>
    </footer>
  </form>
</section>

<section class="Section mt-3" *ngIf="isPreview">
  <header class="Section-header mb-3">
    <h2 class="Section-heading mb-3" id="performanceFirmsHead">{{ listFirmsHead }}</h2>
    <span class="sr-only" id="performanceFirmsHeadName"
      >{{ listFirmsHead }} Grid {{ currentRecords }} rows {{ colCount }} columns</span
    >
    <app-grid-record-details [currentRecords]="currentRecords" [totalRecords]="totalRecords"></app-grid-record-details>
  </header>
  <div class="Section-body">
    <wj-flex-grid
      #flexGrid
      class="DataGrid"
      [itemsSource]="data"
      [headersVisibility]="'Column'"
      [autoClipboard]="false"
      [isReadOnly]="true"
      [allowDragging]="false"
      [autoRowHeights]="true"
      (initialized)="gridInitialized(flexGrid)"
      (sortingColumn)="sortGrid($event.getColumn().binding)"
    >
      <wj-flex-grid-column
        [header]="'Firm ID'"
        [binding]="'id'"
        [align]="'left'"
        [width]="120"
        [wordWrap]="true"
      ></wj-flex-grid-column>
      <wj-flex-grid-column
        [header]="'Firm name'"
        [binding]="'name'"
        [width]="'*'"
        [allowResizing]="false"
        [wordWrap]="true"
      ></wj-flex-grid-column>
    </wj-flex-grid>
    <bento-pagination
      [page]="data.pageIndex + 1"
      [directionLinks]="true"
      [boundaryLinks]="true"
      [totalItems]="data.totalItemCount"
      [(itemsPerPage)]="data.pageSize"
      (itemsPerPageChange)="onItemsPerPageChanged($event)"
      (pageChange)="onPageChanged($event)"
    ></bento-pagination>
  </div>
</section>
