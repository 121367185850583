import {BehaviorSubject} from 'rxjs';

export class RatesReportModal {
  fiscalPeriodFrom: null | any = null;
  fiscalPeriodTo: null | any = null;
  peerGroups: any;
  firm: any;
  currency: any = 'USD';
  practices: any;
  fixedTimeRange: FixedTimeRange = new FixedTimeRange();

  offices: any;
  startPeriod: any;
  endPeriod: any;
  isBillable = true;
  isContingent = true;
  isContractorInclude = false;
  isGranular = false;
  selectedPeerGroupText: string;
  selectedPracticeGroupText: string;
  selectedOfficeText: string;
  rollingTimeRangeSelectedItems: RollingTimeRange;
  timeRangeSelected: any;
  isRollingTimeRange = true;
  email: any;
  contractors: string[] = [];
  matterTypes: string[] = [];
  reportType: string;
  peerGroupStream: BehaviorSubject<string[]>;
  currencyStream: BehaviorSubject<string[]>;
  practiceGroupStream: BehaviorSubject<string[]>;
  officeStream: BehaviorSubject<string[]>;
}

export class RollingTimeRange {
  name: string;
  id: string;
  startPeriod1: string;
  endPeriod1: string;
  startPeriod2: string;
  endPeriod2: string;
}

export class FixedTimeRange {
  startDateText: string;
  startDateText2: string;
  startDateValue: string;
  startPeriod1: string;
  startPeriod2: string;

  endDateText: string;
  endDateText2: string;
  endDateValue: string;
  endPeriod1: string;
  endPeriod2: string;
}
