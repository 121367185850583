import {Directionality} from '@angular/cdk/bidi';
import {OnInit, TemplateRef} from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Keyboard, WindowRef} from '@bento/bento-ng';
import {BentoTranslatable} from '@bento/bento-ng/lib/i18n/translatable.interface';
import {Observable, Subscription} from 'rxjs';
import {BentoMultiselectListComponent, Selection} from './multiselect-list.component';
const OVERLAY_MARGIN = 20;
const DEFAULT_MAX_HEIGHT = 600;
const BENTO_MO_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BentoCustomMultiselectOverlayComponent),
  multi: true,
};

@Component({
  selector: 'app-bento-custom-multiselect-overlay',
  templateUrl: './bento-custom-multiselect-overlay.component.html',
  styleUrls: ['./bento-custom-multiselect-overlay.component.scss', '_side-overlay.scss'],
  providers: [BENTO_MO_VALUE_ACCESSOR],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BentoCustomMultiselectOverlayComponent implements OnDestroy, ControlValueAccessor, BentoTranslatable {
  /**
   * Select button text override
   */
  @Input() selectButtonText: string;

  @Input() isRatesReport: boolean;

  /**
   * Edit button text override
   */
  @Input() editButtonText: string;

  /**
   * All Selected info text override
   */
  @Input() allSelectedInfoText: string;

  /**
   * Selected info text override
   */
  @Input() selectedInfoText: string;

  /**
   * Aria labelledBy text override
   */
  @Input() ariaLabelValue: string;

  /**
   * Aria labelledBy text override
   */
  @Input() ariaFieldHeadingId: string;

  /**
   * Aria labelledBy text override
   */
  @Input() ariaFieldHintTextId: string;

    /**
   * Aria labelledBy text override
   */
    @Input() ariaRequired: boolean;
  
  /**
   * Done button text override
   */
  @Input() doneButtonText: string;

  /**
   * Cancel button text override
   */
  @Input() cancelButtonText: string;

  /**
   * Search input field label override
   */
  @Input() searchLableText: string;

  /**
   * Search input field help text override
   */
  @Input() searchLableHelpText?: string = '';

  /**
   * Main items dataset
   */
  @Input() itemsObservable: Observable<any>;

  /**
   * Show All text override
   */
  @Input() showAllText: string;

  /**
   * Show Selected text override
   */
  @Input() showSelectedText: string;

  /**
   * Select All text override
   */
  @Input() selectAllText: string;

  /**
   * Flag to allow the component to revert selection when a selection session is dismissed
   */
  @Input() revertSelectionsOnDismissal: boolean;

  /**
   * Custom template for list items except the checkbox elements
   */
  @Input() listItemTemplate: TemplateRef<any>;

  /**
   * Set max height of the overlay in pixel. (Default `600`)
   */
  @Input() overlayMaxHeight: number = DEFAULT_MAX_HEIGHT;

  /**
   * The maximum number of items that can be selected by the user.  0 is the default, indicates no restrictions.
   */
  @Input() maxSelectedItems: number;

  /**
   * The minimum number of items that can be selected by the user.  0 is the default, indicates no restrictions.
   */
  @Input() minSelectedItems: number;

  /**
   * The option can be selected by the user whether to show select all option on top of the list.
   */
  @Input() isSelectAllVisible: boolean;

  /**
   * An custom search and compare function to determine if a given item is a match to the search string
   * when an user starts to filter the list using the search input
   */
  @Input() searchCompare: (item: any, search: string) => boolean;

  /**
   * Variable to keep custom translations that are defined specifically for this component instance
   */
  customTranslation: any = {};

  /**
   * Not for public
   */

  @ViewChild('overlay', {static: true}) overlay: ElementRef;
  @ViewChild('toggleButton', {static: true}) toggleButton: ElementRef;
  @ViewChild('containerWrapper', {static: true}) containerWrapper: ElementRef;
  @ViewChild('doneButton', {static: true}) doneButton: ElementRef;
  @ViewChild('cancelButton') cancelButton: ElementRef;
  @ViewChild('overlayContainer', {static: true}) overlayContainer: ElementRef;
  @ViewChild('overlayFooter', {static: true}) overlayFooter: ElementRef;
  @ViewChild('label', {static: true}) label: ElementRef;
  @ViewChild(BentoMultiselectListComponent, {static: true}) multiselectList: BentoMultiselectListComponent;

  _isOpen = false;
  _showOverlay = false;

  /**
   * ngModel Variables
   */
  selectedList: any[] = [];
  _preselectedList: any[] = [];
  onChange: (_: any) => void;
  onTouch: () => void;

  _disabled = false;
  _side = 'right';
  _scrollableParent: HTMLElement;
  _listHeight = 0;
  _listWidth: number;
  _itemsLength: number;

  // Window Listener Vars
  _onWindowResizeCancel: () => void;
  _onWindowClickCancel: () => void;
  _onWindowTouchCancel: () => void;
  _windowWheelCancel: () => void;
  _windowKeydownCancel: () => void;
  _windowResizeTimeoutID;

  private document: Document;
  private window;
  private isRTL = false;
  private activeElement: HTMLElement;
  private dirSubscription = Subscription.EMPTY;
  ariaLabelValueId:any="";
  ariaLabelValueLableId:any="";
  ariaRequiredLabelText:any="";
  ariaFieldHeadingValueId:any="";
  ariaFieldHintTextValueId:any="";
  constructor(
    private elRef: ElementRef,
    private r: Renderer2,
    dir: Directionality,
    windowRef: WindowRef,
    private changeDetector: ChangeDetectorRef
  ) {
    this.onChange = (_: any) => {};
    this.onTouch = () => {};
    this.window = windowRef.nativeWindow;
    this.document = windowRef.nativeDocument;
    this.dirSubscription = dir.change.subscribe(() => {
      this.isRTL = dir.value === 'rtl';
      this.changeDetector.markForCheck();
    });
  }
  ngOnInit(): void {
    
    this.ariaLabelValueId = this.ariaLabelValue.replace(/\s/g, ""); 
    this.ariaLabelValueLableId = this.ariaLabelValueId+'-label';
    this.ariaLabelValueId = this.ariaLabelValueId+'-id';
    if(this.ariaRequired)
    this.ariaRequiredLabelText = "* required ";

    if(this.ariaFieldHeadingId)
        this.ariaFieldHeadingValueId = this.ariaFieldHeadingId;
      else
        this.ariaFieldHeadingValueId ="";

      if(this.ariaFieldHintTextId)
      this.ariaFieldHintTextValueId = this.ariaFieldHintTextId
    else
      this.ariaFieldHintTextValueId ="";
  }
  ngAfterViewInit() {
    // Add keydown event listener to the Done button
    this.doneButton.nativeElement.addEventListener('keydown', this._onDoneButtonKeydown.bind(this));
  }
  _onDoneButtonKeydown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault(); // Prevent default Tab behavior
      this.multiselectList.focusOnShowAllButton(); // Focus on Show All button
    }
  }
  /**
   * ControlValueAccessor interface
   */
  writeValue(v) {
    if (this.selectedList === v) {
      return;
    }

    this.selectedList = v || [];
    this._preselectedList = this.selectedList.slice();
    this.changeDetector.markForCheck();
  }

  registerOnChange(fn: (v: any) => {}) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}) {
    this.onTouch = () => {
      fn();
      this.changeDetector.markForCheck();
    };
  }

  setDisabledState(v: boolean) {
    this._disabled = v;
    this.changeDetector.markForCheck();
  }

  /**
   * Custom translations can be set here.
   *
   * Translation labels that are used:
   * * `BENTO_UI_MULTISELECT_OVERLAY_ALL_SELECTED_INFO`
   * * `BENTO_UI_MULTISELECT_OVERLAY_CANCEL`
   * * `BENTO_UI_MULTISELECT_OVERLAY_DONE`
   * * `BENTO_UI_MULTISELECT_OVERLAY_EDIT`
   * * `BENTO_UI_MULTISELECT_OVERLAY_SELECT`
   * * `BENTO_UI_MULTISELECT_OVERLAY_SELECT_ALL`
   * * `BENTO_UI_MULTISELECT_OVERLAY_SELECTED_INFO`
   * * `BENTO_UI_MULTISELECT_OVERLAY_SHOW_ALL`
   * * `BENTO_UI_MULTISELECT_OVERLAY_SHOW_SELECTED`
   * * `BENTO_UI_MULTISELECT_OVERLAY_SEARCH_LABEL`
   */
  setTranslation(translation: any) {
    this.customTranslation = translation;
    this.changeDetector.markForCheck();
  }

  /**
   * Garbage cleaning on destroy
   */
  ngOnDestroy() {
    this.changeDetector.detach();

    if (this._onWindowClickCancel) {
      this._onWindowClickCancel();
    }

    if (this._onWindowTouchCancel) {
      this._onWindowTouchCancel();
    }

    if (this._onWindowResizeCancel) {
      this._onWindowResizeCancel();
    }

    if (this._windowWheelCancel) {
      this._windowWheelCancel();
    }

    if (this._windowKeydownCancel) {
      this._windowKeydownCancel();
    }

    this.dirSubscription.unsubscribe();
  }

  /**
   * On window click
   */
  _onWindowClick(event) {}

  /**
   * Prevent Wheel Scroll
   */
  _preventWheelScroll(event) {
    if (!this.containerWrapper.nativeElement.contains(event.target)) {
      event.stopPropagation();
    }
  }

  /**
   * Window keydown event listener
   */
  _onWindowKeydown(e: KeyboardEvent) {
    const keysToPrevent = {};
    keysToPrevent[Keyboard.SPACE] = 0;
    keysToPrevent[Keyboard.PAGE_UP] = 1;
    keysToPrevent[Keyboard.PAGE_DOWN] = 1;
    keysToPrevent[Keyboard.END] = 1;
    keysToPrevent[Keyboard.HOME] = 1;
    keysToPrevent[Keyboard.ESC] = 1;

    if (keysToPrevent[e.keyCode]) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.keyCode === Keyboard.ESC) {
      this._onDoneClick(e, true);
      this.changeDetector.markForCheck();
    }
  }

  /**
   * Callback when selection is changed
   */
  _onSelectionChange(selection: any[]) {
    this._preselectedList = selection;
  }

  /**
   * On Toggle button click
   */
  _onToggleClick($event) {
    if (!this._isOpen) {
      this._isOpen = true;

      // Reset Selection tab
      this.multiselectList.show(Selection.all);

      setTimeout(() => {
        // `click` should not be used since the items clicked in the overlay could disappear before
        // the window recieves its event
        this._onWindowClickCancel = this.r.listen('window', 'mousedown', (e) => this._onWindowClick(e));
        this._onWindowTouchCancel = this.r.listen('window', 'touchstart', (e) => this._onWindowClick(e));

        // Render after the overlay is not hidden on stage
        this._onWindowResizeCancel = this.r.listen('window', 'resize', (e) => this._onWindowResize(e));

        this.renderOverlay();

        this.multiselectList.show(Selection.all);
        this.multiselectList.refreshView();
        

        // UX: focus on to the show all button
        this.multiselectList.focusOnShowAllButton();
      });

      this._windowWheelCancel = this.r.listen('window', 'wheel', (e) => {
        this._preventWheelScroll(e);
      });

      this._windowKeydownCancel = this.r.listen('window', 'keydown', (e: KeyboardEvent) => {
        this._onWindowKeydown(e);
      });
    } else {
      this._onDoneClick($event);
    }
  }

  /**
   * When the done button is clicked
   * This is also used with the cancel button
   */
  _onDoneClick(event, isDismissal?) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.multiselectList.focusOnShowAllButton();
    this.multiselectList.clearSearchItem();

    // Remove animation flag
    this.r.removeClass(this.containerWrapper.nativeElement, 'in');
    this.r.addClass(this.containerWrapper.nativeElement, 'out');

    setTimeout(() => {
      this._isOpen = false;
      this._showOverlay = false;
      this.r.removeClass(this.containerWrapper.nativeElement, 'out');
      this.r.appendChild(this.overlay.nativeElement, this.containerWrapper.nativeElement);
      this.changeDetector.markForCheck();
    
      // Focus management after using escape key //
      if (this.selectedList.length === 0 && this.toggleButton && this.toggleButton.nativeElement) {
        // Focus back to the toggle button if nothing is selected
        this.toggleButton.nativeElement.focus();
      } else if (this.label && this.label.nativeElement) {
        // Focus on the label
        setTimeout(() => {
          // After rendering
          this.label.nativeElement.focus();
        });
      } 
    }, 200);

    

    if (this._windowWheelCancel) {
      this._windowWheelCancel();
    }

    if (this._windowKeydownCancel) {
      this._windowKeydownCancel();
    }

    // remove window listener
    if (this._onWindowClickCancel) {
      this._onWindowClickCancel();
    }

    if (this._onWindowTouchCancel) {
      this._onWindowTouchCancel();
    }

    // Render after the overlay is not hidden on stage
    if (this._onWindowResizeCancel) {
      this._onWindowResizeCancel();
    }

    if (this.revertSelectionsOnDismissal && isDismissal) {
      this._preselectedList = this.selectedList.slice();
    } else {
      this.selectedList = this._preselectedList.slice();
      this.onChange(this.selectedList);
    }

    if (this.selectedList.length === 0 && this.toggleButton && this.toggleButton.nativeElement) {
      // focus back to the toggle button
      // since there is nothing that is selected
      this.toggleButton.nativeElement.focus();
    } else if (this.label && this.label.nativeElement) {
      // focus on the label for screen readers to broadcast
      setTimeout(() => {
        // After rendering
        this.label.nativeElement.focus();
      });
    }
  }

  /**
   * Render overlay location
   */
  private renderOverlay() {
    const parentElement = this.containerWrapper.nativeElement;
    const overlayContainer = this.overlayContainer.nativeElement;
    const overlay = this.overlay.nativeElement;
    const toggleButton = this.toggleButton.nativeElement;
    const doneButton = this.doneButton.nativeElement;
    const overlayFooter = this.overlayFooter.nativeElement;

    const viewport = {
      width: Math.max(this.document.documentElement.clientWidth, this.window.innerWidth || 0),
      height: Math.max(this.document.documentElement.clientHeight, this.window.innerHeight || 0),
    };

    this._scrollableParent = this.getScrollableParent(this.elRef.nativeElement);

    if (viewport.width < 601 || viewport.height < 421) {
      this.r.addClass(parentElement, 'bento-side-overlay-container-wrapper-fixed');
      this.r.addClass(parentElement, 'open');
      this.r.appendChild(this.document.body, parentElement);

      this._listHeight = overlayContainer.offsetHeight - overlayFooter.offsetHeight - 2;
      this._listWidth = overlayContainer.offsetWidth - 2;
    } else {
      this.r.removeClass(parentElement, 'bento-side-overlay-container-wrapper-fixed');
      this.r.removeClass(parentElement, 'open');
      this.r.appendChild(overlay, this.containerWrapper.nativeElement);
      const maxOverlayHeight = Math.min(
        this.overlayMaxHeight,
        this._scrollableParent.offsetHeight - OVERLAY_MARGIN * 2
      );
      const overlayInnerHeight = 0;
      const toggleOffset = this._getElementOffsetFrom(toggleButton, this._scrollableParent);
      const toggleOffsetTop =
        toggleOffset.top - (this.document.documentElement.scrollTop || this.document.body.scrollTop);
      let toggleCenterOffsetTop = toggleOffsetTop + toggleButton.offsetHeight * 0.5;

      // check toggle button location and realign if needed
      // for better layout spacing
      const allowedTopSpacing = OVERLAY_MARGIN + 25;
      let overlayHeight;
      let targetOverlayTop;

      // adjust overlay height based on the scrollable container or body
      if (overlayInnerHeight > 0) {
        // overlayHeight is bigger than max-height
        if (overlayInnerHeight > maxOverlayHeight - overlayFooter.offsetHeight) {
          overlayHeight = maxOverlayHeight;
        } else {
          overlayHeight = overlayInnerHeight + overlayFooter.offsetHeight;
        }
      } else {
        overlayHeight = maxOverlayHeight;
      }

      this.r.setStyle(overlayContainer, 'height', overlayHeight + 'px');

      this._listHeight = maxOverlayHeight - overlayFooter.offsetHeight;
      this._listWidth = 0; // reset width

      // Get the overlay height of max is updated
      overlayHeight = overlayContainer.offsetHeight;

      // when the scrollable is <BODY>
      if (this._scrollableParent.tagName === 'HTML' || this._scrollableParent.tagName === 'BODY') {
        const scrollingElement = this.document.scrollingElement || this.document.documentElement;

        // We need to scroll up a little bit
        if (toggleCenterOffsetTop < allowedTopSpacing) {
          this.r.setProperty(scrollingElement, 'scrollTop', toggleOffset.top - allowedTopSpacing);
          toggleCenterOffsetTop = allowedTopSpacing + toggleButton.offsetHeight * 0.5;
        } else if (toggleCenterOffsetTop > this._scrollableParent.offsetHeight - allowedTopSpacing) {
          // We need to scroll down a little
          this.r.setProperty(
            scrollingElement,
            'scrollTop',
            toggleOffset.top + toggleButton.offsetHeight - this._scrollableParent.offsetHeight + allowedTopSpacing
          );
          toggleCenterOffsetTop =
            this._scrollableParent.offsetHeight - allowedTopSpacing - toggleButton.offsetHeight * 0.5;
        }

        // Set default
        targetOverlayTop = -overlayHeight * 0.5;

        if (-targetOverlayTop > toggleCenterOffsetTop - OVERLAY_MARGIN) {
          // Make sure the overlay is not overflowing the top
          targetOverlayTop = -(toggleCenterOffsetTop - OVERLAY_MARGIN + toggleButton.offsetHeight * 0.25);
        } else if (
          overlayHeight + targetOverlayTop >
          this._scrollableParent.offsetHeight - toggleCenterOffsetTop - OVERLAY_MARGIN
        ) {
          // Make sure the overlay is not overflowing the bottom
          targetOverlayTop =
            this._scrollableParent.offsetHeight -
            toggleCenterOffsetTop -
            OVERLAY_MARGIN -
            overlayHeight -
            toggleButton.offsetHeight * 0.25;
        }
      } else {
        // Scrollable is other

        if (toggleOffset.bottom < allowedTopSpacing) {
          // We need to scroll up a little bit
          this.r.setProperty(
            this._scrollableParent,
            'scrollTop',
            this._scrollableParent.scrollTop + allowedTopSpacing - toggleOffset.bottom
          );
        } else if (toggleOffset.top < allowedTopSpacing) {
          // We need to scroll down a little
          this.r.setProperty(
            this._scrollableParent,
            'scrollTop',
            this._scrollableParent.scrollTop - allowedTopSpacing - toggleOffset.top
          );
        }

        const overlayLocalRect = this._getElementOffsetFrom(overlayContainer, this._scrollableParent);

        // set default
        targetOverlayTop = -overlayContainer.offsetHeight * 0.5;

        if (targetOverlayTop < OVERLAY_MARGIN) {
          // Make sure the overlay is not overflowing the top
          targetOverlayTop = overlayContainer.offsetTop - overlayLocalRect.top + OVERLAY_MARGIN;
        } else if (overlayLocalRect.bottom + targetOverlayTop < OVERLAY_MARGIN) {
          // Make sure the overlay is not overflowing the bottom
          targetOverlayTop = overlayContainer.offsetTop + overlayLocalRect.bottom - OVERLAY_MARGIN;
        }

        if (targetOverlayTop > -doneButton.offsetHeight * 0.5 - OVERLAY_MARGIN) {
          targetOverlayTop = -doneButton.offsetHeight * 0.5 - OVERLAY_MARGIN;
        } else if (targetOverlayTop + overlayLocalRect.height < doneButton.offsetHeight * 0.5 + OVERLAY_MARGIN) {
          targetOverlayTop = -overlayLocalRect.height + doneButton.offsetHeight * 0.5 + OVERLAY_MARGIN;
        }
      }

      this.r.setStyle(overlayContainer, 'top', targetOverlayTop + 'px');

      this.r.setStyle(
        overlayContainer,
        'transform-origin',
        `${this.isRTL ? '100%' : '0%'} ${Math.abs(targetOverlayTop / overlayContainer.offsetHeight) * 100}%`
      );
      this.r.addClass(parentElement, 'in');
    }

    // animate in
    if (!this._showOverlay) {
      setTimeout(() => {
        this._showOverlay = true;
        this.changeDetector.markForCheck();
      }, 10);
    }
  }

  /**
   * Event Listener when the window is resizing
   */
  _onWindowResize(e) {
    if (this._isOpen) {
      clearTimeout(this._windowResizeTimeoutID);
      if (this.elRef.nativeElement.contains(this.document.activeElement)) {
        this.activeElement = this.document.activeElement as HTMLElement;
      }
      this._windowResizeTimeoutID = setTimeout(() => {
        this.renderOverlay();
        if (this.activeElement) {
          this.activeElement.focus();
        }
      }, 100);
    }
  }

  /**
   * Get offset of el1 from el2
   */
  _getElementOffsetFrom(el, fromEl) {
    const parentRect = fromEl.getBoundingClientRect();
    const toggleRect = el.getBoundingClientRect();

    // Return the relative RECT of the toggle button to the given parent
    return {
      left: toggleRect.left - parentRect.left,
      right: parentRect.right - toggleRect.right,
      top: toggleRect.top - parentRect.top,
      bottom: parentRect.bottom - toggleRect.bottom,
      width: toggleRect.width,
      height: toggleRect.height,
    };
  }

  /**
   * Monitor when this user blurs from this component
   */
  @HostListener('focusout', ['$event']) _onFocusout(e: FocusEvent) {
    setTimeout(() => {
      if (!this.elRef.nativeElement.contains(this.document.activeElement)) {
        this.onTouch();
      }
    });
  }

  /**
   * Get the closest scrollable parent
   */
  private getScrollableParent(el) {
    if (el === null) {
      return;
    }

    const cStyle = this.window.getComputedStyle(el);
    const overflowY = cStyle.getPropertyValue('overflow-y');
    const overflow = cStyle.getPropertyValue('overflow');
    const display = cStyle.getPropertyValue('display');

    if (
      (display.indexOf('block') > -1 || display.indexOf('flex') > -1) &&
      (overflowY === 'auto' ||
        overflowY === 'scroll' ||
        overflow === 'auto' ||
        overflow === 'scroll' ||
        overflow === 'hidden' ||
        overflowY === 'hidden')
    ) {
      return el;
    } else if (el.nodeName === 'HTML') {
      return el;
    } else {
      return this.getScrollableParent(el.parentElement);
    }
  }

  /**
   * Callback when the length of the items dataset is changed
   */
  _itemsLengthChange(v: number) {
    this._itemsLength = v;
  }
}
