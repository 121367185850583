export default class UserModel {
  public userId: string;
  public lastName: string;
  public firstName: string;
  public email: string;
  public role: string;
  public usesPMM: boolean;
  public currency: string;
  public firmId: number;
  public isApiUser: boolean = false;
  public isStaffingratioUser: boolean = false;
  public constructor() {
    this.currency = 'USD';
    this.role = 'guser';
    this.usesPMM = false;
    this.isStaffingratioUser = false;
    this.isApiUser = false;
  }
}
