import {BehaviorSubject} from 'rxjs';
export class ProductionExtractFilterModel {
  selectedTimeTypeText: string;
  selectedValuesTypeText: string;
  selectedPersonText: string;
  selectedFirmTitleText: string;
  selectedPmTitleText: string;
  selectedFirmOfficeText: string;
  selectedDeptText: string;
  selectedSectionText: string;
  selectedUdfText: string;
  selectedPeerOfficeText: string;
  selectedPeerPracticeText: string;
  selectedPeerTitleText: string;
  selectedPracticeGroupText: string;
  selectedLocText: string;
  selectedTkinitsText: string;

  timeTypeStream: BehaviorSubject<string[]>;
  personStream: BehaviorSubject<any>;
  firmTitleStream: BehaviorSubject<string[]>;
  pmTitleStream: BehaviorSubject<string[]>;
  firmOfficeStream: BehaviorSubject<string[]>;
  departmentStream: BehaviorSubject<string[]>;
  peerTitleStream: BehaviorSubject<string[]>;
  peerOfficeStream: BehaviorSubject<string[]>;
  practiceGroupStream: BehaviorSubject<string[]>;
  sectionStream: BehaviorSubject<string[]>;
  udfStream: BehaviorSubject<string[]>;
  locStream: BehaviorSubject<string[]>;
  tkinitStream: BehaviorSubject<string[]>;
  valuesStream: BehaviorSubject<string[]>;
  peerPracticeStream: BehaviorSubject<string[]>;

  isFteVisible = true;
  isTimeTypeVisible = true;
  isPersonVisible = true;
  isValuesVisible = true;
  isTitleVisible = true;
  isPmTitleVisible = true;
  isOfficeVisible = true;
  isDeptVisible = true;
  isSectionVisible = true;
  isUdfVisible = true;
  isPeerOfficeVisible = true;
  isPeerPracticeVisible = true;
  isPracticeGroupVisible = true;
  isPeerTitleVisible = true;
  isExpYearsVisible = true;
  isLocVisible = true;
  isTKINITVisible = true;
  isPmOfficeVisible = true;
  isGradYear = true;
  isPeriodVisible = true;

  titles: string[];
  pmTitles: string[];
  offices: string[];
  departments: string[];
  loc: string[];
  tkId: string[];
  values: string[];
  peerOffices: string[];
  peerPractices: string[];
  practiceGroups: string[];
  peerTitles: string[];
  timeTypes: string[];
  persons: any[];
  section: any[];

  officeKeyValue: any = {};
  peerOfficeKeyValue: any = {};
  peerTitleKeyValue: any = {};
  titleKeyValue: any = {};
  deptKeyValue: any = {};
  peerPracticeKeyValue: any = {};
}
