import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';
import {PreventMappingModalComponent} from '../shared/prevent-mapping-modal/prevent-mapping-modal.component';
import {GlOfficesComponent} from './gl-offices.component';

@Injectable({
  providedIn: 'root',
})
export class GlOfficesDeactivateGuard implements CanDeactivate<GlOfficesComponent> {
  constructor(public modalService: NgbModal) {}
  alerts: BentoAlertItemOptions[] = [];

  canDeactivate(component: GlOfficesComponent) {
    const subject = new Subject<boolean>();
    if (!component.isDisabled) {
      const modalRef = this.modalService.open(PreventMappingModalComponent);
      modalRef.componentInstance.subject = subject;
      modalRef.result.then(
        (result) => {
          if (result == 'save') {
            component.saveGlOffices();
          }
        },
        (reason) => {}
      );
      return subject.asObservable();
    } else {
      return true;
    }
  }
}
