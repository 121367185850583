<bento-alert [items]="alerts"></bento-alert>
<main id="MainContent" tabindex="-1" class="Main" [bentoBusyLoader]="isGetDataLoading">
  <header class="Section-header">
    <h2 class="Section-heading">{{ permission }}</h2>
  </header>
  <div class="Section-body" *ngIf="isTransferBoxDataLoading && permission == 'Office permission'">
    <div class="row">
      <div class="col">
        <h3>Restricted offices</h3>
      </div>
      <div class="col">
        <h3>Visible offices</h3>
      </div>
    </div>
    <bento-transferbox-basic
      #transferbox
      [boxOneItemSource]="restrictedOffices"
      [boxTwoItemSource]="assignedOffices"
      [boxOneTitle]="'Available items'"
      [boxTwoTitle]="'Available items'"
      [columnDefinitions]="gridOptions"
      [columnKey]="'id'"
      [height]="gridHeight"
      [hidePagination]="hidePagination"
      (transfer)="onTransferOffice($event)"
    ></bento-transferbox-basic>
  </div>
  <div class="Section-body" *ngIf="isTransferBoxDataLoading && permission == 'Practice permission'">
    <div class="row">
      <div class="col">
        <h3>Restricted practices</h3>
      </div>
      <div class="col">
        <h3>Visible practices</h3>
      </div>
    </div>
    <bento-transferbox-basic
      #transferbox
      [boxOneItemSource]="restrictedPractices"
      [boxTwoItemSource]="assignedPractices"
      [boxOneTitle]="'Available items'"
      [boxTwoTitle]="'Available items'"
      [columnDefinitions]="gridOptions"
      [columnKey]="'id'"
      [height]="gridHeight"
      [hidePagination]="hidePagination"
      (transfer)="onTransferPractice($event)"
    ></bento-transferbox-basic>
  </div>
  <div class="Section-body" *ngIf="isTransferBoxDataLoading && permission == 'Metric permission'">
    <div class="row">
      <div class="col">
        <h3>Restricted metrics</h3>
      </div>
      <div class="col">
        <h3>Visible metrics</h3>
      </div>
    </div>
    <bento-transferbox-basic
      #transferbox
      [boxOneItemSource]="restrictedMetrics"
      [boxTwoItemSource]="assignedMetrics"
      [boxOneTitle]="'Available items'"
      [boxTwoTitle]="'Available items'"
      [columnDefinitions]="gridOptions"
      [columnKey]="'metricId'"
      [height]="gridHeight"
      [hidePagination]="hidePagination"
      (transfer)="onTransferMetric($event)"
    ></bento-transferbox-basic>
  </div>
  <footer class="Section-footer">
    <div *ngIf="permission == 'Office permission'" class="Actions">
      <div class="Actions-item">
        <button type="button" class="btn btn-outline-secondary" (click)="closeOfficeInfo()">
          <span class="btn-text">Cancel</span>
        </button>
      </div>
      <div class="Actions-item">
        <button type="submit" class="btn btn-primary" (click)="saveOfficeInfo()" [disabled]="isVisiblePermissionsEmpty">
          <span class="btn-text">Save</span>
        </button>
      </div>
    </div>
    <div *ngIf="permission == 'Practice permission'" class="Actions">
      <div class="Actions-item">
        <button type="button" class="btn btn-outline-secondary" (click)="closePracticeInfo()">
          <span class="btn-text">Cancel</span>
        </button>
      </div>
      <div class="Actions-item">
        <button
          type="submit"
          class="btn btn-primary"
          (click)="savePracticeInfo()"
          [disabled]="isVisiblePermissionsEmpty"
        >
          <span class="btn-text">Save</span>
        </button>
      </div>
    </div>
    <div *ngIf="permission == 'Metric permission'" class="Actions">
      <div class="Actions-item">
        <button type="button" class="btn btn-outline-secondary" (click)="closeMetricInfo()">
          <span class="btn-text">Cancel</span>
        </button>
      </div>
      <div class="Actions-item">
        <button type="submit" class="btn btn-primary" (click)="saveMetricInfo()" [disabled]="isVisiblePermissionsEmpty">
          <span class="btn-text">Save</span>
        </button>
      </div>
    </div>
  </footer>
</main>
