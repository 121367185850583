import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {BaseService} from 'src/app/core/services/base/base.service';
import {AuthService} from '../../../services/auth/auth.service';
import {environment} from 'src/environments/environment';
import {DownloadService} from 'src/app/core/services/download/download.service';
import StaffingratioFirmSettings from 'src/app/core/models/staffingration-subscription-settings.model';
@Component({
  selector: 'app-my-input-sheet',
  templateUrl: './my-input-sheet.component.html',
})
export class MyInputSheetComponent {
  isDataLoading = false;
  successMsg = '';
  errorMessage = '';
  fileUploadedName: string = '';
  alerts: BentoAlertItemOptions[] = [];
  titleMappingFileName = '';
  staffingRatioGuideFileName = '';
  inputSheetFileName = '';
  isQuickSightEnabled = false;
  firmMainOffice: string;
  @Input() currentSurveyYearOfFirm: any;
  @Input() selectedFirmId: any;
  @Input() selectedFirmSRSubscriptionInfo: StaffingratioFirmSettings;
  @Output() handleLoaderAction = new EventEmitter<boolean>();
  @Output() refreshSusbcriptionData = new EventEmitter<boolean>();
  constructor(
    // public _service: PeerCheckServiceService,
    private authService: AuthService,
    private service: BaseService,
    private downloadFile: DownloadService // public modalService: NgbModal,
  ) {}
  async ngOnInit(): Promise<void> {
    if (this.selectedFirmSRSubscriptionInfo.currencyCode == 'AUD') {
      this.firmMainOffice = 'AUD';
      this.titleMappingFileName = 'Title Mapping Quick Reference Guide AUD.xlsx';
      this.staffingRatioGuideFileName = 'Staffing Ratio Guidelines and Roles Definitions AUD.pdf';
      this.inputSheetFileName = 'SR_Input_Worksheet_AUD.xlsx';
    } else {
      this.firmMainOffice = 'US';
      this.titleMappingFileName = 'Title Mapping Quick Reference Guide USD.xlsx';
      this.staffingRatioGuideFileName = 'Staffing Ratio Guidelines and Roles Definitions USD.docx';
      this.inputSheetFileName = 'SR_Input_Worksheet_USD.xlsx';
    }
     }
  /*Upload Staffing Ratio xlsx file upload*/
  onFileSelect(event) {
    this.alerts = [];
    this.isDataLoading = true;
    this.handleLoaderAction.emit(this.isDataLoading);
    const files = event.target.files[0];
    this.fileUploadedName = files.name;
    let extn = this.fileUploadedName.split('.').pop();
    if (extn != 'xls' && extn != 'xlsx') {
      this.isDataLoading = false;
      this.handleLoaderAction.emit(this.isDataLoading);
      this.alerts.push({
        type: 'warning',
        //msg: 'Please upload only .xlsx file.',
        msg:
          'The file "' +
          this.fileUploadedName +
          '" could not be validated and was not accepted by the system as the supported formats are (.xls or .xlsx).<br>If this persists, please contact us at <a href="mailto:financialinsights.admin@thomsonreuters.com"><span style="color: blue;">financialinsights.admin@thomsonreuters.com</span></a>',
        closeable: true,
      });
    } else {
      let formParams = new FormData();
      formParams.append('file', files, this.fileUploadedName);
      formParams.append('currency', this.selectedFirmSRSubscriptionInfo.currencyCode);
      this.service
        .uploadExcel(environment.FIClientBaseEndpoint + 'v1/staffingratio/upload/' + this.selectedFirmId, formParams)
        .subscribe(
          (result) => {
            this.isDataLoading = false;
            this.handleLoaderAction.emit(this.isDataLoading);
            this.refreshSusbcriptionData.emit();
            //this.successMsg = 'File uploaded successfully';
            this.successMsg =
              'Thank you for your submission. Your input has been accepted and additional validation will be performed. You may be contacted for clarification or validation in the coming days.';
            this.alerts.push({
              type: 'success',
              msg: this.successMsg,
              closeable: true,
            });
            event.target.value = '';
          },
          (error) => {
            //this.errorMessage = 'Something went wrong, please try again.';
            this.isDataLoading = false;
            this.handleLoaderAction.emit(this.isDataLoading);
            this.errorMessage = error.error.message;
            if (
              error.error.message == 'Something went wrong! Please contact Administrator' ||
              error.error.message == 'The uploaded file could not be validated and was not accepted by the system.'
            ) {
              this.errorMessage =
                'The file "' +
                this.fileUploadedName +
                '" could not be validated and was not accepted by the system.<br>If this persists, please contact us at <a href="mailto:financialinsights.admin@thomsonreuters.com"><span style="color: blue;">financialinsights.admin@thomsonreuters.com</span></a>';
            }
            this.alerts.push({
              type: 'warning',
              msg: this.errorMessage,
              closeable: true,
            });
            event.target.value = '';
          }
        );
    }
  }

  downloadStaticFile(fileName: string) {
    this.service.downloadExcel(environment.FIClientBaseEndpoint + 'v1/staffingratio/file/'+this.selectedFirmId + '/' + fileName).subscribe(
      (response) => {
        this.downloadFile.download(
          response.body,
          response.headers.get('content-disposition').split('=')[1].replace(/['"]+/g,"")
        );
      },
      (error) => {
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
}
