<bento-alert [items]="alerts"></bento-alert>
<div *ngIf="!isInvalidAction && !isConfirmationVisible" class="modal-content">
  <button
    type="button"
    class="close split_close"
    aria-label="Close"
    ngbTooltip="Close"
    placement="bottom"
    container="body"
    (click)="cancelOrContinue()"
  >
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <span class="bento-label-required padding-top-right">Required field</span>
  <h3 class="modal-header h2">
    {{ header }}
  </h3>
  <div class="modal-body">
    <form name="splitDetail" bfmForm #splitDetail="ngForm">
      <div class="Badges">
        <span class="badge badge-info" *ngFor="let type of selectedTimeKeeper; let i = index">
          <span class="badge-text">
            {{ type.lastName === null || type.firstName === null ? type.lastName : type.lastName + ', ' }}
            {{ type.firstName }}
          </span>
          <button
            type="button"
            class="btn btn-flat-icon btn-sm"
            aria-label="Remove timekeeper"
            ngbTooltip="Remove timekeeper"
            placement="bottom"
            container="body"
            (click)="removeTimekeeper(i)"
          >
            <i class="bento-icon-close-x" aria-hidden="true"></i>
          </button>
        </span>
      </div>
      <h4 id="splitPeerPracticeLabel" class="sr-only">Split peer practice</h4>
      <table class="table table-bordered SplitAccountGrid" role="grid" aria-labelledby="splitPeerPracticeLabel">
        <thead>
          <tr>
            <th scope="col" tab-index="-1" *ngFor="let header of headers">{{ header.value }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let selection of selectedDetails.splitDetails; let i = index">
            <td class="SplitAccountGrid-cell SplitAccountGrid-cell--large">
              <bento-combobox
                #peerOffice
                id="peerOffice-{{ i }}"
                name="peerOffice-{{ i }}"
                [attr.value]="selection.selectedOfficeOption"
                [(ngModel)]="selection.selectedOfficeOption"
                [itemsObservable]="officeDataStream"
                [options]="comboboxOptions"
                [placeholder]="' '"
                [appendTo]="'main'"
                [rowTemplate]="peerOption"
                (ngModelChange)="onSplitsChange()"
                bfmField
                tab-index="-1"
              ></bento-combobox>
              <ng-template #peerOption let-data="data.row" let-options="options" let-row="row">
                <div class="mb-1">
                  {{ data.description }}
                </div>
              </ng-template>
              <label for="peerOffice-{{ i }}" class="sr-only bento-label-required">Select peer office</label>
              <bfm-error-container #peerOffice></bfm-error-container>
            </td>
            <td class="SplitAccountGrid-cell SplitAccountGrid-cell--large">
              <bento-combobox
                #peerPractice
                id="peerPractice-{{ i }}"
                name="peerPractice-{{ i }}"
                [attr.value]="selection.selectedPracticeOption"
                [(ngModel)]="selection.selectedPracticeOption"
                [itemsObservable]="practiceDataStream"
                [options]="comboboxOptions"
                [placeholder]="' '"
                [appendTo]="'main'"
                [rowTemplate]="peerOption"
                (ngModelChange)="onSplitsChange()"
                bfmField
                tab-index="-1"
              ></bento-combobox>
              <ng-template #peerOption let-data="data.row" let-options="options" let-row="row">
                <div class="mb-1">
                  {{ data.description }}
                </div>
              </ng-template>
              <label for="peerPractice-{{ i }}" class="sr-only bento-label-required">Select peer practice</label>
              <bfm-error-container #peerPractice></bfm-error-container>
            </td>
            <td class="SplitAccountGrid-cell SplitAccountGrid-cell--medium">
              <label for="percentage-{{ i }}" class="sr-only bento-label-required">Enter split percentage</label>
              <div class="input-group FormInputGroup">
                <input
                  bfmField
                  bfmLabel="percentage"
                  [validations]="validations.splitsValidation"
                  [pattern]="splitsValidation"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                  (keypress)="keyPress($event, selection.percentage)"
                  class="form-control"
                  id="percentage-{{ i }}"
                  name="percentage-{{ i }}"
                  [errorContainer]="percentage"
                  [(ngModel)]="selection.percentage"
                  required
                  type="text"
                  maxlength="3"
                  tab-index="-1"
                  (ngModelChange)="onSplitsChange()"
                />
                <div class="input-group-append">
                  <span class="input-group-text percentage-symbol">
                    <i class="bento-icon-percent" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <bfm-error-container #percentage></bfm-error-container>
            </td>
            <td class="SplitAccountGrid-cell SplitAccountGrid-cell--small">
              <label for="od-{{ i }}" class="sr-only bento-label-required">Enter override value</label>
              <input
                bfmField
                bfmLabel="O/D"
                [validations]="validations.splitsValidationOd"
                [pattern]="splitsValidation"
                oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                (keypress)="keyPress($event, selection.duration)"
                class="form-control"
                id="od-{{ i }}"
                name="od-{{ i }}"
                [(ngModel)]="selection.duration"
                [errorContainer]="oD"
                required
                type="text"
                maxlength="2"
                tab-index="-1"
                (ngModelChange)="onSplitsChange()"
              />
              <bfm-error-container #oD></bfm-error-container>
            </td>
            <td class="SplitAccountGrid-cell SplitAccountGrid-cell--xSmall">
              <button
                type="button"
                class="btn btn-outline-secondary btn-icon"
                aria-label="Delete row"
                ngbTooltip="Delete row"
                placement="bottom"
                container="body"
                tab-index="-1"
                (click)="deleteCode(i)"
                [disabled]="selectedDetails.splitDetails.length <= 1"
              >
                <i class="bento-icon-close-circle" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
          <tr class="SplitAccountGrid-addAccount">
            <td colspan="4" class="SplitAccountGrid-cell">
              <div class="SplitAccountGrid-action">
                <button
                  type="button"
                  class="btn btn-outline-secondary btn-icon"
                  aria-label="Add another row"
                  ngbTooltip="Add another row"
                  placement="bottom"
                  container="body"
                  tab-index="-1"
                  (click)="addCode()"
                >
                  <i class="bento-icon-add" aria-hidden="true"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
  <div class="modal-footer a11y modal-footer--custom">
    <button type="button" class="btn btn-outline-primary" (click)="cancelOrContinue()">
      <span class="btn-text">Cancel</span>
    </button>
    <button type="submit" 
        class="btn btn-primary" 
        [attr.aria-disabled]="!validateSplits ? 'true' : 'false' " 
        [attr.aria-labelledby]="!validateSplits ? 'aria-required-save' : null " 
        (click)="!validateSplits ? $event.stopPropagation() : saveData()"
    >
      <span class="btn-text">Save</span>
    </button>
    <span class="sr-only" id="aria-required-save">Fill required fields, Save</span>
  </div>
</div>

<div *ngIf="isInvalidAction && !isConfirmationVisible" class="modal-content">
  <button
    type="button"
    class="close"
    aria-label="Close"
    ngbTooltip="Close"
    placement="bottom"
    container="body"
    (click)="activeModal.dismiss('Close click')"
  >
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h3 class="modal-header h2">
    {{ header }}
  </h3>
  <div class="modal-body">
    <p>
      The last timekeeper has been removed from the {{ header }} process. Close this window with the X in the upper
      right corner or use the close button below.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="activeModal.dismiss('Close click')">Close</button>
  </div>
</div>

<div *ngIf="!isInvalidAction && isConfirmationVisible" class="modal-content">
  <button
    type="button"
    class="close"
    aria-label="Close"
    ngbTooltip="Close"
    placement="bottom"
    container="body"
    (click)="activeModal.dismiss('Close click')"
  >
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h3 class="modal-header h2">Unsaved changes</h3>
  <div class="modal-body">
    <p>There are unsaved changes. Would you like to save them?</p>
  </div>
  <div class="modal-footer modal-footer--custom">
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss('Close click')">
      Don't save
    </button>
    <button type="button" class="btn btn-primary" (click)="cancelOrContinue()">Edit</button>
  </div>
</div>
