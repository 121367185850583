<bento-alert [items]="alerts"></bento-alert>
<section class="Section" [bentoBusyLoader]="isgeneralLedgerUpdated">
  <header class="Section-heading">
    <h2 class="Section-heading">General ledger settings</h2>
  </header>
  <span class="label-required-summary">Required field</span>
  <form
    name="generalLedger"
    #generalLedger="ngForm"
    class="Section-form Form"
    bfmForm
    *ngIf="isGetgeneralLedgerSettings"
  >
    <div class="Section-body">
      <fieldset class="Form-set">
        <legend class="sr-only">General ledger book settings</legend>
        <ng-container *ngIf="firmType != '4' && firmType != '5'">
          <div class="form-group Form-input Form-input--medium">
            <label for="glLedgerBook" id="glLedgerBookLabel">General ledger book</label>
            <select
              name="glLedgerBook"
              id="glLedgerBook"
              class="form-control"
              bentoSelect
              bfmField
              bfmLabel="General ledger book"
              aria-labelledby="glLedgerBookLabel"
              [(ngModel)]="generalLedgerSetting.glLedgerBook"
            >
              <option *ngFor="let type of glLedgerValues" [ngValue]="type.dataCode">{{ type.dataName }}</option>
              <option *ngIf="glLedgerValues.length == 0" [ngValue]="undefined" Selected>No result found</option>
            </select>
          </div>
        </ng-container>
        <ng-container *ngIf="firmType === '4' || firmType === '5'">
          <div class="form-group Form-input Form-input--medium">
            <label for="glLedgerBook" class="bento-label-required">General ledger book</label>
            <input
              bfmField
              bfmLabel="General ledger book"
              id="glLedgerBook"
              name="glLedgerBook"
              type="text"
              class="form-control"
              [(ngModel)]="generalLedgerSetting.glLedgerBook"
              pattern="^[A-Z\a-z\d\-_\s]+$"
              required
            />
          </div>
        </ng-container>
        <ng-container *ngIf="firmType === '4' || firmType === '5'">
          <div class="form-group Form-input Form-input--medium">
            <label for="glDeptSource" id="glDeptSourceLabel" class="bento-label-required">General dept source</label>
            <select
              name="glDeptSource"
              id="glDeptSource"
              class="form-control"
              bentoSelect
              bfmField
              bfmLabel="General dept source"
              aria-labelledby="glDeptSourceLabel"
              [(ngModel)]="generalLedgerSetting.glDeptSource"
              required
            >
              <option *ngFor="let type of glDeptSourceValues" [ngValue]="type.tableName">
                {{ type.tableDisplayName }}
              </option>
              <option *ngIf="glDeptSourceValues.length == 0" [ngValue]="undefined" Selected>No result found</option>
            </select>
          </div>
          <div class="form-group Form-input Form-input--medium">
            <label for="glOfficeSource" id="glOfficeSourceLabel" class="bento-label-required">General office source</label>
            <select
              name="glOfficeSource"
              id="glOfficeSource"
              class="form-control"
              bentoSelect
              bfmField
              bfmLabel="General office source"
              aria-labelledby="glOfficeSourceLabel"
              [(ngModel)]="generalLedgerSetting.glOfficeSource"
              required
            >
              <option *ngFor="let type of glOfficeSourceValues" [ngValue]="type.tableName">
                {{ type.tableDisplayName }}
              </option>
              <option *ngIf="glOfficeSourceValues.length == 0" [ngValue]="undefined" Selected>No result found</option>
            </select>
          </div>
        </ng-container>
        <ng-container *ngIf="firmType === '3'">
          <div class="form-group Form-input Form-checkbox">
            <bento-checkbox
              id="useProfCtr"
              name="useProfCtr"
              [(ngModel)]="generalLedgerSetting.useProfCtr"
            ></bento-checkbox>
            <label for="useProfCtr" class="checkbox-inline">Use profit center in general ledger</label>
          </div>
          <div class="form-group Form-input Form-checkbox">
            <bento-checkbox
              id="generateOfficeAllocation"
              name="generateOfficeAllocation"
              [(ngModel)]="generalLedgerSetting.generateOfficeAllocation"
            ></bento-checkbox>
            <label for="generateOfficeAllocation" class="checkbox-inline">Generate office allocation</label>
          </div>
        </ng-container>
        <ng-container *ngIf="firmType != '3' && firmType != '4' && firmType != '5'">
          <div class="form-group Form-input Form-checkbox">
            <bento-checkbox
              name="compositeNaturalAccount"
              id="compositeNaturalAccount"
              [(ngModel)]="generalLedgerSetting.compositeNaturalAccount"
            ></bento-checkbox>
            <label for="compositeNaturalAccount" class="checkbox-inline"
              >Composite natural account (from table GL)</label
            >
          </div>
          <div class="form-group Form-input Form-checkbox">
            <bento-checkbox
              name="hasGlobalRelease"
              id="hasGlobalRelease"
              [(ngModel)]="generalLedgerSetting.hasGlobalRelease"
            ></bento-checkbox>
            <label for="hasGlobalRelease" class="checkbox-inline">Has global release</label>
          </div>
          <div class="form-group Form-input Form-checkbox">
            <bento-checkbox
              name="generateOfficeAllocation"
              id="generateOfficeAllocation"
              [(ngModel)]="generalLedgerSetting.generateOfficeAllocation"
            ></bento-checkbox>
            <label for="generateOfficeAllocation" class="checkbox-inline">Generate office allocation</label>
          </div>

          <div class="form-group Form-input Form-checkbox">
            <bento-checkbox name="glrc1" id="glrc1" [(ngModel)]="generalLedgerSetting.useGlrc1"></bento-checkbox>
            <label for="glrc1" class="checkbox-inline">Use glrc1</label>
          </div>
        </ng-container>
      </fieldset>

      <fieldset class="Form-set" *ngIf="firmType != '3' && firmType != '4' && firmType != '5'">
        <legend class="h4 Form-legend">General ledger segments</legend>
        <div class="Form-group Form-group--indent">
          <div class="form-group Form-input Form-input--large row">
            <div class="col">
              <label for="glSegmentAccountStart" class="bento-label-required">Account start</label>
              <input
                type="number"
                name="Segment Account Start"
                id="glSegmentAccountStart"
                class="form-control Form-input--extraSmall"
                bfmField
                bfmLabel="Account start"
                min="0"
                max="63"
                [pattern]="glAccountStartChangedVal"
                [validations]="validations.glAccountStartChangedVal"
                (change)="glAccountStartChanged($event.target)"
                [(ngModel)]="generalLedgerSetting.glSegmentAccountStart"
                required
              />
            </div>
            <div class="col">
              <label for="glSegmentAccountEnd" class="bento-label-required">Account end</label>
              <input
                type="number"
                name="Segment Account End"
                id="glSegmentAccountEnd"
                class="form-control Form-input--extraSmall"
                bfmField
                bfmLabel="Account end"
                min="1"
                max="64"
                [validations]="validations.glAccountEndChangedVal"
                [pattern]="glAccountEndChangedVal"
                (change)="glAccountEndChanged($event.target)"
                [(ngModel)]="generalLedgerSetting.glSegmentAccountEnd"
                required
              />
            </div>
          </div>
        </div>
        <div class="Form-group Form-group--indent" *ngIf="generalLedgerSetting.compositeNaturalAccount">
          <div class="form-group Form-input Form-input--large row">
            <div class="col">
              <label for="glSegmentRealNaturalAccStart" class="bento-label-required">Real natural account start</label>
              <input
                type="number"
                name="Segment Real Natural Account Start"
                id="glSegmentRealNaturalAccStart"
                class="form-control Form-input--extraSmall"
                bfmField
                bfmLabel="Real natural account start"
                min="0"
                max="63"
                [pattern]="glAccountStartChangedVal"
                [validations]="validations.glAccountStartChangedVal"
                (change)="glAccountStartChanged($event.target)"
                [(ngModel)]="generalLedgerSetting.glSegmentRealNaturalAccStart"
                required
              />
            </div>
            <div class="col">
              <label for="glSegmentRealNaturalAccEnd" class="bento-label-required">Real natural account end</label>
              <input
                type="number"
                name="Segment Real Natural Account End"
                id="glSegmentRealNaturalAccEnd"
                class="form-control Form-input--extraSmall"
                bfmField
                bfmLabel="Real natural account end"
                min="1"
                max="64"
                [validations]="validations.glAccountEndChangedVal"
                [pattern]="glAccountEndChangedVal"
                (change)="glAccountEndChanged($event.target)"
                [(ngModel)]="generalLedgerSetting.glSegmentRealNaturalAccEnd"
                required
              />
            </div>
          </div>
        </div>
        <div class="Form-group Form-group--indent">
          <div class="form-group Form-input Form-input--large row">
            <div class="col">
              <label for="glSegmentOfficeStart" class="bento-label-required">Office start</label>
              <input
                type="number"
                name="Segment Office Start"
                id="glSegmentOfficeStart"
                class="form-control Form-input--extraSmall"
                bfmField
                bfmLabel="Office start"
                min="0"
                max="63"
                [pattern]="glAccountStartChangedVal"
                [validations]="validations.glAccountStartChangedVal"
                (change)="glAccountStartChanged($event.target)"
                [(ngModel)]="generalLedgerSetting.glSegmentOfficeStart"
                required
              />
            </div>
            <div class="col">
              <label for="glSegmentOfficeEnd" class="bento-label-required">Office end</label>
              <input
                type="number"
                name="Segment Office End"
                id="glSegmentOfficeEnd"
                class="form-control Form-input--extraSmall"
                bfmField
                bfmLabel="Office end"
                min="1"
                max="64"
                [validations]="validations.glAccountEndChangedVal"
                (change)="glAccountEndChanged($event.target)"
                [pattern]="glAccountEndChangedVal"
                [(ngModel)]="generalLedgerSetting.glSegmentOfficeEnd"
                required
              />
            </div>
          </div>
        </div>
        <div class="Form-group Form-group--indent">
          <div class="form-group Form-input Form-input--large row">
            <div class="col">
              <label for="glSegmentDepartmentStart" class="bento-label-required">Department start</label>
              <input
                type="number"
                name="Segment Department Start"
                id="glSegmentDepartmentStart"
                class="form-control Form-input--extraSmall"
                bfmField
                bfmLabel="Department start"
                min="0"
                max="63"
                [pattern]="glAccountStartChangedVal"
                [validations]="validations.glAccountStartChangedVal"
                (change)="glAccountStartChanged($event.target)"
                [(ngModel)]="generalLedgerSetting.glSegmentDepartmentStart"
                required
              />
            </div>
            <div class="col">
              <label for="glSegmentDepartmentEnd" class="bento-label-required">Department end</label>
              <input
                type="number"
                name="Segment Department End"
                id="glSegmentDepartmentEnd"
                class="form-control Form-input--extraSmall"
                bfmField
                bfmLabel="Department end"
                min="1"
                max="64"
                [validations]="validations.glAccountEndChangedVal"
                [pattern]="glAccountEndChangedVal"
                [(ngModel)]="generalLedgerSetting.glSegmentDepartmentEnd"
                (change)="glAccountEndChanged($event.target)"
                required
              />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="Section-body">
      <fieldset class="Form-set">
        <div class="form-group Form-input Form-input--medium" *ngIf="firmType === '4' || firmType === '5'">
          <bento-checkbox
            name="generateOfficeAllocation"
            id="generateOfficeAllocation"
            [(ngModel)]="generalLedgerSetting.generateOfficeAllocation"
          ></bento-checkbox>
          <label for="generateOfficeAllocation">Generate office allocation</label>
        </div>
      </fieldset>
    </div>

    <footer class="Section-footer">
      <div class="Actions">
        <div class="Actions-item">
          <button type="button" class="btn btn-outline-secondary" (click)="resetGeneralLedger()">
            <span class="btn-text">Cancel</span>
          </button>
        </div>
        <div class="Actions-item a11y">
          <button
            type="submit"
            class="btn btn-primary"
            [attr.aria-disabled]="generalLedger.invalid ? 'true' : 'false' "
            (click)="generalLedger.invalid ? $event.stopPropagation() : saveGeneralLedger()"
            [attr.aria-labelledby]=" generalLedger.invalid ? 'aria-required-save' : null "
          >
            <span class="btn-text">Save</span>
          </button>
        </div>
        <span class="sr-only" id="aria-required-save">Fill required fields, Save</span>
      </div>
    </footer>
  </form>
</section>
