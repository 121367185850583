import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-svg-logo',
  templateUrl: './svg-logo.component.svg',
})
export class SvgLogoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
