export class TimekeeperDetails {
  tkId?: string;
  startPeriod?: number;
}
export class SplitDetails {
  officeId?: number;
  practiceId?: number;

  duration?: number;
  percentage?: number;
}
export default class SplitPostModel {
  public splitDetails: SplitDetails[];
  public timekeeperDetails: TimekeeperDetails[];
  public constructor() {}
}
export class SplitOverrides {
  firmId: number;
  tkId: string;
  startPeriod: number;
  splitDetails: SplitDetail[];
  constructor() {
    this.splitDetails = [];
  }
}

export class SplitDetail {
  id: number;
  selectedPracticeOption?: any;
  selectedOfficeOption?: any;
  pmPracticeId: any;
  pmOfficeId: any;
  percentage: number;
  overrideValues: number;
  duration: number;
}
