<div class="GlobalLayout GlobalLayout--twoColumn"  *ngIf="!isCurrentViewSelected">
  <main id="MainContent" tabindex="-1" class="Main">
    <header class="Main-header">
      <h1 class="Main-heading">Current views</h1>
    </header>
    <div class="Main-body Main-body--light">
      <div class="GlobalLayout-group">
        <div class="GlobalLayout-item GlobalLayout-item--small">
          <bento-side-nav class="NavSide" aria-label="Current views" [items]="items" [itemTemplate]="itemTemplate">
            <ng-template #itemTemplate let-$item="item" class="label">
              <span>
                {{ $item.label }}
              </span>
              <!-- TODO: Removed the counter on Shared Views, as it is not part of MVP as of now -->
              <!-- <span *ngIf="$item.label == 'Shared views'" class="badge badge-success badge--bold badge--inline">3</span> -->
            </ng-template>
          </bento-side-nav>
        </div>
        <div class="GlobalLayout-item GlobalLayout-item--large">
          <ng-container *ngIf="items[0].data.isVisible">
            <app-views
              [selectedViewData]="selectedViewData"
              (editCurrentViewAction)="editCurrentView($event)"
              viewType="myviews"
            ></app-views>
          </ng-container>
          <ng-container *ngIf="items[1].data.isVisible">
            <app-views (editCurrentViewAction)="editCurrentView($event)" viewType="sharedviews"></app-views>
          </ng-container>
        </div>
      </div>
    </div>
  </main>
</div>
<app-current-view-details
  *ngIf="isCurrentViewSelected"
  [selectedViewData]="selectedViewData"
  (backToCurrentViewAction)="backToCurrentView()"
>
</app-current-view-details>
