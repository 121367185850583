<bento-alert [items]="alerts"></bento-alert>
<div class="GlobalLayout-group" *ngIf="isContentDisplay && isItemsReady">
  <div class="GlobalLayout-item GlobalLayout-item--small">
    <bento-side-nav
      class="NavSide"
      aria-label="Firm administrator settings"
      [items]="items"
      [collapsible]="!isNewFirm"
      (select)="onSelect($event)"
    >
    </bento-side-nav> 
  </div>
  <div class="GlobalLayout-item GlobalLayout-item--large">
    <ng-container *ngIf="items[0].data.isVisible">
      <app-details [isNewFirm]="isNewFirm" [isFAdmin]="isFAdmin" [firmId]="firmId"></app-details>
    </ng-container>
    <div *ngIf="!isFAdmin">
      <ng-container *ngIf="items[1].items[0].data.isVisible">
        <app-fms [isNewFirm]="isNewFirm" [firmId]="firmId" (refreshSideNavigation) = "refreshSideNavigation($event)"></app-fms>
      </ng-container>
      <ng-container *ngIf="items[1].items[1].data.isVisible">
        <app-general-setting [isNewFirm]="isNewFirm" [firmId]="firmId"></app-general-setting>
      </ng-container>
      <ng-container *ngIf="items[1].items[3].data.isVisible">
        <app-firm-practice-setting [isNewFirm]="isNewFirm" [firmId]="firmId"></app-firm-practice-setting>
      </ng-container>
      <ng-container *ngIf="items[1].items[2].data.isVisible">
        <app-timekeeper-setting [isNewFirm]="isNewFirm" [firmId]="firmId"></app-timekeeper-setting>
      </ng-container>
      <ng-container *ngIf="items[1].items[4].data.isVisible">
        <app-general-ledger [isNewFirm]="isNewFirm" [firmId]="firmId" ></app-general-ledger>
      </ng-container>
      <ng-container *ngFor="let item of items[2].items">
        <app-firm-permission
          *ngIf="item.data.isVisible"
          [isFirmPermission]="true"
          [permissionName]="permissionNameLbl"
          [firmId]="firmId"
        ></app-firm-permission>
      </ng-container>
      <ng-container *ngIf="items[3] && items[3].data.isVisible && isStaffingratioEnable">
        <app-firm-staffingratio-subscription [firmId]="firmId"></app-firm-staffingratio-subscription>
      </ng-container>
    </div>
    <div *ngIf="isTAdmin">
      <ng-container *ngIf="items[4] && items[4].items[0].data.isVisible  && is3eCloudEnable">
        <app-client-credential [firmId]="firmId"></app-client-credential>
      </ng-container>
      <ng-container *ngIf="items[4] && items[4].items[1].data.isVisible  && is3eCloudEnable">
        <app-run-extract [firmId]="firmId"></app-run-extract>
      </ng-container>
    </div>
    <div *ngIf="isFAdmin">
      <ng-container *ngIf="items[1] && items[1].items[0].data.isVisible  && is3eCloudEnable">
        <app-client-credential [firmId]="firmId"></app-client-credential>
      </ng-container>
      <ng-container *ngIf="items[1] && items[1].items[1].data.isVisible  && is3eCloudEnable">
        <app-run-extract [firmId]="firmId"></app-run-extract>
      </ng-container>
    </div>
</div>
</div>
