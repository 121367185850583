import {HttpParams} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BentoAlertItemOptions} from '@bento/bento-ng/lib/alert/alert.component';
import FirmDetailModel from 'src/app/core/models/firmdetail.model';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {LocalStorageService} from 'src/app/core/services/local-storage/local-storage.service';
import {PeerCheckServiceService} from '../../../peer-check/peer-check/peer-check-service.service';

@Component({
  selector: 'app-mapping-firms-details',
  templateUrl: './mapping-firms-details.component.html',
})
export class MappingFirmsDetailsComponent implements OnInit {
  firm_Id: any;
  firm_Name: String;
  firmDetailModel: FirmDetailModel = new FirmDetailModel();
  errorMessage: '';
  alerts: BentoAlertItemOptions[] = [];

  constructor(
    private authService: AuthService,
    private service: BaseService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private _peerCheckService: PeerCheckServiceService
  ) {
    this.route.params.subscribe((val) => {
      // this.firm_Id = this.getfirmId;
      if (val && val.id) {
        this.firm_Id = val.id;
      } else {
        if (this._peerCheckService.getSelectedFirmId && this._peerCheckService.getSelectedFirmId != this.getfirmId) {
          this.firm_Id = this._peerCheckService.getSelectedFirmId;
        } else {
          this.firm_Id = this.getfirmId;
        }
      }
      if (this._peerCheckService.isValidMappingFirm(this.firm_Id)) {
        this.getfirmData(this.firm_Id);
      }
    });
  }

  ngOnInit(): void {}

  // getfirmId() should return the selected firmID OR firmID of logged in user.
  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : this.route.snapshot.params.id;
  }

  // getfirmData() should fetch the data based on firmID.
  getfirmData(firmId) {
    let params = new HttpParams();
    params = params.set('firmId', firmId);
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/firm?', params).subscribe(
      (result) => {
        this.firmDetailModel = result;
        this.firm_Name = this.firmDetailModel.name;
        this.localStorageService.set('firmLocale', this.firmDetailModel.defaultAccess);
      },
      (error) => {
        this.errorMessage = error.error;

        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
}
