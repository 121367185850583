<cdk-virtual-scroll-viewport [itemSize]="itemHeight" class="bento-list-viewport">
  <ng-container *cdkVirtualFor="let row of _data; let index = index; trackBy: trackByFn">
    <ng-container
      *ngTemplateOutlet="rowTemp; context: {row: row, options: options, data: {_data: _data, row: row}, index: index}"
    >
    </ng-container>
  </ng-container>
</cdk-virtual-scroll-viewport>

<ng-template #defaultRowTemp let-$row="row" let-$options="options">
  <div class="bento-list-row" tabindex="-1">
    {{ $row }}
  </div>
</ng-template>
