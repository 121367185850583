import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {VisualizationOptions} from 'src/app/core/models/create-chart.model';
import PeerCheckViewColumns from 'src/app/core/models/peerCheckViewColumns.model';
import UserViewSettingModel from '../../../../models/user-views-settings.model';
@Component({
  selector: 'app-views-settings',
  templateUrl: './views-settings.component.html',
})
export class ViewsSettingsComponent implements OnInit {
  @Input() public settingsModel: any;
  @Input() public rules: PeerCheckViewColumns;
  @Input() public settingsFor: string;
  localSettingsModel: any;
  // settingsModel: UserViewSettingModel = new UserViewSettingModel();
  isStatasticsAvail = true;
  showVariance = true;
  isComparisonAvail = true;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.localSettingsModel = JSON.parse(JSON.stringify(this.settingsModel));
    // Rule1: if Both Mean and Median are true then and onlu the Statastics option will display at UI side
    if (!this.rules.showMean || !this.rules.showMedian) {
      this.isStatasticsAvail = false;
    }
    if (!this.rules.showYOY || !this.rules.showVariance) {
      this.isComparisonAvail = false;
    }
   
    if (this.settingsFor == 'Visualization') {
      this.showVariance = false;
    }
  }
  applySettings() {
    this.activeModal.close(this.localSettingsModel);
  }
}
