import {Component, OnInit, HostBinding} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
})
export class OfflineComponent implements OnInit {
  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }

  isLoading = true;
  date: string = environment.offline.date;
  time: string = environment.offline.time;
  timezone: string = environment.offline.timezone;
  timeToComplete: string = environment.offline.timeToComplete;

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (environment.offline.status) {
      this.isLoading = false;
    } else {
      this.router.navigate(['']);
    }
  }
}
