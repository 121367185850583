import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthService as Auth0Service} from '@auth0/auth0-angular';
import {AuthService} from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
})
export class GenericErrorComponent implements OnInit {
  constructor(
    public auth0: Auth0Service,
    @Inject(DOCUMENT) private doc: Document,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}
  errorStatus = '';
  ciamToken = '';

  ngOnInit(): void {
    this.errorStatus = this.route.snapshot.params.errorstatus;
    this.ciamToken = this.authService.getCIAMToken();
  }

  signOut(): void {
    this.auth0.logout({returnTo: this.doc.location.origin});
  }
}
