import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {BaseService} from '../../../services/base/base.service';
import {environment} from 'src/environments/environment';
import {eventDispatcher, store} from '../../../store';
import {ActionTypes} from '../../../store/actions';
import FirmSettingModel from '../../../models/firmsetting.model';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {Router, ActivatedRoute} from '@angular/router';
import * as wjcCore from '@grapecity/wijmo';
import {Directionality} from '@angular/cdk/bidi';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {Tooltip, PopupPosition} from '@grapecity/wijmo';
import {FlexGrid, FormatItemEventArgs} from '@grapecity/wijmo.grid';
import {PeergroupService} from 'src/app/core/services/peergroup/peergroup.service';
import {GlobalService} from 'src/app/core/services/global/global.service';

@Component({
  selector: 'app-firm-setting',
  templateUrl: './firm-setting.component.html',
})
export class FirmSettingComponent implements OnInit, OnDestroy {
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private service: BaseService,
    private dir: Directionality,
    private peerGroupService: PeergroupService,
    private globalservice: GlobalService
  ) {}
  order = 'id';
  reverse = false;
  page: any = 1;
  pageSize: any = 10;
  sortedCollection: any[];
  selectedId: number;
  totalItem = 0;
  errorMessage = '';
  firmSettingModel: FirmSettingModel = new FirmSettingModel();
  searchText = '';
  isitemsperchanged: boolean;
  columnName = '';
  isAscending = false;
  sortDirection = '';
  isAscendingForId = false;
  selectItems: number[] = [10, 20, 30, 40, 50];
  isGetDataLoading = false;
  alerts: BentoAlertItemOptions[] = [];
  currentInfo: any = {
    page: 1,
    totalItems: this.totalItem,
    numItems: 10,
    infoText: '_START_ to _END_ of _MAX_ Users',
    infoPageText: '_PAGE_ of _PAGES_',
    goText: 'Go',
  };
  lastPage = this.currentInfo.page;
  newSortField: string;
  isMappings = false;
  currentRoute: string;
  currentRecords: number;
  totalRecords: number;
  isLoadedFirstTime: boolean;
  isFirmIdAscending: boolean | null = null;
  isFirmNameAscending: boolean | null = null;
  isPendingAscending: boolean | null = null;

  ngOnInit() {
    if (this.activatedRoute.snapshot) {
      this.currentRoute = this.activatedRoute.snapshot['_routerState'].url;
    }
    if (this.currentRoute.includes('/mappings')) {
      this.isMappings = true;
    }
    this.isLoadedFirstTime = true;
    this.currentRecords = 0;
    this.totalRecords = 0;
    this.getfirmsData();
    this.peerGroupService.setSelectedTab = null;
    this.peerGroupService.setCurrentStatus = null;

    // Add body class
    document.body.classList.add('Body--adminFirmsList');
  }

  ngOnDestroy() {
    // Add body class
    document.body.classList.remove('Body--adminFirmsList');
  }

  sortGrid(headerName: string) {
    if (headerName === 'id') {
      this.isFirmNameAscending = null;
      this.isPendingAscending = null;
      this.isFirmIdAscending = !this.isFirmIdAscending;
      this.setOrderByIdAsc();
    } else if (headerName === 'name') {
      this.isFirmIdAscending = null;
      this.isPendingAscending = null;
      this.isFirmNameAscending = !this.isFirmNameAscending;
      this.setOrderByNameAsc();
    } else {
      this.isFirmIdAscending = null;
      this.isFirmNameAscending = null;
      this.isPendingAscending = !this.isPendingAscending;
      this.setOrderByPendingAsc();
    }
    this.columnName = headerName;
    this.getfirmsData();
  }
  setOrderByIdAsc() {
    if (this.isFirmIdAscending) {
      this.sortDirection = 'asc';
    } else {
      this.sortDirection = 'desc';
    }
  }
  setOrderByNameAsc() {
    if (this.isFirmNameAscending) {
      this.sortDirection = 'asc';
    } else {
      this.sortDirection = 'desc';
    }
  }
  setOrderByPendingAsc() {
    if (this.isPendingAscending) {
      this.sortDirection = 'asc';
    } else {
      this.sortDirection = 'desc';
    }
  }

  gridInitialized(flexGrid) {
    flexGrid.rows.defaultSize = 56;
    flexGrid.columnHeaders.rows.defaultSize = 40;
    this.currentRecords = flexGrid.rows.length;
  }

  getfirmsData() {
    this.isGetDataLoading = true;
    let params = new HttpParams();
    params = params.set('page', this.page);
    params = params.set('size', this.pageSize);
    if (this.columnName) {
      params = params.set('sortColumn', this.columnName);
    }
    if (this.sortDirection) {
      params = params.set('sortDir', this.sortDirection);
    }
    if (this.searchText) {
      params = params.set('search', this.searchText);
    }
    if (JSON.parse(environment.loadOnlyFIFirms)) {
      params = params.set('firmType', 'FI');
    }
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/firms?', params).subscribe(
      (result) => {
        this.isGetDataLoading = false;
        this.isitemsperchanged = false;
        this.firmSettingModel.firms = result.firms;
        this.currentInfo.totalItems = result.totalCount;
        this.currentRecords = this.firmSettingModel.firms.length;
        if (this.isLoadedFirstTime) {
          this.totalRecords = result.totalCount;
          this.isLoadedFirstTime = false;
        }
        this.globalservice.addPaginationAdditionalInfo();
      },
      (error) => {
        this.isGetDataLoading = false;
        this.errorMessage = error.error;

        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  searchFirms() {
    this.page = 1;
    this.lastPage = 1;
    this.currentInfo.page = 1;
    this.getfirmsData();
  }
  onPageChanged(page) {
    if (page !== this.lastPage) {
      setTimeout(() => {
        this.page = page;
        this.getfirmsData();
      });
    }
    this.lastPage = page;
  }

  onItemsPerPageChanged(itemsPerPage) {
    this.pageSize = itemsPerPage;
    if (this.page === 1) {
      this.pageSize = itemsPerPage;
      this.getfirmsData();
    }
  }

  navigateToPendingPeerGroup() {
    this.peerGroupService.setSelectedTab = 'Peer groups';
    this.peerGroupService.setCurrentStatus = 'Pending';
  }

  closeAlert($event) {}
}
