import {Injectable} from '@angular/core';
import MappingsModel from '../../models/mappings.model';
import {ViewChild} from '@angular/core';
import {BentoSmartBadgeStyle, BentoSmartBadgeType, BentoSubnavItem, BentoToolbarComponent} from '@bento/bento-ng';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {AuthService} from '../../services/auth/auth.service';
import {BackendTokenClaims} from '../../models/tokenResponse';
import {Role} from 'src/app/shared/enums';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalHeaderServiceService {
  @ViewChild('globalSubnav', {static: false}) globalSubnav: BentoToolbarComponent;

  firm_Id: any;
  unmappedValues = new MappingsModel();
  successMsg = '';
  errorMessage = '';
  alerts: BentoAlertItemOptions[] = [];
  firmSubNav: any[] = [];
  isGetDataLoading = true;
  currentPage: string;
  private inquirySelectionSource = new Subject<string>();
  inquirySelectionHandler = this.inquirySelectionSource.asObservable();
  firmSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

  mappingsData: BentoSubnavItem[] = [
    {
      label: 'Timekeepers',
      useAriaCurrent: true,
      isTransparent: false,
      isVisible: true,
      routerLink: {
        href: '/mappings/timekeepers',
      },
    },
    {
      label: 'Practices',
      useAriaCurrent: true,
      routerLink: {
        href: '/mappings/practices',
      },
    },
    {
      label: 'Titles',
      useAriaCurrent: true,
      routerLink: {
        href: '/mappings/titles',
      },
    },
    {
      label: 'Offices',
      useAriaCurrent: true,
      routerLink: {
        href: '/mappings/offices',
      },
    },
    {
      label: 'GL Offices',
      useAriaCurrent: true,
      routerLink: {
        href: '/mappings/gl-offices',
      },
    },
    {
      label: 'GL Account Codes',
      useAriaCurrent: true,
      routerLink: {
        href: '/mappings/gl-account-codes',
      },
    },
    {
      label: 'Balancing',
      useAriaCurrent: true,
      routerLink: {
        href: '/mappings/balancing',
      },
    },
  ];
  private selectedInquiry: string;

  set setInquirySelection(selectedOption: string) {
    this.selectedInquiry = selectedOption;
    this.inquirySelectionSource.next(selectedOption);
  }
  get getInquirySelection() {
    return this.selectedInquiry;
  }

  constructor(private authService: AuthService) {}

  setMappingsData(id, unmappedValues, isFirmSelected, mappingsData: BentoSubnavItem[]) {
    this.unmappedValues = unmappedValues;
    mappingsData.forEach((item) => {
      if (item.label == 'Timekeepers' && isFirmSelected) {
        item.routerLink.href = this.isTAdmin ? '/mappings/timekeepers/firms/' + id : '/mappings/timekeepers';
      } else if (item.label == 'Practices') {
        item.routerLink.href = this.isTAdmin ? '/mappings/practices/firms/' + id : '/mappings/practices';
        if (this.unmappedValues.practice > 0) {
          item.smartbadge = {
            type: BentoSmartBadgeType.icon,
            content: {value: BentoSmartBadgeStyle.failure},
            color: null,
            ariaLabel: 'Information',
            staticIndicator: false,
          };
        }
      } else if (item.label == 'Titles') {
        item.routerLink.href = this.isTAdmin ? '/mappings/titles/firms/' + id : '/mappings/titles';
        if (this.unmappedValues.title > 0) {
          item.smartbadge = {
            type: BentoSmartBadgeType.icon,
            content: {value: BentoSmartBadgeStyle.failure},
            color: null,
            ariaLabel: 'Information',
            staticIndicator: false,
          };
        }
      } else if (item.label == 'Offices') {
        item.routerLink.href = this.isTAdmin ? '/mappings/offices/firms/' + id : '/mappings/offices';
        if (this.unmappedValues.office > 0) {
          item.smartbadge = {
            type: BentoSmartBadgeType.icon,
            content: {value: BentoSmartBadgeStyle.failure},
            color: null,
            ariaLabel: 'Information',
            staticIndicator: false,
          };
        }
      } else if (item.label == 'GL Offices') {
        item.routerLink.href = this.isTAdmin ? '/mappings/gl-offices/firms/' + id : '/mappings/gl-offices';
        if (this.unmappedValues.glOffice > 0) {
          item.smartbadge = {
            type: BentoSmartBadgeType.icon,
            content: {value: BentoSmartBadgeStyle.failure},
            color: null,
            ariaLabel: 'Information',
            staticIndicator: false,
          };
        }
      } else if (item.label == 'GL Account Codes') {
        item.routerLink.href = this.isTAdmin ? '/mappings/gl-account-codes/firms/' + id : '/mappings/gl-account-codes';
        if (this.unmappedValues.glAccount > 0) {
          item.smartbadge = {
            type: BentoSmartBadgeType.icon,
            content: {value: BentoSmartBadgeStyle.failure},
            color: null,
            ariaLabel: 'Information',
            staticIndicator: false,
          };
        }
      } else if (item.label == 'Balancing') {
        item.routerLink.href = this.isTAdmin ? '/mappings/balancing/firms/' + id : '/mappings/balancing';
      }
    });
  }

  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }

  getFirmSubject() {
    return this.firmSubject.asObservable();
  }
  setFirmSubject(obj) {
    this.firmSubject.next(obj);
  }
}
