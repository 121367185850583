import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BentoComboboxColumn, BentoComboboxOptions} from '@bento/bento-ng/lib';
import {BfmFieldEvent} from '@bento/bfm-ng';
import {BehaviorSubject} from 'rxjs';
import {CreateChart, DateRange, FixedRange, Selection} from 'src/app/core/models/create-chart.model';
import {BaseService} from 'src/app/core/services/base/base.service';
import {PeercheckMessageHandlerService} from 'src/app/core/services/peercheck-message-handler/peercheck-message-handler.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
})
export class DateRangeComponent implements OnInit {
  @ViewChild('DateRangeForm') dateRangeForm: NgForm;
  @Input() createChartModel: CreateChart = new CreateChart();
  @Input() dateRangeField: FixedRange = new FixedRange();
  @Input() maxYears = 3;
  @Input() id = 'dateRangeField1';
  @Input() name = 'dateRangeField1';
  @Input() shouldShowQuarter = false;
  @Input() shouldShowMonth = false;
  @Input() shouldShowCompare = true;
  @Input() isCustomRange = false;
  @Input() disabled = false;
  @Input() yyyymm = 0;
  @Input() ariaFieldHeadingId: string;;
  @Output() userActionPerformed: EventEmitter<any> = new EventEmitter();
  showNewFilter: any = false;
  dateRangeYearsStream: BehaviorSubject<any>;
  dateRangeMonthStream: BehaviorSubject<any>;
  dateRangeQuarterStream: BehaviorSubject<any>;
  comboboxOptions: BentoComboboxOptions = {
    columns: <BentoComboboxColumn[]>[
      // Column definition can be an object formatted as the following
      {
        name: 'name',
      },
    ],
    searchable: true,
    labelFormatter: (row) => {
      return [row.name].join();
    },
  };
  validatePattern = '^(0[1-9]|10|11|12)/([0-9]{4})$';
  validations: {validatePattern: {pattern: string}};

  extractedYYYYMM = {
    year: null,
    month: null,
  };
  fixedRangeValidationStatus: boolean;
  last24Months: string[];
  processedDates: {datesString: any[]; years: any[]; months: any[]};

  constructor(private peercheckMessageHandlerService: PeercheckMessageHandlerService, private service: BaseService) {
    this.validations = {
      validatePattern: {
        pattern: 'Error:  Enter valid date format',
      },
    };
  }

  async ngOnInit() {
    let yyyymmAry = this.yyyymm
      .toString()
      .replace(/(.{4})/g, '$1-')
      .split('-');
    this.extractedYYYYMM.year = yyyymmAry[0];
    this.extractedYYYYMM.month = yyyymmAry[1];

    this.dateRangeYearsStream = new BehaviorSubject([]);
    this.dateRangeQuarterStream = new BehaviorSubject([]);
    this.dateRangeMonthStream = new BehaviorSubject([]);

    this.processedDates = this.getYearAndMonth();
    this.last24Months = this.processedDates.datesString;
    this.updateMonths();

    if (this.dateRangeForm) this.dateRangeForm.reset();
    this.showNewFilter = await this.getNewFilterVisualizationFeatureFlag();
  }

  getYearAndMonth(startDate?: Date, endDate?: Date) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    if (!startDate) {
      startDate = new Date();
      startDate.setFullYear(parseInt(this.extractedYYYYMM.year), parseInt(this.extractedYYYYMM.month) - 1, 1);
      startDate.setMonth(startDate.getMonth() - 23);
    }
    if (!endDate) {
      endDate = new Date();
      endDate.setFullYear(parseInt(this.extractedYYYYMM.year), parseInt(this.extractedYYYYMM.month) - 1, 1);

      // if we want to hide last 3 months to user for canadian firm
      // if(this.createChartModel.isCanadianFirm) {
      //   endDate.setMonth(endDate.getMonth() - 3);
      // }
    }
    var startYear = startDate.getFullYear();
    var endYear = endDate.getFullYear();
    var dates = [];
    var years = [];
    var months = [];
    for (var i = endYear, count = 0; i >= startYear; i--, count++) {
      var endMonth = i != endYear ? 11 : endDate.getMonth();
      var startMon = i === startYear ? startDate.getMonth() : 0;
      for (var j = startMon, Mcount = 0; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1, Mcount++) {
        var month = j + 1;
        var displayMonth = month < 10 ? '0' + month : month;

        let obYear: Selection = {
          id: count,
          value: i,
          name: i.toString(),
        };
        years.push(obYear);
        let obMonth: Selection = {
          id: Mcount,
          value: displayMonth,
          name: monthNames[month - 1],
          ofYear: i.toString(),
        };
        months.push(obMonth);
        dates.push([i, displayMonth, '01'].join('-'));
      }
    }
    this.dateRangeYearsStream = new BehaviorSubject(this.getUniqueListBy(years, 'id'));
    this.dateRangeMonthStream = new BehaviorSubject(this.getUniqueListBy(months, 'id'));
    let finalData = {
      datesString: dates,
      years: years,
      months: months,
    };
    return finalData;
  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  updateMonths() {
    if (this.dateRangeField.years && this.dateRangeField.years.value) {
      this.dateRangeMonthStream = new BehaviorSubject(
        this.processedDates.months.filter((item) => item.ofYear == this.dateRangeField.years.name)
      );
    }
  }

  getNewFilterVisualizationFeatureFlag(): any {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - FILTER_NEW_VISUALIZATION ******/
    /**Remove the call to feature API ***** */
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/FILTER_NEW_VISUALIZATION', '')
      .toPromise()
      .then((result) => {
        return result['FILTER_NEW_VISUALIZATION'];
      });
  }

  handleYearSelection() {
    this.updateMonths();
    if (this.dateRangeField && this.dateRangeField.month) {
      let selectedMonth = this.dateRangeMonthStream.value.find((obj) => obj.value == this.dateRangeField.month.value);
      if (!selectedMonth) {
        this.dateRangeField.month = null;
      } else {
        this.dateRangeField.month = selectedMonth;
      }
    }
    this.userActionPerformed.emit('YOYM');
  }

  handleMonthSelection() {
    this.userActionPerformed.emit('YOYM');
  }

  clearCompareDate() {
    this.fixedRangeValidationStatus = false;
    this.createChartModel.customRange.startDateText2 = null;
    this.createChartModel.customRange.endDateText2 = null;
    this.createChartModel.customRange.startPeriod2 = null;
    this.createChartModel.customRange.endPeriod2 = null;

    // Setting null for current date range as well.
    this.createChartModel.customRange.startPeriod1 = null;
    this.createChartModel.customRange.endPeriod1 = null;
  }

  handleFixedDateRangeSelection() {
    if (
      !(
        this.createChartModel &&
        this.createChartModel.customRange &&
        this.createChartModel.customRange.startDateText &&
        this.createChartModel.customRange.endDateText
      )
    ) {
      this.clearCompareDate();
      this.userActionPerformed.emit('CRC');
      return;
    }

    let startDateText = this.createChartModel.customRange.startDateText;
    let endDateText = this.createChartModel.customRange.endDateText;

    if (
      this.dateRangeForm &&
      this.dateRangeForm.form &&
      this.dateRangeForm.form.controls.startDate.valid &&
      this.dateRangeForm.form.controls.endDate.valid
    ) {
      let startDateMatch = startDateText.match(this.validatePattern);
      let endDateMatch = endDateText.match(this.validatePattern);

      let period = this.peercheckMessageHandlerService.getPeriod(
        this.createChartModel,
        null,
        null,
        false,
        startDateMatch,
        endDateMatch,
        'MMYYYY'
      );

      let calculatedPeriod = this.peercheckMessageHandlerService.getCalculatedTimeRange(
        startDateMatch,
        endDateMatch,
        'MMYYYY'
      );

      if (
        Number(period[0]) <= Number(period[1]) &&
        Number(period[1]) <= Number(this.extractedYYYYMM.year+this.extractedYYYYMM.month) &&
        this.createChartModel.selectedPeerGroups &&
        this.createChartModel.selectedPeerGroups.length
      ) {
        this.createChartModel.customRange.startPeriod1 = period[0];
        this.createChartModel.customRange.endPeriod1 = period[1];

        this.createChartModel.customRange.startDateText2 = calculatedPeriod[0];
        this.createChartModel.customRange.endDateText2 = calculatedPeriod[1];
        let stPeriod = calculatedPeriod[0].toString().split('/');
        let endPeriod = calculatedPeriod[1].toString().split('/');
        this.createChartModel.customRange.startPeriod2 = stPeriod[1] + stPeriod[0];
        this.createChartModel.customRange.endPeriod2 = endPeriod[1] + endPeriod[0];
      }
    } else {
      this.clearCompareDate();
      this.userActionPerformed.emit('CRC');
    }
  }

  isValidDate(evt: BfmFieldEvent, sourceElement: string, formData: any): void {
    if (!evt) {
      this.clearCompareDate();
      return;
    }
    const setValidity = evt.setValidity;
    if (
      (sourceElement == 'EndDate' && formData.form.controls.startDate.value) ||
      (sourceElement == 'StartDate' && formData.form.controls.endDate.value)
    ) {
      const fg = evt.formControl;
      let endDate = formData.form.controls.endDate.value;
      let startDate = formData.form.controls.startDate.value;
      let startDateMatch = startDate.match(this.validatePattern);
      let endDateMatch = endDate.match(this.validatePattern);

      if (
        !(
          startDateMatch &&
          endDateMatch &&
          (Number(startDateMatch[2]) < Number(endDateMatch[2]) ||
            (Number(startDateMatch[2]) == Number(endDateMatch[2]) &&
              Number(startDateMatch[1]) <= Number(endDateMatch[1])))
        )
      ) {
        this.clearCompareDate();
        this.userActionPerformed.emit('CRC');

        setTimeout(() => {
          return setValidity({valid: false, message: 'Start Date should be less than End Date'});
        }, 0);
      } else {
        let stDate = new Date();
        stDate.setFullYear(parseInt(startDateMatch[2]), parseInt(startDateMatch[1]) - 1, 1);
        let endDate = new Date();
        endDate.setFullYear(parseInt(endDateMatch[2]), parseInt(endDateMatch[1]) - 1, 1);
        let currentDate = new Date();
        currentDate.setFullYear(parseInt(this.extractedYYYYMM.year), parseInt(this.extractedYYYYMM.month) - 1, 1);
        let diff = this.monthDiff(stDate, endDate);
        let calculatedPeriod = this.peercheckMessageHandlerService.getCalculatedTimeRange(
          startDateMatch,
          endDateMatch,
          'MMYYYY'
        );

        if (this.monthDiff(endDate, currentDate) <= 0) {
          if (sourceElement == 'EndDate'){
            this.clearCompareDate();
            this.userActionPerformed.emit('CRC');
            formData.form.get('startDate').updateValueAndValidity();
            setTimeout(() => {
              return setValidity({
                valid: false,
                message: `End date cannot be beyond ${this.extractedYYYYMM.month}/${this.extractedYYYYMM.year}.`,
              });
            }, 0);
          }
        } else if (diff > 12) {
          this.clearCompareDate();
          this.userActionPerformed.emit('CRC');
          setTimeout(() => {
            return setValidity({valid: false, message: 'Range cannot be more than 12 months.'});
          }, 0);
        } else if (this.verifySelectedDateRange(calculatedPeriod)) {
          this.clearCompareDate();
          this.userActionPerformed.emit('CRC');
          setTimeout(() => {
            if (this.verifySelectedDateRange(calculatedPeriod, 'StartDate')) {
              formData.form.get('startDate').updateValueAndValidity();
            } else if (this.verifySelectedDateRange(calculatedPeriod, 'EndDate')) {
              formData.form.get('endDate').updateValueAndValidity();
            }
            return setValidity({valid: false, message: 'Only available for the past 36 months.'});
          }, 0);
        } else {
          setValidity({valid: true});
          let period = this.peercheckMessageHandlerService.getPeriod(
            this.createChartModel,
            null,
            null,
            false,
            startDateMatch,
            endDateMatch,
            'MMYYYY'
          );
          this.createChartModel.customRange.startPeriod1 = period[0];
          this.createChartModel.customRange.endPeriod1 = period[1];

          this.createChartModel.customRange.startDateText2 = calculatedPeriod[0];
          this.createChartModel.customRange.endDateText2 = calculatedPeriod[1];
          let stPeriod = calculatedPeriod[0].toString().split('/');
          let endPeriod = calculatedPeriod[1].toString().split('/');
          this.createChartModel.customRange.startPeriod2 = stPeriod[1] + stPeriod[0];
          this.createChartModel.customRange.endPeriod2 = endPeriod[1] + endPeriod[0];

          this.dateRangeForm = formData;

          if (!this.fixedRangeValidationStatus) {
            this.fixedRangeValidationStatus = true;
            this.userActionPerformed.emit('CRC');
          }

          if (sourceElement == 'EndDate' && formData.form.controls.startDate.status == 'INVALID') {
            return formData.form.get('startDate').updateValueAndValidity();
          } else if (sourceElement == 'StartDate' && formData.form.controls.endDate.status == 'INVALID') {
            return formData.form.get('endDate').updateValueAndValidity();
          }
        }
      }
    } else {
      if (sourceElement == 'EndDate' && formData.form.controls.endDate.value) {
        let endDateTextBoxValue = formData.form.controls.endDate.value;
        let endDateMatch = endDateTextBoxValue.match(this.validatePattern);
        let endDate = new Date();
        endDate.setFullYear(parseInt(endDateMatch[2]), parseInt(endDateMatch[1]) - 1, 1);
        let currentDate = new Date();
        currentDate.setFullYear(parseInt(this.extractedYYYYMM.year), parseInt(this.extractedYYYYMM.month) - 1, 1);

        if (this.monthDiff(endDate, currentDate) <= 0) {
          this.clearCompareDate();
          this.userActionPerformed.emit('CRC');
          formData.form.get('startDate').updateValueAndValidity();
          setTimeout(() => {
            return setValidity({
              valid: false,
              message: `End date cannot be beyond ${this.extractedYYYYMM.month}/${this.extractedYYYYMM.year}.`,
            });
          }, 0);
        }
         return;
      } else {
        this.clearCompareDate();
        this.userActionPerformed.emit('CRC');
        return;
      }
    }
  }

  verifySelectedDateRange(calculatedTimeRange, dateRangeCheckFor: string = null) {
    let dateOflastRelease = new Date();
    dateOflastRelease.setFullYear(parseInt(this.extractedYYYYMM.year), parseInt(this.extractedYYYYMM.month) - 1, 1);
    let startDatePeriod = calculatedTimeRange[0].split('/');
    let endDatePeriod = calculatedTimeRange[1].split('/');
    let startDate = new Date(startDatePeriod[1] + ',' + startDatePeriod[0] + ',01');
    startDate.setFullYear(parseInt(startDatePeriod[1]), parseInt(startDatePeriod[0]) - 1, 1);
    let endDate = new Date(endDatePeriod[1] + ',' + endDatePeriod[0] + ',01');
    endDate.setFullYear(parseInt(endDatePeriod[1]), parseInt(endDatePeriod[0]) - 1, 1);
    if (
      !dateRangeCheckFor &&
      (this.monthDiff(startDate, dateOflastRelease) > 36 || this.monthDiff(endDate, dateOflastRelease) > 36)
    ) {
      return true;
    } else if (dateRangeCheckFor) {
      if (dateRangeCheckFor == 'StartDate' && this.monthDiff(startDate, dateOflastRelease) > 36) {
        return true;
      } else if (dateRangeCheckFor == 'EndDate' && this.monthDiff(endDate, dateOflastRelease) > 36) {
        return true;
      }
    }
    return false;
  }

  monthDiff(d1: Date, d2: Date) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth() + 1;
    return months <= 0 ? 0 : months;
  }
}
