import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-svg-clock',
  templateUrl: './svg-clock.component.svg',
})
export class SvgClockComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
