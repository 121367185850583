<bento-alert [items]="alerts"></bento-alert>
<section class="Section" [bentoBusyLoader]="ispracticeUpdated">
  <form name="fpsc" #fpsc="ngForm" class="Section-form Form" bfmForm *ngIf="isGetPracticeSettings">
    <header class="Section-header">
      <h2 class="Section-heading">Practice settings</h2>
    </header>
    <span class="label-required-summary">Required field</span>
    <div class="Section-body">
      <div class="Form-set">
        <div class="form-group Form-input Form-input--medium">
          <label for="practiceGroup" id="practiceGroupLabel">Default practice group</label>
          <select
            name="practiceGroup"
            id="practiceGroup"
            class="form-control"
            bentoSelect
            bfmField
            bfmLabel="Default practice group"
            aria-labelledby="practiceGroupLabel"
            [(ngModel)]="firmPracticeSetting.defaultPracticeGroup"
          >
            <option *ngFor="let type of practiceGroupTypes" [ngValue]="type.code">{{ type.description }}</option>
          </select>
        </div>
      </div>

      <fieldset class="Form-set">
        <legend class="h4 Form-label">Practice group component</legend>
        <div class="form-group Form-input Form-checkbox">
          <bento-checkbox
            name="practiceGroupDepartment"
            id="practiceGroupDepartment"
            [(ngModel)]="firmPracticeSetting.practiceGroupDepartment"
          ></bento-checkbox>
          <label for="practiceGroupDepartment" class="checkbox-inline">Department</label>
        </div>
        <div class="form-group Form-input Form-checkbox">
          <bento-checkbox
            name="practiceGroupSection"
            id="practiceGroupSection"
            [(ngModel)]="firmPracticeSetting.practiceGroupSection"
          ></bento-checkbox>
          <label for="practiceGroupSection" class="checkbox-inline">Section</label>
        </div>
        <div class="form-group Form-input Form-checkbox">
          <bento-checkbox
            name="practiceGroupOffice"
            id="practiceGroupOffice"
            [(ngModel)]="firmPracticeSetting.practiceGroupOffice"
          ></bento-checkbox>
          <label for="practiceGroupOffice" class="checkbox-inline">Office</label>
        </div>
        <div class="form-group Form-input">
          <bento-checkbox
            name="practiceGroupUserDefined"
            id="practiceGroupUserDefined"
            [(ngModel)]="firmPracticeSetting.practiceGroupUserDefined"
          ></bento-checkbox>
          <label for="practiceGroupUserDefined" class="checkbox-inline">User-defined</label>
        </div>
        <div class="Form-group Form-group--indent" *ngIf="firmPracticeSetting.practiceGroupUserDefined">
          <div class="form-group Form-input Form-input--large" *ngIf="firmType === '3'">
            <label for="udfFieldGroup" id="udfFieldGroupLabel">UDF field name</label>
            <select
              name="udfFieldGroup"
              id="udfFieldGroup"
              class="form-control"
              bentoSelect
              bfmField
              bfmLabel="UDF field name"
              aria-labelledby="udfFieldGroupLabel"
              [(ngModel)]="firmPracticeSetting.udfFieldName"
            >
              <option *ngFor="let type of udfFieldNames" [ngValue]="type.value">{{ type.label }}</option>
            </select>
          </div>
          <ng-container *ngIf="firmType != '3' || firmPracticeSetting.udfFieldName === 'OTHER'">
            <div class="form-group Form-input Form-input--large">
              <label for="fieldName" class="bento-label-required">Field name</label>
              <input
                type="text"
                name="fieldName"
                id="fieldName"
                class="form-control"
                bfmField
                required
                bfmLabel="Field name"
                [(ngModel)]="firmPracticeSetting.fieldName"
              />
            </div>
            <div class="form-group Form-input Form-input--large">
              <label for="lookupTable" class="bento-label-required">Lookup table</label>
              <input
                type="text"
                name="lookupTable"
                id="lookupTable"
                class="form-control"
                bfmField
                required
                bfmLabel="Lookup table"
                [(ngModel)]="firmPracticeSetting.lookupTable"
              />
            </div>
            <div class="form-group Form-input Form-input--large">
              <label for="codeField" class="bento-label-required">Code field</label>
              <input
                type="text"
                name="codeField"
                id="codeField"
                class="form-control"
                bfmField
                required
                bfmLabel="Code field"
                [(ngModel)]="firmPracticeSetting.codeField"
              />
            </div>
            <div class="form-group Form-input Form-input--large">
              <label for="descriptionField" class="bento-label-required">Description field</label>
              <input
                type="text"
                name="descriptionField"
                id="descriptionField"
                required
                class="form-control"
                bfmField
                bfmLabel="Description field"
                [(ngModel)]="firmPracticeSetting.descField"
              />
            </div>
          </ng-container>
        </div>
      </fieldset>
    </div>
    <footer class="Section-footer">
      <div class="Actions">
        <div class="Actions-item">
          <button type="button" class="btn btn-outline-secondary" (click)="resetFirmPracticeSetting()">
            <span class="btn-text">Cancel</span>
          </button>
        </div>
        <div class="Actions-item a11y">
          <button
            type="submit"
            class="btn btn-primary"
            [attr.aria-disabled]="
              firmPracticeSetting.practiceGroupUserDefined &&
              (!firmPracticeSetting.fieldName ||
                !firmPracticeSetting.lookupTable ||
                !firmPracticeSetting.codeField ||
                !firmPracticeSetting.descField) ? 'true' : 'false'
            "
            (click)="
              firmPracticeSetting.practiceGroupUserDefined &&
              (!firmPracticeSetting.fieldName ||
                !firmPracticeSetting.lookupTable ||
                !firmPracticeSetting.codeField ||
                !firmPracticeSetting.descField) ? $event.stopPropagation() : savePractice()
            "
            [attr.aria-labelledby]="
              firmPracticeSetting.practiceGroupUserDefined &&
              (!firmPracticeSetting.fieldName ||
                !firmPracticeSetting.lookupTable ||
                !firmPracticeSetting.codeField ||
                !firmPracticeSetting.descField) ? 'aria-required-save' : null
            "
          >
            <span class="btn-text">Save</span>
          </button>
        </div>
        <span class="sr-only" id="aria-required-save">Fill required fields, Save</span>
      </div>
    </footer>
  </form>
</section>
