import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import CreateViewModel, {ViewDetails} from 'src/app/core/models/create-view.model';
import {ExportViewRequestBody} from 'src/app/core/models/export-view-requestbody.model';
import FirmBasicsModel from 'src/app/core/models/firm-basics.model';
import {environment} from 'src/environments/environment';
import {BaseService} from 'src/app/core/services/base/base.service';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {DownloadService} from 'src/app/core/services/download/download.service';
import {ActivatedRoute} from '@angular/router';
import {v4 as uuidv4} from 'uuid';
import {PeercheckMessageHandlerService} from 'src/app/core/services/peercheck-message-handler/peercheck-message-handler.service';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
declare let TRAAC: any;

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss'],
})
export class ExportModalComponent implements OnInit {
  totalMetricSelected: number;
  detailed = false;
  monthly = false;
  quarter = false;
  constructor(
    private downloadFile: DownloadService,
    public activeModal: NgbActiveModal,
    private service: BaseService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private peercheckMessageHandlerService: PeercheckMessageHandlerService,
    private featureToggleService: FeatureToggleService
  ) {}
  metrics: any;
  isDataLoading = false;
  isQuickSightEnabled = false;
  @Input() public triggeredFrom: string;
  @Input() public firmBasicData: FirmBasicsModel;
  @Input() public viewDetails: ViewDetails;
  @Input() public userSettingsModel: CreateViewModel;
  @Input() public firmId: any;
  warningAlerts: BentoAlertItemOptions[] = [];
  ngOnInit(): void {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
    this.featureToggleService.getFeatureValue('QUICKSIGHT').then((val) => (this.isQuickSightEnabled = val));
    /*********************************************************** */
    this.addCheckboxProperties();
  }

  addCheckboxProperties() {
    this.totalMetricSelected = 0;
    this.metrics = [];
    this.viewDetails.metrics.forEach((element) => {
      let metricMetadata = this.firmBasicData.metrics.filter((metricMetadata) => element.id == metricMetadata.metricId);
      if (metricMetadata && (metricMetadata[0].hasLevels || metricMetadata[0].canPivot)) {
        let obj = {
          id: element.id,
          name: element.name,
          detailed: false,
          monthly: false,
          quarter: false,
          isDisabled: false,
        };
        this.metrics.push(obj);
      }
    });
  }
  onExportClick() {
    if (this.totalMetricSelected == 0) {
      return;
    }
    this.isDataLoading = true;
    if (this.isQuickSightEnabled) {
      TRAAC.track(TRAAC.keyValue('Export click', {email: this.authService.getUserEmail()}).build());
    }
    let requestBodyForExport = this.requestBody();
    this.service
      .downloadExcelPost(environment.FIAdminBaseEndpoint + 'v1/report/export/' + this.firmId, requestBodyForExport)
      .subscribe(
        (response) => {
          this.downloadFile.download(response.body, response.headers.get('content-disposition').split('=')[1]);
          this.isDataLoading = false;
        },
        (error) => {
          this.warningAlerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
          this.isDataLoading = false;
        }
      );
  }
  onChangeHeader(title: string) {
    for (let i = 0; i < this.metrics.length; i++) {
      if (title == 'detailed') {
        this.metrics[i].detailed = this.detailed;
      } else if (title == 'monthly') {
        this.metrics[i].monthly = this.monthly;
      } else if (title == 'quarter') {
        this.metrics[i].quarter = this.quarter;
      }
    }
    this.onCheckChange();
  }
  requestBody() {
    let requestBodyForExport = new ExportViewRequestBody();
    let detailedMetric = this.metrics.filter((item) => item.detailed === true).map(({id}) => id);

    let quarterMetric = this.metrics.filter((item) => item.quarter === true).map(({id}) => id);
    let monthlyMetric = this.metrics.filter((item) => item.monthly === true).map(({id}) => id);
    let peerGroups = this.viewDetails.peerGroups.map(({id}) => id);
    requestBodyForExport.detailedReport = detailedMetric;
    requestBodyForExport.monthlyReport = monthlyMetric;
    requestBodyForExport.quarterReport = quarterMetric;
    requestBodyForExport.quid = uuidv4();
    requestBodyForExport.type = 'export';
    requestBodyForExport.yyyymm = this.firmBasicData.yyyymm;
    requestBodyForExport.currency = this.firmBasicData.currency;
    requestBodyForExport.firmCurrency = this.firmBasicData.firmCurrency;
    requestBodyForExport.defaultAccess = this.firmBasicData.defaultAccess;
    requestBodyForExport.matterType = this.viewDetails.matterTypeSetting.toLowerCase();
    requestBodyForExport.contractors = this.viewDetails.contractorsSetting.toLowerCase();
    requestBodyForExport.currencyType = this.viewDetails.currencySetting.toLowerCase();
    requestBodyForExport.statistic = this.viewDetails.statisticSetting.toLowerCase();
    // requestBodyForExport.observe= 'response';
    // requestBodyForExport.blob= 'blob';
    requestBodyForExport.peerGroups = peerGroups;

    //Time Range
    if (this.userSettingsModel.timeRangeSelected == 'Rolling') {
      requestBodyForExport.startPeriod = this.userSettingsModel.rollingTimeRangeSelectedItems.startPeriod1;
      requestBodyForExport.endPeriod = this.userSettingsModel.rollingTimeRangeSelectedItems.endPeriod1;
    } else if (this.userSettingsModel.timeRangeSelected == 'Year To Date(YTD)') {
      const year = this.firmBasicData.yyyymm.toString().slice(0, 4);
      const month = this.firmBasicData.yyyymm.toString().slice(-2);
      const startDate = new Date();
      const endDate = new Date();
      startDate.setDate(1);
      startDate.setMonth(1);
      startDate.setFullYear(parseInt(year));
      endDate.setDate(1);
      endDate.setMonth(parseInt(month) - 1);
      endDate.setFullYear(parseInt(year));
      requestBodyForExport.startPeriod = startDate.getFullYear() + '01';
      requestBodyForExport.endPeriod = endDate.getFullYear() + ('0' + (endDate.getMonth() + 1)).slice(-2);
    } else {
      requestBodyForExport.startPeriod = this.userSettingsModel.fixedTimeRange.startPeriod1;
      requestBodyForExport.endPeriod = this.userSettingsModel.fixedTimeRange.endPeriod1;
    }
    if (this.viewDetails && this.viewDetails.offices && this.viewDetails.offices.length > 0) {
      let offices: number[] = [...new Set(this.viewDetails.offices.map((item) => item.id))];
      let parents: number[] = offices.filter((id) => id < 0);
      let child: number[] = offices.filter((id) => id >= 0);
      offices = this.peercheckMessageHandlerService.getExtractGroupIds(
        this.firmBasicData.officeGroups,
        offices,
        parents,
        child
      );
      requestBodyForExport['offices'] = offices;
    }
    if (this.viewDetails && this.viewDetails.titles && this.viewDetails.titles.length > 0) {
      let titles: number[] = [...new Set(this.viewDetails.titles.map((item) => item.id))];
      let parents: number[] = titles.filter((id) => id < 0);
      let child: number[] = titles.filter((id) => id >= 0);
      titles = this.peercheckMessageHandlerService.getExtractGroupIds(
        this.firmBasicData.titleGroups,
        titles,
        parents,
        child
      );
      requestBodyForExport['titles'] = titles;
    }
    if (this.viewDetails && this.viewDetails.practices) {
      let practices: number[] = [...new Set(this.viewDetails.practices.map((item) => item.id))];
      requestBodyForExport['practices'] = practices;
    }

    let experienceYears = {};

    if (this.viewDetails.partnerExpYears) {
      let partnerYears: number[] = [...new Set(this.viewDetails.partnerExpYears.map((item) => item.id))];
      experienceYears['partnerYears'] = partnerYears;
    } else {
      experienceYears['partnerYears'] = [];
    }

    if (this.viewDetails.srAssociateExpYears) {
      let srAssociateYears: number[] = [...new Set(this.viewDetails.srAssociateExpYears.map((item) => item.id))];
      experienceYears['srAssociateYears'] = srAssociateYears;
    } else {
      experienceYears['srAssociateYears'] = [];
    }

    if (this.viewDetails.associateExpYears) {
      let associateYears: number[] = [...new Set(this.viewDetails.associateExpYears.map((item) => item.id))];
      experienceYears['associateYears'] = associateYears;
    } else {
      experienceYears['associateYears'] = [];
    }

    requestBodyForExport['experienceYears'] = experienceYears;
    requestBodyForExport['metricsOrder'] = this.metrics.map((item) => item.id);

    return requestBodyForExport;
  }
  onCheckChange() {
    let detailedCounter = 0;
    let quarterCounter = 0;
    let monthlyCounter = 0;
    this.totalMetricSelected = 0;
    for (const item of this.metrics) {
      if (item.detailed == false) {
        this.detailed = false;
      } else {
        detailedCounter++;
      }
      if (item.quarter == false) {
        this.quarter = false;
      } else {
        quarterCounter++;
      }
      if (item.monthly == false) {
        this.monthly = false;
      } else {
        monthlyCounter++;
      }
      if (item.detailed || item.quarter || item.monthly) {
        this.totalMetricSelected++;
      }
    }
    if (detailedCounter == this.metrics.length) {
      this.detailed = true;
    }
    if (quarterCounter == this.metrics.length) {
      this.quarter = true;
    }
    if (monthlyCounter == this.metrics.length) {
      this.monthly = true;
    }
    if (this.totalMetricSelected >= 10) {
      for (const item of this.metrics) {
        if (!(item.detailed || item.quarter || item.monthly)) {
          item.isDisabled = true;
        }
      }
    } else {
      for (const item of this.metrics) {
        item.isDisabled = false;
      }
    }
  }
}
