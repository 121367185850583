export enum ActionTypes {
  CreateCustomer = '[Customer API] Create Customer',
  UpdateCustomer = '[Customer API] Update Customer',
  DeleteCustomer = '[Customer API] Delete Customer',
  GetAllCustomer = '[Customer API] Get All Customer',
  SetAllCustomer = '[Customer API] Set All Customer',
  GetCustomerById = '[Customer API] Get All Customer By Id',
  FilterCustomer = '[Customer API] Filter Customer',
  GetFirmList = '[Firm API] Get all firms list',
  GetFirmDetail = '[Firm Detail API] Get firm Information by Id',
  SetFirmDetail = '[Firm Detail API] Set firm Information by Id',
  GetFirmParameterSetting = '[Firm Parameter API] Get firm Parameter by Id',
  SetFirmParameterSetting = '[Firm Parameter API] Set firm Parameter by Id',
  SetUserDetail = '[User Detail API] Set user information by Id',
  GetUserDetails = '[User Detail API] Get user information by Id',
  SetStatistic = '[Statistic Detail] Set statistic selected by user',
  GetStatistic = 'Get statistic selected by user',
  SetBasicDetails = '[Basic Detail] Set basic details for the firm',
  GetBasicDetails = 'Get basic details for the firm',
  SetPivotRequestBody = '[Basic Detail] Set request body for Pivot table',
  GetPivotRequestBody = 'Get request body for Pivot table',
  SetSelectedFirmId = 'Set selected firm id from dropdown',
  GetSelectedFirmId = 'Get selected firm id from dropdown',
  SetProcessedData = 'Set processed data for grid',
  GetProcessedData = 'Get processed data for grid',
}
