export default class StaffingratioAdminConfiguration {
  public firmId: number;
  public firmName: string;
  public surveyYear: string;
  public firmDataValid: boolean;
  public peerSubmitted: boolean;
  public reportReady: boolean;
  public isPeerDisplayed: boolean = false;
  public isSubscribed: boolean = false;
  public isFirmDisabled: boolean = false;
  public dateTimeReportExported: string;
  public dateTimeReportImported: string;
  public dataProcessing: SRFlagDataProcessing = new SRFlagDataProcessing();
  public isUpdating:boolean=false;
  public constructor() {}
}
export class SRFlagDataProcessing {
  firmDataValid = false;
  peerSubmitted = false;
  isFirmDisabled = false;
  isPeerDisplayed = false;
  reportReady = false;
}

export class FlagRequestBody {
  firmId: number;
  surveyYear: number;
  firmDataValid: boolean ;
  peersDisplayed: boolean ;
  peersSubmitted: boolean ;
  reportsReady: boolean ;
  firmDisabled: boolean ;
}