import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TkFilterModel} from 'src/app/core/models/tk-filter.model';
import {TkHistoryRequest} from 'src/app/core/models/tk-history-request.model';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import {BentoAlertItemOptions} from '@bento/bento-ng/lib';
import {GlobalHeaderServiceService} from '../../global-header/global-header-service.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PeerCheckServiceService} from '../../peer-check/peer-check/peer-check-service.service';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {Role} from 'src/app/shared/enums';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
})
export class InquiryComponent implements OnInit, OnDestroy {
  alerts: BentoAlertItemOptions[] = [];
  isDataLoading = false;
  actionDropdownItems: {name: string; isDisabled: boolean}[];
  isFilterOpen = false;
  isMainBusyLoaderBusy = false;
  tkFilterData: TkFilterModel = new TkFilterModel();
  tkHistoryRecordRequest: TkHistoryRequest = new TkHistoryRequest();
  tkHistoryFilterData: TkHistoryRequest = new TkHistoryRequest();
  validations: {tkFilterFte: {pattern: string}};
  tkFilterFteVal = '(([0.0])|([0].*)|([0].[0-9][0-9])|([1.0]))';
  isColumnFreezed = false;
  pageInfo: any = {
    infoText: '_START_ to _END_ of _MAX_ Users',
    infoPageText: '_PAGE_ of _PAGES_',
    goText: 'Go',
    pageSize: 10,
  };
  CHECKBOX_SELECTED = 1;
  CHECKBOX_UNSELECTED = 2;
  headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
  CHECKBOX_INDETERMINATE = 3;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  @ViewChild('filter', {static: true}) gridFilter: wjcGridFilter.FlexGridFilter;
  selectedInquiry: any;
  inquirySubscriptions: any;
  showInquiryMenu: any = false;
  firmId: any;

  constructor(
    private globalHeaderService: GlobalHeaderServiceService,
    private authService: AuthService,
    private _peerCheckService: PeerCheckServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private service: BaseService
  ) {
    router.events.subscribe((val: any) => {
      if (val && val.url) {
        const urlarr = val.url.split('/');
        if (urlarr && urlarr.length > 0) {
          const urlFirmId = urlarr[urlarr.length - 1];
          if (!isNaN(urlFirmId)) {
            this.firmId = urlFirmId;
          }
        }
      }
    });
    this.actionDropdownItems = [
      {name: 'Export selected', isDisabled: false},
      {name: 'Import', isDisabled: false},
    ];

    this.validations = {
      tkFilterFte: {
        pattern: 'Error: Value should be between 0 to 1',
      },
    };
  }

  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }

  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (this.firmId) return this.firmId;
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : parseInt(this.route.snapshot.paramMap.get('id'));
  }

  getInquiryFeatureFlag(): any {
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/INQUIRY_MENU', '')
      .toPromise()
      .then((result) => {
        return result['INQUIRY_MENU'];
      });
  }

  async ngOnInit() {
    if (!this.firmId) {
      const urlarr = this.router.url.split('/');
      if (urlarr && urlarr.length > 0) {
        const urlFirmId = urlarr[urlarr.length - 1];
        if (!isNaN(parseInt(urlFirmId))) {
          this.firmId = urlFirmId;
        }
      }
    }
    this.selectedInquiry = this.globalHeaderService.getInquirySelection;
    this.showInquiryMenu = await this.getInquiryFeatureFlag();
    if (this.isTAdmin && (!this._peerCheckService.isValidMappingFirm(this.firmId) || !this.showInquiryMenu)) {
      this.router.navigate(['']);
    } else {
      if (this.router.url.includes('production-extract')) {
        if (this.isTAdmin) {
          this.router.navigate(['/mappings/inquiry/production-extract/firms/' + this.firmId]);
        } else {
          this.router.navigate(['/mappings/inquiry/production-extract']);
        }
      }
      if (this.router.url.includes('gl-extract')) {
        if (this.isTAdmin) {
          this.router.navigate(['/mappings/inquiry/gl-extract/firms/' + this.firmId]);
        } else {
          this.router.navigate(['/mappings/inquiry/gl-extract']);
        }
      }
      if (!this.inquirySubscriptions) {
        this.inquirySubscriptions = this.globalHeaderService.inquirySelectionHandler.subscribe((selectedOption) => {
          if (selectedOption) {
            this.selectedInquiry = selectedOption;
            // TODO: This is Initial Point
            // Start Initialization task from here.
          } else {
            this.router.navigate(['/mappings']);
          }
        });
      }
    }
  }

  ngOnDestroy(): void {
    if (this.inquirySubscriptions) {
      this.inquirySubscriptions.unsubscribe();
    }
  }

  dropDownOnSelectChange(name) {
    // Write code to perform an action on selected option from action dropdown.
  }

  onClickFilter(newValue: boolean) {
    if (this.isFilterOpen === newValue) {
      this.isFilterOpen = false;
    } else {
      this.isFilterOpen = newValue;
    }
  }

  validateNumber(v: any) {
    if (v.value == '') v.value = v.value.slice(0, -1);
  }

  validateNumberWith(e) {
    if (e.keyCode == 43 || e.keyCode == 45 || e.keyCode == 101) {
      return false;
    }
  }

  onNamesSelected(names: any[]) {
    this.tkHistoryRecordRequest.name = [];
    if (names.length == 1) {
      this.tkFilterData.selectedNamesText = '1 name selected';
    } else if (names.length == this.tkFilterData.names.length) {
      this.tkFilterData.selectedNamesText = 'All';
    } else this.tkFilterData.selectedNamesText = names.length.toString() + ' names selected';
    for (var name of names) {
      this.tkHistoryRecordRequest.name.push(name);
    }
  }

  searchCompare(item: any, search: string) {
    const name = item;
    const searchStr = search.toLowerCase();
    return name.toLowerCase().indexOf(searchStr) > -1;
  }

  onTkinitItemsSelected(tkIds: any[]) {
    this.tkHistoryRecordRequest.tkId = [];
    if (tkIds.length == 1) {
      this.tkFilterData.selectedTkinitsText = '1 TKINIT selected';
    } else if (tkIds.length == this.tkFilterData.tkids.length) {
      this.tkFilterData.selectedTkinitsText = 'All';
    } else this.tkFilterData.selectedTkinitsText = tkIds.length.toString() + ' TKINITs selected';
    for (var tkid of tkIds) {
      this.tkHistoryRecordRequest.tkId.push(tkid);
    }
  }

  onTitleSelected(titles: any[]) {
    this.tkHistoryRecordRequest.firmTitle = [];
    if (titles.length == 1) {
      this.tkFilterData.selectedFirmTitleText = '1 title selected';
    } else if (titles.length == this.tkFilterData.titles.length) {
      this.tkFilterData.selectedFirmTitleText = 'All';
    } else this.tkFilterData.selectedFirmTitleText = titles.length.toString() + ' titles selected';
    for (var title of titles) {
      let firmTitle = Object.keys(this.tkFilterData.titleKeyValue).find(
        (key) => this.tkFilterData.titleKeyValue[key] === title
      );
      this.tkHistoryRecordRequest.firmTitle.push(firmTitle);
    }
  }
  onOfficeSelected(offices: any[]) {
    this.tkHistoryRecordRequest.firmOffice = [];
    if (offices.length == 1) {
      this.tkFilterData.selectedFirmOfficeText = '1 office selected';
    } else if (offices.length == this.tkFilterData.offices.length) {
      this.tkFilterData.selectedFirmOfficeText = 'All';
    } else this.tkFilterData.selectedFirmOfficeText = offices.length.toString() + ' offices selected';
    for (var office of offices) {
      let firmOffice = Object.keys(this.tkFilterData.officeKeyValue).find(
        (key) => this.tkFilterData.officeKeyValue[key] === office
      );
      this.tkHistoryRecordRequest.firmOffice.push(firmOffice);
    }
  }
  onDeptSelected(departments: any[]) {
    this.tkHistoryRecordRequest.department = [];
    if (departments.length == 1) {
      this.tkFilterData.selectedDeptText = '1 department selected';
    } else if (departments.length == this.tkFilterData.departments.length) {
      this.tkFilterData.selectedDeptText = 'All';
    } else this.tkFilterData.selectedDeptText = departments.length.toString() + ' departments selected';
    for (var department of departments) {
      let dept = Object.keys(this.tkFilterData.deptKeyValue).find(
        (key) => this.tkFilterData.deptKeyValue[key] === department
      );
      this.tkHistoryRecordRequest.department.push(dept);
    }
  }
  onPeerOfficeSelected(peerOffices: any[]) {
    this.tkHistoryRecordRequest.peerOffice = [];
    if (peerOffices.length == 1) {
      this.tkFilterData.selectedPeerOfficeText = '1 peer office selected';
    } else if (peerOffices.length == this.tkFilterData.peerOffices.length) {
      this.tkFilterData.selectedPeerOfficeText = 'All';
    } else this.tkFilterData.selectedPeerOfficeText = peerOffices.length.toString() + ' peer offices selected';
    for (var office of peerOffices) {
      let peerOffice = Object.keys(this.tkFilterData.peerOfficeKeyValue).find(
        (key) => this.tkFilterData.peerOfficeKeyValue[key] === office
      );
      this.tkHistoryRecordRequest.peerOffice.push(peerOffice);
    }
  }
  onPeerPracticeSelected(peerPractices: any[]) {
    this.tkHistoryRecordRequest.peerPractice = [];
    if (peerPractices.length == 1) {
      this.tkFilterData.selectedPeerPracticeText = '1 peer practice selected';
    } else if (peerPractices.length == this.tkFilterData.peerPractices.length) {
      this.tkFilterData.selectedPeerPracticeText = 'All';
    } else this.tkFilterData.selectedPeerPracticeText = peerPractices.length.toString() + ' peer practices selected';
    for (var practice of peerPractices) {
      let peerPractice = Object.keys(this.tkFilterData.peerPracticeKeyValue).find(
        (key) => this.tkFilterData.peerPracticeKeyValue[key] === practice
      );
      this.tkHistoryRecordRequest.peerPractice.push(peerPractice);
    }
  }
  onPeerTitleSelected(peerTitles: any[]) {
    this.tkHistoryRecordRequest.peerTitle = [];
    if (peerTitles.length == 1) {
      this.tkFilterData.selectedPeerTitleText = '1 peer title selected';
    } else if (peerTitles.length == this.tkFilterData.peerTitles.length) {
      this.tkFilterData.selectedPeerTitleText = 'All';
    } else this.tkFilterData.selectedPeerTitleText = peerTitles.length.toString() + ' peer titles selected';
    for (var title of peerTitles) {
      let peerTitle = Object.keys(this.tkFilterData.peerTitleKeyValue).find(
        (key) => this.tkFilterData.peerTitleKeyValue[key] === title
      );
      this.tkHistoryRecordRequest.peerTitle.push(peerTitle);
    }
  }
  onExpYearSelected(expYear: number) {
    this.tkHistoryRecordRequest.experienceYear = [];
    if (expYear != null) this.tkHistoryRecordRequest.experienceYear.push(expYear);
  }

  isAllSelected() {
    return this.headerCheckBoxMode === this.CHECKBOX_SELECTED;
  }

  isIndeterminate() {
    return this.headerCheckBoxMode === this.CHECKBOX_INDETERMINATE;
  }

  // Event Handler for Multi-Select Column Header Checkbox
  onHeaderCheckBoxChange() {
    let selected = false;
    if (this.headerCheckBoxMode !== this.CHECKBOX_SELECTED) {
      this.headerCheckBoxMode = this.CHECKBOX_SELECTED;
      selected = true;
    } else {
      this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
      selected = false;
    }
    // if (this.flexGrid.rows) {
    //   for (let i = 0, ttl = this.flexGrid.rows.length; i < ttl; i++) {
    //     this.flexGrid.rows[i].dataItem[this.selectBinding] = selected;
    //     this.flexGrid.rows[i].cssClass = selected ? 'row-selected' : '';
    //     selected
    //       ? this.saveSelection(this.flexGrid.rows[i].dataItem)
    //       : this.removeSelection(this.flexGrid.rows[i].dataItem);
    //   }
    // }
    // this.updateActionDropdownItems();
    // this.setEditFields();
  }

  onCheckBoxChange() {
    this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
    let count = 0;

    // if (this.flexGrid.rows) {
    //   for (let i = 0, ttl = this.flexGrid.rows.length; i < ttl; i++) {
    //     if (this.flexGrid.rows[i].dataItem[this.selectBinding] === true) {
    //       count++;
    //       this.flexGrid.rows[i].cssClass = 'row-selected';
    //       this.saveSelection(this.flexGrid.rows[i].dataItem);
    //     } else {
    //       this.removeSelection(this.flexGrid.rows[i].dataItem);
    //       this.flexGrid.rows[i].cssClass = '';
    //     }
    //   }
    //   if (count === this.flexGrid.rows.length) {
    //     this.headerCheckBoxMode = this.CHECKBOX_SELECTED;
    //   } else if (count > 0) {
    //     this.headerCheckBoxMode = this.CHECKBOX_INDETERMINATE;
    //   }
    // }
    // this.setEditFields();
    // this.updateActionDropdownItems();
  }

  gridInitialized(flexGrid) {
    if (flexGrid.rows) {
      flexGrid.rows.defaultSize = 56;
      flexGrid.columnHeaders.rows.defaultSize = 40;
    }
    this.flexGrid = flexGrid;
  }

  // Pagination Size Changed Event Handler

  retrieveTKHistoryRecords() {}

  resetTKFilter() {}
}
