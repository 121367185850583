import {Component, OnInit, HostBinding} from '@angular/core';
import {Router} from '@angular/router';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {PeerCheckServiceService} from '../../peer-check/peer-check/peer-check-service.service';
import {Role} from 'src/app/shared/enums';
import {BulkeditService} from 'src/app/core/services/bulkedit/bulkedit.service';
import {GlobalHeaderServiceService} from '../../global-header/global-header-service.service';

@Component({
  selector: 'app-mappings',
  templateUrl: './mappings.component.html',
})
export class MappingsComponent implements OnInit {
  showMappingsMenu: any;
  showInquiryMenu: any = false;
  selectedFirmId: any;
  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }

  constructor(
    private router: Router,
    private service: BaseService,
    private _peerCheckService: PeerCheckServiceService,
    private authService: AuthService,
    private _bulkEditService: BulkeditService,
    private globalHeaderService: GlobalHeaderServiceService
  ) {
    router.events.subscribe((val: any) => {
      if (val && val.url) {
        const urlarr = val.url.split('/');
        if (urlarr && urlarr.length > 0) {
          const urlFirmId = urlarr[urlarr.length - 1];
          if (!isNaN(urlFirmId)) {
            this.selectedFirmId = urlFirmId;
          }
        }
        if (val.url.indexOf('gl-account-codes') == -1) {
          this._bulkEditService.setPreserveFilter(null);
        }
      }
    });
  }

  private get getDefaultFirm(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (backendTokenClaims.userDDO) {
      return backendTokenClaims.userDDO.firmID;
    }
  }

  getInquiryFeatureFlag(): any {
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/INQUIRY_MENU', '')
      .toPromise()
      .then((result) => {
        return result['INQUIRY_MENU'];
      });
  }

  async ngOnInit() {
    this.showInquiryMenu = await this.getInquiryFeatureFlag();
    if (this.router.url.includes('inquiry') && !this.showInquiryMenu) {
      this.router.navigate(['']);
    }
    if (this.isTAdmin && !this._peerCheckService.isValidMappingFirm(this.selectedFirmId)) {
      this.router.navigate(['']);
    }
    this.showMappingsMenu = await this.getMappingsFeatureFlag();

    const showMappingMenuForFirm = this.selectedFirmId > 900 && this.selectedFirmId < 1999;
    if (this.isTAdmin && (!this.showMappingsMenu || !showMappingMenuForFirm)) {
      this.globalHeaderService.setFirmSubject({id: this.getDefaultFirm});
      this.router.navigate(['']);
    }
  }

  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }

  getMappingsFeatureFlag(): any {
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/MAPPINGS_MENU', '')
      .toPromise()
      .then((result) => {
        return result['MAPPINGS_MENU'];
      });
  }
}
