<bento-alert [items]="alerts"></bento-alert>
<div class="content-left">
  <img src="assets/images/photo_04.png" width="240" height="180" alt="" />
</div>
<div class="content-right body01">
  <ul>
    <li>Step one - download the input form and save to excel</li>
    <li>Step two - upload input sheet when completed</li>
    <li>
      <a [routerLink]="['.']" (click)="downloadStaticFile(staffingRatioGuideFileName)">
        Staffing Ratio Guidelines and Role Definitions
      </a>
    </li>
    <li>
      <a [routerLink]="['.']" (click)="downloadStaticFile(titleMappingFileName)">
        Title Mapping Quick Reference Guide
      </a>
    </li>
  </ul>
  <div>
    <div
      class="pad-20 pad-btm-0"
      *ngIf="selectedFirmSRSubscriptionInfo && selectedFirmSRSubscriptionInfo.dateTimeReportImported"
    >
      You previously uploaded an input sheet on {{ selectedFirmSRSubscriptionInfo.dateTimeReportImported }}.
    </div>
    <div class="pad-20">
      <button type="button" class="btn btn-primary btn-sr-input" (click)="downloadStaticFile(inputSheetFileName)">
        <span class="btn-text">Download Input Worksheet</span>
      </button>
    </div>

    <div class="pad-left-20">
      <button class="btn btn-primary btn-sr-input" (click)="fileInput.click()" [disabled]="selectedFirmSRSubscriptionInfo.firmDataValid">
        <input
          id="srfileuplod"
          name="srfileuplod"
          #fileInput
          type="file"
          (change)="onFileSelect($event)"
          accept=".xlsx,.xls"
        />
        <span class="btn-text">Upload Input Worksheet</span>
      </button>
    </div>
  </div>
</div>
