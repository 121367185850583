import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-action-dropdown',
  templateUrl: './action-dropdown.component.html',
})
export class ActionDropdownComponent implements OnInit {
  @Input() actionDropdownItems;
  @Input() disable;
  @Output() dropDownSelectChange = new EventEmitter<string>();

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {}
  dropDownOnSelectChange(name) {
    this.dropDownSelectChange.emit(name);
  }
}
