import {BehaviorSubject} from 'rxjs';
export class GlExtractFilterModel {
  selectedPmAccountText: string;

  pmAccountStream: BehaviorSubject<string[]>;

  isPeriodVisible = true;
  isFirmActVisible = true;
  isOverRideVisible = true;
  isReallocVisible = true;

  isOfficeVisible = true;
  isDeptVisible = true;

  isPmAccountVisible = true;

  pmAccount: any[];
}
