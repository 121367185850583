import {Component, OnInit, HostBinding} from '@angular/core';

@Component({
  selector: 'app-administrator',
  templateUrl: './administrator.component.html',
})
export class AdministratorComponent implements OnInit {
  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }

  constructor() {}

  ngOnInit(): void {}
}
