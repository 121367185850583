import {Component} from '@angular/core';

@Component({
  selector: 'app-pmm-faq',
  templateUrl: './pmm-faq.component.html',
  styleUrls: ['./pmm-faq.component.scss'],
})
export class PmmFaqComponent {
  emailAddress = 'financialinsights.admin@thomsonreuters.com';

  faqItems = [
    {
      question: 'Whom should I contact if I have a Financial Insights related question?',
      answer: `If you are familiar with your Financial Insights Benchmarking Consultant, please contact them directly. Otherwise, please <a href="mailto:${this.emailAddress}">email our team</a> and we will respond as quickly as possible.`,
      data: {isVisible: true},
    },
    {
      question:
        "Do other subscribers actually see my firm's results and do I get to see the results of individual firms?",
      answer: `No, protecting the confidentiality of individual firm data is paramount. No subscriber can view specific firm results except for their own, and all peer group data is always aggregated and presented as a median or mean. To get a better sense of data dispersion and where your firm stands in relation to its peers, you can also see the quartile your firm falls in and the standard deviation.`,
      data: {isVisible: true},
    },
    {
      question: 'Can I really pick my peer firms?',
      answer: `Yes, each firm has the ability to select from the list of participating firms which ones it would like to include in its peer group(s). For antitrust reasons, peer groups must contain at least five firms, one of which can be your own firm.`,
      data: {isVisible: true},
    },
    {
      question: 'How many peer groups can my firm create and how do I create a new one or edit an existing peer group?',
      answer: `The number of peer groups available to your firm is stipulated in your contract. Additional peer groups can be purchased. If you are interested in discussing your peer groups, cost for additional groups, or learning how to create or edit a peer group, please contact your firm's Benchmarking Consultant, or <a href="mailto:${this.emailAddress}">email our team</a>.`,
      data: {isVisible: true},
    },
    {
      question: 'As a subscribing firm, how often must my firm provide data?',
      answer: `Financial Insights relies upon subscriber firms to provide data each month and in return, Financial Insights is able to offer benefits from the data submitted by all other subscribing firms.`,
      data: {isVisible: true},
    },
    {
      question: 'I have additional Financial Insights related questions, whom should I contact?',
      answer: `Please <a href="mailto:${this.emailAddress}">email our team</a> and we will respond as quickly as possible.`,
      data: {isVisible: true},
    },
  ];
}
