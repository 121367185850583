<main id="MainContent" class="Main container-fluid">
  <header class="Main-header">
    <h2 class="Main-heading">Frequently Asked Questions</h2>
  </header>
  <div class="Main-body">
    <section class="FAQ-section Section--narrow">
      <article *ngFor="let faq of faqItems" class="bento-list mb-3">
        <h3 class="">Q: {{ faq.question }}</h3>
        <p>
          <span class="h3">A: </span><span [innerHTML]="faq.answer"> {{ faq.answer }}</span>
        </p>
      </article>
    </section>
  </div>
</main>
