import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionDecryptionService {

  constructor() { }
  //To encrypt input data
  public encrypt(cypherText: string): string {
    var hash = CryptoJS.SHA1("Azure3e#EnKy");
    var key = CryptoJS.lib.WordArray.create(hash.words.slice(0, 16 / 4));
    const encrypted = CryptoJS.AES.encrypt(cypherText, key, {
      //keySize: 16,									// not necessary
      //iv: iv,												// not necessary for ECB
      mode: CryptoJS.mode.ECB,
      //padding: CryptoJS.pad.Pkcs7		// default
    }); 
    return encrypted.toString();
  }

  //To decrypt input data
  public decrypt(cypherText: string) {
    var hash = CryptoJS.SHA1("Azure3e#EnKy");
    var key = CryptoJS.lib.WordArray.create(hash.words.slice(0, 16 / 4));
      return CryptoJS.AES.decrypt(cypherText, key,{
        //keySize: 16,									// not necessary
        //iv: iv,												// not necessary for ECB
        mode: CryptoJS.mode.ECB,
        //padding: CryptoJS.pad.Pkcs7		// default
      }).toString(CryptoJS.enc.Utf8);
  }
}
