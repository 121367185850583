import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {PracticeGroupComponentOptions, UDF_FIELD_NAME_VALUES} from 'src/app/shared/constants';
import {BaseService} from 'src/app/core/services/base/base.service';
import {ActivatedRoute} from '@angular/router';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import PracticeFirmSettings from '../../../models/practicesettings.model';
import {environment} from 'src/environments/environment';
import {LocalStorageService} from 'src/app/core/services/local-storage/local-storage.service';
import FirmDetailModel from 'src/app/core/models/firmdetail.model';
import {store, eventDispatcher} from 'src/app/core/store';
import {ActionTypes} from 'src/app/core/store/actions';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import FirmSettingModel from 'src/app/core/models/firmsetting.model';

@Component({
  selector: 'app-firm-practice-setting',
  templateUrl: './firm-practice-setting.component.html',
  styleUrls: ['./firm-practice-setting.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FirmPracticeSettingComponent implements OnInit {
  @Input() isNewFirm;
  @Input() firmId;
  practiceGroupTypes = [];
  alerts: BentoAlertItemOptions[] = [];
  practiceSettingform: any;
  practiceGroupComponents = PracticeGroupComponentOptions;
  successMsg: string;
  errorMessage: any;
  firmType: string;
  firmPracticeSetting = new PracticeFirmSettings();
  resetfirmPracticeSettings = new PracticeFirmSettings();
  mainOfficeCountry: string;
  udfFieldNames = UDF_FIELD_NAME_VALUES;
  firmDetailModel: FirmDetailModel = new FirmDetailModel();
  isGetPracticeSettings = false;
  ispracticeUpdated = false;
  firmParameterModel: any;

  constructor(
    private route: ActivatedRoute,
    private service: BaseService,
    private localStorageService: LocalStorageService,
    private authService: AuthService
  ) {
    this.isGetPracticeSettings = false;
    store.subscribe((state) => {
      const {firmParameter} = state;
      if (firmParameter != null) {
        this.firmPracticeSetting = Object.assign({}, firmParameter.practiceSettings);
        this.firmParameterModel = Object.assign({}, firmParameter);
      }
    });
    this.isGetPracticeSettings = true;
  }

  ngOnInit(): void {
    if (this.isNewFirm) this.firmPracticeSetting = new PracticeFirmSettings();
    else eventDispatcher.next({type: ActionTypes.GetFirmParameterSetting});

    this.firmPracticeSetting.defaultPracticeGroup = parseInt(this.firmPracticeSetting.defaultPracticeGroup);
    this.resetfirmPracticeSettings = JSON.parse(JSON.stringify(this.firmPracticeSetting));
    this.firmType = this.localStorageService.get('FIRM_TYPE');
    this.getPracticeGroupType();
  }
  getPracticeGroupType() {
    this.ispracticeUpdated = true;
    this.mainOfficeCountry = this.localStorageService.get('mainOfficeCountry');
    this.service
      .get(environment.FIClientBaseEndpoint + 'v1/firm/firmparameters/' + this.mainOfficeCountry + '/practices', '')
      .subscribe(
        (result) => {
          this.practiceGroupTypes = result;
          this.ispracticeUpdated = false;
        },
        (error) => {
          this.errorMessage = error.error;
          this.ispracticeUpdated = false;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  savePractice() {
    this.ispracticeUpdated = true;
    if (!this.firmPracticeSetting.practiceGroupUserDefined) {
      this.firmPracticeSetting.fieldName = '';
      this.firmPracticeSetting.lookupTable = '';
      this.firmPracticeSetting.codeField = '';
      this.firmPracticeSetting.descField = '';
    }
    this.service
      .post(
        environment.FIClientBaseEndpoint + 'v1/firm/firmparameters/practice/' + this.firmId,
        this.firmPracticeSetting
      )
      .subscribe(
        (response) => {
          this.resetfirmPracticeSettings = JSON.parse(JSON.stringify(this.firmPracticeSetting));
          this.firmParameterModel.practiceSettings = this.firmPracticeSetting;
          eventDispatcher.next({type: ActionTypes.SetFirmParameterSetting, payload: this.firmParameterModel});
          this.ispracticeUpdated = false;
          this.successMsg = 'Data modified successfully.';
          this.alerts.push({
            type: 'success',
            msg: this.successMsg,
            timeout: 2500,
            closeable: true,
          });
        },
        (error) => {
          this.errorMessage = error.error;
          this.ispracticeUpdated = false;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  resetFirmPracticeSetting() {
    this.ispracticeUpdated = true;
    if (typeof this.resetfirmPracticeSettings !== 'undefined') {
      this.firmPracticeSetting = JSON.parse(JSON.stringify(this.resetfirmPracticeSettings));
    }
    this.successMsg = 'Data reset successfully.';
    this.alerts.push({
      type: 'success',
      msg: this.successMsg,
      timeout: 2500,
      closeable: true,
    });
    this.ispracticeUpdated = false;
  }
}
