<div class="GlobalLayout-group"  [bentoBusyLoader]="isDataLoading">
  <div class="GlobalLayout-item GlobalLayout-item--small">
    <bento-side-nav
      class="NavSide"
      aria-label="Firm administrator peer groups"
      [items]="items"
      [collapsible]="true"
      [itemTemplate]="itemTemplateTS"
    >
      <ng-template #itemTemplate let-item="item" class="label">
        <span class="NavSide-text">
          {{ item.label }}
        </span>
        <span *ngIf="item.label == 'Pending'" class="badge badge-danger badge--bold badge--inline">{{
          numberOfPendingPeerGroup
        }}</span>
      </ng-template>
    </bento-side-nav>
  </div>
  <div class="GlobalLayout-item GlobalLayout-item--large">
    <section class="Section">
      <header class="Section-header">
        <h2 class="Section-heading" id="peergroupHeader">{{ currentStatus }} peer groups</h2>
      </header>
      <div class="Section-body">
        <div
          class="ZeroState"
          *ngIf="
            (!numberOfPendingPeerGroup && currentStatus == 'Pending') || !(firmsPeerGroups && firmsPeerGroups.length)
          "
        >
          <p>There are no {{ currentStatus | lowercase }} peer groups.</p>
        </div>
        <wj-flex-grid
          #flexGrid
          class="DataGrid"
          [itemsSource]="firmsPeerGroups"
          [autoClipboard]="false"
          [isReadOnly]="true"
          [allowDragging]="false"
          [autoRowHeights]="true"
          (initialized)="gridInitialized(flexGrid, 'peergroupHeader')"
          *ngIf="
            (numberOfPendingPeerGroup && currentStatus == 'Pending') || (firmsPeerGroups && firmsPeerGroups.length)
          "
        >
          <wj-flex-grid-column
            [header]="'Name'"
            [binding]="'peerlabel'"
            [align]="'left'"
            [width]="'*'"
            [wordWrap]="true"
          >
            <ng-template
              wjFlexGridCellTemplate
              [cellType]="'Cell'"
              let-item="item"
              *ngIf="
                currentStatus == 'Pending' || currentStatus == 'Approved' || (currentStatus == 'Global' && !isFAdmin)
              "
            >
              <a [routerLink]="['.']" (click)="updateServiceWith(item)">{{ item.peerlabel }}</a>
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'ID'"
            [binding]="'peerid'"
            [allowResizing]="false"
            [align]="'left'"
            [width]="'*'"
            [maxWidth]="300"
            [wordWrap]="true"
          ></wj-flex-grid-column>
        </wj-flex-grid>
      </div>
    </section>
  </div>
</div>
