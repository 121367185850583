<dl class="bento-desc-list bento-desc-list--inline bento-desc-list--bullets FirmDetails">
  <div class="bento-desc-list-item">
    <dt>Firm name:</dt>
    <dd>{{ firm_Name }}</dd>
  </div>
  <div class="bento-desc-list-item">
    <dt>Firm ID:</dt>
    <dd>{{ firm_Id }}</dd>
  </div>
</dl>
