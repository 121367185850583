import {Component, OnInit, HostBinding} from '@angular/core';
import {BentoSideNavItem} from '@bento/bento-ng';
import {GlobalPeerGroupList} from 'src/app/core/models/globalPeerGroupList.model';
import {ActivatedRoute} from '@angular/router';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {environment} from 'src/environments/environment';
import {BaseService} from 'src/app/core/services/base/base.service';
declare let TRAAC: any;

@Component({
  selector: 'app-current-views',
  templateUrl: './current-views.component.html',
})
export class CurrentViewsComponent implements OnInit {
  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }

  items: BentoSideNavItem[];
  isCurrentViewSelected = false;
  selectedViewData: any;
  selectedLabel: string;
  onSelect(item: BentoSideNavItem) {
    this.selectedLabel = item.label;
  }

  constructor(private authService: AuthService, private route: ActivatedRoute, private service: BaseService) {}

  ngOnInit(): void {
    this.registerQuickSightEvent();
    this.setItems();
    this.selectedLabel = this.items[0].label;
  }

  // getfirmId() should return the selected firmID OR firmID of logged in user.
  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : parseInt(this.route.snapshot.paramMap.get('id'));
  }

  showHideCurrentViewTabSideNav(label: string) {
    this.items.forEach((element: BentoSideNavItem) => {
      if (element.label === label) {
        element.data.isVisible = true;
      } else {
        element.data.isVisible = false;
      }
    });
  }

  editCurrentView(item: any) {
    this.isCurrentViewSelected = true;
    this.selectedViewData = item;
  }

  backToCurrentView() {
    this.isCurrentViewSelected = false;
    this.setItems();
  }

  setItems() {
    this.items = [
      {
        label: 'My views',
        selected: true,
        action: () => {
          this.showHideCurrentViewTabSideNav('My views');
        },
        data: {isVisible: true},
      },
      {
        label: 'Shared views',
        action: () => {
          this.showHideCurrentViewTabSideNav('Shared views');
        },
        data: {isVisible: false},
      },
    ];
  }
  registerQuickSightEvent() {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
    /**Remove API feature  call */
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/feature/QUICKSIGHT', '').subscribe((result) => {
      if (result['QUICKSIGHT']) {
        TRAAC.track(TRAAC.keyValue('Current views click', {email: this.authService.getUserEmail()}).build());
      }
    });
  }
}
