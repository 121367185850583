<div class="GlobalLayout" *ngIf="!isGlobalPeerGroupsFirmSelected" [bentoBusyLoader]="isDataLoading">
  <main id="MainContent" role="main" tabindex="-1" class="Main Main--narrow">
    <header class="Main-header">
      <h1 class="Main-heading">Global peer groups</h1>
      <div class="Actions justify-content-end">
        <div class="col pl-0">
          <span class="gl-total-peergroups" role="status">Showing {{currentRecords}} of {{totalRecords}}</span>
        </div>
        <div class="Actions-item Actions-item--large">
          <span class="bento-search">
            <label for="globalPeerGroupsSearch" class="sr-only">Search global peer groups</label>
            <input
              type="text"
              class="form-control"
              name="globalPeerGroupsSearch"
              id="globalPeerGroupsSearch"
              [(ngModel)]="searchText"
            />
            <button type="button" class="btn btn-primary" (click)="searchGlobalPeerGroups()">
              <span class="btn-text">Search</span>
            </button>
          </span>
        </div>
      </div>
    </header>
    <div class="Main-body">
      <wj-flex-grid
        #flexGrid
        class="DataGrid"
        [itemsSource]="globalPeerGroupsGridData"
        [autoClipboard]="false"
        [isReadOnly]="true"
        [headersVisibility]="'Column'"
        [allowDragging]="false"
        [autoRowHeights]="true"
        (initialized)="gridInitialized(flexGrid)"
      >
        <wj-flex-grid-column
          [header]="'Global peer group name'"
          [binding]="'peerLabel'"
          [align]="'left'"
          [width]="'*'"
          [minWidth]="240"
          [wordWrap]="true"
        >
          <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
            <a [routerLink]="['.']" (click)="editGlobalPeerGroups(item)">{{ item.peerLabel }}</a>
          </ng-template>
        </wj-flex-grid-column>
      </wj-flex-grid>
    </div>
  </main>
</div>
<app-global-peer-groups-firms
  *ngIf="isGlobalPeerGroupsFirmSelected"
  [selectedGlobalPeerGroup]="selectedGlobalPeerGroup"
  (backToGlobalPeerGroupsAction)="backToGlobalPeerGroups()"
  class="u-flexGrowCol"
></app-global-peer-groups-firms>
