<bento-alert [items]="alerts" (close)="closeAlert($event)"></bento-alert>
<div class="GlobalLayout GlobalLayout--twoColumn firm-parameter" *ngIf="peerGroupStatus.isDefaultView">
  <main id="MainContent"  role="main" tabindex="-1" class="Main">
    <header class="Main-header">
      <ng-container *ngIf="isTAdmin">
        <app-global-breadcrumbs
          [firmParameterrouterLink]="'/administrator/firms'"
          [isFirmPeerGroup]="true"
          [firmName]="'Firms'"
          [peerLabel]="isNewFirm == true ? 'Add firm' : firmDetailModel.name"
        ></app-global-breadcrumbs>
      </ng-container>
      <div class="row align-items-center">
        <div class="col">
          <h1 class="Main-heading">{{ isNewFirm == true ? 'Add firm' : firmDetailModel.name }}</h1>
        </div>
        <div class="col">
          <div class="Actions justify-content-end" *ngIf="!isNewFirm && activeTab === 'Peer groups'">
            <div class="Actions-item">
              <button type="button" class="btn btn-outline-secondary" tabindex="0" (click)="handleAddPeerGroupAction()">
                <span class="btn-text">Add peer group</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="Main-body Content">
      <div class="bento-tabset tabs-top" *ngIf="!isNewFirm; else elseBlock">
        <span id="FirmGeneralSettings" class="hidden">Firm General Settings</span>
        <ul
          ngbNav
          #nav="ngbNav"
          [activeId]="activeTab"
          (navChange)="onTabChange($event.nextId)"
          class="nav nav-tabs"
          ngPreserveWhitespaces
          aria-labelledby="FirmGeneralSettings"
          role="tablist"
          (keydown)="onKeyDown($event)"
        >
          <li [ngbNavItem]="title" id="{{ title }}" *ngFor="let title of tabData" role="presentation" title="{{ title }}">
            <a ngbNavLink tabindex="0">
              <bento-tabs-label [label]="title"></bento-tabs-label>
            </a>
            <ng-template ngbNavContent>
              <div *ngIf="activeTab === 'Settings'">
                <app-firm-details [firmId]="firmId" class="u-flexGrowCol" [isFAdmin]="isFAdmin"  [isTAdmin]="isTAdmin" [label]="title"> </app-firm-details>
              </div>
              <div *ngIf="activeTab === 'Users'" >
                <app-firm-users
                  [firmId]="firmId"
                  class="u-flexGrowCol"
                  *ngIf="!isFirmsUserSelected"
                  (handleUsersAction)="usersActionHandler($event)"
                ></app-firm-users>
                <app-firm-user-details
                  [firmId]="firmId"
                  class="u-flexGrowCol"
                  *ngIf="isFirmsUserSelected"
                  [selectedOption]="selectedOption"
                  (handleCustomUserAction)="usersActionHandler($event)"
                ></app-firm-user-details>
              </div>
              <div *ngIf="activeTab === 'Peer groups'"  >
                <app-firm-peer-groups class="u-flexGrowCol"></app-firm-peer-groups>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="tab-content"></div>
      </div>
      <ng-template #elseBlock>
        <app-firm-details class="u-flexGrowCol" [firmId]="firmId" [isNewFirm]="true"></app-firm-details>
      </ng-template>
    </div>
  </main>
</div>
<app-firm-peer-group-detail
  *ngIf="!peerGroupStatus.isDefaultView && peerGroupStatus.customPeerGroupMode == null"
  [firmDetails]="firmDetailModel"
  [currentStatus]="peerGroupStatus.currentStatus"
>
</app-firm-peer-group-detail>
<app-firm-custom-peer-groups
  *ngIf="
    !peerGroupStatus.isDefaultView &&
    peerGroupStatus.customPeerGroupMode != null &&
    (peerGroupStatus.currentStatus == 'Pending' ||
      peerGroupStatus.currentStatus == 'Approved' ||
      peerGroupStatus.customPeerGroupMode == 'add')
  "
  [firmDetails]="firmDetailModel"
></app-firm-custom-peer-groups>
