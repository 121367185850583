import { Component, Input, ViewChild } from '@angular/core';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcCore from '@grapecity/wijmo';
import { PeerGroupFirms } from 'src/app/core/models/firmpeergroupstatus.model';
import {StaffingPeergroupService} from 'src/app/core/services/staffing-peergroup/staffing-peergroup.service';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import { StaffingRatioPeerGroupList } from 'src/app/core/models/staffingRatioPeerGroupList.model';
import {environment} from 'src/environments/environment';
import { BaseService } from 'src/app/core/services/base/base.service';
import { BackendTokenClaims } from 'src/app/core/models/tokenResponse';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';

@Component({
  selector: 'app-staffing-ratio-peergroup-details',
  templateUrl: './staffing-ratio-peergroup-details.component.html',
  styleUrls: ['./staffing-ratio-peergroup-details.component.scss']
})
export class StaffingRatioPeergroupDetailsComponent {

  @Input() selectedPeerGroup: StaffingRatioPeerGroupList ;
  @Input() currentStatus: string;
  peerGroupName ='';
  @ViewChild('filter', {static: true}) gridFilter: wjcGridFilter.FlexGridFilter;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  totalRecords: number =0;
  searchText : string = '';
  isDataLoading =false;
  alerts: BentoAlertItemOptions[] = [];
  allFirms : any[] =[];
  peerGroupFirmList : any[] = [];
  peerGroupFirmUnsortedList : any[] = [];
  peerGroupFirmListGridData : any[] = [];
  currentSurveyYearOfFirm : number;
  data: wjcCore.CollectionView;
  enableClearFilter = false;
  currentRecords: number;

  constructor(private staffingPeergroupService: StaffingPeergroupService,
    private service: BaseService,
    private authService : AuthService){}
  async ngOnInit(): Promise<void> {
    this.isDataLoading=true
    await this.getCurrentSurveyYear();
    await this.getAllFirms();
    await this.getPeerGroupDetails();
    if(this.peerGroupFirmUnsortedList.length){
      this.totalRecords = this.peerGroupFirmUnsortedList.length;
      this.peerGroupFirmList = this.peerGroupFirmUnsortedList.sort((a, b) => (a.firmName > b.firmName ? 1 : -1));  
    }
        
    this.peerGroupFirmListGridData = this.peerGroupFirmList; 
    this.staffingPeergroupService.setSelectedPeerGroupFirms = this.peerGroupFirmList;
    this.data = new wjcCore.CollectionView(this.peerGroupFirmList);

    this.isDataLoading=false;

  }
  getAllFirms(){
    return this.service
  .get(environment.FIClientBaseEndpoint + 'v1/staffingratio/subscriptions/'+this.firmId+'?surveyYear=', this.currentSurveyYearOfFirm)
  .toPromise()
  .then(
    (result) => {    
      this.allFirms = result
    },
    (error) => {
      this.isDataLoading=false;
      this.alerts.push({
        type: 'warning',
        msg:error.error.message,
        closeable: true,
      });
    }
  );
  }
  getPeerGroupDetails(){
    return this.service
  .get(environment.FIClientBaseEndpoint + 'v1/staffingratio/peergroup/global/'+this.selectedPeerGroup.peerGroupId+'/'+this.currentSurveyYearOfFirm, '')
  .toPromise()
  .then(
    (result) => {
      this.peerGroupFirmUnsortedList = [];
      result.forEach((o) => {
        const firmDetails = this.allFirms.filter(detail => detail.firmId == o.peerFirmId)[0];
        if(firmDetails)
          this.peerGroupFirmUnsortedList.push(firmDetails);

      });  
      
        
    },
    (error) => {
      this.isDataLoading=false;
      this.alerts.push({
        type: 'warning',
        msg:error.error.message,
        closeable: true,
      });
    }
  );
  }

  gridInitialized(flexGrid: wjcGrid.FlexGrid, gridHeaderId: string = '') {
    flexGrid.rows.defaultSize = 56;
    flexGrid.columnHeaders.rows.defaultSize = 40;
    flexGrid.autoGenerateColumns = false;
    this.flexGrid = flexGrid;
    if (gridHeaderId && flexGrid) flexGrid.cells.hostElement.setAttribute('aria-labelledby', gridHeaderId);
    this.currentRecords = flexGrid.rows.length;
  }

  searchGlobalPeerGroups() {
    this.peerGroupFirmListGridData = this.peerGroupFirmList.filter(
      (element) => element.firmName.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
    );
    this.data = new wjcCore.CollectionView(this.peerGroupFirmListGridData)
}
handleEditButtonAction() {
  this.staffingPeergroupService.setCustomPeerGroupMode = 'edit';
  this.staffingPeergroupService.peerGroupStatusHandler();
}
updateServiceHandler() {
  this.staffingPeergroupService.setSelectedTab = 'Peer groups';
  this.staffingPeergroupService.setCustomPeerGroupMode = null;
  this.staffingPeergroupService.setselectedPeerGroup = null;
  this.staffingPeergroupService.setSelectedPeerGroupFirms = [];
  this.staffingPeergroupService.setIsDefaultView = true;
  this.staffingPeergroupService.peerGroupStatusHandler();
}
private get firmId(): number {
  const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
  if (backendTokenClaims && backendTokenClaims.userDDO) {
    return backendTokenClaims.userDDO.firmID;
  }
}

getCurrentSurveyYear() {
  return this.service
    .get(environment.FIClientBaseEndpoint + 'v1/staffingratio/surveyyear/'+this.firmId, '')
    .toPromise()
    .then(
      (result) => {
        this.currentSurveyYearOfFirm = result;      
      },
      (error) => {
        this.alerts.push({
          type: 'warning',
          msg:error.error.message,
          closeable: true,
        });
      }
    );
}

initFilter() {
  this.gridFilter.showSortButtons = false;
}
onFilterChange() {
  this.enableClearFilter = true;
}
onFilterApply(){
  this.currentRecords = this.flexGrid.rows.length;
}

}
