import {Component, Input, OnInit} from '@angular/core';
import {store, eventDispatcher} from 'src/app/core/store';
import {ActionTypes} from 'src/app/core/store/actions';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {BaseService} from 'src/app/core/services/base/base.service';
import GeneralFirmSettingModel from 'src/app/core/models/generalSetting.model';
import {LocalStorageService} from 'src/app/core/services/local-storage/local-storage.service';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from '../../../services/auth/auth.service';
import {Role} from 'src/app/shared/enums';

@Component({
  selector: 'app-general-setting',
  templateUrl: './general-setting.component.html',
})
export class GeneralSettingComponent implements OnInit {
  @Input() isNewFirm;
  @Input() firmId;
  alerts: BentoAlertItemOptions[] = [];
  generelSettings = new GeneralFirmSettingModel();
  resetGeneralFirmSettingModel = new GeneralFirmSettingModel();
  isGFSUpdated = false;
  isGetGeneralFirmSettings = false;
  successMsg: string;
  errorMessage: any;
  firmType: string;
  firmParameterModel: any;

  constructor(
    private route: ActivatedRoute,
    private service: BaseService,
    private localStorageService: LocalStorageService,
    private authService: AuthService
  ) {
    this.isGetGeneralFirmSettings = false;
    store.subscribe((state) => {
      const {firmParameter} = state;
      if (firmParameter != null) {
        this.generelSettings = Object.assign({}, firmParameter.generalFirmSettings);
        this.firmParameterModel = Object.assign({}, firmParameter);
      }
    });
    this.isGetGeneralFirmSettings = true;
  }

  ngOnInit(): void {
    if (this.isNewFirm) {
      this.generelSettings = new GeneralFirmSettingModel();
    } else eventDispatcher.next({type: ActionTypes.GetFirmParameterSetting});
    this.resetGeneralFirmSettingModel = JSON.parse(JSON.stringify(this.generelSettings));
    this.firmType = this.localStorageService.get('FIRM_TYPE');
  }
  resetGFS() {
    this.isGFSUpdated = true;
    if (typeof this.resetGeneralFirmSettingModel !== 'undefined') {
      this.generelSettings = JSON.parse(JSON.stringify(this.resetGeneralFirmSettingModel));
    }
    this.successMsg = 'Data reset successfully.';
    this.alerts.push({
      type: 'success',
      msg: this.successMsg,
      timeout: 2500,
      closeable: true,
    });
    this.isGFSUpdated = false;
  }
  saveGFS() {
    this.isGFSUpdated = true;
    this.alerts = [];
    // eslint-disable-next-line max-len
    this.service
      .post(environment.FIClientBaseEndpoint + 'v1/firm/firmparameters/settings/' + this.firmId, this.generelSettings)
      .subscribe(
        (result) => {
          this.resetGeneralFirmSettingModel = JSON.parse(JSON.stringify(this.generelSettings));
          this.firmParameterModel.generalFirmSettings = this.generelSettings;
          eventDispatcher.next({type: ActionTypes.SetFirmParameterSetting, payload: this.firmParameterModel});
          this.successMsg = 'Data modified successfully.';
          this.isGFSUpdated = false;
          this.alerts.push({
            type: 'success',
            msg: this.successMsg,
            timeout: 2500,
            closeable: true,
          });
        },
        (error) => {
          this.isGFSUpdated = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }
  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }
}
