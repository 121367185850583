import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BentoModalConfirmationCloseReason, BentoModalConfirmationService} from '@bento/bento-ng';
import {BentoAlertItemOptions} from '@bento/bento-ng/lib/alert/alert.component';
import {BfmFieldEvent} from '@bento/bfm-ng';
import {FiscalPeriod} from 'src/app/core/models/fiscal-period.model';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {PeerCheckServiceService} from '../../../peer-check/peer-check/peer-check-service.service';

@Component({
  selector: 'app-fiscal-period',
  templateUrl: './fiscal-period.component.html',
})
export class FiscalPeriodComponent implements OnInit {
  @Input() isButtonVisible: Boolean;
  @Input() requestFrom: String;
  @Input() availableOverrides: any;
  @Input() isOverride: Boolean;
  @Input() readyToSubmit: Boolean;
  @Input() selectedOverride: any;
  @Input() fiscalPeriodModel: FiscalPeriod = new FiscalPeriod();
  @Input() fiscalDateRange: any = [];
  @Input() selectedGlAcOverride: any;
  @Output() selectionChanged = new EventEmitter();
  @Output() overridePeriodChanged = new EventEmitter();
  @Output() saveDetails = new EventEmitter();
  @Output() fiscalPeriodLoaded = new EventEmitter();
  @Output() bentoAlertOccured = new EventEmitter();
  @Output() verifyDateRangeInvalidPattern = new EventEmitter();
  errorMessage: '';
  alerts: BentoAlertItemOptions[] = [];
  isMainBusyLoaderBusy = false;
  defaultDate: Number;
  isFiscalAvail: boolean;
  prevSelectedOverride: any = null;
  fiscalFromTitle: string;
  fiscalToTitle: string;
  firmId: any;
  defaultSelectedGlAcOverride: any;
  validatePattern = '^([0-9]{4})(0[1-9]|10|11|12)$';
  validations: any;

  constructor(
    private authService: AuthService,
    private service: BaseService,
    private route: ActivatedRoute,
    private modalService: BentoModalConfirmationService,
    private _peerCheckService: PeerCheckServiceService
  ) {
    this.validations = {
      validatePattern: {
        pattern: 'Error:  Enter valid date format',
      },
    };

    this.firmId = this.getfirmId;
    this.route.params.subscribe((val) => {
      if (val && val.id) {
        this.firmId = val.id;
      } else {
        if (this._peerCheckService.getSelectedFirmId && this._peerCheckService.getSelectedFirmId != this.getfirmId) {
          this.firmId = this._peerCheckService.getSelectedFirmId;
        } else {
          this.firmId = this.getfirmId;
        }
      }
      if (this._peerCheckService.isValidMappingFirm(this.firmId)) {
        this.initialize();
      }
    });
  }

  ngOnInit(): void {
    /*if (this.requestFrom === 'productionExtract' || this.requestFrom == 'glExtract') {
      this.fiscalFromTitle = 'Period from';
      this.fiscalToTitle = 'Period to';
    }*/
    this._peerCheckService.getSelectedGlAcOverrideSubject().subscribe((data) => {
      this.defaultSelectedGlAcOverride = data;
    });
  }
  onDateChange() {
    let startDateMatch = (this.fiscalPeriodModel.from_date + '').match(this.validatePattern);
    let endDateMatch = (this.fiscalPeriodModel.to_date + '').match(this.validatePattern);
    if (!startDateMatch || !endDateMatch) {
      this.verifyDateRangeInvalidPattern.emit(false);
    } else {
      this.verifyDateRangeInvalidPattern.emit(true);
    }
  }

  isValidDate(evt: BfmFieldEvent, sourceElement: string, formData: any): void {
    if (!evt) {
      return;
    }
    if (
      (sourceElement == 'EndDate' && formData.form.controls.fispto.value) ||
      (sourceElement == 'StartDate' && formData.form.controls.fispfrm.value)
    ) {
      const fg = evt.formControl;
      const setValidity = evt.setValidity;
      let endDate = formData.form.controls.fispto.value;
      let startDate = formData.form.controls.fispfrm.value;

      let startDateMatch = (startDate + '').match(this.validatePattern);
      let endDateMatch = (endDate + '').match(this.validatePattern);

      if (sourceElement == 'StartDate' && startDateMatch.length > 0 && Number(startDateMatch[0]) < 200001) {
        setTimeout(() => {
          return setValidity({valid: false, message: 'From Date should be greater than 200001'});
        }, 0);
      } else if (sourceElement == 'EndDate' && endDateMatch.length > 0 && Number(endDateMatch[0]) > 209912) {
        setTimeout(() => {
          return setValidity({valid: false, message: 'To Date should be Less than 209912'});
        }, 0);
      } else if (
        startDateMatch.length > 0 &&
        endDateMatch.length > 0 &&
        Number(startDateMatch[0]) > Number(endDateMatch[0])
        // (Number(startDateMatch[2]) < Number(endDateMatch[2]) ||
        //   (Number(startDateMatch[2]) == Number(endDateMatch[2]) &&
        //     Number(startDateMatch[1]) <= Number(endDateMatch[1])))
      ) {
        setTimeout(() => {
          this.alerts.push({
            type: 'warning',
            msg: "Start period can't be larger than end period",
            closeable: true,
          });
          this.bentoAlertOccured.emit(this.alerts);
          this.alerts = [];
          //return setValidity({valid: false, message: null});
        }, 0);
      } else {
        this.verifyDateRangeInvalidPattern.emit(true);
        if (sourceElement == 'EndDate' && formData.form.controls.fispfrm.status == 'INVALID') {
          return formData.form.get('fispfrm').updateValueAndValidity();
        } else if (sourceElement == 'StartDate' && formData.form.controls.fispto.status == 'INVALID') {
          return formData.form.get('fispto').updateValueAndValidity();
        }
      }
    } else {
      return;
    }
  }

  initialize() {
    this.isMainBusyLoaderBusy = false;
    this.getFiscalDateRange(this.firmId);
    this.fiscalFromTitle = 'Fiscal period from';
    this.fiscalToTitle = 'Fiscal period to';
    /*if (this.requestFrom === 'Timekeeper') {
      this.fiscalFromTitle = 'Period from';
      this.fiscalToTitle = 'Period to';
    } else {
      this.fiscalFromTitle = 'Fiscal period from';
      this.fiscalToTitle = 'Fiscal period to';
    }*/
  }

  // getfirmId() should return the selected firmID OR firmID of logged in user.
  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : parseInt(this.route.snapshot.paramMap.get('id'));
  }

  // getfirmData() should fetch the FiscalDate range based on firmID.
  getFiscalDateRange(firmId) {
    if (this.prevSelectedOverride != null && this.isOverride) {
      this.fiscalPeriodModel = new FiscalPeriod();
      let selectedOverride = this.availableOverrides.find((obj) => {
        return obj.id == this.prevSelectedOverride;
      });
      this.fiscalPeriodModel.from_date =
        selectedOverride != undefined && selectedOverride.startPeriod != null ? selectedOverride.startPeriod : null;
      this.fiscalPeriodModel.to_date =
        selectedOverride != undefined && selectedOverride.endPeriod != null ? selectedOverride.endPeriod : null;
      this.generateDateRange(this.fiscalPeriodModel.from_date, this.fiscalPeriodModel.to_date);
      this.isFiscalAvail = true;
      this.fiscalPeriodLoaded.emit(JSON.stringify(this.fiscalPeriodModel));
      return;
    }
    this.isMainBusyLoaderBusy = true;
    this.service.get(environment.FIClientBaseEndpoint + 'v1/timekeeper/history/firm/' + firmId, '').subscribe(
      (result) => {
        this.isMainBusyLoaderBusy = false;
        if (Object.keys(result).length === 0) {
          this.alerts.push({
            type: 'warning',
            msg: 'Fiscal Period data not found',
            closeable: true,
          });
          this.bentoAlertOccured.emit(this.alerts);
          this.alerts = [];
        } else {
          this.fiscalPeriodModel = result;

          this.fiscalDateRange = [];
          this.generateDateRange(this.fiscalPeriodModel.from_date, this.fiscalPeriodModel.to_date);
          this.fiscalPeriodModel.from_date = this.fiscalPeriodModel.to_date;
          this.onChange();
          this.isFiscalAvail = true;
        }
        //this.selectionChanged.emit(JSON.stringify(this.fiscalPeriodModel));
        this.fiscalPeriodLoaded.emit(JSON.stringify(this.fiscalPeriodModel));
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
        this.bentoAlertOccured.emit(this.alerts);
        this.alerts = [];
      }
    );
  }

  onResetFiscal() {
    this.isMainBusyLoaderBusy = true;
    this.service.get(environment.FIClientBaseEndpoint + 'v1/timekeeper/history/firm/' + this.firmId, '').subscribe(
      (result) => {
        this.isMainBusyLoaderBusy = false;
        if (Object.keys(result).length === 0) {
          this.alerts.push({
            type: 'warning',
            msg: 'Fiscal Period data not found',
            closeable: true,
          });
          this.bentoAlertOccured.emit(this.alerts);
          this.alerts = [];
        } else {
          this.fiscalPeriodModel = result;
          this.fiscalDateRange = [];
          this.generateDateRange(this.fiscalPeriodModel.from_date, this.fiscalPeriodModel.to_date);
          this.fiscalPeriodModel.from_date = this.fiscalPeriodModel.to_date;
          this.isFiscalAvail = true;
        }
        this.onChange();
        this.fiscalPeriodLoaded.emit(JSON.stringify(this.fiscalPeriodModel));
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
        this.bentoAlertOccured.emit(this.alerts);
        this.alerts = [];
      }
    );
  }

  // generateDateRange Used to Generate Complete Date range option.
  generateDateRange(startDate, endDate) {
    let dtStart = Number(startDate);
    let dtEnd = Number(endDate);
    this.defaultDate = dtEnd;
    if (this.prevSelectedOverride == null) {
      this.fiscalPeriodModel.from_date = null;
      this.fiscalPeriodModel.to_date = null;
    }
    for (var date = dtStart; date <= dtEnd; date++) {
      let str = date.toString();
      let months = Number(str.substring(4, str.length));
      if (months >= 1 && months <= 12) {
        this.fiscalDateRange.push({value: date, name: str});
        if (str == startDate) {
          this.fiscalPeriodModel.from_date = date;
        } else if (str == endDate) {
          this.fiscalPeriodModel.to_date = date;
        }
      }
    }
  }

  // onChange will call when there is any change in dropdown.
  onChange() {
    if (!this.verifyChanges()) {
      this.alerts.splice(0, this.alerts.length);
    }
    if (!this.isButtonVisible) {
      this.selectionChanged.emit(JSON.stringify(this.fiscalPeriodModel));
    }
  }

  // selectRange will call onclick of Select button.
  selectRange() {
    if (!this.verifyChanges()) {
      this.alerts.splice(0, this.alerts.length);
      this.selectionChanged.emit(JSON.stringify(this.fiscalPeriodModel));
    }
  }

  // verifyChanges will verify and reset the changes made by user in dropdown values.
  // if the from date is greater than todate then it will reset the from date.
  verifyChanges() {
    if (this.requestFrom != 'GL Account Codes') {
      if (this.fiscalPeriodModel.from_date == null && this.fiscalPeriodModel.to_date != null) {
        if (this.alerts.length == 0) {
          this.alerts.push({
            type: 'warning',
            msg: 'Please enter a valid date format. YYYYMM',
            closeable: true,
          });
          this.bentoAlertOccured.emit(this.alerts);
          this.alerts = [];
        }
        return true;
      }
      if (
        Number(this.fiscalPeriodModel.from_date) > Number(this.fiscalPeriodModel.to_date) &&
        this.fiscalPeriodModel.to_date != null
      ) {
        if (this.alerts.length == 0) {
          this.alerts.push({
            type: 'warning',
            msg: "Start period can't be larger than end period",
            closeable: true,
          });
          this.bentoAlertOccured.emit(this.alerts);
          this.alerts = [];
        }
        return true;
      }
      return false;
    } else {
      if (this.fiscalPeriodModel.from_date != null && this.fiscalPeriodModel.to_date != null) {
      }
    }
  }

  closeAlert($event) {
    return true;
  }

  compareTwoArrayOfObjects(first_array_of_objects, second_array_of_objects) {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some((element_2) =>
          Object.keys(element_1).every((key) => {
            if (key == 'departmentObj') {
              return true;
            }
            return element_1[key] === element_2[key];
          })
        )
      )
    );
  }

  onOverridePeriodChange(overrideId) {
    const unsavedData = 'There are unsaved changes. Would you like to save them?';
    const confirmMessage = 'SAVE';
    const cancelMessage = 'DISCARD';
    if (
      this.selectedGlAcOverride.override.accountTotalAmount ==
        this.defaultSelectedGlAcOverride.override.accountTotalAmount &&
      this.compareTwoArrayOfObjects(
        this.selectedGlAcOverride.override.details,
        this.defaultSelectedGlAcOverride.override.details
      )
    ) {
      this.selectedOverride = overrideId;
      this.prevSelectedOverride = overrideId;
      this.overridePeriodChanged.emit(this.selectedOverride);
      this.getFiscalDateRange(this.firmId);
    } else {
      this.displayError(unsavedData, confirmMessage, cancelMessage, overrideId);
    }

    // if (!this.readyToSubmit && this.prevSelectedOverride != null) {
    //   this.selectedOverride = overrideId;
    //   this.prevSelectedOverride = overrideId;
    //   this.overridePeriodChanged.emit(this.selectedOverride);
    //   this.getFiscalDateRange(this.firmId);
    // } else {
    //   this.displayError(unsavedData, confirmMessage, cancelMessage, overrideId);
    // }
  }

  displayError(message, confirmMessage = 'OK', cancelMessage = 'CANCEL', overrideId = null) {
    this.modalService
      .open(message, {
        titleText: 'GL Account Code Override',
        confirmButtonText: confirmMessage,
        cancelButtonText: cancelMessage,
        closeButtonText: 'Close',
        buttonType: 'primary',
      })
      .result.then(
        (result: BentoModalConfirmationCloseReason) => {
          if (confirmMessage == 'SAVE' && result == 'Confirm' && this.readyToSubmit) {
            // Save the current form.
            this.selectedOverride = this.prevSelectedOverride;
            this.saveDetails.emit(true);
          } else if (confirmMessage == 'SAVE' && result == 'Confirm' && !this.readyToSubmit) {
            this.selectedOverride = this.prevSelectedOverride;
            this.displayError('Please make sure all fields are populated.');
          }

          if (cancelMessage == 'DISCARD' && result == 'Cancel') {
            // do reset the form.
            this.selectedOverride = overrideId;
            this.prevSelectedOverride = overrideId;
            this.getFiscalDateRange(this.firmId);
            this.overridePeriodChanged.emit(this.selectedOverride);
          }
        },
        (reason) => {
          this.selectedOverride = this.prevSelectedOverride;
        }
      );
  }
}
