import {Input} from '@angular/core';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject} from 'rxjs';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {Role} from 'src/app/shared/enums';
import {environment} from 'src/environments/environment';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
declare let TRAAC: any;

@Component({
  selector: 'app-share-view-model',
  templateUrl: './share-view-model.component.html',
  styleUrls: ['./share-view-model.component.scss'],
})
export class ShareViewModelComponent implements OnInit {
  @Input() public showPPPError: boolean;
  @Input() public viewTemplateId: string;
  @Input() public firmId: any;
  warningAlerts: BentoAlertItemOptions[] = [];
  shareUserItemsStream: BehaviorSubject<any>;
  shareByLoggedInUser: any[];
  shareUserSelectedText = 'Select';
  errorMessage: string;
  isDataLoading = false;
  shareUserSelectedItems: any[];
  isDefaultForAll = false;
  isQuickSightEnabled = false;
  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private service: BaseService,
    private route: ActivatedRoute,
    private featureToggleService: FeatureToggleService
  ) {}

  ngOnInit(): void {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
    this.featureToggleService.getFeatureValue('QUICKSIGHT').then((val) => (this.isQuickSightEnabled = val));
    /***********************************************************************/

    this.isDataLoading = true;
    if (this.showPPPError) {
      this.addAlert('warning', 'View contains a Performance Peer Group and cannot be shared.');
      this.isDataLoading = false;
    } else {
      this.service
        .get(
          environment.FIAdminBaseEndpoint +
            'v1/view/users/firm/' +
            this.getDefaultfirmId +
            '?viewTemplateId=' +
            this.viewTemplateId,
          ''
        )
        .subscribe(
          (result) => {
            let userIdsSelected = result.filter((item) => item.alreadyShared == true);
            let userIdsByLoggedInUser = result.filter((item) => {
              if (item.alreadyShared == true && !item.disabled) {
                return true;
              }
            });
            this.shareUserItemsStream = new BehaviorSubject(result);
            this.shareUserSelectedItems = userIdsSelected;
            this.shareByLoggedInUser = userIdsByLoggedInUser;
            if (this.shareByLoggedInUser && this.shareByLoggedInUser.length) {
              if (this.shareByLoggedInUser.length == 1) {
                this.shareUserSelectedText = this.shareByLoggedInUser.length.toString() + ' user selected';
              } else this.shareUserSelectedText = this.shareByLoggedInUser.length.toString() + ' users selected';
            }
            this.isDataLoading = false;
          },
          (error) => {
            this.errorMessage = error.error;
            this.addAlert('warning', this.errorMessage, true);
            this.isDataLoading = false;
          }
        );
    }
  }

  public get isGeneralUser(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.GUser;
  }

  share() {
    this.isDataLoading = true;
    if (this.isQuickSightEnabled) {
      TRAAC.track(TRAAC.keyValue('Share click', {email: this.authService.getUserEmail()}).build());
    }
    let requestBody = this.getRequestBody();
    this.service
      .put(
        environment.FIAdminBaseEndpoint + 'v1/view/share/viewTemplate/' + this.viewTemplateId + '/firm/' + this.firmId,
        requestBody
      )
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          this.activeModal.close('Success');
        },
        (error) => {
          this.errorMessage = error.error;
          this.addAlert('warning', this.errorMessage, true);
          this.isDataLoading = false;
        }
      );
  }

  addAlert(type: 'success' | 'info' | 'warning' | 'danger' = 'info', message: string, closeable: boolean = false) {
    this.warningAlerts.push({
      type,
      msg: message,
      closeable: closeable,
    });
  }

  shareUserOnItemsSelected(v: any[]) {
    this.shareUserSelectedItems = v;
    if (v && v.length) {
      this.shareByLoggedInUser = v.filter((item) => {
        if (!item.disabled) {
          return true;
        }
      });
      if (this.shareByLoggedInUser.length == 1) {
        this.shareUserSelectedText = this.shareByLoggedInUser.length.toString() + ' user selected';
      } else this.shareUserSelectedText = this.shareByLoggedInUser.length.toString() + ' users selected';
    }
  }
  getRequestBody() {
    let users = [];
    for (let user of this.shareByLoggedInUser) {
      if (!user.disabled) users.push(user.id);
    }
    let requestBody = {
      users: users,
      isDefaultforAll: this.isDefaultForAll,
    };
    return requestBody;
  }

  // Return firmId of loggedin user
  private get getDefaultfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (backendTokenClaims.userDDO) {
      return backendTokenClaims.userDDO.firmID;
    }
  }
}
