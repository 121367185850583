import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable, pipe, throwError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {LoggingService} from '../app-logging/logging.service';
import {AuthService} from '../auth/auth.service';
import {BackendTokenClaims} from '../../models/tokenResponse';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  clientIpAddress = ''; //For logging purpose
  constructor(private http: HttpClient, private loggingServices: LoggingService, private authService: AuthService) {}

  get(url: string, queryString: any): Observable<any> {
    const httpOptions = this.setHeader();
    const jwtToken = this.authService.getBackendToken();
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    this.loggingServices.logTrace('URL: ' + url + queryString, {
      header: JSON.stringify(httpOptions),
      serviceJwt: jwtToken,
      clientIpAddress: this.clientIpAddress,
      userGuid: backendTokenClaims && backendTokenClaims.userDDO.userID,
    });
    return this.http.get(url + queryString, httpOptions).pipe(
      map((response: Response) => {
        this.loggingServices.logTrace('URL: ' + url + queryString, {
          response: JSON.stringify(response),
          serviceJwt: jwtToken,
          clientIpAddress: this.clientIpAddress,
          userGuid: backendTokenClaims && backendTokenClaims.userDDO.userID,
        });
        return response as any;
      }),
      catchError((err: any) => {
        this.loggingServices.logException(err, {
          url: url + queryString,
          header: JSON.stringify(httpOptions),
          serviceJwt: jwtToken,
          clientIpAddress: this.clientIpAddress,
          exception: JSON.stringify(err),
        });
        return this.handleError(err);
      })
    );
  }

  downloadExcel(url: string): Observable<HttpResponse<Blob>> {
    const jwtToken = this.authService.getBackendToken();
    this.loggingServices.logTrace('URL: ' + url, {serviceJwt: jwtToken, clientIpAddress: this.clientIpAddress});
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return this.http.get(url, {observe: 'response', responseType: 'blob'}).pipe(
      map((response) => {
        this.loggingServices.logTrace('URL: ' + url, {
          response: JSON.stringify(response),
          serviceJwt: jwtToken,
          clientIpAddress: this.clientIpAddress,
          userGuid: backendTokenClaims.userDDO.userID,
        });
        return response;
      }),
      catchError((err: HttpErrorResponse) => {
        this.loggingServices.logException(err, {
          url: url,
          serviceJwt: jwtToken,
          clientIpAddress: this.clientIpAddress,
          exception: JSON.stringify(err),
        });
        return this.handleError(err);
      })
    );
  }
  downloadExcelPost(url: string, model: any): Observable<HttpResponse<Blob>> {
    const jwtToken = this.authService.getBackendToken();
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    this.loggingServices.logTrace('URL: ' + url, {
      requestBody: JSON.stringify(model),
      serviceJwt: jwtToken,
      clientIpAddress: this.clientIpAddress,
      userGuid: backendTokenClaims.userDDO.userID,
    });
    return this.http.post(url, model, {observe: 'response', responseType: 'blob'}).pipe(
      map((response) => {
        const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
        this.loggingServices.logTrace('URL: ' + url, {
          requestBody: JSON.stringify(model),
          response: JSON.stringify(response),
          serviceJwt: jwtToken,
          clientIpAddress: this.clientIpAddress,
          userGuid: backendTokenClaims.userDDO.userID,
        });
        return response;
      }),
      catchError((err: HttpErrorResponse) => {
        this.loggingServices.logException(err, {
          url: url,
          requestBody: JSON.stringify(model),
          serviceJwt: jwtToken,
          clientIpAddress: this.clientIpAddress,
          exception: JSON.stringify(err),
        });
        return this.handleError(err);
      })
    );
  }

  uploadExcel(url: string, model: any): Observable<any> {
    const jwtToken = this.authService.getBackendToken();
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    this.loggingServices.logTrace('URL: ' + url, {
      requestBody: JSON.stringify(model),
      serviceJwt: jwtToken,
      clientIpAddress: this.clientIpAddress,
      userGuid: backendTokenClaims.userDDO.userID,
    });
    return this.http.post(url, model).pipe(
      map((response: Response) => {
        const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
        this.loggingServices.logTrace('URL: ' + url, {
          requestBody: JSON.stringify(model),
          response: JSON.stringify(response),
          serviceJwt: jwtToken,
          clientIpAddress: this.clientIpAddress,
          userGuid: backendTokenClaims.userDDO.userID,
        });
        return response as any;
      }),
      catchError((err: HttpErrorResponse) => {
        this.loggingServices.logException(err, {
          url: url,
          requestBody: JSON.stringify(model),
          serviceJwt: jwtToken,
          clientIpAddress: this.clientIpAddress,
          exception: JSON.stringify(err),
        });
        return this.handleError(err);
      })
    );
  }

  post(url: string, model: any, userGuid: any = null): Observable<any> {
    const body = model ? JSON.stringify(model) : model;
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    const httpOptions = this.setHeader();
    const jwtToken = this.authService.getBackendToken();
    this.loggingServices.logTrace('URL: ' + url, {
      requestBody: JSON.stringify(body),
      requestHeader: JSON.stringify(httpOptions),
      serviceJwt: jwtToken,
      clientIpAddress: this.clientIpAddress,
      userGuid: userGuid ? userGuid : backendTokenClaims && backendTokenClaims.userDDO.userID,
    });
    return this.http.post(url, body, httpOptions).pipe(
      map((response: Response) => {
        const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
        this.loggingServices.logTrace('URL: ' + url, {
          response: JSON.stringify(response),
          requestBody: JSON.stringify(body),
          requestHeader: JSON.stringify(httpOptions),
          serviceJwt: jwtToken,
          clientIpAddress: this.clientIpAddress,
          userGuid: backendTokenClaims && backendTokenClaims.userDDO.userID,
        });
        return response as any;
      }),
      catchError((err: HttpErrorResponse) => {
        this.loggingServices.logException(err, {
          url: url,
          requestBody: JSON.stringify(body),
          requestHeader: JSON.stringify(httpOptions),
          serviceJwt: jwtToken,
          clientIpAddress: this.clientIpAddress,
          exception: JSON.stringify(err),
        });
        return this.handleError(err);
      })
    );
  }

  update(url: string, id: number, model: any): Observable<any> {
    const body = JSON.stringify(model);
    const httpOptions = this.setHeader();
    const jwtToken = this.authService.getBackendToken();
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    this.loggingServices.logTrace('URL: ' + url, {
      requestBody: JSON.stringify(body),
      requestHeader: JSON.stringify(httpOptions),
      serviceJwt: jwtToken,
      userGuid: backendTokenClaims.userDDO.userID,
    });
    return this.http.post(url + '/' + id, body, httpOptions).pipe(
      map((response: Response) => {
        const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
        this.loggingServices.logTrace('URL: ' + url, {
          response: JSON.stringify(response),
          requestBody: JSON.stringify(body),
          requestHeader: JSON.stringify(httpOptions),
          serviceJwt: jwtToken,
          userGuid: backendTokenClaims.userDDO.userID,
        });
        return response as any;
      }),
      catchError((err: HttpErrorResponse) => {
        this.loggingServices.logException(err, {
          url: url,
          requestBody: JSON.stringify(body),
          requestHeader: JSON.stringify(httpOptions),
          serviceJwt: jwtToken,
          exception: JSON.stringify(err),
        });
        return this.handleError(err);
      })
    );
  }

  put(url: string, model: any): Observable<any> {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    const body = model ? JSON.stringify(model) : model;
    const httpOptions = this.setHeader();
    const jwtToken = this.authService.getBackendToken();
    this.loggingServices.logTrace('URL: ' + url, {
      requestBody: JSON.stringify(body),
      requestHeader: JSON.stringify(httpOptions),
      serviceJwt: jwtToken,
      userGuid: backendTokenClaims.userDDO.userID,
    });

    return this.http.put(url, body, httpOptions).pipe(
      map((response: Response) => {
        this.loggingServices.logTrace('URL: ' + url, {
          response: JSON.stringify(response),
          requestBody: JSON.stringify(body),
          requestHeader: JSON.stringify(httpOptions),
          serviceJwt: jwtToken,
        });
        return response as any;
      }),
      catchError((err: HttpErrorResponse) => {
        this.loggingServices.logException(err, {
          url: url,
          requestBody: JSON.stringify(body),
          requestHeader: JSON.stringify(httpOptions),
          serviceJwt: jwtToken,
          exception: JSON.stringify(err),
        });
        return this.handleError(err);
      })
    );
  }

  patch(url: string, model: any): Observable<any> {
    const body = JSON.stringify(model);
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    const httpOptions = this.setHeader();
    const jwtToken = this.authService.getBackendToken();
    this.loggingServices.logTrace('URL: ' + url, {
      requestBody: JSON.stringify(body),
      requestHeader: JSON.stringify(httpOptions),
      serviceJwt: jwtToken,
      userGuid: backendTokenClaims.userDDO.userID,
    });
    return this.http.patch(url, body, httpOptions).pipe(
      map((response: Response) => {
        this.loggingServices.logTrace('URL: ' + url, {
          response: JSON.stringify(response),
          requestBody: JSON.stringify(body),
          requestHeader: JSON.stringify(httpOptions),
          serviceJwt: jwtToken,
          userGuid: backendTokenClaims.userDDO.userID,
        });
        return response as any;
      }),
      catchError((err: HttpErrorResponse) => {
        this.loggingServices.logException(err, {
          url: url,
          requestBody: JSON.stringify(body),
          requestHeader: JSON.stringify(httpOptions),
          serviceJwt: jwtToken,
          exception: JSON.stringify(err),
        });
        return this.handleError(err);
      })
    );
  }

  delete(url: string, queryString: any): Observable<any> {
    const httpOptions = this.setHeader();
    const jwtToken = this.authService.getBackendToken();
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    this.loggingServices.logTrace('URL: ' + url + queryString, {
      requestHeader: JSON.stringify(httpOptions),
      serviceJwt: jwtToken,
      userGuid: backendTokenClaims.userDDO.userID,
    });

    return this.http.delete(url + queryString, httpOptions).pipe(
      map((response: Response) => {
        const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
        this.loggingServices.logTrace('URL: ' + url, {
          response: JSON.stringify(response),
          requestHeader: JSON.stringify(httpOptions),
          serviceJwt: jwtToken,
          userGuid: backendTokenClaims.userDDO.userID,
        });
        return response as any;
      }),
      catchError((err: HttpErrorResponse) => {
        this.loggingServices.logException(err, {
          url: url + queryString,
          requestHeader: JSON.stringify(httpOptions),
          serviceJwt: jwtToken,
          exception: JSON.stringify(err),
        });
        return this.handleError(err);
      })
    );
  }

  setHeader() {
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    headers = headers.append('Access-Control-Allow-Origin', '*');

    const httpOptions = {
      headers,
    };
    return httpOptions;
  }

  handleError(error: HttpErrorResponse) {
    if (error.status === 500) {
      return throwError(error || 'Server error');
    } else if (error.status === 400) {
      return throwError(error || 'Bad Request');
    } else if (error.status === 300) {
      return throwError(error || 'Ambiguous');
    } else if (error.status === 401) {
      return throwError(error || 'Unauthorized');
    } else if (error.status === 403) {
      return throwError(error || 'No Rights');
    } else {
      return throwError(error);
    }
  }
}
