import {Inject, Injectable} from '@angular/core';
import {BackendTokenClaims} from '../../models/tokenResponse';
import jwt_decode from 'jwt-decode';
import {Router, ActivatedRouteSnapshot} from '@angular/router';
import {ConnectionService} from '../connection/connection.service';
import {BehaviorSubject} from 'rxjs';
import {AuthService as Auth0Service} from '@auth0/auth0-angular';
import {DOCUMENT} from '@angular/common';
import {Role} from 'src/app/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  backendJWTToken: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  ciamToken: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  onlySRUserAlowedURLS = ['', 'contact-us', 'staffing-ratio'];
  onlyPMMUserAlowedURLS = ['', 'contact-us', 'pmm'];
  noAccessUserAlowedURLS = ['contact-us'];
  private static removeSignatureFromJwt(token: string): string {
    if (token) {
      const firstPeriodIndex = token.indexOf('.');
      const secondPeriodIndex = token.lastIndexOf('.');
      if (firstPeriodIndex > 0 && secondPeriodIndex > 0 && firstPeriodIndex !== secondPeriodIndex) {
        return token.slice(0, secondPeriodIndex);
      }
    }
    return token;
  }

  constructor(
    private router: Router,
    private connection: ConnectionService,
    public auth0: Auth0Service,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  getBackendToken(includeSignature: boolean = true): string {
    let rawJwt: string;
    this.backendJWTToken.subscribe((token) => {
      rawJwt = token;
    });
    return includeSignature ? rawJwt : AuthService.removeSignatureFromJwt(rawJwt);
  }

  getCIAMToken(): string {
    let rawToken: string;
    this.ciamToken.subscribe((token) => {
      rawToken = token;
    });
    return rawToken;
  }

  getBackendTokenClaims(): BackendTokenClaims {
    const backendToken = this.getBackendToken();
    return backendToken ? this.decodeBackendToken(backendToken) : null;
  }

  getUserEmail(): String {
    const backendToken = this.getBackendToken();
    const decodedBackendToken = backendToken ? this.decodeBackendToken(backendToken) : null;
    if (decodedBackendToken && decodedBackendToken.userDDO && decodedBackendToken.userDDO.email) {
      return decodedBackendToken.userDDO.email;
    } else {
      return 'Not defined';
    }
  }

  isUserAuthenticated(): boolean {
    if (this.isBackendTokenExpired()) {
      return false;
    }

    return true;
  }

  isUserAuthorised(route: ActivatedRouteSnapshot): boolean {
    const backendTokenClaims: BackendTokenClaims = this.getBackendTokenClaims();
    if (backendTokenClaims && backendTokenClaims.userDDO) {
      if (
        (this.isNoAccessUser && this.isSRUser && this.onlySRUserAlowedURLS.includes(route.routeConfig.path)) ||
        (this.isNoAccessUser && this.isPMMUser && this.onlyPMMUserAlowedURLS.includes(route.routeConfig.path)) ||
        (this.isNoAccessUser && !this.isSRUser && this.noAccessUserAlowedURLS.includes(route.routeConfig.path))
      ) {
        return true;
      } else if (
        this.isNoAccessUser ||
        (!this.isSRUser && route.routeConfig.path == 'staffing-ratio') ||
        (!this.isPMMUser && route.routeConfig.path == 'pmm')
      ) {
        this.router.navigate(['accessdenied']);
        return false;
      }
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(backendTokenClaims.userDDO.role) === -1) {
        // role not authorised so redirect to access denied
        this.router.navigate(['accessdenied']);
        return false;
      }

      // authorised so return true
      return true;
    }

    // not logged in so logout and return to origin
    this.auth0.logout({returnTo: this.doc.location.origin});
    return false;
  }

  private isBackendTokenExpired(): boolean {
    const backendToken = this.getBackendToken();
    if (!backendToken) {
      return true;
    }
    const backendTokenClaims: BackendTokenClaims = this.decodeBackendToken(backendToken);
    return (backendTokenClaims.exp || 0) * 1000 < Date.now();
  }

  private decodeBackendToken(backendToken: string): BackendTokenClaims {
    return jwt_decode(backendToken);
  }

  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }

  public get isNoAccessUser(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.NOAccess;
  }
  public get isSRUser(): boolean {
    let isSRUser = false;
    const backendTokenClaims: BackendTokenClaims = this.getBackendTokenClaims();
    if (backendTokenClaims.userDDO.isStaffingratioUser && backendTokenClaims.userDDO.isStaffingratioUser != null) {
      isSRUser = backendTokenClaims.userDDO.isStaffingratioUser;
    }
    return isSRUser;
  }
  public get isPMMUser(): boolean {
    let isPMMUser = false;
    const backendTokenClaims: BackendTokenClaims = this.getBackendTokenClaims();
    if (backendTokenClaims.userDDO.isPMMUser && backendTokenClaims.userDDO.isPMMUser != null) {
      isPMMUser = backendTokenClaims.userDDO.isPMMUser;
    }
    return isPMMUser;
  }
}
