<div class="GlobalLayout-group">
  <div class="GlobalLayout-item GlobalLayout-item--small">
    <bento-side-nav class="NavSide" aria-label="Firm administrator users" [items]="items" [collapsible]="true">
    </bento-side-nav>
  </div>
  <div class="GlobalLayout-item GlobalLayout-item--large">
    <ng-container *ngIf="items[0].data.isVisible">
      <app-user-information
        (handleUserAction)="usersActionHandler($event)"
        [selectedUserData]="selectedOption"
        [firmId]="firmId"
        (enableAllUserSideNav)="enableAllUserSideNavHandler($event)"
      ></app-user-information>
    </ng-container>
    <ng-container *ngIf="items[1].data.isVisible">
      <app-user-permissions
        (handleUserAction)="usersActionHandler($event)"
        [selectedUserData]="selectedOption"
        [firmId]="firmId"
        [permission]="items[1].label"
      ></app-user-permissions>
    </ng-container>
    <ng-container *ngIf="items[2].data.isVisible">
      <app-user-permissions
        (handleUserAction)="usersActionHandler($event)"
        [selectedUserData]="selectedOption"
        [firmId]="firmId"
        [permission]="items[2].label"
      ></app-user-permissions>
    </ng-container>
    <ng-container *ngIf="items[3].data.isVisible">
      <app-user-permissions
        (handleUserAction)="usersActionHandler($event)"
        [selectedUserData]="selectedOption"
        [firmId]="firmId"
        [permission]="items[3].label"
      ></app-user-permissions>
    </ng-container>
  </div>
</div>
