export default class CloudCredential3E {

public tenantId: string ;
public clientId: string ;
public clientSecret: string ;
public instanceId: string ;
public scope: string ;
public grantType: string ;
}

export class Customer3EMigration {

    public host: string ;
    public DBName: string ;
    public userName: string ;
    public password: string ;
    is3EUpdated : boolean = false;
}
    
