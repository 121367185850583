import {Component, EventEmitter, Input, Output, OnInit, OnDestroy} from '@angular/core';
import {BentoSideNavItem} from '@bento/bento-ng';
import UserModel from 'src/app/core/models/user.model';

@Component({
  selector: 'app-firm-user-details',
  templateUrl: './firm-user-details.component.html',
})
export class FirmUserDetailsComponent implements OnInit, OnDestroy {
  isNewUser = false;

  @Input() firmId: number;
  @Input() selectedOption: any;
  @Output() handleCustomUserAction = new EventEmitter<string>();

  items: BentoSideNavItem[] = [
    {
      label: 'User information',
      selected: true,
      action: () => {
        this.showHideUserTabSideNav('User information');
      },
      data: {isVisible: true},
    },
    {
      label: 'Office permission',
      action: () => {
        this.showHideUserTabSideNav('Office permission');
      },
      disabled: this.isNewUser,
      data: {isVisible: false},
    },
    {
      label: 'Practice permission',
      action: () => {
        this.showHideUserTabSideNav('Practice permission');
      },
      disabled: this.isNewUser,
      data: {isVisible: false},
    },
    {
      label: 'Metric permission',
      action: () => {
        this.showHideUserTabSideNav('Metric permission');
      },
      disabled: this.isNewUser,
      data: {isVisible: false},
    },
  ];

  constructor() {}

  ngOnInit(): void {
    if (this.selectedOption == null) this.isNewUser = true;
    this.items.forEach((item) => {
      if (item.label != 'User information') item.disabled = this.isNewUser;
    });
  }

  ngOnDestroy(): void {
    let option = {message: 'Return', selectedOption: null};
    this.handleCustomUserAction.emit(JSON.stringify(option));
  }

  showHideUserTabSideNav(label: string) {
    this.items.forEach((element: BentoSideNavItem) => {
      if (element.label === label) {
        element.data.isVisible = true;
      } else {
        element.data.isVisible = false;
      }
    });
  }

  usersActionHandler(message: string) {
    let response = JSON.parse(message);
    if (response && response.message === 'Deleted') {
      let option = {message: 'Deleted', selectedOption: null};
      this.handleCustomUserAction.emit(JSON.stringify(option));
    } else {
      let option = {message: 'Return', selectedOption: null};
      this.handleCustomUserAction.emit(JSON.stringify(option));
    }
  }

  enableAllUserSideNavHandler(userData: UserModel) {
    this.selectedOption = userData;
    this.items.forEach((item) => {
      if (item.label != 'User information') item.disabled = false;
    });
  }
}
