<bento-alert [items]="alerts" (close)="closeAlert($event)"></bento-alert>
<div class="GlobalLayout Main" *ngIf="peerGroupStatus.isDefaultView" [bentoBusyLoader]="isDataLoading">
  <main id="MainContent" role="main" tabindex="-1" class="Main Main--narrow">
    <header class="Main-header">
      <h1 class="Main-heading">Pre-set peer groups</h1>
      <div class="Actions justify-content-end">
        <div class="col" style="padding-left: 1px">
          <span class="sr-total-firms" role="status">Showing {{ currentRecords }} of {{ totalRecords }}</span>
        </div>
        <div class="Actions-item">
          <button
            type="button"
            [disabled]="isAddpeerGroupbtnDisabled"
            class="btn btn-outline-secondary"
            (click)="handleAddPeerGroupAction()"
          >
            <span class="btn-text">Add peer group</span>
          </button>
        </div>
        <div class="Actions-item Actions-item--large">
          <span class="bento-search">
            <label for="staffingRatioPeerGroupsSearch" class="sr-only">Search staffing ratio peer groups</label>
            <input
              type="text"
              class="form-control"
              name="staffingRatioPeerGroupsSearch"
              id="staffingRatioPeerGroupsSearch"
              (keyup.enter)="searchStaffingRatioPeerGroups()"
              [(ngModel)]="searchText"
            />
            <button type="button" class="btn btn-primary" (click)="searchStaffingRatioPeerGroups()">
              <span class="btn-text">Search</span>
            </button>
          </span>
        </div>
      </div>
    </header>
    <div class="Main-body">
      <wj-flex-grid
        #flexGrid
        class="DataGrid DataGridFlexScroll"
        [itemsSource]="staffingRatioPeerGroupsGridData"
        [autoClipboard]="false"
        [isReadOnly]="true"
        [headersVisibility]="'Column'"
        [allowDragging]="false"
        [autoRowHeights]="true"
        (initialized)="gridInitialized(flexGrid)"
      >
        <wj-flex-grid-column
          [header]="'Peer group name'"
          [binding]="'peerGroupName'"
          [align]="'left'"
          [width]="'*'"
          [minWidth]="240"
          [wordWrap]="true"
        >
          <ng-template
            wjFlexGridCellTemplate
            [cellType]="'Cell'"
            let-item="item"
            style="left: 0px; top: 30px; width: 698.5px; height: 20px"
          >
            <a [routerLink]="['.']" (click)="updateServiceWith(item)">{{ item.peerGroupName }}</a>
          </ng-template>
        </wj-flex-grid-column>
      </wj-flex-grid>
    </div>
  </main>
</div>

<app-staffing-ratio-peergroup-details
  *ngIf="!peerGroupStatus.isDefaultView && peerGroupStatus.customPeerGroupMode == null"
  [currentStatus]="peerGroupStatus.currentStatus"
  [selectedPeerGroup]="peerGroupStatus.selectedPeerGroup"
>
</app-staffing-ratio-peergroup-details>
<app-staffing-ratio-add-peer-groups
  [currentSurveyYear]="currentSurveyYear"
  *ngIf="!peerGroupStatus.isDefaultView && peerGroupStatus.customPeerGroupMode != null"
  [selectedStaffingRatioPeerGroup]="peerGroupStatus.selectedPeerGroup"
  (backToStaffingRatioPeerGroupsAction)="backToStaffingRatioPeerGroups()"
></app-staffing-ratio-add-peer-groups>
