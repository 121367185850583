import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit, HostBinding} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService as Auth0Service} from '@auth0/auth0-angular';
import {Observable, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
})
export class UnauthorizedComponent implements OnInit {
  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }

  public error$: Observable<any> = this.auth0.error$;

  constructor(public auth0: Auth0Service, private router: Router, @Inject(DOCUMENT) private doc: Document) {}

  ngOnInit(): void {
    timer(0)
      .pipe(takeUntil(this.error$))
      .subscribe(() => {
        this.router.navigate(['']);
      });
  }

  signOut(): void {
    this.auth0.logout({returnTo: this.doc.location.origin});
  }
}
