import FirmMetricModel from './firm-metric.model';
import PeerCheckViewColumns from './peerCheckViewColumns.model';
export default class FirmBasicsModel {
  public firmId: number;
  public currency: string;
  public firmCurrency: string;
  public defaultAccess: string;
  public yyyymm: number;
  public settings: string;
  public metrics: FirmMetricModel[];
  public officeGroups: any[];
  public titleGroups: any[];
  public rules: any[];
  public firmLevelRules: PeerCheckViewColumns;
  public constructor() {}
}
