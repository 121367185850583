<header class="global-header global-header-text Header" role="banner">
  <div class="topbar-left">
    <a href="/" class="topbar-item topbar-logo Header-logo">
      <app-svg-logo></app-svg-logo>
    </a>
  </div>
</header>
<main id="MainContent" tabindex="-1" class="Main Main--zeroState" *ngIf="error$ | async as error">
  <figure class="ZeroState">
    <app-svg-exclamation class="ZeroState-header"></app-svg-exclamation>
    <figcaption class="ZeroState-body">
      <h1 class="h3">{{ error.error_description }}</h1>
      <div class="ZeroState-actions">
        <p>
          Contact your firm's admin to resolve this issue or <a [routerLink]="" (click)="signOut()">Login</a> again.
        </p>
      </div>
    </figcaption>
  </figure>
</main>
