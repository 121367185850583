import {BehaviorSubject} from 'rxjs';
export class TkFilterModel {
  selectedNamesText: string;
  selectedTkinitsText: string;
  selectedFirmTitleText: string;
  selectedFirmOfficeText: string;
  selectedDeptText: string;
  selectedSectionText: string;
  selectedUdfText: string;
  selectedPeerOfficeText: string;
  selectedPeerTitleText: string;
  selectedPeerPracticeText: string;
  selectedPmAccountText: string;

  nameStream: BehaviorSubject<string[]>;
  tkinitStream: BehaviorSubject<any>;
  firmTitleStream: BehaviorSubject<string[]>;
  firmOfficeStream: BehaviorSubject<string[]>;
  departmentStream: BehaviorSubject<string[]>;
  peerTitleStream: BehaviorSubject<string[]>;
  peerOfficeStream: BehaviorSubject<string[]>;
  peerPracticeStream: BehaviorSubject<string[]>;
  sectionStream: BehaviorSubject<string[]>;
  udfStream: BehaviorSubject<string[]>;
  pmAccountStream: BehaviorSubject<string[]>;

  isFteVisible = true;
  isNameVisible = true;
  isTKINITVisible = true;
  isTitleVisible = true;
  isOfficeVisible = true;
  isDeptVisible = true;
  isSectionVisible = true;
  isUdfVisible = true;
  isPeerOfficeVisible = true;
  isPeerPracticeVisible = true;
  isPeerTitleVisible = true;
  isExpYearsVisible = true;
  isPmAccountVisible = true;

  titles: string[];
  offices: string[];
  departments: string[];
  peerOffices: string[];
  peerPractices: string[];
  peerTitles: string[];
  names: string[];
  tkids: any[];
  pmAccount: any[];

  officeKeyValue: any = {};
  peerOfficeKeyValue: any = {};
  peerTitleKeyValue: any = {};
  titleKeyValue: any = {};
  deptKeyValue: any = {};
  peerPracticeKeyValue: any = {};
}
