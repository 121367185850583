import {BentoAlertItemOptions} from '@bento/bento-ng';

export class Firmpeergroupstatus {
  isDefaultView: Boolean;
  currentStatus: string;
  selectedTab: string;
  selectedPeerGroup: any;
  alerts: BentoAlertItemOptions[];
  customPeerGroupMode: string;
  selectedPeerGroupFirms: PeerGroupFirms[];

  constructor() {
    this.isDefaultView = true;
    this.currentStatus = null;
    this.selectedTab = null;
    this.selectedPeerGroup = null;
    this.alerts = [];
    this.customPeerGroupMode = null;
    this.selectedPeerGroupFirms = [];
  }
}

export class FirmPeerGroupList {
  peerid: number;
  peerlabel: string;
  isGlobal: boolean;
  isApproved: boolean;
  isPending: boolean;
}

export class PeerGroupFirms {
  firmId: number;
  name: string;
  rank: number;
  lawyers: number;
  headQuarterOffice: any;
  status: any;
  changeDate: Date | any;
  selected: boolean;
}

export class PeerGroupRequestBody {
  name: string;
  selectedFirmIds: number[];
}
