<header class="global-header global-header-text Header" role="banner">
  <div class="topbar-left">
    <a href="/" class="topbar-item topbar-logo Header-logo">
      <app-svg-logo></app-svg-logo>
    </a>
  </div>
</header>
<main id="MainContent" tabindex="-1" class="Main Main--zeroState" [bentoBusyLoader]="isLoading">
  <figure class="ZeroState">
    <app-svg-clock class="ZeroState-header"></app-svg-clock>
    <figcaption class="ZeroState-body">
      <h1 class="h3">Financial Insights is temporarily offline.</h1>
      <p>
        Maintenance is scheduled for {{ date }} at {{ time }} ({{ timezone }}). It is expected to take
        {{ timeToComplete }}.
      </p>
      <div class="ZeroState-actions">
        <p>Here are some links you may find helpful:</p>
        <!-- TODO: Add URLS to links -->
        <ul class="ZeroState-list">
          <li class="ZeroState-item">
            <a href="mailto:financialinsights@thomsonreuters.com">Contact support</a>
          </li>
          <!-- <li class="ZeroState-item">
            <a href="#">Help documentation</a>
          </li> -->
        </ul>
      </div>
    </figcaption>
  </figure>
</main>
