<bento-alert [items]="alerts"></bento-alert>
<div [bentoBusyLoader]="isDataLoading">
  <div class="content-left">
    <img src="assets/images/photo_02.png" width="240" height="180" alt="" />
  </div>
  <div class="content-right body01">
    <div class="flexClass">
      <div class="width-30">
        <label class="font-weight-normal" *ngIf="peerGroups.length === 0" for="listOfPeerGroup">
          <strong>Peer groups data not available.</strong>
        </label>
        <div *ngIf="peerGroups.length !== 0">
          <label class="font-weight-normal" for="listOfPeerGroup"
            >Select your <strong>{{ selectedFirmSRSubscriptionInfo.numberOfStdPeerGroups }}</strong> Pre-set Peer
            {{ preset_groups_label }}.
          </label>
        </div>
        <div id="listOfPeerGroup">
          <ng-container *ngIf="peerGroups && peerGroups.length !== 0">
            <ng-container *ngFor="let pg of peerGroups">
              <div *ngIf="pg.isGlobal">
                <bento-checkbox
                  id="{{ pg.peerGroupId }}"
                  [(ngModel)]="pg.isSelected"
                  (change)="onPgCheckBoxChange(pg)"
                  [disabled]="
                    (checkedLimit == selectedFirmSRSubscriptionInfo.numberOfStdPeerGroups && !pg.isSelected) ||
                    selectedFirmSRSubscriptionInfo.peerSubmitted
                  "
                ></bento-checkbox>
                <label for="{{ pg.peerGroupId }}" class="font-style">{{ pg.peerGroupName }}</label>
                <br />
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="width-50">
        <div *ngIf="selectedFirmSRSubscriptionInfo && selectedFirmSRSubscriptionInfo.numberOfCustomPeerGroups > 0">
          <span
            >Select your <strong>{{ selectedFirmSRSubscriptionInfo.numberOfCustomPeerGroups }} </strong>Custom Peer
            {{ cust_groups_label }}.
          </span>
          <br />
          <span>Click ‘Custom Peer Groups’ to define those groups.</span>
          <br />
          <br />
          <span>Current custom peer group selection status: </span>
          <div id="listOfCutomPeerGroup">
            <ul>
              <ng-container *ngFor="let cpg of customGroups; index as inx">
                <li *ngIf="inx < selectedFirmSRSubscriptionInfo.numberOfCustomPeerGroups">
                  Custom Group <strong>{{ cpg.peerGroupName }}</strong> has {{ cpg.firmCount }}

                  <ng-template [ngIf]="cpg.firmCount <= 1" [ngIfElse]="moreThanOneMember"> member </ng-template>
                  <ng-template #moreThanOneMember>members </ng-template>
                  <ng-container *ngIf="cpg.firmCount < 5"> <strong> (Not fully defined)</strong></ng-container>
                </li>
              </ng-container>
            </ul>
            <button type="button" class="btn btn-primary" (click)="customPeerGroupPopUp()">
              <span class="btn-text">Custom Peer Groups</span>
            </button>
          </div>
        </div>
        <div class="submit-peer-group-sec">
          <ng-template [ngIf]="!selectedFirmSRSubscriptionInfo.peerSubmitted" [ngIfElse]="peerNotSubmitted">
            <p>When all authorized peer groups have been defined, click &lsquo;Submit Peer Groups&rsquo; below.</p>
            <button
              type="button"
              class="btn btn-primary"
              [disabled]="isSubmitPeerGroupDisabled"
              (click)="submitPeerGroups()"
            >
              <span class="btn-text">Submit Peer Groups</span>
            </button>
          </ng-template>
          <ng-template #peerNotSubmitted>
            <p>
              Your peer group selection has been submitted. Please contact us in case you need to alter your peer group
              selection.
            </p>
            <ul>
              <li>
                <a href="mailto:financialinsights.admin@thomsonreuters.com?subject=Staffing Ratio Peer Group Assistance"
                  >Financial Insights</a
                >
              </li>
            </ul>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
