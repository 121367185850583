<div class="modal-content">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h3 id="modalSaveConfirmation" class="modal-header h2">Unsaved changes</h3>
  <div class="modal-body">
    <p>There are unsaved changes. Would you like to save them?</p>
  </div>
  <div class="modal-footer modal-footer--custom">
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss('cancel')">CANCEL</button>
    <button type="button" class="btn btn-outline-secondary" (click)="saveChanges('dontsave')">DON'T SAVE</button>
    <button type="button" class="btn btn-primary" (click)="saveChanges('save')">SAVE</button>
  </div>
</div>
