/* eslint-disable max-len */
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SkipLink, BentoSkipLinksService, BentoAlertItemOptions} from '@bento/bento-ng';
import * as wjcCore from '@grapecity/wijmo';
import {environment} from 'src/environments/environment';
import {ConnectionService} from './core/services/connection/connection.service';
import {wijmoLocalLicenceKey} from './shared/constants';
import IdleTimer from './shared/idle-timer';
import {AuthService as Auth0Service} from '@auth0/auth0-angular';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';
import {filter} from 'rxjs/operators';
import {BaseService} from 'src/app/core/services/base/base.service';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
declare let TRAAC_CONFIG: any;
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {chdir} from 'process';

wjcCore.setLicenseKey(window.location.hostname == 'localhost' ? wijmoLocalLicenceKey.key : environment.wijmoKey);
@Component({
  selector: 'app-peer-monitor',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'peer-monitor';
  alerts: BentoAlertItemOptions[] = [];
  timer: IdleTimer;
  timedOut = false;
  // some fields to store our state so we can display it in the UI
  idleState = 'NOT_STARTED';
  countdown?: number = null;
  lastPing?: Date = null;
  @ViewChild('sessionTimeoutModal', {static: false}) childModal: TemplateRef<any>;

  constructor(
    skipLinks: BentoSkipLinksService,
    private connection: ConnectionService,
    private router: Router,
    public auth0: Auth0Service,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private featureToggleService: FeatureToggleService,
    private service: BaseService,
    @Inject(DOCUMENT) private doc: Document,
    private idle: Idle,
    private keepalive: Keepalive,
    private modalService: NgbModal,
    cd: ChangeDetectorRef
  ) {
    skipLinks.updateLinks([new SkipLink('MainContent', 'Skip to Main Content')]);
    // how long can they be inactive before considered idle, in seconds
    idle.setIdle(+environment.UserIdleConfig.idle - (+environment.UserIdleConfig.timeout));
    // how long can they be idle before considered timed out, in seconds
    idle.setTimeout(+environment.UserIdleConfig.timeout);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'NOT_IDLE';
      this.countdown = null;
      cd.detectChanges();
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.auth0.logout({returnTo: this.doc.location.origin});
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      this.modalService.open(this.childModal);
    });

    // idle.onTimeoutWarning.subscribe((countdown) => {
    //   this.countdown = countdown;
    //   this.idleState = 'You will time out in ' + countdown + ' seconds!'
    //   console.log(this.idleState);
    // });

    // sets the ping interval to 15 seconds
    keepalive.interval(+environment.UserIdleConfig.ping);
    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
  }

  ngOnInit() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);
      rt.data.subscribe((data) => {
        this.titleService.setTitle(data.title + ' | Thomson Reuters Financial Insights');
      });
    });
    this.setupInactivityTimeout();

    this.setupQuickSightEvent();

    if (environment.offline && environment.offline.status) {
      this.router.navigate(['offline']);
    }
  }

  ngOnDestroy(): void {
    this.timer.cleanUp();
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
  setupQuickSightEvent() {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - EXPORT_PEERGROUP ******/
    /**Remove the call to feature API ***** */
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/feature/QUICKSIGHT', '').subscribe((result) => {
      if (result['QUICKSIGHT']) {
        TRAAC_CONFIG.adapters[0].config.url = environment.productInsightURL;
        TRAAC_CONFIG.adapters[0].config.headers['X-PRODUCT-INSIGHTS-TOKEN'] = environment.productInsightToken;
      }
    });
  }
  setupInactivityTimeout() {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - INACTIVITY_TIMEOUT ******/
    //   /**Remove the call to feature API ***** */
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/feature/INACTIVITY_TIMEOUT', '').subscribe((result) => {
      if (result['INACTIVITY_TIMEOUT']) {
        this.reset();
      }
    });
  }
  // setupInactivityTimeout() {
  //   /***** TO BE REMOVED PART OF FEATURE TOGGLE - INACTIVITY_TIMEOUT ******/
  //   /**Remove the call to feature API ***** */
  //   this.service.get(environment.FIAdminBaseEndpoint + 'v1/feature/INACTIVITY_TIMEOUT', '').subscribe((result) => {
  //     if (result['INACTIVITY_TIMEOUT']) {
  //       this.timer = new IdleTimer({
  //         timeout: +environment.inactivityTimeout * 60, //expired after n secs
  //         onTimeout: () => {
  //           this.auth0.logout({returnTo: this.doc.location.origin});
  //         },
  //       });
  //     }
  //   });
  // }
  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = 'NOT_IDLE';
    this.lastPing = null;
  }
}
