import {Component, Input, OnInit} from '@angular/core';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {ActivatedRoute} from '@angular/router';
import {BaseService} from 'src/app/core/services/base/base.service';
import {store, eventDispatcher} from 'src/app/core/store';
import {ActionTypes} from 'src/app/core/store/actions';
import {environment} from 'src/environments/environment';
import {glLedgerValues, glLedger3EValues, aderatnGLLedgerValues, glDeptSourceValues} from 'src/app/shared/constants';
import GeneralLedgerSettingModel from 'src/app/core/models/generalLedgerSetting.model';
import {LocalStorageService} from 'src/app/core/services/local-storage/local-storage.service';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-general-ledger',
  templateUrl: './general-ledger.component.html',
  styleUrls: ['./general-ledger.component.scss']
})
export class GeneralLedgerComponent implements OnInit {
  @Input() isNewFirm;
  @Input() firmId;
  alerts: BentoAlertItemOptions[] = [];
  generalLedgerSetting = new GeneralLedgerSettingModel();
  resetgeneralLedgerSetting = new GeneralLedgerSettingModel();
  isgeneralLedgerUpdated = false;
  isGetgeneralLedgerSettings = false;
  successMsg: string;
  errorMessage: any;
  glLedgerValues = [];
  glDeptSourceValues = [];
  glOfficeSourceValues = [];
  firmType: string;
  glAccountStartChangedVal = '(([0-9])|([1-5][0-9])|(6[0-3]))';
  glAccountEndChangedVal = '(([1-9])|([1-5][0-9])|(6[0-4]))';
  validations: any;
  firmParameterModel: any;

  constructor(
    private route: ActivatedRoute,
    private service: BaseService,
    private localStorageService: LocalStorageService,
    private authService: AuthService
  ) {
    this.isGetgeneralLedgerSettings = false;
    store.subscribe((state) => {
      const {firmParameter} = state;
      if (firmParameter) {
        this.generalLedgerSetting = Object.assign({}, firmParameter.generalLedgerSettings);
        this.firmParameterModel = Object.assign({}, firmParameter);
      }
    });
    this.isGetgeneralLedgerSettings = true;

    this.validations = {
      glAccountStartChangedVal: {
        pattern: 'Error: Value should be between 0 to 63',
      },
      glAccountEndChangedVal: {
        pattern: 'Error: Value should be between 1 to 64',
      },
    };
  }
  ngOnInit(): void {
    if (this.isNewFirm) this.generalLedgerSetting = new GeneralLedgerSettingModel();
    else eventDispatcher.next({type: ActionTypes.GetFirmParameterSetting});
    this.resetgeneralLedgerSetting = JSON.parse(JSON.stringify(this.generalLedgerSetting));
    this.firmType = this.localStorageService.get('FIRM_TYPE');
    this.generalLedgerValue();
  }
  glAccountStartChanged(event) {
    switch (event.name) {
      case 'Segment Account Start': {
        if (
          Number(event.value) <= Number(event.max) &&
          Number(event.value) >= this.generalLedgerSetting.glSegmentAccountEnd
        ) {
          this.generalLedgerSetting.glSegmentAccountEnd = Number(event.value) + 1;
        }
        break;
      }
      case 'Segment Real Natural Account Start': {
        if (
          Number(event.value) <= Number(event.max) &&
          Number(event.value) >= this.generalLedgerSetting.glSegmentRealNaturalAccEnd
        ) {
          this.generalLedgerSetting.glSegmentRealNaturalAccEnd = Number(event.value) + 1;
        }
        break;
      }
      case 'Segment Office Start': {
        if (
          Number(event.value) <= Number(event.max) &&
          Number(event.value) >= this.generalLedgerSetting.glSegmentOfficeEnd
        ) {
          this.generalLedgerSetting.glSegmentOfficeEnd = Number(event.value) + 1;
        }
        break;
      }
      case 'Segment Department Start': {
        if (
          Number(event.value) <= Number(event.max) &&
          Number(event.value) >= this.generalLedgerSetting.glSegmentDepartmentEnd
        ) {
          this.generalLedgerSetting.glSegmentDepartmentEnd = Number(event.value) + 1;
        }
        break;
      }
    }
  }
  glAccountEndChanged(event) {
    switch (event.name) {
      case 'Segment Account End': {
        if (
          Number(event.value) <= Number(event.max) &&
          Number(event.value) <= this.generalLedgerSetting.glSegmentAccountStart
        ) {
          this.generalLedgerSetting.glSegmentAccountStart = Number(event.value) - 1;
        }
        break;
      }
      case 'Segment Real Natural Account End': {
        if (
          Number(event.value) <= Number(event.max) &&
          Number(event.value) <= this.generalLedgerSetting.glSegmentRealNaturalAccStart
        ) {
          this.generalLedgerSetting.glSegmentRealNaturalAccStart = Number(event.value) - 1;
        }
        break;
      }
      case 'Segment Office End': {
        if (
          Number(event.value) <= Number(event.max) &&
          Number(event.value) <= this.generalLedgerSetting.glSegmentOfficeStart
        ) {
          this.generalLedgerSetting.glSegmentOfficeStart = Number(event.value) - 1;
        }
        break;
      }
      case 'Segment Department End': {
        if (
          Number(event.value) <= Number(event.max) &&
          Number(event.value) <= this.generalLedgerSetting.glSegmentDepartmentStart
        ) {
          this.generalLedgerSetting.glSegmentDepartmentStart = Number(event.value) - 1;
        }
        break;
      }
    }
  }

  generalLedgerValue() {
    this.glDeptSourceValues = glDeptSourceValues;
    if (this.firmType === '3') {
      this.glLedgerValues = aderatnGLLedgerValues;
    } else if (this.firmType === '1') {
      this.glLedgerValues = glLedgerValues;
    } else {
      // this.glLedgerValues = glLedger3EValues;
      this.glDeptSourceElite3EValues();
    }
  }
  glLedgerElite3EValues() {
    this.service.get(environment.FIClientBaseEndpoint + 'v1/firm/firmparameters/glledger/' + this.firmId, '').subscribe(
      (result) => {
        this.glLedgerValues = result;
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
  glDeptSourceElite3EValues() {
    this.service.get(environment.FIClientBaseEndpoint + 'v1/firm/firmparameters/glledger/source', '').subscribe(
      (result) => {
        this.glDeptSourceValues = result;
        this.glOfficeSourceValues = result;
        if (this.generalLedgerSetting.glDeptSource == null || this.generalLedgerSetting.glDeptSource == undefined) {
          this.generalLedgerSetting.glDeptSource = '';
        }
        if (this.generalLedgerSetting.glOfficeSource == null || this.generalLedgerSetting.glOfficeSource == undefined) {
          this.generalLedgerSetting.glOfficeSource = '';
        }
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  resetGeneralLedger() {
    this.isgeneralLedgerUpdated = true;
    if (typeof this.resetgeneralLedgerSetting !== 'undefined') {
      this.generalLedgerSetting = JSON.parse(JSON.stringify(this.resetgeneralLedgerSetting));
    }
    this.successMsg = 'Data reset successfully.';
    this.alerts.push({
      type: 'success',
      msg: this.successMsg,
      timeout: 2500,
      closeable: true,
    });
    this.isgeneralLedgerUpdated = false;
  }
  saveGeneralLedger() {
    this.isgeneralLedgerUpdated = true;
    this.alerts = [];
    // eslint-disable-next-line max-len
    this.service
      .post(
        environment.FIClientBaseEndpoint + 'v1/firm/firmparameters/generalledger/' + this.firmId,
        this.generalLedgerSetting
      )
      .subscribe(
        (result) => {
          this.resetgeneralLedgerSetting = JSON.parse(JSON.stringify(this.generalLedgerSetting));
          this.firmParameterModel.generalLedgerSettings = this.generalLedgerSetting;
          eventDispatcher.next({type: ActionTypes.SetFirmParameterSetting, payload: this.firmParameterModel});
          this.successMsg = 'Data modified successfully.';
          this.isgeneralLedgerUpdated = false;
          this.alerts.push({
            type: 'success',
            msg: this.successMsg,
            timeout: 2500,
            closeable: true,
          });
        },
        (error) => {
          this.isgeneralLedgerUpdated = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }
}
