<div [bentoBusyLoader]="isLoading" *ngIf="isLoading; else elseBlock"></div>
<ng-template #elseBlock>
  <app-global-header></app-global-header>
  <main id="MainContent" tabindex="-1" class="Main Main--zeroState">
    <figure class="ZeroState">
      <app-svg-exclamation class="ZeroState-header"></app-svg-exclamation>
      <figcaption class="ZeroState-body">
        <h1 class="h3">500 - Something went wrong on our side</h1>
        <p>There was an error while responding to the last request.</p>
        <p>
          Try reloading the page or <a [routerLink]="" (click)="signOut()">Login</a> again. Our technical team has been
          notified about this error, but please reach out to our support team if the problem persists.
        </p>
        <div class="ZeroState-actions">
          <p>Here are some links you may find helpful:</p>
          <!-- TODO: Add URLS to links -->
          <ul class="ZeroState-list">
            <li class="ZeroState-item">
              <a href="mailto:financialinsights@thomsonreuters.com">Contact support</a>
            </li>
            <!-- <li class="ZeroState-item">
              <a href="#">Help documentation</a>
            </li> -->
            <li class="ZeroState-item" *ngIf="ciamToken">Reference the code below to address this issue:</li>
            <li class="ZeroState-item fontXXLarge" *ngIf="ciamToken">
              {{ ciamToken.substring(ciamToken.length - 20) }}
            </li>
          </ul>
        </div>
      </figcaption>
    </figure>
  </main>
</ng-template>
