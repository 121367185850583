<bento-alert [items]="alerts"></bento-alert>
<div class="GlobalLayout">
  <main id="MainContent" class="Main" role="main" tabindex="-1" [bentoBusyLoader]="isDataLoading">
    <header class="Main-header">
      <app-global-breadcrumbs
        [isFirmPeerGroup]="true"
        [firmName]="'Pre-set peer groups'"
        [peerLabel]="selectedPeerGroup.peerGroupName"
        (updateService)="updateServiceHandler()"
      >
      </app-global-breadcrumbs>
      <div class="row">
        <div class="col">
          <div class="Actions justify-content-end">
            <div class="Actions-item">
              <button type="button" class="btn btn-primary" (click)="handleEditButtonAction()">
                <span class="btn-text">Edit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="Main-body">
      <section class="Section">
        <header class="row align-items-end Section-header">
          <div class="col">
            <div class="col">
              <span class="sr-total-firms" role="status">Showing {{ currentRecords }} of {{ totalRecords }}</span>
            </div>
          </div>
          <div class="col">
            <div class="Actions justify-content-end">
              <div class="Actions-item">
                <span class="bento-search">
                  <label for="firmPeerGroup" class="sr-only">Search firm</label>
                  <input
                    type="text"
                    class="form-control"
                    name="firmPeerGroup"
                    id="firmPeerGroup"
                    (keyup.enter)="searchGlobalPeerGroups()"
                    [(ngModel)]="searchText"
                  />
                  <button type="button" class="btn btn-primary" (click)="searchGlobalPeerGroups()">
                    <span class="btn-text">Search</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </header>
        <div class="Section-body">
          <wj-flex-grid
            #flexGrid
            class="DataGrid DataGridFlexScroll"
            [class.list-grid]="false"
            [autoGenerateColumns]="false"
            [itemsSource]="data"
            [stickyHeaders]="true"
            [showMarquee]="true"
            [showSort]="true"
            [allowDragging]="false"
            [autoRowHeights]="true"
            [headersVisibility]="'Column'"
            (initialized)="gridInitialized(flexGrid, 'viewPeerGroupHeader')"
          >
            <wj-flex-grid-filter
              #filter
              (initialized)="initFilter()"
              (filterChanged)="onFilterChange()"
              (filterApplied)="onFilterApply()"
            ></wj-flex-grid-filter>
            <wj-flex-grid-column
              [header]="'Firm name'"
              [binding]="'firmName'"
              [visible]="true"
              [width]="'*'"
              [align]="'left'"
              [isReadOnly]="true"
              [wordWrap]="true"
            ></wj-flex-grid-column>
            <wj-flex-grid-column
              [header]="'Main office country'"
              [binding]="'mainOfficeCountry'"
              [minWidth]="200"
              [width]="200"
              [visible]="true"
              [align]="'left'"
              [isReadOnly]="true"
              [wordWrap]="true"
            ></wj-flex-grid-column>
            <wj-flex-grid-column
              [header]="'Rank (AmLaw)'"
              [binding]="'amLawRank'"
              [dataType]="2"
              [format]="'f'"
              [minWidth]="150"
              [width]="150"
              [visible]="true"
              [align]="'right'"
              [isReadOnly]="true"
              [wordWrap]="true"
            ></wj-flex-grid-column>
            <wj-flex-grid-column
              [header]="'Lawyers'"
              [binding]="'lawyers'"
              [dataType]="2"
              [format]="'f'"
              [minWidth]="150"
              [width]="150"
              [visible]="true"
              [align]="'right'"
              [isReadOnly]="true"
              [wordWrap]="true"
            ></wj-flex-grid-column>
            <wj-flex-grid-column
              [header]="'Offices'"
              [binding]="'offices'"
              [dataType]="2"
              [format]="'f'"
              [minWidth]="150"
              [width]="150"
              [visible]="true"
              [align]="'right'"
              [isReadOnly]="true"
              [wordWrap]="true"
            ></wj-flex-grid-column>
          </wj-flex-grid>
        </div>
      </section>
    </div>
  </main>
</div>
