export class GlacOverride {
  accountCodes: String[] = [];
  override: Override;
}

export class Override {
  id: number = null;
  startPeriod: null | number = null;
  endPeriod: null | number = null;
  reallocationType = 'P';
  accountTotalAmount: number = null;
  details: Detail[] = [];
}

export class Detail {
  departmentCode: null | string = null;
  officeCode: null | string = null;
  mappedAccountId: null | number = null;
  mappedOfficeId: null | string | number = null;
  amount: null | number = null;
  fteAllocation = false;
  departmentObj: null | any = null;
}

export class GLAccDepartment {
  code: string;
  label: string;
}

export class GLAccOffice {
  firmCode: string;
  pmCode: number;
  firmDescription: string;
}

export class GLAccMappedOffice {
  code: number;
  description: string;
}

export class Departments {
  code: string;
  label: string;
}
