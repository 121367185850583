import {BentoAlertItemOptions} from '@bento/bento-ng';
import {WjFlexChart} from '@grapecity/wijmo.angular2.chart';
import {WjTransposedGrid} from '@grapecity/wijmo.angular2.grid.transposed';
import {FlexGrid} from '@grapecity/wijmo.grid';
import {BehaviorSubject} from 'rxjs';

export default class CreateViewModel {
  isRollingTimeRange: boolean;
  timeRangeSelected: string;
  isUKFirm: boolean;
  isCANFirm: boolean;
  defaultAccess: string;
  rollingTimeRangeSelectedItems: RollingTimeRange;
  metricSelectedItems: ViewsOverlayObject[];
  metricSelectedText: string;
  practiceGroupSelectedItems: ViewsOverlayObject[];
  practiceGroupSelectedText: string;
  peerGroupSelectedItems: ViewsOverlayObject[];
  peerGroupSelectedText: string;
  officeSelectedItems: ViewsOverlayObject[];
  officeSelectedText: string;
  titleSelectedItems: ViewsOverlayObject[];
  titleSelectedText: string;
  associateYearSelectedItems: ViewsOverlayObject[];
  associateYearSelectedText: string;
  srAssociateYearSelectedItems: ViewsOverlayObject[];
  srAssociateYearSelectedText: string;
  partnerYearSelectedItems: ViewsOverlayObject[];
  partnerYearSelectedText: string;
  cardTitles: CardTitles[];
  descriptionList: any[];
  viewsSettings: ViewsSettings;
  fixedTimeRange: FixedTimeRange;
  public constructor() {
    this.isUKFirm = false;
    this.isCANFirm = false;
    this.cardTitles = [];
    this.isRollingTimeRange = true;
    this.timeRangeSelected = 'Rolling';
    this.metricSelectedItems = [];
    this.peerGroupSelectedItems = [];
    this.officeSelectedItems = [];
    this.practiceGroupSelectedItems = [];
    this.titleSelectedItems = [];
    this.associateYearSelectedItems = [];
    this.srAssociateYearSelectedItems = [];
    this.partnerYearSelectedItems = [];
    this.descriptionList = [];
    this.fixedTimeRange = new FixedTimeRange();
    this.rollingTimeRangeSelectedItems = null;
    this.viewsSettings = new ViewsSettings();
  }
}

export class ProcessedBaseTableData {
  isCalledForCard: boolean;
  processedData: any;
  processedDataForChart: any;
  processedColumn: any;
  processedChartSeries: any;
  prossedDataType: string;
  prossedDataCurrency: string;
  precessedWjColformat: string;
}

export class ProcessedMainData {
  isCalledForCard: boolean;
  hasEmptyResult: boolean;
  processedData: any;
  processedHeaders: any;
  processedColumn: any;
  processedColumnCount: number;
}

export class CardTitles {
  cardTitle: string;
  cardId: number;
  office: ViewsOverlayObject;
  practice: ViewsOverlayObject;
  title: ViewsOverlayObject;
  associateYears: ViewsOverlayObject;
  srAssociateYears: ViewsOverlayObject;
  partnerYears: ViewsOverlayObject;
  isCollapsed: boolean;
  mainGridData: any;
  basicTable: any;

  isCardProcessing: boolean;
  isExpanded: boolean;
  shouldShowBasicTable: boolean;
  selectedMetric: Metric;
  isDataTableOrGraph: string;
  showDetailsTable: boolean;
  showBasicTable: boolean;
  isLineChartAvailable: boolean;
  hasError = false;
  errorMessage: any;

  has_levels: boolean;
  report_type: string;
  can_pivot: boolean;
  metricBasicData: any;
  selectedOption: any;

  processedMainGrid: ProcessedMainData;
  processedBaseTable: ProcessedBaseTableData;

  mainFlexGrid: FlexGrid;
  basicFlexGrid: WjTransposedGrid | FlexGrid;
  basicChart: WjFlexChart;
}

export class Metric {
  id: number;
  name: string;
}

export class FixedTimeRange {
  startDateText: string;
  startDateText2: string;
  startDateValue: string;
  startPeriod1: string;
  startPeriod2: string;

  endDateText: string;
  endDateText2: string;
  endDateValue: string;
  endPeriod1: string;
  endPeriod2: string;
}
export class RollingTimeRange {
  name: string;
  id: string;
  startPeriod1: string;
  endPeriod1: string;
  startPeriod2: string;
  endPeriod2: string;
}
export class ViewsOverlayObject {
  name: string;
  id: number;
  groupId: number;
  groupName: string;
  isGlobal: boolean;
  isPpg: boolean;
}
export class ViewsSettings {
  statisticsType: string;
  comparisonType: string;
  matterType: string;
  contractorsType: string;
  currencyType: string;
  //
  public constructor() {
    this.statisticsType = 'Mean';
    this.comparisonType = 'year over year'; //Variance
    this.matterType = 'billable';
    this.contractorsType = 'include';
    this.currencyType = 'dynamic';
  }
}

export class SaveView {
  viewId: string;
  viewName: string;
  original_viewName: string;
  isContainsPPG: boolean;
  shareUserSelectedItems: any[];
  isDefaultForMe: boolean;
  isDefaultForUser: boolean;
  isEditMode: boolean;
  isSharedView: boolean;

  constructor() {
    this.viewId = null;
    this.viewName = null;
    this.original_viewName = null;
    this.isContainsPPG = true;
    this.shareUserSelectedItems = [];
    this.isDefaultForMe = false;
    this.isDefaultForUser = false;
    this.isEditMode = false;
  }
}

export class PeerGroupInfo {
  selectedPeerGroup: any;
  selectedFirms: FirmInfo[];
  is_ppgRequest: boolean;
  ppgCriteria: string[];
}

export class FirmInfo {
  id: number;
  name: string;
  firmId: number;
  rank: number;
  lawyers: number;
  headQuarterOffice: string;
  status: string;
  changeDate: any;
}

export class ViewDetails {
  viewId: string;
  viewTemplateId: string;
  userId: string;
  createdByUserId: string;
  createdDate: Date | any;
  modifiedByUserId: string;
  modifiedDate: Date | any;
  firmId: number;
  viewName: string;
  rollingFormat: ViewOptionDetail;
  viewBeginDate: Date | any;
  viewEndDate: Date | any;
  metrics: ViewOptionDetail[];
  peerGroups: ViewOptionPgDetail[];
  practices: ViewOptionDetail[];
  offices: ViewOptionDetail[];
  titles: ViewOptionDetail[];
  partnerExpYears: ViewOptionDetail[];
  associateExpYears: ViewOptionDetail[];
  srAssociateExpYears: ViewOptionDetail[];
  statisticSetting: string;
  matterTypeSetting: string;
  contractorsSetting: string;
  currencySetting: string;
  comparisionSetting: string;
  templateCreatedByUserId: string;
  templateCreatedDate: Date | any;
  templateModifiedByUserId: string;
  templateModifiedDate: Date | any;
  default: boolean;
  favorite: boolean;
  fixedTimeRange: FixedTimeRange;

  constructor() {
    this.comparisionSetting = 'year over year';
  }
}

export class ViewOptionDetail {
  id: number;
  name: string;
}
export interface ViewOptionPgDetail {
  id: number;
  isGlobal: boolean;
  isPpg: boolean;
  name: string;
}

export class PeerGroupDetails {
  selectedPeerGroup: any;
  hasError: boolean;
  successResponse: PeerGroupInfo;
  failedResponse: any;
  errorMessage: any;
  alerts: BentoAlertItemOptions[];
}

export class UserList {
  id: string;
  name: string;
  disabled: boolean;
  alreadyShared: boolean;
}

export class DescriptionSummary {
  timeRange: string;
  metrics: string;
  peerGroups: string;
  practices: string;
  offices: string;
  titles: string;
  associateExp: string;
  srAssociateExp: string;
  partnerYearsExp: string;

  constructor() {
    this.timeRange = '';
    this.metrics = '';
    this.peerGroups = '';
    this.practices = '';
    this.offices = '';
    this.titles = '';
    this.associateExp = '';
    this.srAssociateExp = '';
    this.partnerYearsExp = '';
  }
}
