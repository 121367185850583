import {Component, Input, ViewChild} from '@angular/core';
import StaffingratioFirmSettings from '../../../models/staffingration-subscription-settings.model';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcCore from '@grapecity/wijmo';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {LocalStorageService} from 'src/app/core/services/local-storage/local-storage.service';
import {defaultAccessFullName} from 'src/app/shared/constants';
@Component({
  selector: 'app-firm-staffingratio-subscription',
  templateUrl: './firm-staffingratio-subscription.component.html',
})
export class FirmStaffingratioSubscriptionComponent {
  @Input() firmId: number;
  firmStaffingratioSubscription: StaffingratioFirmSettings = new StaffingratioFirmSettings();
  resetStaffingRatioModel: StaffingratioFirmSettings = new StaffingratioFirmSettings();
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  isSRUpdated = true;
  numberOfStdPeerGroupValidation = '(([0-9])|(1[0-9])|(2[0]))';
  numberOfCustPeerGroupValidation = '(([0-9])|(1[0]))';
  validations: any;
  alerts: BentoAlertItemOptions[] = [];
  data: wjcCore.CollectionView;
  emptyHistoricalData = [];
  reportDataList = null;
  currentSurveyYearOfFirm = 2018;

  constructor(private service: BaseService, private localStorageService: LocalStorageService) {
    this.validations = {
      numberOfStdPeerGroupValidation: {
        pattern: 'Error: Value should be between 0 to 20',
      },
      numberOfCustPeerGroupValidation: {
        pattern: 'Error: Value should be between 0 to 10',
      },
    };
  }
  async ngOnInit() {
    this.isSRUpdated = true;
    await this.getCurrentSurveyYear();
    this.getMainOfficeCountry();
    this.getStaffingratioSubscriptionByFirmId();
    await this.getHistoricalSubscription();
    this.isSRUpdated = false;
  }
  getHistoricalSubscription() {
    this.isSRUpdated = true;
    let surveyYear = this.currentSurveyYearOfFirm - 1;

    return this.service
      .get(
        environment.FIClientBaseEndpoint +
          'v1/staffingratio/subscription?firmId=' +
          this.firmId +
          '&surveyYear=' +
          surveyYear,
        ''
      )
      .toPromise()
      .then(
        (result) => {
          this.reportDataList = result;
          this.data = new wjcCore.CollectionView(this.reportDataList);
          this.isSRUpdated = false;
        },
        (error) => {
          this.isSRUpdated = false;
          this.reportDataList = [
            {
              firmId: 907,
              firmName: 'Mann & Thomas',
              surveyYear: 'No records available',
              amLawSegment: null,
              lawyerSegment: null,
              officeSegment: null,
              srFirmSubscriptionEntity: null,
              usesServiceCenter: null,
              firmDataValid: null,
              peerSubmitted: null,
              reportReady: null,
              isPeerDisplayed: null,
              isFirmDisabled: null,
              numberOfStdPeerGroups: null,
              numberOfCustomPeerGroups: null,
              mainOfficeCountry: null,
            },
          ];
          this.data = new wjcCore.CollectionView(this.reportDataList);
        }
      );
  }
  getStaffingratioSubscriptionByFirmId() {
    this.isSRUpdated = true;
    this.service
      .get(
        environment.FIClientBaseEndpoint +
          'v1/staffingratio/subscription/' +
          this.firmId +
          '?surveyYear=' +
          this.currentSurveyYearOfFirm,
        ''
      )
      .subscribe(
        (result) => {
          this.firmStaffingratioSubscription = result;
          this.firmStaffingratioSubscription.isSubscribed = true;
          this.resetStaffingRatioModel = JSON.parse(JSON.stringify(this.firmStaffingratioSubscription));
          this.isSRUpdated = false;
        },
        (error) => {
          this.isSRUpdated = false;
          this.firmStaffingratioSubscription.surveyYear = this.currentSurveyYearOfFirm.toString();
          this.firmStaffingratioSubscription.isSubscribed = false;
          this.resetStaffingRatioModel = JSON.parse(JSON.stringify(this.firmStaffingratioSubscription));
        }
      );
  }
  saveStaffingRationSubscription() {
    this.isSRUpdated = true;
    if (!this.firmStaffingratioSubscription.isSubscribed) {
      this.firmStaffingratioSubscription.numberOfCustomPeerGroups = 0;
      this.firmStaffingratioSubscription.numberOfStdPeerGroups = 0;
    }
    let param = {
      surveyYear: this.firmStaffingratioSubscription.surveyYear,
      isSrSubscribed: this.firmStaffingratioSubscription.isSubscribed,
      isFirmDisabled: this.firmStaffingratioSubscription.isFirmDisabled,
      isPeerDisplayed: this.firmStaffingratioSubscription.isPeerDisplayed,
      numberOfStdPeerGroups: this.firmStaffingratioSubscription.numberOfStdPeerGroups,
      numberOfCustomPeerGroups: this.firmStaffingratioSubscription.numberOfCustomPeerGroups,
    };
    this.service
      .post(environment.FIClientBaseEndpoint + 'v1/staffingratio/subscription/' + this.firmId, param)
      .subscribe(
        (result) => {
          this.getStaffingratioSubscriptionByFirmId();
          this.isSRUpdated = false;
          this.alerts.push({
            type: 'success',
            msg: 'Data saved Successfully.',
            timeout: 2500,
            closeable: true,
          });
        },
        (error) => {
          this.isSRUpdated = false;
          this.alerts.push({
            type: 'warning',
            msg: error.error.message,
            closeable: true,
          });
        }
      );
  }

  gridInitialized(flexGrid) {
    if (flexGrid.rows) {
      flexGrid.rows.defaultSize = 30;
      flexGrid.columnHeaders.rows.defaultSize = 30;
    }
    this.flexGrid = flexGrid;
  }
  getMainOfficeCountry() {
    this.isSRUpdated = true;
    let countryCode = this.localStorageService.get('mainOfficeCountry');

    let countryObj = defaultAccessFullName.filter((object) => {
      return object['countryCode'] == countryCode;
    });
    if (countryObj && countryObj.length > 0)
      this.firmStaffingratioSubscription.mainOfficeCountry = countryObj[0].countryDescription;
    this.isSRUpdated = false;
  }
  getCurrentSurveyYear() {
    this.isSRUpdated = true;
    return this.service
      .get(environment.FIClientBaseEndpoint + 'v1/staffingratio/surveyyear/' + this.firmId, '')
      .toPromise()
      .then(
        (result) => {
          this.currentSurveyYearOfFirm = result;
          this.isSRUpdated = false;
        },
        (error) => {
          let erMsg = 'Something went wrong. Please try again.';
          if (error && error.error && error.error.message && error.error.message.trim()) {
            erMsg = error.error.message.trim();
          }
          if (erMsg != 'Firm Id is not valid for the survey year') {
            this.isSRUpdated = false;
            this.alerts.push({
              type: 'warning',
              msg: erMsg,
              closeable: true,
            });
          }
        }
      );
  }

  resetInformation() {
    this.firmStaffingratioSubscription = JSON.parse(JSON.stringify(this.resetStaffingRatioModel));
    this.alerts.push({
      type: 'success',
      msg: 'Data reset successfully.',
      timeout: 2500,
      closeable: true,
    });
  }
}
