<bento-alert [items]="alerts"></bento-alert>
<div [bentoBusyLoader]="is3EUpdated">
  <section class="Section">
    <form name="cloudCredential3E" #cloudCredntialSetting="ngForm" class="Section-form Form" bfmForm>
      <header class="Section-header">
        <h2 class="Section-heading">Create extract</h2>
      </header>
      <div class="Section-body">
        <div class="form-group Form-input Form-input--medium">
          <label for="runextract-combobox">Select period</label>
          <bento-combobox
          id="runextract-combobox"
          name="runextract-combobox"
          class="form-control"
          bfmField
          bfmLabel="runextract-combobox"
          [(ngModel)]="selectedPeriod"
          [itemsObservable]="dataStream"
          (ngModelChange)="onChangeSelectPeriodField(selectedPeriod)"
          [disabled]="disableRunExtract"
          [options]="comboboxOptions"
          [placeholder]="false"
          [rowTemplate]="myTemplate"
          [revertOnInvalidInput]="true"
          ngDefaultControl
        ></bento-combobox>
        <ng-template #myTemplate let-$data="data" let-$options="options" let-$index="index">
          <div class="safe-id">
            {{$data.row.text}}
        </div>
      </ng-template>
        </div>
      </div>
      <footer class="Section-footer">
        <div class="Actions">
          <div class="Actions-item a11y">
            <button
              type="submit"
              class="btn btn-primary"
              [attr.aria-disabled]="disableRunExtract || isExtractRunningForFirm ? 'true' : 'false'"
              (click)="disableRunExtract || isExtractRunningForFirm ? $event.stopPropagation() : runExtract()"
              [attr.aria-labelledby]="disableRunExtract || isExtractRunningForFirm ? 'aria-required-extract' : null"
            >
              <span class="btn-text">Run extract</span>
            </button>
          </div>
          <span class="sr-only" id="aria-required-extract"
            ><p *ngIf="disableRunExtract == true">Error: No valid period, Extract is disabled. Run Extract</p>
            <p *ngIf="isExtractRunningForFirm == true && disableRunExtract == false">
              Extract in progress, Run Extract
            </p></span
          >
        </div>
      </footer>
    </form>
  </section>

  <section class="MainSection MainSection--slim">
    <header class="MainSection-header">
      <h3 class="MainSection-heading">Create new extract</h3>
    </header>
    <div class="MainSection-body">
      <p>Note: Drop-down value for "Select period" will be based on the firm's fiscal period</p>
      <ol class="MainSection-list-page">
        <li>Select the fiscal period to create the extract on.</li>
        <li>
          Select “Run extract” to begin extract. The “Run extract” button will be disabled either when no valid period
          exists or an extract is already in progress for the firm.
        </li>
        <li>
          Let the extract run and monitor for errors on the
          <a [routerLink]="['.']" (click)="openExtratJobage()">Extract Job page</a>
        </li>
        <li>That’s it!</li>
      </ol>
    </div>
  </section>
</div>
