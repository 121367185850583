export class PivotRequestBody {
  requestBodyPractice: any;
  requestBodyOffice: any;
  requestBodyTitle: any;
  requestBodyOfficeGroup: any;
  officeGroups: any;
  requestBodyExpandOfficeParent: any;
  requestBodyExpandOfficeChildren: any;
  requestBodyExpandAllOffices: any;
  requestBodyExpandCategory: any;
  requestBodyExpandCategoryChildren: any;

  public constructor() {}
}
