<header class="global-header global-header-text Header" role="banner">
  <div class="topbar-left">
    <a href="/" class="topbar-item topbar-logo Header-logo">
      <app-svg-logo></app-svg-logo>
    </a>
  </div>
</header>
<main id="MainContent" tabindex="-1" class="Main Main--zeroState" [bentoBusyLoader]="isLoading">
  <figure class="ZeroState">
    <app-svg-exclamation></app-svg-exclamation>
    <figcaption class="ZeroState-body">
      <h3>User ID not found</h3>
      <p>The User ID used at login is not available in Financial Insights.</p>
      <div class="ZeroState-actions">
        <p>
          Contact your firm's admin to resolve this issue or <a [routerLink]="" (click)="signOut()">login</a> again.
        </p>
        <ul class="ZeroState-list">
          <li class="ZeroState-item" *ngIf="ciamToken">Reference the code below to address this issue:</li>
          <li class="ZeroState-item fontXXLarge" *ngIf="ciamToken">
            {{ ciamToken.substring(ciamToken.length - 20) }}
          </li>
        </ul>
      </div>
    </figcaption>
  </figure>
</main>
