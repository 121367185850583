<bento-alert [items]="alerts" (close)="closeAlert($event)"></bento-alert>
<div class="GlobalLayout"  [bentoBusyLoader]="isDataLoading">
  <main id="MainContent" role="main" tabindex="-1" class="Main">
    <header class="Main-header">
      <app-global-breadcrumbs
        [isFirmPeerGroup]="true"
        [firmName]="isTAdmin ? 'Peer groups' : firmDetails.name"
        [peerLabel]="isEditOrAddPeerGroup ? selectedOption.peerlabel : 'Add peer group'"
        (updateService)="updateServiceHandler()"
      >
      </app-global-breadcrumbs>
      <h1 class="Main-heading" id="addPeerGroupHeader">
        {{ isEditOrAddPeerGroup ? selectedOption.peerlabel : 'Add peer group' }}
      </h1>
      <br />
      <span class="label-required-summary">Required field</span>
      <form bfmForm name="customPeerGroup" class="Form">
        <div class="form-group Form-input Form-input--medium">
          <label for="peerGroupName" class="bento-label-required">Peer group name</label>
          <input
            type="text"
            name="peerGroupName"
            id="peerGroupName"
            class="form-control"
            bfmField
            bfmLabel="Peer group name"
            required
            [(ngModel)]="peerGroupName"
            (ngModelChange)="verifySelection()"
          />
        </div>
        <div class="form-group">
          <label for="peerGroupSelection" id="minFirm" class="bento-label-required">Selected firms (minimum {{ mandatorySelectionCount }} firms)</label
          >          
          <bento-tags-input
            name="peerGroupSelection"
            class="FormTags"
            id="peerGroupSelection"
            bfmLabel="Selected firms (minimum 5 firms)"
            required 
            [(ngModel)]="tagInputSelection"
            [editable]="false"
            [disableTagRemove]="false"
            [disableInput]="true"
            [minTagLength]="5"
            tagDefaultType="string"
            (tagRemoved)="onTagRemoved($event)"
            *ngIf="gridSelection.length > 0 || selectedOption != null"
            aria-labelledby="minFirm"
            aria-live="polite"
          ></bento-tags-input>
          <p #noTags *ngIf="!(gridSelection.length > 0 || selectedOption != null)">Select firms below</p>
        </div>
      </form>
      <div class="row">
        <div class="col">
          <app-grid-record-details
            [currentRecords]="currentRecords"
            [totalRecords]="totalRecords"
          ></app-grid-record-details>
        </div>
        <div class="col">
          <div class="Actions justify-content-end">
            <div class="Actions-item Actions-item--large">
              <span class="bento-search">
                <label for="firmPeerGroup" class="sr-only">Search firm name</label>
                <input
                  type="text"
                  name="firmPeerGroup"
                  id="firmPeerGroup"
                  class="form-control"
                  [(ngModel)]="searchText"
                />
                <button type="button" class="btn btn-primary" (click)="searchGlobalPeerGroups()">
                  <span class="btn-text">Search</span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="Main-body">
      <wj-flex-grid
        #flexGrid
        class="DataGrid"
        [class.list-grid]="false"
        [itemsSource]="data"
        [stickyHeaders]="true"
        [showMarquee]="true"
        [class.list-grid]="isListGrid"
        [showSort]="true"
        [allowDragging]="false"
        [autoRowHeights]="true"
        [headersFocusability]="'All'"   
        [headersVisibility]="'All'" 
        [bigCheckboxes]="true"
        (loadedRows)="initLoadedRow(flexGrid)"
        (initialized)="gridInitialized(flexGrid, 'addPeerGroupHeader')"
      >
        <ng-template wjFlexGridCellTemplate [cellType]="'TopLeft'" let-col="col">
          <ng-container *ngIf="col.index === 0">
            <bento-checkbox
              id="selectAll"
              [ngModel]="isAllSelected()"
              (change)="onHeaderCheckBoxChange()"
              [indeterminate]="isIndeterminate()"
            ></bento-checkbox>
            <label for="selectAll" class="sr-only">Select all</label>
          </ng-container>
        </ng-template>
        <ng-template wjFlexGridCellTemplate [cellType]="'RowHeader'" let-col="col" let-row="row" let-item="item">
          <ng-container *ngIf="col.index === 0">           
            <bento-checkbox
              
              id="selectRow-{{ row.index }}"
              [ngModel]="item.selected"
              (change)="item.disabled || (item.selected = !item.selected); onCheckBoxChange()"
            >
            </bento-checkbox>
            <label for="selectRow-{{ row.index }}" class="sr-only" [attr.aria-label]="item.name"  >{{ item.name }}</label>
          </ng-container>
        </ng-template>
        <wj-flex-grid-column
          [header]="'Firm name'"
          [binding]="'name'"
          [visible]="true"
          [width]="'*'"
          [align]="'left'"
          [isReadOnly]="true"
          [wordWrap]="true"
        ></wj-flex-grid-column>
        <wj-flex-grid-column
          [header]="'Rank (AmLaw)'"
          [binding]="'rank'"
          [minWidth]="150"
          [width]="150"
          [visible]="true"
          [align]="'left'"
          [isReadOnly]="true"
          [wordWrap]="true"
        ></wj-flex-grid-column>
        <wj-flex-grid-column
          [header]="'Lawyers'"
          [binding]="'lawyers'"
          [minWidth]="150"
          [width]="150"
          [visible]="true"
          [align]="'left'"
          [isReadOnly]="true"
          [wordWrap]="true"
        ></wj-flex-grid-column>
      </wj-flex-grid>
      <bento-pagination
        [page]="data.pageIndex + 1"
        [directionLinks]="true"
        [boundaryLinks]="true"
        [totalItems]="data.totalItemCount"
        [(itemsPerPage)]="data.pageSize"
        (itemsPerPageChange)="onItemsPerPageChanged($event)"
        (pageChange)="onPageChanged($event)"
      ></bento-pagination>
    </div>
    <footer class="Main-footer">
      <div class="Actions">
        <div class="Actions-item">
          <button type="button" class="btn btn-outline-secondary" (click)="handleButtonAction('Cancel')">
            <span class="btn-text">Cancel</span>
          </button>
        </div>
        <div class="Actions-item" *ngIf="isEditOrAddPeerGroup">
          <button type="button" class="btn btn-outline-danger" (click)="handleButtonAction('Delete')">
            <span class="btn-text">Delete</span>
          </button>
        </div>
        <div class="Actions-item a11y">
          <button
            type="button"
            class="btn btn-primary"
            (click)="!isReadyToSave ? $event.stopPropagation() : handleButtonAction('Save')"
            [attr.aria-disabled]="!isReadyToSave ? 'true' : 'false' "
            [attr.aria-labelledby]="!isReadyToSave ? 'aria-required-save' : null "
          >
            <span class="btn-text">Save</span>
          </button>
          <span class="sr-only" id="aria-required-save">Fill required fields, Save</span>
        </div>
      </div>
    </footer>
  </main>
</div>
